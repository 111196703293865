<table class="table table-condensed">
    <thead>
        <th scope="col">Nivel</th>
        <th scope="col">$ por referido</th>
        <th scope="col">Referidos</th>
        <th scope="col">$Referidos</th>
        <th scope="col">$a pagar</th>
    </thead>
    <tbody>
        <tr *ngIf="data.client.level  === 'MARINERO' || data.client.level === 'CABO' || data.client.level === 'CAPITAN' || data.client.level === 'ALMIRANTE'">
            <td>Nivel 1</td>
            <td>{{30 | currency}}</td>
            <td><span>x</span></td>
            <td>{{data.level_1}}</td>
            <td><span>=</span></td>
            <td>{{data.level_1*30 | currency}}</td>
            <td><span>+</span></td>
        </tr>
        <tr *ngIf="data.client.level === 'CABO' || data.client.level === 'CAPITAN' || data.client.level === 'ALMIRANTE'">
            <td>Nivel 2</td>
            <td>{{15 | currency}}</td>
            <td><span>x</span></td>
            <td>{{data.level_2}}</td>
            <td><span>=</span></td>
            <td>{{data.level_2*15 | currency}}</td>
            <td><span>+</span></td>
        </tr>

        <tr *ngIf="data.client.level === 'CAPITAN' || data.client.level === 'ALMIRANTE'">
            <td>Nivel 3</td>
            <td>{{10 | currency}}</td>
            <td><span>x</span></td>
            <td>{{data.level_3}}</td>
            <td><span>=</span></td>
            <td>{{data.level_3*10 | currency}}</td>
            <td><span>+</span></td>
        </tr>

        <tr *ngIf="data.client.level === 'ALMIRANTE'">
            <td>Nivel 4</td>
            <td>{{5 | currency}}</td>
            <td><span>x</span></td>
            <td>{{data.level_4}}</td>
            <td><span>=</span></td>
            <td>{{data.level_4 *5 | currency}}</td>
            <td><span>+</span></td>
        </tr>

        <tr>
            <td colspan="5" class="text-right"><b>Total:</b></td>
            <td colspan="2"><b>{{data.amount | currency}}</b></td>
        </tr>
    </tbody>
</table>