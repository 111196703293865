<div class="row">
    <div class="col">
        <div class="d-inline-block" 
            ngbDropdown 
            container="body"
            [placement]="['bottom-right', 'top-right']" 
            display="dynamic">
            
            <button class="btn btn-primary btn-sm" ngbDropdownToggle>Opciones</button>
            <div ngbDropdownMenu class="scroll">
                <ng-container *ngFor="let action of options; let i = index;">

                    <ng-container *ngIf="action.length">
                        <ng-container *ngFor="let act of action">
                            <ng-template *ngTemplateOutlet="buttonTpl; context: {$implicit: act}"></ng-template>
                        </ng-container>
                        <div class="dropdown-divider" *ngIf="i < (options.length - 1)"></div>
                    </ng-container>

                    <ng-container *ngIf="!action.length">
                        <ng-template *ngTemplateOutlet="buttonTpl; context: {$implicit: action}"></ng-template>
                    </ng-container>
                </ng-container> 
            </div>
        </div>
    </div>
</div>

<ng-template #buttonTpl let-action>
    <button 
        *ngIf="isVisible(action?.conditionality)" 
        ngbDropdownItem 
        (click)="fireEvent(action.event)"> 

        <i *ngIf="action['icon']" class="{{action.icon}}"></i>
        {{ action.display }} 
    </button>
  </ng-template>