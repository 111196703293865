<div class="modal-body" *ngIf="charge !== undefined">
    <dl class="row">
        <dt class="col-sm-6">Cargo inicial</dt>
        <dd class="col-sm-6">{{charge.amount | currency}}</dd>
        <dt class="col-sm-6">Total de devoluciones al momento</dt>
        <dd class="col-sm-6">{{refundData.total_refunded | currency}}</dd>
        <dt class="col-sm-6">Máxima cantidad a devolver</dt>
        <dd class="col-sm-6">{{refundData.max_amount_to_refund | currency}}</dd>
    </dl>

    <app-form-field type="input" [options]="{label: 'Total a devolver', path: 'amount'}"></app-form-field>
</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid" (click)="makeRefund()">Efectuar
            devolución</button>
    </div>

    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>