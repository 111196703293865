/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./outputs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./outputs.component";
var styles_OutputsComponent = [i0.styles];
var RenderType_OutputsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OutputsComponent, data: {} });
export { RenderType_OutputsComponent as RenderType_OutputsComponent };
export function View_OutputsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Salida"])), (_l()(), i1.ɵeld(2, 0, null, null, 8, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["Cantidad retirada: ", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["Cantidad en stock antes del retiro: ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["Cantidad en stock despues del retiro: ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["ID del ticket: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.amount; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.item.before_in_stock; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.item.after_in_stock; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.item.id_tickets; _ck(_v, 10, 0, currVal_3); }); }
export function View_OutputsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-outputs", [], null, null, null, View_OutputsComponent_0, RenderType_OutputsComponent)), i1.ɵdid(1, 114688, null, 0, i2.OutputsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OutputsComponentNgFactory = i1.ɵccf("app-outputs", i2.OutputsComponent, View_OutputsComponent_Host_0, { item: "item" }, {}, []);
export { OutputsComponentNgFactory as OutputsComponentNgFactory };
