import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
// tslint:disable-next-line: no-default-import
import validateRfc from '../../../../../node_modules/validate-rfc/src/index';

// tslint:disable-next-line: only-arrow-functions
export function rfcValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const rfc = control.value;
      const responseRfcValid = validateRfc(control.value);
      const rfcValid = responseRfcValid ? responseRfcValid.isValid : null;

      return !rfcValid ? {rfc: {value: control.value}} : null;
    };
  }
