import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { FromService } from '../../../../providers/form.service';
import { SalesDashboardService } from '../../../../services/sales-dashboard.service';
import { SwalService } from '../../../../services/swal.service';
var SalesPromotionChartComponent = /** @class */ (function () {
    function SalesPromotionChartComponent(formBuilder, fromService, swal, salesDashboard) {
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.swal = swal;
        this.salesDashboard = salesDashboard;
        this.isLoadPromoChart = new EventEmitter();
        this.isLoad = true;
        this.barChartColors = [
            { backgroundColor: '#ff843c' },
            { backgroundColor: '#83b941' },
            { backgroundColor: '#16a4db' }
        ];
        this.barChartOptions = {
            responsive: true,
            // We use these empty structures as placeholders for dynamic theming.
            scales: {
                xAxes: [
                    {
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ],
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ]
            },
            plugins: {
                plugins: {
                    datalabels: {
                        color: 'blue',
                        labels: {
                            title: {
                                font: {
                                    weight: 'bold'
                                }
                            },
                            value: {
                                color: 'green'
                            }
                        }
                    }
                }
            }
        };
        this.barChartLabels = [];
        this.barChartType = 'bar';
        this.barChartLegend = true;
        this.barChartPlugins = [pluginDataLabels];
        this.labelsValue = [];
        this.leadTypes = [];
        this.barChartData = [];
        this.form = this.formBuilder.group({
            from: [],
            to: [],
            leadType: ''
        });
    }
    SalesPromotionChartComponent.prototype.ngOnInit = function () {
        // setting timeout to avoid call same request to hubspot at same time.    
        this.setChart();
        this.fromService.setForm(this.form);
    };
    SalesPromotionChartComponent.prototype.search = function () {
        var fromSub = this.form.get('from').value;
        var toSub = this.form.get('to').value;
        var leadType = this.form.get('leadType').value;
        var params = {
            from: fromSub,
            to: toSub,
            lead_type: leadType
        };
        if (params.from && params.to) {
            this.setChart(params);
        }
        else {
            this.swal.error({ title: 'Favor de introducir las fechas correctamente' });
        }
    };
    SalesPromotionChartComponent.prototype.setChart = function (dateRangeParams) {
        var _this = this;
        this.isLoad = true;
        this.salesDashboard.salesByPromotion(dateRangeParams).subscribe(function (res) {
            // getting labels                        
            if (res.success) {
                var paramsProm = {
                    name: 'promociones'
                };
                _this.salesDashboard.contactProperty(paramsProm).subscribe(function (response) {
                    var barChartLabelData = [];
                    var keys = Object.keys(res.response);
                    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                        var index = keys_1[_i];
                        if (index) {
                            for (var _a = 0, _b = response.response.data.data.options; _a < _b.length; _a++) {
                                var option = _b[_a];
                                if (option.value === index) {
                                    barChartLabelData.push(option.label);
                                }
                            }
                        }
                    }
                    _this.barChartLabels = barChartLabelData.slice();
                });
                var params = {
                    name: 'etiqueta_de_origen'
                };
                _this.salesDashboard.contactProperty(params).subscribe(function (response) {
                    if (response.success) {
                        if (_this.leadTypes.length === 0) {
                            _this.leadTypes.push({ id: '', name: 'Todos' });
                            for (var _i = 0, _a = response.response.data.data.options; _i < _a.length; _i++) {
                                var option = _a[_i];
                                _this.leadTypes.push({ id: option.value, name: option.label });
                            }
                        }
                    }
                });
                var nameParam = {
                    name: 'lead_promocion'
                };
                _this.salesDashboard.contactProperty(nameParam).subscribe(function (response) {
                    if (response.success) {
                        var keys = Object.keys(res.response);
                        var barChartDataL = [];
                        var closeRateData = [];
                        var _loop_1 = function (option) {
                            var countByData = [];
                            for (var _i = 0, keys_3 = keys; _i < keys_3.length; _i++) {
                                var index = keys_3[_i];
                                var arrayByLabel = res.response[index].filter(function (contact) {
                                    return contact.lead_promocion.indexOf(option.value) > -1;
                                });
                                countByData.push(arrayByLabel.length);
                                if (option.value === 'lead_ganado') {
                                    closeRateData[index] = tslib_1.__assign({}, closeRateData[index], { lead_ganado: arrayByLabel.length });
                                    // this.closeRateData[index].push({ lead_ganado: arrayByLabel.length });                  
                                }
                                if (option.value === 'lead_perdido') {
                                    closeRateData[index] = tslib_1.__assign({}, closeRateData[index], { lead_perdido: arrayByLabel.length });
                                }
                            }
                            var data = { data: countByData, label: option.label, yAxisID: 'y-axis-1' };
                            barChartDataL.push(data);
                        };
                        for (var _i = 0, _a = response.response.data.data.options; _i < _a.length; _i++) {
                            var option = _a[_i];
                            _loop_1(option);
                        }
                        var closeRateChart = [];
                        for (var _b = 0, keys_2 = keys; _b < keys_2.length; _b++) {
                            var index = keys_2[_b];
                            if (closeRateData[index].lead_ganado > 0 && closeRateData[index].lead_perdido > 0) {
                                var closeRate = (closeRateData[index].lead_ganado /
                                    // tslint:disable-next-line: restrict-plus-operands
                                    (closeRateData[index].lead_ganado + closeRateData[index].lead_perdido)) * 100;
                                var valueFinal = +closeRate.toFixed(2);
                                closeRateChart.push(valueFinal);
                            }
                            else {
                                closeRateChart.push(0);
                            }
                        }
                        // cuantos lead ganados por facebok ad lead, etc
                        var closeRateDataChart = {
                            data: closeRateChart, label: 'Close Rate', type: 'line', datalabels: {
                                formatter: function (value, context) {
                                    return value + "%";
                                }
                            },
                            yAxisID: 'y-axis-2'
                        };
                        barChartDataL.push(closeRateDataChart);
                        // group by options            
                        _this.barChartData = barChartDataL.slice();
                        // this.barChartData = barChartDataL;
                        _this.isLoad = false;
                        _this.isLoadPromoChart.emit(true);
                    }
                });
            }
            else {
                _this.swal.error({ title: 'Error recuperando los datos para la gráfica ventas por mes, intente de nuevo' });
            }
        });
    };
    SalesPromotionChartComponent.prototype.showInfoGraphic = function () {
        var message = "How many closed won, lost and valid leads based on promotions of lead generated filtered by date range.\n    Close Rate: Closed Won/Closed Lost";
        this.swal.info(message);
    };
    return SalesPromotionChartComponent;
}());
export { SalesPromotionChartComponent };
