import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class CashService {

  baseUrl = `${environment.apiUrl}`;

  constructor(private readonly http: HttpClient) { }

  create(params): any {
    return this.http.post(`${this.baseUrl}/admin_dashboard/cash`, params);
  }

  delete(id): any {
    return this.http.delete(`${this.baseUrl}/admin_dashboard/cash/${id}`);
  }

  edit(id, params): any {
    return this.http.put(`${this.baseUrl}/admin_dashboard/cash/${id}`, params);
  }

  show(id): any {
    return this.http.get(`${this.baseUrl}/admin_dashboard/cash/${id}`);
  }

  index(params?): any {
    return this.http.get(`${this.baseUrl}/admin_dashboard/cash`, {params});
  }

  getCash(params?): any {
    return this.http.post(`${this.baseUrl}/admin_dashboard/get-cash`, params);
  }
}
