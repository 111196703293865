import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'history-ticket-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnInit {
  @Input('item') item: any;

  ngOnInit(): void {
    //
  }

}
