import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BroadcastService } from '../../../services/broadcast.service';
import { LeadSourcesService } from '../../../services/leadsources.service';
import { PermissionsService } from '../../../services/permissions.service';
import { SwalService } from '../../../services/swal.service';

@Component({
  selector: 'app-leadsources',
  templateUrl: './leadsources.component.html',
  styleUrls: ['./leadsources.component.scss']
})
export class LeadsourcesComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  baseUrl = `${environment.apiUrl}`;
  isDistributor: boolean = ![1].includes(JSON.parse(localStorage.getItem('user')).team_id);
  dataTableConfig: any;
  private readonly baseDataTableConfig = {
    config: {
      base: this.leadSourcesService,
      api: 'getLeadSourcesTable'
    },
    columns: [
      {
        display: 'ID',
        field: 'id_leadsources',
        type: 'text'
      },
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Status',
        field: 'status',
        type: 'boolean'
      },
      {
        display: 'Acciones',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-info',
            icon: 'fa fa-pencil',
            event: 'LeadSourceCreateEdit',
            conditionality: true,
            permissions: ['update-leadsources'],
            onlyForTeam: [1]
          }
        ]
      }
    ],
    filters: [
      {
        title: 'Estatus',
        cssClass: 'col-lg-5 col-md-12 col-sm-12',
        type: 'status',
        options: [
          {
            val: '',
            name: 'TODOS'

          },
          {
            val: '1',
            name: 'Activo'

          },
          {
            val: '0',
            name: 'Inactivo'

          }
        ]
      }
    ]
  };
  constructor(
    private readonly broadcast: BroadcastService,
    private readonly leadSourcesService: LeadSourcesService,
    private readonly permissionsService: PermissionsService,
    private readonly swalService: SwalService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      if (event.name !== 'undefined-row-click') {
        this[event.name]('update', event.data);
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * LeadSourceEdit
   * Edita el nombre o el estado de un leadsource
   * @param data información del lead source seleccionado id, nombre, status
   */
  LeadSourceCreateEdit(status, data?): void {
    const swalParams = this.LeadSourceFormulary(status, data);
    this.swalService.customForm(swalParams).then(formValues => {
      if (!formValues.dismiss) {
        const payload = {};
        if (status === 'update') {
          Object.assign(payload, { id_leadsources: data.id_leadsources });
        }

        formValues.value.forEach(element => {
          Object.assign(payload, element);
        });

        this.subscriptions.push(this.leadSourcesService[status](payload).subscribe((response: any) => {
          if (response.success) {
            this.swalService.success().then(() => {
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swalService.error({ text: response.message });
          }
        }));
      }
    });
  }

  /**
   * LeadSourceFormulary
   * Muestra el formulario de crear o editar un Leadsource dependiendo del tipo pasado
   */
  LeadSourceFormulary(type, data?): Object {
    const confirmButtonText = type === 'create' ? 'Registrar' : 'Actualizar';
    const id_swal = type === 'create' ? 'create-lead' : 'update-lead';
    const swalParams = {
      confirmButtonText,
      id: id_swal
    };
    let formulary = `<div id=${id_swal}>`;
    let inputName = '<input id="name" type="text", placeholder="Nombre" class="swal2-input" required>';
    if (type === 'update') {
      const status = ['Desactivar', 'Activar'];
      inputName = `${inputName.slice(0, inputName.length - 1)} value=${data.name} ${inputName.slice(inputName.length - 1)}`;
      const inputStatus = '<select id="status" class="swal2-input">' +
        `<option value="none" selected disabled hidden>${status[data.status]}</option>` +
        '<option value="1">Activar</option>' +
        '<option value="0">Desactivar</option>' +
        '</select>';
      formulary += inputStatus;
    }
    formulary += inputName;
    formulary += '</div>';

    Object.assign(swalParams, { inputs: [formulary] });

    return swalParams;
  }

}
