/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit-group.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components/form/form-field/form-field.component.ngfactory";
import * as i3 from "../../../../components/form/form-field/form-field.component";
import * as i4 from "../../../../providers/form.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "ngx-lightbox/lightbox.service";
import * as i7 from "@angular/common";
import * as i8 from "./edit-group.component";
import * as i9 from "../../../../services/broadcast.service";
import * as i10 from "../../../../services/groups.service";
import * as i11 from "@angular/forms";
import * as i12 from "@ng-bootstrap/ng-bootstrap";
import * as i13 from "../../../../services/swal.service";
var styles_EditGroupComponent = [i0.styles];
var RenderType_EditGroupComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditGroupComponent, data: {} });
export { RenderType_EditGroupComponent as RenderType_EditGroupComponent };
function View_EditGroupComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-outline-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancelar"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-outline-success"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateGroup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Guardar"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form.invalid; _ck(_v, 3, 0, currVal_0); }); }
function View_EditGroupComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cerrar"]))], null, null); }
export function View_EditGroupComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container m-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-form-field", [["type", "input"]], null, null, null, i2.View_FormFieldComponent_0, i2.RenderType_FormFieldComponent)), i1.ɵdid(2, 114688, null, 0, i3.FormFieldComponent, [i4.FromService, i5.DomSanitizer, i6.Lightbox], { type: [0, "type"], options: [1, "options"] }, null), i1.ɵpod(3, { label: 0, width: 1, path: 2 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-form-field", [["styleInput", "textarea"], ["type", "textarea"]], null, null, null, i2.View_FormFieldComponent_0, i2.RenderType_FormFieldComponent)), i1.ɵdid(5, 114688, null, 0, i3.FormFieldComponent, [i4.FromService, i5.DomSanitizer, i6.Lightbox], { type: [0, "type"], options: [1, "options"] }, null), i1.ɵpod(6, { label: 0, rows: 1, path: 2, width: 3 }), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditGroupComponent_1)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditGroupComponent_2)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "input"; var currVal_1 = _ck(_v, 3, 0, "Nombre", 12, "name"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "textarea"; var currVal_3 = _ck(_v, 6, 0, "Descripci\u00F3n", "4", "description", true); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_4 = _co.form.dirty; _ck(_v, 10, 0, currVal_4); var currVal_5 = !_co.form.dirty; _ck(_v, 12, 0, currVal_5); }, null); }
export function View_EditGroupComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit-group", [], null, null, null, View_EditGroupComponent_0, RenderType_EditGroupComponent)), i1.ɵdid(1, 245760, null, 0, i8.EditGroupComponent, [i9.BroadcastService, i10.GroupsService, i11.FormBuilder, i4.FromService, i12.NgbActiveModal, i13.SwalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditGroupComponentNgFactory = i1.ɵccf("app-edit-group", i8.EditGroupComponent, View_EditGroupComponent_Host_0, { data: "data" }, {}, []);
export { EditGroupComponentNgFactory as EditGroupComponentNgFactory };
