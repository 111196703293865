import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// Services
import { BroadcastService } from '../../../services/broadcast.service';
// Components
import { ErrorCodeCreateEditComponent } from './error-code-create-edit/error-code-create-edit.component';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { ErrrorCodesService } from '../../../services/error-codes.service';
import { SwalService } from '../../../services/swal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PermissionsService } from '../../../services/permissions.service';

@Component({
  selector: 'app-error-codes',
  templateUrl: './error-codes.component.html',
  styleUrls: ['./error-codes.component.scss']
})

export class ErrorCodesComponent implements OnDestroy, OnInit {
  subscriptions: Array<Subscription> = [];
  dataTableConfig: any;
  private readonly baseDataTableConfig = {
    config: {
      base: this.errorCodesService,
      api: 'getErrorCodes',
      order: [2, 'asc'] // Filter by code by default
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Categoría',
        field: 'category',
        type: 'text'
      },
      {
        display: 'Código',
        field: 'code',
        type: 'text'
      },
      {
        display: 'Fecha de alta',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'Acciones',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Editar',
            icon: 'fa fa-pencil',
            event: 'error_codes.edit',
            conditionality: 'true',
            permissions: ['update-error-codes']
          },
          {
            display: 'Eliminar',
            icon: 'fa fa-trash fa-fw',
            event: 'error_codes.delete',
            conditionality: 'true',
            permissions: ['delete-error-codes']
          }
        ]

      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly errorCodesService: ErrrorCodesService,
    private readonly broadcast: BroadcastService,
    public appModal: ModalComponent,
    private readonly swal: SwalService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'error_codes.delete': this.deleteErrorCodeItem(event.data); break;
        case 'error_codes.edit': this.editErrorCodeItem(event.data); break;
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  createErrorCode(data?): void {
    const props: SharedComponent = new SharedComponent(
      ErrorCodeCreateEditComponent,
      {
        errorCodeData: data,
        status: 'create'
      },
      {
        title: 'Crear código de error'
      }
    );
    this.appModal.open(props);
  }

  deleteErrorCodeItem(data): void {
    const swalParams = {
      title: `¿Estás seguro de querer eliminar el código ${data.name}?`
    };

    this.swal.warning(swalParams).then(result => {
      if (result.value) {
        this.subscriptions.push(this.errorCodesService.delete(data.id_error_codes).subscribe((resp: any) => {
          if (resp.success) {
            this.swal.success({ title: 'Código de error eliminado exitosamente' }).then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error('Ocurrio un error al intentar borrar el código de error');
          }
        }));
      }
    });
  }

  editErrorCodeItem(data): void {
    // open modal, passing the context
    const props: SharedComponent = new SharedComponent(
      ErrorCodeCreateEditComponent,
      {
        errorCodeData: data,
        status: 'edit'
      },
      {
        title: 'Códigos de error'
      });
    this.appModal.open(props);
  }

}
