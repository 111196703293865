import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ChargesBacksService = /** @class */ (function () {
    function ChargesBacksService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * getChargesBacksTable
     * @param params .
     * @param filters .
     * @returns JSON
     */
    ChargesBacksService.prototype.getChargesBacksTable = function (params, filters) {
        var id = 0;
        if (filters) {
            id = filters.id_clients;
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/chargesbacks/getChargesBacksDataTable", params);
    };
    ChargesBacksService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChargesBacksService_Factory() { return new ChargesBacksService(i0.ɵɵinject(i1.HttpClient)); }, token: ChargesBacksService, providedIn: "root" });
    return ChargesBacksService;
}());
export { ChargesBacksService };
