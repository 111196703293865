import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FromService } from '../../../../providers/form.service';
import { SwalService } from '../../../../services/swal.service';
import { GroupsService } from '../../../../services/groups.service';
import { BroadcastService } from '../../../../services/broadcast.service';
var PlanData = /** @class */ (function () {
    function PlanData() {
        this.status = false;
    }
    return PlanData;
}());
var mandatoryFields = {
    name: ['', Validators.required],
    deposit: ['', Validators.required],
    installation_fee: ['', Validators.required],
    trial_days: [''],
    trial_days_price: [''],
    monthly_fee: ['', Validators.required],
    sign_into: [''],
    description: ['']
};
var normalFields = {
    name: [''],
    deposit: [''],
    installation_fee: [''],
    trial_days: [''],
    trial_days_price: [''],
    monthly_fee: [''],
    sign_into: [''],
    description: ['']
};
var CreateEditGroupComponent = /** @class */ (function () {
    function CreateEditGroupComponent(activeModal, groupService, broadcast, formBuilder, fromService, swal) {
        this.activeModal = activeModal;
        this.groupService = groupService;
        this.broadcast = broadcast;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.swal = swal;
        this.subscriptions = [];
        this.activePlans = {
            hp: new PlanData(),
            bs: new PlanData(),
            bp: new PlanData()
        };
        this.group_name = false;
        this.groups = [];
        this.plansToDelete = [];
        this.signIntoData = {
            name: '',
            created_at: '',
            deposit: 0,
            installation_fee: 0,
            monthly_fee: 0
        };
        this.form = this.formBuilder.group({
            hs: this.formBuilder.group(mandatoryFields),
            hp: this.formBuilder.group(normalFields),
            bs: this.formBuilder.group(normalFields),
            bp: this.formBuilder.group(normalFields) // Business Premium
        });
    }
    CreateEditGroupComponent.prototype.ngOnInit = function () {
        this.getGroups();
        this.getCurrentGroupInfo();
        this.setGroupData();
        this.fromService.setForm(this.form);
    };
    CreateEditGroupComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    CreateEditGroupComponent.prototype.save = function () {
        if (this.data.status === 'edit') {
            this.updateGroup();
        }
        if (this.data.status === 'create') {
            this.saveGroup();
        }
    };
    CreateEditGroupComponent.prototype.saveGroup = function () {
        var _this = this;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer guardar los datos del grupo?' }).then(function (result) {
                if (result.value) {
                    if (_this.plansToDelete.length > 0) {
                        _this.form.addControl('plansToDelete', new FormControl(_this.plansToDelete));
                    }
                    var formData = _this.removeSignInto(_this.form.value);
                    _this.subscriptions.push(_this.groupService.create(formData).subscribe(function (resp) {
                        if (resp.success) {
                            _this.swal.success().then(function () {
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                        else {
                            _this.swal.error({ title: 'Ocurió un error al guardar los datos' });
                        }
                    }));
                }
            });
        }
    };
    CreateEditGroupComponent.prototype.updateGroup = function () {
        var _this = this;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del grupo?' }).then(function (result) {
                if (result.value) {
                    if (_this.plansToDelete.length > 0) {
                        _this.form.addControl('plansToDelete', new FormControl(_this.plansToDelete));
                    }
                    var formData = _this.removeSignInto(_this.form.value);
                    _this.subscriptions.push(_this.groupService.update(_this.data.editData.id_groups, formData).subscribe(function (resp) {
                        if (resp.success) {
                            _this.swal.success().then(function () {
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                        else {
                            _this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
                        }
                    }));
                }
            });
        }
    };
    CreateEditGroupComponent.prototype.getGroups = function () {
        var _this = this;
        this.subscriptions.push(this.groupService.listGroups().subscribe(function (resp) {
            if (resp.success) {
                _this.groups = resp.response;
            }
        }));
    };
    CreateEditGroupComponent.prototype.deletePlan = function (plan) {
        var _this = this;
        if (plan.id_groups && plan.id_groups > 0 && !this.plansToDelete.includes(plan.id_groups)) {
            var plans_1 = { 2: 'hp', 3: 'bs', 4: 'bp' };
            this.swal.warning({ title: '¿Esta seguro de querer borrar los datos de este plan?' }).then(function (res) {
                if (res.value) {
                    var selectedPlan = plans_1[plan.type];
                    _this.plansToDelete.push(plan.id_groups);
                    _this.activePlans[selectedPlan].status = false;
                    _this.form.get(selectedPlan).reset();
                    _this.form.get(selectedPlan).markAsDirty();
                }
            });
        }
    };
    CreateEditGroupComponent.prototype.setGroupData = function () {
        var _this = this;
        if (this.data.status === 'edit') {
            this.subscriptions.push(this.groupService.show(this.data.editData.id_groups).subscribe(function (resp) {
                _this.group = resp.response;
                _this.form.controls.hs.patchValue(_this.reduceGroupAmount(_this.group));
                _this.group.plans.map(function (group) {
                    switch (group.type) {
                        case 2:
                            var hp = _this.activePlans.hp;
                            hp.id_groups = group.id_groups;
                            hp.type = group.type;
                            hp.status = true;
                            _this.form.controls.hp.patchValue(_this.reduceGroupAmount(group));
                            break;
                        case 3:
                            var bs = _this.activePlans.bs;
                            bs.id_groups = group.id_groups;
                            bs.type = group.type;
                            bs.status = true;
                            _this.form.controls.bs.patchValue(_this.reduceGroupAmount(group));
                            break;
                        case 4:
                            var bp = _this.activePlans.bp;
                            bp.id_groups = group.id_groups;
                            bp.type = group.type;
                            bp.status = true;
                            _this.form.controls.bp.patchValue(_this.reduceGroupAmount(group));
                            break;
                        default: break;
                    }
                });
            }));
        }
    };
    // tslint:disable-next-line: prefer-function-over-method
    CreateEditGroupComponent.prototype.reduceGroupAmount = function (group) {
        var _group = tslib_1.__assign({}, group);
        Object.keys(_group).forEach(function (key) {
            if (key === 'monthly_fee' || key === 'deposit' || key === 'trial_days_price' || key === 'installation_fee') {
                if (_group[key]) {
                    _group[key] = Number((_group[key]) / 100).toFixed(2);
                }
            }
        });
        return _group;
    };
    CreateEditGroupComponent.prototype.removeSignInto = function (formValues) {
        var newValues = tslib_1.__assign({}, formValues);
        var notValidValues = ['', null];
        if (!newValues.hs.sign_into) {
            delete newValues.hs.sign_into;
        }
        for (var _i = 0, _a = Object.entries(newValues); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (value['monthly_fee'] <= 0 || notValidValues.includes(value['monthly_fee'])) {
                delete newValues[key];
            }
        }
        return newValues;
    };
    CreateEditGroupComponent.prototype.getCurrentGroupInfo = function () {
        var _this = this;
        this.subscriptions.push(this.form.valueChanges.subscribe(function (fields) {
            if (fields.hs.name) {
                _this.group_name = true;
            }
            if (fields.hs.sign_into) {
                var group = _this.groups.find(function (item) { return item.id_groups === fields.hs.sign_into; });
                _this.signIntoData = tslib_1.__assign({}, _this.reduceGroupAmount(group));
            }
            else {
                _this.signIntoData = tslib_1.__assign({}, _this.form.controls.hs.value);
            }
        }));
    };
    return CreateEditGroupComponent;
}());
export { CreateEditGroupComponent };
