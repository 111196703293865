<div class="modal-body">
    <div class="container">
        <div *ngIf="data.type === 'clientExtraImage'">
            <app-form-field type="select" [options]="{
                label: 'Categoria',
                path: 'image_category',
                droptext: 'Selecciona la categoria',
                elements: imageCategories
            }"></app-form-field>
            <div *ngIf="newImageCategory">
                <app-form-field type="input" [options]="{label: 'Nueva categoria', path: 'new_image_category_name'}"></app-form-field>
            </div>
        </div>
        <app-form-field type="image" [options]="{
                                width: '0',
                                path: 'photos',
                                image: this.image,
                                text:'Subir imagenes',
                                class: 'image-preview',
                                buttonVisible: 'true',
                                multiple: this.multiple,
                                blob: 'true'
                            }">
        </app-form-field>
    </div>
</div>

<br>
<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="save()">Guardar</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>