<div class="row ng-scope">
    <div class="col-md-6">
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="row">
            <div class="col-md-12">
              <i>Grupos</i>
            </div>
          </div>
        </div>
        <div class="panel-body">
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <tbody>
                <tr *ngFor="let data of groups">
                  <td>{{data.name}}</td>
                  <td class="selection"><button type="button" class="btn btn-outline-success" (click)="selectGroup(data)">Seleccionar</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="panel panel-default">
        <div class="panel-heading">
          <div class="row">
            <div class="col-md-12">
              <i>Grupos seleccionados</i>
            </div>
          </div>
        </div>
        <div class="panel-body">
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <tbody>
                <tr *ngFor="let group of selectedGroups">
                  <td>{{group.name}}</td>
                  <td class="remove"><button type="button" class="btn btn-outline-success" (click)="removeGroup(group)">Remover</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <label class="control-label">Mensaje</label>
      <app-form-field type="textarea" styleInput="textarea" [options]="{rows: '4', path:'message', width: true}"></app-form-field>
    </div>
    <div class="col-md-offset-10 col-md-2">
      <button class="btn btn-info" (click)="notifyGroup()"><i class="fa fa-send"></i>Notificar
      </button>
    </div>
  </div>