import { Component, OnInit, OnDestroy } from '@angular/core';
import { SatisfactionService } from '../../../../services/satisfaction.service';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../../../services/broadcast.service';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { SharedComponent } from '../../../../model/shared-component';
import { SwalService } from '../../../../services/swal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SatisfactionFormComponent } from './satisfaction-form/satisfaction-form.component';
import { PermissionsService } from '../../../../services/permissions.service';

@Component({
  selector: 'app-satisfaction-table',
  templateUrl: './satisfaction-table.component.html',
  styleUrls: ['./satisfaction-table.component.scss']
})
export class SatisfactionTableComponent implements OnInit, OnDestroy {
  broadcast$: Subscription;
  dataTableConfig: any;
  
  private readonly baseDataTableConfig = {
    config: {
      base: this.satisfactionService,
      api: 'getSatisfaction'
    },
    columns: [
      {
        display: 'Período',
        field: 'date',
        type: 'date'
      },
      {
        display: '% Satisfacción',
        field: 'satisfaction',
        type: 'percent'
      },
      {
        display: 'Acciones',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Editar',
            icon: 'fa fa-pencil',
            event: 'satisfaction.edit',
            conditionality: 'true',
            permissions: ['update-satisfaction']
          },
          {
            display: 'Eliminar',
            icon: 'fa fa-trash fa-fw',
            event: 'satisfaction.delete',
            conditionality: 'true',
            permissions: ['delete-satisfaction']
          }
        ]

      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    private readonly satisfactionService: SatisfactionService,
    private readonly broadcast: BroadcastService,
    private readonly swal: SwalService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.broadcast$ = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'satisfaction.delete': this.deleteSatisfactionItem(event.data); break;
        case 'satisfaction.edit': this.editSatisfactionItem(event.data); break;
      }
    });
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    this.broadcast$.unsubscribe();
  }

  createSatisfactionItem(data?): void {
    const props: SharedComponent = new SharedComponent(
      SatisfactionFormComponent,
      {
        satisfactionData: data,
        status: 'create'
      },
      {
        title: 'Crear entrada de satisfacción'
      }
    );
    this.appModal.opensm(props);
  }

  deleteSatisfactionItem(data): void {
    const swalParams = {
      title: `¿Estás seguro de querer eliminar la satisfacción del período ${data.date}?`
    };

    this.swal.warning(swalParams).then(async result => {
      if (result.value) {
        const resp = await this.satisfactionService.delete(data.id_satisfaction).toPromise();
        if (resp && resp.success) {
          this.swal.success({ title: 'Satisfacción eliminada exitosamente' }).then(() => {
            this.activeModal.dismiss();
            this.broadcast.reloadDataTable();
          });
        } else {
          this.swal.error('Ocurrio un error al intentar borrar la satisfacción');
        }
      }
    });
  }

  editSatisfactionItem(data): void {
    // open modal, passing the context
    const props: SharedComponent = new SharedComponent(
      SatisfactionFormComponent,
      {
        satisfactionData: data,
        status: 'edit'
      },
      { 
        title: 'Satisfacción del cliente'
      });
    this.appModal.opensm(props);
  }

}
