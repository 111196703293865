import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MoneySpentCampaignService } from '../../../../../services/money-spent-campaign.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FromService } from '../../../../../providers/form.service';
import { SwalService } from '../../../../../services/swal.service';
import { BroadcastService } from '../../../../../services/broadcast.service';

@Component({
  selector: 'app-money-spent-campaign-form',
  templateUrl: './money-spent-campaign-form.component.html',
  styleUrls: ['./money-spent-campaign-form.component.scss']
})
export class MoneySpentCampaignFormComponent implements OnInit {
  @Input() data: any;
  cashItem: any;
  form: FormGroup = this.formBuilder.group({
    campaign_id: ['', Validators.required],
    balance: ['', Validators.required],
    campaign_name: ['', Validators.required],
    init_date: ['', Validators.required],
    end_date: ['', Validators.required]
  });
  campaigns = [];
  fullCampaigns = [];
  isEdit = false;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly moneySpentCampaignService: MoneySpentCampaignService,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService
  ) { }

  async ngOnInit(): Promise<any> {
    this.moneySpentCampaignService.getCampaigns().subscribe(res => {
      if (res.success) {
        this.fullCampaigns = res.response.data;
        if (this.campaigns.length === 0) {
          for (const option of res.response.data) {
            this.campaigns.push({ id: option.id, name: `${option.id} - ${option.name}` });
          }
        }

        if (this.data.status === 'create') {
          this.campaigns = this.campaigns.filter(c => {
            let isCampaignIdInDatabase = false;
            for (const campaignFromTable of this.data.campaignData) {
              if (c.id === campaignFromTable.campaign_id) {
                isCampaignIdInDatabase = true;
                break;
              }
            }
            if (!isCampaignIdInDatabase) {
              return true;
            }
          });
        }
      }
    });
    this.fromService.setForm(this.form);
    if (this.data.status === 'edit') {
      this.isEdit = true;
      try {
        const resp = await this.moneySpentCampaignService.show(this.data.campaignData.id).toPromise();
        if (resp && resp.success) {
          this.cashItem = resp.response;
          Object.keys(this.cashItem).forEach(key => {
            if (this.cashItem.hasOwnProperty(key) && !!this.form.controls[key]) {
              this.form.controls[key].setValue(this.cashItem[key]);
            }
          });
        }
      } catch (error) {
        this.swal.error({ title: 'Ocurió un error, favor de intentar de nuevo', text: '' });
      }
    }

    this.form.get('campaign_id').valueChanges.subscribe(value => {
      for (const item of this.fullCampaigns) {
        if (item.id === value) {
          // this.form.get('init_date').setValue('');
          const _date = new Date(item.start_time);
          const startTime = {
            year: _date.getFullYear(),
            month: _date.getMonth() + 1,
            day: _date.getDate() + 1
          };
          const _stopDate = new Date(item.stop_time);
          const stopTime = {
            year: _stopDate.getFullYear(),
            month: _stopDate.getMonth() + 1,
            day: _stopDate.getDate() + 1
          };
          this.form.get('init_date').setValue(startTime);
          this.form.get('end_date').setValue(stopTime);
          this.form.get('campaign_name').setValue(item.name);
          if (item.insights && item.insights[0]) {
            this.form.get('balance').setValue(item.insights[0].spend);
          }
        }
      }
    });
  }

  save(): void {
    if (this.data.status === 'edit') {
      this.updateItem();
    }

    if (this.data.status === 'create') {
      this.saveItem();
    }
  }

  saveItem(): void {
    // this.form.get('category').value;
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar el siguiente balance?', text: '' }).then(async result => {
        if (result.value) {
          const resp = await this.moneySpentCampaignService.create(this.form.value).toPromise();
          if (resp && resp.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurió un error al guardar los datos' });
          }
        }
      });
    }
  }

  updateItem(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos de este balance?', text: '' }).then(async result => {
        if (result.value) {
          const resp = await this.moneySpentCampaignService.edit(this.data.campaignData.id, this.form.value).toPromise();
          if (resp.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
          }
        }
      });
    }
  }

}
