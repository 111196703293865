/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bad-status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./bad-status.component";
var styles_BadStatusComponent = [i0.styles];
var RenderType_BadStatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BadStatusComponent, data: {} });
export { RenderType_BadStatusComponent as RenderType_BadStatusComponent };
function View_BadStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Invalidaci\u00F3n"]))], null, null); }
function View_BadStatusComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancelaci\u00F3n"]))], null, null); }
function View_BadStatusComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Rechazo"]))], null, null); }
export function View_BadStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "dl", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BadStatusComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BadStatusComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BadStatusComponent_3)), i1.ɵdid(8, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.type; _ck(_v, 2, 0, currVal_0); var currVal_1 = "invalidations"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "cancellations"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "rejections"; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.item.reasons; _ck(_v, 11, 0, currVal_4); }); }
export function View_BadStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bad-status", [], null, null, null, View_BadStatusComponent_0, RenderType_BadStatusComponent)), i1.ɵdid(1, 114688, null, 0, i3.BadStatusComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BadStatusComponentNgFactory = i1.ɵccf("app-bad-status", i3.BadStatusComponent, View_BadStatusComponent_Host_0, { item: "item" }, {}, []);
export { BadStatusComponentNgFactory as BadStatusComponentNgFactory };
