import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '../../components/components.module';
import { AdministrationRoutes } from './administration.routing';
import { AdministratorsComponent } from './administrators/administrators.component';
import { AppUsageComponent } from './app-usage/app-usage.component';
import { UsersComponent } from './users/users.component';
import { RolesComponent } from './roles/roles.component';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
    AdministratorsComponent,
    AppUsageComponent,
    UsersComponent,
    RolesComponent
  ],
  exports: [AdministratorsComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule.forChild(AdministrationRoutes),
    NgxPermissionsModule.forChild()
  ]
})
export class AdministrationModule { }
