import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CommissionsService {
  baseUrl = `${environment.apiUrl}`;
  
  constructor(private readonly http: HttpClient) { }

  getCommissionsTable(params?, filters?): Observable<any> {
    if (filters) {
      Object.assign(params, filters);
      // params.from = filters.from;
      // params.to = filters.to;
      // params.is_active = filters.is_active;
      // params.paid_at = filters.paid_at;
      // params.level = filters.level;
    }

    return this.http.post(`${this.baseUrl}/commissions/getCommissions`, params);
  }

  getDeclined(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/commissions/getDeclined/${id}`);
  }

  registerAsPaid(id, params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/commissions/${id}/registerAsPaid`, params);
  }
  
  clientCommissions(id): Observable<any> {
    const commissions = this.http.get(`${this.baseUrl}/commissions/getCommissions/${id}`);
    const maxCommissions = this.http.get(`${this.baseUrl}/commissions/getLoosingCommissions/${id}`);

    return forkJoin([commissions, maxCommissions]);
  }
}
