<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <h2>Tickets completados</h2>
    <canvas baseChart 
            width="450" 
            height="100"
            [datasets]="ticketsCompletedChartData"
            [colors]="barChartColors"
            [chartType]="lineChartType"
            [labels]="labels"
            [options]="options"></canvas>
</div>
