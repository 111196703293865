<!-- <div class="card text-white investor-kpi-container" [ngClass]=[backGround]>
    <div class="card-body pb-0 text-right">
        <div class="row">
            <div class="col-sm-2 text-left">
                <i class="fa fa-2x" [ngClass]="[icon]"></i>
            </div>
            <div class="col-sm-10 text-right" style="padding: 0;">
                <h6>{{ title }}</h6>
                <h5>{{ value }}</h5>
            </div>
        </div>
    </div>
</div> -->
<div class="rounded-circle investor-kpi-container text-center" [ngClass]=[backGround]>
    <div class="data-container">
         <div>
            <span class="kpi-title">{{ title }}</span>
         </div>
         <div>
            <span class="kpi-value">{{ value }}</span>
        </div>
        <div class="kpi-description">
            {{ description }}
        </div>
    </div>
</div>
