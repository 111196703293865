import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var RolesService = /** @class */ (function () {
    function RolesService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    RolesService.prototype.getRoles = function (params) {
        return this.http.post(this.baseUrl + "/roles/get_roles", params);
    };
    RolesService.prototype.show = function (rolId) {
        return this.http.get(this.baseUrl + "/roles/" + rolId);
    };
    RolesService.prototype.createRole = function (params) {
        return this.http.post(this.baseUrl + "/roles", params);
    };
    RolesService.prototype.updateRole = function (rolId, params) {
        return this.http.put(this.baseUrl + "/roles/" + rolId, params);
    };
    RolesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RolesService_Factory() { return new RolesService(i0.ɵɵinject(i1.HttpClient)); }, token: RolesService, providedIn: "root" });
    return RolesService;
}());
export { RolesService };
