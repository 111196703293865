<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <h2>App usage</h2>
</div>

<div class="container-fluid border-bottom bg-white p-3 mb-4" *ngIf="app_data">
    <div class="row">
        <!--     <div class="col-12 col-sm-6 col-md-6 col-lg-3">
                <div class="card text-white bg-danger">
                    <div class="card-body pb-0 text-right">
                        <div class="row">
                            <div class="col-sm-2 text-left">
                                <i class="fa fa-hand-peace-o fa-2x"></i>
                            </div>
                            <div class="col-sm-10 text-right">
                                <h5>Invitados</h5>
                                <h3>{{app_data.guestTotal}}</h3>
                            </div>
                        </div>
        
                    </div>
                </div>
            </div> -->
        
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                <div class="card text-white bg-info">
                    <div class="card-body pb-0 text-right">
                        <div class="row">
                            <div class="col-sm-2 text-left">
                                <i class="fa fa-group fa-2x"></i>
                            </div>
                            <div class="col-sm-10 text-right">
                                <h5>Clientes que han usado la app</h5>
                                <h3>{{app_data.recordsTotal}}</h3>
                            </div>
                        </div>
        
                    </div>
                </div>
            </div>
        
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                <div class="card text-white bg-warning">
                    <div class="card-body pb-0 text-right">
                        <div class="row">
                            <div class="col-sm-2 text-left">
                                <i class="fa fa-apple fa-2x"></i>
                            </div>
                            <div class="col-sm-10 text-right">
                                <h5>Registros con iOS</h5>
                                <h3>{{registrationData.ios}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="col-12 col-sm-6 col-md-6 col-lg-4">
                <div class="card text-white bg-success">
                    <div class="card-body pb-0 text-right">
                        <div class="row">
                            <div class="col-sm-2 text-left">
                                <i class="fa fa-android fa-2x"></i>
                            </div>
                            <div class="col-sm-10 text-right">
                                <h5>Registros con Android</h5>
                                <h3>{{registrationData.android}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>
    <h2>Clientes</h2>
    <app-datatable [options]="dataTableConfig"></app-datatable>
</div>