<div class="container-fluid">
    <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one">
        <ngb-panel title="Referido" id="config-panel-referral">
            <ng-template ngbPanelContent>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="input-group">
                            <app-form-field type="email"
                                [options]="{label:'', path: 'internalData.referral_email', placeholder:'aguagente@aguagratis.com'}">
                            </app-form-field>
                            <span class="input-group-btn">
                            <button type="button" (click)="getRefeer()" class="btn btn-primary"><i
                                    class="fa fa-search"></i></button>
                            </span>
                        </div>
                    </div>
                    <div class="col-sm-6" *ngIf="show_plans">
                        <app-form-field type="select" [options]="{
                            label: 'Seleccionar el tipo de contrato',
                            path: 'internalData.contract_type',
                            droptext: 'Seleccionar el tipo de contrato',
                            elements: contractsTypes
                        }"></app-form-field>
                        <div *ngIf="currentPlans.length > 0">
                            <app-form-field type="select" [options]="{
                                label: 'Seleccionar plan',
                                path: 'internalData.plan',
                                droptext: 'Seleccionar plan',
                                elements: currentPlans
                            }"></app-form-field>
                        </div>
                        <div *ngIf="selectedPlan">
                            <dl class="row">
                                <dt class="col-sm-3">Plan: </dt>
                                <dd class="col-sm-9">{{selectedPlanInfo.name}} </dd>
                                <dt class="col-sm-3">Depósito: </dt>
                                <dd class="col-sm-9">{{(selectedPlanInfo.deposit) / 100 | currency}} </dd>
                                <dt class="col-sm-3">Instalación: </dt>
                                <dd class="col-sm-9">{{(selectedPlanInfo.installation_fee) / 100 | currency}} </dd>
                                <dt class="col-sm-3">Mensualidad: </dt>
                                <dd class="col-sm-9">{{(selectedPlanInfo.monthly_fee) / 100 | currency}} </dd>
                                <dt class="col-sm-3">Descripción: </dt>
                                <dd class="col-sm-9">{{selectedPlanInfo.description}} </dd>
                            </dl>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
        <ngb-panel title="Instalación" id="config-panel-instalation" [disabled]="tabsStatus.instalationDisabled">
            <ng-template ngbPanelContent>
                <div class="row">
                    <div class="col">
                        <app-form-field type="switch" [options]="{
                            label: '¿Añadir responsabilidad social?', 
                            path: 'responsabilidad', 
                            trueValue: true, 
                            falseValue: false, 
                            trueCaption: 'Si',
                            falseCaption: 'No'}">
                        </app-form-field>
                        <br>
                        <div *ngFor="let category of categories">
                            <div *ngIf="category.name !== 'Material'">
                                <h2>{{ category.name }}</h2>
                                <p>{{ category.description }}</p>
                                <div *ngFor="let element of category.elements">
                                    <app-form-field type="checkbox" (checkboxValue)="setExtras($event)" [options]="{
                                        id:element,
                                        value: element,
                                        label: element.name + ' <strong>$' + element.price + '</strong>',
                                        path: 'elements'
                                    }">
                                    </app-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <dl class="row">
                            <dt class="col-sm-3">Depósito: </dt>
                            <dd class="col-sm-9">{{(selectedPlanInfo.deposit) / 100 | currency}} </dd>
                            <dt class="col-sm-3">Instalación: </dt>
                            <dd class="col-sm-9">{{(selectedPlanInfo.installation_fee) / 100 | currency}} </dd>
                            <dt class="col-sm-3">Mensualidad: </dt>
                            <dd class="col-sm-9">{{contractTotal().clientMonthlyFee / 100 | currency}} </dd>
                            <dt class="col-sm-3" *ngIf="contractTotal().sr">Responsabilidad social: </dt>
                            <dd class="col-sm-9" *ngIf="contractTotal().sr">{{contractTotal().sr / 100 | currency}}
                            </dd>
                            <dt class="col-sm-3" *ngIf="selectedPlanInfo.trial_days > 0">Promoción: </dt>
                            <dd class="col-sm-9" *ngIf="selectedPlanInfo.trial_days > 0">{{selectedPlanInfo.trial_days}}
                                días X
                                {{selectedPlanInfo.trial_days_price / 100 | currency}}</dd>
                            <dt class="col-sm-3">Total: </dt>
                            <dd class="col-sm-9">{{contractTotal().total | currency}}</dd>
                        </dl>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
        <ngb-panel title="Datos del cliente" id="config-panel-client-data" [disabled]="tabsStatus.personalDataDisabled">
            <ng-template ngbPanelContent>
                <div class="row">
                    <div class="col">
                        <app-form-field type="input" [options]="{label: 'Nombre', path: 'client.name'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Teléfono', path: 'client.phone'}">
                        </app-form-field>
                        <app-form-field type="email" [options]="{label: 'Email', path: 'client.email'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Colonia', path: 'client.colony'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Calle', path: 'client.address'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Municipio', path: 'client.county'}">
                        </app-form-field>
                    </div>
                    <div class="col">
                        <app-form-field type="input"
                            [options]="{label: 'Número exterior', path: 'client.outdoor_number'}">
                        </app-form-field>
                        <app-form-field type="input"
                            [options]="{label: 'Número interior', path: 'client.inside_number'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Entre', path: 'client.between_streets'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Código postal', path: 'client.postal_code'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Estado', path: 'client.state'}">
                        </app-form-field>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-3">
                        <app-form-field type="image" [options]="{
                            width: '0',
                            path: 'images.id',
                            image: this.documents.id,
                            text:'Subir ID',
                            class: 'image-preview',
                            buttonVisible: 'true',
                            zoom: 'true'
                        }"></app-form-field>
                    </div>
                    <div class="col-md-3">
                        <app-form-field type="image" [options]="{
                            width: '0',
                            path: 'images.id_reverse',
                            image: this.documents.id_reverse,
                            text:'Subir ID (atras)',
                            class: 'image-preview',
                            buttonVisible: 'true',
                            zoom: 'true'
                        }"></app-form-field>
                    </div>
                    <div class="col-md-3">
                        <app-form-field type="image" [options]="{
                        width: '0',
                        path: 'images.proof_of_address',
                        image: this.documents.proof_of_address,
                        text:'Comprobante',
                        class: 'image-preview',
                        buttonVisible: 'true',
                        zoom: 'true'
                    }"></app-form-field>
                    </div>
                    <div class="col-md-3">
                        <app-form-field type="image" [options]="{
                        width: '0',
                        path: 'images.profile',
                        image: this.documents.profile,
                        text: 'Perfil',
                        class: 'image-preview',
                        buttonVisible: 'true',
                        zoom: 'true'
                    }"></app-form-field>
                    </div>
                </div>
            </ng-template>
        </ngb-panel>
        <ngb-panel title="Opción de pago" id="config-payment-option" [disabled]="tabsStatus.cardDataDisabled">
            <ng-template ngbPanelContent>
                <app-form-field type="input" [options]="{
                    label: 'Número de la tarjeta', 
                    length: 16,
                    path: 'internalData.cardData.card_number'
                }"></app-form-field>
                <app-form-field type="input" [options]="{label: 'CVC', path: 'internalData.cardData.cvc'}">
                </app-form-field>
                <app-form-field type="select" [options]="{
                    label: 'Mes de expiración',
                    path: 'internalData.cardData.expiration_month',
                    droptext: 'Selecciona el mes de expiración',
                    elements: months
                }"></app-form-field>
                <app-form-field type="select" [options]="{
                    label: 'Año de expiración',
                    path: 'internalData.cardData.expiration_year',
                    droptext: 'Selecciona el año de expiración',
                    elements: years
                }"></app-form-field>
                <div class="text-right" *ngIf="this.form.get('internalData.cardData')">
                    <button *ngIf="this.form.get('internalData.cardData').dirty" type="button"
                        class="btn btn-outline-danger" (click)="clearCardData()">Limpiar datos de pago</button>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>

    <br>
</div>

<br>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="registerClient()">Registrar cliente</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>