import { Component, OnInit, Input } from '@angular/core';
import { HelpersService } from '../../../../../services/helpers.service';

@Component({
  selector: 'app-datatable-column-ticket-client',
  templateUrl: './ticket-client.component.html',
  styleUrls: ['./ticket-client.component.scss']
})
export class TicketClientComponent implements OnInit {
  constructor (private readonly helpersService: HelpersService) {}
  @Input() data: any;

  ngOnInit(): void {
    //
  }

  copyToClipboard(text: string) {
    this.helpersService.copyToClipboard(text);
  }

}
