<div class="modal-body" *ngIf="client !== undefined">
    <dl class="row">
        <dt class="col-sm-3">Nombre</dt>
        <dd class="col-sm-9">{{client.name}}</dd>
        <dt class="col-sm-3">Email</dt>
        <dd class="col-sm-9">{{client.email}}</dd>
        <dt class="col-sm-3">Plan actual</dt>
        <dd class="col-sm-9">{{current_plan_type}}</dd>
        <dt class="col-sm-3">Mensualidad actual</dt>
        <dd class="col-sm-9">{{monthly_fee | currency}}</dd>
    </dl>
    <div class="text-center">
        <button type="button" class="btn btn-outline-danger" (click)="updatePlan()">Actualizar a {{new_plan_info.name}}</button>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
</div>