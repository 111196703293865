import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-card-failed-history',
  templateUrl: './card-failed-history.component.html',
  styleUrls: ['./card-failed-history.component.scss']
})
export class CardFailedHistoryComponent implements OnInit {
  @Input() data: any;
  failed_cards: any;
  client: any;

  constructor(
    public activeModal: NgbActiveModal
  ) { }
  
  ngOnInit(): void {
    this.client = this.data;
    this.failed_cards = this.client.card_failed_record;
  }

}
