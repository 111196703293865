import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[shared-component]'
})
export class SharedComponentDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
