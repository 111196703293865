import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreviousTicketDataComponent } from './previous-ticket-data/previous-ticket-data.component';
import { ClientContractDataComponent } from './client-contract-data/client-contract-data.component';


const sharedModules = [
  PreviousTicketDataComponent,
  ClientContractDataComponent
];

@NgModule({
  declarations: [...sharedModules],
  exports:[...sharedModules],
  entryComponents:[],
  imports: [
    CommonModule
  ]
})
export class SharedModule { }
