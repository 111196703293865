import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MoneySpentCampaignService = /** @class */ (function () {
    function MoneySpentCampaignService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    MoneySpentCampaignService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/admin_dashboard/money-spent-campaign", params);
    };
    MoneySpentCampaignService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/admin_dashboard/money-spent-campaign/" + id);
    };
    MoneySpentCampaignService.prototype.edit = function (id, params) {
        return this.http.put(this.baseUrl + "/admin_dashboard/money-spent-campaign/" + id, params);
    };
    MoneySpentCampaignService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/admin_dashboard/money-spent-campaign/" + id);
    };
    MoneySpentCampaignService.prototype.index = function (params) {
        return this.http.get(this.baseUrl + "/admin_dashboard/money-spent-campaign", params);
    };
    MoneySpentCampaignService.prototype.getCampaigns = function () {
        return this.http.get(this.baseUrl + "/admin_dashboard/get-campaigns");
    };
    MoneySpentCampaignService.prototype.campaigns = function () {
        return this.http.get(this.baseUrl + "/admin_dashboard/get-campaigns");
    };
    MoneySpentCampaignService.prototype.getMoneySpentCampaign = function (params) {
        return this.http.post(this.baseUrl + "/admin_dashboard/get-money-spent-campaign", params);
    };
    MoneySpentCampaignService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MoneySpentCampaignService_Factory() { return new MoneySpentCampaignService(i0.ɵɵinject(i1.HttpClient)); }, token: MoneySpentCampaignService, providedIn: "root" });
    return MoneySpentCampaignService;
}());
export { MoneySpentCampaignService };
