import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VendorsService = /** @class */ (function () {
    function VendorsService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * show
     * Petición GET para obtener la información de un vendor mediante el id
     * @param id del vendor
     * @returns 200 | 404
     */
    VendorsService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/vendors/" + id);
    };
    /**
     * getAll
     * Obtiene todos lo vendors activos en el sistema.
     */
    VendorsService.prototype.getAll = function () {
        return this.http.get(this.baseUrl + "/vendors");
    };
    VendorsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VendorsService_Factory() { return new VendorsService(i0.ɵɵinject(i1.HttpClient)); }, token: VendorsService, providedIn: "root" });
    return VendorsService;
}());
export { VendorsService };
