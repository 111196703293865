<div class="modal-body">
    <div class="container">
        <app-form-field type="input" [options]="{label:'Nombre', path: 'name'}"></app-form-field>
        <app-form-field type="select" [options]="{label: 'Categoria', droptext: '-Selecciona la categoria-', elements: category, path:'category'}"></app-form-field>
        <app-form-field type="input" [options]="{label:'Código', path: 'code'}"></app-form-field>
    </div>
</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid" (click)="save()">Guardar</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>
