import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../providers/form.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { SwalService } from '../../../services/swal.service';
import { Subscription } from 'rxjs';
import { TicketsService } from '../../../services/tickets.service';
import { ClientsService } from '../../../services/clients.service';

@Component({
  selector: 'app-upload-photos',
  templateUrl: './upload-photos.component.html',
  styleUrls: ['./upload-photos.component.scss']
})
export class UploadPhotosComponent implements OnInit, OnDestroy {
  @Input() data: any;
  image: any;
  multiple: boolean = false;
  newImageCategory: boolean = false;
  imageCategories: Array<any> = [{ id: 'newCategory', name: 'Nueva categoria' }];
  subscriptions: Array<Subscription> = [];
  form: FormGroup = this.formBuilder.group({
    base64: [true],
    photos: ['', Validators.required]
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService,
    private readonly ticketsService: TicketsService,
    private readonly clientsService: ClientsService
  ) { }

  ngOnInit(): void {
    this.addFormularyEntries();
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * save
   * Envia una petición post con las imagenes añadidas
   */
  save(): void {
    const swalParams = {
      title: '¿Esta seguro de querer guardar las siguientes imagenes?'
    };
    if (this.form.valid) {
      this.swal.warning(swalParams).then(result => {
        if (result.value) {
          switch (this.data.type) {
            case 'clientExtraImage': this.saveClientExtraImage(); break;
            default: this.saveImageToTicket(); break;
          }
        }
      });
    }
  }

  /**
   * addFormularyEntries
   * Añade entradas al formulario dependiendo del tipo de vista
   */
  private addFormularyEntries(): void {
    if (this.data.type === 'clientExtraImage') {
      this.getImagesExtraCategories();
      this.form.addControl('image_category', this.formBuilder.control('', [Validators.required]));
      this.evaluateFormulary();
    }
  }

  /**
   * evaluateFormulary
   * Si se recibe el parametreo this.data.type === clientExtraImage
   * evalua el valor de image_category para determinar si se va a
   * añadir una nueva categoria o se usara una existente
   */
  private evaluateFormulary(): void {
    this.subscriptions.push(this.form.get('image_category').valueChanges.subscribe(image_category_value => {
      if (image_category_value === 'newCategory') {
        this.newImageCategory = true;
        this.form.addControl('new_image_category_name', this.formBuilder.control('', [Validators.required]));
      } else {
        if (this.form.get('new_image_category_name')) {
          this.newImageCategory = false;
          this.form.removeControl('new_image_category_name');
        }
      }
    }));
  }

  /**
   * saveImageToTicket
   * Envia una petición para adjuntar una o varias imagenes a un ticket.
   */
  private saveImageToTicket(): void {
    this.subscriptions.push(
      this.ticketsService.addPhotos(this.form.value, this.data.id_tickets).subscribe((resp: any) => {
        this.handleResponse(resp);
      })
    );
  }

  /**
   * saveClientExtraImage
   * Envia una petición para guardar una imagen extra a un cliente
   */
  private saveClientExtraImage(): void {
    this.subscriptions.push(
      this.clientsService.addClientExtraImages(this.form.value, this.data.client.id_clients).subscribe((resp: any) => {
        this.handleResponse(resp);
      })
    );
  }

  handleResponse(resp): void {
    if (resp.success) {
      this.swal.success().then(() => {
        this.activeModal.dismiss();
        this.broadcast.reloadDataTable();
      });
    } else {
      this.swal.error({ title: 'Ocurió un error al guardar los datos' });
    }
  }

  /**
   * getImagesExtraCategories
   * Obtiene las categorias extra que puede tener una imagen extra
   */
  private getImagesExtraCategories(): void {
    this.subscriptions.push(
      this.clientsService.getImagesExtraCategories().subscribe((resp: any) => {
        if (resp.response) {
          this.imageCategories = [...this.imageCategories, ...resp.response];
        }
      })
    );
  }
}
