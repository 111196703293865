import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FromService } from '../../../providers/form.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { CardsService } from '../../../services/cards.service';
import { CategoriesService } from '../../../services/categories.service';
import { ClientsService } from '../../../services/clients.service';
import { SwalService } from '../../../services/swal.service';

@Component({
  selector: 'app-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss']
})
export class ClientCreateComponent implements OnInit, OnDestroy {
  id: File;
  id_back: File;
  voucher: File;
  profile: File;
  documents = {
    id: '',
    id_reverse: null,
    proof_of_address: null,
    profile: null
  };
  elements = [];
  show_plans = false;
  selectedPlan = false;
  tabsStatus = {
    instalationDisabled: true,
    personalDataDisabled: true,
    cardDataDisabled: true
  };
  contractsTypes = [];
  months = [];
  years = [];
  banks = [
    'VISA',
    'MASTERCARD',
    'AMEX'
  ];
  imgBrand = '';
  brandUppercase = '';
  selectedPlanInfo: any;
  referralGroupInfo: any;
  categories: Array<any>;
  allPlans = [];
  currentPlans = [];
  subscriptions: Array<Subscription> = [];

  form: FormGroup = this.formBuilder.group({
    seller: ['', Validators.required],
    client: this.formBuilder.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      outdoor_number: ['', Validators.required],
      inside_number: [''],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
      email: ['', Validators.required],
      between_streets: ['', Validators.required],
      colony: ['', [Validators.required, Validators.minLength(4)]],
      postal_code: ['', Validators.required],
      state: ['', [Validators.required, Validators.minLength(4)]],
      county: ['', [Validators.required, Validators.minLength(4)]]
    }),
    responsabilidad: [true],
    images: this.formBuilder.group({
      id: [''],
      id_reverse: [''],
      proof_of_address: [''],
      profile: ['']
    }),
    elements: this.elements,
    monthly_installments: [1, Validators.required],
    internalData: this.formBuilder.group({
      contract_type: [''],
      referral_email: [''],
      plan: ['', Validators.required],
      cardData: this.formBuilder.group({
        card_number: [''],
        cvc: [''],
        expiration_month: [''],
        expiration_year: ['']
      })
    })
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly categoriesService: CategoriesService,
    private readonly cardsService: CardsService,
    private readonly clientsService: ClientsService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swalService: SwalService
  ) { }

  ngOnInit(): void {
    const cardsInfo = this.cardsService.setDateCardInfo();
    this.months = cardsInfo.months;
    this.years = cardsInfo.years;
    this.fromService.setForm(this.form);
    this.cardsService.setVendors();
    this.getCategories();
  }

  ngAfterViewChecked(): void {
    if (this.show_plans) {
      this.getSelectedPlanInfo();
    }
    this.checkPersonalDataFormStatus();
    this.updateCardDataValidators();
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * getRefeer
   * Envia una petición GET a la API para obtener la inforamción del grupo y los planes
   * de la cuenta que refirio al cliente del cual se estan capturando los datos.
   * + Si el correo que se envia es vacio busca la cuenta asociada a aguagratis@aguagente.com
   * + Si el correo ingresado no existe devuelve un mensaje de que el correo no es valido.
   */
  getRefeer(): void {
    const refeer = this.form.get('internalData.referral_email').value === '' ?
      'aguagratis@aguagente.com' : this.form.get('internalData.referral_email').value;
    if (refeer) {
      this.subscriptions.push(this.clientsService.getClientByEmail(refeer).subscribe((resp: any) => {
        if (resp.success && resp.response.length > 0) {
          this.form.controls.seller.setValue(resp.response[0].id_clients);
          this.referralGroupInfo = resp.response[0].group;
          this.setContractTypes(this.referralGroupInfo);
          this.tabsStatus.instalationDisabled = false;
          this.tabsStatus.personalDataDisabled = false;
        } else {
          this.swalService.error({ text: 'El email que ingresaste no es válido' });
        }
      }));
    } else {
      this.swalService.error({ text: 'No se pudo determinar el correo' });
    }
  }

  /**
   * Calcula los totales del costo de la instalación
   * El total de la instalación considera los siguientes parametros:
   *  + La primera mensualidad que se le cobrara al cliente esto dependera del valor dado por el grupo del correo del referidor,
   *    Si el grupo bajo el cual se esta registrando el nuevo cliente su trial_days es mayor que 0, entonces se tomara en cuenta
   *    el trial_days_price para la primera mensualidad, en caso contrario se tomara el monthly fee del grupo
   * + La responsabilidad social (sr) en caso de que se haya seleccionado, para la responsabilidad social se toman en cuenta
   *   la primera mensualidad del cliente, asi como el installation_fee y los elementos extras que se hayan decidido seleccionado.
   * @returns objeto con los totales de la primera mensualidad, el total con los extras y si se selecciono el total
   * de la responsabilidad social.
   */
  contractTotal(): Object {
    const totals = {};
    const selectedElements = this.form.get('elements').value;
    let clientMonthlyFee = Number(this.selectedPlanInfo.monthly_fee);
    if (this.selectedPlanInfo.trial_days > 0) {
      clientMonthlyFee = this.selectedPlanInfo.trial_days_price;
    }
    let total = 0;
    const social_responsability = this.form.get('responsabilidad').value;
    const installationFee = Number(this.selectedPlanInfo.installation_fee);
    const deposit = Number(this.selectedPlanInfo.deposit);
    total = installationFee + clientMonthlyFee;

    if (selectedElements !== null) {
      selectedElements.forEach(element => {
        let elementPrice = 0;
        elementPrice = Number(element.price * 100);
        total += elementPrice;
      });
    }

    if (social_responsability) {
      const sr = (total / 1.16) * 0.007;
      total += sr;
      Object.assign(totals, { sr });
    }

    total = Math.round(((total + deposit) / 100) * 100) / 100;

    Object.assign(totals, { total, clientMonthlyFee });

    return totals;
  }

  /**
   * setExtras
   * Setea en el formulario el valor de los materiales extra seleccionados para la instalación.
   * @param event evento recibido por el checkbox
   */
  setExtras(event): void {
    const value = event.value;

    if (this.elements.indexOf(value) > -1) {
      this.elements.splice(this.elements.indexOf(value), 1);
    } else {
      this.elements.push(event.value);
    }
    this.form.controls.elements.setValue(this.elements);
  }

  /**
   * clearCardData
   * Función que permite limpiar unicamente el formulario de las tarjetas 
   * en caso de que no se quiera añadir ninguna.
   */
  clearCardData(): void {
    const cardData = this.form.get('internalData.cardData');
    const card_number = this.form.get('internalData.cardData.card_number');
    const cvc = this.form.get('internalData.cardData.cvc');
    const expiration_month = this.form.get('internalData.cardData.expiration_month');
    const expiration_year = this.form.get('internalData.cardData.expiration_year');
    card_number.clearValidators();
    cvc.clearValidators();
    expiration_month.clearValidators();
    expiration_year.clearValidators();
    cardData.reset();
  }

  /**
   * registerClient
   * Envia una petición POST en un FORM DATA con los datos basicos para generar
   * el registro del cliente en el sistema.
   */
  registerClient(): void {
    this.registerCard().then(response => {
      if (response.status) {
        const cardTokens = response.added_card ? response.data : '';
        const formData = this.fillFormData(cardTokens);
        this.subscriptions.push(this.clientsService.registerClient(formData).subscribe((res: any) => {
          if (res.success) {
            this.swalService.success({ text: 'Contrato generado exitosamente' }).then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swalService.error();
          }
        }));
      } else {
        this.swalService.error({ text: response.data });
      }
    });
  }

  /**
   * SetContractTypes
   * Determina los tipos de contrato que puede llegar a tener el grupo asociado al referidor
   * @param group array que lista todos los planes standard o premium que puede tener los contratos
   * busissnes o premium
   */
  private setContractTypes(group): void {
    if (group && group.plans && group.plans.length > 0) {
      const _group = { ...group };
      this.allPlans = [_group, ...group.plans];
      this.allPlans.forEach(plan => {
        switch (plan.type) {
          case 1:
          case 2:
            if (this.contractsTypes.map(element => element.name).indexOf('Home') === -1) {
              this.contractsTypes.push({ id: 'home', name: 'Home' });
            }
            break;
          case 3:
          case 4:
            if (this.contractsTypes.map(element => element.name).indexOf('Small Business') === -1) {
              this.contractsTypes.push({ id: 'busissnes', name: 'Small Business' });
            }
            break;
        }
      });
      this.show_plans = true;
      this.showPlans(this.allPlans);
    } else {
      this.selectedPlanInfo = group;
      this.form.get('internalData.plan').setValue(group.id_groups);
    }
  }

  /**
   * showPlans
   * Determina y filtra a que tipo de contrato pertenece cada plan en el grupo.
   * @param plans array con todos los planes que tiene el grupo
   */
  private showPlans(plans): void {
    const contratType = this.form.get('internalData.contract_type');
    if (contratType) {
      this.subscriptions.push(contratType.valueChanges.subscribe(contract => {
        const filteredPlans = [];
        switch (contract) {
          case 'home':
            plans.forEach(plan => {
              if (plan.type === 1 || plan.type === 2) {
                filteredPlans.push({ id: plan.id_groups, name: plan.name });
              }
            });
            break;
          case 'busissnes':
            plans.forEach(plan => {
              if (plan.type === 3 || plan.type === 4) {
                filteredPlans.push({ id: plan.id_groups, name: plan.name });
              }
            });
            break;
        }
        this.currentPlans = filteredPlans;
      }));
    }
  }

  /**
   * getSelectedPlanInfo
   * Obtiene la información del plan seleccionado.
   */
  private getSelectedPlanInfo(): void {
    const planValue = this.form.get('internalData.plan');
    if (planValue) {
      this.subscriptions.push(planValue.valueChanges.subscribe(planId => {
        const plans = this.allPlans;
        this.selectedPlanInfo = plans.filter(plan => plan.id_groups === Number(planId))[0];
        this.selectedPlan = this.selectedPlanInfo ? true : false;
      }));
    }
  }

  /**
   * getCategories
   * Obtiene de la BD las categorias de los elementos que se pueden cobrar para una instalación
   */
  private getCategories(): void {
    this.subscriptions.push(this.categoriesService.getCategoriesList().subscribe((resp: any) => {
      if (resp.response.length > 0) {
        this.categories = resp.response;
      } else {
        this.swalService.error({ text: 'No se pudieron obtener las extras' });
      }
    }));
  }

  /**
   * checkPersonalDataFormStatus
   * Revisa el status de validez del subform de clientes para poder determinar si
   * se activa el tab con el formulario para la tarjeta.
   */
  private checkPersonalDataFormStatus(): void {
    const personalDataStatus = this.form.get('client').valid ? false : true;
    this.tabsStatus.cardDataDisabled = personalDataStatus;
  }

  /**
   * updateCardDataValidators
   * Si el formulario de la tarjeta es rellenado añade validadores a todos los campos
   */
  private updateCardDataValidators(): void {
    if (this.form.get('internalData.cardData').dirty) {
      const card_number = this.form.get('internalData.cardData.card_number');
      const cvc = this.form.get('internalData.cardData.cvc');
      const expiration_month = this.form.get('internalData.cardData.expiration_month');
      const expiration_year = this.form.get('internalData.cardData.expiration_year');

      card_number.setValidators([Validators.required, Validators.minLength(15), Validators.maxLength(16)]);
      cvc.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(4)]);
      expiration_month.setValidators(Validators.required);
      expiration_year.setValidators(Validators.required);

      card_number.updateValueAndValidity({ onlySelf: true });
      cvc.updateValueAndValidity({ onlySelf: true });
      expiration_month.updateValueAndValidity({ onlySelf: true });
      expiration_year.updateValueAndValidity({ onlySelf: true });
    }
  }

  /**
   * fillFormData
   * Genera un FormData con los valores necesarios para poder generar el contrato en el sistema
   * @param cardTokens opcional objeto con los tokens de cada vendor para la tarjeta ingresada
   * @returns FormData
   */
  private fillFormData(cardTokens?: Object): FormData {
    const formData = new FormData();
    formData.append('client[name]', this.form.get('client.name').value);
    formData.append('client[address]', this.form.get('client.address').value);
    formData.append('client[phone]', this.form.get('client.phone').value);
    formData.append('client[email]', this.form.get('client.email').value);
    formData.append('client[between_streets]', this.form.get('client.between_streets').value);
    formData.append('client[colony]', this.form.get('client.colony').value);
    formData.append('client[state]', this.form.get('client.state').value);
    formData.append('client[county]', this.form.get('client.county').value);
    formData.append('client[postal_code]', this.form.get('client.postal_code').value);
    formData.append('client[id_groups]', this.form.get('internalData.plan').value);
    formData.append('responsabilidad', this.form.get('responsabilidad').value);
    formData.append('seller', this.form.get('seller').value);
    formData.append('monthly_installments', this.form.get('monthly_installments').value);

    const elements = this.form.get('elements').value;
    const images = this.form.get('images');
    if (elements) {
      if (elements.length > 0) {
        elements.forEach((element: any) => {
          formData.append('elements[]', element.id_categories_elements);
        });
      }
    }

    if (images.dirty) {
      Object.keys(images.value).forEach(image => {
        const formulary_image = this.form.get(`images.${image}`).value;
        if (formulary_image) {
          formData.append(`images[${image}]`, formulary_image);
        }
      });
    }

    if (cardTokens) {
      Object.keys(cardTokens).forEach(key => {
        formData.append(`credit_card[${key}]`, cardTokens[key]);
      });
    }

    return formData;
  }

  /**
   * registerCard
   * Evalua si la información introducida en la seccion de la tarjeta es valida:
   * + Número de tarjeta valido
   * + CVC valido
   * + Fecha de expiración
   * Si la validación de la tarjeta no devuelve ningún error se ejecuta la función para
   * obtener los tokens (CONEKTA, Openpay) de las tarjetas
   * @returns Objeto con la información si la tarjeta se registro o no.
   */
  private async registerCard(): Promise<any> {
    const response = { status: true, added_card: false };
    const cardData = this.form.get('internalData.cardData');
    if (cardData.dirty) {
      const cardValidationError = this.cardsService.cardValidation(cardData.value);
      if (!cardValidationError) {
        const cardTokens = await this.setCardTokens();
        if (Object.keys(cardTokens).length > 0) {
          response.added_card = true;
          Object.assign(response, { data: cardTokens });
        }
      } else {
        response.status = false;
        Object.assign(response, { data: cardValidationError });
      }
    }

    return response;
  }

  /**
   * setCardTokens
   * Envia la información capturada en el formulario de la tarjeta a los vendors
   * para asi generar los tokens
   * @returns objeto con los tokens de cada vendor
   */
  private async setCardTokens(): Promise<Object> {
    const tokens = {};
    const clientData = this.form.get('client').value;
    const carData = this.form.get('internalData.cardData').value;
    const allTokens = await this.cardsService.getTokens(clientData, carData).toPromise();
    allTokens.forEach(token => {
      if ('success' in token && token.success) {
        delete token.success;
        Object.assign(tokens, token);
      }
    });

    return tokens;
  }
}
