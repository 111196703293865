<div class="table-column" [ngSwitch]="column.type">
    <p *ngSwitchCase="'text'">{{column.field | getField:data:'text'}}</p>
    <p *ngSwitchCase="'percent'">{{column.field | getField:data:'percet'}} %</p>
    <p *ngSwitchCase="'dis_name'">{{data.user.team.name }}</p>
    <p *ngSwitchCase="'boolean'">{{(column.field | getField:data:'boolean') ? 'Activo':'Inactivo' }}</p>
    <p *ngSwitchCase="'number'">${{(column.field | getField:data:'number') / 100| number:'1.2-2'}}</p>
    <p *ngSwitchCase="'currency'"style="text-align:right;"><span style="margin-right: 50%;"> ${{(column.field | getField:data:'number') | number:'1.2-2'}}</span></p>
    <p *ngSwitchCase="'date'">{{(column.field | getField:data:'date') | customDate:column?.format}}</p>
    <p *ngSwitchCase="'period_date'">
        {{(column.field | getField:data:'date') | customDate:'MMMM [del] YYYY'}} <span *ngIf="data.charge_back" class="badge badge-warning">Generada por contracargo</span>
    </p>
    <p *ngSwitchCase="'default_card'">
        <i *ngIf="data.default === 1" class="fa fa-asterisk"></i>{{column.field | getField:data:'text'}}
    </p>
    <p *ngSwitchCase="'charge_description'">
        {{column.field | getField:data:'text'}}
        <span *ngIf="data.credit" class="badge badge-info">Credito usado</span>
        <span *ngIf="data.charge_back" class="badge badge-danger">Contracargado</span>
    </p>
    <p *ngSwitchCase="'label'">
        <app-datatable-column-label [data]="data" [options]="column"></app-datatable-column-label>
    </p>
    <div *ngSwitchCase="'actions'">
        <app-datatable-column-actions [data]="data" [options]="column.options" [trRef]="trRef"></app-datatable-column-actions>
    </div>
    <div *ngSwitchCase="'inline-button'">
        <app-inline-button [data]="data" [options]="column.options" [trRef]="trRef"></app-inline-button>
    </div>

    <!-- Specific Tickets Fields -->
    <div *ngSwitchCase="'ticket_status'">
        <app-datatable-column-ticket-status [data]="data"></app-datatable-column-ticket-status>
    </div>

    <div *ngSwitchCase="'ticket_technician'">
        <app-datatable-column-technician [data]="data.assignations"></app-datatable-column-technician>
    </div>

    <div *ngSwitchCase="'ticket_client'">
        <app-datatable-column-ticket-client [data]="data"></app-datatable-column-ticket-client>
    </div>

    <div *ngSwitchCase="'ticket_contact'">
        <app-datatable-column-ticket-contact [data]="data"></app-datatable-column-ticket-contact>
    </div>

    <div *ngSwitchCase="'client_commission'">
        <app-client-commission [data]="data"></app-client-commission>
    </div>

    <div *ngSwitchCase="'commission_summary'">
        <app-commission-summary [data]="data"></app-commission-summary>
    </div>

    <div *ngSwitchCase="'debt-total'">
        <app-debt-total [data]="data" [options]="column.field"></app-debt-total>
    </div>

    <div *ngSwitchCase="'card-expire'">
        <app-card-expire [data]="data"></app-card-expire>
    </div>

    <div *ngSwitchCase="'client_data'">
        <app-client-data [data]="data"></app-client-data>
    </div>

    <div *ngSwitchCase="'client_sales_group'">
        <app-client-sales-group [data]="data"></app-client-sales-group>
    </div>

    <div *ngSwitchCase="'commission_status'">
        <app-commission-status [data]="data"></app-commission-status>
    </div>

    <div *ngSwitchCase="'charge_amount'">
        <app-charge-amount [data]="data" [options]="column.field"></app-charge-amount>
    </div>

    <div *ngSwitchCase="'html'" [innerHtml]="htmlContent">
    </div>

    <div *ngSwitchCase="'iot-label'">
        <app-iot-label [data]="data" [options]="column.field"></app-iot-label>
    </div>
</div>