<div class="modal-body" *ngIf="data">
    <div class="container-fluid bg-white p-3 mb-4">
        <table class="table">
            <tbody>
                <tr>
                    <td><strong>ID del cargo:</strong></td>
                    <td>{{data.id_charges}}</td>
                </tr>
                <tr>
                    <td><strong>Descripción:</strong></td>
                    <td>{{data.description}}</td>
                </tr>
                <tr>
                    <td><strong>Cantidad:</strong></td>
                    <td>{{data.amount / 100 | currency}}</td>
                </tr>
                <ng-container *ngIf="isReference" ; else cardCharge>
                    <tr>
                        <td><strong>Referencia:</strong></td>
                        <td>{{extraData?.reference}}</td>
                    </tr>
                    <tr>
                        <td><strong>Tipo de referencia: </strong></td>
                        <td>{{extraData?.type}}</td>
                    </tr>
                    <ng-container *ngIf="activeCharge" ; else expiredReference>
                        <tr>
                            <td><strong>Expira en:</strong></td>
                            <td>{{data.remaining_time}} {{data.time_name}}</td>
                        </tr>
                    </ng-container>

                </ng-container>
                <ng-container *ngIf="data.paid_at">
                    <tr>
                        <td><strong>Fecha de confirmación del pago:</strong></td>
                        <td>{{data.paid_at}}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="data.failure_message">
                    <tr>
                        <td><strong>Mensaje de error:</strong></td>
                        <td>{{data.failure_message}}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="extraData?.reason">
                    <tr>
                        <td><strong>Razon:</strong></td>
                        <td>{{extraData.reason}}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="extraData?.authBy">
                    <tr>
                        <td><strong>Autorizado por:</strong></td>
                        <td>{{extraData.authBy}}</td>
                    </tr>
                </ng-container>
                <ng-container *ngIf="data.created_at">
                    <tr>
                        <td><strong>Fecha del cargo:</strong></td>
                        <td>{{data.created_at}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <div class="text-center" *ngIf="hasSummary">
            <button type="button" class="btn btn-outline-primary ms-2" (click)="isCollapsed = !isCollapsed"
                [attr.aria-expanded]="isCollapsed">
                Cargos incluidos
            </button>
        </div>
        <br>
        <div *ngIf="hasSummary" #collapse="ngbCollapse" [(ngbCollapse)]="!isCollapsed">
            <table class="table row-border hover">
                <thead>
                    <tr>
                        <th *ngIf="data.id_charge_type === 6">Cliente</th>
                        <th>Periodo</th>
                        <th>Cantidad</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="charges.length > 0">
                        <tr *ngFor="let charge of charges">
                            <td *ngIf="data.id_charge_type === 6">{{charge?.clientName}}</td>
                            <td>{{charge.name}}</td>
                            <td>{{charge.amount | currency}}</td>
                        </tr>
                        <tr>
                            <td *ngIf="data.id_charge_type === 6"></td>
                            <td>Total</td>
                            <td>{{total | currency}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="sendByWhatsApp()" title="Enviar por Whatsapp">
            <i class="fa fa-whatsapp"></i>
        </button>
        <button type="button" class="btn btn-success" (click)="copyToClipboard()" title="Copiar info del cargo">
            <i class="fa fa-copy"></i>
        </button>
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('close button')">Close</button>
    </div>
</div>

<ng-template #cardCharge>
    <ng-container *ngIf="activeCharge">
        <tr>
            <td><strong>Tiempo restante para solicitar otro cargo:</strong></td>
            <td>{{data.remaining_time}}</td>
        </tr>
    </ng-container>
</ng-template>

<ng-template #expiredReference>
    <tr>
        <td><strong>Expirada el:</strong></td>
        <td>{{expiresAt}}</td>
    </tr>
</ng-template>