import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BroadcastService } from '../../../../services/broadcast.service';
import { collapseAnimation, rubberBandAnimation  } from 'angular-animations';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-clients-referals-treeview',
  templateUrl: './treeview.component.html',
  styleUrls: ['./treeview.component.scss'],
  animations: [
    rubberBandAnimation(),
    collapseAnimation()
  ]
})
export class TreeviewComponent implements OnInit, OnDestroy {
  @Input() clients;
  broadcast$: Subscription;

  parsedClients = [];
  parsedStatus = {
    accepted: 'Aceptado',
    rejected: 'Rechazado',
    canceled: 'Cancelado',
    invalid: 'Invalido',
    standby: 'En Espera'
  };
  activeClient = false;
  collapsed = false;
  activeTree = [];
  
  constructor(private readonly broadcast: BroadcastService) { }

  ngOnInit(): void {
    this.broadcast$ = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'client-referal-show': 
          if (event.data.id_clients !== this.activeClient) {
            this.activeClient = false;
          }
          break;
      }
    });

    if (Array.isArray(this.clients)) {
      this.parsedClients = [...this.clients];
    } else {
      Object.keys(this.clients).forEach(key => {
        this.parsedClients.push(this.clients[key]);
      });
    }
  }

  ngOnDestroy(): void {
    this.broadcast$.unsubscribe();
  }

  getLength(clients?: any): number {
    let length = 0;
    if (clients) {
      length = Object.keys(clients).length;
    }

    return length;
  }

  showInfo(client): void {
    this.activeClient = client.id_clients;
    this.broadcast.fire({
      name: 'client-referal-show', 
      data: client
    });
  }

  toggle(idClients): void {
    if (this.activeTree.indexOf(idClients) > -1) {
      this.activeTree.splice(this.activeTree.indexOf(idClients), 1);
    } else {
      this.activeTree.push(idClients);
    }
  }

}
