<div [ngSwitch]="options">
    <div *ngSwitchCase="'amount'">
        {{amount | currency}}
    </div>

    <p *ngSwitchCase="'refunds_total'">
        {{(data.refunds_total) / 100 | currency}}
    </p>

    <p *ngSwitchCase="'debt'">
        {{(data.debt)/100 | currency}}
    </p>
</div>