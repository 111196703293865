import * as tslib_1 from "tslib";
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
var CustomAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(CustomAdapter, _super);
    function CustomAdapter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.DELIMITER = '-';
        return _this;
    }
    CustomAdapter.prototype.fromModel = function (value) {
        if (value) {
            var date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[2], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[0], 10)
            };
        }
        return null;
    };
    CustomAdapter.prototype.toModel = function (date) {
        return date ? "" + date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : null;
    };
    return CustomAdapter;
}(NgbDateAdapter));
export { CustomAdapter };
/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
var CustomDateParserFormatter = /** @class */ (function (_super) {
    tslib_1.__extends(CustomDateParserFormatter, _super);
    function CustomDateParserFormatter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        _this.DELIMITER = '-';
        return _this;
    }
    CustomDateParserFormatter.prototype.parse = function (value) {
        if (value) {
            var date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    };
    CustomDateParserFormatter.prototype.format = function (date) {
        return date ? "" + date.day + this.DELIMITER + this.months[date.month - 1] + this.DELIMITER + date.year : '';
    };
    return CustomDateParserFormatter;
}(NgbDateParserFormatter));
export { CustomDateParserFormatter };
