<div class="modal-body">
    <div class="row">
        <div class="col-md-12">
            <h2>Técnicos</h2>
            <p>Selecciona el nombre de algún técnico</p>
            
        </div>
    </div>
    <div class="row m-0">
        <div class="col-md-8">
            <div class="container-fluid bg-white p-3 table-responsive">
                <app-datatable [options]="dataTableConfig"></app-datatable>
            </div>
            <!-- <p class="text-danger">You clicked on: <strong>{{ message }}</strong></p> -->
        </div>
        <div class="col-sm-4">
            <div class="row rw">
                <h3 class="col-sm-12 mt-3">Datos del cliente</h3>
                <dt class="col-sm-4 text-right">Nombre</dt>
                <dd class="col-sm-8 pl-0 mb-0">{{ data.client.name }}</dd>
                <dt class="col-sm-4 text-right">Fecha de alta</dt>
                <dd class="col-sm-8 pl-0 mb-0">{{ data.client.created_at }}</dd>
                <dt class="col-sm-4 text-right">Email</dt>
                <dd class="col-sm-8 pl-0 mb-0">{{ data.client.email }}</dd>
                <dt class="col-sm-4 text-right">Telefono</dt>
                <dd class="col-sm-8 pl-0 mb-0">{{ data.client.phone }}</dd>
                <dt class="col-sm-4 text-right">Dirección</dt>
                <dd class="col-sm-8 pl-0 mb-0">{{ data.client.address }}</dd>
                <dt class="col-sm-4 text-right">Entre</dt>
                <dd class="col-sm-8 pl-0 mb-0">{{ data.client.between_streets }}</dd>
                <dt class="col-sm-4 text-right">Colonia</dt>
                <dd class="col-sm-8 pl-0 mb-0">{{ data.client.colony }}</dd>
                <dt class="col-sm-4 text-right">Codigo postal</dt>
                <dd class="col-sm-8 pl-0 mb-0">{{ data.client.postal_code }}</dd>
                <dt class="col-sm-4 text-right">Estado</dt>
                <dd class="col-sm-8 pl-0 mb-0">{{ data.client.state }}</dd>
                <dt class="col-sm-4 text-right">Estatus</dt>
                <dd class="col-sm-8 pl-0 mb-0">
                    <span class="label label-info ng-scope">{{ data.client.subscription_status }}</span>
                </dd>
                <dt class="col-sm-4 text-right">Nivel</dt>
                <dd class="col-sm-8 pl-0 mb-0 ">{{ data.client.level }}</dd>
            </div>
            <div *ngIf="message" class="row rw">
                <h3 class="col-sm-12 mt-4">Datos del técnico seleccionado</h3>
                <div class="col-md-3 text-center pr-0">
                    <div class="m-b-sm">
                        <img alt="image" [src]="imageProfile" (error)="nextImageFormat()" class="img-circle">
                    </div>
                </div>
                <div class="col-md-9">
                    <h5 class="ng-binding">{{ message.name }}</h5>
                    <p class="ng-binding"><i class="fa fa-envelope fa-lg fa-fw text-muted"></i>{{ message.email }}</p>
                    <p class="ng-binding"><i class="fa fa-phone fa-lg fa-fw text-muted"></i>{{ message.phone }}</p>
                </div>
                <div class="col-md-12 text-center">
                    <button type="button" class="btn btn-success btn-lg btn-block ladda-button" (click)="assignTechnician()"><span class="ladda-label">
                        <i class="fa fa-envelope fa-fw"></i><i class="fa fa-ticket fa-fw"></i><br>
                        Asignar ticket y enviar mensaje
                    </span><span class="ladda-spinner"></span></button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('close button')">Cancelar</button>
</div>
