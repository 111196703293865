import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { FromService } from '../../../../providers/form.service';
import { ClientsService } from '../../../../services/clients.service';
import { SwalService } from '../../../../services/swal.service';
import { GroupsService } from '../../../../services/groups.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-group',
  templateUrl: './new-group.component.html',
  styleUrls: ['./new-group.component.scss']
})
export class NewGroupComponent implements OnInit, OnDestroy {
  clients: any;
  selectedClients: any = [];
  subscriptions: Array<Subscription> = [];
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required]
  });
  dataTableConfig = {
    config: {
      base: this.clientService,
      api: 'getClients'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: '',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-success',
            name: 'Seleccionar',
            event: 'add.groupNotification',
            conditionality: 'true'
          }
        ]
      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FromService,
    private readonly clientService: ClientsService,
    private readonly broadcast: BroadcastService,
    private readonly swal: SwalService,
    private readonly groups: GroupsService
  ) { }

  ngOnInit(): void {
    this.formService.setForm(this.form);
    this.clientService.getClients().subscribe((res: any) => {
      this.clients = res.data;
    });
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'add.groupNotification': this.selectClient(event.data); break;
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  selectClient(selclients): void {
    if (this.selectedClients.indexOf(selclients) === -1) {
      this.selectedClients.push(selclients);
    }
  }

  removeClient(clients): void {
    this.selectedClients.splice(this.selectedClients.indexOf(clients), 1);
  }

  groupSave(): void {
    const users = [];
    this.selectedClients.forEach(usr => {
      users.push({
        user_id: usr.id_users
      });
    });
    const params = {
      notification_group: {
        name: this.form.get('name').value,
        description: this.form.get('description').value,
        users
      }
    };

    const swal_title = 'Estás seguro de guardar ';

    this.swal.warning({ title: swal_title }).then(resp => {
      if (resp.value) {
        this.subscriptions.push(this.groups.saveNotificationGroup(params).subscribe((respData: any) => {
          if (respData.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: respData.message });
          }
        }));
      }
    });
  }
}
