import { Routes } from "@angular/router";
import { SuppliesComponent } from "./supplies/supplies.component";
import { SuppliesCategoriesComponent } from "./supplies-categories/supplies-categories.component";

export const WarehouseRoutes: Routes = [
    {
        path: 'insumos',
        data: {role: 'warehoseAdmin', name: 'Administrador bodega'},
        component: SuppliesComponent
    },
    {
        path: 'insumos-categorias',
        component: SuppliesCategoriesComponent
    },
];