import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { SalesDashboardService } from '../../../../services/sales-dashboard.service';
import { SwalService } from '../../../../services/swal.service';

@Component({
  selector: 'app-sales-month-chart',
  templateUrl: './sales-month-chart.component.html',
  styleUrls: ['./sales-month-chart.component.scss']
})
export class SalesMonthChartComponent implements OnInit {

  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;

  @Output()
  isLoadMonthChart = new EventEmitter<boolean>();

  isLoad = true;

  barChartColors: Array<Color> = [
    { backgroundColor: '#83b941' },
    { backgroundColor: '#16a4db' },
    { backgroundColor: '#ff843c' },
    { backgroundColor: '#77FF11' },
    { backgroundColor: '#00ffff' }
  ];
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      plugins: {
        datalabels: {
          color: 'black',
          labels: {
            title: {
              font: {
                weight: 'bold'
              }
            },
            value: {
              color: 'black'
            }
          }
        }
      }
    }
  };
  barChartLabels: Array<Label> = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [pluginDataLabels];
  labelsValue: Array<any> = [];

  barChartData: Array<ChartDataSets> = [];
  fieldFrom: FormControl;
  fieldTo: FormControl;
  form: FormGroup = this.formBuilder.group({
    from: [],
    to: []
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly swal: SwalService,
    private readonly salesDashboard: SalesDashboardService
  ) { }

  ngOnInit(): void {
    this.fieldFrom = this.form.get('from') as FormControl;
    this.fieldTo = this.form.get('to') as FormControl;
    this.setChart();
  }

  search(): void {
    const fromSub = this.form.get('from').value;
    const toSub = this.form.get('to').value;
    const params = {
      from: fromSub,
      to: toSub
    };
    if (params.from && params.to) {
      this.setChart(params);
    } else {
      this.swal.error({ title: 'Favor de introducir las fechas correctamente' });
    }
  }

  resetChart(): void {
    this.isLoad = true;
    let index = Object.keys(this.barChartLabels);
    for (const i of index) {
      this.barChartLabels[i] = '';
    }

    index = Object.keys(this.barChartData);
    for (const i of index) {
      this.barChartData[i].data = [];
      this.barChartData[i].label = '';
    }
  }

  setChart(dateRangeParams?): void {
    this.isLoad = true;
    this.labelsValue = [];
    const params = {
      name: 'etiqueta_de_origen'
    };
    this.salesDashboard.contactProperty(params).subscribe(res => {
      if (res.success) {
       const options = res.response.data.data.options;
        for (const option of options) {
          this.labelsValue.push({ label: option.label, value: option.value });
        }

        this.salesDashboard.salesByMonth(dateRangeParams).subscribe((resp: any) => {
          // getting labels                  
          if (resp.success) {
            const barChartLabelData = [];
            const keys = Object.keys(resp.response).sort();
            for (const index of keys) {
              if (index) {
                const yearMonth = index.split('-');
                const year = yearMonth[0];
                const month = this.months[parseInt(yearMonth[1], 10) - 1];
                barChartLabelData.push(`${month} ${year}`);
              }
              // this.barChartLabels = barChartLabelData;
            }
            this.barChartLabels = [...barChartLabelData];
            // group by options
            const barChartDataL = [];
            for (const labelValue of this.labelsValue) {
              const countByData = [];
              for (const index of keys) {
                const arrayByLabel = resp.response[index].filter(contact =>
                  contact.etiqueta_origen.indexOf(labelValue.value) > -1);
                countByData.push(arrayByLabel.length);
              }
              const data = { data: countByData, label: labelValue.label };
              barChartDataL.push(data);
            }

            this.barChartData = [...barChartDataL];
            // this.barChartData = barChartDataL;
            this.isLoad = false;
            this.isLoadMonthChart.emit(true);
          } else {
            this.swal.error({ title: 'Error recuperando los datos para la gráfica ventas por mes, intente de nuevo' });
          }
        });
      }
    });
  }

  showInfoGraphic(): void {
    const message = 'How many closed won based on month leads generated filtered by lead type';
    this.swal.info(message);
  }

}
