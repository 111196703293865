import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PromotionalMonthlyFeesService = /** @class */ (function () {
    function PromotionalMonthlyFeesService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * create
     * Manda los parametros para crear un nuevo promotionalMonthlyFee
     * @param params Object {name, data}
     * @returns 200 | 400 | 500
     */
    PromotionalMonthlyFeesService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/promotionalMonthlyFees", params);
    };
    /**
     * update
     * Manda los parametros para actualizar los datos de un promotionalMonthlyFee
     * @param id int ID del PromotionalMonthlyFee a actualizar.
     * @param params Object {name, data}
     * @returns 200 | 400 | 404 | 500
     */
    PromotionalMonthlyFeesService.prototype.update = function (id, params) {
        return this.http.put(this.baseUrl + "/promotionalMonthlyFees/" + id, params);
    };
    /**
     * delete
     * Manda los parametros para borrar los datos de un promotionalMonthlyFee
     * @param id int ID del PromotionalMonthlyFee a borrar.
     * @param params Object {name, data}
     * @returns 200 | 404 | 500
     */
    PromotionalMonthlyFeesService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/promotionalMonthlyFees/" + id);
    };
    /**
     * show
     * Devuelve la informacion de un PromotionalMonthlyFee
     * @param id int ID del PromotionalMonthlyFee.
     * @returns 200 | 404
     */
    PromotionalMonthlyFeesService.prototype.show = function (id, params) {
        return this.http.post(this.baseUrl + "/promotionalMonthlyFees/" + id, params);
    };
    /**
     * getPromotionalMothlyFees
     * Obtiene los datos para generar la tabla de las mensualidades promocionales
     * @param params Object con los parametros para definir como ordenar los datos.
     * @param filters Object con los filtros para filtrar la informacion mediante opciones
     * @returns
     */
    PromotionalMonthlyFeesService.prototype.getPromotionalMonthlyFeesTable = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/promotionalMonthlyFees/getPromotionalMonthlyFeesTable", params);
    };
    PromotionalMonthlyFeesService.prototype.getActivePromotionals = function () {
        return this.http.get(this.baseUrl + "/promotionalMonthlyFees/activePromotionalsMonthlyFees");
    };
    PromotionalMonthlyFeesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PromotionalMonthlyFeesService_Factory() { return new PromotionalMonthlyFeesService(i0.ɵɵinject(i1.HttpClient)); }, token: PromotionalMonthlyFeesService, providedIn: "root" });
    return PromotionalMonthlyFeesService;
}());
export { PromotionalMonthlyFeesService };
