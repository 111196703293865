import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NPSService } from '../../../../../services/nps.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FromService } from '../../../../../providers/form.service';
import { SwalService } from '../../../../../services/swal.service';
import { BroadcastService } from '../../../../../services/broadcast.service';
var NpsFormComponent = /** @class */ (function () {
    function NpsFormComponent(activeModal, npsService, broadcast, formBuilder, fromService, swal) {
        this.activeModal = activeModal;
        this.npsService = npsService;
        this.broadcast = broadcast;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.swal = swal;
        this.form = this.formBuilder.group({
            date: ['', Validators.required],
            nps: ['', Validators.required]
        });
    }
    NpsFormComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resp, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.fromService.setForm(this.form);
                        if (!(this.data.status === 'edit')) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.npsService.show(this.data.npsData.id_nps).toPromise()];
                    case 2:
                        resp = _a.sent();
                        if (resp && resp.success) {
                            this.npsItem = resp.response;
                            Object.keys(this.npsItem).forEach(function (key) {
                                if (_this.npsItem.hasOwnProperty(key) && !!_this.form.controls[key]) {
                                    _this.form.controls[key].setValue(_this.npsItem[key]);
                                }
                            });
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    NpsFormComponent.prototype.save = function () {
        if (this.data.status === 'edit') {
            this.updateNPSItem();
        }
        if (this.data.status === 'create') {
            this.saveNPSItem();
        }
    };
    NpsFormComponent.prototype.saveNPSItem = function () {
        var _this = this;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer guardar este NPS' }).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var resp;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.value) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.npsService.create(this.form.value).toPromise()];
                        case 1:
                            resp = _a.sent();
                            if (resp && resp.success) {
                                this.swal.success().then(function () {
                                    _this.activeModal.dismiss();
                                    _this.broadcast.reloadDataTable();
                                });
                            }
                            else {
                                this.swal.error({ title: 'Ocurió un error al guardar los datos' });
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    NpsFormComponent.prototype.updateNPSItem = function () {
        var _this = this;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer actualizar este NPS?' }).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var resp;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.value) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.npsService.edit(this.data.npsData.id_nps, this.form.value).toPromise()];
                        case 1:
                            resp = _a.sent();
                            if (resp.success) {
                                this.swal.success().then(function () {
                                    _this.activeModal.dismiss();
                                    _this.broadcast.reloadDataTable();
                                });
                            }
                            else {
                                this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    return NpsFormComponent;
}());
export { NpsFormComponent };
