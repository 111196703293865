/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clients-map.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@agm/core";
import * as i3 from "../../../../../../node_modules/@agm/core/agm-core.ngfactory";
import * as i4 from "@angular/common";
import * as i5 from "./clients-map.component";
import * as i6 from "../../../../services/clients.service";
import * as i7 from "../../../../services/dashboard.service";
import * as i8 from "../../../../services/swal.service";
var styles_ClientsMapComponent = [i0.styles];
var RenderType_ClientsMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientsMapComponent, data: {} });
export { RenderType_ClientsMapComponent as RenderType_ClientsMapComponent };
function View_ClientsMapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "agm-marker", [], null, [[null, "markerClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("markerClick" === en)) {
        var pd_0 = (_co.showClientInfo(_v.context.$implicit.id_clients) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(6144, null, i2.FitBoundsAccessor, null, [i2.AgmMarker]), i1.ɵdid(2, 1720320, null, 1, i2.AgmMarker, [i2.MarkerManager], { latitude: [0, "latitude"], longitude: [1, "longitude"], clickable: [2, "clickable"] }, { markerClick: "markerClick" }), i1.ɵqud(603979776, 1, { infoWindow: 1 })], function (_ck, _v) { var currVal_0 = _v.context.$implicit.latitude; var currVal_1 = _v.context.$implicit.longitude; var currVal_2 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ClientsMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "agm-map", [], [[2, "sebm-google-map-container", null]], [[null, "mapReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mapReady" === en)) {
        var pd_0 = (_co.onMapLoad($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_AgmMap_0, i3.RenderType_AgmMap)), i1.ɵprd(4608, null, i2.MarkerManager, i2.MarkerManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.CircleManager, i2.CircleManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.DataLayerManager, i2.DataLayerManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.InfoWindowManager, i2.InfoWindowManager, [i2.GoogleMapsAPIWrapper, i1.NgZone, i2.MarkerManager]), i1.ɵprd(4608, null, i2.KmlLayerManager, i2.KmlLayerManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.LayerManager, i2.LayerManager, [i2.GoogleMapsAPIWrapper]), i1.ɵprd(4608, null, i2.PolygonManager, i2.PolygonManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.PolylineManager, i2.PolylineManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i2.RectangleManager, i2.RectangleManager, [i2.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(512, null, i2.GoogleMapsAPIWrapper, i2.GoogleMapsAPIWrapper, [i2.MapsAPILoader, i1.NgZone]), i1.ɵprd(512, null, i2.ɵa, i2.ɵa, [i2.MapsAPILoader]), i1.ɵdid(12, 770048, null, 0, i2.AgmMap, [i1.ElementRef, i2.GoogleMapsAPIWrapper, i1.PLATFORM_ID, i2.ɵa, i1.NgZone], { longitude: [0, "longitude"], latitude: [1, "latitude"], zoom: [2, "zoom"] }, { mapReady: "mapReady" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ClientsMapComponent_1)), i1.ɵdid(14, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.longitude; var currVal_2 = _co.latitude; var currVal_3 = _co.zoom; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.coordinates; _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_ClientsMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-clients-map", [], null, null, null, View_ClientsMapComponent_0, RenderType_ClientsMapComponent)), i1.ɵdid(1, 114688, null, 0, i5.ClientsMapComponent, [i6.ClientsService, i7.DashboardService, i8.SwalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientsMapComponentNgFactory = i1.ɵccf("app-clients-map", i5.ClientsMapComponent, View_ClientsMapComponent_Host_0, {}, {}, []);
export { ClientsMapComponentNgFactory as ClientsMapComponentNgFactory };
