import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'history-ticket-created',
  templateUrl: './created.component.html',
  styleUrls: ['./created.component.scss']
})
export class CreatedComponent implements OnInit {
  @Input('item') item: any;

  ngOnInit(): void {
    // 
  }

}
