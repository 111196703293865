<div class="modal-body">
  <div class="container">
    <div [ngClass]="{'disabled': isEdit}">
      <app-form-field type="select" [options]="{
        label: 'Campaign',
        path: 'campaign_id',
        droptext: 'Choose an option',
        elements: campaigns
    }"></app-form-field>
    </div>
    
    <app-form-field type="datepicker" [options]="{label:'Fecha Inicio', path: 'init_date'}"></app-form-field>
    <app-form-field type="datepicker" [options]="{label:'Fecha Final', path: 'end_date'}"></app-form-field>
    <app-form-field type="input" [options]="{label:'Balance', path: 'balance'}"></app-form-field>
    <app-form-field type="input" [options]="{label:'Nombre Campaña', path: 'campaign_name'}"></app-form-field>
  </div>
</div>

<div class="modal-footer">
  <div *ngIf="this.form.dirty">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
    <button type="button" class="btn btn-outline-success" [disabled]="form.invalid" (click)="save()">Guardar</button>
  </div>
  <div *ngIf="!this.form.dirty">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
  </div>
</div>