import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AppUsageService = /** @class */ (function () {
    function AppUsageService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    AppUsageService.prototype.show = function (params) {
        return this.http.post(this.baseUrl + "/appUsage", params);
    };
    AppUsageService.prototype.registrations = function () {
        return this.http.get(this.baseUrl + "/registration-per-platform");
    };
    AppUsageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppUsageService_Factory() { return new AppUsageService(i0.ɵɵinject(i1.HttpClient)); }, token: AppUsageService, providedIn: "root" });
    return AppUsageService;
}());
export { AppUsageService };
