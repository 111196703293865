import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthorizationService } from '../services/authorization.service';
import { SwalService } from '../services/swal.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/authorization.service";
import * as i3 from "../services/swal.service";
var CatchAllInterceptorService = /** @class */ (function () {
    function CatchAllInterceptorService(router, authService, swal) {
        this.router = router;
        this.authService = authService;
        this.swal = swal;
    }
    CatchAllInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req).pipe(catchError(function (httpError) {
            var errorMessage;
            var displayMessage;
            if (httpError instanceof ErrorEvent) {
                // client-side error
                errorMessage = "Client-side error: " + httpError.error.message;
                _this.swal.error({ text: errorMessage }).catch();
            }
            else {
                if (httpError.error.error === 'user_not_found') {
                    errorMessage = 'Sesion expirada';
                    _this.authService.logout();
                    _this.swal.warning({ title: 'Sesion expirada', text: '', showCancelButton: false, confirmButtonText: 'Ok' });
                    _this.router.navigateByUrl('/login');
                }
                else {
                    // backend error
                    errorMessage = "Server-side error: " + httpError.status + " " + httpError.message;
                    var errors = [
                        httpError.status + " - " + httpError.statusText,
                        httpError.url
                    ];
                    displayMessage = '<div class="server-error">';
                    displayMessage += '<p>Server-side error:</p>';
                    displayMessage += "<ul>" + _this.returnFormated(errors) + "</ul>";
                    displayMessage += '</div>';
                    switch (httpError.status) {
                        case 0:
                            _this.authService.logout();
                            _this.router.navigateByUrl('/login');
                            localStorage.removeItem('user');
                            localStorage.removeItem('access_token');
                            _this.swal.error({ html: displayMessage }).catch();
                            break;
                        case 400:
                            if (req.url.includes('auth/refresh')) {
                                break;
                            }
                            var mergedErrorMessages_1 = [];
                            var response_errors_1 = httpError.error.response.errors;
                            if (!(response_errors_1 instanceof Array)) {
                                response_errors_1 = Object.keys(response_errors_1)
                                    .map(function (key) { return response_errors_1[key]; });
                                var failure_messages_1 = response_errors_1 || [];
                                displayMessage = '<div class="server-error">';
                                // displayMessage += '<p>Vendor error:</p>';
                                var errorsShow_1 = '<li>Error al procesar el pago.</li>';
                                if (failure_messages_1.length > 0) {
                                    var formatedErrors = _this.returnFormated(failure_messages_1);
                                    if (formatedErrors !== '') {
                                        errorsShow_1 = formatedErrors;
                                    }
                                }
                                displayMessage += "<ul>" + errorsShow_1 + "</ul>";
                                displayMessage += '</div>';
                                _this.swal.error({ html: displayMessage });
                            }
                            else {
                                response_errors_1.forEach(function (key) {
                                    mergedErrorMessages_1.push(response_errors_1[key].join(' / '));
                                });
                                var bad_parameters_message = mergedErrorMessages_1.join(' / ');
                                _this.swal.warning({
                                    title: 'Error en parámetros',
                                    text: bad_parameters_message,
                                    confirmButtonText: 'Aceptar',
                                    showCancelButton: false
                                });
                            }
                            break;
                        case 401:
                            if (httpError.error === 'bad_credentials') {
                                _this.swal.error({
                                    title: 'Credenciales invalidas',
                                    text: '',
                                    showCancelButton: false,
                                    confirmButtonText: 'Ok'
                                });
                                break;
                            }
                            errorMessage = httpError;
                            break;
                        case 402:
                            var failure_messages = httpError.error.response.errors || [];
                            displayMessage = '<div class="server-error">';
                            // displayMessage += '<p>Vendor error:</p>';
                            var errorsShow = '<li>Error al procesar el pago.</li>';
                            if (failure_messages.length > 0) {
                                var formatedErrors = _this.returnFormated(failure_messages);
                                if (formatedErrors !== '') {
                                    errorsShow = formatedErrors;
                                }
                            }
                            displayMessage += "<ul>" + errorsShow + "</ul>";
                            displayMessage += '</div>';
                            _this.swal.error({ html: displayMessage });
                            break;
                        case 403:
                            var unauthorized_message = httpError.error.response.errors;
                            _this.swal.warning({
                                title: unauthorized_message,
                                text: '',
                                confirmButtonText: 'Aceptar',
                                showCancelButton: false
                            });
                            break;
                        case 429:
                        case 404:
                            var failure_message = httpError.error.response.errors;
                            _this.swal.warning({
                                title: failure_message,
                                text: '',
                                confirmButtonText: 'Aceptar',
                                showCancelButton: false
                            });
                            break;
                        default:
                            _this.authService.logout();
                            _this.router.navigateByUrl('/login');
                            _this.swal.error({ html: displayMessage }).catch();
                            break;
                    }
                }
            }
            // aquí podrías agregar código que muestre el error en alguna parte fija de la pantalla.
            // this.errorService.show(errorMessage);
            // console.warn(errorMessage);
            return throwError(errorMessage);
        }));
    };
    CatchAllInterceptorService.prototype.returnFormated = function (errors) {
        var text = '';
        errors.forEach(function (error) {
            if (Array.isArray(error) && error.length > 0) {
                error.forEach(function (subError) {
                    if (subError.hasOwnProperty('failure_message')) {
                        text += "<li>" + subError.failure_message + "</li>";
                    }
                    else {
                        text += "<li>" + subError + "</li>";
                    }
                });
            }
            else {
                text += "<li>" + error + "</li>";
            }
            // if (error.hasOwnProperty('failure_message')) {
            //   text += `<li>${error.failure_message}</li>`;
            // } else {
            //   text += `<li>${error}</li>`;
            // }
        });
        return text;
    };
    CatchAllInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CatchAllInterceptorService_Factory() { return new CatchAllInterceptorService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthorizationService), i0.ɵɵinject(i3.SwalService)); }, token: CatchAllInterceptorService, providedIn: "root" });
    return CatchAllInterceptorService;
}());
export { CatchAllInterceptorService };
