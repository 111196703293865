import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TaxService = /** @class */ (function () {
    function TaxService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * index
     * Solicita a la API la lista de situaciones fiscales.
     * @returns 200 | 404 | 500
     */
    TaxService.prototype.index = function () {
        return this.http.get(this.baseUrl + "/tax/situations");
    };
    TaxService.prototype.getTaxData = function () {
        var tax_situations = this.http.get(this.baseUrl + "/tax/situations");
        var tax_cfdi = this.http.get(this.baseUrl + "/tax/cfdi");
        return forkJoin([tax_situations, tax_cfdi]);
    };
    TaxService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TaxService_Factory() { return new TaxService(i0.ɵɵinject(i1.HttpClient)); }, token: TaxService, providedIn: "root" });
    return TaxService;
}());
export { TaxService };
