import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AuthorizationService = /** @class */ (function () {
    function AuthorizationService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
        this.cookieValue = 'NA';
        this.jwt = null;
    }
    AuthorizationService.prototype.login = function (credentials) {
        return this.http.post(this.baseUrl + "/auth/login", credentials, { withCredentials: true });
    };
    AuthorizationService.prototype.refreshToken = function () {
        return this.http.post(environment.apiUrl + "/auth/refresh", {}).pipe(tap(function (data) {
            localStorage.setItem('access_token', data.token);
        }));
    };
    AuthorizationService.prototype.logout = function () {
        this.http.get(environment.apiUrl + "/auth/logout").subscribe(function () {
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
        });
    };
    AuthorizationService.prototype.isAuthenticated = function () {
        var isAuthorized = false;
        if (window.localStorage.getItem('access_token')) {
            isAuthorized = true;
        }
        return isAuthorized;
    };
    Object.defineProperty(AuthorizationService.prototype, "token", {
        get: function () {
            return localStorage.getItem('access_token');
        },
        enumerable: true,
        configurable: true
    });
    AuthorizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizationService_Factory() { return new AuthorizationService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthorizationService, providedIn: "root" });
    return AuthorizationService;
}());
export { AuthorizationService };
