import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupsService } from '../../../../services/groups.service';
import { NotificationService } from '../../../../services/notification.service';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { FromService } from '../../../../providers/form.service';
import { SwalService } from '../../../../services/swal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notify-group',
  templateUrl: './notify-group.component.html',
  styleUrls: ['./notify-group.component.scss']
})
export class NotifyGroupComponent implements OnInit, OnDestroy {
  groups: any;
  selectedGroups: any = [];
  subscriptions: Array<Subscription> = [];
  message: any;
  form: FormGroup = this.formBuilder.group({
    message: ['']
  });
  constructor(
    public activeModal: NgbActiveModal,
    private readonly formBuilder: FormBuilder,
    private readonly groupsService: GroupsService,
    private readonly notify: NotificationService,
    public appModal: ModalComponent,
    private readonly formService: FromService,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    this.formService.setForm(this.form);
    this.subscriptions.push(this.groupsService.getGroups().subscribe((res: any) => {
      this.groups = res.data;
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  selectGroup(groups): void {
    if (this.selectedGroups.indexOf(groups) === -1) {
      this.selectedGroups.push(groups);
    }
  }

  notifyGroup(): void {
    const params = {
      groups: this.selectedGroups.map(grps => grps.id_groups),
      message: this.form.get('message').value
    };
    const swal_title = 'Estás seguro de enviar la notificación ';

    this.swal.warning({ title: swal_title }).then(resp => {
      if (resp.value) {
        this.subscriptions.push(this.notify.notifyGroup(params).subscribe((data: any) => {
          if (data.success) {
            this.swal.success();
          } else {
            this.swal.error({title: data.message});
          }
        }));
      }
    });
  }

  removeGroup(groups): void {
    this.selectedGroups.splice(this.selectedGroups.indexOf(groups), 1);
  }

}
