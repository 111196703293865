import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { KpiComponent } from './main-dashboard/kpi/kpi.component';
import { DashboardTablesComponent } from './main-dashboard/dashboard-tables/dashboard-tables.component';
import { IncomesChartComponent } from './main-dashboard/incomes-chart/incomes-chart.component';
import { ChartsModule } from 'ng2-charts';
import { SubscriptionsChartComponent } from './main-dashboard/subscriptions-chart/subscriptions-chart.component';
import { SharesByTypeChartComponent } from './main-dashboard/shares-by-type-chart/shares-by-type-chart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from '../../components/components.module';
import { CancellationsChartComponent } from './main-dashboard/cancellations-chart/cancellations-chart.component';
import { TicketsCompletitionsChartComponent } from './main-dashboard/tickets-completitions-chart/tickets-completitions-chart.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { SalesMonthChartComponent } from './sales-dashboard-tables/sales-month-chart/sales-month-chart.component';
import { SalesCampaignChartComponent } from './sales-dashboard-tables/sales-campaign-chart/sales-campaign-chart.component';
import { InvestorDashboardComponent } from './investor-dashboard/investor-dashboard.component';
import { InvestorChartComponent } from './investor-dashboard/investor-chart/investor-chart.component';
import { InvestorKpiComponent } from './investor-dashboard/investor-kpi/investor-kpi.component';
import { SalesPromotionChartComponent } from './sales-dashboard-tables/sales-promotion-chart/sales-promotion-chart.component';
import { SalesAgentChartComponent } from './sales-dashboard-tables/sales-agent-chart/sales-agent-chart.component';
import { SalesAgentChartCustomComponent } from './sales-dashboard-tables/sales-agent-chart-custom/sales-agent-chart-custom.component';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { CashTableComponent } from './admin-dashboard/cash-table/cash-table.component';
import { MoneySpentCampaignComponent } from './admin-dashboard/money-spent-campaign/money-spent-campaign.component';
import { SalesCampaignFacebookComponent } from './sales-dashboard-tables/sales-campaign-facebook/sales-campaign-facebook.component';
import { SalesCacComponent } from './sales-dashboard-tables/sales-cac/sales-cac.component';
import { SalesCacCampaignComponent } from './sales-dashboard-tables/sales-cac-campaign/sales-cac-campaign.component';
import { AdvertisingTableComponent } from './admin-dashboard/advertising-table/advertising-table.component';
import { ClientsHeatmapComponent } from './investor-dashboard/clients-heatmap/clients-heatmap.component';
import { AgmCoreModule } from '@agm/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ClientsMapComponent } from './main-dashboard/clients-map/clients-map.component';
import { SatisfactionTableComponent } from './admin-dashboard/satisfaction-table/satisfaction-table.component';
import { NpsTableComponent } from './admin-dashboard/nps-table/nps-table.component';

@NgModule({
  imports: [
    CommonModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ComponentsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBq6jUoU6vrft-0hVUjkcAyeeM4zSuahuo', // production
      // apiKey: 'AIzaSyAXbhIbswUZIdCi-Yw3f1KhoTp3Eg_Y7q4', // development
      libraries: ['visualization']
    }),
    NgxPermissionsModule.forChild()
  ],
  declarations: [
    DashboardComponent,
    DashboardTablesComponent,
    KpiComponent,
    IncomesChartComponent,
    SubscriptionsChartComponent,
    CancellationsChartComponent,
    SharesByTypeChartComponent,
    TicketsCompletitionsChartComponent,
    SalesDashboardComponent,
    SalesMonthChartComponent,
    SalesCampaignChartComponent,
    InvestorDashboardComponent,
    InvestorChartComponent,
    InvestorKpiComponent,
    SalesPromotionChartComponent,
    SalesAgentChartComponent,
    SalesAgentChartCustomComponent,
    AdminDashboardComponent,
    CashTableComponent,
    MoneySpentCampaignComponent,
    SalesCampaignFacebookComponent,
    SalesCacComponent,
    SalesCacCampaignComponent,
    AdvertisingTableComponent,
    ClientsHeatmapComponent,
    ClientsMapComponent,
    SatisfactionTableComponent,
    NpsTableComponent
  ],
  entryComponents: [],
  exports: [
    DashboardComponent
  ]
})
export class DashboardModule { }
