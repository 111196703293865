import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { filter, last } from 'rxjs/operators';

export interface Events {
  name: string;
  data: any;
}
@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  // public _events = new BehaviorSubject<Events>({name: '', data: {}});
  _events = new Subject<Events>();

  fire(data: Events): any {
    this._events.next(data);
  }

  get events(): any {
    return this._events;
  }

  on(eventName: string, callback: any): any {
    const subscription: any = this.events.pipe(
      filter((value: any) => value.name === eventName)
    ).subscribe(callback);

    return (): void => {
      subscription.remove(subscription);
      subscription.unsubscribe();
    };
  }

  reloadDataTable(): void {
    this.fire({
      name: 'datatable-filter',
      data: {}
    });
  }
}
