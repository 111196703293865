import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CategoriesService = /** @class */ (function () {
    function CategoriesService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    CategoriesService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/categories", params);
    };
    CategoriesService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/categories/" + id);
    };
    CategoriesService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/categories/" + id);
    };
    CategoriesService.prototype.update = function (id, params) {
        return this.http.put(this.baseUrl + "/categories/" + id, params);
    };
    CategoriesService.prototype.getCategories = function (params) {
        return this.http.post(this.baseUrl + "/categories/get_categories", params);
    };
    CategoriesService.prototype.getCategoriesList = function () {
        return this.http.get(this.baseUrl + "/categories");
    };
    CategoriesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CategoriesService_Factory() { return new CategoriesService(i0.ɵɵinject(i1.HttpClient)); }, token: CategoriesService, providedIn: "root" });
    return CategoriesService;
}());
export { CategoriesService };
