<div class="container-fluid border-bottom bg-white p-3 mb-4">
  <h3>
    {{ title }}
    <span 
      class="fa fa-info-circle fa-1x" 
      style="cursor: pointer;"
      (click)="showInfoGraphic()"
      data-toggle="tooltip" 
      data-placement="right"
      title="Show description"></span>
  </h3>
  <canvas baseChart 
          *ngIf="datasets.length"
          width="450" 
          height="100"
          [labels]="labels"
          [datasets]="datasets"
          [chartType]="chartType"
          [options]="options"
          [colors]="chartColor"></canvas>
</div>
