<div class="container-fluid bg-white p-3">
    <div class="text-right" *ngIf="client.status === 'accepted' && client.debt <= 0">
        <button type="button" class="btn btn-success" (click)="createCredit(client)"
            *ngxPermissionsOnly="['create-credits']">Añadir credito</button>
    </div>
    <div class="text-right" *ngIf="client.debt > 0">
        <h4 class="text-danger">Deuda: {{client.debt / 100 | currency}}</h4>
    </div>
    <app-datatable [options]="dataTableConfig"></app-datatable>

</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('close button')">Close</button>
</div>
