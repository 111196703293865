import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-completion-map',
  templateUrl: './completion-map.component.html',
  styleUrls: ['./completion-map.component.scss']
})
export class CompletionMapComponent implements OnInit {
  @Input() data: {latitude: number; longitude: number};
  zoom = 15;
  
  ngOnInit(): void {
    //
  }

}
