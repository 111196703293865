/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./upload-photos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../form/form-field/form-field.component.ngfactory";
import * as i3 from "../../form/form-field/form-field.component";
import * as i4 from "../../../providers/form.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "ngx-lightbox/lightbox.service";
import * as i7 from "@angular/common";
import * as i8 from "./upload-photos.component";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
import * as i10 from "../../../services/broadcast.service";
import * as i11 from "@angular/forms";
import * as i12 from "../../../services/swal.service";
import * as i13 from "../../../services/tickets.service";
import * as i14 from "../../../services/clients.service";
var styles_UploadPhotosComponent = [i0.styles];
var RenderType_UploadPhotosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UploadPhotosComponent, data: {} });
export { RenderType_UploadPhotosComponent as RenderType_UploadPhotosComponent };
function View_UploadPhotosComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-form-field", [["type", "input"]], null, null, null, i2.View_FormFieldComponent_0, i2.RenderType_FormFieldComponent)), i1.ɵdid(2, 114688, null, 0, i3.FormFieldComponent, [i4.FromService, i5.DomSanitizer, i6.Lightbox], { type: [0, "type"], options: [1, "options"] }, null), i1.ɵpod(3, { label: 0, path: 1 })], function (_ck, _v) { var currVal_0 = "input"; var currVal_1 = _ck(_v, 3, 0, "Nueva categoria", "new_image_category_name"); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_UploadPhotosComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "app-form-field", [["type", "select"]], null, null, null, i2.View_FormFieldComponent_0, i2.RenderType_FormFieldComponent)), i1.ɵdid(2, 114688, null, 0, i3.FormFieldComponent, [i4.FromService, i5.DomSanitizer, i6.Lightbox], { type: [0, "type"], options: [1, "options"] }, null), i1.ɵpod(3, { label: 0, path: 1, droptext: 2, elements: 3 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadPhotosComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "select"; var currVal_1 = _ck(_v, 3, 0, "Categoria", "image_category", "Selecciona la categoria", _co.imageCategories); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.newImageCategory; _ck(_v, 5, 0, currVal_2); }, null); }
function View_UploadPhotosComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-outline-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancelar"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-outline-success"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Guardar"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form.invalid; _ck(_v, 3, 0, currVal_0); }); }
function View_UploadPhotosComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cerrar"]))], null, null); }
export function View_UploadPhotosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadPhotosComponent_1)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-form-field", [["type", "image"]], null, null, null, i2.View_FormFieldComponent_0, i2.RenderType_FormFieldComponent)), i1.ɵdid(5, 114688, null, 0, i3.FormFieldComponent, [i4.FromService, i5.DomSanitizer, i6.Lightbox], { type: [0, "type"], options: [1, "options"] }, null), i1.ɵpod(6, { width: 0, path: 1, image: 2, text: 3, class: 4, buttonVisible: 5, multiple: 6, blob: 7 }), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadPhotosComponent_3)), i1.ɵdid(10, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UploadPhotosComponent_4)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.data.type === "clientExtraImage"); _ck(_v, 3, 0, currVal_0); var currVal_1 = "image"; var currVal_2 = _ck(_v, 6, 0, "0", "photos", _co.image, "Subir imagenes", "image-preview", "true", _co.multiple, "true"); _ck(_v, 5, 0, currVal_1, currVal_2); var currVal_3 = _co.form.dirty; _ck(_v, 10, 0, currVal_3); var currVal_4 = !_co.form.dirty; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_UploadPhotosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-upload-photos", [], null, null, null, View_UploadPhotosComponent_0, RenderType_UploadPhotosComponent)), i1.ɵdid(1, 245760, null, 0, i8.UploadPhotosComponent, [i9.NgbActiveModal, i10.BroadcastService, i11.FormBuilder, i4.FromService, i12.SwalService, i13.TicketsService, i14.ClientsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UploadPhotosComponentNgFactory = i1.ɵccf("app-upload-photos", i8.UploadPhotosComponent, View_UploadPhotosComponent_Host_0, { data: "data" }, {}, []);
export { UploadPhotosComponentNgFactory as UploadPhotosComponentNgFactory };
