import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FromService } from '../../../../providers/form.service';
import { SwalService } from '../../../../services/swal.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { GalleryService } from '../../../../services/gallery.service';
import { environment } from '../../../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-gallery-create-edit',
  templateUrl: './gallery-create-edit.component.html',
  styleUrls: ['./gallery-create-edit.component.scss']
})
export class GalleryCreateEditComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  baseUrl = `${environment.apiUrl}`;
  post: any;
  image: any;
  form: FormGroup = this.formBuilder.group({
    title: ['', Validators.required],
    text: ['', Validators.required],
    image: ['']
  });
  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService,
    private readonly galleryService: GalleryService
  ) { }

  ngOnInit(): void {
    this.setStatus();
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }
  /**
   * setStatus
   * Determina si la vista va a ser de tipo edit o create
   * Si la vista es de tipo edit busca obtiene la información del post
   * mediante una peticion get y setea los datos en el formulario.
   */
  setStatus(): void {
    if (this.data.status === 'edit') {
      this.subscriptions.push(this.galleryService.show(this.data.postData.id_posts).subscribe((resp: any) => {
        this.post = resp.response;
        this.getPostImage(this.post.id_posts);
        Object.keys(this.post).forEach(key => {
          if (this.post.hasOwnProperty(key) && !!this.form.controls[key]) {
            this.form.controls[key].setValue(this.post[key]);
          }
        });
      }));
    }
  }

  /**
   * save
   * Envia una petición de crear o editar dependiendo del status recibido en el data.
   */

  save(): void {
    if (this.data.status === 'edit') {
      this.updatePost();
    }

    if (this.data.status === 'create') {
      this.savePost();
    }
  }

  /**
   * savePost
   * Envia una petición post con los valores obtenidos del form para crear el registro
   * en la BD
   */
  savePost(): void {
    const swalParams = {
      title: '¿Esta seguro de querer guardar los datos del post?',
      text: 'El post se creara con la siguiente información'
    };
    if (this.form.valid) {
      if (this.form.get('image').value === '') {
        this.form.removeControl('image');
      }

      this.swal.warning(swalParams).then(result => {
        if (result.value) {
          this.subscriptions.push(this.galleryService.create(this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

  /**
   * updatePost
   * Envia una petición post con los datos modificados en el form
   */

  updatePost(): void {
    if (this.form.get('image').value === '' || 'null') {
      this.form.removeControl('image');
    }

    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del post?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.galleryService.update(this.data.postData.id_posts, this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
            }
          }));
        }
      });
    }
  }

  private getPostImage(id): any {
    let imageUrl = null;
    this.subscriptions.push(this.galleryService.getPostImage(id).subscribe((resp: any) => {
      imageUrl = `${this.baseUrl}/posts_files/${resp.response}`;
      this.image = imageUrl;
    }));
  }

}
