import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FromService } from '../../../providers/form.service';
import { CashService } from '../../../services/cash.service';
import { InvestorDashboardService } from '../../../services/investor-dashboard.service';
import { NPSService } from '../../../services/nps.service';
import { SatisfactionService } from '../../../services/satisfaction.service';
import { SwalService } from '../../../services/swal.service';
var InvestorDashboardComponent = /** @class */ (function () {
    function InvestorDashboardComponent(cashService, satisfactionService, npsService, formBuilder, fromService, investorDashboardService, swal) {
        this.cashService = cashService;
        this.satisfactionService = satisfactionService;
        this.npsService = npsService;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.investorDashboardService = investorDashboardService;
        this.swal = swal;
        this.form = this.formBuilder.group({
            from: [],
            to: []
        });
        this.primaryColor = [
            {
                backgroundColor: '#16a4db88',
                borderColor: '#16a4db'
            }
        ];
        // Property where to store data and configurations for Installations/month graph
        this.installationsPerMonthData = {
            title: 'Total Installations',
            chartType: 'bar',
            datasets: [],
            labels: null,
            options: {},
            description: 'Cumulative installed units on the last day of each month'
        };
        // Property where to store data and configurations for growth rate per year graph
        this.annualGrowthRateData = {
            title: 'Annual Client Growth Rate',
            chartType: 'bar',
            datasets: [],
            labels: null,
            options: {},
            description: 'Sum of one years installations divided by the sum of its previous years installations; the result multiplied by 100.'
        };
        // Property where to store data and configurations for revenue rate per year graph
        this.annualRevenueRateData = {
            title: 'Annual Revenue Growth Rate',
            chartType: 'bar',
            datasets: [],
            labels: null,
            options: {},
            description: "Sum of one years revenues divided by the sum of its previous years revenues; the result multiplied by 100. \n    All revenues exclude IVA and social responsability."
        };
        // Property where to store data and configurations for monthly recurring revenue graph
        this.monthlyRecurringRevenueData = {
            title: 'Monthly Recurring Revenue (MRR)',
            chartType: 'line',
            datasets: [],
            labels: null,
            options: {},
            description: 'Sum of active clients\' regular monthly charges (excluding IVA).'
        };
        // Property where to store data and configurations for annual recurring revenue graph
        this.annualRecurringRevenueData = {
            title: 'Annual Recurring Revenue (ARR)',
            chartType: 'line',
            datasets: [],
            labels: null,
            options: {},
            description: 'Monthly recurring revenue (MRR) excluding IVA multiplied by 12.'
        };
        // Property where to store data and configurations for commited monthly recurring revenue graph
        this.commitedMonthlyRecurringRevenueData = {
            title: 'Commited Monthly Recurring Revenue (CMRR)',
            chartType: 'line',
            datasets: [],
            labels: null,
            options: {},
            description: "For CMRR we need to forecast the future monthly recurring revenue if we stop selling but continue having \n    cancellations at the same time. So for this graphic current month is the first point. For the second month we will deduct \n    from the first month revenue the equivalent % as the last churn rate divided by 12. Then month 3 will be month two revenue \n    minus the % calculations again, and so on go forward 12 months."
        };
        // Property where to store data and configurations for cancellations rate per year graph
        this.annualCancellationsRateData = {
            title: 'Annual Client Cancellations Rate',
            chartType: 'bar',
            datasets: [],
            labels: null,
            options: {}
        };
        // Property where to store data and configurations for monthly referrers rate graph
        this.monthlyReferrersRateData = {
            title: 'Clients that have referred someone',
            chartType: 'line',
            datasets: [],
            labels: null,
            options: {},
            description: '% of active clients that have successfully referred at least one client.'
        };
        // Property where to store data and configurations for monthly referrers rate graph
        this.monthlyChurnRateData = {
            title: 'Rolling annual churn rate',
            chartType: 'line',
            datasets: [],
            labels: null,
            options: {},
            description: "Total of cancellations since last year same period until current period, \n    divided by total of installations last year same period. That result multiplied by 100."
        };
        // Property where to store data and configurations for customer lifetime value graph
        this.customerLifetimeValueData = {
            title: 'Customer Lifetime Value (CLV)',
            chartType: 'line',
            datasets: [],
            labels: null,
            options: {},
            description: "First we obtain expected life of an average client in months: 100/Churn Rate x 12. Then we calculate the average monthly fee we charge our clients wihtout IVA: \n    Total monthly charges / Number of clients. Then we multiply the two results together to obtain the CLV."
        };
        // Property where to store data and configurations for last 12 months cash graph
        this.cashData = {
            title: 'Cash',
            chartType: 'bar',
            datasets: [],
            labels: null,
            options: {},
            description: 'Bank balance at the end of each month.'
        };
        // Property where to store data for growth rate kpi
        this.growthRateData = {
            title: 'Growth',
            value: '',
            description: 'YTD growth rate of installations'
        };
        // Property where to store data for cancellations rate kpi
        this.cancellationsRateData = {
            title: 'YTD Client Cancellations Rate',
            value: ''
        };
        // Property where to store data for referrers rate kpi
        this.referresRateData = {
            title: 'Clients that have referred someone',
            value: ''
        };
        // Property where to store data for referrers rate kpi
        this.revenueRateData = {
            title: 'YTD Revenue Growth Rate',
            value: ''
        };
        // Property where to store data for rolling growth rate kpi
        this.rollingGrowRateData = {
            title: 'Rolling growth rate',
            value: ''
        };
        // Property where to store data for churn growth rate kpi
        this.churnRateData = {
            title: 'Churn',
            value: '',
            description: 'Rolling annual churn rate'
        };
        // Property where to store data for customer satisfaction kpi
        this.customerSatisfactionData = {
            title: 'Satisfaction',
            value: '0%',
            description: 'from customer survey taken ...'
        };
        // Property where to store data for net promoter score kpi
        this.netPromoterScoreData = {
            title: 'NPS',
            value: '0%',
            description: 'Net Promoter Score from customer survey taken ...'
        };
    }
    InvestorDashboardComponent.prototype.ngOnInit = function () {
        this.fromService.setForm(this.form);
        this.getAllData();
    };
    InvestorDashboardComponent.prototype.getAllData = function () {
        // Getting date range and construct request params
        var reqParams = {};
        var dateRange = this.form.value;
        if (dateRange.from && dateRange.to) {
            reqParams.from = dateRange.from;
            reqParams.to = dateRange.to;
            // reqParams.from = `${dateRange.from.year}-${dateRange.from.month}-${dateRange.from.day}`;
            // reqParams.to = `${dateRange.to.year}-${dateRange.to.month}-${dateRange.to.day}`;
        }
        // Graphs
        this.getMonthlyChurnRate(reqParams);
        this.getInstallationsPerMonth(reqParams);
        this.getAnnualGrowthRate(reqParams);
        this.getAnnualRevenueRate(reqParams);
        this.getCash(reqParams);
        this.getCommitedMonthlyRecurringRevenue(reqParams);
        this.getCustomerLifetimeValue(reqParams);
        // this.getAnnualCancellationsRate(reqParams);
        this.getMonthlyReferrersRate(reqParams);
        this.getRecurringRevenue(reqParams);
        // KPIs
        this.getChurnRate(reqParams);
        this.getGrowthRate(reqParams);
        this.getCustomerSatisfactionData();
        this.getNPSData();
        // this.getCancellationsRate(reqParams);
        // this.getReferrersRate(reqParams);
        // this.getRollingGrowthRate(reqParams);
        // this.getRevenueRate(reqParams);
    };
    InvestorDashboardComponent.prototype.showInfo = function () {
        this.swal.info('This is a heat map showing where our clients are located', null, 'Client heat map');
    };
    /**
     * Method to fetch installations per month in current year and update
     * the installationsPerMonthData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getInstallationsPerMonth = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var labels, values, result, error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.installationsPerMonthData.options = {
                            responsive: true,
                            scales: {
                                yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        };
                        labels = [];
                        values = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.investorDashboardService.intalationsPerMonth(reqParams).toPromise()];
                    case 2:
                        result = _a.sent();
                        if (result && result.success) {
                            // Object.keys(result.response).map( monthNumber => {
                            //   if (result.response[monthNumber]){
                            //     labels.push(this.months[parseInt(monthNumber) - 1])
                            //     values.push(result.response[monthNumber])
                            //   }
                            // });
                            labels = Object.keys(result.response);
                            values = Object.values(result.response);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.installationsPerMonthData.labels = labels;
                        this.installationsPerMonthData.datasets = [{
                                label: 'Installations',
                                data: values
                            }];
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch annual growth rate and update
     * the annualGrowthRateData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getAnnualGrowthRate = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.annualGrowthRateData.options = {
                            responsive: true,
                            scales: {
                                yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.investorDashboardService.annualGrowthRate(reqParams).toPromise()];
                    case 2:
                        result = _a.sent();
                        if (result && result.success) {
                            this.annualGrowthRateData.labels = Object.keys(result.response);
                            this.annualGrowthRateData.datasets = [{
                                    label: 'Growth rate (%)',
                                    data: Object.values(result.response)
                                }];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_2 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch annual revenue rate and update
     * the annualRevenueRateData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getAnnualRevenueRate = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.annualRevenueRateData.options = {
                            responsive: true,
                            scales: {
                                yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.investorDashboardService.annualRevenueRate(reqParams).toPromise()];
                    case 2:
                        result = _a.sent();
                        if (result && result.success) {
                            this.annualRevenueRateData.labels = Object.keys(result.response);
                            this.annualRevenueRateData.datasets = [{
                                    label: 'Revenue rate (%)',
                                    data: Object.values(result.response)
                                }];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_3 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch annual cancellations rate and update
     * the annualCancellationsRateData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getAnnualCancellationsRate = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_4;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.annualCancellationsRateData.options = {
                            responsive: true,
                            scales: {
                                yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.investorDashboardService.annualCancellationsRate(reqParams).toPromise()];
                    case 2:
                        result = _a.sent();
                        if (result && result.success) {
                            this.annualCancellationsRateData.labels = Object.keys(result.response);
                            this.annualCancellationsRateData.datasets = [{
                                    label: 'Cancellations rate (%)',
                                    data: Object.values(result.response)
                                }];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_4 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch YTD growth rate and update
     * the growthRateData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getGrowthRate = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_5;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.investorDashboardService.growthRate(reqParams).toPromise()];
                    case 1:
                        result = _a.sent();
                        if (result && result.success) {
                            this.growthRateData.value = result.response + "%";
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_5 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getCustomerSatisfactionData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_6;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.satisfactionService.getLast().toPromise()];
                    case 1:
                        result = _a.sent();
                        if (result && result.success && result.response) {
                            this.customerSatisfactionData.description = "from customer survey taken " + result.response.period;
                            this.customerSatisfactionData.value = result.response.satisfaction + "%";
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_6 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getNPSData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_7;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.npsService.getLast().toPromise()];
                    case 1:
                        result = _a.sent();
                        if (result && result.success && result.response) {
                            this.netPromoterScoreData.description = "Net Promoter Score from customer survey taken " + result.response.period;
                            this.netPromoterScoreData.value = result.response.nps + "%";
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_7 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch YTD cancellations rate and update
     * the cancellationsRateData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getCancellationsRate = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_8;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.investorDashboardService.cancellationsRate(reqParams).toPromise()];
                    case 1:
                        result = _a.sent();
                        if (result && result.success) {
                            this.cancellationsRateData.value = result.response + "%";
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_8 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch referrers rate and update
     * the referresRateData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getReferrersRate = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_9;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.investorDashboardService.referrersRate(reqParams).toPromise()];
                    case 1:
                        result = _a.sent();
                        if (result && result.success) {
                            this.referresRateData.value = result.response + " %";
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_9 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch referrers rate and update
     * the rollingGrowRateData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getRollingGrowthRate = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_10;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.investorDashboardService.rollingGrowthRate(reqParams).toPromise()];
                    case 1:
                        result = _a.sent();
                        if (result && result.success) {
                            this.rollingGrowRateData.value = result.response + " %";
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_10 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch YTD revenue rate and update
     * the growthRateData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getRevenueRate = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_11;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.investorDashboardService.revenueRate(reqParams).toPromise()];
                    case 1:
                        result = _a.sent();
                        if (result && result.success) {
                            this.revenueRateData.value = result.response + " %";
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_11 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch referrals per month in current year and update
     * the monthlyReferrersRateData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getMonthlyReferrersRate = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var labels, values, result, error_12;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.monthlyReferrersRateData.options = {
                            plugins: {
                                datalabels: { display: false }
                            },
                            responsive: true,
                            scales: {
                                yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        };
                        labels = [];
                        values = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.investorDashboardService.monthlyReferrersRate(reqParams).toPromise()];
                    case 2:
                        result = _a.sent();
                        if (result && result.response) {
                            // Object.keys(result.response).map( monthNumber => {
                            //   if (result.response[monthNumber]){
                            //     labels.push(this.months[parseInt(monthNumber) - 1])
                            //     values.push(result.response[monthNumber])
                            //   }
                            // });
                            labels = Object.keys(result.response);
                            values = Object.values(result.response);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_12 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.monthlyReferrersRateData.labels = labels;
                        this.monthlyReferrersRateData.datasets = [{
                                label: 'Referrers Rate (%)',
                                data: values
                            }];
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch YTD char rate and update
     * the churnRateData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getChurnRate = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_13;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.investorDashboardService.churnRate(reqParams).toPromise()];
                    case 1:
                        result = _a.sent();
                        if (result && result.success) {
                            this.churnRateData.value = result.response + "%";
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        error_13 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch churn rate per month in current year and update
     * the monthlyChurnRateData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getMonthlyChurnRate = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var labels, values, result, error_14;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.monthlyChurnRateData.options = {
                            plugins: {
                                datalabels: { display: false }
                            },
                            responsive: true,
                            scales: {
                                yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        };
                        labels = [];
                        values = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.investorDashboardService.monthlyChurnRate(reqParams).toPromise()];
                    case 2:
                        result = _a.sent();
                        if (result && result.success) {
                            // Object.keys(result.response).map( monthNumber => {
                            //   if (result.response[monthNumber]){
                            //     labels.push(this.months[parseInt(monthNumber) - 1])
                            //     values.push(result.response[monthNumber])
                            //   }
                            // });
                            labels = Object.keys(result.response);
                            values = Object.values(result.response);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_14 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        this.monthlyChurnRateData.labels = labels;
                        this.monthlyChurnRateData.datasets = [{
                                label: 'Churn Rate (%)',
                                data: values
                            }];
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch recurring revenue and update
     * the monthlyRecurringRevenueData and annualRecurringRevenueData properties
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getRecurringRevenue = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var labels, values, annualValues, result_1, error_15;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Updating monthly recurring revenue options
                        this.monthlyRecurringRevenueData.options = {
                            plugins: {
                                datalabels: { display: false }
                            },
                            responsive: true,
                            scales: {
                                yAxes: [{
                                        ticks: {
                                            callback: (function (label) { return label.toLocaleString('en'); }),
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        };
                        // Updating annual recurring revenue options
                        this.annualRecurringRevenueData.options = {
                            plugins: {
                                datalabels: { display: false }
                            },
                            responsive: true,
                            scales: {
                                yAxes: [{
                                        ticks: {
                                            callback: (function (label) { return label.toLocaleString('en'); }),
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        };
                        labels = [];
                        values = [];
                        annualValues = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.investorDashboardService.monthlyRecurringRevenue(reqParams).toPromise()];
                    case 2:
                        result_1 = _a.sent();
                        if (result_1 && result_1.success) {
                            Object.keys(result_1.response).map(function (monthNumber) {
                                if (result_1.response[monthNumber]) {
                                    labels.push(monthNumber);
                                    values.push(parseFloat(result_1.response[monthNumber]).toFixed(2));
                                    // tslint:disable-next-line: binary-expression-operand-order
                                    annualValues.push((12 * parseFloat(result_1.response[monthNumber])).toFixed(2));
                                }
                            });
                            // Setting annual recurring revenue data
                            this.monthlyRecurringRevenueData.labels = labels;
                            this.monthlyRecurringRevenueData.datasets = [{
                                    label: 'Revenue',
                                    data: values
                                }];
                            // Setting annual recurring revenue data
                            this.annualRecurringRevenueData.labels = labels;
                            this.annualRecurringRevenueData.datasets = [{
                                    label: 'Revenue',
                                    data: annualValues
                                }];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_15 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch commited monthly recurring revenue and update
     * the monthlyReferrersRateData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getCommitedMonthlyRecurringRevenue = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, error_16;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.commitedMonthlyRecurringRevenueData.options = {
                            plugins: {
                                datalabels: { display: false }
                            },
                            responsive: true,
                            scales: {
                                yAxes: [{
                                        ticks: {
                                            callback: (function (label) { return label.toLocaleString('en'); }),
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.investorDashboardService.commitedMonthlyRecurringRevenue(reqParams).toPromise()];
                    case 2:
                        result = _a.sent();
                        if (result && result.success) {
                            this.commitedMonthlyRecurringRevenueData.labels = Object.keys(result.response);
                            this.commitedMonthlyRecurringRevenueData.datasets = [{
                                    label: 'CMRR',
                                    data: Object.values(result.response)
                                }];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_16 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch customer lifetime value per month in current year and update
     * the customerLifetimeValueData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getCustomerLifetimeValue = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var labels, values, result, error_17;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.customerLifetimeValueData.options = {
                            plugins: {
                                datalabels: { display: false }
                            },
                            responsive: true,
                            scales: {
                                yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        };
                        labels = [];
                        values = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.investorDashboardService.customerLifetimeValue(reqParams).toPromise()];
                    case 2:
                        result = _a.sent();
                        if (result && result.success) {
                            // Object.keys(result.response).map( monthNumber => {
                            //   if (result.response[monthNumber]){
                            //     labels.push(this.months[parseInt(monthNumber) - 1])
                            //     values.push(result.response[monthNumber])
                            //   }
                            // });
                            labels = Object.keys(result.response);
                            values = Object.values(result.response);
                            this.customerLifetimeValueData.labels = labels;
                            this.customerLifetimeValueData.datasets = [{
                                    label: 'CLV',
                                    data: values
                                }];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_17 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Method to fetch cash and update
     * the cashData property
     */
    // tslint:disable-next-line: typedef
    InvestorDashboardComponent.prototype.getCash = function (reqParams) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, labels_1, values_1, error_18;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.cashData.options = {
                            responsive: true,
                            scales: {
                                yAxes: [{
                                        ticks: {
                                            callback: (function (label) { return label.toLocaleString('en'); }),
                                            beginAtZero: true
                                        }
                                    }]
                            }
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.cashService.index(reqParams).toPromise()];
                    case 2:
                        result = _a.sent();
                        if (result && result.success) {
                            labels_1 = [];
                            values_1 = [];
                            result.response.map(function (item) {
                                labels_1.push(item.bal_date);
                                values_1.push(item.balance);
                            });
                            this.cashData.labels = labels_1;
                            this.cashData.datasets = [{
                                    label: 'Cash',
                                    data: values_1
                                }];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_18 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return InvestorDashboardComponent;
}());
export { InvestorDashboardComponent };
