import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../components/modal/modal.component';
import { environment } from '../../../../environments/environment';
import { SwalService } from '../../../services/swal.service';
import { GalleryService } from '../../../services/gallery.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { SharedComponent } from '../../../model/shared-component';
import { GalleryCreateEditComponent } from './gallery-create-edit/gallery-create-edit.component';
import { Lightbox } from 'ngx-lightbox';
import { PermissionsService } from '../../../services/permissions.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  image: any;
  baseUrl = `${environment.apiUrl}`;
  isDistributor: boolean = ![1, 3].includes(JSON.parse(localStorage.getItem('user')).team_id);
  dataTableConfig: any;
  private readonly baseDataTableConfig = {
    config: {
      base: this.galleryService,
      api: 'getPostsDataTable',
      order: [[0, 'desc']]
    },
    columns: [
      {
        display: 'Titulo',
        field: 'title',
        type: 'text'
      },
      {
        display: 'Texto',
        field: 'text',
        type: 'text'
      },
      {
        display: 'Fecha de creación',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'Acciones',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-primary',
            icon: 'fa fa fa-file-image-o fa-fw',
            event: 'post.showImage',
            conditionality: true,
            permissions: ['show-posts']
          },
          {
            cssClass: 'btn btn-info',
            icon: 'fa fa-pencil',
            event: 'post.edit',
            conditionality: true,
            permissions: ['update-posts'],
            onlyForTeam: [1, 3]
          },
          {
            cssClass: 'btn btn-danger',
            icon: 'fa fa-trash',
            event: 'post.delete',
            conditionality: true,
            permissions: ['delete-posts'],
            onlyForTeam: [1, 3]
          }
        ]
      }
    ]
  };

  constructor(
    public appModal: ModalComponent,
    public activeModal: NgbActiveModal,
    private readonly swal: SwalService,
    private readonly lightbox: Lightbox,
    private readonly broadcast: BroadcastService,
    private readonly galleryService: GalleryService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'post.edit': this.editPost(event.data); break;
        case 'post.delete': this.deletePost(event.data); break;
        case 'post.showImage': this.showPostImage(event.data); break;
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * createPost
   * Abre un modal con el formulario para crear un post
   */
  createPost(): void {
    const props: SharedComponent = new SharedComponent(
      GalleryCreateEditComponent,
      {
        status: 'create'
      },
      {
        title: 'Crear post'
      }
    );
    this.appModal.opensm(props);
  }

  /**
   * editPost
   * @param data Información de un post especifico
   * Envia el data hacia el componente GalleryCreateEdit
   * para generar la vista en donde se editara la informacion del post
   */

  editPost(data): void {
    const props: SharedComponent = new SharedComponent(
      GalleryCreateEditComponent,
      {
        postData: data,
        status: 'edit'
      },
      {
        title: `Edición del post ${data.title}`
      }
    );
    this.appModal.opensm(props);
  }

  /**
   * deletePost
   * @param data Información de un post especifico
   * Se le pasa el id_posts para mandar una peticion delete a la API 
   */

  deletePost(data): void {
    const swalParams = {
      title: '¿Estás seguro de querer eliminar el siguiente post?',
      text: 'Se eliminara el post de la base de datos'
    };

    this.swal.warning(swalParams).then(result => {
      if (result.value) {
        this.subscriptions.push(this.galleryService.delete(data.id_posts).subscribe((resp: any) => {
          if (resp.success) {
            this.swal.success({ title: 'Post eliminado exitosamente', text: '' }).then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error('Ocurrio un error al intentar borrar el post');
          }
        }));
      }
    });
  }

  showPostImage(data): void {
    this.subscriptions.push(this.galleryService.getPostImage(data.id_posts).subscribe((resp: any) => {
      if (resp.response !== null) {
        this.image = [{ src: `${this.baseUrl}/posts_files/${resp.response}` }];
        this.lightbox.open(this.image, 0);
      } else {
        this.swal.error({ text: 'No se encontro ninguna imagen' });
      }
    }));
  }

}
