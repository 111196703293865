import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';

// Services
import { BroadcastService } from '../../../services/broadcast.service';
import { InvoicesService } from '../../../services/invoices.service';
import { ModalComponent } from '../../../components/modal/modal.component';
import { PermissionsService } from '../../../services/permissions.service';
import { SwalService } from '../../../services/swal.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit, OnDestroy {
  baseUrl = `${environment.apiUrl}`;
  subscriptions: Array<Subscription> = [];
  message: any;
  dataTableConfig: any;
  private readonly baseDataTableConfig = {
    config: {
      base: this.invoices,
      api: 'getInvoices',
      order: [[0, 'desc']]
    },
    columns: [
      {
        display: 'ID',
        field: 'filename',
        type: 'text'
      },
      {
        display: 'Cliente',
        field: 'client.name',
        type: 'text'
      },
      {
        display: 'Razón social',
        field: 'client.invoice_data.name',
        type: 'text'
      },
      {
        display: 'RFC',
        field: 'client.rfc',
        type: 'text'
      },
      {
        display: 'Fecha',
        field: 'date_stamp',
        type: 'date'
      },
      {
        display: 'Acciones',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-primary',
            icon: 'fa fa-fw fa-file-pdf-o',
            event: 'download.pdf',
            conditionality: 'true',
            permissions: ['show-invoices']
          },
          {
            cssClass: 'btn btn-primary',
            icon: 'fa fa-fw fa-file-code-o',
            event: 'download.xml',
            conditionality: 'true',
            permissions: ['show-invoices']
          },
          {
            cssClass: 'btn btn-primary',
            icon: 'fa fa-fw fa-download',
            event: 'download.zip',
            conditionality: 'true',
            permissions: ['show-invoices']
          },
          {
            cssClass: 'btn btn-primary',
            icon: 'fa fa-fw fa-envelope',
            event: 'send.invoice',
            conditionality: 'true',
            permissions: ['show-invoices']
          }
        ]
      }
    ]
  };

  constructor(
    public appModal: ModalComponent,
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly invoices: InvoicesService,
    private readonly permissionsService: PermissionsService,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'download.pdf': this.downloadPdf(event.data); break;
        case 'download.xml': this.downloadXml(event.data); break;
        case 'download.zip': this.downloadZip(event.data); break;
        case 'send.invoice': this.sendInvoice(event.data); break;
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  downloadPdf(data): void {
    window.open(`${this.baseUrl}/invoices_files/${data.filename}.pdf`, '_blank');
    // document.location.href = `${this.baseUrl}/invoices_files/${data.filename}.pdf`;
  }
  downloadXml(data): void {
    window.open(`${this.baseUrl}/invoices_files/${data.filename}.xml`, '_blank');
    // document.location.href = `${this.baseUrl}/invoices_files/${data.filename}.xml`;
  }
  downloadZip(data): void {
    window.open(`${this.baseUrl}/invoices/download_files/${data.id_invoices}`, '_blank');
  }
  sendInvoice(data): void {
    const swalParams = {
      title: 'Envio de Factura',
      text: 'Proporciona el mensaje al cliente',
      inputPlaceholder: `Factura de ${data.name}`
    };
    this.swal.input(swalParams).then((resp: any) => {
      if (resp.value) {
        const params = {
          id_invoices: data.id_invoices,
          message: resp.value
        };
        this.subscriptions.push(this.invoices.sendInvoice(params).subscribe((subresp: any) => {
          this.swal.success().then(() => {
            this.activeModal.dismiss();
            this.broadcast.reloadDataTable();
          });
        }));
      }
    }).catch();
  }
}
