declare var google: any;

import { Component, OnInit } from '@angular/core';
import { InvestorDashboardService } from '../../../../services/investor-dashboard.service';
// import { MapService } from '../../../../services/map.service';
@Component({
  selector: 'app-clients-heatmap',
  templateUrl: './clients-heatmap.component.html',
  styleUrls: ['./clients-heatmap.component.scss']
})
export class ClientsHeatmapComponent implements OnInit {
  longitude = -103.350693;
  latitude = 20.39079895;
  zoom = 8;
  private map: google.maps.Map = null;
  private heatmap: google.maps.visualization.HeatmapLayer = null;

  constructor(
    // private mapService: MapService
    private readonly investorDashboardService: InvestorDashboardService
  ) { }

  ngOnInit(): void {
    // this.mapService.buildMap();
  }

  // tslint:disable-next-line: typedef
  async onMapLoad(mapInstance: google.maps.Map) {
    const _coords = await this.investorDashboardService.getClientsCoordinates().toPromise();
    const coords = _coords.map(c => new google.maps.LatLng(c.latitude, c.longitude));
    this.map = mapInstance;
    this.heatmap = new google.maps.visualization.HeatmapLayer({
      map: this.map,
      maxIntensity: 5,
      radius: 40,
      data: coords
    });
  }
}
