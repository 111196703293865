/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./modal-body/modal-body.component.ngfactory";
import * as i3 from "./modal-body/modal-body.component";
import * as i4 from "../../../../node_modules/ngx-loading/ngx-loading.ngfactory";
import * as i5 from "ngx-loading";
import * as i6 from "./modal.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "@ngx-loading-bar/core";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("Cross click") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-modal-body", [], null, null, null, i2.View_ModalBodyComponent_0, i2.RenderType_ModalBodyComponent)), i1.ɵdid(7, 114688, null, 0, i3.ModalBodyComponent, [i1.ComponentFactoryResolver], { props: [0, "props"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "ngx-loading", [], null, null, null, i4.View_NgxLoadingComponent_0, i4.RenderType_NgxLoadingComponent)), i1.ɵdid(9, 114688, null, 0, i5.NgxLoadingComponent, [i5.NgxLoadingService, i1.ChangeDetectorRef], { show: [0, "show"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.props; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.config == null) ? null : _co.config.title) || "value"); _ck(_v, 2, 0, currVal_0); }); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 245760, null, 0, i6.ModalComponent, [i7.NgbActiveModal, i7.NgbModal, i8.LoadingBarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i6.ModalComponent, View_ModalComponent_Host_0, { props: "props", config: "config" }, {}, []);
export { ModalComponentNgFactory as ModalComponentNgFactory };
