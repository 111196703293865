<div class="container-fluid text-center">
    <app-form-field type="select" [options]="{
        label: 'Selecciona a que clientes deseas cambiar el grupo de ventas',
        path: 'internalData.updateSaleGroupOption',
        droptext: 'Seleccionar una opción',
        elements: updateSaleGroupOption
    }"></app-form-field>

    <div *ngIf="showClients">
        <app-form-field type="multiselect" [options]="{
            multiple: true,
            values: clients,
            label: 'Clientes',
            placeholder: 'Selecciona al cliente',
            searchable: true,
            virtualScroll: true,
            path: 'id_clients'
        }">
        </app-form-field>

    </div>
    <app-form-field type="select" [options]="{
            label: 'Selecciona el grupo de ventas',
            path: 'id_group',
            droptext: 'Seleccionar un grupo de ventas',
            elements: groups}">
    </app-form-field>
</div>

<br>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="updateClientsSalesGroup()">Cambiar grupo de ventas</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>