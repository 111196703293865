import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { FromService } from '../../../providers/form.service';
import { EmailsTemplatesService } from '../../../services/emails-templates.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-emails-templates',
  templateUrl: './emails-templates.component.html',
  styleUrls: ['./emails-templates.component.scss']
})
export class EmailsTemplatesComponent implements OnInit, OnDestroy {
  email_view: any;
  baseUrl = `${environment.apiUrl}`;
  subscriptions: Array<Subscription> = [];
  emails = [];
  form: FormGroup = this.formBuilder.group({
    email: ['']
  });

  constructor(
    private readonly emailsTemplateService: EmailsTemplatesService,
    private readonly fromService: FromService,
    private readonly formBuilder: FormBuilder,
    private readonly sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getEmails();
    this.fromService.setForm(this.form);
    this.getEmailView();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * getEmails
   * Obtiene la lista de emails en la API
   */
  private getEmails(): void {
    this.subscriptions.push(this.emailsTemplateService.getEmailsList().subscribe((resp: any) => {
      this.emails = resp.response;
    }));
  }

  /**
   * getEmailView
   * Obtiene la vista del email seleccionado
   */
  private getEmailView(): void {
    this.subscriptions.push(this.form.get('email').valueChanges.subscribe(selectedView => {
      const params = {
        name: selectedView
      };

      if (selectedView !== '') {
        this.subscriptions.push(this.emailsTemplateService.getEmailView(params).subscribe((resp: any) => {
          this.email_view = this.sanitizer.bypassSecurityTrustHtml(resp);
        }));
      }
    }));
  }

}
