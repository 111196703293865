import { SuppliesComponent } from "./supplies/supplies.component";
import { SuppliesCategoriesComponent } from "./supplies-categories/supplies-categories.component";
var ɵ0 = { role: 'warehoseAdmin', name: 'Administrador bodega' };
export var WarehouseRoutes = [
    {
        path: 'insumos',
        data: ɵ0,
        component: SuppliesComponent
    },
    {
        path: 'insumos-categorias',
        component: SuppliesCategoriesComponent
    },
];
export { ɵ0 };
