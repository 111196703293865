/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./plans.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-permissions";
import * as i3 from "../../../../components/datatable/datatable.component.ngfactory";
import * as i4 from "../../../../components/datatable/datatable.component";
import * as i5 from "../../../../services/broadcast.service";
import * as i6 from "./plans.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../../services/groups.service";
import * as i9 from "../../../../components/modal/modal.component";
import * as i10 from "../../../../services/swal.service";
import * as i11 from "../../../../services/permissions.service";
var styles_PlansComponent = [i0.styles];
var RenderType_PlansComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlansComponent, data: {} });
export { RenderType_PlansComponent as RenderType_PlansComponent };
function View_PlansComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-success align-items-end"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createPlanItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["A\u00F1adir plan"]))], null, null); }
export function View_PlansComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_PlansComponent_1)), i1.ɵdid(4, 737280, null, 0, i2.NgxPermissionsDirective, [i2.NgxPermissionsService, i2.NgxPermissionsConfigurationService, i2.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "app-datatable", [], null, null, null, i3.View_DatatableComponent_0, i3.RenderType_DatatableComponent)), i1.ɵdid(8, 245760, null, 0, i4.DatatableComponent, [i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i5.BroadcastService], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, "create-groups"); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.dataTableConfig; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_PlansComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-plans", [], null, null, null, View_PlansComponent_0, RenderType_PlansComponent)), i1.ɵdid(1, 245760, null, 0, i6.PlansComponent, [i7.NgbActiveModal, i8.GroupsService, i5.BroadcastService, i9.ModalComponent, i10.SwalService, i11.PermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlansComponentNgFactory = i1.ɵccf("app-plans", i6.PlansComponent, View_PlansComponent_Host_0, { data: "data" }, {}, []);
export { PlansComponentNgFactory as PlansComponentNgFactory };
