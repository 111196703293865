import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WithdrawalsService {
  baseUrl = `${environment.apiUrl}`;

  constructor(private readonly http: HttpClient) { }
  
  /**
   * getWithdrawalsTable
   * Envia una peticion post para obtener los parametros basicos para poder
   * crear la tabla de retiros
   * @param params parametros por los cuales se va a definir el orden de la tabla, el numero de records que se mostraran por pagina, etc.
   * @param filters filtros que se pueden mandar a la API para filtrar los resultados de la petición POST
   * @returns array con los parametros para que datatable.component.ts pueda dibujar la tabla.
   */
  getWithdrawalsTable(params?, filters?): Observable<any> {
    Object.assign(params, filters);
    
    return this.http.post(`${this.baseUrl}/withdrawals/getWithdrawalsTable`, params);
  }

  /**
   * getWithdrawalsTotal
   * Obtiene el total de los retiros
   * @returns respuesta con el total de los retiros que se han hecho.
   */
  getWithdrawalsTotal(): Observable <any> {
    return this.http.get(`${this.baseUrl}/withdrawals/getWithdrawalsTotal`);
  }

  /**
   * createWithdrawal
   * Envia una petición POST a la API con los parametros para crear el registro de un retiro.
   * @param params amount: cantidad del retiro, reason: razón por la cual se realiza el retiro 
   * @returns 200|500
   */
  createWithdrawal(params): Observable <any> {
    return this.http.post(`${this.baseUrl}/withdrawals`, params);
  }
}
