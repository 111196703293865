import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AdvertisingService = /** @class */ (function () {
    function AdvertisingService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    AdvertisingService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/admin_dashboard/money-spent-advertising", params);
    };
    AdvertisingService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/admin_dashboard/money-spent-advertising/" + id);
    };
    AdvertisingService.prototype.edit = function (id, params) {
        return this.http.put(this.baseUrl + "/admin_dashboard/money-spent-advertising/" + id, params);
    };
    AdvertisingService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/admin_dashboard/money-spent-advertising/" + id);
    };
    AdvertisingService.prototype.index = function (params) {
        return this.http.get(this.baseUrl + "/admin_dashboard/money-spent-advertising", params);
    };
    AdvertisingService.prototype.getAdvertising = function (params) {
        return this.http.post(this.baseUrl + "/admin_dashboard/get-money-spent-advertising", params);
    };
    AdvertisingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdvertisingService_Factory() { return new AdvertisingService(i0.ɵɵinject(i1.HttpClient)); }, token: AdvertisingService, providedIn: "root" });
    return AdvertisingService;
}());
export { AdvertisingService };
