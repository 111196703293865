import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
// Services
import { RolesService } from '../../../services/roles.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { SwalService } from '../../../services/swal.service';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoleCreateEditComponent } from './role-create-edit/role-create-edit.component';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  dataTableConfig = {
    config: {
      base: this.rolesService,
      api: 'getRoles'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Nombre a mostrar',
        field: 'display_name',
        type: 'text'
      },
      {
        display: 'Descripción',
        field: 'description',
        type: 'text'
      },
      {
        display: 'Fecha de alta',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: [

          {
            display: 'Editar',
            icon: 'fa fa-pencil',
            event: 'role.roleEdit',
            conditionality: 'true',
            permissions: ['update-roles']
          }

        ]
      }
    ]
  };

  actionEventSubscription: Subscription;

  constructor(
    private readonly rolesService: RolesService,
    private readonly broadcast: BroadcastService,
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    public swal: SwalService,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.actionEventSubscription = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'role.roleEdit': this.roleEditItem(event.data); break;
      }
    });
  }

  createRole(data): void {
    const props: SharedComponent = new SharedComponent(
      RoleCreateEditComponent,
      {
        roleData: data,
        status: 'create'
      },
      {
        title: 'Crear rol'
      }
    );
    this.appModal.openXl(props);
  }

  roleEditItem(data): void {
    const props: SharedComponent = new SharedComponent(
      RoleCreateEditComponent,
      {
        role: data,
        status: 'edit'
      },
      {
        title: 'Editar datos del rol'
      });
    this.appModal.openXl(props);
  }
}
