import { OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../components/modal/modal.component';
import { BroadcastService } from '../../../services/broadcast.service';
import { ClientsService } from '../../../services/clients.service';
import { SwalService } from '../../../services/swal.service';
import { environment } from '../../../../environments/environment';
import { PromotionalMonthlyFeesService } from '../../../services/promotional-monthly-fees.service';
var ClientPromotionalsMonthlyFeesComponent = /** @class */ (function () {
    function ClientPromotionalsMonthlyFeesComponent(activeModal, appModal, broadcast, clientsService, promotionalsMonthlyFeesService, swalService) {
        this.activeModal = activeModal;
        this.appModal = appModal;
        this.broadcast = broadcast;
        this.clientsService = clientsService;
        this.promotionalsMonthlyFeesService = promotionalsMonthlyFeesService;
        this.swalService = swalService;
        this.subscriptions = [];
        this.activePromotions = {};
        this.notSupportedEvents = environment.notSupportedEvents;
        this.dataTableConfig = {
            config: {
                base: this.clientsService,
                api: 'getClientPromotionalMonthlyFee',
                params: {
                    id_clients: 0
                }
            },
            columns: [
                {
                    display: 'Promoción',
                    field: 'current_promotional_data.name',
                    type: 'text'
                },
                {
                    display: 'Mensualidad promocional',
                    field: 'current_promotional_data.monthsPrice',
                    type: 'number'
                },
                {
                    display: '# de meses promocionales',
                    field: 'original_promotional_data.numberOfMonths',
                    type: 'text'
                },
                {
                    display: '# de meses promocionales restantes',
                    field: 'current_promotional_data.numberOfMonths',
                    type: 'text'
                },
                {
                    display: 'Estatus',
                    field: 'status',
                    type: 'boolean'
                },
                {
                    display: 'Fecha de adición',
                    field: 'created_at',
                    type: 'date'
                },
                {
                    display: 'Ultima actualización',
                    field: 'updated_at',
                    type: 'date'
                },
                {
                    display: 'Opciones',
                    field: '',
                    type: 'actions',
                    options: [
                        {
                            display: 'Cancelar mes promocional',
                            event: 'cancelPromotionalMonthlyFee',
                            conditionality: 'this.data.status === 1',
                            permissions: ['update-promotional-monthly-fees']
                        }
                    ]
                }
            ],
            filters: [{}]
        };
    }
    ClientPromotionalsMonthlyFeesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.broadcast.events.subscribe(function (event) {
            if (!_this.notSupportedEvents.includes(event.name)) {
                if (_this[event.name]) {
                    _this[event.name](event.data);
                }
            }
        }));
        this.client = this.data;
        this.dataTableConfig.config.params.id_clients = this.data.id_clients;
    };
    ClientPromotionalsMonthlyFeesComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    /**
     * assignPromotionalMonthlyFee
     * Manda una solicitud a la API con el ID de la promoción que se desea asignar al cliente
     */
    ClientPromotionalsMonthlyFeesComponent.prototype.assignPromotionalMonthlyFee = function (client) {
        var _this = this;
        this.subscriptions.push(this.promotionalsMonthlyFeesService.getActivePromotionals().subscribe(function (resp) {
            if (resp.success) {
                resp.response.forEach(function (promotion) {
                    var _a;
                    var formatedOption = (_a = {}, _a[promotion.id_promotional_monthly_fee] = promotion.name, _a);
                    Object.assign(_this.activePromotions, formatedOption);
                });
                var inputParams = {
                    options: _this.activePromotions,
                    text: 'Seleccione la promoción deseada',
                    confirmButtonText: 'Asignar promoción'
                };
                _this.swalService.select(inputParams).then(function (selectResponse) {
                    if (selectResponse !== '') {
                        var params = { id_promotional_monthly_fee: Number(selectResponse.value), id_clients: client.id_clients };
                        _this.subscriptions.push(_this.clientsService.assignPromotionalMonthlyFee(params).subscribe(function (assignResp) {
                            if (assignResp.success) {
                                _this.swalService.success().then(function () {
                                    _this.activeModal.dismiss();
                                    _this.broadcast.reloadDataTable();
                                });
                            }
                        }));
                    }
                });
            }
        }));
    };
    /**
     * cancelPromotionalMonthlyFee
     * Manda una solicitud para actualizar el estatus de una promocion de mensualidad asignada a un cliente
     */
    ClientPromotionalsMonthlyFeesComponent.prototype.cancelPromotionalMonthlyFee = function (promotion) {
        var _this = this;
        var id_swal = 'cancel-promotion';
        var swalParams = {
            confirmButtonText: 'Cancelar meses promocionales',
            id: id_swal,
            inputs: [
                "<div id=" + id_swal + ">" +
                    '<input id="cancellation_reason" type="text", placeholder="Razón" class="swal2-input" required>' +
                    '</div>'
            ]
        };
        this.swalService.customForm(swalParams).then(function (formValues) {
            var payload = { id_client_promotional: promotion.id_client_promotional };
            formValues.value.forEach(function (element) {
                Object.assign(payload, { 'reason': element });
            });
            _this.subscriptions.push(_this.clientsService.cancelPromotion(payload).subscribe(function (response) {
                if (response.success) {
                    _this.swalService.success().then(function () {
                        _this.broadcast.reloadDataTable();
                    });
                }
                else {
                    _this.swalService.error({ text: response.message });
                }
            }));
        });
    };
    return ClientPromotionalsMonthlyFeesComponent;
}());
export { ClientPromotionalsMonthlyFeesComponent };
