import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ElementsService = /** @class */ (function () {
    function ElementsService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    ElementsService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/elements", params);
    };
    ElementsService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/elements/" + id);
    };
    ElementsService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/elements/" + id);
    };
    ElementsService.prototype.update = function (id, params) {
        return this.http.put(this.baseUrl + "/elements/" + id, params);
    };
    ElementsService.prototype.getElements = function (params, filters) {
        var id = 0;
        if (filters) {
            id = filters.id_categories;
        }
        return this.http.post(this.baseUrl + "/elements/" + id + "/get_elements", params);
    };
    ElementsService.prototype.getImage = function (id) {
        return this.http.get(this.baseUrl + "/elements/" + id + "/getImage");
    };
    ElementsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ElementsService_Factory() { return new ElementsService(i0.ɵɵinject(i1.HttpClient)); }, token: ElementsService, providedIn: "root" });
    return ElementsService;
}());
export { ElementsService };
