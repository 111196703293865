import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-iot-label',
  templateUrl: './iot-label.component.html',
  styleUrls: ['./iot-label.component.scss']
})
export class IotLabelComponent implements OnInit {
  @Input() data: any;
  @Input() options: any;
  text: string;
  statuses: Array<string> = ['danger', 'success'];

  ngOnInit(): void {
    switch (this.options) {
      case 'connected': {
        // tslint:disable-next-line: no-string-literal
        this.text = this.data['connected'] ? 'Connected' : 'Disconnected';
        break;
      }
      case 'status': {
        // tslint:disable-next-line: no-string-literal
        this.text = this.data['status'] ? 'Active' : 'Inactive';
        break;
      }
    }
  }

}
