import { AuthorizationService } from '../services/authorization.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/authorization.service";
var AuthorizationInterceptorService = /** @class */ (function () {
    function AuthorizationInterceptorService(authService) {
        this.authService = authService;
    }
    AuthorizationInterceptorService.prototype.intercept = function (request, next) {
        if (this.authService.token) {
            request = this.addToken(request, this.authService.token);
        }
        return next.handle(request);
    };
    // tslint:disable-next-line: prefer-function-over-method
    AuthorizationInterceptorService.prototype.addToken = function (request, token) {
        return request.clone({
            setHeaders: {
                Authorization: "Bearer " + token
            },
            withCredentials: true
        });
    };
    AuthorizationInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizationInterceptorService_Factory() { return new AuthorizationInterceptorService(i0.ɵɵinject(i1.AuthorizationService)); }, token: AuthorizationInterceptorService, providedIn: "root" });
    return AuthorizationInterceptorService;
}());
export { AuthorizationInterceptorService };
