<div class="container-fluid bg-white p-3 mb-4">
    <div class="table-responsive" *ngIf="client && failed_cards">
        <table class="table table-striped table-hover">
            <thead>
                <tr>
                    <th>Tarjeta</th>
                    <th>Exp</th>
                    <th>Marca</th>
                    <th>Categoria</th>
                    <!-- <th>Http code</th> -->
                    <th>Código de error</th>
                    <!-- <th>ID de la petición</th> -->
                    <th>Error</th>
                    <th>Intento de registro</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let card_failed of failed_cards">
                    <td>{{card_failed.card_number}}</td>
                    <td>{{card_failed.exp_month}} / {{card_failed.exp_year}}</td>
                    <td>{{card_failed.brand | uppercase}}</td>
                    <td>{{card_failed.category}}</td>
                    <!-- <td>{{card_failed.http_code}}</td> -->
                    <td>{{card_failed.error_code}}</td>
                    <!-- <td>{{card_failed.request_id}}</td> -->
                    <td>{{card_failed.failure_message}}</td>
                    <td>{{card_failed.created_at}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('close button')">Close</button>
</div>