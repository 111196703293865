import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-history-change',
  templateUrl: './client-history-change.component.html',
  styleUrls: ['./client-history-change.component.scss']
})
export class ClientHistoryChangeComponent implements OnInit {
  @Input('item') item: any;
  before_data: any;
  after_data: any;
  before_data_json = {};
  after_data_json = {};
  extra_info = {authorized_by: '', reason: ''};
  show = false;

  ngOnInit(): void {
    this.evaluateLogType();
  }

  evaluateLogType(): void {
    switch (this.item.type) {
      case 'client_log':
        this.checkDifference();
        break;
      case 'client_log_prices':
        this.show = true;
        this.setClientLogPrices();
        break;
    }
  }

  /**
   * setClientLogPrices
   * Setea la información para mostrarla en el historial de precios
   */
  setClientLogPrices(): void {
    this.before_data = this.item.element.before_data;
    this.after_data =  this.item.element.after_data;
    this.extra_info.authorized_by = this.item.element.authorized_by.name;
    this.extra_info.reason = this.item.element.reason;
  }

  /**
   * checkDifference
   * Evalua y filtra los campos del input item.element para devolver unicamente los que tengan una diferencia. 
   */
  checkDifference(): void {
    const before_data = JSON.parse(this.item.element.before_data);
    const after_data = JSON.parse(this.item.element.after_data);
    Object.entries(after_data).forEach(entry => {
      const [key, after_data_val] = entry;
      const before_data_val = before_data[key];

      if (after_data_val !== before_data_val) {
        this.after_data_json[key] = after_data_val;

        if (before_data.hasOwnProperty(key)) {
          this.before_data_json[key] = before_data_val;
        } else {
          this.before_data_json = [];
        }
        this.before_data = this.before_data_json;
        this.after_data = this.after_data_json;
      }
    });
  }

}
