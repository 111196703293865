import { Component, OnInit, Input } from '@angular/core';
import { BroadcastService } from '../../../../../services/broadcast.service';

@Component({
  selector: 'app-datatable-column-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  @Input() data: any;
  @Input() options: any;
  @Input() trRef: any;

  constructor(private readonly broadcast: BroadcastService) { }

  ngOnInit(): void {
    this.setOptForParentAccount();
  }

  isVisible(conditionallity?): boolean {
    return eval(conditionallity);
  }

  fireEvent(event): void {
    Object.assign(this.data, { ref: this.trRef });
    this.broadcast.fire({ name: event, data: this.data });
  }

  private setOptForParentAccount(): void {
    if (this.data.is_parent) {
      const optOnlyForParent = this.options.filter(option => option.showOnlyForParent);
      if (optOnlyForParent.length > 0) {
        this.options = optOnlyForParent;
      }
    }
  }
}
