import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { SalesDashboardService } from '../../../../services/sales-dashboard.service';
import { SwalService } from '../../../../services/swal.service';
var SalesAgentChartCustomComponent = /** @class */ (function () {
    function SalesAgentChartCustomComponent(formBuilder, swal, salesDashboard) {
        this.formBuilder = formBuilder;
        this.swal = swal;
        this.salesDashboard = salesDashboard;
        this.isLoad = true;
        this.barChartColors = [
            { backgroundColor: '#ff843c' },
            { backgroundColor: '#83b941' },
            { backgroundColor: '#16a4db' }
        ];
        this.months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        this.barChartOptions = {
            responsive: true,
            // We use these empty structures as placeholders for dynamic theming.
            scales: {
                xAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }],
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ]
            },
            plugins: {
                plugins: {
                    datalabels: {
                        color: 'black',
                        labels: {
                            title: {
                                font: {
                                    weight: 'bold'
                                }
                            },
                            value: {
                                color: 'black'
                            }
                        }
                    }
                }
            }
        };
        this.barChartLabels = [];
        this.barChartType = 'bar';
        this.barChartLegend = true;
        this.barChartPlugins = [pluginDataLabels];
        this.labelsValue = [];
        this.barChartData = [];
        this.form = this.formBuilder.group({
            from: [],
            to: [],
            agent: ''
        });
        this.agents = [];
        this.leadTypes = [];
    }
    SalesAgentChartCustomComponent.prototype.ngOnInit = function () {
        this.fieldFrom = this.form.get('from');
        this.fieldTo = this.form.get('to');
        this.fieldAgent = this.form.get('agent');
        this.setChart();
    };
    SalesAgentChartCustomComponent.prototype.search = function () {
        var fromSub = this.form.get('from').value;
        var toSub = this.form.get('to').value;
        var fieldAgent = this.form.get('agent').value;
        var params = {
            from: fromSub,
            to: toSub,
            agent: fieldAgent
        };
        if (params.from && params.to) {
            this.setChart(params);
        }
        else {
            this.swal.error({ title: 'Favor de introducir las fechas correctamente' });
        }
    };
    SalesAgentChartCustomComponent.prototype.setChart = function (dateRangeParams) {
        var _this = this;
        this.isLoad = true;
        this.labelsValue = [];
        this.salesDashboard.owners().subscribe(function (res) {
            if (res.success) {
                if (_this.agents.length === 0) {
                    _this.agents.push({ id: '', name: 'Todos' });
                    for (var _i = 0, _a = res.response; _i < _a.length; _i++) {
                        var option = _a[_i];
                        _this.agents.push({ id: option.ownerId, name: option.firstName + " " + option.lastName });
                    }
                }
                _this.salesDashboard.salesByAgentCustom(dateRangeParams).subscribe(function (resp) {
                    // getting labels            
                    if (resp.success) {
                        var barChartLabelData = [];
                        var keys_2 = Object.keys(resp.response.data).sort();
                        for (var _i = 0, keys_1 = keys_2; _i < keys_1.length; _i++) {
                            var index = keys_1[_i];
                            if (index) {
                                var ownerId = index.split('-')[1];
                                for (var _a = 0, _b = _this.agents; _a < _b.length; _a++) {
                                    var agent = _b[_a];
                                    if (+agent.id === +ownerId) {
                                        barChartLabelData.push(agent.name);
                                    }
                                }
                            }
                            // this.barChartLabels = barChartLabelData;
                        }
                        _this.barChartLabels = barChartLabelData.slice();
                        var nameParam = {
                            name: 'lead_promocion'
                        };
                        _this.salesDashboard.contactProperty(nameParam).subscribe(function (response) {
                            if (response.success) {
                                var barChartDataL = [];
                                var closeRateData = [];
                                var _loop_1 = function (option) {
                                    var countByData = [];
                                    for (var _i = 0, keys_4 = keys_2; _i < keys_4.length; _i++) {
                                        var index = keys_4[_i];
                                        var arrayByLabel = resp.response.data[index].filter(function (contact) {
                                            return contact.lead_promocion.indexOf(option.value) > -1;
                                        });
                                        countByData.push(arrayByLabel.length);
                                        if (option.value === 'lead_ganado') {
                                            closeRateData[index] = tslib_1.__assign({}, closeRateData[index], { lead_ganado: arrayByLabel.length });
                                            // this.closeRateData[index].push({ lead_ganado: arrayByLabel.length });                  
                                        }
                                        if (option.value === 'lead_perdido') {
                                            closeRateData[index] = tslib_1.__assign({}, closeRateData[index], { lead_perdido: arrayByLabel.length });
                                        }
                                    }
                                    var data = { data: countByData, label: option.label, yAxisID: 'y-axis-1' };
                                    barChartDataL.push(data);
                                };
                                for (var _i = 0, _a = response.response.data.data.options; _i < _a.length; _i++) {
                                    var option = _a[_i];
                                    _loop_1(option);
                                }
                                var closeRateChart = [];
                                for (var _b = 0, keys_3 = keys_2; _b < keys_3.length; _b++) {
                                    var index = keys_3[_b];
                                    if (closeRateData[index].lead_ganado > 0 && closeRateData[index].lead_perdido > 0) {
                                        var closeRate = (closeRateData[index].lead_ganado /
                                            // tslint:disable-next-line: restrict-plus-operands
                                            (closeRateData[index].lead_ganado + closeRateData[index].lead_perdido)) * 100;
                                        var valueFinal = +closeRate.toFixed(2);
                                        closeRateChart.push(valueFinal);
                                    }
                                    else {
                                        closeRateChart.push(0);
                                    }
                                }
                                // cuantos lead ganados por facebok ad lead, etc
                                var closeRateDataChart = {
                                    data: closeRateChart, label: 'Close Rate', type: 'line', datalabels: {
                                        formatter: function (value, context) {
                                            return value + "%";
                                        }
                                    },
                                    yAxisID: 'y-axis-2'
                                };
                                barChartDataL.push(closeRateDataChart);
                                // group by options            
                                _this.barChartData = barChartDataL.slice();
                                // this.barChartData = barChartDataL;
                                _this.isLoad = false;
                            }
                        });
                    }
                    else {
                        _this.swal.error({ title: 'Error recuperando los datos para la gráfica ventas por mes, intente de nuevo' });
                    }
                });
            }
        });
    };
    SalesAgentChartCustomComponent.prototype.showInfoGraphic = function () {
        var message = 'How many closed won, lost and valid based on leads generated that belongs an agent filtered by date range and agent. Close rate: closed won/closed lost';
        this.swal.info(message);
    };
    return SalesAgentChartCustomComponent;
}());
export { SalesAgentChartCustomComponent };
