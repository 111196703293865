<div class="modal-body" *ngIf="client !== undefined">
    <div class="container">
        <div class="row">
            <div class="col">
                <app-form-field type="select" [options]="{
                        label: 'Tipo de págo',
                        width:12,
                        droptext: 'Seleccione una opción...',
                        elements: payment_types,
                        path:'payment_types'
                    }">
                </app-form-field>

                <!-- 
                    ESTE CAMPO SOLO DEBERIA APARECER SI:
                    1) Es 1er cargo
                    2) Es cargo regular, pero no tiene deudas
                -->

                <app-form-field
                    *ngIf="client.debt > 0 && client.collected_at != null && client_pending_debts.length > 1"
                    type="select" [options]="{
                    label: 'Como desea pagar la deuda',
                    width:12,
                    droptext: 'Seleccione una opción...',
                    elements: payment_debt_options,
                    path:'payment_debt_option'
                }">
                </app-form-field>
                <app-form-field *ngIf="partial_payment" type="select" [options]="{
                    label: 'Seleccione los meses de deuda que desea pagar',
                    width:12,
                    droptext: 'Seleccione una opción...',
                    elements: number_debts,
                    path:'number_debts_selected'
                }">
                </app-form-field>
                <app-form-field *ngIf="(client.debt > 0 && !client.active_promotional_monthly_fee) || data.firstCharge"
                    type="switch" [options]="{
                    label: '¿Desea añadir meses por adelantado?', 
                    path: 'add_months_ahead', 
                    trueValue: 1, 
                    falseValue: 0, 
                    trueCaption: 'Si',
                    falseCaption: 'No'}">
                </app-form-field>
                <app-form-field *ngIf="show_months_ahead" type="select" [options]="{
                label: 'Pagar X meses por adelantado',
                width:12,
                droptext: 'Seleccione una opción...',
                elements: months_ahead,
                path:'months_ahead'}">
                </app-form-field>
                <div *ngIf="active_reference || last_card_charge">
                    <div *ngIf="last_card_charge">
                        <button type="button" class="btn btn-info" (click)="showChargeInfo('card')">Ultimo cargo con
                            tarjeta</button>
                        <button type="button" class="btn btn-danger" (click)="cancelLastCharge('card')">Remover bloqueo
                            de tarjeta</button>
                    </div>
                    <br>
                    <div class="bt-group" *ngIf="active_reference">
                        <button type="button" class="btn btn-info" (click)="showChargeInfo('reference')">Referencia
                            activa</button>
                        <button type="button" class="btn btn-danger" (click)="cancelLastCharge('reference')">Cancelar
                            referencia activa</button>
                    </div>
                </div>
            </div>

            <div class="col">
                <p *ngIf="client.active_promotional_monthly_fee">
                    Este cliente cuenta con una promoción activa por lo que no se pueden añadir meses por adelantado
                </p>
                <h5>TOTAL</h5>
                <br>
                <div *ngIf="data.firstCharge">
                    <dl class="row">
                        <dt class="col-sm-3">Instalación</dt>
                        <dd class="col-sm-9">{{client.installation_fee /100 | currency}}</dd>
                        <dt class="col-sm-3">Deposito</dt>
                        <dd class="col-sm-9">{{data.contract.deposit | currency}}</dd>
                        <dt class="col-sm-3">Extras total</dt>
                        <dd class="col-sm-9">{{data.contract.extras | currency}}</dd>
                    </dl>
                </div>
                <div *ngIf="data.client.debt > 0">
                    <dl class="row">
                        <dt class="col-sm-3">Deuda</dt>
                        <dd class="col-sm-9">{{client.debt/100 | currency}}</dd>
                        <!-- <dt class="col-sm-3">Cuota de administración</dt>
                        <dd class="col-sm-9">$100.00</dd> -->
                        <!-- <div *ngIf="show_moths_ahead"> -->
                        <dt *ngIf="showMonthlyFeeOnDebt && !client.active_promotional_monthly_fee" class="col-sm-3">
                            Mensualidad</dt>
                        <dd *ngIf="showMonthlyFeeOnDebt && !client.active_promotional_monthly_fee" class="col-sm-9">
                            {{(data.contract.monthly_fee + data.contract.sr) | currency}}</dd>
                        <!-- </div> -->
                        <dt class="col-sm-3">Total a pagar</dt>
                        <dd class="col-sm-9">{{totalManualCharge() | currency}}</dd>
                    </dl>
                </div>
                <div *ngIf="data.client.debt < 1">
                    <div *ngIf="data.firstCharge; else regularCharge">
                        <dl class="row">
                            <dt class="col-sm-3">1° Cargo</dt>
                            <dd class="col-sm-9">{{data.contract.first_charge | currency}}</dd>
                        </dl>
                    </div>
                    <ng-template #regularCharge>
                        <dl class="row">
                            <dt class="col-sm-3">Cuota mensual</dt>
                            <dd class="col-sm-9">{{data.contract.monthly_fee | currency}}</dd>
                            <!-- <dt class="col-sm-3">Cuota de administración</dt>
                            <dd class="col-sm-9">$100.00</dd> -->
                        </dl>
                    </ng-template>

                    <div *ngIf="data.client.social_responsability == 1">
                        <dl class="row">
                            <dt class="col-sm-3">Responsabilidad Social</dt>
                            <dd class="col-sm-9">{{data.contract.sr | currency}}</dd>
                        </dl>
                    </div>

                    <div *ngIf="show_months_ahead && data.firstCharge">
                        <h4>Meses por adelantado</h4>
                        <dl class="row">
                            <dt class="col-sm-3">Mensualidad regular</dt>
                            <dd class="col-sm-9">{{clientService.calculateMonthlyFee(client) | currency}}</dd>
                            <dt class="col-sm-3">Total por los meses por adelantado</dt>
                            <dd class="col-sm-9">{{this.totalMonthsAhead | currency}}</dd>
                        </dl>
                    </div>

                    <div *ngIf="!data.firstCharge && credit_remaining_amount > 0">
                        <dl class="row">
                            <dt class="col-sm-3">Credito disponible</dt>
                            <dd class="col-sm-9">{{credit_remaining_amount | currency}}</dd>
                        </dl>
                        <dl class="row">
                            <dt class="col-sm-3">Credito aplicado</dt>
                            <dd class="col-sm-9">{{credit_applied | currency}}</dd>
                        </dl>
                    </div>
                    <dl class="row">
                        <dt class="col-sm-3">Total</dt>
                        <dd class="col-sm-9">{{totalManualCharge() | currency}}</dd>
                    </dl>
                </div>
            </div>
        </div>
        <div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid" (click)="manualPayment()">Crear
            pago</button>
    </div>

    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>