import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuppliesService } from '../../../services/supplies.service';
var SupplyRecordComponent = /** @class */ (function () {
    function SupplyRecordComponent(activeModal, suppliesService) {
        this.activeModal = activeModal;
        this.suppliesService = suppliesService;
        this.subscriptions = [];
        this.historyConfig = {
            config: {
                type: 'supplies',
                base: this.suppliesService,
                api: 'getHistory',
                params: { history: 0 },
                order: [[0, 'desc']]
            },
            rows: [
                {
                    display: 'Estatus',
                    field: 'id'
                }
            ]
        };
        this.history = [];
    }
    SupplyRecordComponent.prototype.ngOnInit = function () {
        this.getHistory();
    };
    SupplyRecordComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    /**
     * getHistory
     * Obtiene el historial las entradas y salidas de un insumo.
     */
    SupplyRecordComponent.prototype.getHistory = function () {
        var _this = this;
        this.subscriptions.push(this.suppliesService.getHistory(this.data.id).subscribe(function (data) {
            data.forEach(function (element, index) {
                var supplyMovement = '';
                switch (index) {
                    case 0:
                        supplyMovement = 'inputs';
                        break;
                    case 1:
                        supplyMovement = 'outputs';
                        break;
                }
                element.response.forEach(function (itm) {
                    _this.history.push(tslib_1.__assign({ supplyMovement: supplyMovement }, itm));
                });
                _this.sortData();
            });
        }));
    };
    /**
     * sortData
     * Ordena history para mostrar el historial en orden descendente
     * @returns array de objectos ordenados de la fecha mas reciente a la mas antigua.
     */
    SupplyRecordComponent.prototype.sortData = function () {
        return this.history.sort(function (a, b) {
            var nA = a.created_at;
            var nB = b.created_at;
            return new Date(nB) - new Date(nA);
        });
    };
    return SupplyRecordComponent;
}());
export { SupplyRecordComponent };
