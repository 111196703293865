import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
  })

export class EmailsTemplatesService {
    baseUrl = `${environment.apiUrl}`;

    constructor(private readonly http: HttpClient) { }

    /**
     * getEmailList
     * Obtiene la lista formateada de los emails
     * @returns lista de emails
     */
    getEmailsList(): any {
        return this.http.get(`${this.baseUrl}/email/emails`);
    }

    /**
     * Obtiene la vista del email que se quiere mostrar
     * @param params name: nombre del template, params: parametros que utiliza el template
     */
    getEmailView(params?): any {
        return this.http.post(`${this.baseUrl}/email/email_view`, params, {responseType: 'text'});
    }
}
