import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-debt-total',
  templateUrl: './debt-total.component.html',
  styleUrls: ['./debt-total.component.scss']
})
export class DebtTotalComponent implements OnInit {
  @Input() data: any;
  @Input() options: any;

  num = 0;

  ngOnInit(): void {
    this.calculateDebtTotal();
  }

  private calculateDebtTotal(): void {
    switch (this.options) {
      case 'bad_debts':
        this.data.bad_debts.forEach(bad_debt => {
          this.num += Number(bad_debt.amount) + Number(bad_debt.collection_fees) + Number(bad_debt.moratory_fees);
        });
        this.num = (this.num) / 100;
        break;
      default:
        this.num = (Number(this.data.amount) + Number(this.data.collection_fees) + Number(this.data.moratory_fees)) / 100;
        break;
    }
  }
}
