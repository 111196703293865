import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { CardFailedHistoryComponent } from './card-failed-history/card-failed-history.component';
import { CardsService } from '../../../services/cards.service';
import { ClientsService } from '../../../services/clients.service';
import { SwalService } from '../../../services/swal.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { Subscription } from 'rxjs';
import { CardCreateComponent } from './card-create/card-create.component';

@Component({
  selector: 'app-client-cards',
  templateUrl: './client-cards.component.html',
  styleUrls: ['./client-cards.component.scss']
})
export class ClientCardsComponent implements OnInit {
  @Input() data: any;
  client: any;
  subscriptions: Array<Subscription> = [];
  dataTableConfig = {
    config: {
      base: this.cardsService,
      api: 'getCards',
      params: {
        id_clients: 0
      }
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'default_card'
      },
      {
        display: 'Exp',
        field: 'exp_date',
        type: 'text'
      },
      {
        display: 'Tarjeta',
        field: 'last4',
        type: 'text'
      },
      {
        display: 'Marca',
        field: 'brand',
        type: 'text'
      },
      {
        display: 'Fecha de adición',
        field: 'registered_at.date',
        type: 'date'
      },
      {
        display: 'Opciones',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Marcar como default',
            event: 'card.setAsDefault',
            conditionality: 'this.data.default === 0'
          },
          {
            display: 'Eliminar tarjeta',
            event: 'card.deleteCard',
            conditionality: 'this.data.default === 0',
            permissions: ['delete-cards']
          }
        ]
      }
    ],
    filters: [{}]
  };

  constructor(
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    private readonly broadcast: BroadcastService,
    private readonly clientsService: ClientsService,
    private readonly cardsService: CardsService,
    private readonly swalService: SwalService
  ) { }

  ngOnInit(): void {
    this.dataTableConfig.config.params.id_clients = this.data.id_clients;
    this.getClient();
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'card.setAsDefault': this.setAsDefault(event.data); break;
        case 'card.deleteCard': this.deleteCard(event.data); break;
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * clientFailedCardHistory
   * Muestra el historial de tarjetas fallidas de un cliente
   * este historial es la informacion devuelta por Openpay cuando se intenta registrar una tarjeta
   * ya que es el unico vendor que hace una validación al momento de intentar hacer el registro, si el
   * registro falla en Openpay unicamente la tarjeta no se registrara en Openpay, pero si en Conekta ya que
   * este ultimo no tiene esa validación
   * @param client Objeto con la información basica del cliente
   */
  clientFailedCardHistoryItem(client): void {
    const props: SharedComponent = new SharedComponent(
      CardFailedHistoryComponent,
      client,
      {
        title: `Historial de tarjetas rechazadas de ${client.name}`
      });
    this.appModal.openXl(props);
  }

  /**
   * createCard
   * Abre el modal para ingresar los datos necesarios para registrar una tarjeta.
   */
  createCard(client): void {
    const props: SharedComponent = new SharedComponent(
      CardCreateComponent,
      client,
      {
        title: `Registrar tarjeta para el cliente ${client.name}`
      });
    this.appModal.opensm(props);
  }

  /**
   * setAsDefault
   * Envia una petición POST para marcar como default la tarjeta seleccionada
   * @param card Objeto con la información basica de la tarjeta
   */
  private setAsDefault(card): void {
    const params = {
      id_client: card.customer_id,
      id_card: card.id
    };

    this.subscriptions.push(this.cardsService.setAsDefault(params).subscribe((resp: any) => {
      if (resp.success) {
        this.swalService.success({ text: 'Tarjeta marcada como predeterminada exitosamente' }).then(() => {
          this.broadcast.reloadDataTable();
        });
      }
    }));
  }

  /**
   * deleteCard
   * Envia una petición DELETE para eliminar la tarjeta seleccionada.
   * @param card Objeto con la información basica de la tarjeta
   */
  private deleteCard(card): void {
    const params = {
      id_client: card.customer_id,
      id_card: card.id
    };

    this.subscriptions.push(this.cardsService.delete(params).subscribe((resp: any) => {
      if (resp.success) {
        this.swalService.success({ text: 'Tarjeta eliminada exitosamente' }).then(() => {
          this.broadcast.reloadDataTable();
        });
      }
    }));
  }

  /**
   * getClient
   * Petición GET que obtiene la información basica del cliente
   */
  private getClient(): void {
    this.subscriptions.push(this.clientsService.show(this.data.id_clients).subscribe((resp: any) => {
      if (resp.success) {
        this.client = resp.response;
      } else {
        this.swalService.error({ text: 'Client not found' });
      }
    }));
  }
}
