import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
// Components
import { ComponentsModule } from '../../components/components.module';
import { EmailsTemplatesComponent } from './emails-templates/emails-templates.component';
import { GalleryComponent } from './gallery/gallery.component';
import { NotifyGroupComponent } from './notify/notify-group/notify-group.component';
import { NotifyUserComponent } from './notify/notify-user/notify-user.component';
import { NotifyComponent } from './notify/notify.component';
import { PollsComponent } from './polls/polls.component';
import { LeadsourcesComponent } from './leadsources/leadsources.component';
// Routing
import { SocialRoutes } from './social.routing';

@NgModule({
  declarations: [
    NotifyComponent,
    NotifyUserComponent,
    NotifyGroupComponent,
    GalleryComponent,
    EmailsTemplatesComponent,
    PollsComponent,
    LeadsourcesComponent
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    NgbModule,
    RouterModule.forChild(SocialRoutes),
    ReactiveFormsModule,
    NgxPermissionsModule.forChild()
  ]
})
export class SocialModule { }
