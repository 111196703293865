import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { CustomValidator } from '../../../components/form/validators/custom.validator';
import { FromService } from '../../../providers/form.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { ClientsService } from '../../../services/clients.service';
import { GroupsService } from '../../../services/groups.service';
import { SwalService } from '../../../services/swal.service';
import { rfcValidator } from '../../../components/form/validators/rfc-validator';
import { TaxService } from '../../../services/tax.service';
import { LeadSourcesService } from '../../../services/leadsources.service';
import { Subscription } from 'rxjs';

export class InvoiceData {
  invoice_data = {
    name: [],
    rfc: [],
    cfdi_use: [],
    tax_situation: [],
    address: [],
    outdoor_number: [],
    inside_number: [],
    phone: [],
    email: [],
    between_streets: [],
    colony: [],
    postal_code: [],
    state: [],
    county: []
  };
}

@Component({
  selector: 'app-client-edit',
  templateUrl: './client-edit.component.html',
  styleUrls: ['./client-edit.component.scss']
})
export class ClientEditComponent implements OnInit, OnDestroy {
  @Input() data: any;
  baseUrl = `${environment.apiUrl}`;
  client: any;
  vendors = [];
  cfdi_uses = [];
  original_cfdi_uses = [];
  tax_situations = [];
  leadsources = []
  regularPlans: Array<Number> = [1, 3];
  default_vendor: any;
  group: any; // Temporal
  groups = [];
  // extra_filter_status = [];
  payment_types = environment.payment_types;
  preferred_payment_methods: any;
  default_cfdi_use = 'Selecciona el uso del CFDI';
  default_payment_method = 'Selecciona el metodo de pago';
  default_tax_situation = 'Selecciona el regimen fiscal';
  default_leadsource = 'Seleccione el leadsource';
  default_extra_filter_change = 'Seleccione el status';
  show = false;
  extraFilterChange = false;
  copy_status = false;
  max_year: Number;
  min_year: Number;
  documents = {
    id: '',
    id_reverse: null,
    proof_of_address: null,
    profile: null
  };
  id: any;
  id_back: any;
  voucher: any;
  profile: any;
  subscriptions: Array<Subscription> = [];

  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    rfc: [''],
    address: ['', Validators.required],
    outdoor_number: ['', Validators.required],
    inside_number: [''],
    phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
    extra_phones: new FormArray([]),
    email: ['', Validators.required],
    between_streets: ['', Validators.required],
    colony: ['', [Validators.required, Validators.minLength(4)]],
    postal_code: ['', Validators.required],
    state: ['', [Validators.required, Validators.minLength(4)]],
    county: ['', [Validators.required, Validators.minLength(4)]],
    id_leadsources: [''],
    preferred_payment_method: [],
    birth_date: [null],
    vendor: [''],
    id_groups: [''],
    coordinate: this.formBuilder.group({ latitude: ['', Validators.required], longitude: ['', Validators.required] }),
    images: this.formBuilder.group({
      id: [''],
      id_reverse: [''],
      proof_of_address: [''],
      profile: ['']
    }),
    extra_data: [''],
    extra_filter_change: [''],
    anual_payment: [''],
    unique_charge: ['']
  });
  regular_cfdi_use = ['G01', 'G02', 'G03', 'I01', 'I02', 'I03', 'I04', 'I05', 'I06', 'I07', 'I08'];
  special_cfdi_use = ['D01', 'D02', 'D03', 'D04', 'D05', 'D06', 'D07', 'D08', 'D09', 'D10'];
  regular_tax_situation = [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626];
  special_tax_situation = [605, 606, 608, 611, 612, 614, 607, 615, 625];
  common_tax_situation = this.regular_tax_situation.filter(i => this.special_tax_situation.includes(i));

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly clientService: ClientsService,
    private readonly groupService: GroupsService,
    private readonly taxService: TaxService,
    private readonly leadsourcesService: LeadSourcesService,
    private readonly swal: SwalService) { }

  ngOnInit(): void {
    this.getClient();
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * getClient
   * Devuelve toda la información pertinente a un cliente para setearla en el formulario
   * La información se devuelve es la siguiente:
   * Información basica (requeridas): Nombre, dirección, telefono, email, etc.
   * Opcionales: 
   * Información para la facturación.
   * Información de tarjeta del cliente.
   */

  getClient(): void {
    this.subscriptions.push(this.clientService.show(this.data.id_clients).subscribe((data: any) => {
      this.client = data.response;
      this.extraFilterChange = this.regularPlans.includes(this.client.id_plan_type);
      this.preferred_payment_methods = this.updatePaymentTypesIds();
      this.group = 'Selecciona el grupo'; // Temporal
      this.getGroups();
      this.getVendors();
      this.getTaxSituations();
      this.getLeadSources();
      this.getImages(this.client.id_clients);
      this.setClientData(this.client);
      this.setDefaultPreferredPaymentMethod(this.client.preferred_payment_method);
      this.setMaxMinYear();
      this.updateInvoiceDataValidators();
      this.updateExtraPhoneNumbers();
      this.filterCFDIUses();
    }));
  }

  /**
   * updateClient
   * Manda una petición PUT a la API unicamente con los datos modificados del formulario
   */
  updateClient(): void {
    if (this.form.valid) {
      const params = this.fromService.getDirtyValues(this.form);
      if (params.hasOwnProperty('invoice_data')) {
        Object.assign(params, { rfc: params.invoice_data.rfc });
      }
      const swal_params = this.swalParams(params);
      this.swal.warning(swal_params).then(result => {
        if (result.value) {
          this.subscriptions.push(this.clientService.update(this.client.id_clients, params).subscribe((data: any) => {
            if (data.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss('Cross click');
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error();
            }
          }));
        }
      });
    }
  }

  swalParams(params): Object {
    const swal_params = {
      title: '¿Esta seguro de querer actualizar los datos del cliente?'
    };

    if (params.hasOwnProperty('rfc')) {
      let rfcWarningParams = {
        text: 'Solo se añadio el RFC del cliente por lo que la factura se generara con la información de la dirección principal'
      };
      if (params.hasOwnProperty('invoice_data')) {
        rfcWarningParams = {
          text: 'Se añadieron datos en la pestaña de dirección de facturación por lo que la factura se generara con los datos ingresados en esa pestaña'
        };
      }
      Object.assign(swal_params, rfcWarningParams);
    }

    return swal_params;
  }

  /**
   * getGroups
   * Obtiene el array de grupos actuales en el sistema 
   */

  getGroups(): void {
    const params = { start: 0, length: 50 };
    this.subscriptions.push(this.groupService.getGroups(params).subscribe((resp: any) => {
      resp.data.forEach(group => {
        const groupObject = { id: group.id_groups, name: group.name };
        this.groups.push(groupObject);
      });
    }));
  }

  /**
   * addExtraPhone
   * Añade los formularios para añadir numeros extra a un cliente
   * @param params name: nombre del numero extra, phone: telefono extra
   */
  addExtraPhone(params = null): void {
    const extraPhone = this.formBuilder.group({
      name: [params ? params.name : null, Validators.required],
      phone: [params ? params.phone : null, Validators.required]
    });

    if (this.extra_phones.valid) {
      this.extra_phones.push(extraPhone);
    }
  }

  /**
   * removeExtraPhone
   * Remueve el numero extra de un cliente.
   * @param index posicion del numero extra que se desea remover.
   */
  removeExtraPhone(index): void {
    this.extra_phones.removeAt(index);
  }

  /**
   * copyInvoiceData
   * Copia los datos necesarios del objeto cliente para la almacenar los valores en invoice_data.
   */
  copyInvoiceData(): void {
      const skip = ['tax_situation', 'rfc', 'cfdi_use'];
      const inD = new InvoiceData();
      Object.keys(inD.invoice_data).forEach(key => {
        const value = this.client[key] ? this.client[key] : '';
        if (!skip.includes(key)) {
          this.form.get(['invoice_data', key]).patchValue(value);

        }
      });
  }

  /**
   * getImage
   * @param type nombre de la imagen
   * @param images arreglo de las imagenes asociadas al cliente
   */

  private getImage(type, images): string | null {
    let url = null;
    images.forEach(image => {
      if (image.indexOf(type) > -1) {
        url = `${this.baseUrl}/documents/contracts/${image}`;
      }
    });

    return url;
  }

  /**
   * getImages
   * @param id_clients id del cliente
   * Obtiene del servidor las imagenes asociadas de un cliente
   */

  private getImages(id_clients): void {
    this.subscriptions.push(this.clientService.getImages(id_clients).subscribe((resp: any) => {
      this.documents.id = this.getImage('id.', resp.response.documents);
      this.documents.id_reverse = this.getImage('id_reverse.', resp.response.documents);
      this.documents.proof_of_address = this.getImage('proof_of_address.', resp.response.documents);
      this.documents.profile = this.getImage('profile.', resp.response.documents);
    }));

  }

  /**
   * getVendors
   * Obtiene los vendors asociados a un cliente
   * Si el cliente tiene un vendor predeterminado setea el nombre del vendor en el formulario
   */
  private getVendors(): void {
    const validClientVendors = [];
    const vendors = [];
    this.default_vendor = '-Selecciona el vendor-';
    this.client.valid_cards_vendors.forEach(cardVendorInfo => {
      if (vendors.indexOf(cardVendorInfo.id_vendor) === -1) {
        vendors.push(cardVendorInfo.vendor.id_vendor);
        validClientVendors.push({ id: cardVendorInfo.vendor.id_vendor, name: cardVendorInfo.vendor.name });
      }
    });

    if (this.client.default_vendor !== null) {
      this.default_vendor = this.client.default_vendor.vendor.name;
    }

    this.vendors = validClientVendors;
  }

  /**
   * getTaxSituations
   * Obtiene y formatea la lista de situaciones fiscales devuelta por la API
   */
  private getTaxSituations(): void {
    this.subscriptions.push(this.taxService.getTaxData().subscribe((resp: any) => {
      if (resp.length > 0) {
        resp.forEach((element: any) => {
          if (element.success && element.response.length > 0) {
            switch (element.message) {
              case 'situations':
                this.tax_situations = element.response.map((tax: any) => {
                  const formated_taxes = {};
                  Object.assign(formated_taxes, { id: tax.code, name: `${tax.code} - ${tax.description}` });

                  return formated_taxes;
                });
                break;
              case 'cfdi':
                const cfdi_uses = element.response.map((cfdi: any) => {
                  const formated_cfdis = {};
                  Object.assign(formated_cfdis, { id: cfdi.clave, name: `${cfdi.clave} - ${cfdi.uso}` });

                  return formated_cfdis;
                });

                this.cfdi_uses = [...cfdi_uses];
                this.original_cfdi_uses = [...cfdi_uses];
                break;
            }
          }
        });
      } else {
        this.swal.error({ text: 'Ocurrio un error al obtener los datos de facturación' });
      }
    }));
  }

  /**
   * filterCFDIUse
   * Filtra los uso de CFDI que se pueden aplicar a una factura dependiendo
   * de la situacion fiscal seleccionada.
   */
  private filterCFDIUses(): void {
    this.subscriptions.push(this.form.get('invoice_data.tax_situation').valueChanges.subscribe((tax_situation: any) => {
      this.cfdi_uses = this.original_cfdi_uses;
      if (!this.common_tax_situation.includes(tax_situation)) {
        this.cfdi_uses = this.regular_tax_situation.includes(Number(tax_situation)) ?
          this.cfdi_uses.filter(cfdi_uses => this.regular_cfdi_use.includes(cfdi_uses.id)) :
          this.cfdi_uses.filter(cfdi_uses => this.special_cfdi_use.includes(cfdi_uses.id));
      }
    }));
  }

  /**
   * getLeadSources
   * Setea los leadsources
   */
  private getLeadSources(): void {
    this.subscriptions.push(this.leadsourcesService.index({ status: 'all' }).subscribe((resp: any) => {
      if (resp.response.length > 0) {
        this.leadsources = resp.response.map((leadsource: any) => {
          const formatedLeadsources = {};
          const status = ['Inactivo', 'Activo'];
          Object.assign(formatedLeadsources, { id: leadsource.id_leadsources, name: `${leadsource.name} - ${status[leadsource.status]}` });

          return formatedLeadsources;
        });
      } else {
        this.swal.error({ text: 'Ocurrio un error al obtener los leadsources' });
      }
    }));
  }

  /**
   * setClientData
   * @param client información del cliente
   * Setea la información del cliente obtenida del request.
   */

  private setClientData(client): void {
    const specialFields = ['cards', 'invoice_data', 'coordinate.latitude', 'coordinate.longitude', 'extra_phones'];

    if (client.coordinate === '' || client.coordinate === null) {
      client.coordinate = { latitude: '', longitude: '' };
    }

    Object.keys(client).forEach(key => {
      if (specialFields.indexOf(key) === -1 && this.form.controls[key]) {
        this.form.controls[key].setValue(client[key]);
      }
    });

    const grp = [];
    const inD = new InvoiceData();
    const fieldsToUpper = ['name', 'rfc'];
    Object.keys(inD.invoice_data).forEach(key => {
      const value = (client.invoice_data !== null && key in client.invoice_data) ? client.invoice_data[key] : '';
      if (fieldsToUpper.includes(key)) {
        value.toUpperCase();
      }
      grp[key] = [value.trim(), inD.invoice_data[key]];
    });

    this.form.addControl('invoice_data', this.formBuilder.group(grp));
    if (client.extra_phones) {
      const extraPhones = JSON.parse(client.extra_phones);
      if (extraPhones.length > 0) {
        extraPhones.forEach(extraPhone => {
          this.addExtraPhone(extraPhone);
        });
      }
    }
  }

  /**
   * updatePaymentTypesIds
   * Transforma los datos almacendos en payment_types para generar un nuevo ARRAY
   * @returns ARRAY con el formato aceptado por el INPUT SELECT
   */
  private updatePaymentTypesIds(): any {
    const updatedArray = this.payment_types.map(element => {
      let id_payment_type;
      switch (element.id) {
        case 'CARD':
          id_payment_type = 1;
          break;
        case 'OXXO':
          id_payment_type = 2;
          break;
        case 'SPEI':
          id_payment_type = 3;
          break;
      }

      return { ...element, id: id_payment_type };
    });

    return updatedArray;
  }

  /**
   * setDefaultPreferredPaymentMethod
   * Setea el metodo de pago por defecto del cliente
   */
  private setDefaultPreferredPaymentMethod(preferred_payment_method): void {
    switch (preferred_payment_method) {
      case 2:
        this.default_payment_method = 'OXXO';
        break;
      case 3:
        this.default_payment_method = 'SPEI';
        break;
      default:
        this.default_payment_method = 'Tarjeta';
        break;
    }
  }

  /**
   * updateInvoiceDataValidators
   * Actualiza los validadores del subform invoice data para marcarlos como requeridos en caso
   * de que alguno de los siguientes campos sea distinto de null
   */
  private updateInvoiceDataValidators(): void {
    const invoiceForm = this.form.get('invoice_data');
    const skip = ['phone', 'inside_number'];
    const inD = new InvoiceData();

    this.subscriptions.push(invoiceForm.valueChanges.subscribe(invoice_data => {
      if (invoice_data.name !== '' || invoice_data.name !== null || invoice_data.email !== '' || invoice_data.email !== null) {
        Object.keys(inD.invoice_data).forEach(key => {
          if (!skip.includes(key)) {
            if (key === 'rfc') {
              invoiceForm.get(key).setValidators([
                Validators.required, Validators.minLength(12), Validators.maxLength(13), CustomValidator.noSpace, rfcValidator()]);
            } else {
              invoiceForm.get(key).setValidators([Validators.required]);
            }
          }

        });
      }

      Object.keys(inD.invoice_data).forEach(key => {
        if (!skip.includes(key)) {
          invoiceForm.get(key).updateValueAndValidity({ onlySelf: true });
        }
      });
      this.show = true;
    }));
  }

  private updateExtraPhoneNumbers(): void {
    this.subscriptions.push(this.form.get('extra_phones').valueChanges.subscribe(extraPhone => {
      // console.log(extraPhone);
    }));
  }

  /**
   * Setea la variable max_year y min_year para determinar, los rangos de años que se podran asignar
   * al campo de fecha de nacimiento
   */
  private setMaxMinYear(): void {
    const current_date = new Date();
    this.min_year = current_date.getFullYear() - 100;
    this.max_year = current_date.getFullYear() - 18;
  }

  get extra_phones(): FormArray { return this.form.get('extra_phones') as FormArray; }

}
