<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <div class="row">
        <div class="col">
            <div>
                <h2 class="m-0">Correos</h2>
            </div>
            <div class="col text-right">
                <app-form-field type="select" 
                [options]="{
                    label: 'Seleccionar correo',
                    path: 'email',
                    droptext: 'Lista de correos',
                    elements: emails
                }"></app-form-field>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid bg-white p-3">
    <div *ngIf="email_view">
        <div [innerHtml]="email_view"></div>
    </div>
</div>
