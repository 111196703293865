/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./client-promotionals-monthly-fees.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-permissions";
import * as i3 from "@angular/common";
import * as i4 from "../../../components/datatable/datatable.component.ngfactory";
import * as i5 from "../../../components/datatable/datatable.component";
import * as i6 from "../../../services/broadcast.service";
import * as i7 from "./client-promotionals-monthly-fees.component";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "../../../components/modal/modal.component";
import * as i10 from "../../../services/clients.service";
import * as i11 from "../../../services/promotional-monthly-fees.service";
import * as i12 from "../../../services/swal.service";
var styles_ClientPromotionalsMonthlyFeesComponent = [i0.styles];
var RenderType_ClientPromotionalsMonthlyFeesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientPromotionalsMonthlyFeesComponent, data: {} });
export { RenderType_ClientPromotionalsMonthlyFeesComponent as RenderType_ClientPromotionalsMonthlyFeesComponent };
function View_ClientPromotionalsMonthlyFeesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.assignPromotionalMonthlyFee(_co.client) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Dar promoci\u00F3n"]))], null, null); }
function View_ClientPromotionalsMonthlyFeesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ClientPromotionalsMonthlyFeesComponent_2)), i1.ɵdid(2, 737280, null, 0, i2.NgxPermissionsDirective, [i2.NgxPermissionsService, i2.NgxPermissionsConfigurationService, i2.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(3, 1)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "create-promotional-monthly-fees"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ClientPromotionalsMonthlyFeesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientPromotionalsMonthlyFeesComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 16777216, null, null, 1, "app-datatable", [], null, null, null, i4.View_DatatableComponent_0, i4.RenderType_DatatableComponent)), i1.ɵdid(4, 245760, null, 0, i5.DatatableComponent, [i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i6.BroadcastService], { options: [0, "options"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("close button") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.client; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.dataTableConfig; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ClientPromotionalsMonthlyFeesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-client-promotionals-monthly-fees", [], null, null, null, View_ClientPromotionalsMonthlyFeesComponent_0, RenderType_ClientPromotionalsMonthlyFeesComponent)), i1.ɵdid(1, 245760, null, 0, i7.ClientPromotionalsMonthlyFeesComponent, [i8.NgbActiveModal, i9.ModalComponent, i6.BroadcastService, i10.ClientsService, i11.PromotionalMonthlyFeesService, i12.SwalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientPromotionalsMonthlyFeesComponentNgFactory = i1.ɵccf("app-client-promotionals-monthly-fees", i7.ClientPromotionalsMonthlyFeesComponent, View_ClientPromotionalsMonthlyFeesComponent_Host_0, { data: "data" }, {}, []);
export { ClientPromotionalsMonthlyFeesComponentNgFactory as ClientPromotionalsMonthlyFeesComponentNgFactory };
