/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clients.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-permissions";
import * as i3 from "@angular/common";
import * as i4 from "../../components/datatable/datatable.component.ngfactory";
import * as i5 from "../../components/datatable/datatable.component";
import * as i6 from "../../services/broadcast.service";
import * as i7 from "./clients.component";
import * as i8 from "../../services/clients.service";
import * as i9 from "../../services/charges.service";
import * as i10 from "../../services/bad-debts.service";
import * as i11 from "../../services/role-guard.service";
import * as i12 from "../../services/permissions.service";
import * as i13 from "../../services/users.service";
import * as i14 from "../../components/modal/modal.component";
import * as i15 from "../../services/swal.service";
var styles_ClientsComponent = [i0.styles];
var RenderType_ClientsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientsComponent, data: {} });
export { RenderType_ClientsComponent as RenderType_ClientsComponent };
function View_ClientsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createClient() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Registrar cliente"]))], null, null); }
function View_ClientsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateAcceptedClientsSalesGroup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Actualizar grupo de ventas"]))], null, null); }
function View_ClientsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Clientes/Vendedores"])), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "col text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ClientsComponent_2)), i1.ɵdid(6, 737280, null, 0, i2.NgxPermissionsDirective, [i2.NgxPermissionsService, i2.NgxPermissionsConfigurationService, i2.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(7, 1), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ClientsComponent_3)), i1.ɵdid(9, 737280, null, 0, i2.NgxPermissionsDirective, [i2.NgxPermissionsService, i2.NgxPermissionsConfigurationService, i2.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(10, 1)], function (_ck, _v) { var currVal_0 = _ck(_v, 7, 0, "create-clients"); _ck(_v, 6, 0, currVal_0); var currVal_1 = _ck(_v, 10, 0, "update-clients"); _ck(_v, 9, 0, currVal_1); }, null); }
function View_ClientsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clientCards(_co.data) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Ver tarjetas registradas"]))], null, null); }
function View_ClientsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showUniquePaymentModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cargo familiar"]))], null, null); }
function View_ClientsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ClientsComponent_5)), i1.ɵdid(2, 737280, null, 0, i2.NgxPermissionsDirective, [i2.NgxPermissionsService, i2.NgxPermissionsConfigurationService, i2.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ClientsComponent_6)), i1.ɵdid(5, 737280, null, 0, i2.NgxPermissionsDirective, [i2.NgxPermissionsService, i2.NgxPermissionsConfigurationService, i2.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(6, 1)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "list-cards"); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, "create-charges"); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_ClientsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientsComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientsComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "app-datatable", [], null, null, null, i4.View_DatatableComponent_0, i4.RenderType_DatatableComponent)), i1.ɵdid(7, 245760, null, 0, i5.DatatableComponent, [i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i6.BroadcastService], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.data; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.data && _co.data.is_parent); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.dataTableConfig; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_ClientsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-clients", [], null, null, null, View_ClientsComponent_0, RenderType_ClientsComponent)), i1.ɵdid(1, 245760, null, 0, i7.ClientsComponent, [i8.ClientsService, i9.ChargesService, i10.BadDebtsService, i6.BroadcastService, i11.RoleGuardService, i12.PermissionsService, i13.UsersService, i14.ModalComponent, i15.SwalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientsComponentNgFactory = i1.ɵccf("app-clients", i7.ClientsComponent, View_ClientsComponent_Host_0, { data: "data" }, {}, []);
export { ClientsComponentNgFactory as ClientsComponentNgFactory };
