import * as tslib_1 from "tslib";
import * as i0 from "@angular/core";
var TokensService = /** @class */ (function () {
    function TokensService() {
    }
    TokensService.prototype.default = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        var tk = {
                            success: false
                        };
                        resolve(tk);
                    })];
            });
        });
    };
    TokensService.prototype.conekta = function (vendor, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve) {
                        Conekta.token.create({ card: data }, function (token) {
                            var tk = {
                                success: true
                            };
                            tk[vendor] = token.id;
                            resolve(tk);
                        }, function (error) {
                            resolve(error);
                        });
                    })];
            });
        });
    };
    TokensService.prototype.openpay = function (vendor, data) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var deviceSessionId;
            return tslib_1.__generator(this, function (_a) {
                deviceSessionId = OpenPay.deviceData.setup();
                return [2 /*return*/, new Promise(function (resolve) {
                        OpenPay.token.create(data, function (token) {
                            var tk = {
                                success: true,
                                openpay_device_session_id: deviceSessionId
                            };
                            tk[vendor] = token.data.id;
                            resolve(tk);
                        }, function (error) {
                            resolve(error);
                        });
                    })];
            });
        });
    };
    TokensService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokensService_Factory() { return new TokensService(); }, token: TokensService, providedIn: "root" });
    return TokensService;
}());
export { TokensService };
