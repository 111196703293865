import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class PromotionalMonthlyFeesService {
    private readonly baseUrl = `${environment.apiUrl}`;
    constructor(private readonly http: HttpClient) { }
    
    /**
     * create
     * Manda los parametros para crear un nuevo promotionalMonthlyFee
     * @param params Object {name, data}
     * @returns 200 | 400 | 500
     */
    create(params): Observable<any> {
        return this.http.post(`${this.baseUrl}/promotionalMonthlyFees`, params);
    }

    /**
     * update
     * Manda los parametros para actualizar los datos de un promotionalMonthlyFee
     * @param id int ID del PromotionalMonthlyFee a actualizar.
     * @param params Object {name, data}
     * @returns 200 | 400 | 404 | 500
     */
    update(id, params): Observable<any> {
        return this.http.put(`${this.baseUrl}/promotionalMonthlyFees/${id}`, params);
    }

    /**
     * delete
     * Manda los parametros para borrar los datos de un promotionalMonthlyFee
     * @param id int ID del PromotionalMonthlyFee a borrar.
     * @param params Object {name, data}
     * @returns 200 | 404 | 500
     */
    delete(id): Observable<any> {
        return this.http.delete(`${this.baseUrl}/promotionalMonthlyFees/${id}`);
    }

    /**
     * show
     * Devuelve la informacion de un PromotionalMonthlyFee
     * @param id int ID del PromotionalMonthlyFee.
     * @returns 200 | 404
     */
    show(id, params?): Observable<any> {
        return this.http.post(`${this.baseUrl}/promotionalMonthlyFees/${id}`, params);
    }
    
    /**
     * getPromotionalMothlyFees
     * Obtiene los datos para generar la tabla de las mensualidades promocionales
     * @param params Object con los parametros para definir como ordenar los datos. 
     * @param filters Object con los filtros para filtrar la informacion mediante opciones
     * @returns 
     */
    getPromotionalMonthlyFeesTable(params?, filters?): Observable<any> {
        if (filters) {
            Object.assign(params, filters);
        }

        return this.http.post(`${this.baseUrl}/promotionalMonthlyFees/getPromotionalMonthlyFeesTable`, params);
    }

    getActivePromotionals(): Observable<any> {
        return this.http.get(`${this.baseUrl}/promotionalMonthlyFees/activePromotionalsMonthlyFees`);
    }
}