import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { environment } from '../../../../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { TicketsService } from '../../../../../../services/tickets.service';
import { SharedComponent } from '../../../../../../model/shared-component';
import { ModalComponent } from '../../../../../../components/modal/modal.component';
import { CompletionMapComponent } from './completion-map/completion-map.component';

@Component({
  selector: 'history-ticket-completions',
  templateUrl: './completions.component.html',
  styleUrls: ['./completions.component.scss']
})
export class CompletionsComponent implements OnInit, OnDestroy {
  @Input('item') item: any;
  images: Array<any>;
  parts = [];
  private readonly baseUrl = `${environment.apiUrl}`;
  private subscriber;

  constructor(
    private readonly lightBox: Lightbox,
    private readonly domSanitizer: DomSanitizer,
    private readonly ticketsService: TicketsService,
    public appModal: ModalComponent
  ) { }

  ngOnInit(): void {
    const ticketId: number = this.item.assignation.id_tickets;
    this.subscriber = this.ticketsService.getPhotos(ticketId).subscribe((files: Array<string>) => {
      this.images = files.map(file =>
      ({
        src: this.domSanitizer.bypassSecurityTrustUrl(`${this.baseUrl}/documents/tickets/${ticketId}/${file}`),
        caption: `Imagen ${file}`
      }));
    });

    const parts = this.item.used_parts.split(',');

    if (parts.length > 0) {
      parts.forEach(part => {
        if (part !== '' && part !== 'null') {
          parts.push(part);
        }
      });
    }
  }

  showImagesBox(event?): void {
    if (event) {
      event.preventDefault();
    }
    this.lightBox.open(this.images);
  }

  closeImageBox(): void {
    this.lightBox.close();
  }

  openMap(event?): void {
    if (event) {
      event.preventDefault();
    }
    // open modal, passing the context
    const props: SharedComponent = new SharedComponent(
      CompletionMapComponent,
      { latitude: Number(this.item.latitude), longitude: Number(this.item.longitude) },
      { title: 'Ubicación del técnico al completar el formulario' });
    this.appModal.opensm(props);
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }
}
