/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./investor-kpi.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./investor-kpi.component";
var styles_InvestorKpiComponent = [i0.styles];
var RenderType_InvestorKpiComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvestorKpiComponent, data: {} });
export { RenderType_InvestorKpiComponent as RenderType_InvestorKpiComponent };
export function View_InvestorKpiComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "rounded-circle investor-kpi-container text-center"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "data-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "kpi-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "kpi-value"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "kpi-description"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "rounded-circle investor-kpi-container text-center"; var currVal_1 = _ck(_v, 3, 0, _co.backGround); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.value; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.description; _ck(_v, 12, 0, currVal_4); }); }
export function View_InvestorKpiComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-investor-kpi", [], null, null, null, View_InvestorKpiComponent_0, RenderType_InvestorKpiComponent)), i1.ɵdid(1, 114688, null, 0, i3.InvestorKpiComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvestorKpiComponentNgFactory = i1.ɵccf("app-investor-kpi", i3.InvestorKpiComponent, View_InvestorKpiComponent_Host_0, { backGround: "backGround", icon: "icon", title: "title", value: "value", description: "description" }, {}, []);
export { InvestorKpiComponentNgFactory as InvestorKpiComponentNgFactory };
