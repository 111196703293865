import { Injectable } from "@angular/core";
import { SwalService } from "./swal.service";

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    constructor(private readonly swalService: SwalService) { }
    /**
     * formatDate
     * Formatea una fecha.
     */
    formatDate(date: string): Date {
        let formatedDate = new Date(`${date}T11:59:59`);
        return formatedDate;
    }

    /**
     * flatObject
     * @param obj objeto a reducir a un solo nivel
     * @returns objeto reducido a un solo nivel
     */
    flatObject(obj): Object {
        const flattened = {}

        Object.keys(obj).forEach((key) => {
            const value = obj[key]

            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                Object.assign(flattened, this.flatObject(value));
            } else {
                flattened[key] = value
            }
        })

        return flattened
    }

    formatPhoneNumber(phone: string): string {
        const formatedPhoneNumber = phone.replace(/[^\d]/g, '');
        return formatedPhoneNumber;
    }

    sendByWhatsapp(whatsAppMessage: string, clientPhone: string) {
        if (whatsAppMessage && clientPhone) {
            const formatedClientNumber = this.formatPhoneNumber(clientPhone);
            window.open(`https://api.whatsapp.com/send?phone=${formatedClientNumber}&text=${encodeURIComponent(whatsAppMessage)}`);
        } else {
            const data = {
                text: `No se puede enviar el mensaje\nWhatsapp:${clientPhone}\nMensaje:${whatsAppMessage}`,
                allowOutsideClick: false,
            };
            this.swalService.error(data);
        }
    }

    /**
     * copyToClipboard
     * Copia el texto en el portapapeles del sistema
     * @param text texto a copiar en el portapapeles
     */
    async copyToClipboard(text: string) {
        try {
            await navigator.clipboard.writeText(text);
            const data = {
                text: 'Texto copiado exitosamente',
                showConfirmButton: false,
                allowOutsideClick: false,
                timer: 1500
            };
            this.swalService.success(data);
        } catch (err) {
            const data = {
                text: `Error al copiar el texto: ${err}`,
                allowOutsideClick: false,
            };
            this.swalService.error(data);
        }
    }
}