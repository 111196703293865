<br>
<div class="container">
    <ngb-tabset>
        <ngb-tab title="Datos Generales">
            <ng-template ngbTabContent>
                <div class="row">
                    <div class="col">
                        <app-form-field type="input" [options]="{label: 'Nombre', path: 'name'}"></app-form-field>
                        <app-form-field type="input" [options]="{label: 'Nombre a mostrar', path: 'display_name'}"></app-form-field>
                        <app-form-field type="input" [options]="{label: 'Descripcion', path: 'description'}"></app-form-field>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="Permisos del rol">
            <ng-template ngbTabContent>
              <div class="row">
                  <div class="col">
                    <div class="row ng-scope">
                      <div class="col-md-6">
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <div class="row">
                              <div class="col-md-12">
                                <i>Permisos</i>
                              </div>
                            </div>
                          </div>
                          <div class="panel-body">
                            <div class="table-responsive">
                              <table class="table table-striped table-hover">
                                <tbody>
                                  <app-datatable [options]="dataTableConfig"></app-datatable>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="panel panel-default  permissions-tab">
                          <div class="panel-heading">
                            <div class="row">
                              <div class="col-md-12">
                                <i>Permisos seleccionados</i>
                              </div>
                            </div>
                          </div>
                          <div class="panel-body">
                            <div class="table-responsive">
                              <table class="table table-striped table-hover">
                                <tbody>
                                  <tr *ngFor="let permission of form.controls.permissions.value">
                                    <td>{{permission.display_name}}</td>
                                    <td class="remove"><button type="button" class="btn btn-success" (click)="removePermission(permission.id)">Remover</button></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
    <br>
    <div class="modal-footer">
        <div *ngIf="this.form.dirty">
            <button type="button" class="btn btn-outline-danger"
                (click)="activeModal.dismiss('Cross click')">Cancelar</button>
            <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
                (click)="save()">Guardar</button>
        </div>
        <div *ngIf="!this.form.dirty">
            <button type="button" class="btn btn-outline-dark"
                (click)="activeModal.dismiss('Cross click')">Cerrar</button>
        </div>
    </div>
</div>