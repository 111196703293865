import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SalesDashboardService {
    baseUrl = `${environment.apiUrl}`;

    constructor(private readonly http: HttpClient) { }

    salesByMonth(paramsB?): Observable<any> {
        // params = Math.floor(Date.now() / 1000);
        return this.http.get(`${this.baseUrl}/sales-dashboard/salesMonthCustom`, { params: paramsB });
    }

    salesByCampaign(paramsB?): Observable<any> {
        return this.http.get(`${this.baseUrl}/sales-dashboard/salesCampaignCustom`, { params: paramsB });
    }

    salesByCampaignFacebook(paramsB?): Observable<any> {
        return this.http.get(`${this.baseUrl}/sales-dashboard/salesCampaignFacebook`, { params: paramsB });
    }

    salesByPromotion(paramsB?): Observable<any> {
        return this.http.get(`${this.baseUrl}/sales-dashboard/salesPromotionCustom`, { params: paramsB });
    }

    salesCacCampaign(): Observable<any> {
        return this.http.get(`${this.baseUrl}/sales-dashboard/cacCampaign`);
    }

    salesCac(paramsB?): Observable<any> {
        return this.http.get(`${this.baseUrl}/sales-dashboard/cac`, { params: paramsB });
    }

    /**
     * Sales by Agent
     * 
     * @param paramsB params {from, to, agent}
     */
    salesByAgent(paramsB?): Observable<any> {
        return this.http.get(`${this.baseUrl}/sales-dashboard/salesAgentCustom`, { params: paramsB });
    }

    salesByAgentCustom(paramsB?): Observable<any> {
        return this.http.get(`${this.baseUrl}/sales-dashboard/salesAgentCustom?isByOwner=true`, { params: paramsB });
    }

    contactProperty(paramsB?): Observable<any> {
        return this.http.get(`${this.baseUrl}/sales-dashboard/contactProperty`, { params: paramsB });
    }

    owners(): Observable<any> {
        return this.http.get(`${this.baseUrl}/sales-dashboard/owners`);
    }
}
