import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NPSService } from '../../../../services/nps.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { SharedComponent } from '../../../../model/shared-component';
import { SwalService } from '../../../../services/swal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NpsFormComponent } from './nps-form/nps-form.component';
import { PermissionsService } from '../../../../services/permissions.service';
var NpsTableComponent = /** @class */ (function () {
    function NpsTableComponent(activeModal, appModal, npsService, broadcast, swal, permissionsService) {
        this.activeModal = activeModal;
        this.appModal = appModal;
        this.npsService = npsService;
        this.broadcast = broadcast;
        this.swal = swal;
        this.permissionsService = permissionsService;
        this.baseDataTableConfig = {
            config: {
                base: this.npsService,
                api: 'getNPS'
            },
            columns: [
                {
                    display: 'Período',
                    field: 'date',
                    type: 'date'
                },
                {
                    display: 'NPS',
                    field: 'nps',
                    type: 'percent'
                },
                {
                    display: 'Acciones',
                    field: '',
                    type: 'actions',
                    options: [
                        {
                            display: 'Editar',
                            icon: 'fa fa-pencil',
                            event: 'nps.edit',
                            conditionality: 'true',
                            permissions: ['update-nps']
                        },
                        {
                            display: 'Eliminar',
                            icon: 'fa fa-trash fa-fw',
                            event: 'nps.delete',
                            conditionality: 'true',
                            permissions: ['delete-nps']
                        }
                    ]
                }
            ]
        };
    }
    NpsTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.broadcast$ = this.broadcast.events.subscribe(function (event) {
            switch (event.name) {
                case 'nps.delete':
                    _this.deleteNPSItem(event.data);
                    break;
                case 'nps.edit':
                    _this.editNPSItem(event.data);
                    break;
            }
        });
        this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
    };
    NpsTableComponent.prototype.ngOnDestroy = function () {
        this.broadcast$.unsubscribe();
    };
    NpsTableComponent.prototype.createNPSItem = function (data) {
        var props = new SharedComponent(NpsFormComponent, {
            npsData: data,
            status: 'create'
        }, {
            title: 'Crear entrada de NPS'
        });
        this.appModal.opensm(props);
    };
    NpsTableComponent.prototype.deleteNPSItem = function (data) {
        var _this = this;
        var swalParams = {
            title: "\u00BFEst\u00E1s seguro de querer eliminar NPS del per\u00EDodo " + data.date + "?"
        };
        this.swal.warning(swalParams).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var resp;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!result.value) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.npsService.delete(data.id_nps).toPromise()];
                    case 1:
                        resp = _a.sent();
                        if (resp && resp.success) {
                            this.swal.success({ title: 'NPS eliminado exitosamente' }).then(function () {
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                        else {
                            this.swal.error('Ocurrio un error al intentar borrar NPS');
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    NpsTableComponent.prototype.editNPSItem = function (data) {
        // open modal, passing the context
        var props = new SharedComponent(NpsFormComponent, {
            npsData: data,
            status: 'edit'
        }, {
            title: 'Net Promoter Score '
        });
        this.appModal.opensm(props);
    };
    return NpsTableComponent;
}());
export { NpsTableComponent };
