import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-corrections',
  templateUrl: './corrections.component.html',
  styleUrls: ['./corrections.component.scss']
})
export class CorrectionsComponent implements OnInit {
  @Input('item') item: any;
  
  ngOnInit(): void {
    //
  }

}
