import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ChartOptions, ChartDataSets, ChartType } from 'chart.js';

@Component({
  selector: 'app-shares-by-type-chart',
  templateUrl: './shares-by-type-chart.component.html',
  styleUrls: ['./shares-by-type-chart.component.scss']
})
export class SharesByTypeChartComponent implements OnInit, OnChanges {
  @Input('data') type: any;
  fb = [];
  wa = [];
  other = [];
  try_share = [];
  total_shares = [];
  labels = [];
  options: ChartOptions = {
    responsive: true,
    plugins: {
      datalabels: { display: false }
    },
    scales: {
      xAxes: [
        {
          offset: true,
          type: 'time',
          distribution: 'linear',
          time: {
            unit: 'month'
          }
        }
      ],
      yAxes: [{}]
    }
  };

  sharesByTypeChartData: Array<ChartDataSets> = [
    {
      data: this.try_share,
      label: 'Intento compartir',
      fill: false,
      borderColor: 'orange',
      pointBackgroundColor: 'orange'
    },
    {
      data: this.fb,
      label: 'Facebook',
      fill: false,
      borderColor: 'blue',
      pointBackgroundColor: 'blue'
    },
    {
      data: this.wa,
      label: 'WhatsApp',
      fill: false,
      borderColor: '#2eb85c',
      pointBackgroundColor: '#2eb85c'
    },
    {
      data: this.other,
      label: 'Otros',
      fill: false,
      borderColor: '#1B9CA2',
      pointBackgroundColor: '#1B9CA2'
    },
    {
      data: this.total_shares,
      label: 'Total',
      fill: false,
      borderColor: 'red',
      pointBackgroundColor: 'red'
    }

  ];

  barChartType: ChartType = 'line';

  ngOnInit(): void {
    //
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type.currentValue.type.length > 0) {
      this.sharesByTypeChartData[0].data = changes.type.currentValue.type
        .filter((try_share: any) =>
          try_share.type === 1)
        .map((try_share: any) =>
          ({ x: try_share.month, y: try_share.quantity }));

      this.sharesByTypeChartData[1].data = changes.type.currentValue.type
        .filter((fb: any) =>
          fb.type === 2)
        .map((fb: any) =>
          ({ x: fb.month, y: fb.quantity }));

      this.sharesByTypeChartData[2].data = changes.type.currentValue.type
        .filter((wa: any) =>
          wa.type === 3)
        .map((wa: any) =>
          ({ x: wa.month, y: wa.quantity }));

      this.sharesByTypeChartData[3].data = changes.type.currentValue.type
        .filter((other: any) =>
          other.type === 4)
        .map((other: any) =>
          ({ x: other.month, y: other.quantity }));
    }

    if (changes.type.currentValue.total.length > 0) {
      this.total_shares = [];
      this.sharesByTypeChartData[4].data = changes.type.currentValue.total.map((total: any) =>
        ({ x: total.month, y: total.quantity }));

      this.labels = changes.type.currentValue.total.map((total: any) =>
        total.month);
    }

  }

}
