<div class="modal-body">
    
    <div class="container-fluid">
        <h6 *ngIf="data.status !== 'edit'">Proporciona más detalles al técnico para un servico más rápido y eficiente.</h6>
        <div *ngIf="data.status === 'edit'">
            <h6 class="font-italic">Datos del ticket</h6>
            <div class="row">
                <div class="col-md-12">
                    <dl class="row">
                        <dt class="col-sm-3">Cliente: </dt>
                        <dd class="col-sm-9">{{data.ticket.client.name}}</dd>
                        <dt class="col-sm-3">Código de error: </dt>
                        <dd class="col-sm-9">{{error_code_description}}</dd>
                        <dt class="col-sm-3">Técnico asignado: </dt>
                        <dd class="col-sm-9">{{assignation.technician.name}}</dd>
                        <dt class="col-sm-3">Ultima modificación: </dt>
                        <dd class="col-sm-9">{{assignation.updated_at | customDate:'MMMM Do, YYYY, h:mm:ss a'}}</dd>
                    </dl>
                </div>
            </div>
        </div>
        <br>
        <app-form-field type="datepicker" [options]="{
            label: 'Fecha del servicio',
            path: 'schedule_date',
            readonly: 'true',
            placeholder:'Fecha del servicio',
            min_year: min_date.year,
            min_month: min_date.month,
            min_day: min_date.day
        }"></app-form-field>
        <div class="row">

            <div class="col">
                <app-form-field type="timepicker" [options]="{
                    label: 'Hora de inicio',
                    minute_step: 15,
                    path: 'start_time'
                }"></app-form-field>
            </div>
            <div class="col">
                <app-form-field type="timepicker" [options]="{
                    label: 'Hora de finalización',
                    minute_step: 15,
                    path: 'end_time'
                }"></app-form-field>
            </div>
            <div *ngIf="(form.controls['start_time'].invalid && (form.controls['start_time'].dirty) || form.controls['end_time'].invalid && (form.controls['end_time'].dirty))">
                <div *ngIf="(form.controls['start_time'].errors?.checkRangeHour) || (form.controls['end_time'].errors?.checkRangeHour) ">
                    <h6 class="danger">La hora de finalización del servicio es menor a la hora de inicio</h6>  
                </div>
            </div>
        </div>

        <br>
        <h5>Detalles</h5>
        <app-form-field type="textarea" styleInput="textarea" [options]="{rows: '4', path:'details', width: true}">
        </app-form-field>
    </div>
</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="save()">{{view_description}}</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>