import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../../environments/environment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { PromotionalMonthlyFeesService } from '../../../../services/promotional-monthly-fees.service';
import { SwalService } from '../../../../services/swal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-edit-promotional-monthly-fee',
  templateUrl: './create-edit-promotional-monthly-fee.component.html',
  styleUrls: ['./create-edit-promotional-monthly-fee.component.scss']
})
export class CreateEditPromotionalMonthlyFeeComponent implements OnInit, OnDestroy {
  @Input() data: any;
  addFreeMonths = false;
  promotional: any;
  subscriptions: Array<Subscription> = [];
  months = environment.months_ahead.slice(1);
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    data: this.formBuilder.group({
      freeMonths: [false],
      monthsPrice: ['', Validators.required],
      numberOfMonths: ['', Validators.required]
    }),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService,
    private readonly promotionalMonthlyFeesService: PromotionalMonthlyFeesService
  ) { }

  ngOnInit(): void {
    if (this.data.status === 'edit') {
      this.subscriptions.push(this.promotionalMonthlyFeesService.show(this.data.editData.id_promotional_monthly_fee).subscribe((resp: any) => {
        this.promotional = resp.response;
        this.setData();
      }));
    }
    this.fromService.setForm(this.form);
    this.checkFormChange();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  /**
   * save
   * Dependiendo del status de la vista ejecuta la funcionalidad para 
   * guardar cambios o enviar los datos para un nuevo promocional
   */
  save(): void {
    const functionName = `${this.data.status}Promotional`;
    this[functionName]();
  }

  /**
   * createPromotional
   * Envia datos del formulario para registrar un nuevo promocional
   */
  createPromotional(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar el siguiente promocional?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.promotionalMonthlyFeesService.create(this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

  /**
   * editPromotional
   * Guarda los cambios hechos a un promocional existente.
   */
  editPromotional(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del promocional?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.promotionalMonthlyFeesService.update(this.data.id_promotional_monthly_fee, this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
            }
          }));
        }
      });
    }
  }

  /**
   * setData
   * Setea los datos cuando el status es edit.
   */
  private setData(): void {
    Object.keys(this.promotional).forEach(key => {
      if (this.promotional.hasOwnProperty(key) && !!this.form.controls[key]) {
        if (key === 'data') {
          Object.keys(this.promotional.data).forEach(subkey => {
            if (subkey == 'freeMonths' && this.promotional[key][subkey]) {
              this.addFreeMonths = true;
              (this.form.get('data') as FormGroup).addControl('numberOfFreeMonths', new FormControl('', [Validators.required]));
            }
            if (this.promotional.data.hasOwnProperty(subkey) && !!this.form.controls[key]['controls'][subkey]) {
              let value = this.promotional[key][subkey];

              if (subkey === 'monthsPrice') {
                value = parseFloat(Number((this.promotional[key][subkey])).toFixed(0)) / 100;
              }

              this.form.controls[key]['controls'][subkey].setValue(value);
            }
          });
        } else {
          this.form.controls[key].setValue(this.promotional[key]);
        }
      }
    });
  }

  /**
   * checkFormChange
   * Revisa el estatus de data.freeMonths del formulario para saber 
   * si añadir o remover los meses gratis que se le pueden dar a una promocion
   */
  private checkFormChange(): void {
    const freeMonths = this.form.get('data.freeMonths');
    const data = this.form.get('data') as FormGroup;
    this.subscriptions.push(freeMonths.valueChanges.subscribe(inputValue => {
      const numberOfFreeMonths = this.form.get('numberOfFreeMonths');
      if (inputValue === 1) {
        if (!numberOfFreeMonths) {
          this.addFreeMonths = true;
          data.addControl('numberOfFreeMonths', new FormControl('', [Validators.required]));
        }
      } else {
        this.addFreeMonths = false;
        data.removeControl('numberOfFreeMonths');
      }
    }));
  }
}
