import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import * as JWT from 'jwt-decode';

@Injectable({
    providedIn: 'root'
})

export class RoleGuardService implements CanActivate {

    tokenPayload = {
        user: {
            roles: []
        }
    };

    constructor(public auth: AuthorizationService, public router: Router) { }

    canActivate(): boolean {

        let authorized = false;
        this.tokenPayload = this.decodedToken;
        const roles = this.tokenPayload.user.roles;
        
        // User with only one role and role = Client can't access to ERP
        if (this.auth.isAuthenticated()) {
            authorized = true;
            
            if (roles && roles.length > 0 && roles.length === 1 && roles[0].name === 'Client') {
                authorized = false;
            }
        }
        
        return authorized;
         
    }

    get decodedToken(): any {
        const token = window.localStorage.getItem('access_token');
        const decoded = JWT(token);

        return decoded;
    }
}
