import { Component, OnInit, Input } from '@angular/core';
import { BroadcastService } from '../../../../../services/broadcast.service';

@Component({
  selector: 'app-inline-button',
  templateUrl: './inline-button.component.html',
  styleUrls: ['./inline-button.component.scss']
})
export class InlineButtonComponent implements OnInit {
  @Input() data: any;
  @Input() options: any;
  @Input() trRef: any;

  constructor(private readonly broadcast: BroadcastService) { }

  ngOnInit(): void {
    //
  }

  isVisible(conditionallity): any {
    // tslint:disable-next-line: no-eval
    return eval(conditionallity);
  }
  
  fireEvent(event): void {
    Object.assign(this.data, {ref: this.trRef});
    this.broadcast.fire({name: event, data: this.data});
  }

}
