<div class="container-fluid">
    <app-form-field type="input" [options]="{
        label: 'Número de la tarjeta', 
        length: 16,
        path: 'card_number'
    }"></app-form-field>
    <app-form-field type="input" [options]="{label: 'CVC', path: 'cvc'}">
    </app-form-field>
    <app-form-field type="select" [options]="{
        label: 'Mes de expiración',
        path: 'expiration_month',
        droptext: 'Selecciona el mes de expiración',
        elements: months
    }"></app-form-field>
    <app-form-field type="select" [options]="{
        label: 'Año de expiración',
        path: 'expiration_year',
        droptext: 'Selecciona el año de expiración',
        elements: years
    }"></app-form-field>
</div>
<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid" (click)="createCard()">Registrar
            tarjeta</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>