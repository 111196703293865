<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <div class="row">
        <div class="col">
            <h2 class="m-0">Leadsources</h2>
        </div>
        <!-- <div class="col text-right" *ngIf="!isDistributor"> -->
            <button class="btn btn-success" (click)="LeadSourceCreateEdit('create')" *ngxPermissionsOnly="['create-leadsources']">Crear leadsource</button>
        <!-- </div> -->
    </div>
</div>

<div class="container-fluid bg-white p-3">
    <app-datatable [options]="dataTableConfig"></app-datatable>
</div>
