import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SwalService } from '../../../services/swal.service';
import { GroupsService } from '../../../services/groups.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedComponent } from '../../../model/shared-component';
import { CreateEditGroupComponent } from './create-edit-group/create-edit-group.component';
import { PermissionsService } from '../../../services/permissions.service';
import { PlansComponent } from './plans/plans.component';

@Component({
  selector: 'app-sales-group',
  templateUrl: './sales-group.component.html',
  styleUrls: ['./sales-group.component.scss']
})
export class SalesGroupComponent implements OnInit, OnDestroy {
  dataTableConfig: any;
  subscriptions: Array<Subscription> = [];
  private readonly baseDataTableConfig = {
    config: {
      base: this.groupsService,
      api: 'getGroups'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Promoción',
        field: 'promotion',
        type: 'text'
      },
      {
        display: 'Despósito',
        field: 'deposit',
        type: 'text'
      },
      {
        display: 'Cuota mensual',
        field: 'monthly_fee',
        type: 'text'
      },
      {
        display: 'Grupo de ventas',
        field: 'sign_into',
        type: 'text'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Editar',
            icon: 'fa fa-pencil-square-o fa-fw',
            event: 'group.edit',
            conditionality: true,
            permissions: ['update-groups']
          },
          /* {
            display: 'Planes',
            icon: 'fa fa-money fa-fw',
            event: 'plans.list',
            conditionality: true,
            permissions: ['list-groups']
          }, */
          {
            display: 'Eliminar',
            icon: 'fa fa-trash fa-fw',
            event: 'group.delete',
            conditionality: true,
            permissions: ['delete-groups']
          }
        ]
      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly groupsService: GroupsService,
    private readonly broadcast: BroadcastService,
    private readonly appModal: ModalComponent,
    private readonly swal: SwalService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'group.edit': this.editGroupItem(event.data); break;
        case 'group.delete': this.deleteGroupItem(event.data); break;
        case 'plans.list': this.showPlans(event.data); break;
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }
  createGroupItem(): void {
    const props: SharedComponent = new SharedComponent(
      CreateEditGroupComponent,
      {
        status: 'create'
      },
      {
        title: 'Crear grupo'
      }
    );
    this.appModal.openXl(props);
  }

  editGroupItem(data): void {
    const props: SharedComponent = new SharedComponent(
      CreateEditGroupComponent,
      {
        editData: data,
        status: 'edit'
      },
      {
        title: `Edición del grupo ${data.name}`
      }
    );
    this.appModal.openXl(props);
  }

  deleteGroupItem(data): void {
    const swalParams = {
      title: `¿Estás seguro de querer eliminar el grupo ${data.name}?`
    };

    this.swal.warning(swalParams).then(result => {
      if (result.value) {
        this.subscriptions.push(this.groupsService.delete(data.id_groups).subscribe((resp: any) => {
          if (resp.success) {
            this.swal.success({ title: 'Grupo eliminado exitosamente' }).then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurrio un error al intentar borrar el grupo' });
          }
        }));
      }
    });
  }

  showPlans(data): void {
    const props: SharedComponent = new SharedComponent(
      PlansComponent,
      {
        parent: data.id_groups
      },
      {
        title: `Planes del grupo ${data.name}`
      }
    );
    this.appModal.openXl(props);
  }

}
