/**
 * Interceptor to add Bearer token to every request
 */

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';

@Injectable({
  providedIn: 'root'
})

// tslint:disable: no-parameter-reassignment
// tslint:disable: unnecessary-else
// tslint:disable: prefer-function-over-method
export class AuthorizationInterceptorService implements HttpInterceptor {

  constructor(
    public authService: AuthorizationService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (this.authService.token) {
      request = this.addToken(request, this.authService.token);
    }
    
    return next.handle(request);
  }

  // tslint:disable-next-line: prefer-function-over-method
  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      },
      withCredentials: true
    });
  }

}
