<div class="container-fluid">
    <div class="text-right" *ngIf="extraClientImages.length < 5">
        <button class="btn btn-success" (click)="addExtraPhotos()">Adjuntar imagen extra</button>
    </div>
    <div class="text-center" *ngIf="extraClientImages.length > 0">
        <h3>Imagenes extra</h3>
        <br>
        <div class="row">
            <div class="col-sm-12 col-md-3" *ngFor="let extraImage of extraClientImages">
                <h5>{{extraImage.caption}}</h5>
                <img class="image-preview" [src]="extraImage.src.changingThisBreaksApplicationSecurity"
                    alt="Image for extra image of client"
                    (click)="openLightBox(extraImage.src.changingThisBreaksApplicationSecurity)">
            </div>
        </div>
        <hr>
    </div>

    <div class="text-center" *ngIf="isPremium && premiumInstallationIdTicket">
        <h3>Instalación premium - Ticket #{{premiumInstallationIdTicket}}</h3>
        <br>
        <div class="row" *ngIf="premiumInstallationImages.length > 0; else noData">
            <div class="col-sm-12 col-md-3" *ngFor="let premiumImage of premiumInstallationImages">
                <img class="image-preview" [src]="premiumImage.src.changingThisBreaksApplicationSecurity"
                    alt="Image for premium installation"
                    (click)="openLightBox(premiumImage.src.changingThisBreaksApplicationSecurity)">
            </div>
        </div>
        <hr>
    </div>

    <div class="text-center" *ngIf="regularInstallationIdTicket">
        <h3>Instalación regular - Ticket #{{regularInstallationIdTicket}}</h3>
        <br>
        <div class="row" *ngIf="regularInstallationImages.length > 0; else noData">
            <div class="col-sm-12 col-md-3" *ngFor="let regularImage of regularInstallationImages">
                <img class="image-preview" [src]="regularImage.src.changingThisBreaksApplicationSecurity"
                    alt="Image for regular installation"
                    (click)="openLightBox(regularImage.src.changingThisBreaksApplicationSecurity)">
            </div>
        </div>
    </div>
</div>

<ng-template #noData>
    <h5>No se encontraron imagenes asociadas al ticket</h5>
</ng-template>