import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DashboardService = /** @class */ (function () {
    function DashboardService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    DashboardService.prototype.freewater = function (params) {
        // params = Math.floor(Date.now() / 1000);
        return this.http.get(this.baseUrl + "/dashboard/free_water", params);
    };
    DashboardService.prototype.activeUsers = function () {
        return this.http.get(this.baseUrl + "/dashboard/clients");
    };
    DashboardService.prototype.filterChanges = function () {
        return this.http.get(this.baseUrl + "/dashboard/filter-change");
    };
    DashboardService.prototype.dashboard = function (params) {
        return this.http.get(this.baseUrl + "/dashboard", { params: params });
    };
    DashboardService.prototype.incomes = function (params) {
        return this.http.post(this.baseUrl + "/dashboard/incomes", {}, { params: params });
    };
    DashboardService.prototype.ticketComplettions = function (params) {
        return this.http.get(this.baseUrl + "/dashboard/tickets_completion", { params: params });
    };
    DashboardService.prototype.totals = function () {
        return this.http.get(this.baseUrl + "/dashboard/totals");
    };
    DashboardService.prototype.subscriptions = function (params) {
        return this.http.post(this.baseUrl + "/dashboard/subscriptions", {}, { params: params });
    };
    DashboardService.prototype.shares = function (params) {
        return this.http.post(this.baseUrl + "/dashboard/shares", {}, { params: params });
    };
    DashboardService.prototype.clientsCoordinates = function () {
        return this.http.get(this.baseUrl + "/dashboard/clients-coordinates");
    };
    DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.HttpClient)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
