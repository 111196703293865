import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'history-ticket-previous',
  templateUrl: './previous.component.html',
  styleUrls: ['./previous.component.scss']
})
export class PreviousComponent implements OnInit {
  @Input('item') item: any;
  constructor() { }

  ngOnInit(): void {
    //
  }

}
