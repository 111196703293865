/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inputs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./inputs.component";
var styles_InputsComponent = [i0.styles];
var RenderType_InputsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputsComponent, data: {} });
export { RenderType_InputsComponent as RenderType_InputsComponent };
export function View_InputsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "badge badge-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Entrada"])), (_l()(), i1.ɵeld(3, 0, null, null, 12, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["Cantidad ingresada: ", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["Cantidad en stock antes del ingreso: ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["Cantidad en stock despues del ingreso: ", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["Costo unitario ", ""])), i1.ɵppd(12, 1), (_l()(), i1.ɵeld(13, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["Costo total ", ""])), i1.ɵppd(15, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.amount; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.item.before_in_stock; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.item.after_in_stock; _ck(_v, 9, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), _co.item.unitary_cost)); _ck(_v, 11, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v, 0), _co.item.total_cost)); _ck(_v, 14, 0, currVal_4); }); }
export function View_InputsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inputs", [], null, null, null, View_InputsComponent_0, RenderType_InputsComponent)), i1.ɵdid(1, 114688, null, 0, i3.InputsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputsComponentNgFactory = i1.ɵccf("app-inputs", i3.InputsComponent, View_InputsComponent_Host_0, { item: "item" }, {}, []);
export { InputsComponentNgFactory as InputsComponentNgFactory };
