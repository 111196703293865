import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbCarouselConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ComponentsModule } from '../../components/components.module';
import { FromService } from '../../providers/form.service';
import { DevComponent } from './dev.component';
import { DevRoutes } from './dev.routing';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ComponentsModule,
        RouterModule.forChild(DevRoutes),
        NgxPermissionsModule.forChild()
    ],
    declarations: [
        DevComponent
    ],
    entryComponents: [
    ],
    exports: [
        DevComponent
    ],
    providers: [
        NgbCarouselConfig,
        FromService
    ]
})

export class DevModule { }
