<div class="modal-body" *ngIf="data">
    <div class="container">
        <div class="row text-center">
            <div class="col-sm-12">
                <app-form-field type="select" [options]="{
                        label: 'Tipo de págo',
                        width:12,
                        droptext: 'Seleccione una opción...',
                        elements: payment_types,
                        path:'payment_types'
                    }">
                </app-form-field>

                <app-form-field *ngIf="!this.accountsWithDebts" type="select" [options]="{
                label: 'Pagar X meses por adelantado',
                width:12,
                droptext: 'Seleccione una opción...',
                elements: months_ahead,
                path:'months_ahead'}">
                </app-form-field>

                <div *ngIf="active_reference || last_card_charge">
                    <div *ngIf="last_card_charge">
                        <button type="button" class="btn btn-info" (click)="showLastCardChargeInfo()">Ultimo cargo con
                            tarjeta</button>
                        <button type="button" class="btn btn-danger" (click)="cancelLastCharge('card')">Remover bloqueo
                            de tarjeta</button>
                    </div>
                    <br>
                    <div class="bt-group" *ngIf="active_reference">
                        <button type="button" class="btn btn-info" (click)="showActiveReferenceInfo()">Referencia
                            activa</button>
                        <button type="button" class="btn btn-danger" (click)="cancelLastCharge('reference')">Cancelar
                            referencia activa</button>
                    </div>
                </div>
            </div>

            <div class="col-sm-12">
                <p *ngIf="activePromotionalMonthlyFee">
                    Este cliente cuenta con una promoción activa por lo que no se pueden añadir meses por adelantado
                </p>
                <h5>TOTAL</h5>
                <br>
                <dl class="row" *ngFor="let account of accounts">
                    <dt class="col-sm-4">Cuenta</dt>
                    <dd class="col-sm-8">{{account.name}}</dd>
                    <dt class="col-sm-4">
                        {{account.text}}
                        <span *ngIf="account.acumulatedLatePayment">
                            <span *ngFor="let debt of account.debts">
                                <br>
                                {{debt.monthly_charge_date | date :'yyyy-MM'}}
                            </span>
                        </span>
                    </dt>
                    <dd class="col-sm-8">
                        <span *ngIf="account.acumulatedLatePayment">
                            <br>
                            <span *ngFor="let debt of account.debts">
                                {{debt.amount / 100 | currency}}
                                <br>
                            </span>
                        </span>
                        {{account.fixed_monthly_fee | currency}}
                    </dd>
                </dl>

                <dl class="row">
                    <dt class="col-sm-4">Total</dt>
                    <dd class="col-sm-8">{{totalManualCharge() | currency}}</dd>
                </dl>
            </div>
        </div>
        <div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid" (click)="manualPayment()">Crear
            pago</button>
    </div>

    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>