import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
// Services
import { UsersService } from '../../../services/users.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { SwalService } from '../../../services/swal.service';
// Components
import { AdministratorCreateEditComponent } from './administrator-create-edit/administrator-create-edit.component';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-administrators',
  templateUrl: './administrators.component.html',
  styleUrls: ['./administrators.component.scss']
})
export class AdministratorsComponent implements OnInit, OnDestroy {
  role: any;

  dataTableConfig = {
    config: {
      base: this.users,
      api: 'getUserByRole',
      params: {
        role: ''
      }
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Email',
        field: 'email',
        type: 'text'
      },
      {
        display: 'Activo',
        field: 'status',
        type: 'boolean'
      },
      {
        display: 'Fecha de alta',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: [

          {
            display: 'Editar',
            icon: 'fa fa-pencil',
            event: 'user.userEdit',
            conditionality: 'true'
          }

        ]
      }
    ]
  };

  actionEventSubscription: Subscription;

  constructor(
    private readonly users: UsersService,
    private readonly broadcast: BroadcastService,
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    public swal: SwalService,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.role = data;
    });

    this.actionEventSubscription = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'user.userEdit': this.userEditItem(event.data); break;
      }
    });

    this.dataTableConfig.config.params.role = this.role;
  }

  ngOnDestroy(): void {
    this.actionEventSubscription.unsubscribe();
  }

  createUser(data): void {
    const props: SharedComponent = new SharedComponent(
      AdministratorCreateEditComponent,
      {
        userData: data,
        role: this.role,
        status: 'create'
      },
      {
        title: `Crear ${this.role.name}`
      }
    );
    this.appModal.openXl(props);
  }

  userEditItem(data): void {
    const props: SharedComponent = new SharedComponent(
      AdministratorCreateEditComponent,
      {
        user: data,
        role: this.role,
        status: 'edit'
      },
      {
        title: `Editar datos de ${data.name || this.role.name}`
      });
    this.appModal.openXl(props);
  }

}
