<div class="modal-body" *ngIf="declined">
    <ng-container *ngFor="let level of declined  | keyvalue">
        <h4 [ngSwitch]="level.key">
            <span *ngSwitchCase="'level_1'">MARINERO (Nivel 1)</span>
            <span *ngSwitchCase="'level_2'">CABO (Nivel 2)</span>
            <span *ngSwitchCase="'level_3'">CAPITAN (Nivel 3)</span>
            <span *ngSwitchCase="'level_4'">ALMIRANTE (Nivel 4)</span>
        </h4>
        <table class="table" >
            <thead>
                <tr class="d-flex">
                    <th class="col-8">Nombre</th>
                    <th class="col-2">Estatus</th>
                    <th class="col-2">Deuda</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let value of level.value | keyvalue">
                    <tr class="d-flex">
                        <td class="col-8">{{value.value.name}}</td>
                        <td class="col-2" [ngSwitch]="value.value.status">
                            <span *ngSwitchCase="'standby'" class="badge badge-secondary">Pendiente</span>
                            <span *ngSwitchCase="'invalid'" class="badge badge-warning">Invalido</span>
                            <span *ngSwitchCase="'accepted'" class="badge badge-info">Aceptado</span>
                            <span *ngSwitchCase="'canceled'" class="badge badge-danger">Cancelado</span>
                            <span *ngSwitchCase="'rejected'" class="badge badge-danger">Rechazado</span>
                        </td>
                        <td class="col-2">{{(value.value.debt == 0 ? value.value.debt : value.value.debt / 100)  | currency}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </ng-container>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('close button')">Close</button>
</div>