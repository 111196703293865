import { Component, OnInit, Input } from '@angular/core';
import { ChartOptions, ChartDataSets, ChartType } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { SwalService } from '../../../../services/swal.service';
// tslint:disable-next-line: no-default-import
import ChartColors from './investor-chart-colors';

@Component({
  selector: 'app-investor-chart',
  templateUrl: './investor-chart.component.html',
  styleUrls: ['./investor-chart.component.scss']
})
export class InvestorChartComponent implements OnInit {
  @Input() datasets: Array<ChartDataSets>;
  @Input() labels: Array<Label> = [];
  @Input() options: ChartOptions = {};
  @Input() title = '';
  @Input() description = 'No information';
  @Input() chartType: ChartType = 'bar';
  @Input() color: string = null;
  chartColor: Array<Color>;

  constructor(
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    // Setting chart colors
    switch (this.color) {
      case 'primary1': {
        this.chartColor = ChartColors.primary1;
        break;
      }
      case 'primary2': {
        this.chartColor = ChartColors.primary2;
        break;
      }
      case 'primary3': {
        this.chartColor = ChartColors.primary3;
        break;
      }
      case 'secondary1': {
        this.chartColor = ChartColors.secondary1;
        break;
      }
      case 'secondary2': {
        this.chartColor = ChartColors.secondary2;
        break;
      }
      case 'secondary3': {
        this.chartColor = ChartColors.secondary3;
        break;
      }
    }
  }

  showInfoGraphic(): void {
    this.swal.info(this.description, null, this.title);
  }
}
