import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FromService } from '../../../../providers/form.service';
import { SwalService } from '../../../../services/swal.service';
import { GroupsService } from '../../../../services/groups.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { Subscription } from 'rxjs';
class PlanData {
  id_groups: Number; type: Number; status = false;
}

const mandatoryFields = {
  name: ['', Validators.required],
  deposit: ['', Validators.required],
  installation_fee: ['', Validators.required],
  trial_days: [''],
  trial_days_price: [''],
  monthly_fee: ['', Validators.required],
  sign_into: [''],
  description: ['']
};
const normalFields = {
  name: [''],
  deposit: [''],
  installation_fee: [''],
  trial_days: [''],
  trial_days_price: [''],
  monthly_fee: [''],
  sign_into: [''],
  description: ['']
};

@Component({
  selector: 'app-create-edit-group',
  templateUrl: './create-edit-group.component.html',
  styleUrls: ['./create-edit-group.component.scss']
})

export class CreateEditGroupComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  activePlans = {
    hp: new PlanData(),
    bs: new PlanData(),
    bp: new PlanData()
  };

  group_name = false;
  group: any;
  groups = [];
  plansToDelete = [];
  signIntoData = {
    name: '',
    created_at: '',
    deposit: 0,
    installation_fee: 0,
    monthly_fee: 0
  };

  form: FormGroup = this.formBuilder.group({
    hs: this.formBuilder.group(mandatoryFields), // Home Standard
    hp: this.formBuilder.group(normalFields), // Home Premium
    bs: this.formBuilder.group(normalFields), // Business Standard
    bp: this.formBuilder.group(normalFields)  // Business Premium
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly groupService: GroupsService,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    this.getGroups();
    this.getCurrentGroupInfo();
    this.setGroupData();
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  save(): void {
    if (this.data.status === 'edit') {
      this.updateGroup();
    }
    if (this.data.status === 'create') {
      this.saveGroup();
    }
  }

  saveGroup(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar los datos del grupo?' }).then(result => {
        if (result.value) {
          if (this.plansToDelete.length > 0) {
            this.form.addControl('plansToDelete', new FormControl(this.plansToDelete));
          }
          const formData = this.removeSignInto(this.form.value);
          this.subscriptions.push(this.groupService.create(formData).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

  updateGroup(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del grupo?' }).then(result => {
        if (result.value) {
          if (this.plansToDelete.length > 0) {
            this.form.addControl('plansToDelete', new FormControl(this.plansToDelete));
          }
          const formData = this.removeSignInto(this.form.value);
          this.subscriptions.push(this.groupService.update(this.data.editData.id_groups, formData).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
            }
          }));
        }
      });
    }
  }

  getGroups(): void {
    this.subscriptions.push(this.groupService.listGroups().subscribe((resp: any) => {
      if (resp.success) {
        this.groups = resp.response;
      }
    }));
  }

  deletePlan(plan): void {
    if (plan.id_groups && plan.id_groups > 0 && !this.plansToDelete.includes(plan.id_groups)) {
      const plans = { 2: 'hp', 3: 'bs', 4: 'bp' };
      this.swal.warning({ title: '¿Esta seguro de querer borrar los datos de este plan?' }).then(res => {
        if (res.value) {
          const selectedPlan = plans[plan.type];
          this.plansToDelete.push(plan.id_groups);
          this.activePlans[selectedPlan].status = false;
          this.form.get(selectedPlan).reset();
          this.form.get(selectedPlan).markAsDirty();
        }
      });
    }
  }

  private setGroupData(): void {
    if (this.data.status === 'edit') {
      this.subscriptions.push(this.groupService.show(this.data.editData.id_groups).subscribe((resp: any) => {
        this.group = resp.response;
        this.form.controls.hs.patchValue(this.reduceGroupAmount(this.group));
        this.group.plans.map(group => {
          switch (group.type) {
            case 2:
              const hp = this.activePlans.hp;
              hp.id_groups = group.id_groups;
              hp.type = group.type;
              hp.status = true;
              this.form.controls.hp.patchValue(this.reduceGroupAmount(group));
              break;
            case 3:
              const bs = this.activePlans.bs;
              bs.id_groups = group.id_groups;
              bs.type = group.type;
              bs.status = true;
              this.form.controls.bs.patchValue(this.reduceGroupAmount(group));
              break;
            case 4:
              const bp = this.activePlans.bp;
              bp.id_groups = group.id_groups;
              bp.type = group.type;
              bp.status = true;
              this.form.controls.bp.patchValue(this.reduceGroupAmount(group));
              break;
            default: break;
          }
        });
      }));
    }
  }

  // tslint:disable-next-line: prefer-function-over-method
  private reduceGroupAmount(group: any): any {
    const _group = { ...group };
    Object.keys(_group).forEach(key => {
      if (key === 'monthly_fee' || key === 'deposit' || key === 'trial_days_price' || key === 'installation_fee') {
        if (_group[key]) {
          _group[key] = Number((_group[key]) / 100).toFixed(2);
        }
      }
    });

    return _group;
  }

  private removeSignInto(formValues): object {
    const newValues = { ...formValues };
    const notValidValues = ['', null];
    if (!newValues.hs.sign_into) {
      delete newValues.hs.sign_into;
    }

    for (const [key, value] of Object.entries(newValues)) {
      if (value['monthly_fee'] <= 0 || notValidValues.includes(value['monthly_fee'])) {
        delete newValues[key];
      }
    }

    return newValues;
  }

  private getCurrentGroupInfo(): void {
    this.subscriptions.push(this.form.valueChanges.subscribe(fields => {
      if (fields.hs.name) {
        this.group_name = true;
      }

      if (fields.hs.sign_into) {
        const group = this.groups.find(item => item.id_groups === fields.hs.sign_into);
        this.signIntoData = { ...this.reduceGroupAmount(group) };
      } else {
        this.signIntoData = { ...this.form.controls.hs.value };
      }
    }));
  }
}
