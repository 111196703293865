import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { SuppliesService } from '../../../../services/supplies.service';
import { SwalService } from '../../../../services/swal.service';

@Component({
  selector: 'app-supply-input-create',
  templateUrl: './supply-input-create.component.html',
  styleUrls: ['./supply-input-create.component.scss']
})
export class SupplyInputCreateComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  form: FormGroup = this.formBuilder.group({
    id_supplies: ['', Validators.required],
    amount: ['', Validators.required],
    unitary_cost: ['', Validators.required],
    total_cost: ['', Validators.required],
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService,
    private readonly suppliesService: SuppliesService
  ) { }

  ngOnInit(): void {
    this.fromService.setForm(this.form);
    this.form.get('id_supplies').setValue(this.data.id);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * save
   * Dependiendo del status de la vista ejecuta la funcionalidad para 
   * guardar cambios o enviar los datos para un nuevo insumo
   */
  save(): void {
    const functionName = `${this.data.status}SupplyInput`;
    this[functionName]();
  }

  /**
   * createSupplyInput
   * Envia datos del formulario para registrar una nueva entrada al insumo y
   * actualizar el stock.
   */
  createSupplyInput(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer asociar la siguiente entrada al insumo?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.suppliesService.createSupplyInput(this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

}
