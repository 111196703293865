import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { sessionRoutes } from './session.routing';
import { FromService } from '../providers/form.service';
import { ComponentsModule } from '../components/components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        NgbModule,
        RouterModule.forChild(sessionRoutes)
    ],
    declarations: [
        LoginComponent
    ],
    providers: [
        FromService
    ]
})

export class SessionModule { }
