import { Component, OnInit } from '@angular/core';
import { AppUsageService } from '../../../services/app-usage.service';

@Component({
  selector: 'app-app-usage',
  templateUrl: './app-usage.component.html',
  styleUrls: ['./app-usage.component.scss']
})
export class AppUsageComponent implements OnInit {
  app_data = {
    recordsTotal: 0,
    guestTotal: 0
  };

  registrationData = {
    android: 0,
    ios: 0
  };

  dataTableConfig = {
    config: {
      base: this.appUsageService,
      api: 'show',
      order: [[2, 'desc']]
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Versión',
        field: 'version',
        type: 'text'
      },
      {
        display: 'Último uso',
        field: 'updated_at',
        type: 'date'
      }
    ]
  };

  constructor(private readonly appUsageService: AppUsageService) { }

  ngOnInit(): void {
    this.getAppUsageData();
  }

  getAppUsageData(): void {
    this.appUsageService.show().subscribe((resp: any) => {
      Object.keys(resp).forEach(key => {
        if (this.app_data.hasOwnProperty(key)) {
          this.app_data[key] = resp[key];
        }
      });
    });

    this.appUsageService.registrations().subscribe((resp: any) => {
      if (resp && resp.success) {
        Object.keys(resp.response).forEach(key => {
          if (this.registrationData.hasOwnProperty(key)) {
            this.registrationData[key] = resp.response[key];
          }
        });
        // this.registrationData = resp.response;
      }
    });
  }
}
