import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LeadSourcesService = /** @class */ (function () {
    function LeadSourcesService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * index
     * Devuelve todos los leadsources activos e inactivos en el sistema
     * @param params all indica que se devolveran todos los leadsources.
     * @returns 200 | 404 | 500
     */
    LeadSourcesService.prototype.index = function (params) {
        return this.http.get(this.baseUrl + "/leadsources", { params: params });
    };
    /**
     * create
     * Registra un nuevo leadsource en la BD
     * @param params name
     * @returns 200 | 400 | 500
     */
    LeadSourcesService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/leadsources", params);
    };
    /**
     * update
     * Actualiza el estado o nombre de un leadsource
     * @param params id_leadsources, name, status
     * @returns 200 | 404 | 500
     */
    LeadSourcesService.prototype.update = function (params) {
        return this.http.put(this.baseUrl + "/leadsources/update", params);
    };
    /**
     * getLeadSourcesTable
     * @param params con los que se cargara inicialmente la tabla
     * @param filters status para obtener datos mas especificos
     * @returns array con los datos para la tabla
     */
    LeadSourcesService.prototype.getLeadSourcesTable = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/leadsources/leadSourcesTable", params);
    };
    LeadSourcesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LeadSourcesService_Factory() { return new LeadSourcesService(i0.ɵɵinject(i1.HttpClient)); }, token: LeadSourcesService, providedIn: "root" });
    return LeadSourcesService;
}());
export { LeadSourcesService };
