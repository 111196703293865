import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SalesDashboardService = /** @class */ (function () {
    function SalesDashboardService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    SalesDashboardService.prototype.salesByMonth = function (paramsB) {
        // params = Math.floor(Date.now() / 1000);
        return this.http.get(this.baseUrl + "/sales-dashboard/salesMonthCustom", { params: paramsB });
    };
    SalesDashboardService.prototype.salesByCampaign = function (paramsB) {
        return this.http.get(this.baseUrl + "/sales-dashboard/salesCampaignCustom", { params: paramsB });
    };
    SalesDashboardService.prototype.salesByCampaignFacebook = function (paramsB) {
        return this.http.get(this.baseUrl + "/sales-dashboard/salesCampaignFacebook", { params: paramsB });
    };
    SalesDashboardService.prototype.salesByPromotion = function (paramsB) {
        return this.http.get(this.baseUrl + "/sales-dashboard/salesPromotionCustom", { params: paramsB });
    };
    SalesDashboardService.prototype.salesCacCampaign = function () {
        return this.http.get(this.baseUrl + "/sales-dashboard/cacCampaign");
    };
    SalesDashboardService.prototype.salesCac = function (paramsB) {
        return this.http.get(this.baseUrl + "/sales-dashboard/cac", { params: paramsB });
    };
    /**
     * Sales by Agent
     *
     * @param paramsB params {from, to, agent}
     */
    SalesDashboardService.prototype.salesByAgent = function (paramsB) {
        return this.http.get(this.baseUrl + "/sales-dashboard/salesAgentCustom", { params: paramsB });
    };
    SalesDashboardService.prototype.salesByAgentCustom = function (paramsB) {
        return this.http.get(this.baseUrl + "/sales-dashboard/salesAgentCustom?isByOwner=true", { params: paramsB });
    };
    SalesDashboardService.prototype.contactProperty = function (paramsB) {
        return this.http.get(this.baseUrl + "/sales-dashboard/contactProperty", { params: paramsB });
    };
    SalesDashboardService.prototype.owners = function () {
        return this.http.get(this.baseUrl + "/sales-dashboard/owners");
    };
    SalesDashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SalesDashboardService_Factory() { return new SalesDashboardService(i0.ɵɵinject(i1.HttpClient)); }, token: SalesDashboardService, providedIn: "root" });
    return SalesDashboardService;
}());
export { SalesDashboardService };
