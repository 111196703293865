<div class="modal-body">
   <div class="container">
      <ngb-tabset [destroyOnHide]="false">
         <ngb-tab title="Clientes">
            <ng-template ngbTabContent>
               <app-datatable [options]="dataTableConfig"></app-datatable>
            </ng-template>
         </ngb-tab>

         <ngb-tab title="Cadena">
            <ng-template ngbTabContent>
               <div class="row">
                  <div class="col-md-8">
                     <app-clients-referals-treeview [clients]="client" *ngIf="client"></app-clients-referals-treeview>
                  </div>
                  <div class="col-md-4">
                     <div class="sticky-top">
                        <dl class="row">
                           <dt class="col-sm-3">Nombre</dt>
                           <dd class="col-sm-9">{{info.name}}</dd>
                           <dt class="col-sm-3">Nivel</dt>
                           <dd class="col-sm-9">{{info.level}}</dd>
                           <dt class="col-sm-3">Email</dt>
                           <dd class="col-sm-9">{{info.email}}</dd>
                           <dt class="col-sm-3">Estado</dt>
                           <dd class="col-sm-9">{{info.state}}</dd>
                           <dt class="col-sm-3">Status</dt>
                           <dd class="col-sm-9">
                              <span class="badge" 
                                 [ngClass]="{
                                    'badge-info': info.status === 'accepted',
                                    'badge-danger': info.status === 'canceled',
                                    'badge-warning': ['accepted','canceled'].indexOf(info.status) === -1
                                    }">
                                 {{info.status}}
                              </span>
                           </dd>
                        </dl>
                     </div>
                  </div>
               </div>
            </ng-template>
         </ngb-tab>

         <ngb-tab title="Comisiones" *ngIf="others?.commissions">
            <ng-template ngbTabContent>
               <app-clients-referals-commissions [data]="others?.commissions" *ngIf="others?.commissions">
               </app-clients-referals-commissions>
            </ng-template>
         </ngb-tab>

         <ngb-tab title="Comisiones Máximas">
            <ng-template ngbTabContent>
               <app-clients-referals-commissions [isMax]="true" [data]="others?.maxCommissions" *ngIf="others?.maxCommissions">
               </app-clients-referals-commissions>
            </ng-template>
         </ngb-tab>
      </ngb-tabset>
   </div>
</div>
<div class="modal-footer">
   <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('close button')">Close</button>
</div>