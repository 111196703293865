<div class="modal-body">
  <div class="container-fluid">
      <h5>Precios propios</h5>
      <div class="row">
        <div class="col">
          <app-form-field type="input" [options]="{label:'Nombre', path: 'name'}"></app-form-field>
        </div>
        <div class="col">
            <app-form-field type="select"
                [options]="{label:'Tipo', path: 'type', droptext: 'Seleccione una opción...', elements: types}">
            </app-form-field>
        </div>
      </div>
      <app-form-field type="input" [options]="{label:'Depósito', path: 'deposit'}"></app-form-field>
      <app-form-field type="input" [options]="{label:'Cuota de instalación', path: 'installation_fee'}">
      </app-form-field>
      <div class="row">
          <div class="col">
              <app-form-field type="input" [options]="{label:'Promoción', path:'trial_days'}"></app-form-field>
          </div>
          <div class="col">
              <app-form-field type="input" [options]="{label:'días a $', path: 'trial_days_price'}"></app-form-field>
          </div>
      </div>
      <app-form-field type="input" [options]="{label:'Cuota mensual', path: 'monthly_fee'}"></app-form-field>
  </div>
</div>

<br>

<div class="modal-footer">
  <div *ngIf="this.form.dirty">
      <button type="button" class="btn btn-outline-danger"
          (click)="activeModal.dismiss('Cross click')">Cancelar</button>
      <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
          (click)="save()">Guardar</button>
  </div>
  <div *ngIf="!this.form.dirty">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
  </div>
</div>