import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../providers/form.service';
import { ClientsService } from '../../../services/clients.service';
import { ErrrorCodesService } from '../../../services/error-codes.service';
import { SwalService } from '../../../services/swal.service';
import { TicketsService } from '../../../services/tickets.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-support-ticket',
  templateUrl: './create-support-ticket.component.html',
  styleUrls: ['./create-support-ticket.component.scss']
})
export class CreateSupportTicketComponent implements OnInit, OnDestroy {
  @Input() data: any;
  client: any;
  error_codes = [];
  ticket_types = [];
  ticketData: any;
  subscriptions: Array<Subscription> = [];

  form: FormGroup = this.formBuilder.group({
    id_clients: [],
    id_error_codes: ['', Validators.required],
    description: ['', Validators.required],
    estimated_service_fee: [''],
    estimated_service_fee_reasons: [''],
    type: ['', Validators.required],
    status: ['opened']
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly clientService: ClientsService,
    private readonly errorCodesService: ErrrorCodesService,
    private readonly ticketsService: TicketsService,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    this.getClientInfo();
    this.getErrorCodes();
    this.getTicketsTypes();
    this.getClientLastTicket();
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * getClientInfo
   * Obtiene la información del cliente
   */
  getClientInfo(): void {
    this.subscriptions.push(this.clientService.show(this.data.id_clients).subscribe((data: any) => {
      this.client = data.response;
    }));
  }

  /**
   * getErrorCodes
   * Obtiene los codigos de error almacenados en la BD y filtra los que se pueden asignar de manera manual.
   */
  getErrorCodes(): void {
    this.subscriptions.push(this.errorCodesService.index().subscribe((data: any) => {
      data.response.forEach(error_code => {
        if (error_code.id !== 3) {
          this.error_codes.push(error_code);
        }
      });
    }));
  }

  /**
   * getTicketsTypes
   * Obtiene los tipos de tickets de la BD y filtra los que se pueden asignar de manera manual.
   */
  getTicketsTypes(): void {
    this.subscriptions.push(this.ticketsService.getTicketTypes().subscribe((data: any) => {
      data.response.forEach(ticket_type => {
        if (ticket_type.id !== 'installation') {
          this.ticket_types.push(ticket_type);
        }
      });
    }));
  }

  /**
   * createSupportTicket
   * Crea de manera manual un ticket de soporte
   */
  createSupportTicket(): void {
    this.form.controls.id_clients.setValue(this.client.id_clients);

    if (this.form.valid) {
      this.subscriptions.push(this.ticketsService.create(this.form.value).subscribe((data: any) => {
        if (data.success) {
          this.swal.success({ title: 'Ticket creado exitosamente' }).then(() => {
            this.activeModal.dismiss();
          });
        } else {
          this.swal.error({ title: 'Ocurrio un error al crear el ticket' });
        }
      }));
    }
  }

  /**
   * getClientLastTicket
   * Obtiene la información del ultimo ticket del cliente.
   */
  private getClientLastTicket(): void {
    const params = { lastTicket: true };
    this.subscriptions.push(this.clientService.getClientLastTicket(this.data.id_clients, params).subscribe((data: any) => {
      this.ticketData = data.response;
    }));
  }

}
