import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { SalesDashboardService } from '../../../../services/sales-dashboard.service';
import { SwalService } from '../../../../services/swal.service';

@Component({
  selector: 'app-sales-cac',
  templateUrl: './sales-cac.component.html',
  styleUrls: ['./sales-cac.component.scss']
})
export class SalesCacComponent implements OnInit {

  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;

  @Output()
  isLoadMonthChart = new EventEmitter<boolean>();

  isLoad = true;

  barChartColors: Array<Color> = [
    { backgroundColor: '#83b941' },
    { backgroundColor: '#16a4db' },
    { backgroundColor: '#ff843c' },
    { backgroundColor: '#77FF11' },
    { backgroundColor: '#00ffff' }
  ];
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      plugins: {
        datalabels: {
          color: 'black',
          labels: {
            title: {
              font: {
                weight: 'bold'
              }
            },
            value: {
              color: 'black'
            }
          }
        }
      }
    }
  };
  barChartLabels: Array<Label> = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [pluginDataLabels];
  labelsValue: Array<any> = [];

  barChartData: Array<ChartDataSets> = [];
  form: FormGroup = this.formBuilder.group({
    from: [],
    to: []
  });
  fieldFrom: FormControl;
  fieldTo: FormControl;

  constructor(
    private readonly swal: SwalService,
    private readonly salesDashboard: SalesDashboardService,
    private readonly formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.setChart();
    this.fieldFrom = this.form.get('from') as FormControl;
    this.fieldTo = this.form.get('to') as FormControl;
  }

  search(): void {
    const fromSub = this.form.get('from').value;
    const toSub = this.form.get('to').value;
    const params = {
      from: fromSub,
      to: toSub
    };
    if (params.from && params.to) {
      this.setChart(params);
    } else {
      this.swal.error({ title: 'Favor de introducir las fechas correctamente' });
    }
  }

  setChart(params?): void {
    this.isLoad = true;
    this.labelsValue = [];
    this.salesDashboard.salesByMonth(params).subscribe(res => {
      if (res.success) {
        this.salesDashboard.salesCac(params).subscribe((resp: any) => {
          // getting labels                  
          if (resp.success) {
            const barChartLabelData = [];
            const barChartDataL = [];
            const keys = Object.keys(res.response).sort();
            for (const key of keys) {
              for (const cacAd of resp.response) {
                const yearMonth = key.split('-');
                const year = yearMonth[0];
                const month = this.months[parseInt(yearMonth[1], 10) - 1];
                const yearMonthAd = cacAd.balance_date.split('-');
                const yearAd = yearMonthAd[0];
                const monthAd = this.months[parseInt(yearMonthAd[1], 10) - 1];
                if (`${month} ${year}` === `${monthAd} ${yearAd}`) {
                  const closedWon = res.response[key];
                  barChartLabelData.push(`${month} ${year}`);
                  let cac = 0;
                  if (closedWon.length > 0) {
                    cac = cacAd.balance / closedWon.length;
                  }
                  const data = {
                    data: cac.toFixed(2), label: 'Balance', datalabels: {
                      clamp: true,
                      formatter: (value, context) =>
                        `$${value}`
                    }
                  };
                  barChartDataL.push(data);
                }
              }
            }
            this.barChartLabels = [...barChartLabelData];
            // group by options            
            this.barChartData = [...barChartDataL];
            // this.barChartData = barChartDataL;
            this.isLoad = false;
            this.isLoadMonthChart.emit(true);
          } else {
            this.swal.error({ title: 'Error recuperando los datos para la gráfica ventas por mes, intente de nuevo' });
          }
        });
      }
    });
  }

  showInfoGraphic(): void {
    const message = 'Client Aquisition Costs by Month';
    this.swal.info(message);
  }

}
