import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  baseUrl = `${environment.apiUrl}`;

  constructor(private readonly http: HttpClient) { }

  getRoles(params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/roles/get_roles`, params);
  }

  show(rolId: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/roles/${rolId}`);
  }

  createRole(params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/roles`, params);
  }

  updateRole(rolId: number, params?): Observable<any> {
    return this.http.put(`${this.baseUrl}/roles/${rolId}`, params);
  }
}
