<h3 *ngIf="isMax">Comisiones si fueras ALMIRANTE</h3>

<p>Para que los clientes se consideren válidos es necesario que cumplan con los siguientes:</p>
<ul>
    <li>Que su estatus sea "aceptado".</li>
    <li>Que no estén cancelados.</li>
    <li>Que estén al día en su pagos.</li>
    <li>Que no presenten ninguna deuda activa.</li>
</ul>

<table class="table">
    <thead>
        <tr>
            <th>Nivel</th>
            <th>Clientes Válidos</th>
            <th>Comisión</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Aguagratis</td>
            <td>6</td>
            <td>$ 0</td>
        </tr>
        <tr *ngFor="let level of levels; let i = index;">
            <td>Nivel {{i + 1}}</td>
            <td>{{level.count}}</td>
            <td>$ {{level.comission | number:'1.0-0'}}</td>
        </tr>
    </tbody>
    <tfoot>
        <tr>
            <td colspan="2" class="text-right">
                <h2>TOTAL:</h2>
            </td>
            <td>
                <h2>$ {{total | number:'1.0-0'}}</h2>
            </td>
        </tr>
    </tfoot>
</table>
