/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cancellations-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-charts";
import * as i3 from "./cancellations-chart.component";
var styles_CancellationsChartComponent = [i0.styles];
var RenderType_CancellationsChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CancellationsChartComponent, data: {} });
export { RenderType_CancellationsChartComponent as RenderType_CancellationsChartComponent };
export function View_CancellationsChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancelaciones"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "canvas", [["baseChart", ""], ["height", "100"], ["width", "450"]], null, null, null, null, null)), i1.ɵdid(4, 999424, null, 0, i2.BaseChartDirective, [i1.ElementRef, i2.ThemeService], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], colors: [4, "colors"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cancelChartData; var currVal_1 = _co.labels; var currVal_2 = _co.options; var currVal_3 = _co.lineChartType; var currVal_4 = _co.chartColor; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_CancellationsChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cancellations-chart", [], null, null, null, View_CancellationsChartComponent_0, RenderType_CancellationsChartComponent)), i1.ɵdid(1, 638976, null, 0, i3.CancellationsChartComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CancellationsChartComponentNgFactory = i1.ɵccf("app-cancellations-chart", i3.CancellationsChartComponent, View_CancellationsChartComponent_Host_0, { cancellations: "data" }, {}, []);
export { CancellationsChartComponentNgFactory as CancellationsChartComponentNgFactory };
