import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NPSService } from '../../../../../services/nps.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FromService } from '../../../../../providers/form.service';
import { SwalService } from '../../../../../services/swal.service';
import { BroadcastService } from '../../../../../services/broadcast.service';

@Component({
  selector: 'app-nps-form',
  templateUrl: './nps-form.component.html',
  styleUrls: ['./nps-form.component.scss']
})
export class NpsFormComponent implements OnInit {
  @Input() data: any;
  npsItem: any;
  form: FormGroup = this.formBuilder.group({
    date: ['', Validators.required],
    nps: ['', Validators.required]
  });
  constructor(
    public activeModal: NgbActiveModal,
    private readonly npsService: NPSService,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService
  ) { }

  async ngOnInit(): Promise<any> {
    this.fromService.setForm(this.form);
    if (this.data.status === 'edit') {
      try {
        const resp = await this.npsService.show(this.data.npsData.id_nps).toPromise();
        if (resp && resp.success) {
          this.npsItem = resp.response;
          Object.keys(this.npsItem).forEach(key => {
            if (this.npsItem.hasOwnProperty(key) && !!this.form.controls[key]) {
              this.form.controls[key].setValue(this.npsItem[key]);
            }
          });
        }
      } catch (error) {
        // console.log(error.message);
      }
    }
  }

  save(): void {
    if (this.data.status === 'edit') {
      this.updateNPSItem();
    }

    if (this.data.status === 'create') {
      this.saveNPSItem();
    }
  }

  saveNPSItem(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar este NPS' }).then(async result => {
        if (result.value) {
          const resp = await this.npsService.create(this.form.value).toPromise();
          if (resp && resp.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurió un error al guardar los datos' });
          }
        }
      });
    }
  }

  updateNPSItem(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar este NPS?' }).then(async result => {
        if (result.value) {
          const resp = await this.npsService.edit(this.data.npsData.id_nps, this.form.value).toPromise();
          if (resp.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
          }
        }
      });
    }
  }

}
