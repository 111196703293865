import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { WarehouseRoutes } from './warehouse.routing';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SuppliesCategoriesComponent } from './supplies-categories/supplies-categories.component';
import { SuppliesComponent } from './supplies/supplies.component';

@NgModule({
  declarations: [SuppliesCategoriesComponent, SuppliesComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule.forChild(WarehouseRoutes),
    NgxPermissionsModule.forChild()
  ]
})
export class WarehouseModule { }
