<div class="col-md-12 content">
    <dl>
        <history-ticket-badge [item]="item"></history-ticket-badge>
        <dt>Técnico</dt>
        <dd>
        <div class="row">
            <div class="col-md-2">
                <img [src]="imageProfile" class="img-circle" (error)="errorHandler()">
            </div>
            <div class="col-md-10">
                <i class="fa fa-fw fa-lg fa-user"></i>{{ item.technician.name}}<br>
                <i class="fa fa-fw fa-lg fa-phone"></i>{{ item.technician.phone}}<br>
                <i class="fa fa-fw fa-lg fa-envelope"></i>{{ item.technician.email}}
            </div>
        </div>
        </dd>
        <div *ngIf="item.schedule_date_start != null">
            <dt>Fecha programada del servicio:</dt>
            <dd>{{item.schedule_date_start | date: 'dd/MM/yyyy'}}</dd>
            <dt>Horario estimado:</dt>
            <dd>{{item.schedule_date_start | date: 'HH:mm'}} - {{item.schedule_date_end | date: 'HH:mm'}}</dd>
        </div>
        <dt>Detalles</dt>
        <dd class="ng-binding">{{ item.details }}</dd>
    </dl>
</div>