import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DebtsService = /** @class */ (function () {
    function DebtsService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    DebtsService.prototype.getClientDebts = function (params, filters) {
        var id = 0;
        if (filters) {
            id = filters.id_clients;
        }
        Object.assign(params, filters);
        return this.http.post(this.baseUrl + "/debts/" + id + "/getClientDebtsTable", params);
    };
    DebtsService.prototype.chargeDebt = function (id, params) {
        return this.http.post(this.baseUrl + "/debts/" + id + "/charge", params);
    };
    DebtsService.prototype.chargeAllDebts = function (id, params) {
        return this.http.post(this.baseUrl + "/client/" + id + "/debts/chargeAll", params);
    };
    DebtsService.prototype.generateDebtsReport = function () {
        return this.http.get(this.baseUrl + "/debts/generate_debts_report");
    };
    DebtsService.prototype.update = function (id, params) {
        return this.http.put(this.baseUrl + "/debts/" + id, params);
    };
    DebtsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DebtsService_Factory() { return new DebtsService(i0.ɵɵinject(i1.HttpClient)); }, token: DebtsService, providedIn: "root" });
    return DebtsService;
}());
export { DebtsService };
