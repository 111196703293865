import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { GroupsService } from '../../../../services/groups.service';
import { ClientsService } from '../../../../services/clients.service';
import { SwalService } from '../../../../services/swal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../../../services/broadcast.service';

@Component({
  selector: 'app-members-group',
  templateUrl: './members-group.component.html',
  styleUrls: ['./members-group.component.scss']
})
export class MembersGroupComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  clients: any;
  clientsNotifications: any = [];
  dataTableConfig = {
    config: {
      base: this.clientsService,
      api: 'getClients'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: '',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-success',
            name: 'Seleccionar',
            event: 'add.member',
            conditionality: 'true'
          }
        ]
      }
    ]
  };

  constructor(
    private readonly groupsService: GroupsService,
    private readonly clientsService: ClientsService,
    private readonly swal: SwalService,
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.groupsService.getClientNotification(this.data.id_notification_groups).subscribe((res: any) => {
      res.response.users.forEach(client => {
        this.clientsNotifications.push({
          id_users: client.client.id_users,
          id_clients: client.client.id_clients,
          name: client.client.name
        });
      });
    }));
    this.subscriptions.push(this.clientsService.getClients().subscribe((res: any) => {
      this.clients = res.data;
    }));
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'add.member': this.selectClient(event.data); break;
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  selectClient(selclients): void {
    const persons = this.clientsNotifications.find(x => x.id_clients === selclients.id_clients);
    if (persons) {
      // No imprime el mismo usuario 
    } else if (this.clientsNotifications.indexOf(selclients) === -1) {
      this.clientsNotifications.push(selclients);
    }
  }

  removeClient(clients): void {
    this.clientsNotifications.splice(this.clientsNotifications.indexOf(clients), 1);
  }

  membersUpdate(id_notification_groups): void {
    const users = [];
    this.clientsNotifications.forEach(usr => {
      users.push({
        user_id: usr.id_users
      });
    });
    const params = {
      notification_group: {
        name: this.data.name,
        description: this.data.description,
        users
      }
    };

    const swal_title = 'Estás seguro de modificar ';

    this.swal.warning({ title: swal_title }).then(resp => {
      if (resp.value) {
        this.subscriptions.push(this.groupsService.editClientNotification(id_notification_groups, params).subscribe((data: any) => {
          if (data.success) {
            this.swal.success();
            this.activeModal.dismiss();
          } else {
            this.swal.error({ title: data.message });
          }
        }));
      }
    });

  }
}
