import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ChargesService } from '../../../../services/charges.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FromService } from '../../../../providers/form.service';
import { Subscription } from 'rxjs';
import { SwalService } from '../../../../services/swal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService } from '../../../../services/broadcast.service';

@Component({
  selector: 'app-make-refund',
  templateUrl: './make-refund.component.html',
  styleUrls: ['./make-refund.component.scss']
})
export class MakeRefundComponent implements OnInit, OnDestroy {
  @Input() data: any;
  charge: any;
  refundData = {
    id_charges: 0,
    amount: 0,
    total_refunded: 0,
    max_amount_to_refund: 0,
    refunds: []
  };
  subscriptions: Array<Subscription> = [];
  form: FormGroup = this.formBuilder.group({
    amount: ['', Validators.required]
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly formService: FromService,
    private readonly chargeService: ChargesService,
    private readonly swal: SwalService) { }

  ngOnInit(): void {
    this.subscriptions.push(this.chargeService.show(this.data.id_charges).subscribe((data: any) => {
      this.charge = data.response;
      this.refundData.amount = this.charge.amount;
      this.refundData.refunds = this.charge.refunds;
      this.refundData.total_refunded = this.totalRefunded();
      this.refundData.max_amount_to_refund = this.maxAmountToRefund();
    }));
    this.formService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  totalRefunded(): number {
    let total_refunded = 0;

    for (let i = 0; i < this.refundData.refunds.length; i++) {
      const amount = Number(this.charge.refunds[i].amount);
      total_refunded += amount;
    }

    return (total_refunded) / 100;
  }

  maxAmountToRefund(): number {
    const maxRefund = this.refundData.amount - this.totalRefunded();

    return maxRefund;
  }

  makeRefund(): void {
    if (this.form.valid) {
      const params = this.form.value;
      this.swal.warning({
        title: '¿Esta seguro de querer efectuar la siguiente devolución?',
        text: 'Se procesara la siguiente transacción'
      }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.chargeService.refund(this.charge.id_charges, params).subscribe((data: any) => {
            if (data.success) {
              this.swal.success({ text: 'Reembolso efectuado exitosamente' }).then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurrió un error al realizar el reembolso' });
            }
          }));
        }
      });
    }
  }
}
