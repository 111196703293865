<div class="investor-container">
  <img src="assets/img/investors/image.png" style="width: 100%;" />

  <div class="row" style="margin-top: 20px;">
    <div class="col-md-4">
      <!-- Customer satisfaction -->
      <app-investor-kpi
        [icon]="'fa-thumbs-up'"
        [backGround]="'bg-info'"
        [title]="customerSatisfactionData.title"
        [value]="customerSatisfactionData.value"
        [description]="customerSatisfactionData.description"
      >
      </app-investor-kpi>
    </div>
    <!-- <div class="col-md-3"> -->
      <!-- YTD Growth rate -->
      <!-- <app-investor-kpi
        [icon]="'fa-thumbs-up'"
        [backGround]="'bg-success'"
        [title]="growthRateData.title"
        [value]="growthRateData.value"
        [description]="growthRateData.description"

      >
      </app-investor-kpi> -->
    <!-- </div> -->
    <div class="col-md-4">
      <!-- Net promoter score -->
      <app-investor-kpi
        [icon]="'fa-thumbs-up'"
        [backGround]="'bg-warning'"
        [title]="netPromoterScoreData.title"
        [value]="netPromoterScoreData.value"
        [description]="netPromoterScoreData.description"
      >
      </app-investor-kpi>      
    </div>
    <div class="col-md-4">
      <!-- Churn Cancellations rate -->
      <app-investor-kpi
        [icon]="'fa-line-chart'"
        [backGround]="'bg-danger'"
        [title]="churnRateData.title"
        [value]="churnRateData.value"
        [description]="churnRateData.description"
      >
      </app-investor-kpi>   
    </div>
  </div>
  <br />
  <div class="container-fluid border-bottom bg-white p-3 mb-4">
    <h3>
      Client heat map
      <span 
        class="fa fa-info-circle fa-1x" 
        style="cursor: pointer;"
        (click)="showInfo()"
        data-toggle="tooltip" 
        data-placement="right"
        title="Show description"></span>
    </h3> 
    <app-clients-heatmap></app-clients-heatmap>
  </div>
  <br />
  <div class="row">
    <div class="col-sm-4">
        <app-form-field type="datepicker" [options]="{path:'from', placeholder:'Desde'}"></app-form-field>
    </div>
    <div class="col-sm-4">
        <app-form-field type="datepicker" [options]="{path:'to', placeholder: 'Hasta'}"></app-form-field>
    </div>
    <div class="col-sm-4">
        <button class="btn btn-primary" type="button" (click)="getAllData()">Buscar</button>
    </div>
</div>
  <!-- Cumulative installations per month -->
  <app-investor-chart
    [title]="installationsPerMonthData.title"
    [chartType]="installationsPerMonthData.chartType"
    [options]="installationsPerMonthData.options"
    [datasets]="installationsPerMonthData.datasets"
    [labels]="installationsPerMonthData.labels"
    [color]="'primary2'"
    [description]="installationsPerMonthData.description"
  ></app-investor-chart>

  <!-- Annual recurring revenue -->
  <app-investor-chart
    [title]="annualRecurringRevenueData.title"
    [chartType]="annualRecurringRevenueData.chartType"
    [options]="annualRecurringRevenueData.options"
    [datasets]="annualRecurringRevenueData.datasets"
    [labels]="annualRecurringRevenueData.labels"
    [color]="'primary1'"
    [description]="annualRecurringRevenueData.description"
  ></app-investor-chart>

  <!-- Cash -->
  <app-investor-chart
    [title]="cashData.title"
    [chartType]="cashData.chartType"
    [options]="cashData.options"
    [datasets]="cashData.datasets"
    [labels]="cashData.labels"
    [color]="'primary3'"
    [description]="cashData.description"
  ></app-investor-chart>

  <!-- Customer lifetime value -->
  <app-investor-chart
    [title]="customerLifetimeValueData.title"
    [chartType]="customerLifetimeValueData.chartType"
    [options]="customerLifetimeValueData.options"
    [datasets]="customerLifetimeValueData.datasets"
    [labels]="customerLifetimeValueData.labels"
    [color]="'primary3'"
    [description]="customerLifetimeValueData.description"
  ></app-investor-chart>

  <!-- Commited monthly recurring revenue -->
  <app-investor-chart
    [title]="commitedMonthlyRecurringRevenueData.title"
    [chartType]="commitedMonthlyRecurringRevenueData.chartType"
    [options]="commitedMonthlyRecurringRevenueData.options"
    [datasets]="commitedMonthlyRecurringRevenueData.datasets"
    [labels]="commitedMonthlyRecurringRevenueData.labels"
    [color]="'primary2'"
    [description]="commitedMonthlyRecurringRevenueData.description"
  ></app-investor-chart>

  <!-- Monthly referrers rate -->
  <app-investor-chart
    [title]="monthlyReferrersRateData.title"
    [chartType]="monthlyReferrersRateData.chartType"
    [options]="monthlyReferrersRateData.options"
    [datasets]="monthlyReferrersRateData.datasets"
    [labels]="monthlyReferrersRateData.labels"
    [color]="'primary1'"
    [description]="monthlyReferrersRateData.description"
  ></app-investor-chart>

  <!-- Churn rate per month -->
  <app-investor-chart
    [title]="monthlyChurnRateData.title"
    [chartType]="monthlyChurnRateData.chartType"
    [options]="monthlyChurnRateData.options"
    [datasets]="monthlyChurnRateData.datasets"
    [labels]="monthlyChurnRateData.labels"
    [color]="'secondary2'"
    [description]="monthlyChurnRateData.description"
  ></app-investor-chart>

  <!-- Monthly recurring revenue -->
  <app-investor-chart
    [title]="monthlyRecurringRevenueData.title"
    [chartType]="monthlyRecurringRevenueData.chartType"
    [options]="monthlyRecurringRevenueData.options"
    [datasets]="monthlyRecurringRevenueData.datasets"
    [labels]="monthlyRecurringRevenueData.labels"
    [color]="'primary1'"
    [description]="monthlyRecurringRevenueData.description"
  ></app-investor-chart>

  <!-- Annual revenue rate -->
  <!-- <app-investor-chart
    [title]="annualRevenueRateData.title"
    [chartType]="annualRevenueRateData.chartType"
    [options]="annualRevenueRateData.options"
    [datasets]="annualRevenueRateData.datasets"
    [labels]="annualRevenueRateData.labels"
    [color]="'secondary1'"
    [description]="annualRevenueRateData.description"
  ></app-investor-chart> -->

  <!-- Annual growth rate -->
  <app-investor-chart
    [title]="annualGrowthRateData.title"
    [chartType]="annualGrowthRateData.chartType"
    [options]="annualGrowthRateData.options"
    [datasets]="annualGrowthRateData.datasets"
    [labels]="annualGrowthRateData.labels"
    [color]="'primary3'"
    [description]="annualGrowthRateData.description"
  ></app-investor-chart>


<!-- 
  <div class="row">


    <div class="col-md-3">
      // YTD Cancellations rate
      <app-investor-kpi
      [icon]="'fa-thumbs-down'"
      [backGround]="'bg-danger'"
      [title]="cancellationsRateData.title"
      [value]="cancellationsRateData.value"
    >
    </app-investor-kpi>
    </div>
    <div class="col-md-3">
      // Rolling growth rate
      <app-investor-kpi
        [icon]="'fa-refresh'"
        [backGround]="'bg-success'"
        [title]="rollingGrowRateData.title"
        [value]="rollingGrowRateData.value"
      >
      </app-investor-kpi>
    </div>
  </div>
  <br />



  // Annual cancellations rate
  <app-investor-chart
    [title]="annualCancellationsRateData.title"
    [chartType]="annualCancellationsRateData.chartType"
    [options]="annualCancellationsRateData.options"
    [datasets]="annualCancellationsRateData.datasets"
    [labels]="annualCancellationsRateData.labels"
    [color]="'secondary2'"
  ></app-investor-chart>
   -->
</div>