import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FromService } from '../../providers/form.service';
import { DashboardService } from '../../services/dashboard.service';
import { SwalService } from '../../services/swal.service';
var DashboardComponent = /** @class */ (function () {
    // activeUsers$: Subscription;
    // freeWater$: Subscription;
    // incomes$: Subscription;
    // shares$: Subscription;
    // subscriptions$: Subscription;
    // dashboard$: Subscription;
    // totals$: Subscription;
    function DashboardComponent(dashboardService, fromService, formBuilder, swal) {
        this.dashboardService = dashboardService;
        this.fromService = fromService;
        this.formBuilder = formBuilder;
        this.swal = swal;
        this.kpiData = {
            active_users: 0,
            debts: 0,
            free_water: 0,
            free_water_percent: 0,
            sr: 0
        };
        this.tableData = {
            clientStatus: 0,
            filterChanges: {},
            ticketStatus: {
                opened: 0,
                closed: 0,
                assigned: 0,
                confirmed: 0,
                completed: 0
            }
        };
        this.subscriptionsData = {
            actualSubscriptions: [],
            pastSubscriptions: []
        };
        this.incomesData = {
            actualIncomes: [],
            pastIncomes: []
        };
        this.sharesTotalData = {
            total: []
        };
        this.sharesTypeData = {
            type: [],
            total: []
        };
        this.ticketsCompletitionsData = {
            ticketsCompleted: []
        };
        this.cancellationsData = {
            actualCancelations: []
        };
        this.form = this.formBuilder.group({
            from: [],
            to: []
        });
        this.subscriptions = [];
    }
    DashboardComponent.prototype.ngOnInit = function () {
        this.getActiveUsers();
        this.getFilterChanges();
        this.getIncomes();
        this.getSubcriptions();
        this.getTickets();
        this.getTicketCompletions();
        this.getTotals();
        this.getShares();
        this.fromService.setForm(this.form);
    };
    DashboardComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
        // this.activeUsers$.unsubscribe();
        // this.freeWater$.unsubscribe();
        // this.incomes$.unsubscribe();
        // this.subscriptions$.unsubscribe();
        // this.dashboard$.unsubscribe();
        // this.totals$.unsubscribe();
    };
    DashboardComponent.prototype.freeWaterPercent = function () {
        var free_water_percent = 0;
        if (this.kpiData.free_water || this.kpiData.active_users > 0) {
            free_water_percent = Math.round((this.kpiData.free_water / this.kpiData.active_users) * 100);
        }
        return free_water_percent;
    };
    DashboardComponent.prototype.getActiveUsers = function () {
        var _this = this;
        this.subscriptions.push(this.dashboardService.activeUsers().subscribe(function (resp) {
            resp.clients.forEach(function (clienStatus) {
                if (clienStatus.status === 'accepted') {
                    // this.kpiData.active_users = Number(clienStatus.charged);
                    _this.kpiData.active_users = Number(clienStatus.active);
                }
            });
            _this.tableData.clientStatus = resp.clients;
            _this.subscriptions.push(_this.dashboardService.freewater().subscribe(function (data) {
                _this.kpiData.free_water = data.length;
                _this.kpiData.free_water_percent = _this.freeWaterPercent();
            }));
        }));
    };
    DashboardComponent.prototype.getFilterChanges = function () {
        var _this = this;
        this.dashboardService.filterChanges().subscribe(function (resp) {
            if (resp && resp.success) {
                _this.tableData.filterChanges = resp.response;
            }
        });
    };
    DashboardComponent.prototype.getIncomes = function (params) {
        var _this = this;
        this.subscriptions.push(this.dashboardService.incomes(params).subscribe(function (resp) {
            var nIn = {
                actualIncomes: resp.incomes.slice(),
                pastIncomes: resp.past_incomes.slice()
            };
            _this.incomesData = tslib_1.__assign({}, nIn);
        }));
    };
    DashboardComponent.prototype.getShares = function (params) {
        var _this = this;
        this.subscriptions.push(this.dashboardService.shares(params).subscribe(function (resp) {
            var ntotal = {
                total: resp.total.slice()
            };
            var nType = {
                type: resp.byType.slice(),
                total: resp.total.slice()
            };
            _this.sharesTotalData = tslib_1.__assign({}, ntotal);
            _this.sharesTypeData = tslib_1.__assign({}, nType);
        }));
    };
    DashboardComponent.prototype.getSubcriptions = function (params) {
        var _this = this;
        this.subscriptions.push(this.dashboardService.subscriptions(params).subscribe(function (resp) {
            var nSubs = {
                actualSubscriptions: resp.new.slice(),
                pastSubscriptions: resp.past.slice()
            };
            _this.subscriptionsData = tslib_1.__assign({}, nSubs);
        }));
    };
    DashboardComponent.prototype.getTickets = function (params) {
        var _this = this;
        this.subscriptions.push(this.dashboardService.dashboard(params).subscribe(function (resp) {
            _this.tableData.ticketStatus.opened = resp.tickets.opened;
            _this.tableData.ticketStatus.closed = resp.tickets.closed;
            _this.tableData.ticketStatus.assigned = resp.tickets.assigned;
            _this.tableData.ticketStatus.confirmed = resp.tickets.confirmed;
            _this.tableData.ticketStatus.completed = resp.tickets.completed;
            var nIn = {
                actualCancelations: resp.subscription_events.slice()
            };
            _this.cancellationsData = tslib_1.__assign({}, nIn);
        }));
    };
    DashboardComponent.prototype.getTicketCompletions = function (params) {
        var _this = this;
        this.dashboardService.ticketComplettions(params).subscribe(function (resp) {
            var tCom = {
                ticketsCompleted: resp.slice()
            };
            _this.ticketsCompletitionsData = tslib_1.__assign({}, tCom);
        });
    };
    DashboardComponent.prototype.getTotals = function () {
        var _this = this;
        this.subscriptions.push(this.dashboardService.totals().subscribe(function (resp) {
            _this.kpiData.debts = resp.totals.debts;
            var clientSocial = parseInt(resp.totals.client_social, 10);
            var aguagenteSocial = parseInt(resp.totals.aguagente_social, 10);
            _this.kpiData.sr = Math.floor(clientSocial + aguagenteSocial);
        }));
    };
    DashboardComponent.prototype.search = function () {
        var from_sub = this.form.get('from').value;
        var to_sub = this.form.get('to').value;
        var params = {
            from: from_sub,
            to: to_sub
        };
        if (params.from && params.to !== '') {
            this.getSubcriptions(params);
            this.getIncomes(params);
            this.getTickets(params);
            this.getShares(params);
            this.getTicketCompletions(params);
        }
        else {
            this.swal.error({ title: 'Favor de introducir las fechas correctamente' });
        }
    };
    return DashboardComponent;
}());
export { DashboardComponent };
