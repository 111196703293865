import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SwalService } from './swal.service';
@Injectable({
  providedIn: 'root'
})

export class ClientsService {
  baseUrl = `${environment.apiUrl}`;

  constructor(private readonly http: HttpClient, private readonly swal: SwalService) { }

  getClients(params?, filters?): Observable<any> {
    if (filters && filters.id_users !== 0) {
      Object.assign(params, filters);
    }

    return this.http.post(`${this.baseUrl}/clients/get_clients`, params);
  }

  show(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/clients/${id}`);
  }

  delete(id): Observable<any> {
    return this.http.delete(`${this.baseUrl}/clients/${id}`);
  }

  setStatus(id?, params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/clients/${id}/setStatus`, params);
  }

  checkActiveOfflineReference(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/client/${id}/checkActiveOfflineReference`);
  }

  makeSalesAgent(id?, params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/clients/${id}/attachRole`, params);
  }

  update(id, params?): Observable<any> {
    return this.http.put(`${this.baseUrl}/clients/${id}`, params);
  }

  getHierarchicalTree(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/clients/${id}/hierarchicalTree`);
  }

  getImages(id): Observable<any> {
    return this.http.get(`${this.baseUrl}/clients/${id}/getImages`);
  }

  getHistory(id): any {
    const id_clients = id.id_clients;
    const id_users = id.id_users;
    const paramsClient = {
      params: { id_clients }
    };
    const paramsUser = {
      params: { id_users }
    };

    const corrections = this.http.get(`${this.baseUrl}/corrections`, paramsClient);
    const invalidations = this.http.get(`${this.baseUrl}/invalidations`, paramsClient);
    const rejections = this.http.get(`${this.baseUrl}/rejections`, paramsClient);
    const cancellations = this.http.get(`${this.baseUrl}/cancellations`, paramsClient);
    const notifications = this.http.get(`${this.baseUrl}/notifications`, paramsUser);

    return forkJoin([corrections, invalidations, rejections, cancellations, notifications]);
  }

  getLogs(params): Observable<any> {
    const logParams = {
      params: {
        id_users: params.id_users,
        id_clients: params.id_clients
      }
    };

    return this.http.get(`${this.baseUrl}/logs`, logParams);
  }

  getPriceHistory(params): Observable<any> {

    return this.http.get(`${this.baseUrl}/prices/client-price-change-logs/${params.id_clients}`);
  }

  updateMonthlyFee(id_client, params): Observable<any> {

    return this.http.put(`${this.baseUrl}/clients/${id_client}/updateMonthlyFee`, params);
  }

  updatePlan(id_client, params): Observable<any> {
    return this.http.put(`${this.baseUrl}/clients/${id_client}/updateClientPlan`, params);
  }

  calculateMonthlyFee(client): number {
    let monthly_fee = Number(client.monthly_fee);

    if (client.social_responsability) {
      const sr = ((monthly_fee / 1.16) * 0.007);
      monthly_fee = (monthly_fee + sr);
    }
    monthly_fee = parseFloat(monthly_fee.toFixed(0)) / 100;

    return monthly_fee;
  }

  getClientsName(): Observable<any> {
    return this.http.get(`${this.baseUrl}/clients?status=accepted`).pipe(
      map((result: any) => {
        if (result.success) {
          return result.response.map((client: any) =>
            ({ id: client.id_clients, name: client.name }));
        }
      })
    );
  }

  /**
   * checkLastPayaments
   * 
   * @param id_client id del cliente al cual se le van a verificar sus ultimos cargos OXXO|SPEI|TARJETA
   */
  checkLastPayments(id_client): Observable<any> {
    const last_reference = this.http.get(`${this.baseUrl}/client/${id_client}/checkActiveOfflineReference`);
    const last_card_charge = this.http.get(`${this.baseUrl}/client/${id_client}/checkLastCardCharge`);

    return forkJoin([last_reference, last_card_charge]);
  }

  /**
   * getClientByEmail
   * Manda una petición GET para obtener mediante el correo la información de un cliente registrado
   * en la BD.
   * @param email del referido
   * @returns 200 | 404 objeto con los datos del referido
   */
  getClientByEmail(email): Observable<any> {
    return this.http.get(`${this.baseUrl}/clients?email=${email}`);
  }

  registerClient(params): Observable<any> {
    return this.http.post(`${this.baseUrl}/contracts`, params);
  }

  /**
   * changeDeviceRecoveryStatus
   * 
   * @param id_client int id del cliente
   * @param params booleano que determina el status para el dispositivo
   * @returns 200 | 404
   */
  changeDeviceRecoveryStatus(id_client, params): Observable<any> {
    return this.http.post(`${this.baseUrl}/clients/${id_client}/changeDeviceRecoveryStatus`, params);
  }

  /**
   * updateClientsSalesGroup
   * Envia una petición POST para actualizar el grupo de ventas de los clientes
   * @param params optional listado de id de clientes aceptados
   * @returns 200 | 404
   */
  updateClientsSalesGroup(params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/clients/updateClientsSalesGroup`, params);
  }

  /**
   * associateChildAccounts
   * Envia una petición PUT para asociar un array de id a una cuenta padre.
   * @param id del cliente que sera el padre
   * @param params {childAccounts: Array<number>}
   * @returns 200 | 400 | 404
   */
  associateChildAccounts(id, params): Observable<any> {

    return this.http.put(`${this.baseUrl}/clients/${id}/associateChildAccounts`, params);
  }

  /**
   * disAssociateChildAccounts
   * Envia una petición PUT para volver una cuenta hija una cuenta regular.
   * @param id del cliente hijo que se volvera cuenta individual
   * @returns 200 | 400 | 404
   */
  disAssociateChildAccounts(id, params?): Observable<any> {

    return this.http.put(`${this.baseUrl}/clients/${id}/disAssociateChildAccounts`, params);
  }

  assignPromotionalMonthlyFee(params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/clients/assignPromotionalMonthlyFee`, params);
  }

  /**
    * cancelPromotion
    * @param params Objeto con id_client:int, extra_data: string, expirable: boolean, amount: int
    * @returns 200 | 400 | 404 | 500
    */
  cancelPromotion(params): Observable<object> {
    const idPromotion = params.id_client_promotional;

    return this.http.post(`${this.baseUrl}/clients/promotionalMonthlyFee/${idPromotion}/cancel`, params);
  }

  getClientPromotionalMonthlyFee(params, filters?): Observable<any> {
    let id = 0;

    if (filters) {
      id = filters.id_clients;
      Object.assign(params, filters);
    }

    return this.http.post(`${this.baseUrl}/clients/${id}/getClientPromotionalMonthlyFeesTable`, params);

  }

  addClientExtraImages(params, id_clients): Observable<any> {
    return this.http.post(`${this.baseUrl}/clients/${id_clients}/addExtraImages`, params);
  }

  getImagesExtraCategories(): Observable<any> {
    return this.http.get(`${this.baseUrl}/clients/getClientExtraImagesCategories`);
  }

  getClientLastTicket(id, params?): Observable<object> {

    return this.http.get(`${this.baseUrl}/clients/${id}/getClientTickets`, { params });
  }
}
