import { Component, OnInit, OnDestroy } from '@angular/core';
import { CashService } from '../../../../services/cash.service';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../../../services/broadcast.service';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { SharedComponent } from '../../../../model/shared-component';
import { SwalService } from '../../../../services/swal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CashFormComponent } from './cash-form/cash-form.component';
import { PermissionsService } from '../../../../services/permissions.service';

@Component({
  selector: 'app-cash-table',
  templateUrl: './cash-table.component.html',
  styleUrls: ['./cash-table.component.scss']
})
export class CashTableComponent implements OnInit, OnDestroy {
  broadcast$: Subscription;
  dataTableConfig: any;

  private readonly baseDataTableConfig = {
    config: {
      base: this.cashService,
      api: 'getCash'
    },
    columns: [
      {
        display: 'Fecha de balance',
        field: 'balance_date',
        type: 'date'
      },
      {
        display: 'Balance',
        field: 'balance',
        type: 'currency'
      },
      {
        display: 'Distribuidor',
        field: 'team',
        type: 'text',
        onlyForTeam: [3]
      },
      {
        display: 'Acciones',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Editar',
            icon: 'fa fa-pencil',
            event: 'cash.edit',
            conditionality: 'true',
            permissions: ['update-cash']
          },
          {
            display: 'Eliminar',
            icon: 'fa fa-trash fa-fw',
            event: 'cash.delete',
            conditionality: 'true',
            permissions: ['delete-cash']
          }
        ]

      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    private readonly cashService: CashService,
    private readonly broadcast: BroadcastService,
    private readonly swal: SwalService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.broadcast$ = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'cash.delete': this.deleteCashItem(event.data); break;
        case 'cash.edit': this.editCashItem(event.data); break;
      }
    });
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    this.broadcast$.unsubscribe();
  }

  createCashItem(data?): void {
    const props: SharedComponent = new SharedComponent(
      CashFormComponent,
      {
        cashData: data,
        status: 'create'
      },
      {
        title: 'Crear entrada de cash'
      }
    );
    this.appModal.opensm(props);
  }

  deleteCashItem(data): void {
    const swalParams = {
      title: `¿Estás seguro de querer eliminar el balance del día ${data.balance_date}?`
    };

    this.swal.warning(swalParams).then(async result => {
      if (result.value) {
        const resp = await this.cashService.delete(data.id_cash).toPromise();
        if (resp && resp.success) {
          this.swal.success({ title: 'Entrada de cash eliminada exitosamente' }).then(() => {
            this.activeModal.dismiss();
            this.broadcast.reloadDataTable();
          });
        } else {
          this.swal.error('Ocurrio un error al intentar borrar la entrada de cash');
        }
      }
    });
  }

  editCashItem(data): void {
    // open modal, passing the context
    const props: SharedComponent = new SharedComponent(
      CashFormComponent,
      {
        cashData: data,
        status: 'edit'
      },
      {
        title: 'Entradas de cash'
      });
    this.appModal.opensm(props);
  }

}
