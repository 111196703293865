import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { forkJoin } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SuppliesService = /** @class */ (function () {
    function SuppliesService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * createSupply
     * Manda los parametros para crear una nueva categoria de insumo
     * @param params Object {name, data}
     * @returns 200 | 400 | 500
     */
    SuppliesService.prototype.createSupply = function (params) {
        return this.http.post(this.baseUrl + "/supplies", params);
    };
    /**
     * updateSupply
     * Manda los parametros para actualizar los datos de un insumo
     * @param id int ID del supplyCategory a actualizar.
     * @param params Object {name, data}
     * @returns 200 | 400 | 404 | 500
     */
    SuppliesService.prototype.updateSupply = function (id, params) {
        return this.http.put(this.baseUrl + "/supplies/" + id, params);
    };
    /**
     * showSupply
     * Devuelve la informacion de un supply
     * @param id int ID del supply.
     * @returns 200 | 404
     */
    SuppliesService.prototype.showSupply = function (id, params) {
        return this.http.get(this.baseUrl + "/supplies/" + id, params);
    };
    /**
     * getSuppliesDataTable
     * Obtiene los datos para mostrar la tabla de insumos
     * @param params
     * @param filters
     * @returns JSON object
     */
    SuppliesService.prototype.getSuppliesDataTable = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/supplies/getSuppliesDataTable", params);
    };
    /**
     * createSupplyInput
     * Manda los parametros para registrar una nueva entrada al insumo y
     * actualizar su stock insumo
     * @param params Object {name, data}
     * @returns 200 | 400 | 500
     */
    SuppliesService.prototype.createSupplyInput = function (params) {
        return this.http.post(this.baseUrl + "/supplies/inputs", params);
    };
    /**
     * indexCategoriesSupplies
     * Devuelve todas las categorias de insumos
     * @param id int ID del supply.
     * @returns 200 | 404
     */
    SuppliesService.prototype.indexCategoriesSupplies = function () {
        return this.http.get(this.baseUrl + "/supplies/categories");
    };
    /**
     * createCategorySupply
     * Manda los parametros para crear una nueva categoria de insumo
     * @param params Object {name, data}
     * @returns 200 | 400 | 500
     */
    SuppliesService.prototype.createSupplyCategory = function (params) {
        return this.http.post(this.baseUrl + "/supplies/categories", params);
    };
    /**
     * updateCategorySupply
     * Manda los parametros para actualizar los datos una categoria de insumo
     * @param id int ID del supplyCategory a actualizar.
     * @param params Object {name, data}
     * @returns 200 | 400 | 404 | 500
     */
    SuppliesService.prototype.updateSupplyCategory = function (id, params) {
        return this.http.put(this.baseUrl + "/supplies/categories/" + id, params);
    };
    /**
     * showSupplyCategory
     * Devuelve la informacion de un supplyCategory
     * @param id int ID del supplyCategory.
     * @returns 200 | 404
     */
    SuppliesService.prototype.showSupplyCategory = function (id, params) {
        return this.http.get(this.baseUrl + "/supplies/categories/" + id, params);
    };
    /**
     * getCategoriesSuppliesDataTable
     * Obtiene los datos para mostrar la tabla de categoria de insumos
     * @param params
     * @param filters
     * @returns JSON object
     */
    SuppliesService.prototype.getCategoriesSuppliesDataTable = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/supplies/categories/getCategoriesSuppliesDataTable", params);
    };
    /**
     * getHistory
     * Obtiene el historial de todas las entradas y salidas de un insumo.
     * @param id id del supply
     * @returns forkjoin
     */
    SuppliesService.prototype.getHistory = function (id) {
        var params = {
            params: { id: id }
        };
        var historyItems = [
            this.http.get(this.baseUrl + "/supplies/inputs", params),
            this.http.get(this.baseUrl + "/supplies/outputs", params)
        ];
        return forkJoin(historyItems);
    };
    SuppliesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SuppliesService_Factory() { return new SuppliesService(i0.ɵɵinject(i1.HttpClient)); }, token: SuppliesService, providedIn: "root" });
    return SuppliesService;
}());
export { SuppliesService };
