<span *ngIf="this.field">
    <div class="form-group row" [ngClass]="{invalid: field && field.invalid}">
        <label *ngIf="options.label"
            class="col-sm-{{options?.width ? options.width:4}} col-form-label font-weight-bold">
            <div [innerHTML]="options?.label"></div>
        </label>
        <div class="col-sm-{{options?.width ? 12:8}}" [ngSwitch]="type">
            <input *ngSwitchCase="'input'" [ngClass]="{uppercase: options.uppercase}" type="text" class="form-control"
                [readonly]="options?.disabled ? options?.disabled : false" placeholder="{{options?.placeholder}}"
                maxlength="{{options?.length ? options.length: 255}}" [formControl]="field">

            <input *ngSwitchCase="'inputNumber'" [ngClass]="{uppercase: options.uppercase}" type="number"
                class="form-control" [readonly]="options?.disabled ? options?.disabled : false"
                placeholder="{{options?.placeholder}}" maxlength="10" [formControl]="field">

            <input *ngSwitchCase="'mask'" type="text" class="form-control" [formControl]="field" mask="{{options.mask}}"
                placeholder="{{options?.placeholder}}">

            <input *ngSwitchCase="'email'" type="email" class="form-control" placeholder="{{options?.placeholder}}"
                [formControl]="field" autocomplete="off">

            <input *ngSwitchCase="'password'" type="password" class="form-control"
                placeholder="{{options?.placeholder}}" [formControl]="field" autocomplete="off">

            <input *ngSwitchCase="'fake_password'" type="text" class="form-control"
                placeholder="{{options?.placeholder}}" [formControl]="field" autocomplete="off"
                onfocus="this.type='password'">

            <textarea *ngSwitchCase="'textarea'" type="text" class="form-control" [formControl]="field"
                rows="{{options.rows}}" placeholder="{{options?.placeholder}}">
        </textarea>

            <select *ngSwitchCase="'select'" class="form-control" [formControl]="field" title="{{options?.title}}">
                <option disabled selected value="">{{options?.droptext}}</option>
                <option *ngFor="let option of options?.elements" [value]="option.id">{{option.name}}</option>
            </select>

            <div *ngSwitchCase="'switch'" class="custom-control custom-switch">
                <input type="checkbox" (change)="switched($event)" [checked]="isChecked" class="custom-control-input"
                    id="{{options?.path}}">
                <label class="custom-control-label" for="{{options?.path}}">{{ isChecked ? (options.trueCaption || 'Activo')
                    :
                    (options.falseCaption || 'Inactivo') }}</label>
            </div>

            <input *ngSwitchCase="'radio'" type="radio" class="radio-inline" (change)="reset($event)"
                [checked]="checked" [formControl]="field" name="radio" [id]="options.id"
                placeholder="{{options?.placeholder}}">

            <input *ngSwitchCase="'checkbox'" type="checkbox" (change)="onCheckChange(options.value, $event)"
                id="custom-switch" placeholder="{{options?.placeholder}}">
            <div *ngSwitchCase="'multiselect'">
                <ng-select [multiple]="options?.multiple ? true:false"
                    [closeOnSelect]="options.closeOnSelect ? true : false"
                    [searchable]="options?.searchable ? true:false" [hideSelected]="true"
                    [virtualScroll]="options?.virtualScroll ? true:false" bindLabel="options.label"
                    placeholder="{{options.placeholder}}" [formControl]="field">
                    <ng-option *ngFor="let item of options?.values" [value]="item.id">{{item.name}}</ng-option>
                </ng-select>
            </div>
            <div *ngSwitchCase="'image'">
                <div class="row" *ngIf="options.multiple && images.length > 0">
                    <div *ngFor="let image of images" class="col-sm-12 col-md-4 multi-image-preview">
                        <div id="review" [style.background-image]="bgStyle(image)">
                        </div>
                    </div>
                </div>
                <div class="avatar-upload" [ngClass]="options?.class">
                    <div class="avatar-edit">
                        <input type='file' id="imageUpload-{{options?.path}}" (change)="readFile($event)"
                            [multiple]="options.multiple ? true : false" accept=".png, .jpg, .jpeg" />
                        <label for="imageUpload-{{options?.path}}"></label>
                        <div *ngIf="options?.zoom && options?.image">
                            <button class="btn btn-link" (click)="openLightBox(options?.image)" title="{{options?.title}}">
                                <i class="fa fa-search-plus"></i>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!options?.multiple" class="avatar-preview">
                        <div id="imagePreview" [style.background-image]="bgStyle()">
                        </div>
                    </div>
                    <br>
                    <div *ngIf="options?.buttonVisible">
                        <label for="imageUpload-{{options?.path}}"
                            class="btn btn-primary btn-block">{{options.text}}</label>
                    </div>
                </div>
            </div>

            <div *ngSwitchCase="'datepicker'">
                <input [readonly]="options?.readonly ? options.readonly : false" class="form-control"
                    placeholder="{{options?.placeholder}}" [formControl]="field" ngbDatepicker #d="ngbDatepicker"
                    [minDate]="{
                    year: options?.min_year ? options.min_year : 2011, 
                    month: options?.min_month ? options.min_month : 1, 
                    day: options?.min_day ? options.min_day : 1
                }" [maxDate]="{
                    year: maxDate().max_year, 
                    month: maxDate().max_month, 
                    day: maxDate().max_day
                }" (click)="d.toggle()">
            </div>

            <div *ngSwitchCase="'timepicker'">
                <ngb-timepicker [formControl]="field" [minuteStep]="options?.minute_step ? options.minute_step : 1">
                </ngb-timepicker>
            </div>
        </div>
    </div>
</span>