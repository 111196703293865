import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalComponent } from '../../../components/modal/modal.component';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../../services/broadcast.service';
import { ReviewRefundsComponent } from './review-refunds/review-refunds.component';
import { SharedComponent } from '../../../model/shared-component';
import { MakeRefundComponent } from './make-refund/make-refund.component';
import { ChargesService } from '../../../services/charges.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChargeSummaryComponent } from '../../../components/shared/charge-summary/charge-summary.component';
import { SwalService } from '../../../services/swal.service';
import { InvoicesService } from '../../../services/invoices.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-client-charges-history',
  templateUrl: './client-charges-history.component.html',
  styleUrls: ['./client-charges-history.component.scss']
})
export class ClientChargesHistoryComponent implements OnInit, OnDestroy {
  @Input() data: any;
  notSupportedEvents = environment.notSupportedEvents;
  subscriptions: Array<Subscription> = [];
  dataTableConfig = {
    config: {
      base: this.chargeService,
      api: 'getClientCharges',
      params: {
        id_clients: 0
      }
    },
    columns: [
      {
        display: 'Descripción',
        field: 'description',
        type: 'charge_description'
      },
      {
        display: 'Nota de credito',
        field: 'invoice_status',
        type: 'text'
      },
      {
        display: 'Cantidad',
        field: 'amount',
        type: 'charge_amount'
      },
      {
        display: 'Fecha de pago',
        field: 'paid_at',
        type: 'text'
      },
      {
        display: 'Efectuado en',
        field: 'vendor.name',
        type: 'text'
      },
      {
        display: 'Tipo de cargo',
        field: 'charge_type_name',
        type: 'text'
      },
      {
        display: 'Pagado con',
        field: 'paid_in',
        type: 'text'
      },
      {
        display: 'Pago creado el',
        field: 'created_at',
        type: 'date'
      },
      {
        display: '',
        field: '',
        type: 'actions',
        permissions: ['list-refunds', 'refund-charges', 'show-charges'],
        options: [
          {
            icon: 'fa fa-list fa-fw m-r-sm',
            display: 'Devoluciones',
            event: 'viewRefundsItem',
            permissions: ['list-refunds'],
            conditionality: 'this.data.refunds.length > 0'
          },
          {
            icon: 'fa fa-reply fa-fw m-r-sm',
            display: 'Efectuar devolución',
            event: 'makeRefundItem',
            permissions: ['refund-charges'],
            conditionality: 'this.data.paid_at !== null && this.data.able_to_refund'
          },
          {
            icon: 'fa fa-book',
            display: 'Resumen del cargo',
            event: 'chargeSummary',
            conditionality: true
          },
          {
            icon: 'fa fa-file-text-o',
            display: 'Generar factura',
            event: 'chargeGenerateInvoiceItem',
            permissions: ['create-invoices'],
            conditionality: 'this.data.ableToGenInvoice'
          }
        ]

      }
    ],
    filters: [
      {
        title: 'Estatus de pago',
        cssClass: 'col-md-4',
        type: 'charge_status',
        options: [
          {
            val: '',
            name: 'TODOS'
          },
          {
            val: 'paid',
            name: 'Pagado'
          },
          {
            val: 'no_paid',
            name: 'No pagado'
          }
        ]
      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    public swalService: SwalService,
    private readonly chargeService: ChargesService,
    private readonly invoiceService: InvoicesService,
    private readonly broadcast: BroadcastService,
    private readonly appModal: ModalComponent
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      if (!this.notSupportedEvents.includes(event.name)) {
        this[event.name](event.data);
      }
    }));
    this.dataTableConfig.config.params.id_clients = this.data.id_clients;
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  makeRefundItem(data): void {
    const props: SharedComponent = new SharedComponent(MakeRefundComponent, data, { title: 'Devolución parcial o total' });
    this.appModal.opensm(props);
  }

  viewRefundsItem(data): void {
    const props: SharedComponent = new SharedComponent(
      ReviewRefundsComponent,
      data,
      { title: `Devoluciónes del cliente ${this.data.name}` }
    );
    this.appModal.opensm(props);
  }

  /**
   * chargeSummary
   * Muestra el resumen de un cargo
   */
  chargeSummary(data): void {
    const formatData = data;
    const props: SharedComponent = new SharedComponent(
      ChargeSummaryComponent,
      formatData,
      { title: `Resumen del cargo de ${this.data.name}` }
    );
    this.appModal.opensm(props);
  }

  /**
   * chargeGenerateInvoiceItem
   * Envia una peticion post para generar una factura manual para el cargos seleccionado.
   * @param data informacion del cargo
   */
  chargeGenerateInvoiceItem(data): void {
    this.swalService.warning(
      {
        title: '¿Generar factura?',
        text: '¿Esta seguro de querer generar una factura para este cargo?'
      }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.invoiceService.generateInvoice(data.id_charges).subscribe((resp: any) => {
            if (resp.success) {
              this.swalService.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            }
          }));
        }
      });
  }
}
