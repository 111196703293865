<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <div class="row" *ngIf="!data">
        <div class="col">
            <h2 class="m-0">Clientes/Vendedores</h2>
        </div>
        <div class="col text-right">
            <button class="btn btn-success" (click)="createClient()" *ngxPermissionsOnly="['create-clients']">Registrar cliente</button>
            <button class="btn btn-info" (click)="updateAcceptedClientsSalesGroup()" *ngxPermissionsOnly="['update-clients']">Actualizar grupo de ventas</button>
        </div>
    </div>
    <div *ngIf="data && data.is_parent" class="text-right">
        <button class="btn btn-info" (click)="clientCards(data)" *ngxPermissionsOnly="['list-cards']">Ver tarjetas registradas</button>
        <button class="btn btn-success" (click)="showUniquePaymentModal()" *ngxPermissionsOnly="['create-charges']">Cargo familiar</button>
    </div>
</div>

<div class="container-fluid bg-white p-3">
    <app-datatable [options]="dataTableConfig"></app-datatable>
</div>
