import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-investor-kpi',
  templateUrl: './investor-kpi.component.html',
  styleUrls: ['./investor-kpi.component.scss']
})
export class InvestorKpiComponent implements OnInit {
  @Input() backGround = 'bg-warning'; // Class name that defines background color
  @Input() icon = 'fa-hand-peace-o'; // Class name that defines icon to show
  @Input() title = ''; // Title of the KPI
  @Input() value = ''; // Value of KPI
  @Input() description = ''; // Description of KPI

  ngOnInit(): void {
    //
  }

}
