import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokensService } from './tokens.service';
import { VendorsService } from './vendors.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./tokens.service";
import * as i3 from "./vendors.service";
var CardsService = /** @class */ (function () {
    function CardsService(http, tokensService, vendorsService) {
        this.http = http;
        this.tokensService = tokensService;
        this.vendorsService = vendorsService;
        this.months = [];
        this.years = [];
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * getCards
     * Petición POST que solicita la información para la tabla de las tarjetas asociadas
     * a un cliente
     * @param params parametros para el filtrado de la tabla
     * @param filters opcional: parametros que se pueden enviar si se desea hacer un filtrado mas detallado de la tabla.
     * @returns json con la información necesaria para la tabla
     */
    CardsService.prototype.getCards = function (params, filters) {
        var id = 0;
        if (filters) {
            id = filters.id_clients;
        }
        return this.http.post(this.baseUrl + "/client/" + id + "/getCards", params);
    };
    /**
     * setAsDefault
     * Envia una petición POST para actualizar la tarjeta predeterminada bajo la cual se intentara
     * hacer primero los cargos
     * @param params objeto que incluye el id de la tarjeta y del cliente
     * @returns 200 | 404
     */
    CardsService.prototype.setAsDefault = function (params) {
        return this.http.post(this.baseUrl + "/clients/" + params.id_client + "/cards/" + params.id_card + "/setAsDefault", params);
    };
    /**
     * create
     * Envia una petición POST para registrar una nueva tarjeta en cada uno de los vendors activos.
     * @param id_client int identificador del cliente
     * @param params Object que incluye los tokens de la tarjeta
     * @returns 200 | 404
     */
    CardsService.prototype.create = function (id_client, params) {
        return this.http.post(this.baseUrl + "/clients/" + id_client + "/cards", params);
    };
    /**
     * delete
     * Envia una petición DELETE para borrar una tarjeta, si el cliente tiene solo una tarjeta
     * y su status es aceptado la tarjeta no se podra borrar
     * @param params objeto que incluye el id de la tarjeta y del cliente
     * @returns 200 | 404
     */
    CardsService.prototype.delete = function (params) {
        return this.http.delete(this.baseUrl + "/clients/" + params.id_client + "/cards/" + params.id_card, params);
    };
    /**
     * setVendors
     * Inicializa las credenciales de los procesadores de pago.
     */
    CardsService.prototype.setVendors = function () {
        var _this = this;
        Conekta.setPublishableKey(environment.apis.conekta);
        OpenPay.setId(environment.apis.openpay.merchant);
        OpenPay.setApiKey(environment.apis.openpay.key);
        OpenPay.setSandboxMode(!environment.apis.openpay.production);
        this.vendorsService.getAll().subscribe(function (resp) {
            _this.vendors = resp.response;
        });
    };
    /**
     * cardValidation
     * Valida con el plugin de Conekta si la tarjeta es valida
     * @param data datos del formulario de la tarjeta.
     * @returns string con el error, boolean false si no se encontro ningun error
     */
    CardsService.prototype.cardValidation = function (data) {
        if (!Conekta.card.validateNumber(data.card_number)) {
            return 'Número de tarjeta invalido';
        }
        if (!Conekta.card.validateCVC(data.cvc)) {
            return 'CVC incorrecto';
        }
        if (!Conekta.card.validateExpirationDate(data.expiration_month, data.expiration_year)) {
            return 'Fecha de vencimiento invalida';
        }
        return false;
    };
    /**
     * setDateCardInfo
     * Setea en los array de month y year los valores a mostrar para registrar tarjetas
     */
    CardsService.prototype.setDateCardInfo = function () {
        var date = new Date();
        for (var i = 0; i < 10; i++) {
            this.years.push({ id: (date.getUTCFullYear() + i).toString(), name: (date.getUTCFullYear() + i).toString() });
        }
        for (var i = 1; i < 13; i++) {
            var str = "" + i;
            var pad = '00';
            var str_pad = pad.substring(0, pad.length - str.length) + str;
            this.months.push({ id: str_pad, name: str_pad });
        }
        return { months: this.months, years: this.years };
    };
    /**
     * getTokens
     * retorna los tokens generados para la tarjeta.
     * @param personalData informacion del cliente.
     * @param paymentData informacion basica de la tarjeta.
     * @returns objeto con los tokens de la tarjeta para cada vendor.
     */
    CardsService.prototype.getTokens = function (personalData, paymentData) {
        var _this = this;
        return forkJoin.apply(void 0, this.vendors.map(function (vendor) { return _this.createTokens(vendor, personalData, paymentData); }));
    };
    /**
     * createTokens
     * Envia la información necesaria a cada uno de los vendors para generar el token para registrar las tarjeta en cada uno de los
     * procesadores de pago
     * @param vendor procesador de pagos a los cuales se enviara la petición de generar el token de la tarjeta
     * @param personalData información del cliente
     * @param paymentData informacion basica de la tarjeta.
     * @returns objeto con los tokens de cada uno de los vendors
     */
    CardsService.prototype.createTokens = function (vendor, personalData, paymentData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (vendor.name.toUpperCase()) {
                    case 'CONEKTA':
                        return [2 /*return*/, this.tokensService.conekta(vendor.name, this.createDataConekta(personalData, paymentData))];
                    case 'OPENPAY':
                        return [2 /*return*/, this.tokensService.openpay(vendor.name, this.createDataOpenpay(personalData, paymentData))];
                    default:
                        return [2 /*return*/, this.tokensService.default()];
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * createDataConetka
     * Genera un objeto con la información necesaria para poder tokenizar los datos de la tarjeta.
     * @param personalData información del cliente
     * @param paymentData información basica de la tarjeta.
     * @returns Objeto con los datos necesarios para Conekta
     */
    // tslint:disable-next-line: prefer-function-over-method
    CardsService.prototype.createDataConekta = function (personalData, paymentData) {
        var data = {
            name: personalData.name,
            number: paymentData.card_number,
            cvc: paymentData.cvc,
            exp_month: paymentData.expiration_month,
            exp_year: paymentData.expiration_year
        };
        return data;
    };
    /**
     * createDataOpenpay
     * Genera un objeto con la información necesaria para poder tokenizar los datos de la tarjeta.
     * @param personalData información del cliente
     * @param paymentData información basica de la tarjeta.
     * @returns Objeto con los datos necesarios para OpenpayFFFFF
     */
    // tslint:disable-next-line: prefer-function-over-method
    CardsService.prototype.createDataOpenpay = function (personalData, paymentData) {
        var data = {
            holder_name: personalData.name,
            card_number: paymentData.card_number,
            cvv2: paymentData.cvc,
            expiration_month: paymentData.expiration_month,
            expiration_year: paymentData.expiration_year.substring(2, 4),
            address: {
                line1: personalData.address + " " + personalData.between_streets,
                line2: personalData.colony,
                line3: personalData.county,
                postal_code: personalData.postal_code,
                city: personalData.county,
                state: personalData.state,
                country_code: 'MX'
            }
        };
        return data;
    };
    CardsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CardsService_Factory() { return new CardsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.TokensService), i0.ɵɵinject(i3.VendorsService)); }, token: CardsService, providedIn: "root" });
    return CardsService;
}());
export { CardsService };
