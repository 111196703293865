<div class="container m-2">
    <h3>{{ data.name }}</h3>
    <h3>{{ data.description }}</h3>
    <label class="col-sm-12 col-form-label font-weight-bold int">Intregantes</label>
    <div class="row">
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="row">
                    <div class="col-md-12">
                      <i>Clientes</i>
                    </div>
                  </div>
                </div>
                <div class="panel-body">
                  <div class="table-responsive">
                    <table class="table table-striped table-hover">
                      <tbody>
                          <app-datatable [options]="dataTableConfig"></app-datatable>
                      </tbody>
                    </table>
                  </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="panel panel-default">
                <div class="panel-heading">
                  <div class="row">
                    <div class="col-md-12">
                      <i>Clientes seleccionados</i>
                    </div>
                  </div>
                </div>
                <div class="panel-body">
                  <div class="table-responsive clientSelected">
                      <table class="table table-striped table-hover">
                          <tbody>
                            <tr *ngFor="let client of clientsNotifications">
                                <td>{{client.name}}</td>
                                <td class="remove"><button type="button" class="btn btn-outline-success" (click)="removeClient(client)">Remover</button></td>
                            </tr>
                          </tbody>
                      </table>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="membersUpdate(data.id_notification_groups)">Actualizar</button>
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
</div>
