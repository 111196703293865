<app-kpi [data]="kpiData"></app-kpi>
<app-dashboard-tables [data]="tableData"></app-dashboard-tables>
<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <h3>
      Mapa de clientes
    </h3> 
    <app-clients-map></app-clients-map>
  </div>
<div class="container-fluid border-bottom bg-white p-3 mb-4 col-sm-12 col-md-12">
    <div class="row">
        <div class="col-sm-3">
            <h3>Nuevas Subscripciones</h3>
        </div>
        <div class="col-sm-3">
            <app-form-field type="datepicker" [options]="{path:'from', placeholder:'Desde'}"></app-form-field>
        </div>
        <div class="col-sm-3">
            <app-form-field type="datepicker" [options]="{path:'to', placeholder: 'Hasta'}"></app-form-field>
        </div>
        <div class="col-sm-3">
            <button class="btn btn-primary" type="button" (click)="search()">Buscar</button>
        </div>
    </div>
    <app-subscriptions-chart [data]="subscriptionsData"></app-subscriptions-chart>
</div>

<div class="container-fluid border-bottom bg-white p-3 mb-4 col-sm-12 col-md-12">
    <app-incomes-chart [data]="incomesData"></app-incomes-chart>
</div>

<div class="container-fluid border-bottom bg-white p-3 mb-4 col-sm-12 col-md-12">
    <app-cancellations-chart [data]="cancellationsData"></app-cancellations-chart>
</div>

<div class="container-fluid border-bottom bg-white p-3 mb-4 col-sm-12 col-md-12">
    <app-tickets-completitions-chart [data]="ticketsCompletitionsData"></app-tickets-completitions-chart>
</div>

<div class="container-fluid border-bottom bg-white p-3 mb-4 col-sm-12 col-md-12">
    <app-shares-by-type-chart [data]="sharesTypeData"></app-shares-by-type-chart>
</div>
