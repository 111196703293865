import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SatisfactionService } from '../../../../../services/satisfaction.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FromService } from '../../../../../providers/form.service';
import { SwalService } from '../../../../../services/swal.service';
import { BroadcastService } from '../../../../../services/broadcast.service';

@Component({
  selector: 'app-satisfaction-form',
  templateUrl: './satisfaction-form.component.html',
  styleUrls: ['./satisfaction-form.component.scss']
})
export class SatisfactionFormComponent implements OnInit {
  @Input() data: any;
  satisfactionItem: any;
  form: FormGroup = this.formBuilder.group({
    date: ['', Validators.required],
    satisfaction: ['', Validators.required]
  });
  constructor(
    public activeModal: NgbActiveModal,
    private readonly satisfactionService: SatisfactionService,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService
  ) { }

  async ngOnInit(): Promise<any> {
    this.fromService.setForm(this.form);
    if (this.data.status === 'edit') {
      try {
        const resp = await this.satisfactionService.show(this.data.satisfactionData.id_satisfaction).toPromise();
        if (resp && resp.success) {
          this.satisfactionItem = resp.response;
          Object.keys(this.satisfactionItem).forEach(key => {
            if (this.satisfactionItem.hasOwnProperty(key) && !!this.form.controls[key]) {
              this.form.controls[key].setValue(this.satisfactionItem[key]);
            }
          });
        }
      } catch (error) {
        // console.log(error.message);
      }
    }
  }

  save(): void {
    if (this.data.status === 'edit') {
      this.updateSatisfactionItem();
    }

    if (this.data.status === 'create') {
      this.saveSatisfactionItem();
    }
  }

  saveSatisfactionItem(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar esta satisfacción del cliente?' }).then(async result => {
        if (result.value) {
          const resp = await this.satisfactionService.create(this.form.value).toPromise();
          if (resp && resp.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurió un error al guardar los datos' });
          }
        }
      });
    }
  }

  updateSatisfactionItem(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar esta satisfacción del cliente?' }).then(async result => {
        if (result.value) {
          const resp = await this.satisfactionService.edit(this.data.satisfactionData.id_satisfaction, this.form.value).toPromise();
          if (resp.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
          }
        }
      });
    }
  }

}
