import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FromService } from '../../../../../providers/form.service';
import { SwalService } from '../../../../../services/swal.service';
import { GroupsService } from '../../../../../services/groups.service';
import { BroadcastService } from '../../../../../services/broadcast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-edit-plan',
  templateUrl: './create-edit-plan.component.html',
  styleUrls: ['./create-edit-plan.component.scss']
})
export class CreateEditPlanComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  group: any;
  types = [
    { id: 1, name: 'Home' },
    { id: 2, name: 'Small Business' }
  ];
  form: FormGroup = this.formBuilder.group({
    name: [''],
    deposit: ['', Validators.required],
    installation_fee: ['', Validators.required],
    trial_days: [''],
    trial_days_price: [''],
    monthly_fee: ['', Validators.required],
    sign_into: [null],
    type: ['', Validators.required],
    parent: ['', Validators.required]
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly groupService: GroupsService,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    if (this.data.status === 'edit') {
      this.subscriptions.push(this.groupService.show(this.data.editData.id_groups).subscribe((resp: any) => {
        this.group = resp.response;
        Object.keys(this.group).forEach(key => {
          if (this.group.hasOwnProperty(key) && !!this.form.controls[key]) {
            if (key === 'monthly_fee' || key === 'deposit' || key === 'trial_days_price' || key === 'installation_fee') {
              this.form.controls[key].setValue(Number((this.group[key]) / 100));
            } else {
              this.form.controls[key].setValue(this.group[key]);
            }

          }
        });
      }));
    }
    if (this.data.parent) {
      this.form.controls.parent.setValue(this.data.parent);
    }

    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  save(): void {
    if (this.data.status === 'edit') {
      this.updatePlan();
    }
    if (this.data.status === 'create') {
      this.savePlan();
    }
  }

  private savePlan(): void {
    this.prices();
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar los datos del plan?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.groupService.create(this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

  private updatePlan(): void {
    this.prices();
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del plan?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.groupService.update(this.data.editData.id_groups, this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
            }
          }));
        }
      });
    }
  }

  private prices(): void {
    const deposit = Number((this.form.get('deposit').value) * 100);
    const trial_days_price = Number((this.form.get('trial_days_price').value) * 100);
    const monthly_fee = Number((this.form.get('monthly_fee').value) * 100);
    const installation_fee = Number((this.form.get('installation_fee').value) * 100);
    this.form.controls.deposit.setValue(deposit);
    this.form.controls.trial_days_price.setValue(trial_days_price);
    this.form.controls.monthly_fee.setValue(monthly_fee);
    this.form.controls.installation_fee.setValue(installation_fee);
  }

}
