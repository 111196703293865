import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { CreditsService } from '../../../../services/credits.service';
import { SwalService } from '../../../../services/swal.service';
import { ApiResponse } from '../../../../model/shared-component.interface';
import { environment } from '../../../../../environments/environment';
import { ClientsService } from '../../../../services/clients.service';

@Component({
  selector: 'app-create-credit',
  templateUrl: './create-credit.component.html',
  styleUrls: ['./create-credit.component.scss']
})

export class CreateCreditComponent implements OnInit {
  @Input() data: any;
  apiresponse: ApiResponse;
  expirable: boolean;
  manualAmount: boolean;
  monthAmount: boolean;
  subscriptions: Array<Subscription> = [];
  disabled = false;
  months = environment.months_ahead.slice(1);
  monthCreditTotal = 0;
  creditModes = [{ id: 'months', name: 'Meses' }, { id: 'manual', name: 'Manual' }];
  form: FormGroup = this.formBuilder.group({
    id_clients: [],
    credit_mode: ['', Validators.required],
    amount: ['', Validators.required],
    automatic: [false, Validators.required],
    extra_data: this.formBuilder.group({
      reason: ['', Validators.required]
    })
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly clientsService: ClientsService,
    private readonly creditsService: CreditsService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swalService: SwalService
  ) { }

  ngOnInit(): void {
    this.form.controls.id_clients.setValue(this.data.id_clients);
    this.fromService.setForm(this.form);
    this.checkCreditMode();
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * createCredit
   * Manda una petición POST a la API para generar un nuevo credito al cliente
   */
  createCredit(): void {
    const formData = this.form.value;
    this.swalService.warning({ title: '¿Esta seguro de querer asignar este credito?' }).then(result => {
      if (result.value) {
        this.subscriptions.push(this.creditsService.createCredit(formData).subscribe((resp: ApiResponse) => {
          if (resp.success) {
            this.swalService.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swalService.error({ title: 'Ocurió un error al guardar los datos' });
          }
        }));
      }
    });
  }

  /**
   * checkCreditMode
   * Check the value of the dropdown to select who to create the credit
   */
  checkCreditMode(): void {
    this.subscriptions.push(this.form.get('credit_mode').valueChanges.subscribe(mode => {
      this.form.controls.amount.patchValue('');
      if (mode === 'months') {
        this.setSelectedControl({ monthAmount: true, manualAmount: false, addControl: 'months' });
        this.calculateMonthlyFeeCredit();
      }

      if (mode === 'manual') {
        this.setSelectedControl({ monthAmount: false, manualAmount: true, removeControl: 'months' });
        this.disabled = false;
      }
    }));
  }

  /**
   * calculateMonthlyFeeCredit
   * Get the client monthly fee and get the total for the number of months selected.
   */
  private calculateMonthlyFeeCredit() {
    this.disabled = true;
    const monthlyFee = this.clientsService.calculateMonthlyFee(this.data);
    this.subscriptions.push(this.form.get('months').valueChanges.subscribe((month: number) => {
      const total = (monthlyFee * month).toFixed(2);
      this.form.controls.amount.patchValue(total);
    }));
  }

  /**
   * setSelectedControl
   * Modify the form that will be send to the API
   */
  private setSelectedControl(params): void {
    this.monthAmount = params.monthAmount;
    this.manualAmount = params.manualAmount;
    if (params.addControl) {
      this.form.addControl(params.addControl, new FormControl(null, Validators.required));
    }

    if (params.removeControl && this.form.get(params.removeControl)) {
      this.form.removeControl(params.removeControl);
    }
  }
}
