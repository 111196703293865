import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ChartOptions, ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';

@Component({
  selector: 'app-tickets-completitions-chart',
  templateUrl: './tickets-completitions-chart.component.html',
  styleUrls: ['./tickets-completitions-chart.component.scss']
})
export class TicketsCompletitionsChartComponent implements OnInit, OnChanges {
  @Input('data') type: any;
  tickets_completed = [];
  labels = [];
  options: ChartOptions = {
    plugins: {
      datalabels: { display: false }
    },
    scales: {
      xAxes: [{
        type: 'time',
        distribution: 'linear',
        time: {
          unit: 'month'
        }
      }],
      yAxes: [{}]
    }
  };

  ticketsCompletedChartData: Array<ChartDataSets> = [
    {
      data: this.tickets_completed,
      label: 'Tickets completados'
    }
  ];

  barChartColors: Array<Color> = [
    {
      backgroundColor: '#10416a88',
      borderColor: '#10416a'
    }
  ];

  lineChartType = 'line';

  ngOnInit(): void {
    //
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type.currentValue.ticketsCompleted.length > 0) {
      this.tickets_completed = [];
      this.ticketsCompletedChartData[0].data = changes.type.currentValue.ticketsCompleted.map((subs: any) =>
        ({ x: subs.month, y: subs.quantity }));
    }
  }

}
