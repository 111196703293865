<div class="d-block">
    <a class="font-weight-bold">{{data.client?.name}}</a>
    <br>
    <a>{{data.client.level}}</a>
    <div [ngSwitch]="data.is_active">
        <span class="badge badge-primary" *ngSwitchCase="1">Activo</span>
        <span class="badge badge-danger" *ngSwitchCase="0">Inactivo</span>
    </div>
</div>

