/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./iot-label.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./iot-label.component";
var styles_IotLabelComponent = [i0.styles];
var RenderType_IotLabelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IotLabelComponent, data: {} });
export { RenderType_IotLabelComponent as RenderType_IotLabelComponent };
export function View_IotLabelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "badge badge-", _co.statuses[_co.data[_co.options]], ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.text; _ck(_v, 1, 0, currVal_1); }); }
export function View_IotLabelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-iot-label", [], null, null, null, View_IotLabelComponent_0, RenderType_IotLabelComponent)), i1.ɵdid(1, 114688, null, 0, i2.IotLabelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IotLabelComponentNgFactory = i1.ɵccf("app-iot-label", i2.IotLabelComponent, View_IotLabelComponent_Host_0, { data: "data", options: "options" }, {}, []);
export { IotLabelComponentNgFactory as IotLabelComponentNgFactory };
