import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { CustomValidator } from '../../../components/form/validators/custom.validator';
import { FromService } from '../../../providers/form.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { ClientsService } from '../../../services/clients.service';
import { GroupsService } from '../../../services/groups.service';
import { SwalService } from '../../../services/swal.service';
import { rfcValidator } from '../../../components/form/validators/rfc-validator';
import { TaxService } from '../../../services/tax.service';
import { LeadSourcesService } from '../../../services/leadsources.service';
var InvoiceData = /** @class */ (function () {
    function InvoiceData() {
        this.invoice_data = {
            name: [],
            rfc: [],
            cfdi_use: [],
            tax_situation: [],
            address: [],
            outdoor_number: [],
            inside_number: [],
            phone: [],
            email: [],
            between_streets: [],
            colony: [],
            postal_code: [],
            state: [],
            county: []
        };
    }
    return InvoiceData;
}());
export { InvoiceData };
var ClientEditComponent = /** @class */ (function () {
    function ClientEditComponent(activeModal, broadcast, formBuilder, fromService, clientService, groupService, taxService, leadsourcesService, swal) {
        var _this = this;
        this.activeModal = activeModal;
        this.broadcast = broadcast;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.clientService = clientService;
        this.groupService = groupService;
        this.taxService = taxService;
        this.leadsourcesService = leadsourcesService;
        this.swal = swal;
        this.baseUrl = "" + environment.apiUrl;
        this.vendors = [];
        this.cfdi_uses = [];
        this.original_cfdi_uses = [];
        this.tax_situations = [];
        this.leadsources = [];
        this.regularPlans = [1, 3];
        this.groups = [];
        // extra_filter_status = [];
        this.payment_types = environment.payment_types;
        this.default_cfdi_use = 'Selecciona el uso del CFDI';
        this.default_payment_method = 'Selecciona el metodo de pago';
        this.default_tax_situation = 'Selecciona el regimen fiscal';
        this.default_leadsource = 'Seleccione el leadsource';
        this.default_extra_filter_change = 'Seleccione el status';
        this.show = false;
        this.extraFilterChange = false;
        this.copy_status = false;
        this.documents = {
            id: '',
            id_reverse: null,
            proof_of_address: null,
            profile: null
        };
        this.subscriptions = [];
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            rfc: [''],
            address: ['', Validators.required],
            outdoor_number: ['', Validators.required],
            inside_number: [''],
            phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
            extra_phones: new FormArray([]),
            email: ['', Validators.required],
            between_streets: ['', Validators.required],
            colony: ['', [Validators.required, Validators.minLength(4)]],
            postal_code: ['', Validators.required],
            state: ['', [Validators.required, Validators.minLength(4)]],
            county: ['', [Validators.required, Validators.minLength(4)]],
            id_leadsources: [''],
            preferred_payment_method: [],
            birth_date: [null],
            vendor: [''],
            id_groups: [''],
            coordinate: this.formBuilder.group({ latitude: ['', Validators.required], longitude: ['', Validators.required] }),
            images: this.formBuilder.group({
                id: [''],
                id_reverse: [''],
                proof_of_address: [''],
                profile: ['']
            }),
            extra_data: [''],
            extra_filter_change: [''],
            anual_payment: [''],
            unique_charge: ['']
        });
        this.regular_cfdi_use = ['G01', 'G02', 'G03', 'I01', 'I02', 'I03', 'I04', 'I05', 'I06', 'I07', 'I08'];
        this.special_cfdi_use = ['D01', 'D02', 'D03', 'D04', 'D05', 'D06', 'D07', 'D08', 'D09', 'D10'];
        this.regular_tax_situation = [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626];
        this.special_tax_situation = [605, 606, 608, 611, 612, 614, 607, 615, 625];
        this.common_tax_situation = this.regular_tax_situation.filter(function (i) { return _this.special_tax_situation.includes(i); });
    }
    ClientEditComponent.prototype.ngOnInit = function () {
        this.getClient();
        this.fromService.setForm(this.form);
    };
    ClientEditComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    /**
     * getClient
     * Devuelve toda la información pertinente a un cliente para setearla en el formulario
     * La información se devuelve es la siguiente:
     * Información basica (requeridas): Nombre, dirección, telefono, email, etc.
     * Opcionales:
     * Información para la facturación.
     * Información de tarjeta del cliente.
     */
    ClientEditComponent.prototype.getClient = function () {
        var _this = this;
        this.subscriptions.push(this.clientService.show(this.data.id_clients).subscribe(function (data) {
            _this.client = data.response;
            _this.extraFilterChange = _this.regularPlans.includes(_this.client.id_plan_type);
            _this.preferred_payment_methods = _this.updatePaymentTypesIds();
            _this.group = 'Selecciona el grupo'; // Temporal
            _this.getGroups();
            _this.getVendors();
            _this.getTaxSituations();
            _this.getLeadSources();
            _this.getImages(_this.client.id_clients);
            _this.setClientData(_this.client);
            _this.setDefaultPreferredPaymentMethod(_this.client.preferred_payment_method);
            _this.setMaxMinYear();
            _this.updateInvoiceDataValidators();
            _this.updateExtraPhoneNumbers();
            _this.filterCFDIUses();
        }));
    };
    /**
     * updateClient
     * Manda una petición PUT a la API unicamente con los datos modificados del formulario
     */
    ClientEditComponent.prototype.updateClient = function () {
        var _this = this;
        if (this.form.valid) {
            var params_1 = this.fromService.getDirtyValues(this.form);
            if (params_1.hasOwnProperty('invoice_data')) {
                Object.assign(params_1, { rfc: params_1.invoice_data.rfc });
            }
            var swal_params = this.swalParams(params_1);
            this.swal.warning(swal_params).then(function (result) {
                if (result.value) {
                    _this.subscriptions.push(_this.clientService.update(_this.client.id_clients, params_1).subscribe(function (data) {
                        if (data.success) {
                            _this.swal.success().then(function () {
                                _this.activeModal.dismiss('Cross click');
                                _this.broadcast.reloadDataTable();
                            });
                        }
                        else {
                            _this.swal.error();
                        }
                    }));
                }
            });
        }
    };
    ClientEditComponent.prototype.swalParams = function (params) {
        var swal_params = {
            title: '¿Esta seguro de querer actualizar los datos del cliente?'
        };
        if (params.hasOwnProperty('rfc')) {
            var rfcWarningParams = {
                text: 'Solo se añadio el RFC del cliente por lo que la factura se generara con la información de la dirección principal'
            };
            if (params.hasOwnProperty('invoice_data')) {
                rfcWarningParams = {
                    text: 'Se añadieron datos en la pestaña de dirección de facturación por lo que la factura se generara con los datos ingresados en esa pestaña'
                };
            }
            Object.assign(swal_params, rfcWarningParams);
        }
        return swal_params;
    };
    /**
     * getGroups
     * Obtiene el array de grupos actuales en el sistema
     */
    ClientEditComponent.prototype.getGroups = function () {
        var _this = this;
        var params = { start: 0, length: 50 };
        this.subscriptions.push(this.groupService.getGroups(params).subscribe(function (resp) {
            resp.data.forEach(function (group) {
                var groupObject = { id: group.id_groups, name: group.name };
                _this.groups.push(groupObject);
            });
        }));
    };
    /**
     * addExtraPhone
     * Añade los formularios para añadir numeros extra a un cliente
     * @param params name: nombre del numero extra, phone: telefono extra
     */
    ClientEditComponent.prototype.addExtraPhone = function (params) {
        if (params === void 0) { params = null; }
        var extraPhone = this.formBuilder.group({
            name: [params ? params.name : null, Validators.required],
            phone: [params ? params.phone : null, Validators.required]
        });
        if (this.extra_phones.valid) {
            this.extra_phones.push(extraPhone);
        }
    };
    /**
     * removeExtraPhone
     * Remueve el numero extra de un cliente.
     * @param index posicion del numero extra que se desea remover.
     */
    ClientEditComponent.prototype.removeExtraPhone = function (index) {
        this.extra_phones.removeAt(index);
    };
    /**
     * copyInvoiceData
     * Copia los datos necesarios del objeto cliente para la almacenar los valores en invoice_data.
     */
    ClientEditComponent.prototype.copyInvoiceData = function () {
        var _this = this;
        var skip = ['tax_situation', 'rfc', 'cfdi_use'];
        var inD = new InvoiceData();
        Object.keys(inD.invoice_data).forEach(function (key) {
            var value = _this.client[key] ? _this.client[key] : '';
            if (!skip.includes(key)) {
                _this.form.get(['invoice_data', key]).patchValue(value);
            }
        });
    };
    /**
     * getImage
     * @param type nombre de la imagen
     * @param images arreglo de las imagenes asociadas al cliente
     */
    ClientEditComponent.prototype.getImage = function (type, images) {
        var _this = this;
        var url = null;
        images.forEach(function (image) {
            if (image.indexOf(type) > -1) {
                url = _this.baseUrl + "/documents/contracts/" + image;
            }
        });
        return url;
    };
    /**
     * getImages
     * @param id_clients id del cliente
     * Obtiene del servidor las imagenes asociadas de un cliente
     */
    ClientEditComponent.prototype.getImages = function (id_clients) {
        var _this = this;
        this.subscriptions.push(this.clientService.getImages(id_clients).subscribe(function (resp) {
            _this.documents.id = _this.getImage('id.', resp.response.documents);
            _this.documents.id_reverse = _this.getImage('id_reverse.', resp.response.documents);
            _this.documents.proof_of_address = _this.getImage('proof_of_address.', resp.response.documents);
            _this.documents.profile = _this.getImage('profile.', resp.response.documents);
        }));
    };
    /**
     * getVendors
     * Obtiene los vendors asociados a un cliente
     * Si el cliente tiene un vendor predeterminado setea el nombre del vendor en el formulario
     */
    ClientEditComponent.prototype.getVendors = function () {
        var validClientVendors = [];
        var vendors = [];
        this.default_vendor = '-Selecciona el vendor-';
        this.client.valid_cards_vendors.forEach(function (cardVendorInfo) {
            if (vendors.indexOf(cardVendorInfo.id_vendor) === -1) {
                vendors.push(cardVendorInfo.vendor.id_vendor);
                validClientVendors.push({ id: cardVendorInfo.vendor.id_vendor, name: cardVendorInfo.vendor.name });
            }
        });
        if (this.client.default_vendor !== null) {
            this.default_vendor = this.client.default_vendor.vendor.name;
        }
        this.vendors = validClientVendors;
    };
    /**
     * getTaxSituations
     * Obtiene y formatea la lista de situaciones fiscales devuelta por la API
     */
    ClientEditComponent.prototype.getTaxSituations = function () {
        var _this = this;
        this.subscriptions.push(this.taxService.getTaxData().subscribe(function (resp) {
            if (resp.length > 0) {
                resp.forEach(function (element) {
                    if (element.success && element.response.length > 0) {
                        switch (element.message) {
                            case 'situations':
                                _this.tax_situations = element.response.map(function (tax) {
                                    var formated_taxes = {};
                                    Object.assign(formated_taxes, { id: tax.code, name: tax.code + " - " + tax.description });
                                    return formated_taxes;
                                });
                                break;
                            case 'cfdi':
                                var cfdi_uses = element.response.map(function (cfdi) {
                                    var formated_cfdis = {};
                                    Object.assign(formated_cfdis, { id: cfdi.clave, name: cfdi.clave + " - " + cfdi.uso });
                                    return formated_cfdis;
                                });
                                _this.cfdi_uses = cfdi_uses.slice();
                                _this.original_cfdi_uses = cfdi_uses.slice();
                                break;
                        }
                    }
                });
            }
            else {
                _this.swal.error({ text: 'Ocurrio un error al obtener los datos de facturación' });
            }
        }));
    };
    /**
     * filterCFDIUse
     * Filtra los uso de CFDI que se pueden aplicar a una factura dependiendo
     * de la situacion fiscal seleccionada.
     */
    ClientEditComponent.prototype.filterCFDIUses = function () {
        var _this = this;
        this.subscriptions.push(this.form.get('invoice_data.tax_situation').valueChanges.subscribe(function (tax_situation) {
            _this.cfdi_uses = _this.original_cfdi_uses;
            if (!_this.common_tax_situation.includes(tax_situation)) {
                _this.cfdi_uses = _this.regular_tax_situation.includes(Number(tax_situation)) ?
                    _this.cfdi_uses.filter(function (cfdi_uses) { return _this.regular_cfdi_use.includes(cfdi_uses.id); }) :
                    _this.cfdi_uses.filter(function (cfdi_uses) { return _this.special_cfdi_use.includes(cfdi_uses.id); });
            }
        }));
    };
    /**
     * getLeadSources
     * Setea los leadsources
     */
    ClientEditComponent.prototype.getLeadSources = function () {
        var _this = this;
        this.subscriptions.push(this.leadsourcesService.index({ status: 'all' }).subscribe(function (resp) {
            if (resp.response.length > 0) {
                _this.leadsources = resp.response.map(function (leadsource) {
                    var formatedLeadsources = {};
                    var status = ['Inactivo', 'Activo'];
                    Object.assign(formatedLeadsources, { id: leadsource.id_leadsources, name: leadsource.name + " - " + status[leadsource.status] });
                    return formatedLeadsources;
                });
            }
            else {
                _this.swal.error({ text: 'Ocurrio un error al obtener los leadsources' });
            }
        }));
    };
    /**
     * setClientData
     * @param client información del cliente
     * Setea la información del cliente obtenida del request.
     */
    ClientEditComponent.prototype.setClientData = function (client) {
        var _this = this;
        var specialFields = ['cards', 'invoice_data', 'coordinate.latitude', 'coordinate.longitude', 'extra_phones'];
        if (client.coordinate === '' || client.coordinate === null) {
            client.coordinate = { latitude: '', longitude: '' };
        }
        Object.keys(client).forEach(function (key) {
            if (specialFields.indexOf(key) === -1 && _this.form.controls[key]) {
                _this.form.controls[key].setValue(client[key]);
            }
        });
        var grp = [];
        var inD = new InvoiceData();
        var fieldsToUpper = ['name', 'rfc'];
        Object.keys(inD.invoice_data).forEach(function (key) {
            var value = (client.invoice_data !== null && key in client.invoice_data) ? client.invoice_data[key] : '';
            if (fieldsToUpper.includes(key)) {
                value.toUpperCase();
            }
            grp[key] = [value.trim(), inD.invoice_data[key]];
        });
        this.form.addControl('invoice_data', this.formBuilder.group(grp));
        if (client.extra_phones) {
            var extraPhones = JSON.parse(client.extra_phones);
            if (extraPhones.length > 0) {
                extraPhones.forEach(function (extraPhone) {
                    _this.addExtraPhone(extraPhone);
                });
            }
        }
    };
    /**
     * updatePaymentTypesIds
     * Transforma los datos almacendos en payment_types para generar un nuevo ARRAY
     * @returns ARRAY con el formato aceptado por el INPUT SELECT
     */
    ClientEditComponent.prototype.updatePaymentTypesIds = function () {
        var updatedArray = this.payment_types.map(function (element) {
            var id_payment_type;
            switch (element.id) {
                case 'CARD':
                    id_payment_type = 1;
                    break;
                case 'OXXO':
                    id_payment_type = 2;
                    break;
                case 'SPEI':
                    id_payment_type = 3;
                    break;
            }
            return tslib_1.__assign({}, element, { id: id_payment_type });
        });
        return updatedArray;
    };
    /**
     * setDefaultPreferredPaymentMethod
     * Setea el metodo de pago por defecto del cliente
     */
    ClientEditComponent.prototype.setDefaultPreferredPaymentMethod = function (preferred_payment_method) {
        switch (preferred_payment_method) {
            case 2:
                this.default_payment_method = 'OXXO';
                break;
            case 3:
                this.default_payment_method = 'SPEI';
                break;
            default:
                this.default_payment_method = 'Tarjeta';
                break;
        }
    };
    /**
     * updateInvoiceDataValidators
     * Actualiza los validadores del subform invoice data para marcarlos como requeridos en caso
     * de que alguno de los siguientes campos sea distinto de null
     */
    ClientEditComponent.prototype.updateInvoiceDataValidators = function () {
        var _this = this;
        var invoiceForm = this.form.get('invoice_data');
        var skip = ['phone', 'inside_number'];
        var inD = new InvoiceData();
        this.subscriptions.push(invoiceForm.valueChanges.subscribe(function (invoice_data) {
            if (invoice_data.name !== '' || invoice_data.name !== null || invoice_data.email !== '' || invoice_data.email !== null) {
                Object.keys(inD.invoice_data).forEach(function (key) {
                    if (!skip.includes(key)) {
                        if (key === 'rfc') {
                            invoiceForm.get(key).setValidators([
                                Validators.required, Validators.minLength(12), Validators.maxLength(13), CustomValidator.noSpace, rfcValidator()
                            ]);
                        }
                        else {
                            invoiceForm.get(key).setValidators([Validators.required]);
                        }
                    }
                });
            }
            Object.keys(inD.invoice_data).forEach(function (key) {
                if (!skip.includes(key)) {
                    invoiceForm.get(key).updateValueAndValidity({ onlySelf: true });
                }
            });
            _this.show = true;
        }));
    };
    ClientEditComponent.prototype.updateExtraPhoneNumbers = function () {
        this.subscriptions.push(this.form.get('extra_phones').valueChanges.subscribe(function (extraPhone) {
            // console.log(extraPhone);
        }));
    };
    /**
     * Setea la variable max_year y min_year para determinar, los rangos de años que se podran asignar
     * al campo de fecha de nacimiento
     */
    ClientEditComponent.prototype.setMaxMinYear = function () {
        var current_date = new Date();
        this.min_year = current_date.getFullYear() - 100;
        this.max_year = current_date.getFullYear() - 18;
    };
    Object.defineProperty(ClientEditComponent.prototype, "extra_phones", {
        get: function () { return this.form.get('extra_phones'); },
        enumerable: true,
        configurable: true
    });
    return ClientEditComponent;
}());
export { ClientEditComponent };
