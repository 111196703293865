import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ClientsService } from '../../../services/clients.service';

@Component({
  selector: 'app-client-history',
  templateUrl: './client-history.component.html',
  styleUrls: ['./client-history.component.scss']
})
export class ClientHistoryComponent implements OnInit, OnDestroy {
  @Input() data: any;
  historyConfig = {
    config: {
      type: 'client',
      base: this.clientService,
      api: 'getHistory',
      params: { history: 0 },
      order: [[0, 'desc']]
    },
    rows: [
      {
        display: 'Estatus',
        field: 'id'
      }
    ]
  };
  history = [];
  history_log = [];
  history_price_change_log = [];
  subscriptions: Array<Subscription> = [];

  constructor(
    private readonly clientService: ClientsService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    const params = {
      id_clients: this.data.id_clients,
      id_users: this.data.id_users
    };

    this.getHistory(params);
    this.getLogs(params);
    this.getPriceChangeLog(params);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * getHistory
   * Obtiene el historial de invalidaciones, correcciones, rechazos, cancelaciones y notificaciones del cliente
   * @param params ids para identificar al cliente.
   */
  getHistory(params): void {
    this.subscriptions.push(this.clientService.getHistory(params).subscribe((data: any) => {
      data.forEach((element, index) => {
        let type = '';
        switch (index) {
          case 0: type = 'corrections'; break;
          case 1: type = 'invalidations'; break;
          case 2: type = 'rejections'; break;
          case 3: type = 'cancellations'; break;
          case 4: type = 'notifications'; break;
        }
        element.response.forEach(itm => {
          if (itm.type === 1 || itm.type === 2) {
            itm.type = 'notifications';
          }

          this.history.push({ type, ...itm });
        });
        this.sortData(this.history, null);
      });
    }));
  }

  /**
   * getLogs
   * Obtiene el historial de cambios realizados al cliente y a su usuario asociado
   * @param params ids para identificar al cliente. 
   */
  getLogs(params): void {
    this.subscriptions.push(this.clientService.getLogs(params).subscribe((data: any) => {
      const type = 'client_log';
      data.response.forEach(entry => {
        const element = this.filterElements(entry);
        if (element !== undefined) {
          this.history_log.push({ type, element });
        }
        this.sortData(this.history_log, 'client_log');
      });
    }));
  }

  /**
   * getPriceChangeLog
   * Obtiene el historial de cambios de mensualidades efectuadas a un cliente
   * @param params ids para identificar al cliente.
   */
  getPriceChangeLog(params): void {
    this.subscriptions.push(this.clientService.getPriceHistory(params).subscribe((data: any) => {
      const type = 'client_log_prices';
      if (data.response) {
        data.response.forEach(element => {
          this.history_price_change_log.push({ type, element });
        });
        this.sortData(this.history_price_change_log, 'client_log_prices');
      }
    }));
  }

  /**
   * filterElements
   * Evalua y filtra los campos before_data y after_data relacionados al cliente, esta información se devuelve de la tabla logs.
   * @param element log individual de la tabla logs
   * @returns un booleano o el elemento en caso de que haya diferencia
   */
  filterElements(element): Object {
    const before_data = JSON.parse(element.before_data);
    const after_data = JSON.parse(element.after_data);
    let diff = false;

    Object.entries(after_data).forEach(entry => {

      const [key, after_data_val] = entry;
      const before_data_val = before_data[key];

      if (after_data_val !== before_data_val) {
        diff = true;
      }
    });

    if (diff && element !== undefined) {
      return element;
    }
  }

  sortData(order_value, type): Array<any> {
    if (type !== null) {
      return order_value.sort((a, b) =>
        (new Date(b.element.created_at) as any) - (new Date(a.element.created_at) as any));
    }

    return order_value.sort((a, b) =>
      (new Date(b.created_at) as any) - (new Date(a.created_at) as any));
  }
}
