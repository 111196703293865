import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private readonly baseUrl = `${environment.apiUrl}`;

  constructor(
    private readonly permissionsService: NgxPermissionsService,
    private readonly http: HttpClient
  ) { }

  fetchPermissions(params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/permissions/get_permissions`, params);
  }

  setPermissions(permissions: Array<string>): void {
    if (permissions) {
      this.permissionsService.flushPermissions();
      this.permissionsService.loadPermissions(permissions);
    }
  }

  getPermissions(): any {
    return this.permissionsService.getPermissions();
  }

  hasPermission(permission: string): any {
    return this.permissionsService.getPermission(permission);
  }

  filterTableConfig(configuration: any): any {
    const user = JSON.parse(localStorage.getItem('user'));
    const finalConfig = configuration;
    // Filter actions columns based on user's permissions
    if (!configuration.columns) {
      return configuration;
    }

    const actionColumns = configuration.columns.filter(
      column => (!column.onlyForTeam ||
        (column.onlyForTeam && column.onlyForTeam.includes(user.team_id))) &&
        (column.type === 'actions' || column.type === 'inline-button'));
    const commonColumns = configuration.columns.filter(
      column => (!column.onlyForTeam ||
        (column.onlyForTeam && column.onlyForTeam.includes(user.team_id)))
        && (!['actions', 'inline-button'].includes(column.type)));
    const finalActionColumns: any = { ...actionColumns[0] };
    finalActionColumns.options = [];
    actionColumns.map(column => {
      column.options.map((option: any) => {
        if (Array.isArray(option)) {
          const _arrayOptions = [];
          option.map(action => {
            if (!action.onlyForTeam || (action.onlyForTeam && action.onlyForTeam.includes(user.team_id))) {
              if (action.permissions && action.permissions.length) {
                for (let i = 0; i < action.permissions.length; i++) {
                  if (this.hasPermission(action.permissions[i])) {
                    _arrayOptions.push(action);
                    break;
                  }
                }
              } else {
                _arrayOptions.push(action);
              }
            }
          });
          finalActionColumns.options.push(_arrayOptions);
        } else {
          if (!option.onlyForTeam || (option.onlyForTeam && option.onlyForTeam.includes(user.team_id))) {
            if (option.permissions && option.permissions.length) {
              for (let i = 0; i < option.permissions.length; i++) {
                if (this.hasPermission(option.permissions[i])) {
                  finalActionColumns.options.push(option);
                  break;
                }
              }
            } else {
              finalActionColumns.options.push(option);
            }
          }
        }
      });
    });
    if (finalActionColumns.options.length > 0) {
      commonColumns.push(finalActionColumns);
    }
    // commonColumns.push(finalActionColumns);
    finalConfig.columns = commonColumns;

    return finalConfig;
  }
}
