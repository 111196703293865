import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { SwalService } from '../../../../services/swal.service';
import { GroupsService } from '../../../../services/groups.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedComponent } from '../../../../model/shared-component';
import { PermissionsService } from '../../../../services/permissions.service';
import { CreateEditPlanComponent } from './create-edit-plan/create-edit-plan.component';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit, OnDestroy {
  @Input() data: any;
  dataTableConfig: any;
  subscriptions: Array<Subscription> = [];
  private readonly baseDataTableConfig = {
    config: {
      base: this.groupsService,
      api: 'getGroups'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Promoción',
        field: 'promotion',
        type: 'text'
      },
      {
        display: 'Despósito',
        field: 'deposit',
        type: 'text'
      },
      {
        display: 'Cuota mensual',
        field: 'monthly_fee',
        type: 'text'
      },
      {
        display: 'Tipo de plan',
        field: 'type_desc',
        type: 'text'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Editar',
            icon: 'fa fa-pencil-square-o fa-fw',
            event: 'plan.edit',
            conditionality: true,
            permissions: ['update-groups']
          },
          {
            display: 'Eliminar',
            icon: 'fa fa-trash fa-fw',
            event: 'plan.delete',
            conditionality: true,
            permissions: ['delete-groups']
          }
        ]
      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly groupsService: GroupsService,
    private readonly broadcast: BroadcastService,
    private readonly appModal: ModalComponent,
    private readonly swal: SwalService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'plan.edit': this.editPlanItem(event.data); break;
        case 'plan.delete': this.deletePlanItem(event.data); break;
      }
    }));
    if (this.data.parent) {
      // tslint:disable-next-line: no-string-literal
      this.baseDataTableConfig.config['params'] = { parent: this.data.parent };
    }
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  createPlanItem(): void {
    const props: SharedComponent = new SharedComponent(
      CreateEditPlanComponent,
      {
        status: 'create',
        parent: this.data.parent
      },
      {
        title: 'Añadir plan'
      }
    );
    this.appModal.openXl(props);
  }

  private editPlanItem(data): void {
    const props: SharedComponent = new SharedComponent(
      CreateEditPlanComponent,
      {
        status: 'edit',
        editData: data
      },
      {
        title: 'Editar plan'
      }
    );
    this.appModal.openXl(props);
  }

  private deletePlanItem(data): void {
    const swalParams = {
      title: `¿Estás seguro de querer eliminar el plan ${data.name}?`
    };

    this.swal.warning(swalParams).then(result => {
      if (result.value) {
        this.subscriptions.push(this.groupsService.delete(data.id_groups).subscribe((resp: any) => {
          if (resp.success) {
            this.swal.success({ title: 'Plan eliminado exitosamente' }).then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurrio un error al intentar borrar el plan' });
          }
        }));
      }
    });
  }

}
