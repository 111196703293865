import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-expire',
  templateUrl: './card-expire.component.html',
  styleUrls: ['./card-expire.component.scss']
})
export class CardExpireComponent implements OnInit {
  @Input() data: any;
  year: number;
  month: number;
  diffInDays: number;
  valid: number;
  forexpire: number;
  expired: number;
  no_cards: number;
  date_exp: string;
  card_exp: any;
  date = new Date();

  ngOnInit(): void {
    this.expired = 0;
    this.forexpire = 0;
    this.valid = 0;
    this.no_cards = 0;

    if (this.data.cards.length > 0) {

      for (const item of this.data.cards) {
        this.year = item.exp_year;
        this.month = item.exp_month;
        this.date_exp = `20${this.year}/${this.month}/01`;

        this.card_exp = new Date(this.date_exp);

        const diff = Math.floor(this.card_exp.getTime() - this.date.getTime());
        const day = 1000 * 60 * 60 * 24;

        const days = Math.floor(diff / day);
        const months = Math.floor(days / 31);

        if (months < 0) {
          this.expired = this.expired + 1;
        } else if (months > 3) {
          this.valid++;
        } else {
          this.forexpire = this.forexpire + 1;
        }

      }
    } else {
      this.no_cards = 1;
    }
  }
}
