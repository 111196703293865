import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { environment } from '../../../../../environments/environment';
// Services
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { RolesService } from '../../../../services/roles.service';
import { SwalService } from '../../../../services/swal.service';
import { TeamsService } from '../../../../services/teams.service';
import { UsersService } from '../../../../services/users.service';

@Component({
  selector: 'app-user-create-edit',
  templateUrl: './user-create-edit.component.html',
  styleUrls: ['./user-create-edit.component.scss']
})
export class UserCreateEditComponent implements OnInit, OnDestroy {
  @Input() data: any;
  admin: any;
  baseUrl = `${environment.apiUrl}`;
  image_profile: any;
  roles: any;
  change_team = false;
  teams = [];
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    image64: [''],
    phone: [''],
    email: ['', Validators.required],
    password: ['', [Validators.required, Validators.minLength(6)]],
    password_confirm: ['', [Validators.required, Validators.minLength(6)]],
    contractor: [''],
    status: [''],
    type: [''],
    roles: [[]]
  });

  dataTableConfig = {
    config: {
      base: this.rolesService,
      api: 'getRoles'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: '',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-success',
            name: 'Seleccionar',
            event: 'notify.role',
            conditionality: 'true'
          }
        ]
      }
    ]
  };

  broadcast$: Subscription;

  constructor(
    private readonly swal: SwalService,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly userService: UsersService,
    private readonly rolesService: RolesService,
    private readonly teamsService: TeamsService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.change_team = this.data.show_teams ? true : false;
    this.initBroadcast();
    this.defineViewStatus();
    this.checkPermission();
     // this.phoneValidator(this.role.role);
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    this.broadcast$.unsubscribe();
  }

  initBroadcast(): void {
    this.broadcast$ = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'notify.role': this.addRole(event.data); break;
      }
    });
  }

  /**
   * defineviewStatus
   * Si la variable status es edit obtiene y setea en el formulario las variables del user id
   */
  defineViewStatus(): void {
    if (this.data.status === 'edit') {
      this.userService.show(this.data.user.id).subscribe((data: any) => {
        this.admin = data.response || this.data.user;
        Object.keys(this.admin).forEach(key => {
          if (this.admin.hasOwnProperty(key) && !!this.form.controls[key]) {
            this.form.controls[key].setValue(this.admin[key]);
          }
        });
        this.updateValidators();

        // this.userService.getImageProfile(this.admin.id).subscribe((resp: any) => {
        //   this.image_profile = `${this.baseUrl}/profiles/${resp.response}`;
        // });
      });
    }
  }

  updateValidators(): void {
    const password = this.form.get('password');
    const password_confirm = this.form.get('password_confirm');
    this.form.get('password').clearValidators();
    this.form.get('password_confirm').clearValidators();

    this.form.get('password').valueChanges.subscribe(value => {
      if (value !== '') {
        password.setValidators([Validators.required, Validators.minLength(6)]);
        password_confirm.setValidators([Validators.required, Validators.minLength(6)]);
        // password.updateValueAndValidity({ onlySelf: true });
        // password_confirm.updateValueAndValidity({ onlySelf: true});
      }
    });
    password.updateValueAndValidity();
    password_confirm.updateValueAndValidity();
  }

  phoneValidator(role): void {
    if (role === 'Technician') {
      this.form.get('phone').setValidators([Validators.required]);
      this.form.get('phone').updateValueAndValidity();
    }
  }

  save(): void {
    if (this.data.status === 'edit') {
      this.updateUser();
    }

    if (this.data.status === 'create') {
      this.saveUser();
    }
  }

  saveUser(): void {

    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar los datos del usuario?' }).then(result => {
        if (result.value) {
          const formData = { ...this.form.value };
          const roleIds = this.form.controls.roles.value.map(rol => rol.id);
          formData.roles = roleIds;
          this.userService.createUser(formData).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.form.reset();
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            }
          });
        } else {
          this.swal.error({ title: 'Ocurió un error al crear al usuario' });
        }
      });
    }
  }

  updateUser(): void {
    if (this.form.valid && this.form.get('password').value === this.form.get('password_confirm').value) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del usuario?' }).then(result => {
        if (result.value) {
          this.userService.update(this.admin.id, this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
            }
          });
        }
      });
    }
  }

  addRole(selectedRole: any): void {
    if (!this.form.controls.roles.value.length || !this.form.controls.roles.value.find(role => role.id === selectedRole.id)) {
      this.form.controls.roles.setValue([...this.form.controls.roles.value, selectedRole]);
      this.form.markAsDirty();
    } else {
      this.swal.error({ title: 'Ya tiene este rol.', text: '' });
    }
  }

  removeRole(roleId: any): void {
    const actualRoles = this.form.controls.roles.value.filter(role => role.id !== roleId);
    this.form.controls.roles.setValue(actualRoles);
    this.form.markAsDirty();
  }

  /**
   * checkPermission
   * Revisa si el usuario logueado tiene permisos para listar los equipos de trabajo
   * en caso de que si añadira al formulario la posibilidad de seleccionar el team bajo el cual crear al usuario
   */

  checkPermission(): void {
    if (this.change_team) {
      this.getTeams();
      this.form.addControl('team_id', new FormControl('', [Validators.required]));
      this.change_team = true;
    }
  }

  /**
   * getTeams
   * Obtiene los equipos de trabajo almacenados en la BD y los asigna a la variable teams
   */

  private getTeams(): void {
    this.teamsService.index().subscribe((resp: any) => {
      if (resp.success) {
        this.teams = resp.response;
      } else {
        this.swal.error({title: 'Ocurrio un error al momento de obtener la información de los equipos de trabajo'});
      }
    });
  }

}
