import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { WithdrawalsService } from '../../../services/withdrawals.service';
import { WithdrawalCreateEditComponent } from './withdrawal-create-edit/withdrawal-create-edit.component';

@Component({
  selector: 'app-withdrawals',
  templateUrl: './withdrawals.component.html',
  styleUrls: ['./withdrawals.component.scss']
})
export class WithdrawalsComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  withdrawalsTotal = 0;
  dataTableConfig = {
    config: {
      base: this.withdrawalsService,
      api: 'getWithdrawalsTable'
    },
    columns: [
      {
        display: 'Cantidad',
        field: 'amount',
        type: 'currency'
      },
      {
        display: 'Fecha de alta',
        field: 'date',
        type: 'date'
      },
      {
        display: 'Razón',
        field: 'reason',
        type: 'text'
      }
    ]
  };

  constructor(
    public appModal: ModalComponent,
    private readonly withdrawalsService: WithdrawalsService
  ) { }

  ngOnInit(): void {
    this.getWithdrawalsTotal();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * createWithdrawal
   * Abre un modal para capturar los datos necesarios para registrar un retiro en la BD.
   */
  createWithdrawal(): void {
    const props: SharedComponent = new SharedComponent(
      WithdrawalCreateEditComponent,
      {
        status: 'create'
      },
      {
        title: 'Registrar retiro'
      }
    );
    this.appModal.opensm(props);
  }

  /**
   * getWithDrawalsTotal
   * Solicita a la API la cantidad total de los retiros hechos.
   */
  private getWithdrawalsTotal(): void {
    this.subscriptions.push(this.withdrawalsService.getWithdrawalsTotal().subscribe(resp => {
      if (resp.success) {
        this.withdrawalsTotal = resp.response;
      }
    }));
  }

}
