import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Lightbox } from 'ngx-lightbox';
import { Subscription } from 'rxjs';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { environment } from '../../../../../environments/environment';
// Services
import { BroadcastService } from '../../../../services/broadcast.service';
import { CreateEditElementComponent } from '../create-edit-element/create-edit-element.component';
import { ElementsService } from '../../../../services/elements.service';
import { PermissionsService } from '../../../../services/permissions.service';
import { SharedComponent } from '../../../../model/shared-component';
import { SwalService } from '../../../../services/swal.service';
@Component({
  selector: 'app-elements',
  templateUrl: './elements.component.html',
  styleUrls: ['./elements.component.scss']
})
export class ElementsComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  image: any;
  baseUrl = `${environment.apiUrl}`;
  dataTableConfig: any;
  private readonly baseDataTableConfig = {
    config: {
      base: this.elementService,
      api: 'getElements',
      params: {
        id_categories: 0
      }
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Precio',
        field: 'price',
        type: 'text'
      },
      {
        display: '',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-primary',
            icon: 'fa fa fa-file-image-o fa-fw',
            event: 'element.view_image',
            conditionality: true,
            permissions: ['show-elements']
          },
          {
            cssClass: 'btn btn-primary',
            icon: 'fa fa-pencil-square-o fa-fw',
            name: 'Editar',
            event: 'element.edit',
            conditionality: true,
            permissions: ['update-elements']
          },
          {
            cssClass: 'btn btn-danger',
            icon: 'fa fa-trash fa-fw',
            name: 'Borrar',
            event: 'element.delete',
            conditionality: true,
            permissions: ['delete-elements']
          }
        ]
      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly appModal: ModalComponent,
    private readonly broadcast: BroadcastService,
    private readonly elementService: ElementsService,
    private readonly permissionsService: PermissionsService,
    private readonly swal: SwalService,
    private readonly lightbox: Lightbox
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'element.edit': this.editElementItem(event.data); break;
        case 'element.delete': this.deleteElementItem(event.data); break;
        case 'element.view_image': this.viewElementImageItem(event.data); break;
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
    this.dataTableConfig.config.params.id_categories = this.data.id_categories;
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  createElementItem(): void {
    const props: SharedComponent = new SharedComponent(
      CreateEditElementComponent,
      {
        elementData: this.data,
        status: 'create'
      },
      {
        title: 'Crear elemento'
      }
    );
    this.appModal.open(props);
  }

  editElementItem(data): void {
    const props: SharedComponent = new SharedComponent(
      CreateEditElementComponent,
      {
        elementData: data,
        status: 'edit'
      },
      {
        title: `Edición del elemento ${data.name}`
      }
    );
    this.appModal.open(props);
  }

  deleteElementItem(data): void {
    const swalParams = {
      title: `¿Estás seguro de querer eliminar el elemento ${data.name}?`
    };
    this.swal.warning(swalParams).then(result => {
      if (result.value) {
        this.subscriptions.push(this.elementService.delete(data.id_categories_elements).subscribe((resp: any) => {
          if (resp.success) {
            this.swal.success({ title: 'Elemento eliminado exitosamente' }).then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurrio un error al intentar borrar el elemento' });
          }
        }));
      }
    });
  }

  viewElementImageItem(data): void {
    this.subscriptions.push(this.elementService.getImage(data.id_categories_elements).subscribe((resp: any) => {
      if (resp.response !== null) {
        this.image = [{ src: `${this.baseUrl}/extras/${resp.response}` }];
        this.lightbox.open(this.image, 0);
      } else {
        this.swal.error({ text: 'No se encontro ninguna imagen' });
      }
    }));
  }
}
