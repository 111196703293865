import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokensService } from './tokens.service';
import { VendorsService } from './vendors.service';
declare var Conekta: any;
declare var OpenPay: any;

interface CardData { months: Array<any>; years: Array<any>; }

@Injectable({
    providedIn: 'root'
})

export class CardsService {
    months = [];
    years = [];
    vendors: any;
    baseUrl = `${environment.apiUrl}`;
    constructor(
        private readonly http: HttpClient,
        private readonly tokensService: TokensService,
        private readonly vendorsService: VendorsService
    ) { }

    /**
     * getCards
     * Petición POST que solicita la información para la tabla de las tarjetas asociadas
     * a un cliente
     * @param params parametros para el filtrado de la tabla
     * @param filters opcional: parametros que se pueden enviar si se desea hacer un filtrado mas detallado de la tabla.
     * @returns json con la información necesaria para la tabla
     */
    getCards(params, filters?): Observable<any> {
        let id = 0;
        if (filters) {
            id = filters.id_clients;
        }

        return this.http.post(`${this.baseUrl}/client/${id}/getCards`, params);
    }

    /**
     * setAsDefault
     * Envia una petición POST para actualizar la tarjeta predeterminada bajo la cual se intentara
     * hacer primero los cargos
     * @param params objeto que incluye el id de la tarjeta y del cliente
     * @returns 200 | 404
     */
    setAsDefault(params): Observable<any> {
        return this.http.post(`${this.baseUrl}/clients/${params.id_client}/cards/${params.id_card}/setAsDefault`, params);
    }

    /**
     * create
     * Envia una petición POST para registrar una nueva tarjeta en cada uno de los vendors activos.
     * @param id_client int identificador del cliente
     * @param params Object que incluye los tokens de la tarjeta
     * @returns 200 | 404
     */
    create(id_client, params): Observable<any> {
        return this.http.post(`${this.baseUrl}/clients/${id_client}/cards`, params);
    }

    /**
     * delete
     * Envia una petición DELETE para borrar una tarjeta, si el cliente tiene solo una tarjeta 
     * y su status es aceptado la tarjeta no se podra borrar
     * @param params objeto que incluye el id de la tarjeta y del cliente
     * @returns 200 | 404
     */
    delete(params): Observable<any> {
        return this.http.delete(`${this.baseUrl}/clients/${params.id_client}/cards/${params.id_card}`, params);
    }

    /**
     * setVendors
     * Inicializa las credenciales de los procesadores de pago.
     */
    setVendors(): void {
        Conekta.setPublishableKey(environment.apis.conekta);
        OpenPay.setId(environment.apis.openpay.merchant);
        OpenPay.setApiKey(environment.apis.openpay.key);
        OpenPay.setSandboxMode(!environment.apis.openpay.production);
        this.vendorsService.getAll().subscribe((resp: any) => {
            this.vendors = resp.response;
        });
    }

    /**
     * cardValidation
     * Valida con el plugin de Conekta si la tarjeta es valida
     * @param data datos del formulario de la tarjeta.
     * @returns string con el error, boolean false si no se encontro ningun error
     */
    cardValidation(data): String | boolean {
        if (!Conekta.card.validateNumber(data.card_number)) { return 'Número de tarjeta invalido'; }
        if (!Conekta.card.validateCVC(data.cvc)) { return 'CVC incorrecto'; }
        if (!Conekta.card.validateExpirationDate(data.expiration_month, data.expiration_year)) { return 'Fecha de vencimiento invalida'; }

        return false;
    }

    /**
     * setDateCardInfo
     * Setea en los array de month y year los valores a mostrar para registrar tarjetas
     */
    setDateCardInfo(): CardData {
        const date = new Date();
        for (let i = 0; i < 10; i++) {
            this.years.push({ id: (date.getUTCFullYear() + i).toString(), name: (date.getUTCFullYear() + i).toString() });
        }

        for (let i = 1; i < 13; i++) {
            const str = `${i}`;
            const pad = '00';
            const str_pad = pad.substring(0, pad.length - str.length) + str;
            this.months.push({ id: str_pad, name: str_pad });
        }

        return { months: this.months, years: this.years };
    }

    /**
     * getTokens
     * retorna los tokens generados para la tarjeta.
     * @param personalData informacion del cliente.
     * @param paymentData informacion basica de la tarjeta.
     * @returns objeto con los tokens de la tarjeta para cada vendor.
     */
    getTokens(personalData, paymentData): any {
        return forkJoin(...this.vendors.map(vendor => this.createTokens(vendor, personalData, paymentData)));
    }

    /**
     * createTokens
     * Envia la información necesaria a cada uno de los vendors para generar el token para registrar las tarjeta en cada uno de los
     * procesadores de pago
     * @param vendor procesador de pagos a los cuales se enviara la petición de generar el token de la tarjeta
     * @param personalData información del cliente
     * @param paymentData informacion basica de la tarjeta.
     * @returns objeto con los tokens de cada uno de los vendors
     */
    private async createTokens(vendor, personalData, paymentData): Promise<any> {
        switch (vendor.name.toUpperCase()) {
            case 'CONEKTA':
                return this.tokensService.conekta(vendor.name, this.createDataConekta(personalData, paymentData));
            case 'OPENPAY':
                return this.tokensService.openpay(vendor.name, this.createDataOpenpay(personalData, paymentData));
            default:
                return this.tokensService.default();
        }
    }
    /**
     * createDataConetka
     * Genera un objeto con la información necesaria para poder tokenizar los datos de la tarjeta.
     * @param personalData información del cliente
     * @param paymentData información basica de la tarjeta.
     * @returns Objeto con los datos necesarios para Conekta
     */
    // tslint:disable-next-line: prefer-function-over-method
    private createDataConekta(personalData, paymentData): Object {
        const data = {
            name: personalData.name,
            number: paymentData.card_number,
            cvc: paymentData.cvc,
            exp_month: paymentData.expiration_month,
            exp_year: paymentData.expiration_year
        };

        return data;
    }

    /**
     * createDataOpenpay
     * Genera un objeto con la información necesaria para poder tokenizar los datos de la tarjeta.
     * @param personalData información del cliente
     * @param paymentData información basica de la tarjeta.
     * @returns Objeto con los datos necesarios para OpenpayFFFFF
     */
    // tslint:disable-next-line: prefer-function-over-method
    private createDataOpenpay(personalData, paymentData): Object {
        const data = {
            holder_name: personalData.name,
            card_number: paymentData.card_number,
            cvv2: paymentData.cvc,
            expiration_month: paymentData.expiration_month,
            expiration_year: paymentData.expiration_year.substring(2, 4),
            address: {
                line1: `${personalData.address} ${personalData.between_streets}`,
                line2: personalData.colony,
                line3: personalData.county,
                postal_code: personalData.postal_code,
                city: personalData.county,
                state: personalData.state,
                country_code: 'MX'
            }
        };

        return data;
    }
}
