/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clients-heatmap.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@agm/core/agm-core.ngfactory";
import * as i3 from "@agm/core";
import * as i4 from "./clients-heatmap.component";
import * as i5 from "../../../../services/investor-dashboard.service";
var styles_ClientsHeatmapComponent = [i0.styles];
var RenderType_ClientsHeatmapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientsHeatmapComponent, data: {} });
export { RenderType_ClientsHeatmapComponent as RenderType_ClientsHeatmapComponent };
export function View_ClientsHeatmapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "agm-map", [], [[2, "sebm-google-map-container", null]], [[null, "mapReady"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mapReady" === en)) {
        var pd_0 = (_co.onMapLoad($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_AgmMap_0, i2.RenderType_AgmMap)), i1.ɵprd(4608, null, i3.CircleManager, i3.CircleManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i3.DataLayerManager, i3.DataLayerManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i3.MarkerManager, i3.MarkerManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i3.InfoWindowManager, i3.InfoWindowManager, [i3.GoogleMapsAPIWrapper, i1.NgZone, i3.MarkerManager]), i1.ɵprd(4608, null, i3.KmlLayerManager, i3.KmlLayerManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i3.LayerManager, i3.LayerManager, [i3.GoogleMapsAPIWrapper]), i1.ɵprd(4608, null, i3.PolygonManager, i3.PolygonManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i3.PolylineManager, i3.PolylineManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(4608, null, i3.RectangleManager, i3.RectangleManager, [i3.GoogleMapsAPIWrapper, i1.NgZone]), i1.ɵprd(512, null, i3.GoogleMapsAPIWrapper, i3.GoogleMapsAPIWrapper, [i3.MapsAPILoader, i1.NgZone]), i1.ɵprd(512, null, i3.ɵa, i3.ɵa, [i3.MapsAPILoader]), i1.ɵdid(12, 770048, null, 0, i3.AgmMap, [i1.ElementRef, i3.GoogleMapsAPIWrapper, i1.PLATFORM_ID, i3.ɵa, i1.NgZone], { longitude: [0, "longitude"], latitude: [1, "latitude"], zoom: [2, "zoom"] }, { mapReady: "mapReady" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.longitude; var currVal_2 = _co.latitude; var currVal_3 = _co.zoom; _ck(_v, 12, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 0, 0, currVal_0); }); }
export function View_ClientsHeatmapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-clients-heatmap", [], null, null, null, View_ClientsHeatmapComponent_0, RenderType_ClientsHeatmapComponent)), i1.ɵdid(1, 114688, null, 0, i4.ClientsHeatmapComponent, [i5.InvestorDashboardService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientsHeatmapComponentNgFactory = i1.ɵccf("app-clients-heatmap", i4.ClientsHeatmapComponent, View_ClientsHeatmapComponent_Host_0, {}, {}, []);
export { ClientsHeatmapComponentNgFactory as ClientsHeatmapComponentNgFactory };
