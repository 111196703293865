import { Router } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import * as i0 from "@angular/core";
import * as i1 from "./authorization.service";
import * as i2 from "@angular/router";
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(auth, router) {
        this.auth = auth;
        this.router = router;
        this.isLoggedIn = false;
    }
    AuthGuardService.prototype.canActivate = function () {
        if (!this.auth.isAuthenticated()) {
            this.router.navigate(['login']);
        }
        else {
            return true;
        }
    };
    AuthGuardService.prototype.getLocalStorageUser = function () {
        this.userData = JSON.parse(localStorage.getItem('access_token'));
        this.isLoggedIn = false;
        if (this.userData) {
            this.isLoggedIn = true;
        }
        return this.isLoggedIn;
    };
    AuthGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.ɵɵinject(i1.AuthorizationService), i0.ɵɵinject(i2.Router)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
