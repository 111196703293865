/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./unrealizations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../badge/badge.component.ngfactory";
import * as i3 from "../badge/badge.component";
import * as i4 from "./unrealizations.component";
var styles_UnrealizationsComponent = [i0.styles];
var RenderType_UnrealizationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UnrealizationsComponent, data: {} });
export { RenderType_UnrealizationsComponent as RenderType_UnrealizationsComponent };
export function View_UnrealizationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "col-md-12 content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "history-ticket-badge", [], null, null, null, i2.View_BadgeComponent_0, i2.RenderType_BadgeComponent)), i1.ɵdid(2, 114688, null, 0, i3.BadgeComponent, [], { item: [0, "item"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 8, "dl", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "dt", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["C\u00F3digo de error"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "dd", [["class", "col-md-8"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", " - ", " - ", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "dt", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Razones"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "dd", [["class", "col-md-8"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item.error_code.category; var currVal_2 = _co.item.error_code.name; var currVal_3 = _co.item.error_code.code; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_co.item.reason || "Sin cargos extra"); _ck(_v, 11, 0, currVal_4); }); }
export function View_UnrealizationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "history-ticket-unrealizations", [], null, null, null, View_UnrealizationsComponent_0, RenderType_UnrealizationsComponent)), i1.ɵdid(1, 114688, null, 0, i4.UnrealizationsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UnrealizationsComponentNgFactory = i1.ɵccf("history-ticket-unrealizations", i4.UnrealizationsComponent, View_UnrealizationsComponent_Host_0, { item: "item" }, {}, []);
export { UnrealizationsComponentNgFactory as UnrealizationsComponentNgFactory };
