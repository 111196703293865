import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NotificationService = /** @class */ (function () {
    function NotificationService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    NotificationService.prototype.notifyUser = function (params) {
        return this.http.post(this.baseUrl + "/notifyUser", params);
    };
    NotificationService.prototype.notifyGroup = function (params) {
        return this.http.post(this.baseUrl + "/notifyGroup", params);
    };
    NotificationService.prototype.notifyUsersGroup = function (params) {
        return this.http.post(this.baseUrl + "/notifyUsersGroup", params);
    };
    NotificationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationService_Factory() { return new NotificationService(i0.ɵɵinject(i1.HttpClient)); }, token: NotificationService, providedIn: "root" });
    return NotificationService;
}());
export { NotificationService };
