import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {

  transform(date: string, format?: string): any {
    let finalFormat = format;
    if (!format) {
      finalFormat = 'MMMM Do, YYYY';
    }
    const myDate = moment(date);
    
    return myDate.isValid() ? myDate.format(finalFormat) : date;
  }

}

@Pipe({
  name: 'dateDiff'
})
export class DateDiffPipe implements PipeTransform {

  transform(date: string): any {
    const myDate = moment(date);
    
    return myDate.isValid() ? myDate.fromNow() : 'Fecha invalida';
  }

}
