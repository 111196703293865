import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ChargesBacksService {
  baseUrl = `${environment.apiUrl}`;

  constructor(private readonly http: HttpClient) { }

  /**
   * getChargesBacksTable
   * @param params .
   * @param filters .
   * @returns JSON
   */
  getChargesBacksTable(params, filters?): Observable<any> {
    let id = 0;
    if (filters) {
      id = filters.id_clients;
      Object.assign(params, filters);
    }

    return this.http.post(`${this.baseUrl}/chargesbacks/getChargesBacksDataTable`, params);
  }
}
