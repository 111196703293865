/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ticket-client.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./ticket-client.component";
import * as i3 from "../../../../../services/helpers.service";
var styles_TicketClientComponent = [i0.styles];
var RenderType_TicketClientComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TicketClientComponent, data: {} });
export { RenderType_TicketClientComponent as RenderType_TicketClientComponent };
export function View_TicketClientComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "d-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "d-block text-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copyToClipboard(_co.data.client.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "d-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", " - ", " - ", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "small", [["class", "d-block"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.client.name; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.data.error_code.category; var currVal_2 = _co.data.error_code.code; var currVal_3 = _co.data.error_code.name; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.data.description; _ck(_v, 8, 0, currVal_4); }); }
export function View_TicketClientComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-datatable-column-ticket-client", [], null, null, null, View_TicketClientComponent_0, RenderType_TicketClientComponent)), i1.ɵdid(1, 114688, null, 0, i2.TicketClientComponent, [i3.HelpersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TicketClientComponentNgFactory = i1.ɵccf("app-datatable-column-ticket-client", i2.TicketClientComponent, View_TicketClientComponent_Host_0, { data: "data" }, {}, []);
export { TicketClientComponentNgFactory as TicketClientComponentNgFactory };
