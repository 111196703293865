/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./charge-amount.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./charge-amount.component";
var styles_ChargeAmountComponent = [i0.styles];
var RenderType_ChargeAmountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChargeAmountComponent, data: {} });
export { RenderType_ChargeAmountComponent as RenderType_ChargeAmountComponent };
function View_ChargeAmountComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.amount)); _ck(_v, 1, 0, currVal_0); }); }
function View_ChargeAmountComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), (_co.data.refunds_total / 100))); _ck(_v, 1, 0, currVal_0); }); }
function View_ChargeAmountComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), (_co.data.debt / 100))); _ck(_v, 1, 0, currVal_0); }); }
export function View_ChargeAmountComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChargeAmountComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChargeAmountComponent_2)), i1.ɵdid(6, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ChargeAmountComponent_3)), i1.ɵdid(8, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); var currVal_1 = "amount"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "refunds_total"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "debt"; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ChargeAmountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-charge-amount", [], null, null, null, View_ChargeAmountComponent_0, RenderType_ChargeAmountComponent)), i1.ɵdid(1, 114688, null, 0, i3.ChargeAmountComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChargeAmountComponentNgFactory = i1.ɵccf("app-charge-amount", i3.ChargeAmountComponent, View_ChargeAmountComponent_Host_0, { data: "data", options: "options" }, {}, []);
export { ChargeAmountComponentNgFactory as ChargeAmountComponentNgFactory };
