/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal-body.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../directives/shared-component.directive";
import * as i3 from "./modal-body.component";
var styles_ModalBodyComponent = [i0.styles];
var RenderType_ModalBodyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalBodyComponent, data: {} });
export { RenderType_ModalBodyComponent as RenderType_ModalBodyComponent };
function View_ModalBodyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_ModalBodyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { sharedComponent: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalBodyComponent_1)), i1.ɵdid(2, 16384, [[1, 4]], 0, i2.SharedComponentDirective, [i1.ViewContainerRef], null, null)], null, null); }
export function View_ModalBodyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal-body", [], null, null, null, View_ModalBodyComponent_0, RenderType_ModalBodyComponent)), i1.ɵdid(1, 114688, null, 0, i3.ModalBodyComponent, [i1.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalBodyComponentNgFactory = i1.ɵccf("app-modal-body", i3.ModalBodyComponent, View_ModalBodyComponent_Host_0, { props: "props" }, {}, []);
export { ModalBodyComponentNgFactory as ModalBodyComponentNgFactory };
