import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

    readonly DELIMITER = '-';

    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            const date = value.split(this.DELIMITER);

            return {
                day: parseInt(date[2], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[0], 10)
            };
        }

        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {

        return date ? `${date.year}${this.DELIMITER}${date.month}${this.DELIMITER}${date.day}` : null;
    }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
    readonly months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    readonly DELIMITER = '-';

    parse(value: string): NgbDateStruct | null {
        if (value) {

            const date = value.split(this.DELIMITER);

            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }

        return null;
    }

    format(date: NgbDateStruct | null): string {
        return date ? `${date.day}${this.DELIMITER}${this.months[date.month - 1]}${this.DELIMITER}${date.year}` : '';
    }
}
