import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective } from 'ng2-charts';
import { SalesDashboardService } from '../../../../services/sales-dashboard.service';
import { SwalService } from '../../../../services/swal.service';
var SalesAgentChartComponent = /** @class */ (function () {
    function SalesAgentChartComponent(formBuilder, swal, salesDashboard) {
        this.formBuilder = formBuilder;
        this.swal = swal;
        this.salesDashboard = salesDashboard;
        this.isLoadAgentChart = new EventEmitter();
        this.isLoad = true;
        this.barChartColors = [
            { backgroundColor: '#83b941' },
            { backgroundColor: '#16a4db' },
            { backgroundColor: '#ff843c' },
            { backgroundColor: '#77FF11' },
            { backgroundColor: '#F0F0F0' }
        ];
        this.months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
        this.barChartOptions = {
            responsive: true,
            // We use these empty structures as placeholders for dynamic theming.
            scales: {
                xAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }],
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ]
            },
            plugins: {
                plugins: {
                    datalabels: {
                        color: 'black',
                        labels: {
                            title: {
                                font: {
                                    weight: 'bold'
                                }
                            },
                            value: {
                                color: 'black'
                            }
                        }
                    }
                }
            }
        };
        this.barChartLabels = [];
        this.barChartType = 'bar';
        this.barChartLegend = true;
        this.barChartPlugins = [pluginDataLabels];
        this.labelsValue = [];
        this.barChartData = [];
        this.form = this.formBuilder.group({
            from: [],
            to: [],
            agent: ''
        });
        this.agents = [];
    }
    SalesAgentChartComponent.prototype.ngOnInit = function () {
        this.fieldFrom = this.form.get('from');
        this.fieldTo = this.form.get('to');
        this.fieldAgent = this.form.get('agent');
        this.setChart();
    };
    SalesAgentChartComponent.prototype.search = function () {
        var fromSub = this.form.get('from').value;
        var toSub = this.form.get('to').value;
        var fieldAgent = this.form.get('agent').value;
        var params = {
            from: fromSub,
            to: toSub,
            agent: fieldAgent
        };
        if (params.from && params.to) {
            this.setChart(params);
        }
        else {
            this.swal.error({ title: 'Favor de introducir las fechas correctamente' });
        }
    };
    SalesAgentChartComponent.prototype.setChart = function (dateRangeParams) {
        var _this = this;
        this.isLoad = true;
        this.labelsValue = [];
        this.salesDashboard.owners().subscribe(function (res) {
            if (res.success) {
                if (_this.agents.length === 0) {
                    _this.agents.push({ id: '', name: 'Todos' });
                    for (var _i = 0, _a = res.response; _i < _a.length; _i++) {
                        var option = _a[_i];
                        _this.agents.push({ id: option.ownerId, name: option.firstName + " " + option.lastName });
                    }
                }
                for (var _b = 0, _c = res.response; _b < _c.length; _b++) {
                    var option = _c[_b];
                    _this.labelsValue.push({ label: option.firstName, value: option.ownerId });
                }
                _this.salesDashboard.salesByAgent(dateRangeParams).subscribe(function (resp) {
                    // getting labels            
                    if (resp.success) {
                        var barChartLabelData = [];
                        var keys = Object.keys(resp.response.data).sort();
                        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
                            var index = keys_1[_i];
                            if (index) {
                                var yearMonth = index.split('-');
                                var year = yearMonth[0];
                                var month = _this.months[parseInt(yearMonth[1], 10) - 1];
                                barChartLabelData.push(month + " " + year);
                            }
                            // this.barChartLabels = barChartLabelData;
                        }
                        _this.barChartLabels = barChartLabelData.slice();
                        // group by options
                        var barChartDataL = [];
                        var _loop_1 = function (labelValue) {
                            var countByData = [];
                            for (var _i = 0, keys_2 = keys; _i < keys_2.length; _i++) {
                                var index = keys_2[_i];
                                var arrayByLabel = resp.response.data[index].filter(function (contact) {
                                    if ((contact.hubspot_owner.indexOf(labelValue.value)) > -1 &&
                                        contact.lead_promocion === 'lead_ganado') {
                                        return contact;
                                    }
                                });
                                countByData.push(arrayByLabel.length);
                            }
                            var data = { data: countByData, label: labelValue.label };
                            barChartDataL.push(data);
                        };
                        for (var _a = 0, _b = _this.labelsValue; _a < _b.length; _a++) {
                            var labelValue = _b[_a];
                            _loop_1(labelValue);
                        }
                        var closeRateDataChart = {
                            data: resp.response.close_rate, label: 'Close Rate', type: 'line', datalabels: {
                                formatter: function (value, context) {
                                    return value + "%";
                                }
                            },
                            yAxisID: 'y-axis-2'
                        };
                        barChartDataL.push(closeRateDataChart);
                        _this.barChartData = barChartDataL.slice();
                        // this.barChartData = barChartDataL;
                        _this.isLoad = false;
                        _this.isLoadAgentChart.emit(true);
                    }
                    else {
                        _this.swal.error({ title: 'Error recuperando los datos para la gráfica ventas por mes, intente de nuevo' });
                    }
                });
            }
        });
    };
    SalesAgentChartComponent.prototype.showInfoGraphic = function () {
        var message = 'How many closed won based on month leads generated filtered by date range and agent. Close rate: closed won/closed lost';
        this.swal.info(message);
    };
    return SalesAgentChartComponent;
}());
export { SalesAgentChartComponent };
