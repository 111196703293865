import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from '../../../services/clients.service';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../../services/broadcast.service';
import { CommissionsService } from '../../../services/commissions.service';

@Component({
  selector: 'app-referals',
  templateUrl: './referals.component.html',
  styleUrls: ['./referals.component.scss']
})
export class ReferalsComponent implements OnInit, OnDestroy {
  @Input() data: any;

  client: any;
  info: any;
  others = {
    commissions: null,
    maxCommissions: null
  };
  dataTableConfig = {
    config: {
      base: this.clientService,
      api: 'getClients',
      params: {
        referred_by: ''
      }
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Grupo',
        field: 'signed_in',
        type: 'text'
      },
      {
        display: 'Alta',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'Nivel',
        field: 'level',
        type: 'text'
      },
      {
        display: 'Email',
        field: 'email',
        type: 'text'
      },
      {
        display: 'Estatus',
        field: 'status',
        type: 'label',
        options: {
          emptyText: 'N/A'
        }
      },
      {
        display: 'Suscripción',
        field: 'subscription_status',
        type: 'label',
        options: {
          emptyText: 'Faltante'
        }
      },
      {
        display: 'Deposito',
        field: 'deposit',
        type: 'number'
      },
      {
        display: 'Instalación',
        field: 'installation_fee',
        type: 'number'
      },
      {
        display: 'Mensualidad',
        field: 'monthly_fee',
        type: 'number'
      }
    ],
    filters: []
  };

  private clientSub$: Subscription;
  private broadcast$: Subscription;
  private commisions$: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly clientService: ClientsService,
    private readonly commissionService: CommissionsService,
    private readonly broadcast: BroadcastService) { }

  ngOnInit(): void {
    this.info = this.data;
    this.dataTableConfig.config.params.referred_by = this.data.id_clients;

    this.broadcast$ = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'client-referal-show': this.info = event.data; break;
      }
    });
    this.clientSub$ = this.clientService.getHierarchicalTree(this.data.id_clients)
      .subscribe((resp: any) => {
        this.client = resp.response;
      });
    
    this.commisions$ = this.commissionService.clientCommissions(this.data.id_clients)
      .subscribe((resp: any) => {
        this.others.commissions = resp[0].response;
        this.others.maxCommissions = resp[1].response;
      });
  }

  ngOnDestroy(): void {
    this.clientSub$.unsubscribe();
    this.broadcast$.unsubscribe();
    this.commisions$.unsubscribe();
  }

}
