export const environment = {
  production: true,
  apiUrl: 'https://www.panelaguagente.xyz/api/public',
  apis: {
    conekta: 'key_TGFAZrzeFTSi71VbMPfmCkg',
    openpay: {
      production: true,
      merchant: 'mfegpeirrk11ylcewwo3',
      key: 'pk_ea58b0e5dcd14c17800ace371f558bd3'
    }
  },
  notSupportedEvents : ['undefined-row-click', 'datatable-filter'],
  months_ahead: [
    {
      id: 0,
      name: 'Ningún mes adelantado'
    },
    {
      id: 1,
      name: 1
    },
    {
      id: 2,
      name: 2
    },
    {
      id: 3,
      name: 3
    },
    {
      id: 4,
      name: 4
    },
    {
      id: 5,
      name: 5
    },
    {
      id: 6,
      name: 6
    },
    {
      id: 7,
      name: 7
    },
    {
      id: 8,
      name: 8
    },
    {
      id: 9,
      name: 9
    },
    {
      id: 10,
      name: 10
    },
    {
      id: 11,
      name: 11
    },
    {
      id: 12,
      name: 12
    }
  ],
  payment_types: [
    {
      name: 'OXXO',
      id: 'OXXO'
    }, 
    {
      name: 'SPEI',
      id: 'SPEI'
    },
    {
      name: 'Tarjeta',
      id: 'CARD'
    }
  ],
  error_codes_categories: [
    {
      id: 'Basic Codes',
      name: 'Basic Codes'
    },
    {
      id: 'Detailed Codes',
      name: 'Detailed Codes'
    },
    {
      id: 'Other',
      name: 'Other'
    }
  ],
  payment_debt_options: [
    {
      id: 'total',
      name: 'Total'
    },
    {
      id: 'partial',
      name: 'Parcial'
    }
  ]
};
