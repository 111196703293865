import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule } from 'ngx-mask';
import { FormFieldComponent } from './form-field/form-field.component';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
  declarations: [
    FormFieldComponent
  ],
  entryComponents: [
    FormFieldComponent
  ],
  exports: [
    FormFieldComponent
  ],
  providers: [
    FormFieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskModule.forRoot()
  ]
})
export class FormModule { }
