<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <div class="row">
        <div class="col">
            <h2 class="m-0">Comisiones para agentes de ventas</h2>
        </div>
    </div>
</div>
<div class="container-fluid bg-white p-3">
    <div class="row">
        <div class="col-sm-3">
            <h4>Buscador</h4>
        </div>
        <div class="col-sm-3">
            <app-form-field type="datepicker" [options]="{path:'from', placeholder:'Desde'}"></app-form-field>
        </div>
        <div class="col-sm-3">
            <app-form-field type="datepicker" [options]="{path:'to', placeholder: 'Hasta'}"></app-form-field>
        </div>
        <div class="col-sm-3">
            <button class="btn btn-primary" type="button" (click)="search()">Buscar</button>
        </div>
    </div> 
</div>
<div class="container-fluid bg-white p-3">
    <app-datatable [options]="dataTableConfig"></app-datatable>
</div>