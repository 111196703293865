<div class="modal-body" [formGroup]="form">
    <div class="container-fluid">
        <div formGroupName="hs" class="form-group row">
            <label class="col-md-3 col-form-label font-weight-bold">Nombre</label>
            <div class="col-md-9">
                <input type="text" class="form-control" formControlName="name" tabindex="1" />
            </div>
        </div>            
        <h5>Precios propios</h5>

        <table class="table">
            <thead>
                <th></th>
                <th>Home Standard</th>
                <th>Home Premium</th>
                <th>Business Standard</th>
                <th>Business Premium</th>
            </thead>
            <tbody>
                <tr>
                    <td>Depósito</td>
                    <td formGroupName="hs">
                        <input type="number" class="form-control" formControlName="deposit" tabindex="2" />
                    </td>
                    <td formGroupName="hp">
                        <input type="number" class="form-control" formControlName="deposit" tabindex="7" />
                    </td>
                    <td formGroupName="bs">
                        <input type="number" class="form-control" formControlName="deposit" tabindex="12" />
                    </td>
                    <td formGroupName="bp">
                        <input type="number" class="form-control" formControlName="deposit" tabindex="17" />
                    </td>
                </tr>
                <tr>
                    <td>Cuota de instalación</td>
                    <td formGroupName="hs">
                        <input type="number" class="form-control" formControlName="installation_fee" tabindex="3" />
                    </td>
                    <td formGroupName="hp">
                        <input type="number" class="form-control" formControlName="installation_fee" tabindex="8" />
                    </td>
                    <td formGroupName="bs">
                        <input type="number" class="form-control" formControlName="installation_fee" tabindex="13" />
                    </td>
                    <td formGroupName="bp">
                        <input type="number" class="form-control" formControlName="installation_fee" tabindex="18" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="row">
                            <div class="col-md-8">Promoción</div>
                            <div class="col-md-4">
                                <div class="row" style="height: 100%;">
                                    <div class="col-md-12">Días</div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">a $</div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td formGroupName="hs">
                        <div class="row">
                            <div class="col-md-12">
                                <input type="number" class="form-control" formControlName="trial_days" tabindex="4" />
                            </div>
                        </div>
                        <div class="row" style="margin-top: 5px;">
                            <div class="col-md-12">
                                <input type="number" class="form-control" formControlName="trial_days_price" tabindex="5" />
                            </div>
                        </div>
                    </td>
                    <td formGroupName="hp">
                        <div class="row">
                            <div class="col-md-12">
                                <input type="number" class="form-control" formControlName="trial_days" tabindex="9" />
                            </div>
                        </div>
                        <div class="row" style="margin-top: 5px;">
                            <div class="col-md-12">
                                <input type="number" class="form-control" formControlName="trial_days_price" tabindex="10" />
                            </div>
                        </div>
                    </td>
                    <td formGroupName="bs">
                        <div class="row">
                            <div class="col-md-12">
                                <input type="number" class="form-control" formControlName="trial_days" tabindex="14" />
                            </div>
                        </div>
                        <div class="row" style="margin-top: 5px;">
                            <div class="col-md-12">
                                <input type="number" class="form-control" formControlName="trial_days_price" tabindex="15" />
                            </div>
                        </div>
                    </td>
                    <td formGroupName="bp">
                        <div class="row">
                            <div class="col-md-12">
                                <input type="number" class="form-control" formControlName="trial_days" tabindex="19" />
                            </div>
                        </div>
                        <div class="row" style="margin-top: 5px;">
                            <div class="col-md-12">
                                <input type="number" class="form-control" formControlName="trial_days_price" tabindex="20" />
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>Cuota mensual</td>
                    <td formGroupName="hs">
                        <input type="number" class="form-control" formControlName="monthly_fee" tabindex="6" />
                    </td>
                    <td formGroupName="hp">
                        <input type="number" class="form-control" formControlName="monthly_fee" tabindex="11" />
                    </td>
                    <td formGroupName="bs">
                        <input type="number" class="form-control" formControlName="monthly_fee" tabindex="16" />
                    </td>
                    <td formGroupName="bp">
                        <input type="number" class="form-control" formControlName="monthly_fee" tabindex="21" />
                    </td>
                </tr>
                <tr>
                    <td>Descripción del plan</td>
                    <td formGroupName="hs">
                        <textarea type="text" class="form-control" formControlName="description" tabindex="6"></textarea>
                    </td>
                    <td formGroupName="hp">
                        <textarea type="text" class="form-control" formControlName="description" tabindex="11"></textarea>
                    </td>
                    <td formGroupName="bs">
                        <textarea type="text" class="form-control" formControlName="description" tabindex="16"></textarea>
                    </td>
                    <td formGroupName="bp">
                        <textarea type="text" class="form-control" formControlName="description" tabindex="21"></textarea>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td>
                        <button *ngIf="activePlans.hp.status" class="btn btn-outline-danger"
                        (click)="deletePlan(activePlans.hp)">Borrar datos del plan</button>
                    </td>
                    <td>
                        <button *ngIf="activePlans.bs.status" class="btn btn-outline-danger"
                        (click)="deletePlan(activePlans.bs)">Borrar datos del plan</button>
                    </td>
                    <td>
                        <button *ngIf="activePlans.bp.status" class="btn btn-outline-danger"
                        (click)="deletePlan(activePlans.bp)">Borrar datos del plan</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <hr class="divider">

    <h5>Grupo para ventas</h5>
    <div class="row">
        <div class="col" formGroupName="hs">
            <select class="form-control" formControlName="sign_into" tabindex="23">
                <option *ngIf="this.data.status === 'create' && group_name" value="">{{ form.controls.hs.controls.name.value }}</option>
                <option *ngFor="let group of groups" [value]="group.id_groups">{{ group.name }}</option>
            </select>
        </div>
        <div class="col">
            <dl class="row">
                <dt class="col-sm-3">Nombre</dt>
                <dd class="col-sm-9">{{signIntoData.name}}</dd>
                <dt class="col-sm-3">Fecha de alta</dt>
                <dd class="col-sm-9">{{signIntoData.created_at}}</dd>
                <dt class="col-sm-3">Depósito</dt>
                <dd class="col-sm-9">{{signIntoData.deposit | currency}}</dd>
                <dt class="col-sm-3">Cuota de instalación</dt>
                <dd class="col-sm-9">{{signIntoData.installation_fee | currency}}</dd>
                <dt class="col-sm-3">Cuota mensual</dt>
                <dd class="col-sm-9">{{signIntoData.monthly_fee | currency}}</dd>
            </dl>
        </div>
    </div>
</div>

<br>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="save()">Guardar</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>
