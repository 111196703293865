/**
 * Interceptor to handle requests when token expired
 */

import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SwalService } from '../services/swal.service';

@Injectable({
  providedIn: 'root'
})

export class RefreshTokenInterceptorService implements HttpInterceptor {

  constructor(
    private readonly router: Router,
    private readonly injector: Injector,
    private readonly swalService: SwalService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token_errors = ['token_expired', 'token_invalid', 'token_error', 400, 401];
    
    return next.handle(request)
      .pipe(catchError((errorResponse: HttpErrorResponse) => {
        // Invalid token
        if (token_errors.includes(errorResponse.status) && token_errors.includes(errorResponse.error.error)) {
          if (errorResponse.url.includes('auth/refresh')) {
            // Expired token and can't be refreshed
            localStorage.removeItem('token');
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
            this.swalService.error({text: 'Sesión expirada'});
            this.router.navigate(['login']);

            return next.handle(request);
          }
          // Refresh token
          const accessToken = request.headers.get('authorization').split(' ')[1];
          const http = this.injector.get(HttpClient);

          return http.post<any>(`${environment.apiUrl}/auth/refresh`, {}, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          })
            .pipe(mergeMap(data => {
              localStorage.setItem('token', data.token);
              localStorage.setItem('access_token', data.token);
              const cloneRequest = request.clone({ setHeaders: { Authorization: `Bearer ${data.token}` } });

              return next.handle(cloneRequest);
            }));

        } 
        
        // Other http errors
        return throwError(errorResponse);
      }));
  }
}
