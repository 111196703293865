<div class="modal-body">
    <div class="container">
        <app-form-field type="input" [options]="{label:'Nombre', path: 'name'}"></app-form-field>
        <app-form-field type="input" [options]="{label:'Descripción', path: 'description'}"></app-form-field>
        <app-form-field type="inputNumber" [options]="{label:'Cantidad minima', path: 'min_amount'}"></app-form-field>
        <span *ngIf="data.status === 'create'">
            <app-form-field type="inputNumber" [options]="{label:'Stock', path: 'stock'}"></app-form-field>
            <app-form-field type="inputNumber"
                [options]="{label:'Costo unitario', path: 'unitary_cost'}"></app-form-field>
            <app-form-field type="inputNumber" [options]="{label:'Costo total', path: 'total_cost'}"></app-form-field>
        </span>
        <app-form-field type="select" [options]="{
            label: 'Categoria',
            path: 'supplies_categories_id',
            droptext: defaultSupplyCategory,
            elements: suppliesCategories
        }"></app-form-field>
        <app-form-field type="select" [options]="{
                label: 'Unidad',
                path: 'unity',
                droptext: defaultUnity,
                elements: units
        }"></app-form-field>
        <app-form-field type="switch" [options]="{
            label: 'Estatus', 
            path: 'status', 
            trueValue: 1, 
            falseValue: 0, 
            trueCaption: 'Activo',
            falseCaption: 'Inactivo'}">
        </app-form-field>
    </div>
</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="save()">Guardar</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>