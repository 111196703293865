import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  baseUrl = `${environment.apiUrl}`;

  constructor(private readonly http: HttpClient) { }
  
  notifyUser(params?): any {
    return this.http.post(`${this.baseUrl}/notifyUser`, params);
  }
  notifyGroup(params?): any {
    return this.http.post(`${this.baseUrl}/notifyGroup`, params);
  }
  notifyUsersGroup(params?): any {
    return this.http.post(`${this.baseUrl}/notifyUsersGroup`, params);
  }
}
