<h2># {{ data.id_tickets }}</h2>
<span *ngIf="data.client.installation_extras && data.client.installation_extras.length > 0" class="badge badge-info">Extras en la instalaciónn</span>
<ng-template #popContent>
    <dl *ngIf="data.client.installation_extras && data.client.installation_extras.length > 0">
        <dt>Extras</dt>
        <dd *ngFor="let extra of data.client.installation_extras">{{extra.element_name}}</dd>
    </dl>
    <dl class="dl-horizontal">
        <dt>Costo estimado</dt>
        <dd *ngIf="data.estimated_service_fee">${{data.estimated_service_fee}}</dd>
        <dd *ngIf="!data.estimated_service_fee">Campo vacío</dd>
        <dt>Razones</dt>
        <dd *ngIf="data.estimated_service_fee_reasons">{{data.estimated_service_fee_reasons}}</dd>
        <dd *ngIf="!data.estimated_service_fee_reasons">Campo vacío</dd>
        <dt>Costo final</dt>
        <dd *ngIf="data.final_service_fee">${{data.final_service_fee}}</dd>
        <dd *ngIf="!data.final_service_fee">Campo vacío</dd>
        <dt>Razones</dt>
        <dd *ngIf="data.final_service_fee_reasons">{{data.final_service_fee_reasons}}</dd>
        <dd *ngIf="!data.final_service_fee_reasons">Campo vacío</dd>
    </dl>

</ng-template>

<i class="fa fa-info-circle fa-lg text-primary pointer"
    [placement]="['right']" 
    [ngbPopover]="popContent">
</i>
<br>
<span *ngIf="data.status !== 'assigned' && text && badge" class="{{badge}}">{{text}}</span>

<div class="truck inline" *ngIf="data.status === 'assigned'">
    <span class="badge badge-warning">Asignado</span>
    <i class="fa fa-truck fa-2x fa-passing-reverse animated fa-slow"></i>
</div>