import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InvestorDashboardService {
    baseUrl = `${environment.apiUrl}`;

    constructor(private readonly http: HttpClient) { }

    intalationsPerMonth(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/installations_per_month`, {params});
    }

    growthRate(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/ytd_growth_rate`, {params});
    }

    cancellationsRate(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/ytd_cancellations_rate`, {params});
    }

    referrersRate(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/referrers_rate`, {params});
    }

    rollingGrowthRate(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/rolling-growth-rate`, {params});
    }

    annualGrowthRate(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/annual-growth-rate`, {params});
    }

    revenueRate(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/ytd-revenue-rate`, {params});
    }

    annualRevenueRate(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/annual-revenue-rate`, {params});
    }

    annualCancellationsRate(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/annual-cancellations-rate`, {params});
    }

    monthlyReferrersRate(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/monthly-referrers-rate`, {params});
    }

    churnRate(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/churn-rate`, {params});
    }

    monthlyChurnRate(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/monthly-churn-rate`, {params});
    }

    monthlyRecurringRevenue(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/monthly-recurring-revenue`, {params});
    }

    commitedMonthlyRecurringRevenue(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/commited-monthly-recurring-revenue`, {params});
    }

    customerLifetimeValue(params?): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/customer-lifetime-value`, {params});
    }

    getClientsCoordinates(): Observable<any> {
      return this.http.get(`${this.baseUrl}/investor_dashboard/clients-coordinates`);
    }
}
