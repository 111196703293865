<div *ngFor="let client of parsedClients; let i = index;">
    <ul class="tree-view">
        <li class="tree-node">
            <span class="tree-node-info" (click)="toggle(client.id_clients)">
                <span class="tree-node-expander">
                    <i class="fa fa-chevron-down" 
                        *ngIf="client.clients"
                        [ngClass]="{
                            'fa-rotate-270': activeTree.indexOf(client.id_clients) > -1
                        }"></i>
                </span>
                <span class="tree-node-text">
                    {{client.name}}
                </span>
                <span class="badge badge-light">
                    {{getLength(client?.clients)}} 
                    <i class="fa fa-users"></i>
                </span>
                <span class="badge" [ngClass]="{
                    'badge-info': ['accepted'].indexOf(client.status) > -1,
                    'badge-danger': ['rejected','canceled'].indexOf(client.status) > -1,
                    'badge-warning': ['invalid'].indexOf(client.status) > -1,
                    'badge-light': ['accepted', 'invalid', 'rejected', 'canceled'].indexOf(client.status) === -1
                  }">{{parsedStatus[client.status]}}</span>
            </span>
            <span (click)="showInfo(client)">
                <i class="fa fa-info-circle" [ngClass]="{'fa-lg text-info': activeClient === client.id_clients}"></i>
            </span>
            <div [@collapse]="activeTree.indexOf(client.id_clients) > -1">
                <app-clients-referals-treeview [clients]="client.clients" *ngIf="client.clients"></app-clients-referals-treeview>
            </div>
        </li>
    </ul>
</div>