import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color } from 'ng2-charts';

@Component({
  selector: 'app-incomes-chart',
  templateUrl: './incomes-chart.component.html',
  styleUrls: ['./incomes-chart.component.scss']
})
export class IncomesChartComponent implements OnInit, OnChanges {
  @Input('data') incomes: any;
  actual_incomes = [];
  past_incomes = [];
  labels = [];
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  month_label_custom: any;
  chartColor: Array<Color> = [
    {
      backgroundColor: '#16a4db88',
      borderColor: '#16a4db'
    },
    {
      backgroundColor: '#83b94188',
      borderColor: '#83b941'
    }
  ];

  options: ChartOptions = {
    plugins: {
      datalabels: { display: false }
    },
    scales: {
      xAxes: [{}],
      yAxes: [{
        ticks: {
          callback: ((label: any) =>
            label.toLocaleString('en')),
          beginAtZero: true,
          stepSize: 10000
        }
      }]
    },
    tooltips: {
      callbacks: {
        title: ((tooltipItems: any) => {
          this.month_label_custom = tooltipItems[0].label;
          if (tooltipItems[0].datasetIndex === 1) {
            const month = tooltipItems[0].label.slice(0, -2);
            const current_year = tooltipItems[0].label.slice(-2);
            const past_year = current_year - 1;
            const past_period = `${month} ${past_year}`;

            this.month_label_custom = past_period;
          }

          return this.month_label_custom;
        }),
        label: ((tooltipItems: any) => {
          const income = (tooltipItems.yLabel).toLocaleString('en');

          return `$ ${income}`;
        })
      }
    }
  };
  incomesChartData: Array<ChartDataSets> = [
    {
      stack: 'a',
      data: this.actual_incomes,
      label: 'Ingresos actuales'
    },
    {
      stack: 'a',
      data: this.past_incomes,
      label: 'Ingresos pasados'
    }
  ];
  lineChartType = 'line';

  ngOnInit(): void {
    //
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.incomes.currentValue.actualIncomes.length > 0) {
      this.actual_incomes = [];
      this.incomesChartData[0].data = changes.incomes.currentValue.actualIncomes.map((income: any) =>
        ({ x: income.date, y: parseFloat(income.amount).toFixed(2) }));

      this.labels = changes.incomes.currentValue.actualIncomes.map((income: any) => {
        const month = income.date.slice(5) - 1;
        const year = income.date.slice(2, 4);
        const month_label = `${this.months[month]} ${year}`;

        return month_label;
      });
    }

    if (changes.incomes.currentValue.pastIncomes.length > 0) {
      this.past_incomes = [];
      this.incomesChartData[1].data = changes.incomes.currentValue.pastIncomes.map((income: any) =>
        ({ x: income.date, y: parseFloat(income.amount).toFixed(2) }));

      if (changes.incomes.currentValue.actualIncomes.length === 0) {
        this.labels = changes.incomes.currentValue.pastIncomes.map((income: any) => {
          const month = income.date.slice(5) - 1;
          const year = income.date.slice(2, 4);
          const month_label = `${this.months[month]} ${year}`;

          return month_label;
        });
      }
    }
  }

}
