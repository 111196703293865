import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SupplyCreateEditComponent } from './supply-create-edit/supply-create-edit.component';
import { SharedComponent } from '../../../model/shared-component';
import { BroadcastService } from '../../../services/broadcast.service';
import { SuppliesService } from '../../../services/supplies.service';
import { ModalComponent } from '../../../components/modal/modal.component';
import { PermissionsService } from '../../../services/permissions.service';
import { SupplyInputCreateComponent } from './supply-input-create/supply-input-create.component';
import { SupplyRecordComponent } from '../supply-record/supply-record.component';

@Component({
  selector: 'app-supplies',
  templateUrl: './supplies.component.html',
  styleUrls: ['./supplies.component.scss']
})
export class SuppliesComponent implements OnInit, OnDestroy {
  notSupportedEvents = environment.notSupportedEvents;
  dataTableConfig: any;
  subscriptions: Array<Subscription> = [];
  private readonly baseDataTableConfig = {
    config: {
      base: this.suppliesService,
      api: 'getSuppliesDataTable'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Descripción',
        field: 'description',
        type: 'text'
      },
      {
        display: 'Stock',
        field: 'stock',
        type: 'text'
      },
      {
        display: 'Cantidad minima',
        field: 'min_amount',
        type: 'text'
      },
      {
        display: 'Estatus',
        field: 'status',
        type: 'boolean'
      },
      {
        display: 'Categoria',
        field: 'supply_category.name',
        type: 'text'
      },
      {
        display: 'Unidad',
        field: 'unity',
        type: 'text'
      },
      {
        display: 'Fecha de alta',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Registrar entrada al insumo',
            icon: 'fa fa-sign-in',
            event: 'createSupplyInput',
            conditionality: true,
            permissions: ['create-inputs-supplies']
          },
          {
            display: 'Editar',
            icon: 'fa fa-pencil',
            event: 'editSupply',
            conditionality: true,
            permissions: ['update-supplies']
          },
          {
            display: 'Ver historial',
            icon: 'fa fa-history',
            event: 'seeSupplyHistory',
            conditionality: true,
            permissions: ['list-supplies']
          }
        ]
      }
    ],
    filters: [
      {
        cssClass: 'col-md-12',
        type: 'stock',
        options: [
          {
            val: '',
            name: 'TODOS'
          },
          {
            val: 'lowStock',
            name: 'Stock bajo'
          },
          {
            val: 'noStock',
            name: 'Sin stock'
          }
        ]
      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    private readonly broadcast: BroadcastService,
    private readonly suppliesService: SuppliesService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      if (!this.notSupportedEvents.includes(event.name)) {
        if (this[event.name]) {
          this[event.name](event.data);
        }
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * createSupply
   * Abre un modal para crear de un insumo
   */
  createSupply(): void {
    const props: SharedComponent = new SharedComponent(
      SupplyCreateEditComponent,
      {
        status: 'create'
      },
      {
        title: 'Registrar el insumo'
      }
    );
    this.appModal.opensm(props);
  }

  /**
   * editSupply
   * Abre un modal que muestra la información a editar de un insumo
   */
  editSupply(data): void {
    const props: SharedComponent = new SharedComponent(
      SupplyCreateEditComponent,
      {
        editData: data,
        status: 'edit'
      },
      {
        title: `Edición del insumo ${data.name}`
      }
    );
    this.appModal.opensm(props);
  }

  /**
   * createSupplyInput
   * Abre un modal para registrar entradas a un insumo existente
   */
  createSupplyInput(data): void {
    const props: SharedComponent = new SharedComponent(
      SupplyInputCreateComponent,
      {
        status: 'create',
        id: data.id
      },
      {
        title: `Registrar nuevas entradas al insumo ${data.name}`
      }
    );
    this.appModal.opensm(props);
  }

  seeSupplyHistory(data): void {
    const props: SharedComponent = new SharedComponent(
      SupplyRecordComponent, data,
      {
        title: `Historial de entradas y salidas del insumo ${data.name}`
      }
    );
    this.appModal.openXl(props);
  }
}
