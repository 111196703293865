<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form [formGroup]="loginForm">
                  <div class="text-center">
                    <h3>Bievenido a Aguagente</h3>
                    <p>Por la gente, para la gente</p>
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                    </div>
                    <input type="email" class="form-control" formControlName="email" placeholder="Email" autocomplete="username" required>
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" formControlName="password" placeholder="Contraseña" autocomplete="current-password"
                      required>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button (click)="logIn()" type="submit" class="btn btn-primary px-4">Iniciar sesión</button>
                    </div>
                    <div class="col-6 text-right">
                      <button type="button" class="btn btn-link px-0">¿Olvidaste tu contraseña?</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>