<div class="d-block">

    <div *ngIf="data.client.address">
        <span class="d-block"><b>CALLE</b></span>
        <span class="d-block">{{ data.client.address }}</span>
    </div>

    <div *ngIf="data.client.outdoor_number">
        <span class="d-block"><i class="fa fa-home fa-fw" aria-hidden="true"></i> #{{ data.client.outdoor_number }}</span>
    </div>

    <div *ngIf="!data.client.outdoor_number">
        <span class="d-block"><i class="fa fa-home fa-fw" aria-hidden="true"></i> Sin numero</span>
    </div>

    <span class="d-block"><b>COLONIA</b></span>
    <span class="d-block">{{ data.client.colony }} {{ data.client.postal_code }}</span>
    <span class="d-block"><b>ENTRE</b></span>
    <span class="d-block">{{ data.client.between_streets }}</span>
    <br>
    <span class="d-block"><i class="fa fa-envelope fa-fw" aria-hidden="true"></i> {{ data.client.email }}</span>
    <span class="d-block"><i class="fa fa-phone fa-fw" aria-hidden="true"></i> {{ data.client.phone }}</span>

</div>
