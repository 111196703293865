import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Color } from 'ng2-charts';
import { FromService } from '../../../providers/form.service';
import { CashService } from '../../../services/cash.service';
import { InvestorDashboardService } from '../../../services/investor-dashboard.service';
import { NPSService } from '../../../services/nps.service';
import { SatisfactionService } from '../../../services/satisfaction.service';
import { SwalService } from '../../../services/swal.service';

@Component({
  selector: 'app-investor-dashboard',
  templateUrl: './investor-dashboard.component.html',
  styleUrls: ['./investor-dashboard.component.scss']
})
export class InvestorDashboardComponent implements OnInit {
  form: FormGroup = this.formBuilder.group({
    from: [],
    to: []
  });
  primaryColor: Array<Color> = [
    {
      backgroundColor: '#16a4db88',
      borderColor: '#16a4db'
    }
  ];
  // Property where to store data and configurations for Installations/month graph
  installationsPerMonthData: any = {
    title: 'Total Installations',
    chartType: 'bar',
    datasets: [],
    labels: null,
    options: {},
    description: 'Cumulative installed units on the last day of each month'
  };

  // Property where to store data and configurations for growth rate per year graph
  annualGrowthRateData: any = {
    title: 'Annual Client Growth Rate',
    chartType: 'bar',
    datasets: [],
    labels: null,
    options: {},
    description: 'Sum of one years installations divided by the sum of its previous years installations; the result multiplied by 100.'
  };

  // Property where to store data and configurations for revenue rate per year graph
  annualRevenueRateData: any = {
    title: 'Annual Revenue Growth Rate',
    chartType: 'bar',
    datasets: [],
    labels: null,
    options: {},
    description: `Sum of one years revenues divided by the sum of its previous years revenues; the result multiplied by 100. 
    All revenues exclude IVA and social responsability.`
  };

  // Property where to store data and configurations for monthly recurring revenue graph
  monthlyRecurringRevenueData: any = {
    title: 'Monthly Recurring Revenue (MRR)',
    chartType: 'line',
    datasets: [],
    labels: null,
    options: {},
    description: 'Sum of active clients\' regular monthly charges (excluding IVA).'
  };

  // Property where to store data and configurations for annual recurring revenue graph
  annualRecurringRevenueData: any = {
    title: 'Annual Recurring Revenue (ARR)',
    chartType: 'line',
    datasets: [],
    labels: null,
    options: {},
    description: 'Monthly recurring revenue (MRR) excluding IVA multiplied by 12.'
  };

  // Property where to store data and configurations for commited monthly recurring revenue graph
  commitedMonthlyRecurringRevenueData: any = {
    title: 'Commited Monthly Recurring Revenue (CMRR)',
    chartType: 'line',
    datasets: [],
    labels: null,
    options: {},
    description: `For CMRR we need to forecast the future monthly recurring revenue if we stop selling but continue having 
    cancellations at the same time. So for this graphic current month is the first point. For the second month we will deduct 
    from the first month revenue the equivalent % as the last churn rate divided by 12. Then month 3 will be month two revenue 
    minus the % calculations again, and so on go forward 12 months.`
  };

  // Property where to store data and configurations for cancellations rate per year graph
  annualCancellationsRateData: any = {
    title: 'Annual Client Cancellations Rate',
    chartType: 'bar',
    datasets: [],
    labels: null,
    options: {}
  };

  // Property where to store data and configurations for monthly referrers rate graph
  monthlyReferrersRateData: any = {
    title: 'Clients that have referred someone',
    chartType: 'line',
    datasets: [],
    labels: null,
    options: {},
    description: '% of active clients that have successfully referred at least one client.'
  };

  // Property where to store data and configurations for monthly referrers rate graph
  monthlyChurnRateData: any = {
    title: 'Rolling annual churn rate',
    chartType: 'line',
    datasets: [],
    labels: null,
    options: {},
    description: `Total of cancellations since last year same period until current period, 
    divided by total of installations last year same period. That result multiplied by 100.`
  };

  // Property where to store data and configurations for customer lifetime value graph
  customerLifetimeValueData: any = {
    title: 'Customer Lifetime Value (CLV)',
    chartType: 'line',
    datasets: [],
    labels: null,
    options: {},
    description: `First we obtain expected life of an average client in months: 100/Churn Rate x 12. Then we calculate the average monthly fee we charge our clients wihtout IVA: 
    Total monthly charges / Number of clients. Then we multiply the two results together to obtain the CLV.`
  };

  // Property where to store data and configurations for last 12 months cash graph
  cashData: any = {
    title: 'Cash',
    chartType: 'bar',
    datasets: [],
    labels: null,
    options: {},
    description: 'Bank balance at the end of each month.'
  };

  // Property where to store data for growth rate kpi
  growthRateData: any = {
    title: 'Growth',
    value: '',
    description: 'YTD growth rate of installations'
  };

  // Property where to store data for cancellations rate kpi
  cancellationsRateData: any = {
    title: 'YTD Client Cancellations Rate',
    value: ''
  };

  // Property where to store data for referrers rate kpi
  referresRateData: any = {
    title: 'Clients that have referred someone',
    value: ''
  };

  // Property where to store data for referrers rate kpi
  revenueRateData: any = {
    title: 'YTD Revenue Growth Rate',
    value: ''
  };

  // Property where to store data for rolling growth rate kpi
  rollingGrowRateData: any = {
    title: 'Rolling growth rate',
    value: ''
  };

  // Property where to store data for churn growth rate kpi
  churnRateData: any = {
    title: 'Churn',
    value: '',
    description: 'Rolling annual churn rate'
  };

  // Property where to store data for customer satisfaction kpi
  customerSatisfactionData: any = {
    title: 'Satisfaction',
    value: '0%',
    description: 'from customer survey taken ...'
  };

  // Property where to store data for net promoter score kpi
  netPromoterScoreData: any = {
    title: 'NPS',
    value: '0%',
    description: 'Net Promoter Score from customer survey taken ...'
  };

  constructor(
    private readonly cashService: CashService,
    private readonly satisfactionService: SatisfactionService,
    private readonly npsService: NPSService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly investorDashboardService: InvestorDashboardService,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    this.fromService.setForm(this.form);
    this.getAllData();

  }

  getAllData(): void {
    // Getting date range and construct request params
    const reqParams: any = {};
    const dateRange = this.form.value;
    if (dateRange.from && dateRange.to) {
      reqParams.from = dateRange.from;
      reqParams.to = dateRange.to;
      // reqParams.from = `${dateRange.from.year}-${dateRange.from.month}-${dateRange.from.day}`;
      // reqParams.to = `${dateRange.to.year}-${dateRange.to.month}-${dateRange.to.day}`;
    }

    // Graphs
    this.getMonthlyChurnRate(reqParams);
    this.getInstallationsPerMonth(reqParams);
    this.getAnnualGrowthRate(reqParams);
    this.getAnnualRevenueRate(reqParams);
    this.getCash(reqParams);
    this.getCommitedMonthlyRecurringRevenue(reqParams);
    this.getCustomerLifetimeValue(reqParams);
    // this.getAnnualCancellationsRate(reqParams);
    this.getMonthlyReferrersRate(reqParams);
    this.getRecurringRevenue(reqParams);

    // KPIs
    this.getChurnRate(reqParams);
    this.getGrowthRate(reqParams);
    this.getCustomerSatisfactionData();
    this.getNPSData();
    // this.getCancellationsRate(reqParams);
    // this.getReferrersRate(reqParams);
    // this.getRollingGrowthRate(reqParams);
    // this.getRevenueRate(reqParams);
  }

  showInfo(): void {
    this.swal.info('This is a heat map showing where our clients are located', null, 'Client heat map');
  }

  /**
   * Method to fetch installations per month in current year and update
   * the installationsPerMonthData property
   */
  // tslint:disable-next-line: typedef
  private async getInstallationsPerMonth(reqParams) {
    this.installationsPerMonthData.options = {
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    };
    let labels: Array<string> = [];
    let values: Array<number> = [];
    try {
      const result = await this.investorDashboardService.intalationsPerMonth(reqParams).toPromise();
      if (result && result.success) {
        // Object.keys(result.response).map( monthNumber => {
        //   if (result.response[monthNumber]){
        //     labels.push(this.months[parseInt(monthNumber) - 1])
        //     values.push(result.response[monthNumber])
        //   }
        // });
        labels = Object.keys(result.response);
        values = Object.values(result.response);
      }
    } catch (error) {
      // console.log(error);
    }
    this.installationsPerMonthData.labels = labels;
    this.installationsPerMonthData.datasets = [{
      label: 'Installations',
      data: values
    }];
  }

  /**
   * Method to fetch annual growth rate and update
   * the annualGrowthRateData property
   */
  // tslint:disable-next-line: typedef
  private async getAnnualGrowthRate(reqParams) {
    this.annualGrowthRateData.options = {
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    };

    try {
      const result = await this.investorDashboardService.annualGrowthRate(reqParams).toPromise();
      if (result && result.success) {
        this.annualGrowthRateData.labels = Object.keys(result.response);
        this.annualGrowthRateData.datasets = [{
          label: 'Growth rate (%)',
          data: Object.values(result.response)
        }];
      }
    } catch (error) {
      // console.log(error);
    }

  }

/**
 * Method to fetch annual revenue rate and update
 * the annualRevenueRateData property
 */
  // tslint:disable-next-line: typedef
  private async getAnnualRevenueRate(reqParams) {
    this.annualRevenueRateData.options = {
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    };

    try {
      const result = await this.investorDashboardService.annualRevenueRate(reqParams).toPromise();
      if (result && result.success) {
        this.annualRevenueRateData.labels = Object.keys(result.response);
        this.annualRevenueRateData.datasets = [{
          label: 'Revenue rate (%)',
          data: Object.values(result.response)
        }];
      }
    } catch (error) {
      // console.log(error);
    }

  }

  /**
   * Method to fetch annual cancellations rate and update
   * the annualCancellationsRateData property
   */
  // tslint:disable-next-line: typedef
  private async getAnnualCancellationsRate(reqParams) {
    this.annualCancellationsRateData.options = {
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    };

    try {
      const result = await this.investorDashboardService.annualCancellationsRate(reqParams).toPromise();
      if (result && result.success) {
        this.annualCancellationsRateData.labels = Object.keys(result.response);
        this.annualCancellationsRateData.datasets = [{
          label: 'Cancellations rate (%)',
          data: Object.values(result.response)
        }];
      }
    } catch (error) {
      // console.log(error);
    }

  }

  /**
   * Method to fetch YTD growth rate and update
   * the growthRateData property
   */
  // tslint:disable-next-line: typedef
  private async getGrowthRate(reqParams) {
    try {
      const result = await this.investorDashboardService.growthRate(reqParams).toPromise();
      if (result && result.success) {
        this.growthRateData.value = `${result.response}%`;
      }
    } catch (error) {
      // console.log(error);
    }
  }

  // tslint:disable-next-line: typedef
  private async getCustomerSatisfactionData() {
    try {
      const result = await this.satisfactionService.getLast().toPromise();
      if (result && result.success && result.response) {
        this.customerSatisfactionData.description = `from customer survey taken ${result.response.period}`;
        this.customerSatisfactionData.value = `${result.response.satisfaction}%`;
      }
    } catch (error) {
      // console.log(error)
    }
  }

  // tslint:disable-next-line: typedef
  private async getNPSData() {
    try {
      const result = await this.npsService.getLast().toPromise();
      if (result && result.success && result.response) {
        this.netPromoterScoreData.description = `Net Promoter Score from customer survey taken ${result.response.period}`;
        this.netPromoterScoreData.value = `${result.response.nps}%`;
      }
    } catch (error) {
      // console.log(error)
    }
  }

  /**
   * Method to fetch YTD cancellations rate and update
   * the cancellationsRateData property
   */
  // tslint:disable-next-line: typedef
  private async getCancellationsRate(reqParams) {
    try {
      const result = await this.investorDashboardService.cancellationsRate(reqParams).toPromise();
      if (result && result.success) {
        this.cancellationsRateData.value = `${result.response}%`;
      }
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * Method to fetch referrers rate and update
   * the referresRateData property
   */
  // tslint:disable-next-line: typedef
  private async getReferrersRate(reqParams) {
    try {
      const result = await this.investorDashboardService.referrersRate(reqParams).toPromise();
      if (result && result.success) {
        this.referresRateData.value = `${result.response} %`;
      }
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * Method to fetch referrers rate and update
   * the rollingGrowRateData property
   */
  // tslint:disable-next-line: typedef
  private async getRollingGrowthRate(reqParams) {
    try {
      const result = await this.investorDashboardService.rollingGrowthRate(reqParams).toPromise();
      if (result && result.success) {
        this.rollingGrowRateData.value = `${result.response} %`;
      }
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * Method to fetch YTD revenue rate and update
   * the growthRateData property
   */
  // tslint:disable-next-line: typedef
  private async getRevenueRate(reqParams) {
    try {
      const result = await this.investorDashboardService.revenueRate(reqParams).toPromise();
      if (result && result.success) {
        this.revenueRateData.value = `${result.response} %`;
      }
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * Method to fetch referrals per month in current year and update
   * the monthlyReferrersRateData property
   */
  // tslint:disable-next-line: typedef
  private async getMonthlyReferrersRate(reqParams) {
    this.monthlyReferrersRateData.options = {
      plugins: {
        datalabels: { display: false }
      },
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    };
    let labels: Array<string> = [];
    let values: Array<number> = [];
    try {
      const result = await this.investorDashboardService.monthlyReferrersRate(reqParams).toPromise();
      if (result && result.response) {
        // Object.keys(result.response).map( monthNumber => {
        //   if (result.response[monthNumber]){
        //     labels.push(this.months[parseInt(monthNumber) - 1])
        //     values.push(result.response[monthNumber])
        //   }
        // });
        labels = Object.keys(result.response);
        values = Object.values(result.response);
      }
    } catch (error) {
      // console.log(error);
    }
    this.monthlyReferrersRateData.labels = labels;
    this.monthlyReferrersRateData.datasets = [{
      label: 'Referrers Rate (%)',
      data: values
    }];
  }

  /**
   * Method to fetch YTD char rate and update
   * the churnRateData property
   */
  // tslint:disable-next-line: typedef
  private async getChurnRate(reqParams) {
    try {
      const result = await this.investorDashboardService.churnRate(reqParams).toPromise();
      if (result && result.success) {
        this.churnRateData.value = `${result.response}%`;
      }
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * Method to fetch churn rate per month in current year and update
   * the monthlyChurnRateData property
   */
  // tslint:disable-next-line: typedef
  private async getMonthlyChurnRate(reqParams) {
    this.monthlyChurnRateData.options = {
      plugins: {
        datalabels: { display: false }
      },
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    };
    let labels: Array<string> = [];
    let values: Array<number> = [];
    try {
      const result = await this.investorDashboardService.monthlyChurnRate(reqParams).toPromise();
      if (result && result.success) {
        // Object.keys(result.response).map( monthNumber => {
        //   if (result.response[monthNumber]){
        //     labels.push(this.months[parseInt(monthNumber) - 1])
        //     values.push(result.response[monthNumber])
        //   }
        // });
        labels = Object.keys(result.response);
        values = Object.values(result.response);
      }
    } catch (error) {
      // console.log(error);
    }
    this.monthlyChurnRateData.labels = labels;
    this.monthlyChurnRateData.datasets = [{
      label: 'Churn Rate (%)',
      data: values
    }];
  }

  /**
   * Method to fetch recurring revenue and update
   * the monthlyRecurringRevenueData and annualRecurringRevenueData properties
   */
  // tslint:disable-next-line: typedef
  private async getRecurringRevenue(reqParams) {
    // Updating monthly recurring revenue options
    this.monthlyRecurringRevenueData.options = {
      plugins: {
        datalabels: { display: false }
      },
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            callback: ((label: any) => label.toLocaleString('en')),
            beginAtZero: true
          }
        }]
      }
    };
    // Updating annual recurring revenue options
    this.annualRecurringRevenueData.options = {
      plugins: {
        datalabels: { display: false }
      },
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            callback: ((label: any) => label.toLocaleString('en')),
            beginAtZero: true
          }
        }]
      }
    };
    const labels: Array<string> = [];
    const values: Array<any> = [];
    const annualValues: Array<any> = [];
    try {
      const result = await this.investorDashboardService.monthlyRecurringRevenue(reqParams).toPromise();
      if (result && result.success) {
        Object.keys(result.response).map(monthNumber => {
          if (result.response[monthNumber]) {
            labels.push(monthNumber);
            values.push(parseFloat(result.response[monthNumber]).toFixed(2));
            // tslint:disable-next-line: binary-expression-operand-order
            annualValues.push((12 * parseFloat(result.response[monthNumber])).toFixed(2));
          }
        });

        // Setting annual recurring revenue data
        this.monthlyRecurringRevenueData.labels = labels;
        this.monthlyRecurringRevenueData.datasets = [{
          label: 'Revenue',
          data: values
        }];

        // Setting annual recurring revenue data
        this.annualRecurringRevenueData.labels = labels;
        this.annualRecurringRevenueData.datasets = [{
          label: 'Revenue',
          data: annualValues
        }];
      }
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * Method to fetch commited monthly recurring revenue and update
   * the monthlyReferrersRateData property
   */
  // tslint:disable-next-line: typedef
  private async getCommitedMonthlyRecurringRevenue(reqParams) {
    this.commitedMonthlyRecurringRevenueData.options = {
      plugins: {
        datalabels: { display: false }
      },
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            callback: ((label: any) => label.toLocaleString('en')),
            beginAtZero: true
          }
        }]
      }
    };
    try {
      const result = await this.investorDashboardService.commitedMonthlyRecurringRevenue(reqParams).toPromise();
      if (result && result.success) {
        this.commitedMonthlyRecurringRevenueData.labels = Object.keys(result.response);
        this.commitedMonthlyRecurringRevenueData.datasets = [{
          label: 'CMRR',
          data: Object.values(result.response)
        }];
      }
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * Method to fetch customer lifetime value per month in current year and update
   * the customerLifetimeValueData property
   */
  // tslint:disable-next-line: typedef
  private async getCustomerLifetimeValue(reqParams) {
    this.customerLifetimeValueData.options = {
      plugins: {
        datalabels: { display: false }
      },
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    };
    let labels: Array<string> = [];
    let values: Array<number> = [];
    try {
      const result = await this.investorDashboardService.customerLifetimeValue(reqParams).toPromise();
      if (result && result.success) {
        // Object.keys(result.response).map( monthNumber => {
        //   if (result.response[monthNumber]){
        //     labels.push(this.months[parseInt(monthNumber) - 1])
        //     values.push(result.response[monthNumber])
        //   }
        // });
        labels = Object.keys(result.response);
        values = Object.values(result.response);
        this.customerLifetimeValueData.labels = labels;
        this.customerLifetimeValueData.datasets = [{
          label: 'CLV',
          data: values
        }];
      }
    } catch (error) {
      // console.log(error);
    }
  }

  /**
   * Method to fetch cash and update
   * the cashData property
   */
  // tslint:disable-next-line: typedef
  private async getCash(reqParams) {
    this.cashData.options = {
      responsive: true,
      scales: {
        yAxes: [{
          ticks: {
            callback: ((label: any) => label.toLocaleString('en')),
            beginAtZero: true
          }
        }]
      }
    };

    try {
      const result = await this.cashService.index(reqParams).toPromise();
      if (result && result.success) {
        const labels = [];
        const values = [];
        result.response.map(item => {
          labels.push(item.bal_date);
          values.push(item.balance);
        });
        this.cashData.labels = labels;
        this.cashData.datasets = [{
          label: 'Cash',
          data: values
        }];
      }
    } catch (error) {
      // console.log(error);
    }

  }

}
