/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./supply-record.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/history/history.component.ngfactory";
import * as i3 from "../../../components/history/history.component";
import * as i4 from "./supply-record.component";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "../../../services/supplies.service";
var styles_SupplyRecordComponent = [i0.styles];
var RenderType_SupplyRecordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SupplyRecordComponent, data: {} });
export { RenderType_SupplyRecordComponent as RenderType_SupplyRecordComponent };
export function View_SupplyRecordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-history", [["type", "supplies"]], null, null, null, i2.View_HistoryComponent_0, i2.RenderType_HistoryComponent)), i1.ɵdid(2, 114688, null, 0, i3.HistoryComponent, [], { type: [0, "type"], items: [1, "items"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("close button") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancelar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "supplies"; var currVal_1 = _co.history; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SupplyRecordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-supply-record", [], null, null, null, View_SupplyRecordComponent_0, RenderType_SupplyRecordComponent)), i1.ɵdid(1, 245760, null, 0, i4.SupplyRecordComponent, [i5.NgbActiveModal, i6.SuppliesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SupplyRecordComponentNgFactory = i1.ɵccf("app-supply-record", i4.SupplyRecordComponent, View_SupplyRecordComponent_Host_0, { data: "data" }, {}, []);
export { SupplyRecordComponentNgFactory as SupplyRecordComponentNgFactory };
