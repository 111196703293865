import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ModalComponent } from '../../../components/modal/modal.component';
import { BroadcastService } from '../../../services/broadcast.service';
import { ClientsService } from '../../../services/clients.service';
import { SwalService } from '../../../services/swal.service';
import { environment } from '../../../../environments/environment';
import { PromotionalMonthlyFeesService } from '../../../services/promotional-monthly-fees.service';

@Component({
  selector: 'app-client-promotionals-monthly-fees',
  templateUrl: './client-promotionals-monthly-fees.component.html',
  styleUrls: ['./client-promotionals-monthly-fees.component.scss']
})
export class ClientPromotionalsMonthlyFeesComponent implements OnInit, OnDestroy {
  @Input() data: any;
  client: any;
  subscriptions: Array<Subscription> = [];
  activePromotions = {};
  notSupportedEvents = environment.notSupportedEvents;
  dataTableConfig = {
    config: {
      base: this.clientsService,
      api: 'getClientPromotionalMonthlyFee',
      params: {
        id_clients: 0
      }
    },
    columns: [
      {
        display: 'Promoción',
        field: 'current_promotional_data.name',
        type: 'text'
      },
      {
        display: 'Mensualidad promocional',
        field: 'current_promotional_data.monthsPrice',
        type: 'number'
      },
      {
        display: '# de meses promocionales',
        field: 'original_promotional_data.numberOfMonths',
        type: 'text'
      },
      {
        display: '# de meses promocionales restantes',
        field: 'current_promotional_data.numberOfMonths',
        type: 'text'
      },
      {
        display: 'Estatus',
        field: 'status',
        type: 'boolean'
      },
      {
        display: 'Fecha de adición',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'Ultima actualización',
        field: 'updated_at',
        type: 'date'
      },
      {
        display: 'Opciones',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Cancelar mes promocional',
            event: 'cancelPromotionalMonthlyFee',
            conditionality: 'this.data.status === 1',
            permissions: ['update-promotional-monthly-fees']
          }
        ]
      }
    ],
    filters: [{}]
  };
  constructor(
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    private readonly broadcast: BroadcastService,
    private readonly clientsService: ClientsService,
    private readonly promotionalsMonthlyFeesService: PromotionalMonthlyFeesService,
    private readonly swalService: SwalService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      if (!this.notSupportedEvents.includes(event.name)) {
        if (this[event.name]) {
          this[event.name](event.data);
        }
      }
    }));
    this.client = this.data;
    this.dataTableConfig.config.params.id_clients = this.data.id_clients;
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * assignPromotionalMonthlyFee
   * Manda una solicitud a la API con el ID de la promoción que se desea asignar al cliente
   */
  assignPromotionalMonthlyFee(client): void {
    this.subscriptions.push(this.promotionalsMonthlyFeesService.getActivePromotionals().subscribe((resp: any) => {
      if (resp.success) {
        resp.response.forEach(promotion => {
          const formatedOption = { [promotion.id_promotional_monthly_fee]: promotion.name };
          Object.assign(this.activePromotions, formatedOption)
        });

        const inputParams = {
          options: this.activePromotions,
          text: 'Seleccione la promoción deseada',
          confirmButtonText: 'Asignar promoción'
        };

        this.swalService.select(inputParams).then((selectResponse: any) => {
          if (selectResponse !== '') {
            const params = { id_promotional_monthly_fee: Number(selectResponse.value), id_clients: client.id_clients };
            this.subscriptions.push(this.clientsService.assignPromotionalMonthlyFee(params).subscribe((assignResp: any) => {
              if (assignResp.success) {
                this.swalService.success().then(() => {
                  this.activeModal.dismiss();
                  this.broadcast.reloadDataTable();
                });
              }
            }));
          }
        });
      }
    }));
  }

  /**
   * cancelPromotionalMonthlyFee
   * Manda una solicitud para actualizar el estatus de una promocion de mensualidad asignada a un cliente
   */
  cancelPromotionalMonthlyFee(promotion): void {
    const id_swal = 'cancel-promotion';
    const swalParams = {
      confirmButtonText: 'Cancelar meses promocionales',
      id: id_swal,
      inputs: [
        `<div id=${id_swal}>` +
        '<input id="cancellation_reason" type="text", placeholder="Razón" class="swal2-input" required>' +
        '</div>'
      ]
    };
    this.swalService.customForm(swalParams).then(formValues => {
      const payload = { id_client_promotional: promotion.id_client_promotional };
      formValues.value.forEach(element => {
        Object.assign(payload, { 'reason': element });
      });
      this.subscriptions.push(this.clientsService.cancelPromotion(payload).subscribe((response: any) => {
        if (response.success) {
          this.swalService.success().then(() => {
            this.broadcast.reloadDataTable();
          });
        } else {
          this.swalService.error({ text: response.message });
        }
      }));
    });
  }

}
