<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <div class="row">
        <div class="col-sm-10">
            <h2 class="m-0">Contratos</h2>
        </div>
        <div class="col-sm-2 text-right">
            <button 
            type="button" 
            class="btn btn-success" 
            title="Generar reporte de deudas"
            (click)="generateDebtsReport()" 
            *ngxPermissionsOnly="['list-debts']">
        <i class="fa fa-file-text"></i>
        </button>
        </div>
    </div>
</div>

<div class="container-fluid bg-white p-3">
    <app-datatable [options]="dataTableConfig"></app-datatable>
</div>