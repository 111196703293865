import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';
import * as i0 from "@angular/core";
var SwalService = /** @class */ (function () {
    function SwalService() {
        this.actionEvent = new BehaviorSubject({});
    }
    SwalService.prototype.input = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var base;
            return tslib_1.__generator(this, function (_a) {
                base = {
                    title: 'Razon',
                    text: 'Favor de proporcionar las razones',
                    input: 'textarea',
                    inputPlaceholder: 'Razones',
                    inputAttributes: {
                        autocapitalize: 'on'
                    },
                    showCancelButton: true,
                    confirmButtonColor: '#128f76',
                    confirmButtonText: 'Guardar',
                    cancelButtonText: 'Cancelar',
                    preConfirm: function (data) {
                        if (data === '') {
                            Swal.showValidationMessage('El campo no puede estar vacio');
                        }
                    }
                };
                if (params) {
                    Object.assign(base, params);
                }
                return [2 /*return*/, Swal.isVisible() ? null : Swal.fire(base)];
            });
        });
    };
    SwalService.prototype.error = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var base;
            return tslib_1.__generator(this, function (_a) {
                base = {
                    icon: 'error',
                    title: 'Error',
                    text: 'Ocurrio un error al procesar los datos',
                    confirmButtonColor: '#128f76',
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    onClose: function () {
                        // call (event)DatatableService to broadcast the params
                    }
                };
                if (params) {
                    Object.assign(base, params);
                }
                return [2 /*return*/, Swal.isVisible() ? null : Swal.fire(base)];
            });
        });
    };
    SwalService.prototype.success = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var base;
            return tslib_1.__generator(this, function (_a) {
                base = {
                    icon: 'success',
                    title: '!Exito!',
                    text: 'Datos procesados exitosamente',
                    confirmButtonColor: '#128f76',
                    confirmButtonText: 'OK',
                    allowOutsideClick: false,
                    onClose: function () {
                        // call (event)DatatableService to broadcast the params
                    }
                };
                if (params) {
                    Object.assign(base, params);
                }
                return [2 /*return*/, Swal.isVisible() ? null : Swal.fire(base)];
            });
        });
    };
    SwalService.prototype.warning = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var base;
            return tslib_1.__generator(this, function (_a) {
                base = {
                    icon: 'warning',
                    title: 'Atención',
                    text: 'Se modificará la siguiente información',
                    confirmButtonColor: '#128f76',
                    confirmButtonText: 'Si',
                    showCancelButton: true,
                    cancelButtonText: 'Cancelar',
                    allowOutsideClick: false,
                    onClose: function () {
                        // call (event)DatatableService to broadcast the params
                    }
                };
                if (params) {
                    Object.assign(base, params);
                }
                return [2 /*return*/, Swal.isVisible() ? null : Swal.fire(base)];
            });
        });
    };
    SwalService.prototype.info = function (info, params, title) {
        if (title === void 0) { title = ''; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var base;
            return tslib_1.__generator(this, function (_a) {
                base = {
                    icon: 'info',
                    title: title,
                    text: info,
                    confirmButtonColor: '#128f76',
                    confirmButtonText: 'OK',
                    showCancelButton: false,
                    allowOutsideClick: true,
                    onClose: function () {
                        // call (event)DatatableService to broadcast the params
                    }
                };
                if (params) {
                    Object.assign(base, params);
                }
                return [2 /*return*/, Swal.isVisible() ? null : Swal.fire(base)];
            });
        });
    };
    SwalService.prototype.select = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var base;
            return tslib_1.__generator(this, function (_a) {
                base = {
                    title: 'Seleccione una opción',
                    text: 'Favor de seleccionar la opción deseada',
                    input: 'select',
                    inputPlaceholder: 'Seleccione una opción',
                    inputOptions: params.options,
                    showCancelButton: true,
                    confirmButtonColor: '#128f76',
                    confirmButtonText: 'Guardar',
                    cancelButtonText: 'Cancelar'
                };
                if (params) {
                    Object.assign(base, params);
                }
                return [2 /*return*/, Swal.isVisible() ? null : Swal.fire(base)];
            });
        });
    };
    SwalService.prototype.customForm = function (params) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var base;
            return tslib_1.__generator(this, function (_a) {
                base = {
                    title: '',
                    showCancelButton: true,
                    confirmButtonColor: '#128f76',
                    confirmButtonText: 'Guardar',
                    cancelButtonText: 'Cancelar',
                    html: params.inputs,
                    preConfirm: function () {
                        var formValues = [];
                        var elementsById = document.getElementById(params.id).children;
                        var elementsByIdArr = Array.prototype.map.call(elementsById, function (element) { return element.id; });
                        elementsByIdArr.forEach(function (element) {
                            var _a;
                            var element_name = element;
                            formValues.push((_a = {}, _a[element_name] = document.getElementById(element).value, _a));
                        });
                        return formValues;
                    }
                };
                if (params) {
                    Object.assign(base, params);
                }
                return [2 /*return*/, Swal.isVisible() ? null : Swal.fire(base)];
            });
        });
    };
    SwalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SwalService_Factory() { return new SwalService(); }, token: SwalService, providedIn: "root" });
    return SwalService;
}());
export { SwalService };
