import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrrorCodesService } from '../../../../services/error-codes.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FromService } from '../../../../providers/form.service';
import { SwalService } from '../../../../services/swal.service';
import { environment } from '../../../../../environments/environment';
import { BroadcastService } from '../../../../services/broadcast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-error-code-create-edit',
  templateUrl: './error-code-create-edit.component.html',
  styleUrls: ['./error-code-create-edit.component.scss']
})
export class ErrorCodeCreateEditComponent implements OnInit, OnDestroy {
  @Input() data: any;
  error_code: any;
  subscriptions: Array<Subscription> = [];
  category = environment.error_codes_categories;
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    code: ['', Validators.required],
    category: ['', Validators.required]
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly errorCodeService: ErrrorCodesService,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService) { }

  ngOnInit(): void {
    if (this.data.status === 'edit') {
      this.subscriptions.push(this.errorCodeService.show(this.data.errorCodeData.id_error_codes).subscribe((resp: any) => {
        this.error_code = resp.response;
        Object.keys(this.error_code).forEach(key => {
          if (this.error_code.hasOwnProperty(key) && !!this.form.controls[key]) {
            this.form.controls[key].setValue(this.error_code[key]);
          }
        });
      }));
    }
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  save(): void {
    if (this.data.status === 'edit') {
      this.updateErrorCode();
    }

    if (this.data.status === 'create') {
      this.saveErrorCode();
    }
  }

  saveErrorCode(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar el siguiente código de error?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.errorCodeService.create(this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

  updateErrorCode(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del código de error?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.errorCodeService.edit(this.data.errorCodeData.id_error_codes, this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
            }
          }));
        }
      });
    }
  }

}
