/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./elements.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-permissions";
import * as i3 from "../../../../components/datatable/datatable.component.ngfactory";
import * as i4 from "../../../../components/datatable/datatable.component";
import * as i5 from "../../../../services/broadcast.service";
import * as i6 from "./elements.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../../components/modal/modal.component";
import * as i9 from "../../../../services/elements.service";
import * as i10 from "../../../../services/permissions.service";
import * as i11 from "../../../../services/swal.service";
import * as i12 from "ngx-lightbox/lightbox.service";
var styles_ElementsComponent = [i0.styles];
var RenderType_ElementsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ElementsComponent, data: {} });
export { RenderType_ElementsComponent as RenderType_ElementsComponent };
function View_ElementsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createElementItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Agregar Elemento"]))], null, null); }
export function View_ElementsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ElementsComponent_1)), i1.ɵdid(3, 737280, null, 0, i2.NgxPermissionsDirective, [i2.NgxPermissionsService, i2.NgxPermissionsConfigurationService, i2.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "app-datatable", [], null, null, null, i3.View_DatatableComponent_0, i3.RenderType_DatatableComponent)), i1.ɵdid(6, 245760, null, 0, i4.DatatableComponent, [i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i5.BroadcastService], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, "create-elements"); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.dataTableConfig; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_ElementsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-elements", [], null, null, null, View_ElementsComponent_0, RenderType_ElementsComponent)), i1.ɵdid(1, 245760, null, 0, i6.ElementsComponent, [i7.NgbActiveModal, i8.ModalComponent, i5.BroadcastService, i9.ElementsService, i10.PermissionsService, i11.SwalService, i12.Lightbox], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ElementsComponentNgFactory = i1.ɵccf("app-elements", i6.ElementsComponent, View_ElementsComponent_Host_0, { data: "data" }, {}, []);
export { ElementsComponentNgFactory as ElementsComponentNgFactory };
