import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
// Components
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { BroadcastService } from '../../../services/broadcast.service';
import { PermissionsService } from '../../../services/permissions.service';
import { SwalService } from '../../../services/swal.service';
// Services
import { TicketsService } from '../../../services/tickets.service';
import { AssignComponent } from './assign/assign.component';
import { AssignationCreateEditComponent } from './assignation-create-edit/assignation-create-edit.component';
import { CloseComponent } from './close/close.component';
import { RecordComponent } from './record/record.component';
import { UnrealizedComponent } from './unrealized/unrealized.component';
import { UploadPhotosComponent } from '../../../components/shared/upload-photos/upload-photos.component';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.scss']
})

export class TicketsComponent implements OnDestroy, OnInit {
  broadcast$: Subscription;
  dataTableConfig: any;
  private readonly baseDataTableConfig = {
    config: {
      type: 'tickets',
      base: this.tickets,
      api: 'getTickets',
      role: 3,
      order: [[0, 'desc']]
    },
    columns: [
      {
        display: 'Estatus',
        field: 'id_tickets',
        type: 'ticket_status'
      },
      {
        display: 'Técnico',
        field: 'assignations',
        type: 'ticket_technician'
      },
      {
        display: 'CLIENTE',
        field: 'client.name',
        type: 'ticket_client',
        orderable: false
      },
      {
        display: 'CONTACTO',
        field: 'client',
        type: 'ticket_contact',
        orderable: false
      },
      {
        display: 'FECHA DE CREACION',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Historial',
            icon: 'fa fa-search',
            event: 'tickets.record',
            conditionality: 'true'
          },
          {
            display: 'Asignar ticket',
            icon: 'fa fa-hand-o-right',
            event: 'tickets.assign',
            conditionality: 'this.data.status === "opened"',
            permissions: ['update-tickets']
          },
          {
            display: 'Reasignar ticket',
            icon: 'fa fa-hand-o-right',
            event: 'tickets.reassing',
            conditionality: 'this.data.status === "assigned"',
            permissions: ['update-tickets']
          },
          {
            display: 'Desasignar ticket',
            icon: 'fa fa-hand-o-left',
            event: 'tickets.unassign',
            conditionality: 'this.data.status === "assigned"',
            permissions: ['update-tickets']
          },
          {
            display: 'Editar descripción',
            icon: 'fa fa-pencil',
            event: 'ticket.edit',
            conditionality: 'this.data.status === "assigned"',
            permissions: ['update-tickets']
          },
          {
            display: 'Ticket no realizado',
            event: 'tickets.unrealized',
            conditionality: 'this.data.status === "opened" || this.data.status === "assigned" ',
            permissions: ['update-tickets']
          },
          {
            display: 'Cerrar ticket',
            event: 'tickets.close',
            conditionality: 'this.data.status === "completed" || this.data.status === "confirmed" ',
            permissions: ['update-tickets']
          },
          {
            display: 'Subir imagenes',
            icon: 'fa fa-picture-o',
            event: 'tickets.images',
            conditionality: 'this.data.add_images ',
            permissions: ['update-tickets']
          }
        ]
      }
    ],
    filters: [
      {
        cssClass: 'col-md-12',
        type: 'status',
        options: [
          {
            val: '',
            name: 'TODOS'
          },
          {
            val: 'opened',
            name: 'ABIERTO'
          },
          {
            val: 'assigned',
            name: 'ASIGNADO'
          },
          {
            val: 'completed',
            name: 'COMPLETADO POR EL TECNICO'
          },
          {
            val: 'confirmed',
            name: 'FIRMADO POR EL CLIENTE'
          },
          {
            val: 'closed',
            name: 'CERRADO'
          },
          {
            val: 'unrealized',
            name: 'NO REALIZADO'
          }
        ]
      }
    ]
  };

  constructor(
    private readonly tickets: TicketsService,
    private readonly broadcast: BroadcastService,
    public appModal: ModalComponent,
    private readonly swal: SwalService,
    public activeModal: NgbActiveModal,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.broadcast$ = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'tickets.record': this.recordItem(event.data); break;
        case 'tickets.assign': this.assignItem(event.data); break;
        case 'tickets.reassing': this.reassingItem(event.data); break;
        case 'tickets.unassign': this.unassignItem(event.data); break;
        case 'ticket.edit': this.editItem(event.data); break;
        case 'tickets.close': this.closeItem(event.data); break;
        case 'tickets.unrealized': this.unrealizedItem(event.data); break;
        case 'tickets.images': this.uploadPhotosItem(event.data); break;
      }
    });
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    this.broadcast$.unsubscribe();
    // this.setStatusSub.unsubscribe();
  }

  recordItem(data): void {
    // open modal, passing the context
    const props: SharedComponent = new SharedComponent(RecordComponent, data, { title: `Historial del ticket ${data.id_tickets} del cliente ${data.client.name}` });
    this.appModal.openXl(props);
  }

  assignItem(data): void {
    // open modal, passing the context
    const props: SharedComponent = new SharedComponent(
      AssignComponent,
      { ticket: data, status: 'assign' },
      { title: `Asignación de ticket ${data.id_tickets}` }
    );
    this.appModal.openXl(props);
  }

  reassingItem(data): void {
    // open modal, passing the context
    const props: SharedComponent = new SharedComponent(
      AssignComponent,
      { ticket: data, status: 'reassing' },
      { title: `Reasignación de ticket #${data.id_tickets}` }
    );
    this.appModal.openXl(props);
  }

  unassignItem(data): void {
    const swalParams = {
      title: '¿Desea desasignar este ticket?',
      text: '¡Si desasigna este ticket el estatus pasara a abierto!'
    };

    this.swal.warning(swalParams).then(result => {
      if (result.value) {
        const params = {
          status: 'unassigned'
        };
        this.tickets.setStatus(params, data.id_tickets).subscribe((res: any) => {
          if (res.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurrió un error al actualizar los datos' });
          }
        });
      }
    });
  }

  editItem(data): void {
    const props: SharedComponent = new SharedComponent(
      AssignationCreateEditComponent,
      { ticket: data, status: 'edit' },
      { title: `Edición del ticket #${data.id_tickets}` }
    );
    this.appModal.opensm(props);
  }

  closeItem(data): void {
    // open modal, passing the context
    const props: SharedComponent = new SharedComponent(CloseComponent, data, { title: `Cerrar el ticket ${data.id_tickets} del cliente ${data.client.name}` });
    this.appModal.opensm(props);
  }

  /**
   * unrealizedItem
   * Manda una peticion a la API para marcar un ticket como no realizado.
   * @param data 
   */
  unrealizedItem(data): void {
    const props: SharedComponent = new SharedComponent(UnrealizedComponent, data, { title: `Marcar como no realizado el ticket ${data.id_tickets} del cliente ${data.client.name}` });
    this.appModal.opensm(props);
  }

  /**
   * addImagesItem
   * Abre un modal para subir imagenes que se asociaran al ticket
   * @param data 
   */
  uploadPhotosItem(data): void {
    const props: SharedComponent = new SharedComponent(
      UploadPhotosComponent,
      data,
      { title: `Subir imagenes al ticket ${data.id_tickets} del cliente ${data.client.name}` }
    );
    this.appModal.opensm(props);
  }

}
