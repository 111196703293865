import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CategoriesService {
    baseUrl = `${environment.apiUrl}`;

    constructor(private readonly http: HttpClient) { }

    create(params): Observable<any> {
        return this.http.post(`${this.baseUrl}/categories`, params);
    }

    delete(id): Observable<any> {
        return this.http.delete(`${this.baseUrl}/categories/${id}`);
    }

    show(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/categories/${id}`);
    }

    update(id, params): Observable<any> {
        return this.http.put(`${this.baseUrl}/categories/${id}`, params);
    }

    getCategories(params?): Observable<any> {
        return this.http.post(`${this.baseUrl}/categories/get_categories`, params);
    }

    getCategoriesList(): Observable<any> {
        return this.http.get(`${this.baseUrl}/categories`);
    }
}
