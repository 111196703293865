import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthorizationService } from '../services/authorization.service';
import { SwalService } from '../services/swal.service';

@Injectable({
  providedIn: 'root'
})

export class CatchAllInterceptorService implements HttpInterceptor {

  constructor(
    private readonly router: Router,
    public authService: AuthorizationService,
    public swal: SwalService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError(httpError => {
        let errorMessage: any;
        let displayMessage: any;
        if (httpError instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Client-side error: ${httpError.error.message}`;
          this.swal.error({ text: errorMessage }).catch();
        } else {
          if (httpError.error.error === 'user_not_found') {
            errorMessage = 'Sesion expirada';
            this.authService.logout();
            this.swal.warning({ title: 'Sesion expirada', text: '', showCancelButton: false, confirmButtonText: 'Ok' });
            this.router.navigateByUrl('/login');
          } else {
            // backend error
            errorMessage = `Server-side error: ${httpError.status} ${httpError.message}`;
            const errors = [
              `${httpError.status} - ${httpError.statusText}`,
              httpError.url
            ];

            displayMessage = '<div class="server-error">';
            displayMessage += '<p>Server-side error:</p>';
            displayMessage += `<ul>${this.returnFormated(errors)}</ul>`;
            displayMessage += '</div>';

            switch (httpError.status) {
              case 0:
                this.authService.logout();
                this.router.navigateByUrl('/login');
                localStorage.removeItem('user');
                localStorage.removeItem('access_token');
                this.swal.error({ html: displayMessage }).catch();
                break;
              case 400:
                if (req.url.includes('auth/refresh')) {
                  break;
                }
                const mergedErrorMessages = [];
                let response_errors = httpError.error.response.errors;
                if (!(response_errors instanceof Array)) {
                  response_errors = Object.keys(response_errors)
                    .map(key => response_errors[key]);
                  const failure_messages = response_errors || [];
                  displayMessage = '<div class="server-error">';
                  // displayMessage += '<p>Vendor error:</p>';
                  let errorsShow = '<li>Error al procesar el pago.</li>';
                  if (failure_messages.length > 0) {
                    const formatedErrors = this.returnFormated(failure_messages);
                    if (formatedErrors !== '') {
                      errorsShow = formatedErrors;
                    }
                  }
                  displayMessage += `<ul>${errorsShow}</ul>`;
                  displayMessage += '</div>';
                  this.swal.error({ html: displayMessage });
                } else {
                  response_errors.forEach(key => {
                    mergedErrorMessages.push(response_errors[key].join(' / '));
                  });

                  const bad_parameters_message = mergedErrorMessages.join(' / ');
                  this.swal.warning({
                    title: 'Error en parámetros',
                    text: bad_parameters_message,
                    confirmButtonText: 'Aceptar',
                    showCancelButton: false
                  });
                }
                break;
              case 401:
                if (httpError.error === 'bad_credentials') {
                  this.swal.error({
                    title: 'Credenciales invalidas',
                    text: '',
                    showCancelButton: false,
                    confirmButtonText: 'Ok'
                  });
                  break;
                }
                errorMessage = httpError;
                break;
              case 402:
                const failure_messages = httpError.error.response.errors || [];
                displayMessage = '<div class="server-error">';
                // displayMessage += '<p>Vendor error:</p>';
                let errorsShow = '<li>Error al procesar el pago.</li>';
                if (failure_messages.length > 0) {
                  const formatedErrors = this.returnFormated(failure_messages);
                  if (formatedErrors !== '') {
                    errorsShow = formatedErrors;
                  }
                }
                displayMessage += `<ul>${errorsShow}</ul>`;
                displayMessage += '</div>';
                this.swal.error({ html: displayMessage });

                break;
              case 403:
                const unauthorized_message = httpError.error.response.errors;
                this.swal.warning({
                  title: unauthorized_message,
                  text: '',
                  confirmButtonText: 'Aceptar',
                  showCancelButton: false
                });
                break;
              case 429:
              case 404:
                const failure_message = httpError.error.response.errors;
                this.swal.warning({
                  title: failure_message,
                  text: '',
                  confirmButtonText: 'Aceptar',
                  showCancelButton: false
                });
                break;
              default:
                this.authService.logout();
                this.router.navigateByUrl('/login');
                this.swal.error({ html: displayMessage }).catch();
                break;
            }

          }
        }

        // aquí podrías agregar código que muestre el error en alguna parte fija de la pantalla.
        // this.errorService.show(errorMessage);
        // console.warn(errorMessage);
        return throwError(errorMessage);
      })
    );
  }

  returnFormated(errors: Array<any>): string {
    let text = '';
    errors.forEach(error => {
      if (Array.isArray(error) && error.length > 0) {
        error.forEach(subError => {
          if (subError.hasOwnProperty('failure_message')) {
            text += `<li>${subError.failure_message}</li>`;
          } else {
            text += `<li>${subError}</li>`;
          }
        });
      } else {
        text += `<li>${error}</li>`;
      }
      // if (error.hasOwnProperty('failure_message')) {
      //   text += `<li>${error.failure_message}</li>`;
      // } else {
      //   text += `<li>${error}</li>`;
      // }
    });

    return text;
  }

  // returnPaymentErrors(errors: Array<any>): string {
  //   let text = '';
  //   errors.forEach(element => {
  //     text += `<li>${element.failure_message}</li>`;
  //   });

  //   return text;
  // }
}
