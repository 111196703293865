<div [ngSwitch]="data.select_type">
    <div *ngSwitchCase="'dropdown'">
        <div ngbDropdown container="body" class="btn-group btn-group-toggle mb-2 d-table">
            <button class="btn btn-secondary" id="sortData" ngbDropdownToggle>{{dropdown_title}}</button>
            <div ngbDropdownMenu aria-labelledby="sortData">
              <button class="dropdown-item" *ngFor="let filter of data.options" (click)="radioChange(filter.val, filter.name)">{{filter.name}}</button>
            </div>
          </div>
    </div>

    <div *ngSwitchDefault class="btn-group btn-group-toggle mb-2 d-table" ngbRadioGroup name="radioBasic" [(ngModel)]="selected">
        <label *ngFor="let filter of data.options" ngbButtonLabel class="btn-secondary">
            <input ngbButton type="radio" (change)="radioChange(filter.val)"> {{filter.name}}    
        </label>
    </div>
</div>
<br>