import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FromService } from '../../../providers/form.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { ClientsService } from '../../../services/clients.service';
import { SwalService } from '../../../services/swal.service';

@Component({
  selector: 'app-associate-child-accounts',
  templateUrl: './associate-child-accounts.component.html',
  styleUrls: ['./associate-child-accounts.component.scss']
})
export class AssociateChildAccountsComponent implements OnInit {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  childAccounts = [];
  form: FormGroup = this.formBuilder.group({
    childAccounts: ['', Validators.required]
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly clientsService: ClientsService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swalService: SwalService,
  ) { }

  ngOnInit(): void {
    this.fromService.setForm(this.form);
    this.getClients();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * associateAccounts
   * Envia una petición PUT para asociar el id del cliente seleccionado con los ids que pasaran a ser sus cuentas hijas
   */
  associateAccounts(): void {
    const swalTitle = `Se asociaran las siguientes cuentas a ${this.data.name}`;
    this.swalService.warning({ title: swalTitle }).then(result => {
      if (result.value) {
        this.subscriptions.push(this.clientsService.associateChildAccounts(this.data.id_clients, this.form.value).subscribe((response: any) => {
          if (response.success) {
            this.swalService.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swalService.error({ title: response.message });
          }
        }));
      }
    });
  }

  /**
   * getClients
   * Obtiene a todos los clientes en el sistema, excepto el cliente de data.
   */
  private getClients(): void {
    if (this.childAccounts.length <= 0) {
      const params = {
        // selected_columns: ['id_clients', 'name', 'parent'],
        parentId: this.data.id_clients,
        // notIn: { column: 'parent', values: [this.data.id_clients] },
        length: '3000'
      };

      this.subscriptions.push(this.clientsService.getClients(params).subscribe((resp: any) => {
        if (resp.data.length > 0) {
          this.childAccounts = resp.data.map(client => ({ id: client.id_clients, name: client.name }));
        }
      }));
    }
  }
}
