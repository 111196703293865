import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var NPSService = /** @class */ (function () {
    function NPSService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    NPSService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/admin_dashboard/nps", params);
    };
    NPSService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/admin_dashboard/nps/" + id);
    };
    NPSService.prototype.edit = function (id, params) {
        return this.http.put(this.baseUrl + "/admin_dashboard/nps/" + id, params);
    };
    NPSService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/admin_dashboard/nps/" + id);
    };
    NPSService.prototype.index = function (params) {
        return this.http.get(this.baseUrl + "/admin_dashboard/nps", { params: params });
    };
    NPSService.prototype.getNPS = function (params) {
        return this.http.post(this.baseUrl + "/admin_dashboard/nps/get-nps", params);
    };
    NPSService.prototype.getLast = function () {
        return this.http.get(this.baseUrl + "/admin_dashboard/nps/last");
    };
    NPSService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NPSService_Factory() { return new NPSService(i0.ɵɵinject(i1.HttpClient)); }, token: NPSService, providedIn: "root" });
    return NPSService;
}());
export { NPSService };
