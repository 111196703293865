import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatatableModule } from './datatable/datatable.module';
import { FormModule } from './form/form.module';
import { ModalModule } from './modal/modal.module';
import { PipesModule } from './pipes/pipes.module';

@NgModule({
  declarations: [
  ],
  entryComponents: [
  ],
  exports: [
    DatatableModule,
    ModalModule,
    FormModule
  ],
  imports: [
    CommonModule,
    DatatableModule,
    ModalModule,
    FormModule,
    PipesModule
  ]
})
export class ComponentsModule { }
