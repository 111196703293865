<span *ngIf="clientData; else notFound">
    <dl class="row">
        <dt class="col-sm-3">Nombre</dt>
        <dd class="col-sm-9">{{clientData?.name}}</dd>
        <dt class="col-sm-3">Email</dt>
        <dd class="col-sm-9">{{clientData?.email}}</dd>
        <dt class="col-sm-3">Teléfono</dt>
        <dd class="col-sm-9">{{clientData?.phone}}</dd>
        <dt class="col-sm-3">Dirección</dt>
        <dd class="col-sm-9">{{clientData?.address}}</dd>
        <dt class="col-sm-3" *ngIf="clientData?.inside_number">Número interior</dt>
        <dd class="col-sm-9" *ngIf="clientData?.inside_number">{{clientData?.inside_number}}</dd>
        <dt class="col-sm-3">Colonia</dt>
        <dd class="col-sm-9">{{clientData?.colony}}</dd>
        <dt class="col-sm-3">Entre</dt>
        <dd class="col-sm-9">{{clientData?.between_streets}}</dd>
        <dt class="col-sm-3">Código postal</dt>
        <dd class="col-sm-9">{{clientData?.postal_code}}</dd>
        <dt *ngIf="clientData?.coordinate" class="col-sm-3">Coordenadas</dt>
        <dd *ngIf="clientData?.coordinate" class="col-sm-9">{{clientData.coordinate?.latitude}}, {{clientData.coordinate?.longitude}}</dd>
        <dt class="col-sm-3">Estado</dt>
        <dd class="col-sm-9">{{clientData?.state}}</dd>
        <dt class="col-sm-3">Fecha de alta</dt>
        <dd class="col-sm-9">{{clientData?.created_at}}</dd>
        <dt class="col-sm-3">Estatus</dt>
        <dd class="col-sm-9">{{clientData?.status}}</dd>
        <dt class="col-sm-3">Servicio gratis</dt>
        <dd class="col-sm-9">{{clientData?.level!='VIAJERO'?'Si':'No'}}</dd>
    </dl>
</span>

<ng-template #notFound>Datos del cliente no encontrados...</ng-template>
