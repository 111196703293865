import { Injectable } from '@angular/core';
declare var Conekta: any;
declare var OpenPay: any;
@Injectable({
  providedIn: 'root'
})
export class TokensService {

  async default(): Promise<any> {
    return new Promise(
      resolve => {
        const tk = {
          success: false
        };
        resolve(tk);
      }
    );
  }
  
  async conekta(vendor: string, data: any): Promise<any> {
    return new Promise(
      resolve => {
        Conekta.token.create({ card: data },
          token => {
            const tk = {
              success: true
            };
            tk[vendor] = token.id;
            resolve(tk);
          },
          error => {
            resolve(error);
          }
        );
        
      }
    );
  }

  async openpay(vendor: string, data: any): Promise<any> {
    const deviceSessionId = OpenPay.deviceData.setup();

    return new Promise(
      resolve => {
        OpenPay.token.create(data,
          token => {
            const tk = {
              success: true,
              openpay_device_session_id: deviceSessionId
            };
            tk[vendor] = token.data.id;
            resolve(tk);
          },
          error => {
            resolve(error);
          }
        );
      }
    );
  }

}
