import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

// Services
import { AdvertisingService } from '../../../../services/advertising.service';
import { AdvertisingFormComponent } from './advertising-form/advertising-form.component';
import { BroadcastService } from '../../../../services/broadcast.service';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { PermissionsService } from '../../../../services/permissions.service';
import { SharedComponent } from '../../../../model/shared-component';
import { SwalService } from '../../../../services/swal.service';

@Component({
  selector: 'app-advertising-table',
  templateUrl: './advertising-table.component.html',
  styleUrls: ['./advertising-table.component.scss']
})
export class AdvertisingTableComponent implements OnInit, OnDestroy {

  dataTableConfig: any;
  broadcast$: Subscription;

  private readonly baseDataTableConfig = {
    config: {
      base: this.advertisingService,
      api: 'getAdvertising'
    },
    columns: [
      {
        display: 'Fecha de balance',
        field: 'balance_date',
        type: 'date'
      },
      {
        display: 'Balance',
        field: 'balance',
        type: 'currency'
      },
      {
        display: 'Acciones',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Editar',
            icon: 'fa fa-pencil',
            event: 'advertising.edit',
            conditionality: 'true',
            permissions: ['update-ads']
          },
          {
            display: 'Eliminar',
            icon: 'fa fa-trash fa-fw',
            event: 'advertising.delete',
            conditionality: 'true',
            permissions: ['delete-ads']
          }
        ]

      }
    ]
  };

  constructor(
    private readonly advertisingService: AdvertisingService,
    private readonly broadcast: BroadcastService,
    private readonly permissionsService: PermissionsService,
    private readonly swal: SwalService,
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent
  ) { }

  ngOnInit(): void {
    this.broadcast$ = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'advertising.delete': this.deleteAdvertisingItem(event.data); break;
        case 'advertising.edit': this.editAdvertisingItem(event.data); break;
      }
    });
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    this.broadcast$.unsubscribe();
  }

  createAdvertisingItem(data?): void {
    const props: SharedComponent = new SharedComponent(
      AdvertisingFormComponent,
      {
        advertisingData: data,
        status: 'create'
      },
      {
        title: 'Crear entrada de advertising'
      }
    );
    this.appModal.open(props);
  }

  deleteAdvertisingItem(data): void {
    const swalParams = {
      title: `¿Estás seguro de querer eliminar el balance del día ${data.balance_date}?`,
      text: ''
    };

    this.swal.warning(swalParams).then(async result => {
      if (result.value) {
        const resp = await this.advertisingService.delete(data.id).toPromise();
        if (resp && resp.success) {
          this.swal.success({ title: 'Entrada de advertising eliminada exitosamente' }).then(() => {
            this.activeModal.dismiss();
            this.broadcast.reloadDataTable();
          });
        } else {
          this.swal.error('Ocurrio un error al intentar borrar la entrada de advertising');
        }
      }
    });
  }

  editAdvertisingItem(data): void {
    // open modal, passing the context
    const props: SharedComponent = new SharedComponent(
      AdvertisingFormComponent,
      {
        advertisingData: data,
        status: 'edit'
      },
      {
        title: 'Entradas de advertising'
      });
    this.appModal.opensm(props);
  }

}
