import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { SuppliesService } from '../../../../services/supplies.service';
import { SwalService } from '../../../../services/swal.service';
import { selectFormat } from '../../../../model/shared-component.interface';

@Component({
  selector: 'app-supply-create-edit',
  templateUrl: './supply-create-edit.component.html',
  styleUrls: ['./supply-create-edit.component.scss']
})
export class SupplyCreateEditComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  supply: any;
  suppliesCategories: Array<selectFormat> = [];
  units: Array<selectFormat> = [
    { id: 'PIEZAS', name: 'PIEZAS' }, { id: 'METROS', name: 'METROS' },
    { id: 'LITROS', name: 'LITROS' }, { id: 'KILOS', name: 'KILOS' }
  ];
  defaultSupplyCategory = 'Selecciona una categoria';
  defaultUnity = 'Selecciona una unidad';
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    stock: ['', Validators.required],
    supplies_categories_id: [null, Validators.required],
    min_amount: ['', Validators.required],
    unitary_cost: ['', Validators.required],
    total_cost: ['', Validators.required],
    unity: ['', Validators.required],
    status: ['', Validators.required]
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService,
    private readonly suppliesService: SuppliesService
  ) { }

  ngOnInit(): void {
    this.getSuppliesCategories();
    if (this.data.status === 'edit') {
      this.removeControls();
      this.subscriptions.push(this.suppliesService.showSupply(this.data.editData.id).subscribe((resp: any) => {
        this.supply = resp.response;
        this.setData();
      }));
    }
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * save
   * Dependiendo del status de la vista ejecuta la funcionalidad para 
   * guardar cambios o enviar los datos para un nuevo insumo
   */
  save(): void {
    const functionName = `${this.data.status}Supply`;
    this[functionName]();
  }

  /**
   * createSupply
   * Envia datos del formulario para registrar un insumo
   */
  createSupply(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar el siguiente insumo?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.suppliesService.createSupply(this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

  /**
   * editSupply
   * Guarda los cambios hechos a un insumo existente.
   */
  editSupply(): void {
    if (this.form.valid) {
      const params = this.fromService.getDirtyValues(this.form);
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del insumo?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.suppliesService.updateSupply(this.supply.id, params).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
            }
          }));
        }
      });
    }
  }

  /**
   * getSuppliesCategories
   * Obtiene todas las categorias de insumos registradas en la BD.
   */
  private getSuppliesCategories() {
    this.subscriptions.push(
      this.suppliesService.indexCategoriesSupplies().subscribe((resp: any) => {
        if (resp.success) {
          this.suppliesCategories = resp.response;
        }
      })
    );
  }

  /**
   * setData
   * Setea los datos cuando el status es edit.
   */
  private setData(): void {
    Object.keys(this.supply).forEach(key => {
      if (this.supply.hasOwnProperty(key) && !!this.form.controls[key]) {
        this.form.controls[key].setValue(this.supply[key]);
      }
    });
  }

  /**
   * removeControntol
   * Cuando la vista es de tipo edit remueve los controladores del form especificados en el Array;
   */
  private removeControls(): void {
    const controlToRemove = ['stock', 'unitary_cost', 'total_cost'];
    controlToRemove.forEach(control => { this.form.removeControl(control) });
  }
}
