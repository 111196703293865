/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shares-by-type-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-charts";
import * as i3 from "./shares-by-type-chart.component";
var styles_SharesByTypeChartComponent = [i0.styles];
var RenderType_SharesByTypeChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharesByTypeChartComponent, data: {} });
export { RenderType_SharesByTypeChartComponent as RenderType_SharesByTypeChartComponent };
export function View_SharesByTypeChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Shares"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "canvas", [["baseChart", ""]], null, null, null, null, null)), i1.ɵdid(4, 999424, null, 0, i2.BaseChartDirective, [i1.ElementRef, i2.ThemeService], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sharesByTypeChartData; var currVal_1 = _co.labels; var currVal_2 = _co.options; var currVal_3 = _co.barChartType; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_SharesByTypeChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shares-by-type-chart", [], null, null, null, View_SharesByTypeChartComponent_0, RenderType_SharesByTypeChartComponent)), i1.ɵdid(1, 638976, null, 0, i3.SharesByTypeChartComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharesByTypeChartComponentNgFactory = i1.ɵccf("app-shares-by-type-chart", i3.SharesByTypeChartComponent, View_SharesByTypeChartComponent_Host_0, { type: "data" }, {}, []);
export { SharesByTypeChartComponentNgFactory as SharesByTypeChartComponentNgFactory };
