import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bad-status',
  templateUrl: './bad-status.component.html',
  styleUrls: ['./bad-status.component.scss']
})
export class BadStatusComponent implements OnInit {
  @Input('item') item: any;
  
  ngOnInit(): void {
    //
  }

}
