import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ChartOptions, ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';

@Component({
  selector: 'app-subscriptions-chart',
  templateUrl: './subscriptions-chart.component.html',
  styleUrls: ['./subscriptions-chart.component.scss']
})
export class SubscriptionsChartComponent implements OnInit, OnChanges {
  @Input('data') subscriptions: any;
  actual_subs = [];
  past_subs = [];
  labels = [];
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  month_label_custom: any;
  chartColor: Array<Color> = [
    {
      backgroundColor: '#16a4db88',
      borderColor: '#16a4db'
    },
    {
      backgroundColor: '#83b94188',
      borderColor: '#83b941'
    }
  ];

  options: ChartOptions = {
    plugins: {
      datalabels: { display: false }
    },
    scales: {
      xAxes: [{}],
      yAxes: [{
        ticks: { beginAtZero: true }
      }]
    },
    tooltips: {
      callbacks: {
        title: ((tooltipItems: any) => {
          this.month_label_custom = tooltipItems[0].label;
          if (tooltipItems[0].datasetIndex === 1) {
            const month = tooltipItems[0].label.slice(0, -2);
            const current_year = tooltipItems[0].label.slice(-2);
            const past_year = current_year - 1;
            const past_period = `${month} ${past_year}`;

            this.month_label_custom = past_period;
          }

          return this.month_label_custom;
        })
      }
    }
  };

  subsChartData: Array<ChartDataSets> = [
    {
      data: this.actual_subs,
      label: 'Subscripciones actuales',
      stack: 'a'
    },
    {
      data: this.past_subs,
      label: 'Subscripciones pasadas',
      stack: 'a'
    }
  ];
  lineChartType = 'line';

  ngOnInit(): void {
    //
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.subscriptions.currentValue.actualSubscriptions.length > 0) {
      this.actual_subs = [];
      this.subsChartData[0].data = changes.subscriptions.currentValue.actualSubscriptions.map((subs: any) =>
        ({ x: subs.month, y: subs.quantity }));

      this.labels = changes.subscriptions.currentValue.actualSubscriptions.map((subs: any) => {
        const month = subs.month.slice(5) - 1;
        const year = (subs.month.slice(2, 4));
        const month_label = `${this.months[month]} ${year}`;

        return month_label;
      });
    }
    if (changes.subscriptions.currentValue.pastSubscriptions.length > 0) {
      this.past_subs = [];
      this.subsChartData[1].data = changes.subscriptions.currentValue.pastSubscriptions.map((subs: any) =>
        ({ x: subs.month, y: subs.quantity }));
      if (changes.subscriptions.currentValue.actualSubscriptions.length === 0) {
        this.labels = changes.subscriptions.currentValue.pastSubscriptions.map((subs: any) => {
          const month = subs.month.slice(5) - 1;
          const year = (subs.month.slice(2, 4));
          const month_label = `${this.months[month]} ${year}`;

          return month_label;
        });
      }
    }
  }

}
