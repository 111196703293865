import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { BroadcastService } from '../../../services/broadcast.service';
import { PermissionsService } from '../../../services/permissions.service';
import { PollsService } from '../../../services/polls.service';
import { SwalService } from '../../../services/swal.service';
import { PollCreateEditComponent } from './poll-create-edit/poll-create-edit.component';
import { PollShowComponent } from './poll-show/poll-show.component';
var PollsComponent = /** @class */ (function () {
    function PollsComponent(swalService, appModal, broadcast, premissionsService, pollsService) {
        this.swalService = swalService;
        this.appModal = appModal;
        this.broadcast = broadcast;
        this.premissionsService = premissionsService;
        this.pollsService = pollsService;
        this.subscriptions = [];
        this.baseDataTableConfig = {
            config: {
                base: this.pollsService,
                api: 'getPolls',
                order: [[1, 'desc']]
            },
            columns: [
                {
                    display: 'Nombre',
                    field: 'name',
                    type: 'text'
                },
                {
                    display: 'Fecha de creación',
                    field: 'created_at',
                    type: 'date'
                },
                {
                    display: 'Acciones',
                    field: '',
                    type: 'inline-button',
                    options: [
                        {
                            cssClass: 'btn btn-info',
                            icon: 'fa fa-eye',
                            event: 'poll.show',
                            conditionality: true,
                            permissions: ['show-polls']
                        },
                        {
                            cssClass: 'btn btn-info',
                            icon: 'fa fa-pencil',
                            event: 'poll.edit',
                            conditionality: true,
                            permissions: ['update-polls']
                        },
                        {
                            cssClass: 'btn btn-danger',
                            icon: 'fa fa-trash',
                            event: 'poll.delete',
                            conditionality: true,
                            permissions: ['delete-polls']
                        }
                    ]
                }
            ],
            filters: [{}
            ]
        };
    }
    PollsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.broadcast.events.subscribe(function (event) {
            switch (event.name) {
                case 'poll.edit':
                    _this.editPoll(event.data);
                    break;
                case 'poll.delete':
                    _this.deletePoll(event.data);
                    break;
                case 'poll.show':
                    _this.showPoll(event.data);
                    break;
            }
        }));
        this.dataTableConfig = this.premissionsService.filterTableConfig(this.baseDataTableConfig);
    };
    PollsComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    /**
     * createPoll
     * Abre un modal con el formulario para crear una encuesta
     */
    PollsComponent.prototype.createPoll = function () {
        var props = new SharedComponent(PollCreateEditComponent, {
            status: 'create'
        }, {
            title: 'Crear encuesta'
        });
        this.appModal.opensm(props);
    };
    /**
     * Abre un modal con el formulario para editar una encuesta
     * @param data datos de la encuesta
     */
    PollsComponent.prototype.editPoll = function (data) {
        var props = new SharedComponent(PollCreateEditComponent, {
            status: 'edit',
            pollData: data
        }, {
            title: "Editar la encuesta " + data.name
        });
        this.appModal.opensm(props);
    };
    /**
     * Abre un modal con la confirmación para borrar una encuesta
     * @param data datos de la encuesta
     */
    PollsComponent.prototype.deletePoll = function (data) {
        var _this = this;
        var swalParams = {
            title: "\u00BFEst\u00E1s seguro de querer eliminar la encuesta: " + data.name + "?"
        };
        this.swalService.warning(swalParams).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var resp;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!result.value) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.pollsService.delete(data.id_polls).toPromise()];
                    case 1:
                        resp = _a.sent();
                        if (!(resp && resp.success)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.swalService.success({ title: 'Encuesta eliminada exitosamente' })];
                    case 2:
                        _a.sent();
                        this.broadcast.reloadDataTable();
                        return [3 /*break*/, 4];
                    case 3:
                        this.swalService.error('Ocurrio un error al intentar borrar la entrada de cash');
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); });
    };
    PollsComponent.prototype.showPoll = function (data) {
        var props = new SharedComponent(PollShowComponent, {
            idPoll: data.id_polls
        }, {
            title: "Informaci\u00F3n de la encuesta " + data.name
        });
        this.appModal.opensm(props);
    };
    return PollsComponent;
}());
export { PollsComponent };
