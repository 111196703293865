import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { CashService } from '../../../../services/cash.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { SharedComponent } from '../../../../model/shared-component';
import { SwalService } from '../../../../services/swal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CashFormComponent } from './cash-form/cash-form.component';
import { PermissionsService } from '../../../../services/permissions.service';
var CashTableComponent = /** @class */ (function () {
    function CashTableComponent(activeModal, appModal, cashService, broadcast, swal, permissionsService) {
        this.activeModal = activeModal;
        this.appModal = appModal;
        this.cashService = cashService;
        this.broadcast = broadcast;
        this.swal = swal;
        this.permissionsService = permissionsService;
        this.baseDataTableConfig = {
            config: {
                base: this.cashService,
                api: 'getCash'
            },
            columns: [
                {
                    display: 'Fecha de balance',
                    field: 'balance_date',
                    type: 'date'
                },
                {
                    display: 'Balance',
                    field: 'balance',
                    type: 'currency'
                },
                {
                    display: 'Distribuidor',
                    field: 'team',
                    type: 'text',
                    onlyForTeam: [3]
                },
                {
                    display: 'Acciones',
                    field: '',
                    type: 'actions',
                    options: [
                        {
                            display: 'Editar',
                            icon: 'fa fa-pencil',
                            event: 'cash.edit',
                            conditionality: 'true',
                            permissions: ['update-cash']
                        },
                        {
                            display: 'Eliminar',
                            icon: 'fa fa-trash fa-fw',
                            event: 'cash.delete',
                            conditionality: 'true',
                            permissions: ['delete-cash']
                        }
                    ]
                }
            ]
        };
    }
    CashTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.broadcast$ = this.broadcast.events.subscribe(function (event) {
            switch (event.name) {
                case 'cash.delete':
                    _this.deleteCashItem(event.data);
                    break;
                case 'cash.edit':
                    _this.editCashItem(event.data);
                    break;
            }
        });
        this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
    };
    CashTableComponent.prototype.ngOnDestroy = function () {
        this.broadcast$.unsubscribe();
    };
    CashTableComponent.prototype.createCashItem = function (data) {
        var props = new SharedComponent(CashFormComponent, {
            cashData: data,
            status: 'create'
        }, {
            title: 'Crear entrada de cash'
        });
        this.appModal.opensm(props);
    };
    CashTableComponent.prototype.deleteCashItem = function (data) {
        var _this = this;
        var swalParams = {
            title: "\u00BFEst\u00E1s seguro de querer eliminar el balance del d\u00EDa " + data.balance_date + "?"
        };
        this.swal.warning(swalParams).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var resp;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!result.value) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.cashService.delete(data.id_cash).toPromise()];
                    case 1:
                        resp = _a.sent();
                        if (resp && resp.success) {
                            this.swal.success({ title: 'Entrada de cash eliminada exitosamente' }).then(function () {
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                        else {
                            this.swal.error('Ocurrio un error al intentar borrar la entrada de cash');
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    CashTableComponent.prototype.editCashItem = function (data) {
        // open modal, passing the context
        var props = new SharedComponent(CashFormComponent, {
            cashData: data,
            status: 'edit'
        }, {
            title: 'Entradas de cash'
        });
        this.appModal.opensm(props);
    };
    return CashTableComponent;
}());
export { CashTableComponent };
