import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var BadDebtsService = /** @class */ (function () {
    function BadDebtsService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    BadDebtsService.prototype.getBadDebtsClients = function (params, stateSelect) {
        return this.http.post(this.baseUrl + "/bad-debts/getBadDebtsClients", params);
    };
    BadDebtsService.prototype.moveToBadDebt = function (id, params) {
        return this.http.post(this.baseUrl + "/bad-debts/moveBadDebt/" + id, params);
    };
    BadDebtsService.prototype.restoreClient = function (id, params) {
        return this.http.post(this.baseUrl + "/bad-debts/restoreClient/" + id, params);
    };
    BadDebtsService.prototype.getSummary = function () {
        return this.http.get(this.baseUrl + "/bad-debts/summary");
    };
    BadDebtsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BadDebtsService_Factory() { return new BadDebtsService(i0.ɵɵinject(i1.HttpClient)); }, token: BadDebtsService, providedIn: "root" });
    return BadDebtsService;
}());
export { BadDebtsService };
