import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotificationService } from '../../../../services/notification.service';
import { FromService } from '../../../../providers/form.service';
import { SwalService } from '../../../../services/swal.service';
import { ClientsService } from '../../../../services/clients.service';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../../../services/broadcast.service';

@Component({
  selector: 'app-notify-user',
  templateUrl: './notify-user.component.html',
  styleUrls: ['./notify-user.component.scss']
})
export class NotifyUserComponent implements OnInit, OnDestroy {
  selectedClients: any = [];
  clients: any;
  message: any;
  subscriptions: Array<Subscription> = [];
  form: FormGroup = this.formBuilder.group({
    message: ['']
  });
  dataTableConfig = {
    config: {
      base: this.clientService,
      api: 'getClients'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: '',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-success',
            name: 'Seleccionar',
            event: 'notify.user',
            conditionality: 'true'
          }
        ]
      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly formBuilder: FormBuilder,
    private readonly clientService: ClientsService,
    private readonly notify: NotificationService,
    public appModal: ModalComponent,
    private readonly formService: FromService,
    private readonly swal: SwalService,
    private readonly broadcast: BroadcastService
  ) { }

  ngOnInit(): void {
    this.formService.setForm(this.form);
    this.clientService.getClients().subscribe((res: any) => {
      this.clients = res.data;
    });
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'notify.user': this.selectClient(event.data); break;
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  selectClient(selclients): void {
    if (this.selectedClients.indexOf(selclients) === -1) {
      this.selectedClients.push(selclients);
    }
  }

  notifyClient(): void {
    const params = {
      users: this.selectedClients.map(client => client.id_users),
      message: this.form.get('message').value
    };

    const swal_title = 'Estás seguro de enviar la notificación ';
    this.swal.warning({ title: swal_title }).then(resp => {
      if (resp.value) {
        this.subscriptions.push(this.notify.notifyUser(params).subscribe((data: any) => {
          if (data.success) {
            this.swal.success();
          } else {
            this.swal.error({ title: data.message });
          }
        }));
      }
    });
  }
  removeClient(clients): void {
    this.selectedClients.splice(this.selectedClients.indexOf(clients), 1);
  }
}
