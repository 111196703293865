<div class="row">
  <div class="col-sm-{{show ? 4 : 6}}">
    <p>Datos anteriores</p>
  </div>

  <div class="col-sm-{{show ? 4 : 6}}">
    <p>Datos actuales</p>
  </div>

  <div *ngIf="show" class="col-sm-4">
    <p>Info extra</p>
  </div>
</div>

<br>

<div class="row">
  <div class="col-sm-{{show ? 4 : 6}}">
    <pre>{{before_data | json}}</pre>
  </div>

  <div class="col-sm-{{show ? 4 : 6}}">
    <pre>{{after_data | json}}</pre>
  </div>

  <div *ngIf="show" class="col-sm-4">
    <p>{{extra_info | json}}</p>
  </div>
</div>