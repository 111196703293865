import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datatable-column-technician',
  templateUrl: './ticket-technician.component.html',
  styleUrls: ['./ticket-technician.component.scss']
})
export class TicketTechnicianComponent implements OnInit {
  @Input() data: any;
  assignedTechnician: any;

  ngOnInit(): void {
    if (this.data.length) {
      const assignations = [...this.data];
      assignations.sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1);
      this.assignedTechnician = {
        name: assignations[0].technician.name,
        email: assignations[0].technician.email
      };
    }
  }

}
