import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datatable-column-ticket-status',
  templateUrl: './ticket-status.component.html',
  styleUrls: ['./ticket-status.component.scss']
})
export class TicketStatusComponent implements OnInit {
  @Input() data: any;
  text: string;
  badge: string;
  ticketStatus = {
    'opened': { text: 'Abierto', badge: 'badge badge-danger' },
    'closed': { text: 'Cerrado', badge: 'badge badge-success' },
    'confirmed': { text: 'Firmado por el cliente', badge: 'badge badge-success' },
    'completed': { text: 'Completado por el técnico', badge: 'badge badge-info' },
    'unrealized': { text: 'No realizado', badge: 'badge badge-secondary' }
  };

  ngOnInit(): void {
    if (this.data.status !== 'assigned') {
      this.text = this.ticketStatus[this.data.status].text;
      this.badge = this.ticketStatus[this.data.status].badge;
    }
  }
}
