import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TicketsService } from '../../../../services/tickets.service';
import { ClientsService } from '../../../../services/clients.service';
var RecordComponent = /** @class */ (function () {
    function RecordComponent(tickets, clientService, activeModal, _historyService) {
        this.tickets = tickets;
        this.clientService = clientService;
        this.activeModal = activeModal;
        this._historyService = _historyService;
        this.subscriptions = [];
        this.historyConfig = {
            config: {
                type: 'tickets',
                base: this.tickets,
                api: 'getHistory',
                params: { history: 0 },
                order: [[0, 'desc']]
            },
            rows: [
                {
                    display: 'Estatus',
                    field: 'id'
                }
            ]
        };
        this.history = [];
    }
    RecordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getClientLastTicket();
        this.history.push(tslib_1.__assign({ ticketType: 'created' }, this.data));
        if (this.data.status === 'closed') {
            var nData = JSON.parse(JSON.stringify(this.data));
            this.history.push(tslib_1.__assign({ ticketType: 'closed' }, nData, { created_at: this.data.updated_at }));
        }
        this.subscriptions.push(this._historyService.getHistory(this.data.id_tickets).subscribe(function (data) {
            data.forEach(function (element, index) {
                var ticketType = '';
                switch (index) {
                    case 0:
                        ticketType = 'assignations';
                        break;
                    case 1:
                        ticketType = 'completions';
                        break;
                    case 2:
                        ticketType = 'confirmations';
                        break;
                    case 3:
                        ticketType = 'unrealizations';
                        break;
                }
                element.response.forEach(function (itm) {
                    _this.history.push(tslib_1.__assign({ ticketType: ticketType }, itm));
                });
                _this.sortData();
            });
        }));
    };
    RecordComponent.prototype.sortData = function () {
        return this.history.sort(function (a, b) {
            var nA = a.created_at;
            var nB = b.created_at;
            if (a.ticketType === 'closed') {
                nA = a.updated_at;
            }
            if (b.ticketType === 'closed') {
                nB = b.updated_at;
            }
            return new Date(nB) - new Date(nA);
        });
    };
    /**
     * getClientLastTicket
     * Obtiene la información del ultimo ticket del cliente.
     */
    RecordComponent.prototype.getClientLastTicket = function () {
        var _this = this;
        var params = { lastTicket: true, currentIdTicket: this.data.id_tickets };
        this.subscriptions.push(this.clientService.getClientLastTicket(this.data.id_clients, params).subscribe(function (data) {
            if (data.response && data.response.status == 'closed') {
                _this.history.push(tslib_1.__assign({ ticketType: 'previous' }, data.response));
            }
        }));
    };
    RecordComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    return RecordComponent;
}());
export { RecordComponent };
