import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-tables',
  templateUrl: './dashboard-tables.component.html',
  styleUrls: ['./dashboard-tables.component.scss']
})
export class DashboardTablesComponent implements OnInit {
  @Input('data') data: any;

  ngOnInit(): void {
    //
  }

  noOrder(): any {
    return 0;
  }
}
