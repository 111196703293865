import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FromService } from '../../../../providers/form.service';
import { SwalService } from '../../../../services/swal.service';
import { GroupsService } from '../../../../services/groups.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService } from '../../../../services/broadcast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  admin: any;
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required]
  });
  constructor(
    private readonly broadcast: BroadcastService,
    private readonly groupsService: GroupsService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    public activeModal: NgbActiveModal,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.groupsService.getClientNotification(this.data.id_notification_groups).subscribe((data: any) => {
      this.admin = data.response || this.data.user;
      Object.keys(this.admin).forEach(key => {
        if (this.admin.hasOwnProperty(key) && !!this.form.controls[key]) {
          this.form.controls[key].setValue(this.admin[key]);
        }
      });
    }));
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  updateGroup(): void {
    if (this.admin) {
      const users = [];
      let params = {};
      this.subscriptions.push(this.groupsService.getClientNotification(this.admin.id_notification_groups).subscribe((res: any) => {
        res.response.users.forEach(client => {
          users.push({
            user_id: client.id
          });
        });
      }));
      params = {
        notification_group: {
          name: this.form.value.name,
          description: this.form.value.description,
          users
        }
      };
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del grupo?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.groupsService.editClientNotification(this.admin.id_notification_groups, params).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success();
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            } else {
              this.swal.error({ title: resp.message });
            }
          }));
        }
      });
    }
  }

}
