import * as tslib_1 from "tslib";
import { SwalService } from "./swal.service";
import * as i0 from "@angular/core";
import * as i1 from "./swal.service";
var HelpersService = /** @class */ (function () {
    function HelpersService(swalService) {
        this.swalService = swalService;
    }
    /**
     * formatDate
     * Formatea una fecha.
     */
    HelpersService.prototype.formatDate = function (date) {
        var formatedDate = new Date(date + "T11:59:59");
        return formatedDate;
    };
    /**
     * flatObject
     * @param obj objeto a reducir a un solo nivel
     * @returns objeto reducido a un solo nivel
     */
    HelpersService.prototype.flatObject = function (obj) {
        var _this = this;
        var flattened = {};
        Object.keys(obj).forEach(function (key) {
            var value = obj[key];
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                Object.assign(flattened, _this.flatObject(value));
            }
            else {
                flattened[key] = value;
            }
        });
        return flattened;
    };
    HelpersService.prototype.formatPhoneNumber = function (phone) {
        var formatedPhoneNumber = phone.replace(/[^\d]/g, '');
        return formatedPhoneNumber;
    };
    HelpersService.prototype.sendByWhatsapp = function (whatsAppMessage, clientPhone) {
        if (whatsAppMessage && clientPhone) {
            var formatedClientNumber = this.formatPhoneNumber(clientPhone);
            window.open("https://api.whatsapp.com/send?phone=" + formatedClientNumber + "&text=" + encodeURIComponent(whatsAppMessage));
        }
        else {
            var data = {
                text: "No se puede enviar el mensaje\nWhatsapp:" + clientPhone + "\nMensaje:" + whatsAppMessage,
                allowOutsideClick: false,
            };
            this.swalService.error(data);
        }
    };
    /**
     * copyToClipboard
     * Copia el texto en el portapapeles del sistema
     * @param text texto a copiar en el portapapeles
     */
    HelpersService.prototype.copyToClipboard = function (text) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var data, err_1, data;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, navigator.clipboard.writeText(text)];
                    case 1:
                        _a.sent();
                        data = {
                            text: 'Texto copiado exitosamente',
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            timer: 1500
                        };
                        this.swalService.success(data);
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        data = {
                            text: "Error al copiar el texto: " + err_1,
                            allowOutsideClick: false,
                        };
                        this.swalService.error(data);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    HelpersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelpersService_Factory() { return new HelpersService(i0.ɵɵinject(i1.SwalService)); }, token: HelpersService, providedIn: "root" });
    return HelpersService;
}());
export { HelpersService };
