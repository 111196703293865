/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./assignations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../badge/badge.component.ngfactory";
import * as i4 from "../badge/badge.component";
import * as i5 from "./assignations.component";
import * as i6 from "@angular/platform-browser";
var styles_AssignationsComponent = [i0.styles];
var RenderType_AssignationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssignationsComponent, data: {} });
export { RenderType_AssignationsComponent as RenderType_AssignationsComponent };
function View_AssignationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fecha programada del servicio:"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "dd", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 1, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Horario estimado:"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "dd", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", " - ", ""])), i1.ɵppd(10, 2), i1.ɵppd(11, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), _co.item.schedule_date_start, "dd/MM/yyyy")); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 0), _co.item.schedule_date_start, "HH:mm")); var currVal_2 = i1.ɵunv(_v, 9, 1, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 0), _co.item.schedule_date_end, "HH:mm")); _ck(_v, 9, 0, currVal_1, currVal_2); }); }
export function View_AssignationsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 24, "div", [["class", "col-md-12 content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 23, "dl", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "history-ticket-badge", [], null, null, null, i3.View_BadgeComponent_0, i3.RenderType_BadgeComponent)), i1.ɵdid(4, 114688, null, 0, i4.BadgeComponent, [], { item: [0, "item"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["T\u00E9cnico"])), (_l()(), i1.ɵeld(7, 0, null, null, 12, "dd", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["class", "img-circle"]], [[8, "src", 4]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.errorHandler() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 8, "div", [["class", "col-md-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "fa fa-fw fa-lg fa-user"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "fa fa-fw fa-lg fa-phone"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), (_l()(), i1.ɵeld(17, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "i", [["class", "fa fa-fw fa-lg fa-envelope"]], null, null, null, null, null)), (_l()(), i1.ɵted(19, null, ["", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AssignationsComponent_1)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(22, 0, null, null, 1, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Detalles"])), (_l()(), i1.ɵeld(24, 0, null, null, 1, "dd", [["class", "ng-binding"]], null, null, null, null, null)), (_l()(), i1.ɵted(25, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 4, 0, currVal_0); var currVal_5 = (_co.item.schedule_date_start != null); _ck(_v, 21, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imageProfile; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.item.technician.name; _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.item.technician.phone; _ck(_v, 16, 0, currVal_3); var currVal_4 = _co.item.technician.email; _ck(_v, 19, 0, currVal_4); var currVal_6 = _co.item.details; _ck(_v, 25, 0, currVal_6); }); }
export function View_AssignationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "history-ticket-assignations", [], null, null, null, View_AssignationsComponent_0, RenderType_AssignationsComponent)), i1.ɵdid(1, 114688, null, 0, i5.AssignationsComponent, [i6.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssignationsComponentNgFactory = i1.ɵccf("history-ticket-assignations", i5.AssignationsComponent, View_AssignationsComponent_Host_0, { item: "item" }, {}, []);
export { AssignationsComponentNgFactory as AssignationsComponentNgFactory };
