import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EmailsTemplatesService = /** @class */ (function () {
    function EmailsTemplatesService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * getEmailList
     * Obtiene la lista formateada de los emails
     * @returns lista de emails
     */
    EmailsTemplatesService.prototype.getEmailsList = function () {
        return this.http.get(this.baseUrl + "/email/emails");
    };
    /**
     * Obtiene la vista del email que se quiere mostrar
     * @param params name: nombre del template, params: parametros que utiliza el template
     */
    EmailsTemplatesService.prototype.getEmailView = function (params) {
        return this.http.post(this.baseUrl + "/email/email_view", params, { responseType: 'text' });
    };
    EmailsTemplatesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmailsTemplatesService_Factory() { return new EmailsTemplatesService(i0.ɵɵinject(i1.HttpClient)); }, token: EmailsTemplatesService, providedIn: "root" });
    return EmailsTemplatesService;
}());
export { EmailsTemplatesService };
