import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { PollsService } from '../../../../services/polls.service';
import { SwalService } from '../../../../services/swal.service';
import { Subscription } from 'rxjs';
var PollCreateEditComponent = /** @class */ (function () {
    function PollCreateEditComponent(activeModal, broadcast, formBuilder, fromService, pollsService, swalService) {
        this.activeModal = activeModal;
        this.broadcast = broadcast;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.pollsService = pollsService;
        this.swalService = swalService;
        this.subscriptions = new Subscription();
        this.questionsTypes = [
            { id: 'single', name: 'single' },
            { id: 'multiple', name: 'multiple' },
            { id: 'ranking', name: 'ranking' }
        ];
        this.areas = [
            { id: 'technicians', name: 'Técnicos' },
            { id: 'staff', name: 'Staff' }
        ];
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            area: ['', Validators.required],
            questions: this.formBuilder.array([])
        });
    }
    PollCreateEditComponent.prototype.ngOnInit = function () {
        this.buttonName = this.data.status === 'create' ? 'Crear encuesta' : 'Editar encuesta';
        this.getMinDate();
        this.setData();
        this.fromService.setForm(this.form);
    };
    PollCreateEditComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.unsubscribe();
    };
    /**
     * setData
     * Determina si la vista va a ser de tipo edit o create
     * Si la vista es de tipo edit busca obtiene la información del post
     * mediante una peticion get y setea los datos en el formulario.
     */
    PollCreateEditComponent.prototype.setData = function () {
        var _this = this;
        if (this.data.status === 'edit') {
            this.subscriptions.add(this.pollsService.show(this.data.pollData.id_polls).subscribe(function (resp) {
                _this.poll = resp.response;
                var questions = _this.poll.questions;
                Object.keys(_this.poll).forEach(function (key) {
                    if (_this.poll.hasOwnProperty(key) && !!_this.form.controls[key] && ['questions'].indexOf(key) === -1) {
                        _this.form.controls[key].setValue(_this.poll[key]);
                    }
                });
                if (questions && questions.length > 0) {
                    questions.forEach(function (question, index) {
                        _this.addQuestion(question, index);
                    });
                }
            }));
        }
    };
    /**
     * save
     * Envia una petición de crear o editar dependiendo del status recibido en el data.
     */
    PollCreateEditComponent.prototype.save = function () {
        var _this = this;
        var service = this.pollsService.create(this.form.value);
        var swalParams = {
            title: '¿Esta seguro de querer guardar los datos de la encuesta?',
            text: 'La encuesta se creara con la siguiente información'
        };
        if (this.data.status === 'edit') {
            service = this.pollsService.update(this.data.pollData.id_polls, this.form.value);
            swalParams.text = 'La encuesta se modificara con la siguiente información';
        }
        if (this.form.valid) {
            this.swalService.warning(swalParams).then(function (result) {
                if (result.value) {
                    _this.subscriptions.add(service.subscribe(function (resp) {
                        if (resp.success) {
                            _this.swalService.success().then(function () {
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                        else {
                            _this.swalService.error({ title: 'Ocurió un error al guardar los datos' });
                        }
                    }));
                }
            });
        }
    };
    Object.defineProperty(PollCreateEditComponent.prototype, "questions", {
        /**
         * questions
         * Regresa las preguntas en el formulario como un FormArray
         * @returns FormArray
         */
        get: function () {
            return this.form.get('questions');
        },
        enumerable: true,
        configurable: true
    });
    /**
     * addQuestion
     * Añade al formulario las preguntas que conformaran una encuesta
     * Si el parametro question es distinto de null se setean los datos de la pregunta (usado para la vista edit)
     * @param question objeto que define la pregunta a mostrar, type: tipo de pregunta que se va a realizar.
     * @param qIndex interger para determinar el indice de la pregunta en el formarray questions
     */
    PollCreateEditComponent.prototype.addQuestion = function (question, qIndex) {
        var _this = this;
        if (question === void 0) { question = null; }
        if (qIndex === void 0) { qIndex = null; }
        var questionForm = this.formBuilder.group({
            description: ['', Validators.required],
            type: ['', Validators.required],
            question_options: this.formBuilder.array([])
        });
        if (question) {
            questionForm.controls.description.setValue(question.description);
            questionForm.controls.type.setValue(question.type);
            questionForm.addControl('id_questions', this.formBuilder.control(question.id_questions, [Validators.required]));
            this.questions.push(questionForm);
            if (question.question_options && question.question_options.length > 0) {
                question.question_options.forEach(function (option) {
                    _this.addQuestionOption(qIndex, option);
                });
            }
        }
        else {
            this.questions.push(questionForm);
            this.form.markAsDirty();
        }
    };
    /**
     * removeQuestion
     * Remueve la pregunta de la encuesta.
     * si la vista cargada es la de edit, se añade el campo removedQuestions al formulario, este campo contiene
     * el array de los id de las preguntas eliminadas.
     * @param qIndex posicion en el array de la pregunta que se desea remover del questionario.
     */
    PollCreateEditComponent.prototype.removeQuestion = function (qIndex) {
        this.questions.removeAt(qIndex);
        this.form.markAsDirty();
    };
    /**
     * questionOptions
     * Regresa las opciones de una pregunta en el formulario como un FormArray
     * @returns FormArray
     */
    PollCreateEditComponent.prototype.questionOptions = function (qIndex) {
        return this.questions.at(qIndex).get('question_options');
    };
    /**
     * addQuestionOption
     * Añade al formulario las opciones que puede llegar a tener una la pregunta de una encuesta
     * si @param option es distinto de null se setearan los datos de la opcion (utilizado para la vista edit)
     * @param qIndex interger del indice de la pregunta
     * @param option objeto que define la opcion que se añadira a una pregunta
     */
    PollCreateEditComponent.prototype.addQuestionOption = function (qIndex, option) {
        if (option === void 0) { option = null; }
        var optionForm = this.formBuilder.group({
            question_option: ['', Validators.required],
            option_value: ['', Validators.required]
        });
        if (option) {
            optionForm.controls.question_option.setValue(option.question_option);
            optionForm.controls.option_value.setValue(option.option_value);
            optionForm.addControl('id_questions_options', this.formBuilder.control(option.id_questions_options, [Validators.required]));
        }
        else {
            this.form.markAsDirty();
        }
        this.questionOptions(qIndex).push(optionForm);
    };
    /**
     * removeQuestionOption
     * Remueve la opcion asociada a una pregunta de la encuesta.
     * si la vista cargada es la de edit, se añade el campo removedQuestionOption al formulario, este campo contiene
     * el array de los id de las opciones eliminadas.
     * @param qIndex posicion en el array de la pregunta que se desea remover del questionario.
     * @param oIndex posicion en el array de la opcion dentro de la pregunta que se desea remover del questionario.
     */
    PollCreateEditComponent.prototype.removeQuestionOption = function (qIndex, oIndex) {
        this.questionOptions(qIndex).removeAt(oIndex);
        this.form.markAsDirty();
    };
    /**
     * getMinDate
     * Obtiene los valores de fecha minimos para filtrar el input del calendario
     * y evitar seleccionar fechas anteriores a la fecha actual.
     */
    PollCreateEditComponent.prototype.getMinDate = function () {
        var current = new Date();
        this.min_date = {
            year: current.getFullYear(),
            month: current.getMonth() + 1,
            day: current.getDate()
        };
    };
    return PollCreateEditComponent;
}());
export { PollCreateEditComponent };
