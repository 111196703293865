/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./record.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../components/history/history.component.ngfactory";
import * as i3 from "../../../../components/history/history.component";
import * as i4 from "./record.component";
import * as i5 from "../../../../services/tickets.service";
import * as i6 from "../../../../services/clients.service";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
var styles_RecordComponent = [i0.styles];
var RenderType_RecordComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RecordComponent, data: {} });
export { RenderType_RecordComponent as RenderType_RecordComponent };
export function View_RecordComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-history", [["type", "tickets"]], null, null, null, i2.View_HistoryComponent_0, i2.RenderType_HistoryComponent)), i1.ɵdid(2, 114688, null, 0, i3.HistoryComponent, [], { type: [0, "type"], items: [1, "items"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("close button") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancelar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tickets"; var currVal_1 = _co.history; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_RecordComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-record", [], null, null, null, View_RecordComponent_0, RenderType_RecordComponent)), i1.ɵdid(1, 245760, null, 0, i4.RecordComponent, [i5.TicketsService, i6.ClientsService, i7.NgbActiveModal, i5.TicketsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecordComponentNgFactory = i1.ɵccf("app-record", i4.RecordComponent, View_RecordComponent_Host_0, { data: "data" }, {}, []);
export { RecordComponentNgFactory as RecordComponentNgFactory };
