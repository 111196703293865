<div *ngIf="no_cards">
    <span class="badge badge-default">Sin tarjeta</span>
</div>
<div *ngIf="valid">
    <span class="badge badge-success">{{ valid }} Vigente(s)</span>
</div>
<div *ngIf="forexpire">
    <span class="badge badge-warning">{{ forexpire }} Por expirar</span>
</div>
<div *ngIf="expired">
    <span class="badge badge-danger">{{ expired }} Expirada(s)</span>
</div>
