import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { CardsService } from '../../../../services/cards.service';
import { SwalService } from '../../../../services/swal.service';
var CardCreateComponent = /** @class */ (function () {
    function CardCreateComponent(activeModal, appModal, broadcast, formBuilder, fromService, cardsService, swalService) {
        this.activeModal = activeModal;
        this.appModal = appModal;
        this.broadcast = broadcast;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.cardsService = cardsService;
        this.swalService = swalService;
        this.months = [];
        this.years = [];
        this.subscriptions = [];
        this.form = this.formBuilder.group({
            card_number: ['', [Validators.required, Validators.minLength(15), Validators.maxLength(16)]],
            cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
            expiration_month: ['', Validators.required],
            expiration_year: ['', Validators.required]
        });
    }
    CardCreateComponent.prototype.ngOnInit = function () {
        var cardsInfo = this.cardsService.setDateCardInfo();
        this.months = cardsInfo.months;
        this.years = cardsInfo.years;
        this.cardsService.setVendors();
        this.fromService.setForm(this.form);
    };
    CardCreateComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    /**
     * createCard
     * Envia una petición POST a la API con los tokens de la tarjeta para asociarla al cliente
     */
    CardCreateComponent.prototype.createCard = function () {
        var _this = this;
        this.registerCard().then(function (response) {
            if (response.status && response.data) {
                var params = _this.formatParams(response);
                _this.subscriptions.push(_this.cardsService.create(_this.data.id_clients, params).subscribe(function (resp) {
                    if (resp.success) {
                        _this.swalService.success({ text: 'Tarjeta registrada exitosamente' }).then(function () {
                            _this.activeModal.dismiss();
                            _this.broadcast.reloadDataTable();
                        });
                    }
                    else {
                        _this.swalService.error();
                    }
                }));
            }
            else {
                var message = response.data ? response.data : 'Ocurrio un error al validar los datos de la tarjeta';
                _this.swalService.error({ text: message });
            }
        });
    };
    /**
     * formatParams
     * Genera un Object que con el formato necesario para poder enviar los datos de los tokens de la tarjeta a la API
     * @param response respuesta con los diferentes tokens de las tarjetas
     * @returns Object
     */
    CardCreateComponent.prototype.formatParams = function (response) {
        var tokens = {};
        var params = {};
        Object.keys(response.data).forEach(function (key) {
            if (key !== 'openpay_device_session_id') {
                tokens[key] = response.data[key];
            }
        });
        Object.assign(params, { token: tokens });
        if (response.data.hasOwnProperty('openpay_device_session_id')) {
            Object.assign(params, { deviceSessionId: response.data.openpay_device_session_id });
        }
        return params;
    };
    /**
     * registerCard
     * Evalua si la información introducida en la seccion de la tarjeta es valida:
     * + Número de tarjeta valido
     * + CVC valido
     * + Fecha de expiración
     * Si la validación de la tarjeta no devuelve ningún error se ejecuta la función para
     * obtener los tokens (CONEKTA, Openpay) de las tarjetas
     * @returns Objeto con la información si la tarjeta se registro o no.
     */
    CardCreateComponent.prototype.registerCard = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, cardValidationError, cardTokens;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        response = { status: true, added_card: false };
                        if (!this.form.dirty) return [3 /*break*/, 3];
                        cardValidationError = this.cardsService.cardValidation(this.form.value);
                        if (!!cardValidationError) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.setCardTokens()];
                    case 1:
                        cardTokens = _a.sent();
                        if (Object.keys(cardTokens).length > 0) {
                            response.added_card = true;
                            Object.assign(response, { data: cardTokens });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        response.status = false;
                        Object.assign(response, { data: cardValidationError });
                        _a.label = 3;
                    case 3: return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * setCardTokens
     * Envia la información capturada en el formulario de la tarjeta a los vendors
     * para asi generar los tokens
     * @returns objeto con los tokens de cada vendor
     */
    CardCreateComponent.prototype.setCardTokens = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tokens, clientData, carData, allTokens;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tokens = {};
                        clientData = {
                            name: this.data.name,
                            address: this.data.address,
                            outdoor_number: this.data.outdoor_number,
                            inside_number: this.data.inside_number,
                            phone: this.data.phone,
                            email: this.data.email,
                            between_streets: this.data.between_streets,
                            colony: this.data.colony,
                            postal_code: this.data.postal_code,
                            state: this.data.state,
                            county: this.data.county
                        };
                        carData = this.form.value;
                        return [4 /*yield*/, this.cardsService.getTokens(clientData, carData).toPromise()];
                    case 1:
                        allTokens = _a.sent();
                        allTokens.forEach(function (token) {
                            if ('success' in token && token.success) {
                                delete token.success;
                                Object.assign(tokens, token);
                            }
                        });
                        return [2 /*return*/, tokens];
                }
            });
        });
    };
    return CardCreateComponent;
}());
export { CardCreateComponent };
