import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CashService } from '../../../../../services/cash.service';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FromService } from '../../../../../providers/form.service';
import { SwalService } from '../../../../../services/swal.service';
import { BroadcastService } from '../../../../../services/broadcast.service';
import { TeamsService } from '../../../../../services/teams.service';

@Component({
  selector: 'app-cash-form',
  templateUrl: './cash-form.component.html',
  styleUrls: ['./cash-form.component.scss']
})
export class CashFormComponent implements OnInit {
  @Input() data: any;
  cashItem: any;
  form: FormGroup = this.formBuilder.group({
    balance_date: ['', Validators.required],
    balance: ['', Validators.required]
  });
  teams: Array<any>;
  superAdmin: boolean = JSON.parse(localStorage.getItem('user')).team_id === 3;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly cashService: CashService,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService,
    private readonly teamService: TeamsService
  ) { }

  async ngOnInit(): Promise<any> {
    this.fromService.setForm(this.form);
    if (this.superAdmin) {
      try {
        this.form.addControl('team_id', new FormControl(['', Validators.required]));
        const resp = await this.teamService.index().toPromise();
        if (resp && resp.success) {
          this.teams = resp.response;
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      if (this.form.get('team_id')) {
        this.form.removeControl('team_id');
      }
    }

    if (this.data.status === 'edit') {
      try {
        const resp = await this.cashService.show(this.data.cashData.id_cash).toPromise();
        if (resp && resp.success) {
          this.cashItem = resp.response;
          Object.keys(this.cashItem).forEach(key => {
            if (this.cashItem.hasOwnProperty(key) && !!this.form.controls[key]) {
              this.form.controls[key].setValue(this.cashItem[key]);
            }
          });
        }
      } catch (error) {
        // console.log(error.message);
      }
    }
  }

  save(): void {
    if (this.data.status === 'edit') {
      this.updateCashItem();
    }

    if (this.data.status === 'create') {
      this.saveCashItem();
    }
  }

  saveCashItem(): void {
    // this.form.get('category').value;
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar el siguiente balance?' }).then(async result => {
        if (result.value) {
          const resp = await this.cashService.create(this.form.value).toPromise();
          if (resp && resp.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurió un error al guardar los datos' });
          }
        }
      });
    }
  }

  updateCashItem(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos de este balance?' }).then(async result => {
        if (result.value) {
          const resp = await this.cashService.edit(this.data.cashData.id_cash, this.form.value).toPromise();
          if (resp.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
          }
        }
      });
    }
  }

  // tslint:disable-next-line: prefer-function-over-method
  private teamValidator(): ValidatorFn {
    return JSON.parse(localStorage.getItem('user')).team_id === 3 ? Validators.required : null;
  }
}
