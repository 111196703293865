import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { BadDebtsService } from '../../../services/bad-debts.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { ClientsService } from '../../../services/clients.service';
import { DebtsService } from '../../../services/debts.service';
import { PermissionsService } from '../../../services/permissions.service';
import { SwalService } from '../../../services/swal.service';
import { DebtsComponent } from './debts/debts.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit, OnDestroy {
  baseUrl = `${environment.apiUrl}`;
  dataTableConfig: any;
  subscriptions: Array<Subscription> = [];

  private readonly baseDataTableConfig = {
    config: {
      base: this.clientsService,
      api: 'getClients',
      params: {
        no_bad_debts: 0,
        debts: true
      },
      order: [[4, 'desc']]
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'client_data'
      },
      {
        display: 'Suscripción',
        field: 'subscription_status',
        type: 'label',
        orderable: false,
        options: {
          emptyText: 'Faltante'
        }
      },
      {
        display: 'Tarjeta por expirar',
        field: 'cards',
        type: 'card-expire',
        orderable: false
      },
      {
        display: 'Sig. Pago',
        field: 'next_payday',
        type: 'date',
        orderable: false
      },
      {
        display: 'Balance MXN',
        field: 'debt',
        type: 'charge_amount'
      },
      {
        display: '',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-primary',
            name: 'Deudas',
            event: 'client.debts',
            conditionality: 'true',
            permissions: ['list-debts', 'list-charges']
          },
          {
            cssClass: 'btn btn-danger',
            name: 'Mover a mala deuda',
            event: 'client.moveToBadDebt',
            conditionality: '(this.data.status === "accepted" || this.data.status === "canceled") && this.data.bad_debt === 0 && this.data.debt > 0',
            permissions: ['move-bad-debts']
          }
        ]
      }
    ],
    filters: [
      {
        title: 'Estatus',
        cssClass: 'col-lg-5 col-md-12 col-sm-12',
        type: 'card_status',
        options: [
          {
            val: '',
            name: 'TODOS'

          },
          {
            val: 'valid',
            name: 'Vigente'

          },
          {
            val: 'by_expire',
            name: 'Por expirar'

          },
          {
            val: 'expired',
            name: 'Expirada'

          },
          {
            val: 'no_card',
            name: 'Sin tarjeta'

          }
        ]
      }
    ]
  };

  constructor(
    public appModal: ModalComponent,
    private readonly broadcast: BroadcastService,
    private readonly badDebts: BadDebtsService,
    private readonly clientsService: ClientsService,
    private readonly debtsService: DebtsService,
    private readonly permissionsService: PermissionsService,
    public swalService: SwalService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'client.debts': this.debtsItem(event.data); break;
        case 'client.moveToBadDebt': this.moveToBadDebtItem(event.data); break;
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * generateDebtsReport
   * Genera el reporte de todos los clientes con deuda.
   */
  generateDebtsReport(): void {
    this.subscriptions.push(this.debtsService.generateDebtsReport().subscribe((resp: any) => {
      window.open(`${this.baseUrl}/reports/${resp.response}`, '_blank');
    }));
  }

  debtsItem(data): void {
    // open modal, passing the context
    const props: SharedComponent = new SharedComponent(DebtsComponent, data, { title: `${data.name}` });
    this.appModal.open(props);
  }

  moveToBadDebtItem(data): void {
    const swalParams = {
      title: 'Mover a mala deuda',
      text: 'Favor de proporcionar las razones por las que el cliente sera movido a mala deuda',
      inputPlaceholder: 'Razones'
    };

    this.swalService.input(swalParams).then((resp: any) => {
      if (resp.value) {
        this.subscriptions.push(this.badDebts.moveToBadDebt(data.id_clients, { reason: resp.value }).subscribe((response: any) => {
          if (response.success) {
            this.swalService.success().then(() => {
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swalService.error();
          }
        }));
      }
    });
  }
}
