import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<ngx-loading-bar></ngx-loading-bar><router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  
  constructor(private readonly router: Router) { }

  ngOnInit(): void {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
