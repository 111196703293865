/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./inline-button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./inline-button.component";
import * as i4 from "../../../../../services/broadcast.service";
var styles_InlineButtonComponent = [i0.styles];
var RenderType_InlineButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InlineButtonComponent, data: {} });
export { RenderType_InlineButtonComponent as RenderType_InlineButtonComponent };
function View_InlineButtonComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.parent.context.$implicit.icon, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_InlineButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [], [[8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.fireEvent(_v.parent.context.$implicit.event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InlineButtonComponent_3)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit["icon"]; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.cssClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.name; _ck(_v, 3, 0, currVal_2); }); }
function View_InlineButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InlineButtonComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible(_v.context.$implicit.conditionality); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InlineButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InlineButtonComponent_1)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.options; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InlineButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inline-button", [], null, null, null, View_InlineButtonComponent_0, RenderType_InlineButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.InlineButtonComponent, [i4.BroadcastService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InlineButtonComponentNgFactory = i1.ɵccf("app-inline-button", i3.InlineButtonComponent, View_InlineButtonComponent_Host_0, { data: "data", options: "options", trRef: "trRef" }, {}, []);
export { InlineButtonComponentNgFactory as InlineButtonComponentNgFactory };
