import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var GroupsService = /** @class */ (function () {
    function GroupsService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    GroupsService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/groups", params);
    };
    GroupsService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/groups/" + id);
    };
    GroupsService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/groups/" + id);
    };
    GroupsService.prototype.update = function (id, params) {
        return this.http.put(this.baseUrl + "/groups/" + id, params);
    };
    GroupsService.prototype.getGroups = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/groups/get_groups", params);
    };
    GroupsService.prototype.listGroups = function (params) {
        return this.http.get(this.baseUrl + "/groups", params);
    };
    GroupsService.prototype.getNotifications = function (params) {
        return this.http.post(this.baseUrl + "/notification_groups/get", params);
    };
    GroupsService.prototype.saveNotificationGroup = function (params) {
        return this.http.post(this.baseUrl + "/notification_groups/save", params);
    };
    GroupsService.prototype.getClientNotification = function (id, params) {
        return this.http.post(this.baseUrl + "/notification_groups/get_users/" + id, params);
    };
    GroupsService.prototype.editClientNotification = function (id, params) {
        return this.http.post(this.baseUrl + "/notification_groups/edit/" + id, params);
    };
    GroupsService.prototype.deleteGroup = function (id) {
        return this.http.delete(this.baseUrl + "/notification_groups/" + id);
    };
    /**
     * flatGroup
     * Simplifica la estructura de los grupos con los planes para unicamente devolver un array bidimesional
     */
    GroupsService.prototype.flatGroups = function (groups) {
        var group_object = {};
        var flatted_groups = [];
        groups.forEach(function (group) {
            if (group.plans.length > 0) {
                group.plans.forEach(function (plan) {
                    group_object = { id: plan.id_groups, name: plan.name };
                });
            }
            else {
                group_object = { id: group.id_groups, name: group.name };
            }
            flatted_groups.push(group_object);
        });
        return flatted_groups;
    };
    GroupsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GroupsService_Factory() { return new GroupsService(i0.ɵɵinject(i1.HttpClient)); }, token: GroupsService, providedIn: "root" });
    return GroupsService;
}());
export { GroupsService };
