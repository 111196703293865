<br>
<div class="container">
    <ngb-tabset>
        <ngb-tab title="Datos Generales">
            <ng-template ngbTabContent>
                <div class="row">
                    <div class="col">
                        <app-form-field type="input" [options]="{label: 'Nombre', path: 'name'}"></app-form-field>
                        <div *ngIf="role.role == 'Technician'">
                            <app-form-field type="image"
                            [options]="{
                                label: 'Imagen',
                                path: 'image64',
                                image: this.image_profile
                            }"></app-form-field>
                        </div>
                        <app-form-field type="mask" [options]="{label: 'Teléfono', mask:'(00) 0000-0000', path: 'phone'}"></app-form-field>
                        <app-form-field type="email" [options]="{label: 'Email', path: 'email'}"></app-form-field>
                        <app-form-field type="password" [options]="{label: 'Contraseña', path: 'password'}"></app-form-field>
                        <pre>{{form.password}}</pre>
                        <div *ngIf="form.controls.password.dirty">
                            <app-form-field type="password" [options]="{label: 'Confirmar contraseña', path: 'password_confirm'}"></app-form-field>
                        </div>
                        <app-form-field type="switch" [options]="{
                                label: 'Estatus', 
                                path: 'status', 
                                trueValue: 1, 
                                falseValue: 0, 
                                trueCaption: 'Activo',
                                falseCaption: 'Inactivo'}">
                        </app-form-field>
                        <!-- <app-form-field type="input" [options]="{label: 'Equipo', path: 'team_id'}"></app-form-field> -->
                    </div>

                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
    <br>
    <div class="modal-footer">
        <div *ngIf="this.form.dirty">
            <button type="button" class="btn btn-outline-danger"
                (click)="activeModal.dismiss('Cross click')">Cancelar</button>
            <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
                (click)="save()">Guardar</button>
        </div>
        <div *ngIf="!this.form.dirty">
            <button type="button" class="btn btn-outline-dark"
                (click)="activeModal.dismiss('Cross click')">Cerrar</button>
        </div>
    </div>
</div>