import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SatisfactionService = /** @class */ (function () {
    function SatisfactionService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    SatisfactionService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/admin_dashboard/satisfaction", params);
    };
    SatisfactionService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/admin_dashboard/satisfaction/" + id);
    };
    SatisfactionService.prototype.edit = function (id, params) {
        return this.http.put(this.baseUrl + "/admin_dashboard/satisfaction/" + id, params);
    };
    SatisfactionService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/admin_dashboard/satisfaction/" + id);
    };
    SatisfactionService.prototype.index = function (params) {
        return this.http.get(this.baseUrl + "/admin_dashboard/satisfaction", { params: params });
    };
    SatisfactionService.prototype.getSatisfaction = function (params) {
        return this.http.post(this.baseUrl + "/admin_dashboard/satisfaction/get-satisfaction", params);
    };
    SatisfactionService.prototype.getLast = function () {
        return this.http.get(this.baseUrl + "/admin_dashboard/satisfaction/last");
    };
    SatisfactionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SatisfactionService_Factory() { return new SatisfactionService(i0.ɵɵinject(i1.HttpClient)); }, token: SatisfactionService, providedIn: "root" });
    return SatisfactionService;
}());
export { SatisfactionService };
