import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CommissionsService = /** @class */ (function () {
    function CommissionsService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    CommissionsService.prototype.getCommissionsTable = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
            // params.from = filters.from;
            // params.to = filters.to;
            // params.is_active = filters.is_active;
            // params.paid_at = filters.paid_at;
            // params.level = filters.level;
        }
        return this.http.post(this.baseUrl + "/commissions/getCommissions", params);
    };
    CommissionsService.prototype.getDeclined = function (id) {
        return this.http.get(this.baseUrl + "/commissions/getDeclined/" + id);
    };
    CommissionsService.prototype.registerAsPaid = function (id, params) {
        return this.http.post(this.baseUrl + "/commissions/" + id + "/registerAsPaid", params);
    };
    CommissionsService.prototype.clientCommissions = function (id) {
        var commissions = this.http.get(this.baseUrl + "/commissions/getCommissions/" + id);
        var maxCommissions = this.http.get(this.baseUrl + "/commissions/getLoosingCommissions/" + id);
        return forkJoin([commissions, maxCommissions]);
    };
    CommissionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CommissionsService_Factory() { return new CommissionsService(i0.ɵɵinject(i1.HttpClient)); }, token: CommissionsService, providedIn: "root" });
    return CommissionsService;
}());
export { CommissionsService };
