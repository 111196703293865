import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class LeadSourcesService {
    private readonly baseUrl = `${environment.apiUrl}`;
    constructor(private readonly http: HttpClient) { }

    /**
     * index
     * Devuelve todos los leadsources activos e inactivos en el sistema
     * @param params all indica que se devolveran todos los leadsources.
     * @returns 200 | 404 | 500
     */
    index(params?): Observable<any> {
        return this.http.get(`${this.baseUrl}/leadsources`, {params});
    }

    /**
     * create
     * Registra un nuevo leadsource en la BD
     * @param params name 
     * @returns 200 | 400 | 500
     */
    create(params): Observable<any> {
        return this.http.post(`${this.baseUrl}/leadsources`, params);
    }

    /**
     * update
     * Actualiza el estado o nombre de un leadsource
     * @param params id_leadsources, name, status
     * @returns 200 | 404 | 500
     */
    update(params): Observable<any> {
        return this.http.put(`${this.baseUrl}/leadsources/update`, params);
    }

    /**
     * getLeadSourcesTable
     * @param params con los que se cargara inicialmente la tabla
     * @param filters status para obtener datos mas especificos
     * @returns array con los datos para la tabla
     */
    getLeadSourcesTable(params, filters): Observable<any> {
        if (filters) {
            Object.assign(params, filters);
        }

        return this.http.post(`${this.baseUrl}/leadsources/leadSourcesTable`, params);
    }
}
