import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'history-ticket-unrealizations',
  templateUrl: './unrealizations.component.html',
  styleUrls: ['./unrealizations.component.scss']
})
export class UnrealizationsComponent implements OnInit {
  @Input('item') item: any;
  
  ngOnInit(): void {
    // 
  }

}
