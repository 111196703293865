import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// Services
import { FromService } from '../../../../providers/form.service';
import { PermissionsService } from '../../../../services/permissions.service';
import { SwalService } from '../../../../services/swal.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { RolesService } from '../../../../services/roles.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-role-create-edit',
  templateUrl: './role-create-edit.component.html',
  styleUrls: ['./role-create-edit.component.scss']
})
export class RoleCreateEditComponent implements OnInit, OnDestroy {
  @Input() data: any;
  admin: any;

  roles: any;
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    display_name: ['', Validators.required],
    description: [''],
    permissions: [[]]
  });

  dataTableConfig = {
    config: {
      base: this.permissionsService,
      api: 'fetchPermissions'
    },
    columns: [
      {
        display: 'Permiso',
        field: 'display_name',
        type: 'text'
      },
      {
        display: '',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-success',
            name: 'Seleccionar',
            event: 'notify.permission',
            conditionality: 'true'
          }
        ]
      }
    ]
  };

  broadcast$: Subscription;

  constructor(
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly permissionsService: PermissionsService,
    private readonly rolesService: RolesService,
    private readonly swal: SwalService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.broadcast$ = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'notify.permission': this.addPermission(event.data); break;
      }
    });
    if (this.data.status === 'edit') {
      this.rolesService.show(this.data.role.id).subscribe((data: any) => {
        if (data && data.success) {
          this.form.patchValue(data.response);
        }

        // this.userService.getImageProfile(this.admin.id).subscribe((resp: any) => {
        //   this.image_profile = `${this.baseUrl}/profiles/${resp.response}`;
        // });
      });
    }
    // this.phoneValidator(this.role.role);
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    this.broadcast$.unsubscribe();
  }

  save(): void {
    if (this.data.status === 'edit') {
      this.updateRole(this.data.role.id);
    }

    if (this.data.status === 'create') {
      this.saveRole();
    }
  }

  saveRole(): void {

    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar los datos del rol?' }).then(result => {
        if (result.value) {
          const formData = { ...this.form.value };
          const permissionsIds = this.form.controls.permissions.value.map(permission => permission.id);
          formData.permissions = permissionsIds;
          this.rolesService.createRole(formData).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.form.reset();
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            }
          });
        } else {
          this.swal.error({ title: 'Ocurrió un error al crear el rol' });
        }
      });
    }
  }

  updateRole(rolId): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del rol?' }).then(result => {
        if (result.value) {
          this.rolesService.updateRole(rolId, this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurrió un error al actualizar los datos' });
            }
          });
        }
      });
    }
  }

  addPermission(selectedPermission: any): void {
    if (!this.form.controls.permissions.value.length ||
      !this.form.controls.permissions.value.find(permission => permission.id === selectedPermission.id)) {
      this.form.controls.permissions.setValue([...this.form.controls.permissions.value, selectedPermission]);
      this.form.markAsDirty();
    } else {
      this.swal.error({ title: 'Ya tiene este permiso.', text: '' });
    }
  }

  removePermission(permissionId: any): void {
    const actualPermissions = this.form.controls.permissions.value.filter(permission => permission.id !== permissionId);
    this.form.controls.permissions.setValue(actualPermissions);
    this.form.markAsDirty();
  }

}
