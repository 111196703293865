import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetFieldPipe } from './get-field.pipe';
import { CustomDatePipe, DateDiffPipe } from './custom-date.pipe';

const pipes = [
  GetFieldPipe,
  CustomDatePipe,
  DateDiffPipe
];
@NgModule({
  declarations: [...pipes],
  imports: [
    CommonModule
  ],
  exports: [...pipes]
})
export class PipesModule { }
