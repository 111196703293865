import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { PollsService } from '../../../../services/polls.service';
import { SwalService } from '../../../../services/swal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-poll-create-edit',
  templateUrl: './poll-create-edit.component.html',
  styleUrls: ['./poll-create-edit.component.scss']
})
export class PollCreateEditComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions = new Subscription();
  poll: any;
  min_date: {};
  questionsTypes = [
    { id: 'single', name: 'single' },
    { id: 'multiple', name: 'multiple' },
    { id: 'ranking', name: 'ranking' }
  ];
  areas = [
    { id: 'technicians', name: 'Técnicos' },
    { id: 'staff', name: 'Staff' }
  ];
  buttonName: string;
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    area: ['', Validators.required],
    questions: this.formBuilder.array([])
  });
  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly pollsService: PollsService,
    private readonly swalService: SwalService
  ) { }

  ngOnInit(): void {
    this.buttonName = this.data.status === 'create' ? 'Crear encuesta' : 'Editar encuesta';
    this.getMinDate();
    this.setData();
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * setData
   * Determina si la vista va a ser de tipo edit o create
   * Si la vista es de tipo edit busca obtiene la información del post
   * mediante una peticion get y setea los datos en el formulario.
   */
  setData(): void {
    if (this.data.status === 'edit') {
      this.subscriptions.add(this.pollsService.show(this.data.pollData.id_polls).subscribe((resp: any) => {
        this.poll = resp.response;
        const questions = this.poll.questions;
        Object.keys(this.poll).forEach(key => {
          if (this.poll.hasOwnProperty(key) && !!this.form.controls[key] && ['questions'].indexOf(key) === -1) {
            this.form.controls[key].setValue(this.poll[key]);
          }
        });

        if (questions && questions.length > 0) {
          questions.forEach((question, index) => {
            this.addQuestion(question, index);
          });
        }
      }));

    }
  }

  /**
   * save
   * Envia una petición de crear o editar dependiendo del status recibido en el data.
   */
  save(): void {
    let service = this.pollsService.create(this.form.value);
    const swalParams = {
      title: '¿Esta seguro de querer guardar los datos de la encuesta?',
      text: 'La encuesta se creara con la siguiente información'
    };

    if (this.data.status === 'edit') {
      service = this.pollsService.update(this.data.pollData.id_polls, this.form.value);
      swalParams.text = 'La encuesta se modificara con la siguiente información';
    }

    if (this.form.valid) {
      this.swalService.warning(swalParams).then(result => {
        if (result.value) {
          this.subscriptions.add(service.subscribe((resp: any) => {
            if (resp.success) {
              this.swalService.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swalService.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

  /**
   * questions
   * Regresa las preguntas en el formulario como un FormArray
   * @returns FormArray 
   */
  get questions(): FormArray {
    return this.form.get('questions') as FormArray;
  }

  /**
   * addQuestion
   * Añade al formulario las preguntas que conformaran una encuesta
   * Si el parametro question es distinto de null se setean los datos de la pregunta (usado para la vista edit)
   * @param question objeto que define la pregunta a mostrar, type: tipo de pregunta que se va a realizar.
   * @param qIndex interger para determinar el indice de la pregunta en el formarray questions
   */
  addQuestion(question = null, qIndex: number = null): void {
    const questionForm = this.formBuilder.group({
      description: ['', Validators.required],
      type: ['', Validators.required],
      question_options: this.formBuilder.array([])
    });

    if (question) {
      questionForm.controls.description.setValue(question.description);
      questionForm.controls.type.setValue(question.type);
      questionForm.addControl('id_questions', this.formBuilder.control(question.id_questions, [Validators.required]));

      this.questions.push(questionForm);
      if (question.question_options && question.question_options.length > 0) {
        question.question_options.forEach(option => {
          this.addQuestionOption(qIndex, option);
        });
      }
    } else {
      this.questions.push(questionForm);
      this.form.markAsDirty();
    }
  }

  /**
   * removeQuestion
   * Remueve la pregunta de la encuesta.
   * si la vista cargada es la de edit, se añade el campo removedQuestions al formulario, este campo contiene
   * el array de los id de las preguntas eliminadas.
   * @param qIndex posicion en el array de la pregunta que se desea remover del questionario.
   */
  removeQuestion(qIndex: number): void {
    this.questions.removeAt(qIndex);
    this.form.markAsDirty();
  }

  /**
   * questionOptions
   * Regresa las opciones de una pregunta en el formulario como un FormArray
   * @returns FormArray 
   */
  questionOptions(qIndex: number): FormArray {
    return this.questions.at(qIndex).get('question_options') as FormArray;
  }

  /**
   * addQuestionOption
   * Añade al formulario las opciones que puede llegar a tener una la pregunta de una encuesta
   * si @param option es distinto de null se setearan los datos de la opcion (utilizado para la vista edit)
   * @param qIndex interger del indice de la pregunta
   * @param option objeto que define la opcion que se añadira a una pregunta
   */
  addQuestionOption(qIndex: number, option = null): void {
    const optionForm = this.formBuilder.group({
      question_option: ['', Validators.required],
      option_value: ['', Validators.required]
    });

    if (option) {
      optionForm.controls.question_option.setValue(option.question_option);
      optionForm.controls.option_value.setValue(option.option_value);
      optionForm.addControl('id_questions_options', this.formBuilder.control(option.id_questions_options, [Validators.required]));
    } else {
      this.form.markAsDirty();
    }

    this.questionOptions(qIndex).push(optionForm);
    
  }

  /**
   * removeQuestionOption
   * Remueve la opcion asociada a una pregunta de la encuesta.
   * si la vista cargada es la de edit, se añade el campo removedQuestionOption al formulario, este campo contiene
   * el array de los id de las opciones eliminadas.
   * @param qIndex posicion en el array de la pregunta que se desea remover del questionario.
   * @param oIndex posicion en el array de la opcion dentro de la pregunta que se desea remover del questionario.
   */
  removeQuestionOption(qIndex: number, oIndex: number): void {
    this.questionOptions(qIndex).removeAt(oIndex);
    this.form.markAsDirty();
  }

  /**
   * getMinDate
   * Obtiene los valores de fecha minimos para filtrar el input del calendario 
   * y evitar seleccionar fechas anteriores a la fecha actual. 
   */
  getMinDate(): void {
    const current = new Date();
    this.min_date = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }
}
