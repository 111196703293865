import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { NgxPermissionsService } from 'ngx-permissions';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "ngx-permissions";
import * as i2 from "@angular/common/http";
var PermissionsService = /** @class */ (function () {
    function PermissionsService(permissionsService, http) {
        this.permissionsService = permissionsService;
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    PermissionsService.prototype.fetchPermissions = function (params) {
        return this.http.post(this.baseUrl + "/permissions/get_permissions", params);
    };
    PermissionsService.prototype.setPermissions = function (permissions) {
        if (permissions) {
            this.permissionsService.flushPermissions();
            this.permissionsService.loadPermissions(permissions);
        }
    };
    PermissionsService.prototype.getPermissions = function () {
        return this.permissionsService.getPermissions();
    };
    PermissionsService.prototype.hasPermission = function (permission) {
        return this.permissionsService.getPermission(permission);
    };
    PermissionsService.prototype.filterTableConfig = function (configuration) {
        var _this = this;
        var user = JSON.parse(localStorage.getItem('user'));
        var finalConfig = configuration;
        // Filter actions columns based on user's permissions
        if (!configuration.columns) {
            return configuration;
        }
        var actionColumns = configuration.columns.filter(function (column) { return (!column.onlyForTeam ||
            (column.onlyForTeam && column.onlyForTeam.includes(user.team_id))) &&
            (column.type === 'actions' || column.type === 'inline-button'); });
        var commonColumns = configuration.columns.filter(function (column) { return (!column.onlyForTeam ||
            (column.onlyForTeam && column.onlyForTeam.includes(user.team_id)))
            && (!['actions', 'inline-button'].includes(column.type)); });
        var finalActionColumns = tslib_1.__assign({}, actionColumns[0]);
        finalActionColumns.options = [];
        actionColumns.map(function (column) {
            column.options.map(function (option) {
                if (Array.isArray(option)) {
                    var _arrayOptions_1 = [];
                    option.map(function (action) {
                        if (!action.onlyForTeam || (action.onlyForTeam && action.onlyForTeam.includes(user.team_id))) {
                            if (action.permissions && action.permissions.length) {
                                for (var i = 0; i < action.permissions.length; i++) {
                                    if (_this.hasPermission(action.permissions[i])) {
                                        _arrayOptions_1.push(action);
                                        break;
                                    }
                                }
                            }
                            else {
                                _arrayOptions_1.push(action);
                            }
                        }
                    });
                    finalActionColumns.options.push(_arrayOptions_1);
                }
                else {
                    if (!option.onlyForTeam || (option.onlyForTeam && option.onlyForTeam.includes(user.team_id))) {
                        if (option.permissions && option.permissions.length) {
                            for (var i = 0; i < option.permissions.length; i++) {
                                if (_this.hasPermission(option.permissions[i])) {
                                    finalActionColumns.options.push(option);
                                    break;
                                }
                            }
                        }
                        else {
                            finalActionColumns.options.push(option);
                        }
                    }
                }
            });
        });
        if (finalActionColumns.options.length > 0) {
            commonColumns.push(finalActionColumns);
        }
        // commonColumns.push(finalActionColumns);
        finalConfig.columns = commonColumns;
        return finalConfig;
    };
    PermissionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PermissionsService_Factory() { return new PermissionsService(i0.ɵɵinject(i1.NgxPermissionsService), i0.ɵɵinject(i2.HttpClient)); }, token: PermissionsService, providedIn: "root" });
    return PermissionsService;
}());
export { PermissionsService };
