import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SuppliesService } from '../../../../services/supplies.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FromService } from '../../../../providers/form.service';
import { SwalService } from '../../../../services/swal.service';
import { BroadcastService } from '../../../../services/broadcast.service';

@Component({
  selector: 'app-supplies-categories-create-edit',
  templateUrl: './supplies-categories-create-edit.component.html',
  styleUrls: ['./supplies-categories-create-edit.component.scss']
})
export class SuppliesCategoriesCreateEditComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  supplyCategory: any;
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required]
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService,
    private readonly suppliesService: SuppliesService
  ) { }

  ngOnInit(): void {
    if (this.data.status === 'edit') {
      this.subscriptions.push(this.suppliesService.showSupplyCategory(this.data.editData.id).subscribe((resp: any) => {
        this.supplyCategory = resp.response;
        this.setData();
      }));
    }
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * save
   * Dependiendo del status de la vista ejecuta la funcionalidad para 
   * guardar cambios o enviar los datos para una nueva categoria
   */
  save(): void {
    const functionName = `${this.data.status}SupplyCategory`;
    this[functionName]();
  }

  /**
   * createPromotional
   * Envia datos del formulario para registrar una nueva categoria de insumo
   */
  createSupplyCategory(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar la siguiente categoria?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.suppliesService.createSupplyCategory(this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

  /**
   * editSupplyCategory
   * Guarda los cambios hechos a una categoria de insumo existente.
   */
  editSupplyCategory(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos de la categoria?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.suppliesService.updateSupplyCategory(this.supplyCategory.id, this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
            }
          }));
        }
      });
    }
  }

  /**
   * setData
   * Setea los datos cuando el status es edit.
   */
  private setData(): void {
    Object.keys(this.supplyCategory).forEach(key => {
      if (this.supplyCategory.hasOwnProperty(key) && !!this.form.controls[key]) {
        this.form.controls[key].setValue(this.supplyCategory[key]);
      }
    });
  }

}
