/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmations.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../badge/badge.component.ngfactory";
import * as i3 from "../badge/badge.component";
import * as i4 from "./confirmations.component";
var styles_ConfirmationsComponent = [i0.styles];
var RenderType_ConfirmationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationsComponent, data: {} });
export { RenderType_ConfirmationsComponent as RenderType_ConfirmationsComponent };
export function View_ConfirmationsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "col-md-12 content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "history-ticket-badge", [], null, null, null, i2.View_BadgeComponent_0, i2.RenderType_BadgeComponent)), i1.ɵdid(2, 114688, null, 0, i3.BadgeComponent, [], { item: [0, "item"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "dl", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "dt", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comentarios del cliente"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "dd", [["class", "col-md-8"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item.client_comments; _ck(_v, 7, 0, currVal_1); }); }
export function View_ConfirmationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "history-ticket-confirmations", [], null, null, null, View_ConfirmationsComponent_0, RenderType_ConfirmationsComponent)), i1.ɵdid(1, 114688, null, 0, i4.ConfirmationsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationsComponentNgFactory = i1.ɵccf("history-ticket-confirmations", i4.ConfirmationsComponent, View_ConfirmationsComponent_Host_0, { item: "item" }, {}, []);
export { ConfirmationsComponentNgFactory as ConfirmationsComponentNgFactory };
