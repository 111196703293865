import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { BroadcastService } from '../../../services/broadcast.service';
import { PermissionsService } from '../../../services/permissions.service';
import { PollsService } from '../../../services/polls.service';
import { SwalService } from '../../../services/swal.service';
import { PollCreateEditComponent } from './poll-create-edit/poll-create-edit.component';
import { PollShowComponent } from './poll-show/poll-show.component';

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.scss']
})
export class PollsComponent implements OnInit, OnDestroy {
  dataTableConfig: any;
  subscriptions: Array<Subscription> = [];

  private readonly baseDataTableConfig = {
    config: {
      base: this.pollsService,
      api: 'getPolls',
      order: [[1, 'desc']]
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Fecha de creación',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'Acciones',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-info',
            icon: 'fa fa-eye',
            event: 'poll.show',
            conditionality: true,
            permissions: ['show-polls']
          },
          {
            cssClass: 'btn btn-info',
            icon: 'fa fa-pencil',
            event: 'poll.edit',
            conditionality: true,
            permissions: ['update-polls']
          },
          {
            cssClass: 'btn btn-danger',
            icon: 'fa fa-trash',
            event: 'poll.delete',
            conditionality: true,
            permissions: ['delete-polls']
          }
        ]
      }
    ],
    filters: [{}
    ]
  };

  constructor(
    public swalService: SwalService,
    public appModal: ModalComponent,
    private readonly broadcast: BroadcastService,
    private readonly premissionsService: PermissionsService,
    private readonly pollsService: PollsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'poll.edit': this.editPoll(event.data); break;
        case 'poll.delete': this.deletePoll(event.data); break;
        case 'poll.show': this.showPoll(event.data); break;
      }
    }));
    this.dataTableConfig = this.premissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

/**
 * createPoll
 * Abre un modal con el formulario para crear una encuesta
 */
  createPoll(): void {
    const props: SharedComponent = new SharedComponent(
      PollCreateEditComponent,
      {
        status: 'create'
      },
      {
        title: 'Crear encuesta'
      }
    );
    this.appModal.opensm(props);
  }

  /**
   * Abre un modal con el formulario para editar una encuesta
   * @param data datos de la encuesta
   */
  editPoll(data): void {
    const props: SharedComponent = new SharedComponent(
      PollCreateEditComponent,
      {
        status: 'edit',
        pollData: data
      },
      {
        title: `Editar la encuesta ${data.name}`
      }
    );
    this.appModal.opensm(props);
  }

  /**
   * Abre un modal con la confirmación para borrar una encuesta
   * @param data datos de la encuesta
   */
  deletePoll(data): void {
    const swalParams = {
      title: `¿Estás seguro de querer eliminar la encuesta: ${data.name}?`
    };

    this.swalService.warning(swalParams).then(async result => {
      if (result.value) {
        const resp = await this.pollsService.delete(data.id_polls).toPromise();
        if (resp && resp.success) {
          await this.swalService.success({ title: 'Encuesta eliminada exitosamente' });
          this.broadcast.reloadDataTable();
        } else {
          this.swalService.error('Ocurrio un error al intentar borrar la entrada de cash');
        }
      }
    });
  }

  showPoll(data): void {
    const props: SharedComponent = new SharedComponent(
      PollShowComponent,
      {
        idPoll: data.id_polls
      },
      {
        title: `Información de la encuesta ${data.name}`
      }
    );
    this.appModal.opensm(props);
  }
}
