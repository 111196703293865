import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-contract-data',
  templateUrl: './client-contract-data.component.html',
  styleUrls: ['./client-contract-data.component.scss']
})
export class ClientContractDataComponent implements OnInit {
  @Input('clientData') clientData: any;
  ngOnInit(): void {
    //
  }

}
