<div class="col-md-12 content">
    <history-ticket-badge [item]="item"></history-ticket-badge>
    <dl *ngIf="item.client.installation_extras && item.client.installation_extras.length > 0">
        <dt>Extras</dt>
        <dd *ngFor="let extra of item.client.installation_extras">{{extra.element_name}}</dd>
    </dl>
    <dl class="row">
        <dt class="col-md-4">Tipo</dt>
        <dd class="col-md-8">
            {{item.error_code.category}} - {{item.error_code.name}} - {{item.error_code.code}}
        </dd>
        <dt class="col-md-4">Descripción</dt>
        <dd class="col-md-8">{{ item.description }}</dd>
        <dt class="col-md-4">Costo Estimado</dt>
        <dd class="col-md-8">$ {{item.estimated_service_fee || 0}}</dd>
        <dt class="col-md-4">Razones del costo estimado</dt>
        <dd class="col-md-8">{{ item.estimated_service_fee_reasons }}</dd>
    </dl>
</div>