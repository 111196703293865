/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./client-credits.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-permissions";
import * as i3 from "@angular/common";
import * as i4 from "../../../components/datatable/datatable.component.ngfactory";
import * as i5 from "../../../components/datatable/datatable.component";
import * as i6 from "../../../services/broadcast.service";
import * as i7 from "./client-credits.component";
import * as i8 from "@ng-bootstrap/ng-bootstrap";
import * as i9 from "../../../components/modal/modal.component";
import * as i10 from "../../../services/credits.service";
import * as i11 from "../../../services/swal.service";
import * as i12 from "../../../services/permissions.service";
var styles_ClientCreditsComponent = [i0.styles];
var RenderType_ClientCreditsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientCreditsComponent, data: {} });
export { RenderType_ClientCreditsComponent as RenderType_ClientCreditsComponent };
function View_ClientCreditsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCredit(_co.client) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["A\u00F1adir credito"]))], null, null); }
function View_ClientCreditsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ClientCreditsComponent_2)), i1.ɵdid(2, 737280, null, 0, i2.NgxPermissionsDirective, [i2.NgxPermissionsService, i2.NgxPermissionsConfigurationService, i2.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(3, 1)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "create-credits"); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ClientCreditsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Deuda: ", ""])), i1.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), (_co.client.debt / 100))); _ck(_v, 2, 0, currVal_0); }); }
export function View_ClientCreditsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientCreditsComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientCreditsComponent_3)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 16777216, null, null, 1, "app-datatable", [], null, null, null, i4.View_DatatableComponent_0, i4.RenderType_DatatableComponent)), i1.ɵdid(7, 245760, null, 0, i5.DatatableComponent, [i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i6.BroadcastService], { options: [0, "options"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("close button") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.client.status === "accepted") && (_co.client.debt <= 0)); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.client.debt > 0); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.dataTableConfig; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_ClientCreditsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-client-credits", [], null, null, null, View_ClientCreditsComponent_0, RenderType_ClientCreditsComponent)), i1.ɵdid(1, 245760, null, 0, i7.ClientCreditsComponent, [i8.NgbActiveModal, i9.ModalComponent, i6.BroadcastService, i10.CreditsService, i11.SwalService, i12.PermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientCreditsComponentNgFactory = i1.ɵccf("app-client-credits", i7.ClientCreditsComponent, View_ClientCreditsComponent_Host_0, { data: "data" }, {}, []);
export { ClientCreditsComponentNgFactory as ClientCreditsComponentNgFactory };
