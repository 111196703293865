import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ChartOptions, ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';

@Component({
  selector: 'app-cancellations-chart',
  templateUrl: './cancellations-chart.component.html',
  styleUrls: ['./cancellations-chart.component.scss']
})
export class CancellationsChartComponent implements OnInit, OnChanges {
  @Input('data') cancellations: any;
  actual_cancellations = [];
  past_cancellations = [];
  labels = [];
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  chartColor: Array<Color> = [
    {
      backgroundColor: '#ff113188',
      borderColor: '#ff1131'
    },
    {
      backgroundColor: '#10416a88',
      borderColor: '#10416a'
    }
  ];
  
  options: ChartOptions = {
    plugins: {
      datalabels: { display: false }
    },
    scales: {
      xAxes: [{}],
      yAxes: [{}]
    }
  };

  cancelChartData: Array<ChartDataSets> = [
    {
      data: this.actual_cancellations,
      label: 'Cancelaciones actuales',
      stack: 'a'
    }
  ];
  lineChartType = 'line';

  ngOnInit(): void {
    //
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cancellations.currentValue.actualCancelations.length > 0) {
      this.actual_cancellations = [];
      this.cancelChartData[0].data = changes.cancellations.currentValue.actualCancelations.map((subs: any) =>
        ({ x: subs.month, y: subs.quantity }));

      this.labels = changes.cancellations.currentValue.actualCancelations.map((subs: any) => {
        const month = subs.month.slice(5) - 1;
        const year = (subs.month.slice(2, 4));
        const month_label = `${this.months[month]} ${year}`;

        return month_label;
      });
    }
    
    // if (changes.cancellations.currentValue.pastCancelations.length > 0) {
    //   this.past_cancellations = [];
    //   this.cancelChartData[1].data = changes.cancellations.currentValue.pastCancelations.map((subs: any) =>
    //     ({ x: subs.month, y: subs.quantity }));
    //   if (changes.cancellations.currentValue.actualCancelations.length === 0) {
    //     this.labels = changes.cancellations.currentValue.pastCancelations.map((subs: any) => {
    //       const month = subs.month.slice(5) - 1;
    //       const year = (subs.month.slice(2, 4));
    //       const month_label = `${this.months[month]} ${year}`;

    //       return month_label;
    //     });
    //   }
    // }
  }

}
