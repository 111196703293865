/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./items.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./tickets/tickets.component.ngfactory";
import * as i3 from "./tickets/tickets.component";
import * as i4 from "./client/client.component.ngfactory";
import * as i5 from "./client/client.component";
import * as i6 from "./supplies/supplies.component.ngfactory";
import * as i7 from "./supplies/supplies.component";
import * as i8 from "@angular/common";
import * as i9 from "./items.component";
var styles_ItemsComponent = [i0.styles];
var RenderType_ItemsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ItemsComponent, data: {} });
export { RenderType_ItemsComponent as RenderType_ItemsComponent };
function View_ItemsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tickets", [], null, null, null, i2.View_TicketsComponent_0, i2.RenderType_TicketsComponent)), i1.ɵdid(1, 114688, null, 0, i3.TicketsComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ItemsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-client", [], null, null, null, i4.View_ClientComponent_0, i4.RenderType_ClientComponent)), i1.ɵdid(1, 114688, null, 0, i5.ClientComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ItemsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-supplies", [], null, null, null, i6.View_SuppliesComponent_0, i6.RenderType_SuppliesComponent)), i1.ɵdid(1, 114688, null, 0, i7.SuppliesComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ItemsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i8.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemsComponent_1)), i1.ɵdid(3, 278528, null, 0, i8.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i8.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemsComponent_2)), i1.ɵdid(5, 278528, null, 0, i8.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i8.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ItemsComponent_3)), i1.ɵdid(7, 278528, null, 0, i8.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i8.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "tickets"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "client"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "supplies"; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_ItemsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-items", [], null, null, null, View_ItemsComponent_0, RenderType_ItemsComponent)), i1.ɵdid(1, 114688, null, 0, i9.ItemsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ItemsComponentNgFactory = i1.ɵccf("app-items", i9.ItemsComponent, View_ItemsComponent_Host_0, { type: "type", item: "item" }, {}, []);
export { ItemsComponentNgFactory as ItemsComponentNgFactory };
