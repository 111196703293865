/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./client-charges-history.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/datatable/datatable.component.ngfactory";
import * as i3 from "../../../components/datatable/datatable.component";
import * as i4 from "../../../services/broadcast.service";
import * as i5 from "./client-charges-history.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "../../../services/swal.service";
import * as i8 from "../../../services/charges.service";
import * as i9 from "../../../services/invoices.service";
import * as i10 from "../../../components/modal/modal.component";
var styles_ClientChargesHistoryComponent = [i0.styles];
var RenderType_ClientChargesHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientChargesHistoryComponent, data: {} });
export { RenderType_ClientChargesHistoryComponent as RenderType_ClientChargesHistoryComponent };
export function View_ClientChargesHistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "app-datatable", [], null, null, null, i2.View_DatatableComponent_0, i2.RenderType_DatatableComponent)), i1.ɵdid(2, 245760, null, 0, i3.DatatableComponent, [i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i4.BroadcastService], { options: [0, "options"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("close button") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataTableConfig; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ClientChargesHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-client-charges-history", [], null, null, null, View_ClientChargesHistoryComponent_0, RenderType_ClientChargesHistoryComponent)), i1.ɵdid(1, 245760, null, 0, i5.ClientChargesHistoryComponent, [i6.NgbActiveModal, i7.SwalService, i8.ChargesService, i9.InvoicesService, i4.BroadcastService, i10.ModalComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientChargesHistoryComponentNgFactory = i1.ɵccf("app-client-charges-history", i5.ClientChargesHistoryComponent, View_ClientChargesHistoryComponent_Host_0, { data: "data" }, {}, []);
export { ClientChargesHistoryComponentNgFactory as ClientChargesHistoryComponentNgFactory };
