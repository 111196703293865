import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InvoicesService = /** @class */ (function () {
    function InvoicesService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    InvoicesService.prototype.getInvoices = function (params) {
        return this.http.post(this.baseUrl + "/invoices", params);
    };
    InvoicesService.prototype.downloadFiles = function (id, params) {
        return this.http.get(this.baseUrl + "/invoices/download_files/" + id, params);
    };
    InvoicesService.prototype.sendInvoice = function (params) {
        return this.http.post(this.baseUrl + "/invoices/send_invoice", params);
    };
    InvoicesService.prototype.generateInvoice = function (id_charge, params) {
        return this.http.post(this.baseUrl + "/invoices/manual_invoice/" + id_charge, params);
    };
    InvoicesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvoicesService_Factory() { return new InvoicesService(i0.ɵɵinject(i1.HttpClient)); }, token: InvoicesService, providedIn: "root" });
    return InvoicesService;
}());
export { InvoicesService };
