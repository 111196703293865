<app-header [navbarBrandRouterLink]="['/admin']" [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/aguagente-logo.png', width: 114, height: 50, alt: 'Aguagente Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/logo-aguagente-default.png', width: 30, height: 30, alt: 'Aguagente Logo'}"
  [sidebarToggler]="false" [mobileAsideMenuToggler]="false" [asideMenuToggler]="false">


  <!-- <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <div *ngIf="currentUser.name">
        <p>!Bienvenido! {{currentUser.name}}</p>
      </div>
      <button  type="button" class="btn btn-link" (click)="logOut()"><i class="fa fa-sign-out"></i></button>
    </li>
  </ul> -->
  <div class="row text-center">
    <div class="col-sm-8 align-self-center" *ngIf="currentUser.name">{{currentUser.name}}</div>
    <div class="col-sm-4 align-self-center">
      <button type="button" class="btn btn-link" (click)="logOut()"><i class="fa fa-sign-out"></i></button>
    </div>
  </div>

</app-header>

<div class="app-body">
  <app-sidebar [fixed]="true" [display]="'lg'">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="sidebarMinimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <cui-breadcrumb>
      <!-- Breadcrumb Menu-->
      <!-- <li class="breadcrumb-menu d-md-down-none">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <a class="btn" href="#"><i class="icon-speech"></i></a>
          <a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>
          <a class="btn" href="#"><i class="icon-settings"></i> &nbsp;Settings</a>
        </div>
      </li> -->
    </cui-breadcrumb>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
    <!-- /.container-fluid -->
  </main>

</div>