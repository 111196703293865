import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class ElementsService {
    baseUrl = `${environment.apiUrl}`;

    constructor(private readonly http: HttpClient) { }

    create(params): Observable<any> {
        return this.http.post(`${this.baseUrl}/elements`, params);
    }

    delete(id): Observable<any> {
        return this.http.delete(`${this.baseUrl}/elements/${id}`);
    }

    show(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/elements/${id}`);
    }

    update(id, params): Observable<any> {
        return this.http.put(`${this.baseUrl}/elements/${id}`, params);
    }

    getElements(params?, filters?): Observable<any> {
        let id = 0;
        if (filters) {
            id = filters.id_categories;
        }
        
        return this.http.post(`${this.baseUrl}/elements/${id}/get_elements`, params);
    }

    getImage(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/elements/${id}/getImage`);
    }
}
