<div class="container m-2">
    <app-form-field type="input" [options]="{label: 'Nombre',width:12, path: 'name'}"></app-form-field>
    <app-form-field type="textarea" styleInput="textarea" [options]="{label: 'Descripción', rows: '4', path:'description', width: true}"></app-form-field>
</div>
<br>
<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid" (click)="updateGroup()">Guardar</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>