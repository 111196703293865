import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthorizationService } from '../../services/authorization.service';
import { UsersService } from '../../services/users.service';
import { FromService } from '../../providers/form.service';
import { RoleGuardService } from '../../services/role-guard.service';
import { PermissionsService } from '../../services/permissions.service';
import * as JWT from 'jwt-decode';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
  providers: [AuthorizationService]
})
export class LoginComponent {
  loginForm: FormGroup = this.formBuilder.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
    appVersion: ['']
  });

  errors = [];
  loading = false;

  constructor(
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
    private readonly authorizationService: AuthorizationService,
    private readonly roleGuardService: RoleGuardService,
    private readonly userService: UsersService,
    private readonly fromService: FromService,
    private readonly permissionsService: PermissionsService
  ) {
    const access_token = localStorage.getItem('access_token');
    const user = localStorage.getItem('user');

    if (access_token && user) {
      this.loading = true;
      const userData = JSON.parse(user);
      this.userService.user(userData.id).subscribe((data: any) => {
        if (data.success) {
          this.router.navigate(['admin/inicio']);
        } else {
          localStorage.removeItem('access_token');
          localStorage.removeItem('user');
        }
      });
    }

  }

  ngOnInit(): void {
    this.fromService.setForm(this.loginForm);
  }

  logIn(): void {
    this.errors = [];

    if (this.loginForm.valid) {
      this.loading = true;

      this.authorizationService.login(this.loginForm.value).subscribe((data: any) => {
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('user', JSON.stringify(data.user));
        const decoded: any = JWT(data.access_token);
        // const unique_permissions: Array<any> = Array.from(new Set(decoded.permissions).values());
        this.permissionsService.setPermissions(decoded.permissions);
        const roles = this.roleGuardService.decodedToken.user.roles;
        const adminClientsRoles = ['Sales Agent', 'Sales Manager'];

        if (this.permissionsService.hasPermission('show-main-dashboard')) {
          this.router.navigate(['/admin/inicio']);
        } else if (this.permissionsService.hasPermission('show-sales-dashboard')) {
          this.router.navigate(['/admin/dashboard-ventas']);
        } else if (this.permissionsService.hasPermission('show-investor-dashboard')) {
          this.router.navigate(['/admin/dashboard-inversores']);
        } else if (this.permissionsService.hasPermission('show-admin-dashboard')) {
          this.router.navigate(['/admin/dashboard-admin']);
        } else {
          if (roles.find(role => role.name === 'Technician')) {
            this.router.navigate(['/admin/servicio/tickets']);
          } else if (roles.find(role => adminClientsRoles.includes(role.name))) {
            this.router.navigate(['/admin/clientes']);
          } else if (roles.find(role => role.name === 'Warehouse Manager')) {
            this.router.navigate(['/admin/bodega/insumos']);
          }
        }
      });
    }
  }

}
