import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-commission-status',
  templateUrl: './commission-status.component.html',
  styleUrls: ['./commission-status.component.scss']
})
export class CommissionStatusComponent implements OnInit {
  @Input() data: any;

  ngOnInit(): void {
    //
  }

}
