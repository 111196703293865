import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CashService = /** @class */ (function () {
    function CashService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    CashService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/admin_dashboard/cash", params);
    };
    CashService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/admin_dashboard/cash/" + id);
    };
    CashService.prototype.edit = function (id, params) {
        return this.http.put(this.baseUrl + "/admin_dashboard/cash/" + id, params);
    };
    CashService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/admin_dashboard/cash/" + id);
    };
    CashService.prototype.index = function (params) {
        return this.http.get(this.baseUrl + "/admin_dashboard/cash", { params: params });
    };
    CashService.prototype.getCash = function (params) {
        return this.http.post(this.baseUrl + "/admin_dashboard/get-cash", params);
    };
    CashService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CashService_Factory() { return new CashService(i0.ɵɵinject(i1.HttpClient)); }, token: CashService, providedIn: "root" });
    return CashService;
}());
export { CashService };
