import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
var BroadcastService = /** @class */ (function () {
    function BroadcastService() {
        // public _events = new BehaviorSubject<Events>({name: '', data: {}});
        this._events = new Subject();
    }
    BroadcastService.prototype.fire = function (data) {
        this._events.next(data);
    };
    Object.defineProperty(BroadcastService.prototype, "events", {
        get: function () {
            return this._events;
        },
        enumerable: true,
        configurable: true
    });
    BroadcastService.prototype.on = function (eventName, callback) {
        var subscription = this.events.pipe(filter(function (value) { return value.name === eventName; })).subscribe(callback);
        return function () {
            subscription.remove(subscription);
            subscription.unsubscribe();
        };
    };
    BroadcastService.prototype.reloadDataTable = function () {
        this.fire({
            name: 'datatable-filter',
            data: {}
        });
    };
    BroadcastService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BroadcastService_Factory() { return new BroadcastService(); }, token: BroadcastService, providedIn: "root" });
    return BroadcastService;
}());
export { BroadcastService };
