import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class GalleryService {
    baseUrl = `${environment.apiUrl}`;

    constructor(private readonly http: HttpClient) { }

    create(params): Observable<any> {
        return this.http.post(`${this.baseUrl}/posts`, params);
    }

    delete(id): Observable<any> {

        return this.http.delete(`${this.baseUrl}/posts/${id}`);
    }

    getPostsDataTable(params?): Observable<any> {

        return this.http.post(`${this.baseUrl}/posts/getPostsDataTable`, params);
    }

    getPostImage(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/post/${id}/getPostImage`);
    }

    show(id): Observable<any> {

        return this.http.get(`${this.baseUrl}/posts/${id}`);
    }

    update(id, params): Observable<any> {

        return this.http.put(`${this.baseUrl}/posts/${id}`, params);
    }

}
