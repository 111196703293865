import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getField'
})
export class GetFieldPipe implements PipeTransform {

  transform(field: any, ...args: Array<any>): any {
    const nField = field.split('.');
    const data = args[0];
    const type = args[1];

    let isNull = false;
    let text = data;
    nField.forEach((item: any) => {
      text = text[item];
      if (text === null) {
        isNull = true;
      }
    });
  
    if (isNull) {
      switch (type) {
        case 'date': text = null; break;
        case 'number': text = 0; break;
        default: text = 'N/A'; break;
      }
    }

    return text;
  }

}
