<div class="container-fluid border-bottom bg-white p-3 mb-4 col-sm-12 col-md-12">
    <div class="row">
        <div class="col-sm-3">
            <h3>Sales by Agent (Month) <span class="fa fa-info-circle fa-1x" (click)="showInfoGraphic()"
                    data-toggle="tooltip" data-placement="right"
                    title="How many closed won based on month leads generated filtered by date range and agent. Close rate: closed won/closed lost"></span>
            </h3>
        </div>
        <!-- Filters -->
        <div class="col-sm-2">
            <input class="form-control" placeholder="Desde" [formControl]="fieldFrom" ngbDatepicker #d="ngbDatepicker"
                (click)="d.toggle()">
        </div>
        <div class="col-sm-2">
            <input class="form-control" placeholder="Hasta" [formControl]="fieldTo" ngbDatepicker #dd="ngbDatepicker"
                (click)="dd.toggle()">
        </div>
        <div class="col-sm-3">
            <select class="form-control" [formControl]="fieldAgent">
                <option *ngFor="let option of agents" [value]="option.id">{{option.name}}</option>
            </select>
        </div>
        <div class="col-sm-2">
            <button class="btn btn-primary" type="button" (click)="search()">Buscar</button>
        </div>
    </div>
    <!-- Bar Chart -->
    <div class="container-fluid border-bottom bg-white p-3 mb-4">
        <div style="display: block" *ngIf="!isLoad && barChartData.length > 0" class="chart">
            <canvas baseChart [colors]="barChartColors" [datasets]="barChartData" [labels]="barChartLabels"
                [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
                [chartType]="barChartType">
            </canvas>
        </div>
    </div>
</div>