import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { CreditsService } from '../../../../services/credits.service';
import { PermissionsService } from '../../../../services/permissions.service';

@Component({
  selector: 'app-credit-history',
  templateUrl: './credit-history.component.html',
  styleUrls: ['./credit-history.component.scss']
})
export class CreditHistoryComponent implements OnInit {
  @Input() data: any;
  dataTableConfig: any;
  private readonly baseDataTableConfig = {
    config: {
      base: this.creditsService,
      api: 'getCreditHistoryTable',
      params: {
        id_credit: 0
      }
    },
    columns: [
      {
        display: 'ID del credito',
        field: 'id_credit',
        type: 'text'
      },
      {
        display: 'ID del cargo',
        field: 'id_charge',
        type: 'text'
      },
      {
        display: 'Credito aplicado',
        field: 'amount',
        type: 'number'
      },
      {
        display: 'Fecha de uso',
        field: 'created_at',
        type: 'date'
      },
      {
        display: '',
        field: '',
        type: 'actions',
        options: []
      }
    ],
    filters: [{}]
  };
  constructor(
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    private readonly creditsService: CreditsService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
    this.dataTableConfig.config.params.id_credit = this.data.id_credit;
  }

}
