/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bad-debts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../components/datatable/datatable.component.ngfactory";
import * as i4 from "../../../components/datatable/datatable.component";
import * as i5 from "../../../services/broadcast.service";
import * as i6 from "./bad-debts.component";
import * as i7 from "../../../services/swal.service";
import * as i8 from "../../../services/bad-debts.service";
import * as i9 from "../../../services/permissions.service";
var styles_BadDebtsComponent = [i0.styles];
var RenderType_BadDebtsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BadDebtsComponent, data: {} });
export { RenderType_BadDebtsComponent as RenderType_BadDebtsComponent };
export function View_BadDebtsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "col-sm-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Malas Deudas"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "col-sm-4 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "h4", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["Total: ", ""])), i1.ɵppd(9, 1), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 16777216, null, null, 1, "app-datatable", [], null, null, null, i3.View_DatatableComponent_0, i3.RenderType_DatatableComponent)), i1.ɵdid(12, 245760, null, 0, i4.DatatableComponent, [i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i5.BroadcastService], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.dataTableConfig; _ck(_v, 12, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v, 0), _co.total_bad_debts)); _ck(_v, 8, 0, currVal_0); }); }
export function View_BadDebtsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-bad-debts", [], null, null, null, View_BadDebtsComponent_0, RenderType_BadDebtsComponent)), i1.ɵdid(1, 245760, null, 0, i6.BadDebtsComponent, [i7.SwalService, i8.BadDebtsService, i5.BroadcastService, i9.PermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BadDebtsComponentNgFactory = i1.ɵccf("app-bad-debts", i6.BadDebtsComponent, View_BadDebtsComponent_Host_0, {}, {}, []);
export { BadDebtsComponentNgFactory as BadDebtsComponentNgFactory };
