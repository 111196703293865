import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-charge-amount',
  templateUrl: './charge-amount.component.html',
  styleUrls: ['./charge-amount.component.scss']
})
export class ChargeAmountComponent implements OnInit {
  @Input() data: any;
  @Input() options: any;
  amount: any;

  ngOnInit(): void {
    this.amount = this.data.amount;
    if (this.data.vendor !== 2) {
      this.amount = (this.data.amount) / 100;
    }
  }

}
