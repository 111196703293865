import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var TeamsService = /** @class */ (function () {
    function TeamsService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    TeamsService.prototype.index = function () {
        return this.http.get(this.baseUrl + "/teams");
    };
    TeamsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TeamsService_Factory() { return new TeamsService(i0.ɵɵinject(i1.HttpClient)); }, token: TeamsService, providedIn: "root" });
    return TeamsService;
}());
export { TeamsService };
