/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./poll-show.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "./poll-show.component";
import * as i6 from "../../../../services/polls.service";
var styles_PollShowComponent = [i0.styles];
var RenderType_PollShowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PollShowComponent, data: {} });
export { RenderType_PollShowComponent as RenderType_PollShowComponent };
function View_PollShowComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.question_option; _ck(_v, 1, 0, currVal_0); }); }
function View_PollShowComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PollShowComponent_5)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.question_options; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PollShowComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PollShowComponent_4)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = ((_v.parent.context.$implicit == null) ? null : ((_v.parent.context.$implicit.question_options == null) ? null : _v.parent.context.$implicit.question_options.length)); _ck(_v, 1, 0, currVal_0); }, null); }
function View_PollShowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ngb-accordion", [["class", "accordion"], ["role", "tablist"]], [[1, "aria-multiselectable", 0]], null, null, i3.View_NgbAccordion_0, i3.RenderType_NgbAccordion)), i1.ɵdid(1, 2146304, null, 1, i4.NgbAccordion, [i4.NgbAccordionConfig], null, null), i1.ɵqud(603979776, 1, { panels: 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 6, "ngb-panel", [], null, null, null, null, null)), i1.ɵdid(4, 2113536, [[1, 4]], 3, i4.NgbPanel, [], { title: [0, "title"] }, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { headerTpls: 1 }), i1.ɵqud(603979776, 4, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_PollShowComponent_3)), i1.ɵdid(9, 16384, [[4, 4]], 0, i4.NgbPanelContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.description, ""); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = !i1.ɵnov(_v, 1).closeOtherPanels; _ck(_v, 0, 0, currVal_0); }); }
function View_PollShowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PollShowComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.poll.questions; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PollShowComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Esta encuesta no tiene preguntas asociadas"]))], null, null); }
export function View_PollShowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PollShowComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["noPolls", 2]], null, 0, null, View_PollShowComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.poll == null) ? null : ((_co.poll.questions == null) ? null : _co.poll.questions.length)); var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_PollShowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-poll-show", [], null, null, null, View_PollShowComponent_0, RenderType_PollShowComponent)), i1.ɵdid(1, 245760, null, 0, i5.PollShowComponent, [i4.NgbActiveModal, i6.PollsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PollShowComponentNgFactory = i1.ɵccf("app-poll-show", i5.PollShowComponent, View_PollShowComponent_Host_0, { data: "data" }, {}, []);
export { PollShowComponentNgFactory as PollShowComponentNgFactory };
