import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FromService } from '../../providers/form.service';
import { DashboardService } from '../../services/dashboard.service';
import { SwalService } from '../../services/swal.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {

  kpiData = {
    active_users: 0,
    debts: 0,
    free_water: 0,
    free_water_percent: 0,
    sr: 0
  };

  tableData = {
    clientStatus: 0,
    filterChanges: {},
    ticketStatus: {
      opened: 0,
      closed: 0,
      assigned: 0,
      confirmed: 0,
      completed: 0
    }
  };

  subscriptionsData = {
    actualSubscriptions: [],
    pastSubscriptions: []
  };

  incomesData = {
    actualIncomes: [],
    pastIncomes: []
  };

  sharesTotalData = {
    total: []
  };

  sharesTypeData = {
    type: [],
    total: []
  };

  ticketsCompletitionsData = {
    ticketsCompleted: []
  };

  cancellationsData = {
    actualCancelations: []
  };

  form: FormGroup = this.formBuilder.group({
    from: [],
    to: []
  });
  subscriptions: Array<Subscription> = [];
  // activeUsers$: Subscription;
  // freeWater$: Subscription;
  // incomes$: Subscription;
  // shares$: Subscription;
  // subscriptions$: Subscription;
  // dashboard$: Subscription;
  // totals$: Subscription;

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly fromService: FromService,
    private readonly formBuilder: FormBuilder,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    this.getActiveUsers();
    this.getFilterChanges();
    this.getIncomes();
    this.getSubcriptions();
    this.getTickets();
    this.getTicketCompletions();
    this.getTotals();
    this.getShares();
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
    // this.activeUsers$.unsubscribe();
    // this.freeWater$.unsubscribe();
    // this.incomes$.unsubscribe();
    // this.subscriptions$.unsubscribe();
    // this.dashboard$.unsubscribe();
    // this.totals$.unsubscribe();
  }

  freeWaterPercent(): number {
    let free_water_percent = 0;
    if (this.kpiData.free_water || this.kpiData.active_users > 0) {
      free_water_percent = Math.round((this.kpiData.free_water / this.kpiData.active_users) * 100);
    }

    return free_water_percent;
  }

  getActiveUsers(): void {
    this.subscriptions.push(this.dashboardService.activeUsers().subscribe((resp: any) => {
      resp.clients.forEach((clienStatus: any) => {
        if (clienStatus.status === 'accepted') {
          // this.kpiData.active_users = Number(clienStatus.charged);
          this.kpiData.active_users = Number(clienStatus.active);
        }
      });

      this.tableData.clientStatus = resp.clients;

      this.subscriptions.push(this.dashboardService.freewater().subscribe((data: any) => {
        this.kpiData.free_water = data.length;
        this.kpiData.free_water_percent = this.freeWaterPercent();
      }));
    }));
  }

  getFilterChanges(): void {
    this.dashboardService.filterChanges().subscribe(resp => {
      if (resp && resp.success) {
        this.tableData.filterChanges = resp.response;
      }
    });
  }

  getIncomes(params?): void {
    this.subscriptions.push(this.dashboardService.incomes(params).subscribe((resp: any) => {
      const nIn = {
        actualIncomes: [...resp.incomes],
        pastIncomes: [...resp.past_incomes]
      };
      this.incomesData = { ...nIn };
    }));
  }

  getShares(params?): void {
    this.subscriptions.push(this.dashboardService.shares(params).subscribe((resp: any) => {
      const ntotal = {
        total: [...resp.total]
      };
      const nType = {
        type: [...resp.byType],
        total: [...resp.total]
      };

      this.sharesTotalData = { ...ntotal };
      this.sharesTypeData = { ...nType };
    }));
  }

  getSubcriptions(params?): void {
    this.subscriptions.push(this.dashboardService.subscriptions(params).subscribe((resp: any) => {
      const nSubs = {
        actualSubscriptions: [...resp.new],
        pastSubscriptions: [...resp.past]
      };
      this.subscriptionsData = { ...nSubs };
    }));
  }

  getTickets(params?): void {
    this.subscriptions.push(this.dashboardService.dashboard(params).subscribe((resp: any) => {
      this.tableData.ticketStatus.opened = resp.tickets.opened;
      this.tableData.ticketStatus.closed = resp.tickets.closed;
      this.tableData.ticketStatus.assigned = resp.tickets.assigned;
      this.tableData.ticketStatus.confirmed = resp.tickets.confirmed;
      this.tableData.ticketStatus.completed = resp.tickets.completed;

      const nIn = {
        actualCancelations: [...resp.subscription_events]
      };
      this.cancellationsData = { ...nIn };
    }));
  }

  getTicketCompletions(params?): void {
    this.dashboardService.ticketComplettions(params).subscribe((resp: any) => {
      const tCom = {
        ticketsCompleted: [...resp]
      };
      this.ticketsCompletitionsData = { ...tCom };
    });
  }

  getTotals(): void {
    this.subscriptions.push(this.dashboardService.totals().subscribe((resp: any) => {
      this.kpiData.debts = resp.totals.debts;
      const clientSocial = parseInt(resp.totals.client_social, 10);
      const aguagenteSocial = parseInt(resp.totals.aguagente_social, 10);
      this.kpiData.sr = Math.floor(clientSocial + aguagenteSocial);
    }));
  }

  search(): void {
    const from_sub = this.form.get('from').value;
    const to_sub = this.form.get('to').value;
    const params = {
      from: from_sub,
      to: to_sub
    };

    if (params.from && params.to !== '') {
      this.getSubcriptions(params);
      this.getIncomes(params);
      this.getTickets(params);
      this.getShares(params);
      this.getTicketCompletions(params);
    } else {
      this.swal.error({ title: 'Favor de introducir las fechas correctamente' });
    }
  }

}
