import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SatisfactionService {

  baseUrl = `${environment.apiUrl}`;

  constructor(private readonly http: HttpClient) { }

  create(params): any {
    return this.http.post(`${this.baseUrl}/admin_dashboard/satisfaction`, params);
  }

  delete(id): any {
    return this.http.delete(`${this.baseUrl}/admin_dashboard/satisfaction/${id}`);
  }

  edit(id, params): any {
    return this.http.put(`${this.baseUrl}/admin_dashboard/satisfaction/${id}`, params);
  }

  show(id): any {
    return this.http.get(`${this.baseUrl}/admin_dashboard/satisfaction/${id}`);
  }

  index(params?): any {
    return this.http.get(`${this.baseUrl}/admin_dashboard/satisfaction`, {params});
  }

  getSatisfaction(params?): any {
    return this.http.post(`${this.baseUrl}/admin_dashboard/satisfaction/get-satisfaction`, params);
  }

  getLast(): any {
    return this.http.get(`${this.baseUrl}/admin_dashboard/satisfaction/last`);
  }
}
