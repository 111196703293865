/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./investor-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ng2-charts";
import * as i3 from "@angular/common";
import * as i4 from "./investor-chart.component";
import * as i5 from "../../../../services/swal.service";
var styles_InvestorChartComponent = [i0.styles];
var RenderType_InvestorChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InvestorChartComponent, data: {} });
export { RenderType_InvestorChartComponent as RenderType_InvestorChartComponent };
function View_InvestorChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "canvas", [["baseChart", ""], ["height", "100"], ["width", "450"]], null, null, null, null, null)), i1.ɵdid(1, 999424, null, 0, i2.BaseChartDirective, [i1.ElementRef, i2.ThemeService], { datasets: [0, "datasets"], labels: [1, "labels"], options: [2, "options"], chartType: [3, "chartType"], colors: [4, "colors"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.datasets; var currVal_1 = _co.labels; var currVal_2 = _co.options; var currVal_3 = _co.chartType; var currVal_4 = _co.chartColor; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_InvestorChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "fa fa-info-circle fa-1x"], ["data-placement", "right"], ["data-toggle", "tooltip"], ["style", "cursor: pointer;"], ["title", "Show description"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showInfoGraphic() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InvestorChartComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.datasets.length; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_InvestorChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-investor-chart", [], null, null, null, View_InvestorChartComponent_0, RenderType_InvestorChartComponent)), i1.ɵdid(1, 114688, null, 0, i4.InvestorChartComponent, [i5.SwalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvestorChartComponentNgFactory = i1.ɵccf("app-investor-chart", i4.InvestorChartComponent, View_InvestorChartComponent_Host_0, { datasets: "datasets", labels: "labels", options: "options", title: "title", description: "description", chartType: "chartType", color: "color" }, {}, []);
export { InvestorChartComponentNgFactory as InvestorChartComponentNgFactory };
