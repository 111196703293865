import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var WithdrawalsService = /** @class */ (function () {
    function WithdrawalsService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * getWithdrawalsTable
     * Envia una peticion post para obtener los parametros basicos para poder
     * crear la tabla de retiros
     * @param params parametros por los cuales se va a definir el orden de la tabla, el numero de records que se mostraran por pagina, etc.
     * @param filters filtros que se pueden mandar a la API para filtrar los resultados de la petición POST
     * @returns array con los parametros para que datatable.component.ts pueda dibujar la tabla.
     */
    WithdrawalsService.prototype.getWithdrawalsTable = function (params, filters) {
        Object.assign(params, filters);
        return this.http.post(this.baseUrl + "/withdrawals/getWithdrawalsTable", params);
    };
    /**
     * getWithdrawalsTotal
     * Obtiene el total de los retiros
     * @returns respuesta con el total de los retiros que se han hecho.
     */
    WithdrawalsService.prototype.getWithdrawalsTotal = function () {
        return this.http.get(this.baseUrl + "/withdrawals/getWithdrawalsTotal");
    };
    /**
     * createWithdrawal
     * Envia una petición POST a la API con los parametros para crear el registro de un retiro.
     * @param params amount: cantidad del retiro, reason: razón por la cual se realiza el retiro
     * @returns 200|500
     */
    WithdrawalsService.prototype.createWithdrawal = function (params) {
        return this.http.post(this.baseUrl + "/withdrawals", params);
    };
    WithdrawalsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function WithdrawalsService_Factory() { return new WithdrawalsService(i0.ɵɵinject(i1.HttpClient)); }, token: WithdrawalsService, providedIn: "root" });
    return WithdrawalsService;
}());
export { WithdrawalsService };
