import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
// Routing
import { FinancesRoutes } from './finances.routing';
// Components
import { BadDebtsComponent } from './bad-debts/bad-debts.component';
import { ComponentsModule } from '../../components/components.module';
import { ClientsComponent } from './clients/clients.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { WithdrawalsComponent } from './withdrawals/withdrawals.component';
import { RecentChargesComponent } from './recent-charges/recent-charges.component';
import { ChargesBacksComponent } from './charges-backs/charges-backs.component';

@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    RouterModule.forChild(FinancesRoutes),
    NgxPermissionsModule.forChild()
  ],
  declarations: [
    BadDebtsComponent,
    ClientsComponent,
    InvoicesComponent,
    WithdrawalsComponent,
    RecentChargesComponent,
    ChargesBacksComponent
  ],
  exports: [
    BadDebtsComponent,
    ClientsComponent
  ]
})
export class FinancesModule { }
