/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./client-extra-images.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./client-extra-images.component";
import * as i4 from "../../../components/modal/modal.component";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../services/clients.service";
import * as i7 from "../../../services/tickets.service";
import * as i8 from "ngx-lightbox/lightbox.service";
var styles_ClientExtraImagesComponent = [i0.styles];
var RenderType_ClientExtraImagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientExtraImagesComponent, data: {} });
export { RenderType_ClientExtraImagesComponent as RenderType_ClientExtraImagesComponent };
function View_ClientExtraImagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addExtraPhotos() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Adjuntar imagen extra"]))], null, null); }
function View_ClientExtraImagesComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col-sm-12 col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Image for extra image of client"], ["class", "image-preview"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLightBox(_v.context.$implicit.src.changingThisBreaksApplicationSecurity) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.caption; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.src.changingThisBreaksApplicationSecurity; _ck(_v, 3, 0, currVal_1); }); }
function View_ClientExtraImagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Imagenes extra"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientExtraImagesComponent_3)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.extraClientImages; _ck(_v, 6, 0, currVal_0); }, null); }
function View_ClientExtraImagesComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col-sm-12 col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Image for premium installation"], ["class", "image-preview"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLightBox(_v.context.$implicit.src.changingThisBreaksApplicationSecurity) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.src.changingThisBreaksApplicationSecurity; _ck(_v, 1, 0, currVal_0); }); }
function View_ClientExtraImagesComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientExtraImagesComponent_6)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.premiumInstallationImages; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ClientExtraImagesComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Instalaci\u00F3n premium - Ticket #", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientExtraImagesComponent_5)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.premiumInstallationImages.length > 0); var currVal_2 = i1.ɵnov(_v.parent, 9); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.premiumInstallationIdTicket; _ck(_v, 2, 0, currVal_0); }); }
function View_ClientExtraImagesComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "col-sm-12 col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Image for regular installation"], ["class", "image-preview"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLightBox(_v.context.$implicit.src.changingThisBreaksApplicationSecurity) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.src.changingThisBreaksApplicationSecurity; _ck(_v, 1, 0, currVal_0); }); }
function View_ClientExtraImagesComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientExtraImagesComponent_9)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.regularInstallationImages; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ClientExtraImagesComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["Instalaci\u00F3n regular - Ticket #", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientExtraImagesComponent_8)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.regularInstallationImages.length > 0); var currVal_2 = i1.ɵnov(_v.parent, 9); _ck(_v, 5, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.regularInstallationIdTicket; _ck(_v, 2, 0, currVal_0); }); }
function View_ClientExtraImagesComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No se encontraron imagenes asociadas al ticket"]))], null, null); }
export function View_ClientExtraImagesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientExtraImagesComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientExtraImagesComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientExtraImagesComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientExtraImagesComponent_7)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["noData", 2]], null, 0, null, View_ClientExtraImagesComponent_10))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.extraClientImages.length < 5); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.extraClientImages.length > 0); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.isPremium && _co.premiumInstallationIdTicket); _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.regularInstallationIdTicket; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ClientExtraImagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-client-extra-images", [], null, null, null, View_ClientExtraImagesComponent_0, RenderType_ClientExtraImagesComponent)), i1.ɵdid(1, 245760, null, 0, i3.ClientExtraImagesComponent, [i4.ModalComponent, i5.DomSanitizer, i6.ClientsService, i7.TicketsService, i8.Lightbox], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientExtraImagesComponentNgFactory = i1.ɵccf("app-client-extra-images", i3.ClientExtraImagesComponent, View_ClientExtraImagesComponent_Host_0, { data: "data" }, {}, []);
export { ClientExtraImagesComponentNgFactory as ClientExtraImagesComponentNgFactory };
