<div class="modal-body" *ngIf="client && error_codes !== undefined">
    <div class="container">
        <div class="row">
            <div class="col">
                <h5 class="font-italic">Datos del cliente</h5>
                <br>
                <dl class="row">
                    <dt class="col-sm-3">Nombre</dt>
                    <dd class="col-sm-9">{{client.name}}</dd>
                    <dt class="col-sm-3">Fecha de alta</dt>
                    <dd class="col-sm-9">{{client.created_at}}</dd>
                    <dt class="col-sm-3">Email</dt>
                    <dd class="col-sm-9">{{client.email}}</dd>
                    <dt class="col-sm-3">Teléfono</dt>
                    <dd class="col-sm-9">{{client.phone}}</dd>
                    <dt class="col-sm-3">Dirección</dt>
                    <dd class="col-sm-9">Calle {{client.address}} entre {{client.between_streets}}</dd>
                    <dt class="col-sm-3">Colonia</dt>
                    <dd class="col-sm-9">{{client.colony}}</dd>
                    <dt class="col-sm-3">Código postal</dt>
                    <dd class="col-sm-9">{{client.postal_code}}</dd>
                    <dt class="col-sm-3">Estado</dt>
                    <dd class="col-sm-9">{{client.state}}</dd>
                    <dt class="col-sm-3">Estatus</dt>
                    <dd class="col-sm-9">{{client.status}}</dd>
                    <dt class="col-sm-3">Servicio gratis</dt>
                    <dd class="col-sm-9">{{client.level!='VIAJERO'?'Si':'No'}}</dd>
                </dl>
            </div>

            <div class="col" *ngIf="ticketData">
                <app-previous-ticket-data [ticketData]="ticketData"></app-previous-ticket-data>
            </div>
        </div>
        <br>
        <div class="row">
            <app-form-field class="col-sm-12 col-md-4" type="multiselect" [options]="{
                    label: 'Código de error', 
                    width:12, 
                    values: error_codes, 
                    path: 'id_error_codes',
                    searchable: true,
                    virtualScroll: true,
                    multiple: false
                }">
            </app-form-field>
            <app-form-field class="col-sm-12 col-md-4" type="select"
                [options]="{label: 'Tipo',  width:12, droptext: '-Selecciona el tipo-', elements: ticket_types, path:'type'}">
            </app-form-field>
            <app-form-field class="col-sm-12 col-md-4" type="input"
                [options]="{label: 'Cuota estimada del servicio',  width:12, path: 'estimated_service_fee'}">
            </app-form-field>
        </div>
        <div class="row">
            <app-form-field class="col-sm-12 col-md-6" type="textarea"
                [options]="{label: 'Descripción',  width:12, path: 'description'}">
            </app-form-field>
            <app-form-field class="col-sm-12 col-md-6" type="textarea"
                [options]="{label: 'Razones de la cuota estimada',  width:12, path: 'estimated_service_fee_reasons'}">
            </app-form-field>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="createSupportTicket()">Crear Ticket</button>
    </div>

    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>