import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../../services/broadcast.service';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GroupsService } from '../../../services/groups.service';
import { SwalService } from '../../../services/swal.service';
import { NotificationService } from '../../../services/notification.service';
import { NewGroupComponent } from './new-group/new-group.component';
import { MembersGroupComponent } from './members-group/members-group.component';
import { EditGroupComponent } from './edit-group/edit-group.component';
import { PermissionsService } from '../../../services/permissions.service';

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  dataTableConfig: any;
  private readonly baseDataTableConfig = {
    config: {
      base: this.groups,
      api: 'getNotifications'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Descripción',
        field: 'description',
        type: 'text',
        orderable: false
      },
      {
        display: '',
        cssClass: 'botones',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-success',
            name: 'Enviar',
            event: 'group.notify',
            conditionality: 'true',
            permissions: ['notify-users-group']
          },
          {
            cssClass: 'btn btn-primary',
            name: 'Integrantes',
            event: 'group.member',
            conditionality: 'true',
            permissions: ['update-notification-group']
          },
          {
            cssClass: 'btn btn-primary',
            name: 'Editar',
            event: 'group.edit',
            conditionality: 'true',
            permissions: ['update-notification-group']
          },
          {
            cssClass: 'btn btn-danger',
            name: 'Eliminar',
            event: 'group.delete',
            conditionality: 'true',
            permissions: ['delete-notification-group']
          }
        ]
      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly groups: GroupsService,
    private readonly broadcast: BroadcastService,
    public appModal: ModalComponent,
    private readonly swal: SwalService,
    private readonly notify: NotificationService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'group.notify': this.groupNotify(event.data); break;
        case 'group.member': this.groupMember(event.data); break;
        case 'group.edit': this.groupEdit(event.data); break;
        case 'group.delete': this.groupDelete(event.data); break;
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  groupNew(data): void {
    const props: SharedComponent = new SharedComponent(NewGroupComponent, data, { title: 'Grupo de notificación' });
    this.appModal.openXl(props);
  }

  groupMember(data): void {
    const props: SharedComponent = new SharedComponent(MembersGroupComponent, data, { title: 'Integrantes' });
    this.appModal.openXl(props);
  }

  groupEdit(data): void {
    const props: SharedComponent = new SharedComponent(EditGroupComponent, data, { title: 'Editar grupo de notificación' });
    this.appModal.openXl(props);
  }

  groupNotify(data): void {
    const swalParams = {
      title: 'Enviar notificación',
      text: 'Proporciona el mensaje.',
      inputPlaceholder: 'Mensaje'
    };
    this.swal.input(swalParams).then((resp: any) => {
      if (resp.value) {
        const params = {
          id_notification_groups: data.id_notification_groups,
          message: resp.value
        };
        this.subscriptions.push(this.notify.notifyUsersGroup(params).subscribe((respData: any) => {
          if (respData.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
            });
          } else {
            this.swal.error();
          }
        }));

      }
    });
  }

  groupDelete(data): void {
    const swal_title = `¿Estás seguro de eliminar el grupo ${data.name} ?`;
    this.swal.warning({ title: swal_title }).then(result => {
      if (result.value) {
        this.subscriptions.push(this.groups.deleteGroup(data.id_notification_groups).subscribe((response: any) => {
          if (response.success) {
            this.swal.success().then(() => {
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: response.message });
          }
        }));
      }
    });
  }

}
