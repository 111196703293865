<div class="container-fluid text-center">
    <app-form-field type="multiselect" [options]="{
            multiple: true,
            values: childAccounts,
            label: 'Selecciona a los clientes a asociar a esta cuenta',
            placeholder: 'Selecciona a los clientes',
            searchable: true,
            virtualScroll: true,
            path: 'childAccounts'
        }">
    </app-form-field>
</div>

<br>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="associateAccounts()">Asociar cuentas</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>