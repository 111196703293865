<div class="row">
    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
        <div class="card text-white bg-info">
            <div class="card-body pb-0 text-right">
                <div class="row">
                    <div class="col-sm-2 text-left">
                        <i class="fa fa-tint fa-2x"></i>
                    </div>
                    <div class="col-sm-10 text-right">
                        <h5>Aguagratis</h5>
                        <h5>{{kpi.free_water}} / {{kpi.active_users}} ({{kpi.free_water_percent}}) %</h5>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
        <div class="card text-white bg-warning">
            <div class="card-body pb-0 text-right">
                <div class="row">
                    <div class="col-sm-2 text-left">
                        <i class="fa fa-hand-peace-o fa-2x"></i>
                    </div>
                    <div class="col-sm-10 text-right">
                        <h5>Responsabilidad social</h5>
                        <h5>{{kpi.sr | currency}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
        <div class="card text-white bg-success">
            <div class="card-body pb-0 text-right">
                <div class="row">
                    <div class="col-sm-2 text-left">
                        <i class="fa fa-user fa-2x"></i>
                    </div>
                    <div class="col-sm-10 text-right">
                        <h5>Usuarios activos</h5>
                        <h5>{{kpi.active_users}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 col-sm-6 col-md-6 col-lg-3">
        <div class="card text-white bg-danger">
            <div class="card-body pb-0 text-right">
                <div class="row">
                    <div class="col-sm-2 text-left">
                        <i class="fa fa-warning fa-2x"></i>
                    </div>
                    <div class="col-sm-10 text-right">
                        <h5>Deudas</h5>
                        <h5>{{kpi.debts | currency}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>