import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var GalleryService = /** @class */ (function () {
    function GalleryService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    GalleryService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/posts", params);
    };
    GalleryService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/posts/" + id);
    };
    GalleryService.prototype.getPostsDataTable = function (params) {
        return this.http.post(this.baseUrl + "/posts/getPostsDataTable", params);
    };
    GalleryService.prototype.getPostImage = function (id) {
        return this.http.get(this.baseUrl + "/post/" + id + "/getPostImage");
    };
    GalleryService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/posts/" + id);
    };
    GalleryService.prototype.update = function (id, params) {
        return this.http.put(this.baseUrl + "/posts/" + id, params);
    };
    GalleryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GalleryService_Factory() { return new GalleryService(i0.ɵɵinject(i1.HttpClient)); }, token: GalleryService, providedIn: "root" });
    return GalleryService;
}());
export { GalleryService };
