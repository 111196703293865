<div class="container-fluid border-bottom bg-white p-3 mb-4 col-sm-12 col-md-12">
    <div class="row">
        <div class="col-sm-3">
            <h3>Sales by Campaign Facebook<span class="fa fa-info-circle fa-1x" (click)="showInfoGraphic()"
                    data-toggle="tooltip" data-placement="right" title="How many closed won, lost and valid leads based on origin of Facebook's campaigns filtered by date range.
                    Close Rate: Closed Won/Closed Lost"></span>
            </h3>
        </div>
        <!-- Filters -->
        <div class="col-sm-3">
            <input class="form-control" placeholder="Desde" [formControl]="fieldFrom" ngbDatepicker #d="ngbDatepicker"
                (click)="d.toggle()">
        </div>
        <div class="col-sm-3">
            <input class="form-control" placeholder="Hasta" [formControl]="fieldTo" ngbDatepicker #dd="ngbDatepicker"
                (click)="dd.toggle()">
        </div>
        <div class="col-sm-3">
            <button class="btn btn-primary" type="button" (click)="search()">Buscar</button>
        </div>
    </div>
    <!-- Bar Chart -->
    <div class="container-fluid border-bottom bg-white p-3 mb-4">
        <div style="display: block" *ngIf="!isLoad  && barChartData.length > 0" class="chart">
            <canvas baseChart [colors]="barChartColors" [datasets]="barChartData" [labels]="barChartLabels"
                [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
                [chartType]="barChartType">
            </canvas>
        </div>
    </div>
</div>