import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService } from '../../../services/broadcast.service';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SwalService } from '../../../services/swal.service';
import { CategoriesService } from '../../../services/categories.service';
import { SharedComponent } from '../../../model/shared-component';
import { CreateEditCategoryComponent } from './create-edit-category/create-edit-category.component';
import { ElementsComponent } from './elements/elements.component';
import { PermissionsService } from '../../../services/permissions.service';

@Component({
  selector: 'app-extras',
  templateUrl: './extras.component.html',
  styleUrls: ['./extras.component.scss']
})
export class ExtrasComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  dataTableConfig: any;
  private readonly baseDataTableConfig = {
    config: {
      base: this.categoriesService,
      api: 'getCategories'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text',
        orderable: false
      },
      {
        display: 'Descripción',
        field: 'description',
        type: 'text',
        orderable: false
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-info',
            icon: 'fa fa-list fa-fw',
            name: 'Elementos',
            event: 'category.element',
            conditionality: true,
            permissions: ['list-elements']
          },
          {
            cssClass: 'btn btn-primary',
            icon: 'fa fa-pencil-square-o fa-fw',
            name: 'Editar',
            event: 'category.edit',
            conditionality: true,
            permissions: ['update-categories']
          },
          {
            cssClass: 'btn btn-danger',
            icon: 'fa fa-trash fa-fw',
            name: 'Borrar',
            event: 'category.delete',
            conditionality: true,
            permissions: ['delete-categories']
          }
        ]
      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly categoriesService: CategoriesService,
    private readonly broadcast: BroadcastService,
    private readonly appModal: ModalComponent,
    private readonly swal: SwalService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        // case 'create.element': this.createElementItem(event.data); break;
        case 'category.element': this.showElementsItem(event.data); break;
        case 'category.edit': this.editCategoryItem(event.data); break;
        case 'category.delete': this.deleteCategoryItem(event.data); break;
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  createCategoryItem(): void {
    const props: SharedComponent = new SharedComponent(
      CreateEditCategoryComponent,
      {
        status: 'create'
      },
      {
        title: 'Crear Categoria'
      }
    );
    this.appModal.open(props);
  }

  editCategoryItem(data): void {
    const props: SharedComponent = new SharedComponent(
      CreateEditCategoryComponent,
      {
        categoryData: data,
        status: 'edit'
      },
      {
        title: `Edición de la categoria ${data.name}`
      }
    );
    this.appModal.open(props);
  }

  deleteCategoryItem(data): void {
    const swalParams = {
      title: `¿Estás seguro de querer eliminar la categoria ${data.name}?`
    };

    this.swal.warning(swalParams).then(result => {
      if (result.value) {
        this.subscriptions.push(this.categoriesService.delete(data.id_categories).subscribe((resp: any) => {
          if (resp.success) {
            this.swal.success({ title: 'Categoria eliminada exitosamente' }).then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error('Ocurrio un error al intentar borrar la categoria');
          }
        }));
      }
    });
  }

  showElementsItem(data): void {
    const props: SharedComponent = new SharedComponent(ElementsComponent, data, { title: `Elementos de la categoria ${data.name}` });
    this.appModal.openXl(props);
  }
}
