<div class="container-fluid bg-white p-3">
  <ngb-tabset>
    <ngb-tab title="Cash" *ngxPermissionsOnly="['list-cash']">
      <ng-template ngbTabContent>
        <app-cash-table></app-cash-table>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Campaign" *ngxPermissionsOnly="['list-campaign']">
      <ng-template ngbTabContent>
        <app-money-spent-campaign></app-money-spent-campaign>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Advertising" *ngxPermissionsOnly="['list-ads']">
      <ng-template ngbTabContent>
        <app-advertising-table></app-advertising-table>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Satisfacción del cliente" *ngxPermissionsOnly="['list-satisfaction']">
      <ng-template ngbTabContent>
        <app-satisfaction-table></app-satisfaction-table>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Net Promoter Score " *ngxPermissionsOnly="['list-nps']">
      <ng-template ngbTabContent>
        <app-nps-table></app-nps-table>
      </ng-template>
    </ngb-tab>    
  </ngb-tabset>
</div>
