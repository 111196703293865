import { AdministratorsComponent } from './administrators/administrators.component';
import { AppUsageComponent } from './app-usage/app-usage.component';
import { RolesComponent } from './roles/roles.component';
import { UsersComponent } from './users/users.component';
var ɵ0 = { role: 'Administrator', name: 'Administrador' }, ɵ1 = { role: 'Technician', name: 'Técnico' };
export var AdministrationRoutes = [
    {
        path: 'administradores',
        data: ɵ0,
        component: AdministratorsComponent
    },
    {
        path: 'tecnicos',
        data: ɵ1,
        component: AdministratorsComponent
    },
    {
        path: 'app-usage',
        component: AppUsageComponent
    },
    {
        path: 'usuarios',
        component: UsersComponent
    },
    {
        path: 'roles',
        component: RolesComponent
    }
];
export { ɵ0, ɵ1 };
