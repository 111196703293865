import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { CommissionsService } from '../../../../services/commissions.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-client-invalid',
  templateUrl: './client-invalid.component.html',
  styleUrls: ['./client-invalid.component.scss']
})
export class ClientInvalidComponent implements OnInit, OnDestroy {
  @Input() data: any;
  declined: any;
  subscriptions: Array<Subscription> = [];
  constructor(
    private readonly commissionService: CommissionsService,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.subscriptions.push(this.commissionService.getDeclined(this.data.id_commissions).subscribe((resp: any) => {
      this.declined = resp;
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

}
