/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./gallery.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-permissions";
import * as i3 from "@angular/common";
import * as i4 from "../../../components/datatable/datatable.component.ngfactory";
import * as i5 from "../../../components/datatable/datatable.component";
import * as i6 from "../../../services/broadcast.service";
import * as i7 from "./gallery.component";
import * as i8 from "../../../components/modal/modal.component";
import * as i9 from "@ng-bootstrap/ng-bootstrap";
import * as i10 from "../../../services/swal.service";
import * as i11 from "ngx-lightbox/lightbox.service";
import * as i12 from "../../../services/gallery.service";
import * as i13 from "../../../services/permissions.service";
var styles_GalleryComponent = [i0.styles];
var RenderType_GalleryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GalleryComponent, data: {} });
export { RenderType_GalleryComponent as RenderType_GalleryComponent };
function View_GalleryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createPost() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Crear post"]))], null, null); }
function View_GalleryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "col text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_GalleryComponent_2)), i1.ɵdid(2, 737280, null, 0, i2.NgxPermissionsDirective, [i2.NgxPermissionsService, i2.NgxPermissionsConfigurationService, i2.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(3, 1)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "create-posts"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_GalleryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Galeria"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GalleryComponent_1)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "app-datatable", [], null, null, null, i4.View_DatatableComponent_0, i4.RenderType_DatatableComponent)), i1.ɵdid(9, 245760, null, 0, i5.DatatableComponent, [i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i6.BroadcastService], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isDistributor; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.dataTableConfig; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_GalleryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-gallery", [], null, null, null, View_GalleryComponent_0, RenderType_GalleryComponent)), i1.ɵdid(1, 245760, null, 0, i7.GalleryComponent, [i8.ModalComponent, i9.NgbActiveModal, i10.SwalService, i11.Lightbox, i6.BroadcastService, i12.GalleryService, i13.PermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GalleryComponentNgFactory = i1.ɵccf("app-gallery", i7.GalleryComponent, View_GalleryComponent_Host_0, {}, {}, []);
export { GalleryComponentNgFactory as GalleryComponentNgFactory };
