<div class="col-md-12 content">
    <history-ticket-badge [item]="item"></history-ticket-badge>
    <dl class="row">
        <dt class="col-md-4">Código de error</dt>
        <dd class="col-md-8">{{item.error_code.category}} - {{item.error_code.name}} - {{item.error_code.code}}</dd>
        <dt class="col-md-4">Razones</dt>
        <dd class="col-md-8">{{ item.reason || 'Sin cargos extra' }}</dd>
    </dl>
</div>

