import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, forkJoin } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class SuppliesService {
    baseUrl = `${environment.apiUrl}`;

    constructor(
        private readonly http: HttpClient
    ) { }

    /**
     * createSupply
     * Manda los parametros para crear una nueva categoria de insumo
     * @param params Object {name, data}
     * @returns 200 | 400 | 500
     */
    createSupply(params): Observable<any> {
        return this.http.post(`${this.baseUrl}/supplies`, params);
    }

    /**
     * updateSupply
     * Manda los parametros para actualizar los datos de un insumo
     * @param id int ID del supplyCategory a actualizar.
     * @param params Object {name, data}
     * @returns 200 | 400 | 404 | 500
     */
    updateSupply(id, params): Observable<any> {
        return this.http.put(`${this.baseUrl}/supplies/${id}`, params);
    }

    /**
     * showSupply
     * Devuelve la informacion de un supply
     * @param id int ID del supply.
     * @returns 200 | 404
     */
    showSupply(id, params?): Observable<any> {
        return this.http.get(`${this.baseUrl}/supplies/${id}`, params);
    }

    /**
     * getSuppliesDataTable
     * Obtiene los datos para mostrar la tabla de insumos
     * @param params 
     * @param filters 
     * @returns JSON object
     */
    getSuppliesDataTable(params?, filters?): Observable<any> {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(`${this.baseUrl}/supplies/getSuppliesDataTable`, params);
    }

    /**
     * createSupplyInput
     * Manda los parametros para registrar una nueva entrada al insumo y
     * actualizar su stock insumo
     * @param params Object {name, data}
     * @returns 200 | 400 | 500
     */
    createSupplyInput(params): Observable<any> {
        return this.http.post(`${this.baseUrl}/supplies/inputs`, params);
    }

    /**
     * indexCategoriesSupplies
     * Devuelve todas las categorias de insumos
     * @param id int ID del supply.
     * @returns 200 | 404
     */
    indexCategoriesSupplies(): Observable<any> {
        return this.http.get(`${this.baseUrl}/supplies/categories`);
    }

    /**
     * createCategorySupply
     * Manda los parametros para crear una nueva categoria de insumo
     * @param params Object {name, data}
     * @returns 200 | 400 | 500
     */
    createSupplyCategory(params): Observable<any> {
        return this.http.post(`${this.baseUrl}/supplies/categories`, params);
    }

    /**
     * updateCategorySupply
     * Manda los parametros para actualizar los datos una categoria de insumo
     * @param id int ID del supplyCategory a actualizar.
     * @param params Object {name, data}
     * @returns 200 | 400 | 404 | 500
     */
    updateSupplyCategory(id, params): Observable<any> {
        return this.http.put(`${this.baseUrl}/supplies/categories/${id}`, params);
    }

    /**
     * showSupplyCategory
     * Devuelve la informacion de un supplyCategory
     * @param id int ID del supplyCategory.
     * @returns 200 | 404
     */
    showSupplyCategory(id, params?): Observable<any> {
        return this.http.get(`${this.baseUrl}/supplies/categories/${id}`, params);
    }

    /**
     * getCategoriesSuppliesDataTable
     * Obtiene los datos para mostrar la tabla de categoria de insumos
     * @param params 
     * @param filters 
     * @returns JSON object
     */
    getCategoriesSuppliesDataTable(params?, filters?): Observable<any> {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(`${this.baseUrl}/supplies/categories/getCategoriesSuppliesDataTable`, params);
    }

    /**
     * getHistory
     * Obtiene el historial de todas las entradas y salidas de un insumo.
     * @param id id del supply
     * @returns forkjoin
     */
    getHistory(id): Observable<any> {
        const params = {
            params: { id }
        };

        const historyItems = [
            this.http.get(`${this.baseUrl}/supplies/inputs`, params),
            this.http.get(`${this.baseUrl}/supplies/outputs`, params)
        ];

        return forkJoin(historyItems);
    }
}