<div class="modal-body">
    <h4 *ngIf="data.type === 'installation'"class="text-center">
        Atención estas por marcar un ticket de instalación como no realizado 
        por lo que el cliente pasara a tener el estatus de cancelado
    </h4>
    <div class="row d-block">
        <div class="col-sm-12">
            <app-form-field type="multiselect" [options]="{
                values: error_codes,
                label: 'Codigo de error',
                placeholder: 'Selecciona el codigo de error',
                searchable: true,
                virtualScroll: true,
                path: 'id_error_codes'
            }">
            </app-form-field>
        </div>
        <div class="col-sm-12">
            <app-form-field type="textarea" styleInput="textarea"
                [options]="{
                    label: 'Razones por las que no se realiza el servicio (Opcional)',
                    rows: '4', path:'reason', width: true
                }">
            </app-form-field>
        </div>
    </div>

</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('close button')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" (click)="unrealizedTicket()" [disabled]="form.invalid">Marcar como no realizado</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>