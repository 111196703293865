<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <div class="col-sm-8">
        <h2 class="m-0">Notificaciones</h2>
    </div>
</div>
<div class="container-fluid bg-white p-3">
    <ngb-tabset>
        <ngb-tab title="Notificar usuario" *ngxPermissionsOnly="['notify-user']">    
            <ng-template ngbTabContent>
              <app-notify-user></app-notify-user>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="Notificar grupo" *ngxPermissionsOnly="['notify-group']">
            <ng-template ngbTabContent>
              <app-notify-group></app-notify-group>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="Notificar grupos(Mixtos)" *ngxPermissionsOnly="['list-notification-group']">
            <ng-template ngbTabContent>
                <div class="row group">
                  <button class="btn btn-success add" (click)="groupNew()" *ngxPermissionsOnly="['create-notification-group']">Crear grupo</button>
                  <app-datatable [options]="dataTableConfig"></app-datatable>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>