import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'history-ticket-closed',
  templateUrl: './closed.component.html',
  styleUrls: ['./closed.component.scss']
})
export class ClosedComponent implements OnInit {
  @Input('item') item: any;

  ngOnInit(): void {
    //
  }

}
