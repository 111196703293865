/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./extras.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "ngx-permissions";
import * as i3 from "../../../components/datatable/datatable.component.ngfactory";
import * as i4 from "../../../components/datatable/datatable.component";
import * as i5 from "../../../services/broadcast.service";
import * as i6 from "./extras.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap";
import * as i8 from "../../../services/categories.service";
import * as i9 from "../../../components/modal/modal.component";
import * as i10 from "../../../services/swal.service";
import * as i11 from "../../../services/permissions.service";
var styles_ExtrasComponent = [i0.styles];
var RenderType_ExtrasComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ExtrasComponent, data: {} });
export { RenderType_ExtrasComponent as RenderType_ExtrasComponent };
function View_ExtrasComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-success"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.createCategoryItem() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Crear categoria"]))], null, null); }
export function View_ExtrasComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cargos extras a contratos"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col text-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ExtrasComponent_1)), i1.ɵdid(7, 737280, null, 0, i2.NgxPermissionsDirective, [i2.NgxPermissionsService, i2.NgxPermissionsConfigurationService, i2.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(8, 1), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 16777216, null, null, 1, "app-datatable", [], null, null, null, i3.View_DatatableComponent_0, i3.RenderType_DatatableComponent)), i1.ɵdid(11, 245760, null, 0, i4.DatatableComponent, [i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i5.BroadcastService], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 8, 0, "create-categories"); _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.dataTableConfig; _ck(_v, 11, 0, currVal_1); }, null); }
export function View_ExtrasComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-extras", [], null, null, null, View_ExtrasComponent_0, RenderType_ExtrasComponent)), i1.ɵdid(1, 245760, null, 0, i6.ExtrasComponent, [i7.NgbActiveModal, i8.CategoriesService, i5.BroadcastService, i9.ModalComponent, i10.SwalService, i11.PermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExtrasComponentNgFactory = i1.ɵccf("app-extras", i6.ExtrasComponent, View_ExtrasComponent_Host_0, {}, {}, []);
export { ExtrasComponentNgFactory as ExtrasComponentNgFactory };
