import * as tslib_1 from "tslib";
import { NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
var pad = function (i) { return i < 10 ? "0" + i : "" + i; };
var ɵ0 = pad;
var NgbTimeStringAdapter = /** @class */ (function (_super) {
    tslib_1.__extends(NgbTimeStringAdapter, _super);
    function NgbTimeStringAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NgbTimeStringAdapter.prototype.fromModel = function (value) {
        if (!value) {
            return null;
        }
        var split = value.split(':');
        return {
            hour: parseInt(split[0], 10),
            minute: parseInt(split[1], 10),
            second: parseInt(split[2], 10)
        };
    };
    NgbTimeStringAdapter.prototype.toModel = function (time) {
        return time != null ? pad(time.hour) + ":" + pad(time.minute) + ":" + pad(time.second) : null;
    };
    return NgbTimeStringAdapter;
}(NgbTimeAdapter));
export { NgbTimeStringAdapter };
export { ɵ0 };
