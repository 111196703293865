<div class="container-fluid bg-white p-3">
    <div class="text-right" *ngIf="client">
        <button type="button" class="btn btn-success" (click)="assignPromotionalMonthlyFee(client)"
            *ngxPermissionsOnly="['create-promotional-monthly-fees']">Dar promoción</button>
    </div>
    <app-datatable [options]="dataTableConfig"></app-datatable>

</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('close button')">Close</button>
</div>
