import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var CreditsService = /** @class */ (function () {
    function CreditsService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    /**
     * createCredit
     * @param params Objeto con id_client:int, extra_data: string, expirable: boolean, amount: int
     * @returns 200 | 400 | 404 | 500
     */
    CreditsService.prototype.createCredit = function (params) {
        return this.http.post(this.baseUrl + "/credit", params);
    };
    /**
     * createCredit
     * @param params Objeto con id_client:int, extra_data: string, expirable: boolean, amount: int
     * @returns 200 | 400 | 404 | 500
     */
    CreditsService.prototype.cancellCredit = function (params) {
        var id_credit = params.id_credit;
        return this.http.post(this.baseUrl + "/credit/" + id_credit + "/cancel", params);
    };
    /**
     * creditsTable
     * @param id_clients id del cliente
     * @param params parametros de filtrado de la tabla
     * @returns json con la información de la tabla
     */
    CreditsService.prototype.getClientCreditsTable = function (params, filters) {
        var id_clients = 0;
        if (filters) {
            id_clients = filters.id_clients;
        }
        return this.http.post(this.baseUrl + "/credit/clientCreditsTable/" + id_clients, params);
    };
    /**
     * creditsTable
     * @param id_clients id del cliente
     * @param params parametros de filtrado de la tabla
     * @returns json con la información de la tabla
     */
    CreditsService.prototype.getCreditHistoryTable = function (params, filters) {
        var id_credit = 0;
        if (filters) {
            id_credit = filters.id_credit;
        }
        return this.http.post(this.baseUrl + "/credit/creditHistoryTable/" + id_credit, params);
    };
    CreditsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CreditsService_Factory() { return new CreditsService(i0.ɵɵinject(i1.HttpClient)); }, token: CreditsService, providedIn: "root" });
    return CreditsService;
}());
export { CreditsService };
