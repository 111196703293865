<div class="container-fluid border-bottom bg-white p-3 mb-4 col-sm-12 col-md-12">
    <div class="row">
        <div class="col-sm-3">
            <h3>Sales by Promotion <span class="fa fa-info-circle fa-1x" (click)="showInfoGraphic()"
                    data-toggle="tooltip" data-placement="right" title="How many closed won, lost and valid leads based on promotions of lead generated filtered by date range.
                    Close Rate: Closed Won/Closed Lost"></span>
            </h3>
        </div>
        <!-- Filters -->
        <div class="col-sm-2">
            <app-form-field type="datepicker" [options]="{path:'from', placeholder:'Desde'}"></app-form-field>
        </div>
        <div class="col-sm-2">
            <app-form-field type="datepicker" [options]="{path:'to', placeholder: 'Hasta'}"></app-form-field>
        </div>
        <div class="col-sm-3">
            <app-form-field type="select" [options]="{
                            label: 'Lead Type',
                            path: 'leadType',
                            droptext: 'Escoja una opción',
                            elements: leadTypes
                        }"></app-form-field>
        </div>
        <div class="col-sm-2">
            <button class="btn btn-primary" type="button" (click)="search()">Buscar</button>
        </div>
    </div>
    <!-- Bar Chart -->
    <div class="container-fluid border-bottom bg-white p-3 mb-4">
        <div style="display: block" *ngIf="!isLoad && barChartData.length > 0" class="chart">
            <canvas baseChart [colors]="barChartColors" [datasets]="barChartData" [labels]="barChartLabels"
                [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
                [chartType]="barChartType">
            </canvas>
        </div>
    </div>
</div>