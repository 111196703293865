import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datatable-column-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {
  @Input() data: any;
  @Input() options: any;

  dictionary = {
    in_trial: 'Periodo de prueba',
    active: 'Activa',
    paused: 'Pausada',
    past_due: 'Fallando',
    canceled: 'Cancelado',
    invalid: 'Inválido',
    rejected: 'Rechazado',
    accepted: 'Aceptado',
    standby: 'Pendiente'
  };

  classess = {
    in_trial: 'primary',
    active: 'success',
    paused: 'info',
    past_due: 'warning',
    canceled: 'danger',
    invalid: 'warning',
    rejected: 'danger',
    accepted: 'success',
    standby: 'secondary'
  };

  text: string;
  badgeClass: string;

  ngOnInit(): void {
    const dataField = this.options.field;
    const txt = this.data[dataField];
    this.text = txt in this.dictionary ? this.dictionary[txt] : this.options.options.emptyText;
    if (this.text === '') {
      this.text = txt;
    }
    this.badgeClass = txt in this.classess ? this.classess[txt] : 'default';
  }

}
