import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { FromService } from '../../../providers/form.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { CommissionsService } from '../../../services/commissions.service';
import { SwalService } from '../../../services/swal.service';
import { ClientInvalidComponent } from './client-invalid/client-invalid.component';

@Component({
  selector: 'app-commissions',
  templateUrl: './commissions.component.html',
  styleUrls: ['./commissions.component.scss']
})
export class CommissionsComponent implements OnInit, OnDestroy {
  dataTableConfig = {
    config: {
      base: this.commissionsService,
      api: 'getCommissionsTable',
      params: {
        from: '',
        to: ''
      },
      order: [[1, 'desc']]
    },
    columns: [
      {
        display: 'Nombre',
        field: 'id_commissions',
        type: 'client_commission'
      },
      {
        display: 'Periodo',
        field: 'calculated_at',
        type: 'period_date'
      },
      {
        display: 'Estatus',
        field: 'paid_at',
        type: 'commission_status',
        orderable: false
      },
      {
        display: 'Desglose',
        field: 'id_commissions',
        type: 'commission_summary',
        orderable: false
      },
      {
        display: '',
        field: '',
        type: 'inline-button',
        orderable: false,
        options: [
          {
            cssClass: 'btn btn-danger btn-block',
            icon: 'fa fa-exclamation-triangle fa-fw fa-lg',
            name: 'Clientes inválidados',
            event: 'client.declined',
            conditionality: 'this.data.declined !== null'
          },
          {
            cssClass: 'btn btn-info btn-block',
            icon: 'fa fa-money fa-fw fa-lg',
            name: 'Pagar',
            event: 'commission.registerAsPaid',
            conditionality: 'this.data.paid_at === null'
          }
        ]
      }
    ],
    filters: [
      {
        title: 'Estatus del cliente',
        cssClass: 'col-md-4',
        type: 'is_active',
        options: [
          {
            val: '',
            name: 'TODOS'
          },
          {
            val: '1',
            name: 'Activo'
          },
          {
            val: '0',
            name: 'Inactivo'
          }
        ]
      },
      {
        title: 'Nivel del cliente',
        cssClass: 'col-md-4',
        type: 'level',
        options: [
          {
            val: '',
            name: 'TODOS'
          },
          {
            val: 'MARINERO',
            name: 'Marinero'
          },
          {
            val: 'CABO',
            name: 'Cabo'
          },
          {
            val: 'CAPITAN',
            name: 'Capitan'
          },
          {
            val: 'ALMIRANTE',
            name: 'Almirante'
          }
        ]
      },
      {
        title: 'Estatus de la comisión',
        cssClass: 'col-md-4',
        type: 'paid_at',
        options: [
          {
            val: '',
            name: 'TODOS'
          },
          {
            val: 'paid',
            name: 'Pagada'
          },
          {
            val: 'nopaid',
            name: 'Sin pagar'
          }
        ]
      }
    ]
  };

  form: FormGroup = this.formBuilder.group({
    from: [],
    to: []
  });

  subscriptions: Array<Subscription> = [];
  constructor(
    private readonly commissionsService: CommissionsService,
    private readonly broadcast: BroadcastService,
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    public swal: SwalService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'client.declined': this.clientDeclinedItem(event.data); break;
        case 'commission.registerAsPaid': this.registerAsPaidItem(event.data); break;
      }
    }));
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  search(): void {
    const from = this.form.get('from').value;
    const to = this.form.get('to').value;
    const params = {
      from,
      to,
      multi: true
    };
    this.broadcast.fire({
      name: 'datatable-filter',
      data: params
    });
  }

  clientDeclinedItem(data): void {
    const props: SharedComponent = new SharedComponent(ClientInvalidComponent, data, { title: 'Clientes rechazados para el cálculo de la comisión' });
    this.appModal.openXl(props);
  }

  registerAsPaidItem(data): void {
    const params = {};
    this.swal.warning({ title: '¿ Estás seguro de marcar la comisión como pagada ?' }).then(result => {
      if (result.value) {
        this.subscriptions.push(this.commissionsService.registerAsPaid(data.id_commissions, params).subscribe((resp: any) => {
          if (resp.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
            });
          } else {
            this.swal.error({ title: 'Ocurrió un error al actualizar los datos' });
          }
        }));
      }
    });
  }
}
