<p class="m-b-xs">
</p>
<ng-container [ngSwitch]="item.type">
    <app-corrections *ngSwitchCase="'corrections'" [item]="item"></app-corrections>
    <app-bad-status *ngSwitchCase="'invalidations'" [item]="item"></app-bad-status>
    <app-bad-status *ngSwitchCase="'cancellations'" [item]="item"></app-bad-status>
    <app-bad-status *ngSwitchCase="'rejections'" [item]="item"></app-bad-status>
    <app-notifications *ngSwitchCase="'notifications'" [item]="item"></app-notifications>
    <app-client-history-change *ngSwitchCase="'client_log'" [item]="item"></app-client-history-change>
    <app-client-history-change *ngSwitchCase="'client_log_prices'" [item]="item"></app-client-history-change>
</ng-container>
