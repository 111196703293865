import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../providers/form.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { ChargesService } from '../../../services/charges.service';
import { SwalService } from '../../../services/swal.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ClientsService } from '../../../services/clients.service';
import { HelpersService } from '../../../services/helpers.service';
import { monthsNumber } from '../../../constants/months';

@Component({
  selector: 'app-unique-payment',
  templateUrl: './unique-payment.component.html',
  styleUrls: ['./unique-payment.component.scss']
})
export class UniquePaymentComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  subAccounts = [];
  accounts = [];
  accountsWithDebts = false;
  showMultipleAccounts = false;
  parentAccount: any;
  parentAccountName: string;
  uniqueCharge = false;
  totalUniqueCharge = 0;
  paymentType: string;
  offline_format: any;
  loaded: boolean = false;
  months_ahead = environment.months_ahead;
  payment_types = environment.payment_types;
  monthsNumber = monthsNumber;
  whatsAppMessage: string = '';

  form: FormGroup = this.formBuilder.group({
    months_ahead: ['', Validators.required],
    payment_types: ['', Validators.required],
    unique_charge: [true],
    charges: [''],
    total: [0]
  });

  constructor(
    public activeModal: NgbActiveModal,
    public modal: NgbModal,
    private readonly clientsService: ClientsService,
    private readonly chargesService: ChargesService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly helpersService: HelpersService,
    private readonly broadcast: BroadcastService,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    this.getAccountsData();
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
  * manualPayment
  * Envia a la API los parametros para generar un cargo OXXO/SPEI/Tarjeta ya sea para primer pago, pago de deuda, pago de meses adelantados
  */
  manualPayment(): void {
    this.paymentType = this.form.get('payment_types').value;
    if (this.form.valid) {
      this.form.get('total').setValue(this.totalManualCharge());
      this.subscriptions.push(this.chargesService.createManualCharge(this.form.value).subscribe((resp: any) => {
        if (resp.success) {
          this.showSuccessModal(this.paymentType, resp);
          this.activeModal.dismiss();
        } else {
          this.swal.error({ title: 'Ocurrio un error al momento de generar el pago' });
        }
      }));
    }
  }

  /**
   * totalManualCharge
   * Calcula el total del pago a generar
   * @returns total del pago
   */
  totalManualCharge(): number {
    let total = 0;
    if (this.loaded) {
      const params = {
        idChargeType: 6,
        accounts: this.accounts
      };

      if (!this.accountsWithDebts) {
        Object.assign(params, { months_ahead: this.form.get('months_ahead').value });
      }

      total = this.chargesService.calculateTotalForManual(params);
    }

    return Math.round(total * 100) / 100;
  }

  sendByWhatsApp() {
    this.helpersService.sendByWhatsapp(this.whatsAppMessage, this.data.client.phone);
  }

  private getAccountsData(): void {
    this.subscriptions.push(this.clientsService.show(this.data.id_clients).subscribe((resp: any) => {
      this.data = resp.response;
      this.setAccountsData();
    }));
  }

  /**
   * setAccountsData
   * Inicializa los valores de las cuentas a cobrar
   */
  private setAccountsData(): void {
    this.parentAccount = this.formatChargesData(this.data);
    this.accounts.push(this.parentAccount);

    if (this.data.sub_accounts.length > 0) {
      this.subAccounts = this.data.sub_accounts.map(sub => {
        const subAccount = this.formatChargesData(sub);
        if (subAccount) {
          return subAccount;
        }
      });
      this.accounts.push(...this.subAccounts);
      this.form.controls.charges.setValue(this.accounts);
    }

    this.loaded = true;
  }

  /**
   * formatChargeData
   * Evalua a las cuentas asociadas a una misma familia para generar el objeto base 
   * de los cargos que incluira cada una de las cuentas validas
   * @param data información del cliente
   * @returns formated data {idChargeType: number, id_clients: number, fixed_monthly_fee: number, name: string, text: string, debts?: array}
   */
  private formatChargesData(data): object {
    let formatedData = {};
    if (data.status === 'accepted' && data.completed_installation) {
      const pendingDebts = data.pending_debts.length;
      let text = 'Mensualidad';
      let idChargeType = 2;
      let fixedMonthlyFee = data.fixed_monthly_fee;
      let date = data.next_payday;
      if (pendingDebts > 0) {
        const evaluateDebts = this.chargesService.evaluateDebts(data.pending_debts);
        fixedMonthlyFee = evaluateDebts.totalDebt;
        idChargeType = evaluateDebts.idChargeType;
        text = 'Pago tardio';
        date = data.pending_debts[0].monthly_charge_date;
        text = pendingDebts === 1 ? text : `${text} acumulado`;

        if (!this.accountsWithDebts) {
          this.accountsWithDebts = true;
          if (this.form.get('months_ahead')) {
            this.form.removeControl('months_ahead');
          }
        }
      }

      formatedData = {
        id_clients: data.id_clients,
        fixed_monthly_fee: fixedMonthlyFee,
        name: data.name,
        id_charge_type: idChargeType,
        text
      };

      if (pendingDebts < 2) {
        const formatedDate = this.helpersService.formatDate(date);
        formatedData['text'] = `${text} (${formatedDate.getFullYear()} - ${this.monthsNumber[formatedDate.getMonth()]})`;
      } else {
        Object.assign(formatedData, { acumulatedLatePayment: true, debts: data.pending_debts });
      }

    }

    return formatedData;
  }

  /**
  * showSuccessModal
  * Muestra el modal de exito cuando una referencia OXXO|SPEI o cargo por tarjeta es efectuado exitosamente.
  * @param paymentType tipo de cargo que se realizo Tarjeta|OXXO|SPEI
  * @param resp unicamente se usa si el cargo fue un OXXO|SPEI para mostra el modal de la información de la referencia.
  */
  private showSuccessModal(paymentType, resp?): void {
    if (paymentType === 'CARD') {
      this.swal.success({ title: 'Transación efectuada exitosamente' }).then(() => {
        this.activeModal.dismiss();
        this.broadcast.reloadDataTable();
      });
    } else {
      const whatsAppButton = `<button type="button" class="btn btn-success" id="sendByWa" title="Enviar por Whatsapp">
                                <i class="fa fa-whatsapp"></i>
                              </button>`;
      this.offline_format = this.chargesService.formatMessage(resp, paymentType);
      this.whatsAppMessage = this.offline_format.copyText;

      const data = {
        title: this.offline_format.title,
        html: this.offline_format.msg + whatsAppButton,
        onBeforeOpen: () => {
          const sendByWa = document.getElementById('sendByWa');
          sendByWa.addEventListener('click', () => {
            this.sendByWhatsApp();
          });
        }
      };
      this.swal.success(data).then(() => {
        this.activeModal.dismiss();
        this.broadcast.reloadDataTable();
      });
    }
  }
}
