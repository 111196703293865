import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class TaxService {
    private readonly baseUrl = `${environment.apiUrl}`;
    constructor(private readonly http: HttpClient) { }

    /**
     * index
     * Solicita a la API la lista de situaciones fiscales.
     * @returns 200 | 404 | 500
     */
    index(): any {
        return this.http.get(`${this.baseUrl}/tax/situations`);
    }

    getTaxData(): Observable<any> {
        const tax_situations =  this.http.get(`${this.baseUrl}/tax/situations`);
        const tax_cfdi = this.http.get(`${this.baseUrl}/tax/cfdi`);

        return forkJoin([tax_situations, tax_cfdi]);
    }
}
