import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DebtsService {
  baseUrl = `${environment.apiUrl}`;

  constructor(private readonly http: HttpClient) { }

  getClientDebts(params?, filters?): any {
    let id = 0;
    if (filters) {
      id = filters.id_clients;
    }
    
    Object.assign(params, filters);
    
    return this.http.post(`${this.baseUrl}/debts/${id}/getClientDebtsTable`, params);
  }

  chargeDebt(id?, params?): any {
    return this.http.post(`${this.baseUrl}/debts/${id}/charge`, params);
  }

  chargeAllDebts(id, params?): any {
    return this.http.post(`${this.baseUrl}/client/${id}/debts/chargeAll`, params);
  }

  generateDebtsReport(): any {
    return this.http.get(`${this.baseUrl}/debts/generate_debts_report`);
  }

  update(id, params): Observable<any> {
    return this.http.put(`${this.baseUrl}/debts/${id}`, params);
  }
}
