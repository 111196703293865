<div class="modal-body">
    <div class="container">
        <span *ngIf="data.status === 'create'">
            <app-form-field type="inputNumber" [options]="{label:'Cantidad', path: 'amount'}"></app-form-field>
            <app-form-field type="inputNumber"
                [options]="{label:'Costo unitario', path: 'unitary_cost'}"></app-form-field>
            <app-form-field type="inputNumber" [options]="{label:'Costo total', path: 'total_cost'}"></app-form-field>
        </span>
    </div>
</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="save()">Guardar</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>
