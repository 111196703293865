import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { SatisfactionService } from '../../../../services/satisfaction.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { SharedComponent } from '../../../../model/shared-component';
import { SwalService } from '../../../../services/swal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SatisfactionFormComponent } from './satisfaction-form/satisfaction-form.component';
import { PermissionsService } from '../../../../services/permissions.service';
var SatisfactionTableComponent = /** @class */ (function () {
    function SatisfactionTableComponent(activeModal, appModal, satisfactionService, broadcast, swal, permissionsService) {
        this.activeModal = activeModal;
        this.appModal = appModal;
        this.satisfactionService = satisfactionService;
        this.broadcast = broadcast;
        this.swal = swal;
        this.permissionsService = permissionsService;
        this.baseDataTableConfig = {
            config: {
                base: this.satisfactionService,
                api: 'getSatisfaction'
            },
            columns: [
                {
                    display: 'Período',
                    field: 'date',
                    type: 'date'
                },
                {
                    display: '% Satisfacción',
                    field: 'satisfaction',
                    type: 'percent'
                },
                {
                    display: 'Acciones',
                    field: '',
                    type: 'actions',
                    options: [
                        {
                            display: 'Editar',
                            icon: 'fa fa-pencil',
                            event: 'satisfaction.edit',
                            conditionality: 'true',
                            permissions: ['update-satisfaction']
                        },
                        {
                            display: 'Eliminar',
                            icon: 'fa fa-trash fa-fw',
                            event: 'satisfaction.delete',
                            conditionality: 'true',
                            permissions: ['delete-satisfaction']
                        }
                    ]
                }
            ]
        };
    }
    SatisfactionTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.broadcast$ = this.broadcast.events.subscribe(function (event) {
            switch (event.name) {
                case 'satisfaction.delete':
                    _this.deleteSatisfactionItem(event.data);
                    break;
                case 'satisfaction.edit':
                    _this.editSatisfactionItem(event.data);
                    break;
            }
        });
        this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
    };
    SatisfactionTableComponent.prototype.ngOnDestroy = function () {
        this.broadcast$.unsubscribe();
    };
    SatisfactionTableComponent.prototype.createSatisfactionItem = function (data) {
        var props = new SharedComponent(SatisfactionFormComponent, {
            satisfactionData: data,
            status: 'create'
        }, {
            title: 'Crear entrada de satisfacción'
        });
        this.appModal.opensm(props);
    };
    SatisfactionTableComponent.prototype.deleteSatisfactionItem = function (data) {
        var _this = this;
        var swalParams = {
            title: "\u00BFEst\u00E1s seguro de querer eliminar la satisfacci\u00F3n del per\u00EDodo " + data.date + "?"
        };
        this.swal.warning(swalParams).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var resp;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!result.value) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.satisfactionService.delete(data.id_satisfaction).toPromise()];
                    case 1:
                        resp = _a.sent();
                        if (resp && resp.success) {
                            this.swal.success({ title: 'Satisfacción eliminada exitosamente' }).then(function () {
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                        else {
                            this.swal.error('Ocurrio un error al intentar borrar la satisfacción');
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    SatisfactionTableComponent.prototype.editSatisfactionItem = function (data) {
        // open modal, passing the context
        var props = new SharedComponent(SatisfactionFormComponent, {
            satisfactionData: data,
            status: 'edit'
        }, {
            title: 'Satisfacción del cliente'
        });
        this.appModal.opensm(props);
    };
    return SatisfactionTableComponent;
}());
export { SatisfactionTableComponent };
