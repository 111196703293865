import { EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Lightbox } from 'ngx-lightbox';
import { FromService } from '../../../providers/form.service';
var FormFieldComponent = /** @class */ (function () {
    function FormFieldComponent(fromService, sanitizer, lightbox) {
        this.fromService = fromService;
        this.sanitizer = sanitizer;
        this.lightbox = lightbox;
        this.checkboxValue = new EventEmitter();
        this.images = [];
    }
    FormFieldComponent.prototype.ngOnInit = function () {
        if (this.options.path) {
            this.field = this.form.get(this.options.path);
        }
    };
    Object.defineProperty(FormFieldComponent.prototype, "form", {
        get: function () {
            return this.fromService.form;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "isChecked", {
        get: function () {
            return this.field.value === (this.options.hasOwnProperty('trueValue') ? this.options.trueValue : true);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormFieldComponent.prototype, "checked", {
        get: function () {
            var acceptedValues = [1, true];
            return acceptedValues.includes(this.options.default);
        },
        enumerable: true,
        configurable: true
    });
    FormFieldComponent.prototype.reset = function (event) {
        var cards = this.fromService.getForm().get('cards');
        var controls = 'controls';
        if (event.target.value === 'on') {
            cards[controls].forEach(function (card) {
                card.get('default_card').setValue(0);
                if (card.value.id_cards === Number(event.target.id)) {
                    card.get('default_card').setValue(true);
                }
            });
        }
        this.field.markAsTouched();
        this.field.markAsDirty();
    };
    FormFieldComponent.prototype.bgImage = function () {
        return this.image || this.options.image || '/assets/img/misc/default-photo.svg';
    };
    FormFieldComponent.prototype.bgStyle = function (image) {
        var bgImage = image ? image : this.bgImage();
        var imageString = JSON.stringify(bgImage).replace(/\\n/g, '');
        var style = "url(" + imageString + ")";
        return this.sanitizer.bypassSecurityTrustStyle(style);
    };
    FormFieldComponent.prototype.switched = function (e) {
        var target = e.target;
        this.field.setValue(target.checked ?
            (this.options.hasOwnProperty('trueValue') ? this.options.trueValue : true) :
            (this.options.hasOwnProperty('falseValue') ? this.options.falseValue : false));
        this.field.markAsTouched();
        this.field.markAsDirty();
    };
    FormFieldComponent.prototype.readFile = function (e) {
        var _this = this;
        var input = e.target;
        var filesAmount = input.files.length;
        if (input.files && filesAmount > 0) {
            for (var i = 0; i < filesAmount; i++) {
                var reader = new FileReader();
                reader.onload = function (ev) {
                    var targetResult = ev.target.result;
                    _this.image = targetResult;
                    var values = targetResult;
                    if (_this.options.multiple) {
                        _this.images.push(targetResult);
                        values = _this.images;
                    }
                    _this.field.setValue(values);
                };
                reader.readAsDataURL(input.files[i]);
            }
            this.field.markAsTouched();
            this.field.markAsDirty();
        }
    };
    FormFieldComponent.prototype.onCheckChange = function (checkboxValue, event) {
        this.checkboxValue.emit({
            value: checkboxValue,
            checked: event.target.checked
        });
    };
    FormFieldComponent.prototype.maxDate = function () {
        var date = new Date();
        var max_day = 31;
        var max_month = 12;
        var max_year = date.getFullYear() + 1;
        if (this.options.max_day || this.options.max_month || this.options.max_year) {
            if (this.options.max_day) {
                max_day = this.options.max_day;
            }
            if (this.options.max_month) {
                max_month = this.options.max_month;
            }
            if (this.options.max_year) {
                max_year = this.options.max_year;
            }
        }
        return { max_day: max_day, max_month: max_month, max_year: max_year };
    };
    FormFieldComponent.prototype.openLightBox = function (image) {
        // tslint:disable-next-line: no-parameter-reassignment
        image = [{ src: "" + image }];
        this.lightbox.open(image, 0, { showZoom: true });
    };
    return FormFieldComponent;
}());
export { FormFieldComponent };
