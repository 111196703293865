import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Lightbox } from 'ngx-lightbox';
import { FromService } from '../../../providers/form.service';
import { calendarMaxDates } from '../../../model/shared-component.interface';


@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements OnInit {
  @Input() type: string;
  @Input() options: any;
  @Output() checkboxValue: EventEmitter<any> = new EventEmitter();
  field: FormControl;
  images: Array<string> = [];
  private image: string;

  constructor(
    private readonly fromService: FromService,
    private readonly sanitizer: DomSanitizer,
    private readonly lightbox: Lightbox) { }

  ngOnInit(): void {
    if (this.options.path) {
      this.field = this.form.get(this.options.path) as FormControl;
    }
  }

  get form(): FormGroup {
    return this.fromService.form;
  }

  get isChecked(): boolean {
    return this.field.value === (this.options.hasOwnProperty('trueValue') ? this.options.trueValue : true);
  }

  get checked(): boolean {
    const acceptedValues = [1, true];
    return acceptedValues.includes(this.options.default);
  }

  reset(event): void {
    const cards = this.fromService.getForm().get('cards');
    const controls = 'controls';
    if (event.target.value === 'on') {
      cards[controls].forEach(card => {
        card.get('default_card').setValue(0);
        if (card.value.id_cards === Number(event.target.id)) {
          card.get('default_card').setValue(true);
        }
      });
    }
    this.field.markAsTouched();
    this.field.markAsDirty();
  }

  bgImage(): String {
    return this.image || this.options.image || '/assets/img/misc/default-photo.svg';
  }

  bgStyle(image): any {
    const bgImage = image ? image : this.bgImage();
    const imageString = JSON.stringify(bgImage).replace(/\\n/g, '');
    const style = `url(${imageString})`;

    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  switched(e): void {
    const target = e.target;
    this.field.setValue(target.checked ?
      (this.options.hasOwnProperty('trueValue') ? this.options.trueValue : true) :
      (this.options.hasOwnProperty('falseValue') ? this.options.falseValue : false));

    this.field.markAsTouched();
    this.field.markAsDirty();
  }

  readFile(e): void {
    const input = e.target;
    const filesAmount = input.files.length;
    if (input.files && filesAmount > 0) {
      for (let i = 0; i < filesAmount; i++) {
        const reader: FileReader = new FileReader();
        reader.onload = (ev: any) => {
          const targetResult = ev.target.result;
          this.image = targetResult;
          let values = targetResult;
          if (this.options.multiple) {
            this.images.push(targetResult);
            values = this.images
          }
          this.field.setValue(values);
        };
        reader.readAsDataURL(input.files[i]);
      }
      this.field.markAsTouched();
      this.field.markAsDirty();
    }
  }

  onCheckChange(checkboxValue, event): void {
    this.checkboxValue.emit(
      {
        value: checkboxValue,
        checked: event.target.checked
      }
    );
  }

  maxDate(): calendarMaxDates {
    const date = new Date();
    let max_day = 31;
    let max_month = 12;
    let max_year = date.getFullYear() + 1;
    if (this.options.max_day || this.options.max_month || this.options.max_year) {
      if (this.options.max_day) {
        max_day = this.options.max_day;
      }

      if (this.options.max_month) {
        max_month = this.options.max_month;
      }

      if (this.options.max_year) {
        max_year = this.options.max_year;
      }
    }

    return { max_day, max_month, max_year };
  }

  openLightBox(image): void {
    // tslint:disable-next-line: no-parameter-reassignment
    image = [{ src: `${image}` }];
    this.lightbox.open(image, 0, { showZoom: true });
  }
}
