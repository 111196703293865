import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ApiResponse } from '../model/shared-component.interface';

@Injectable({
    providedIn: 'root'
})

export class CreditsService {
    baseUrl = `${environment.apiUrl}`;
    apiresponse: ApiResponse;

    constructor(
        private readonly http: HttpClient
    ) { }

    /**
     * createCredit 
     * @param params Objeto con id_client:int, extra_data: string, expirable: boolean, amount: int
     * @returns 200 | 400 | 404 | 500
     */
    createCredit(params): Observable<object> {
        return this.http.post(`${this.baseUrl}/credit`, params);
    }

    /**
     * createCredit 
     * @param params Objeto con id_client:int, extra_data: string, expirable: boolean, amount: int
     * @returns 200 | 400 | 404 | 500
     */
    cancellCredit(params): Observable<object> {
        const id_credit = params.id_credit;

        return this.http.post(`${this.baseUrl}/credit/${id_credit}/cancel`, params);
    }

    /**
     * creditsTable
     * @param id_clients id del cliente
     * @param params parametros de filtrado de la tabla
     * @returns json con la información de la tabla
     */
    getClientCreditsTable(params, filters?): Observable<object> {
        let id_clients = 0;
        if (filters) {
            id_clients = filters.id_clients;
        }

        return this.http.post(`${this.baseUrl}/credit/clientCreditsTable/${id_clients}`, params);
    }

    /**
     * creditsTable
     * @param id_clients id del cliente
     * @param params parametros de filtrado de la tabla
     * @returns json con la información de la tabla
     */
    getCreditHistoryTable(params, filters?): Observable<object> {
        let id_credit = 0;
        if (filters) {
            id_credit = filters.id_credit;
        }

        return this.http.post(`${this.baseUrl}/credit/creditHistoryTable/${id_credit}`, params);
    }
}
