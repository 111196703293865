/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./emails-templates.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/form/form-field/form-field.component.ngfactory";
import * as i3 from "../../../components/form/form-field/form-field.component";
import * as i4 from "../../../providers/form.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "ngx-lightbox/lightbox.service";
import * as i7 from "@angular/common";
import * as i8 from "./emails-templates.component";
import * as i9 from "../../../services/emails-templates.service";
import * as i10 from "@angular/forms";
var styles_EmailsTemplatesComponent = [i0.styles];
var RenderType_EmailsTemplatesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailsTemplatesComponent, data: {} });
export { RenderType_EmailsTemplatesComponent as RenderType_EmailsTemplatesComponent };
function View_EmailsTemplatesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.email_view; _ck(_v, 1, 0, currVal_0); }); }
export function View_EmailsTemplatesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Correos"])), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "col text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "app-form-field", [["type", "select"]], null, null, null, i2.View_FormFieldComponent_0, i2.RenderType_FormFieldComponent)), i1.ɵdid(8, 114688, null, 0, i3.FormFieldComponent, [i4.FromService, i5.DomSanitizer, i6.Lightbox], { type: [0, "type"], options: [1, "options"] }, null), i1.ɵpod(9, { label: 0, path: 1, droptext: 2, elements: 3 }), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmailsTemplatesComponent_1)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "select"; var currVal_1 = _ck(_v, 9, 0, "Seleccionar correo", "email", "Lista de correos", _co.emails); _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_2 = _co.email_view; _ck(_v, 12, 0, currVal_2); }, null); }
export function View_EmailsTemplatesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-emails-templates", [], null, null, null, View_EmailsTemplatesComponent_0, RenderType_EmailsTemplatesComponent)), i1.ɵdid(1, 245760, null, 0, i8.EmailsTemplatesComponent, [i9.EmailsTemplatesService, i4.FromService, i10.FormBuilder, i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailsTemplatesComponentNgFactory = i1.ɵccf("app-emails-templates", i8.EmailsTemplatesComponent, View_EmailsTemplatesComponent_Host_0, {}, {}, []);
export { EmailsTemplatesComponentNgFactory as EmailsTemplatesComponentNgFactory };
