<p class="m-b-xs">
    <ng-container [ngSwitch]="item.ticketType">
        <span *ngSwitchCase="'created'" class="badge badge-danger">Creado</span>
        <span *ngSwitchCase="'assignations'" class="badge badge-warning">Asignado</span>
        <span *ngSwitchCase="'completions'" class="badge badge-info">Completado por el técnico</span>
        <span *ngSwitchCase="'confirmations'" class="badge badge-success">Firmado por el cliente</span>
        <span *ngSwitchCase="'closed'" class="badge badge-success">Cerrado</span>
        <span *ngSwitchCase="'unrealizations'" class="badge badge-secondary">No realizado</span>
        <span *ngSwitchCase="'previous'" class="badge badge-secondary">Ticket previo</span>
    </ng-container>
</p>