import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class BadDebtsService {
    baseUrl = `${environment.apiUrl}`;
    constructor(private readonly http: HttpClient) { }

    getBadDebtsClients(params?, stateSelect?): any {
        return this.http.post(`${this.baseUrl}/bad-debts/getBadDebtsClients`, params);
    }

    moveToBadDebt(id?, params?): any {
        return this.http.post(`${this.baseUrl}/bad-debts/moveBadDebt/${id}`, params);
    }

    restoreClient(id?, params?): any {
        return this.http.post(`${this.baseUrl}/bad-debts/restoreClient/${id}`, params);
    }

    getSummary(): any {
        return this.http.get(`${this.baseUrl}/bad-debts/summary`);
    }
}
