import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-client-commission',
  templateUrl: './client-commission.component.html',
  styleUrls: ['./client-commission.component.scss']
})
export class ClientCommissionComponent implements OnInit {
@Input() data: any;

ngOnInit(): void {
  //
}

}
