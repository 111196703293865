import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CashService } from '../../../../../services/cash.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FromService } from '../../../../../providers/form.service';
import { SwalService } from '../../../../../services/swal.service';
import { BroadcastService } from '../../../../../services/broadcast.service';
import { TeamsService } from '../../../../../services/teams.service';
var CashFormComponent = /** @class */ (function () {
    function CashFormComponent(activeModal, cashService, broadcast, formBuilder, fromService, swal, teamService) {
        this.activeModal = activeModal;
        this.cashService = cashService;
        this.broadcast = broadcast;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.swal = swal;
        this.teamService = teamService;
        this.form = this.formBuilder.group({
            balance_date: ['', Validators.required],
            balance: ['', Validators.required]
        });
        this.superAdmin = JSON.parse(localStorage.getItem('user')).team_id === 3;
    }
    CashFormComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resp, error_1, resp, error_2;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.fromService.setForm(this.form);
                        if (!this.superAdmin) return [3 /*break*/, 5];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        this.form.addControl('team_id', new FormControl(['', Validators.required]));
                        return [4 /*yield*/, this.teamService.index().toPromise()];
                    case 2:
                        resp = _a.sent();
                        if (resp && resp.success) {
                            this.teams = resp.response;
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        if (this.form.get('team_id')) {
                            this.form.removeControl('team_id');
                        }
                        _a.label = 6;
                    case 6:
                        if (!(this.data.status === 'edit')) return [3 /*break*/, 10];
                        _a.label = 7;
                    case 7:
                        _a.trys.push([7, 9, , 10]);
                        return [4 /*yield*/, this.cashService.show(this.data.cashData.id_cash).toPromise()];
                    case 8:
                        resp = _a.sent();
                        if (resp && resp.success) {
                            this.cashItem = resp.response;
                            Object.keys(this.cashItem).forEach(function (key) {
                                if (_this.cashItem.hasOwnProperty(key) && !!_this.form.controls[key]) {
                                    _this.form.controls[key].setValue(_this.cashItem[key]);
                                }
                            });
                        }
                        return [3 /*break*/, 10];
                    case 9:
                        error_2 = _a.sent();
                        return [3 /*break*/, 10];
                    case 10: return [2 /*return*/];
                }
            });
        });
    };
    CashFormComponent.prototype.save = function () {
        if (this.data.status === 'edit') {
            this.updateCashItem();
        }
        if (this.data.status === 'create') {
            this.saveCashItem();
        }
    };
    CashFormComponent.prototype.saveCashItem = function () {
        var _this = this;
        // this.form.get('category').value;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer guardar el siguiente balance?' }).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var resp;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.value) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.cashService.create(this.form.value).toPromise()];
                        case 1:
                            resp = _a.sent();
                            if (resp && resp.success) {
                                this.swal.success().then(function () {
                                    _this.activeModal.dismiss();
                                    _this.broadcast.reloadDataTable();
                                });
                            }
                            else {
                                this.swal.error({ title: 'Ocurió un error al guardar los datos' });
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    CashFormComponent.prototype.updateCashItem = function () {
        var _this = this;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos de este balance?' }).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var resp;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.value) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.cashService.edit(this.data.cashData.id_cash, this.form.value).toPromise()];
                        case 1:
                            resp = _a.sent();
                            if (resp.success) {
                                this.swal.success().then(function () {
                                    _this.activeModal.dismiss();
                                    _this.broadcast.reloadDataTable();
                                });
                            }
                            else {
                                this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    // tslint:disable-next-line: prefer-function-over-method
    CashFormComponent.prototype.teamValidator = function () {
        return JSON.parse(localStorage.getItem('user')).team_id === 3 ? Validators.required : null;
    };
    return CashFormComponent;
}());
export { CashFormComponent };
