/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./charges-backs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/datatable/datatable.component.ngfactory";
import * as i3 from "../../../components/datatable/datatable.component";
import * as i4 from "../../../services/broadcast.service";
import * as i5 from "./charges-backs.component";
import * as i6 from "../../../services/chargesbacks.service";
import * as i7 from "../../../services/permissions.service";
var styles_ChargesBacksComponent = [i0.styles];
var RenderType_ChargesBacksComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ChargesBacksComponent, data: {} });
export { RenderType_ChargesBacksComponent as RenderType_ChargesBacksComponent };
export function View_ChargesBacksComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "container-fluid border-bottom bg-white p-3 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "m-0"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contracargos"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "container-fluid bg-white p-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 16777216, null, null, 1, "app-datatable", [], null, null, null, i2.View_DatatableComponent_0, i2.RenderType_DatatableComponent)), i1.ɵdid(6, 245760, null, 0, i3.DatatableComponent, [i1.ViewContainerRef, i1.Renderer2, i1.ComponentFactoryResolver, i4.BroadcastService], { options: [0, "options"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataTableConfig; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_ChargesBacksComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-charges-backs", [], null, null, null, View_ChargesBacksComponent_0, RenderType_ChargesBacksComponent)), i1.ɵdid(1, 114688, null, 0, i5.ChargesBacksComponent, [i6.ChargesBacksService, i7.PermissionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ChargesBacksComponentNgFactory = i1.ɵccf("app-charges-backs", i5.ChargesBacksComponent, View_ChargesBacksComponent_Host_0, {}, {}, []);
export { ChargesBacksComponentNgFactory as ChargesBacksComponentNgFactory };
