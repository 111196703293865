/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./client-history.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../components/history/history.component.ngfactory";
import * as i3 from "../../../components/history/history.component";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "../../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i6 from "@angular/common";
import * as i7 from "./client-history.component";
import * as i8 from "../../../services/clients.service";
var styles_ClientHistoryComponent = [i0.styles];
var RenderType_ClientHistoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientHistoryComponent, data: {} });
export { RenderType_ClientHistoryComponent as RenderType_ClientHistoryComponent };
function View_ClientHistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-history", [["type", "client"]], null, null, null, i2.View_HistoryComponent_0, i2.RenderType_HistoryComponent)), i1.ɵdid(1, 114688, null, 0, i3.HistoryComponent, [], { type: [0, "type"], items: [1, "items"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "client"; var currVal_1 = _co.history; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ClientHistoryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-history", [["type", "client"]], null, null, null, i2.View_HistoryComponent_0, i2.RenderType_HistoryComponent)), i1.ɵdid(1, 114688, null, 0, i3.HistoryComponent, [], { type: [0, "type"], items: [1, "items"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "client"; var currVal_1 = _co.history_log; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ClientHistoryComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-history", [["type", "client"]], null, null, null, i2.View_HistoryComponent_0, i2.RenderType_HistoryComponent)), i1.ɵdid(1, 114688, null, 0, i3.HistoryComponent, [], { type: [0, "type"], items: [1, "items"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "client"; var currVal_1 = _co.history_price_change_log; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ClientHistoryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "ngb-tab", [["title", "Historial de precios"]], null, null, null, null, null)), i1.ɵdid(1, 2113536, [[1, 4]], 2, i4.NgbTab, [], { title: [0, "title"] }, null), i1.ɵqud(603979776, 6, { titleTpls: 1 }), i1.ɵqud(603979776, 7, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ClientHistoryComponent_4)), i1.ɵdid(5, 16384, [[7, 4]], 0, i4.NgbTabContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_0 = "Historial de precios"; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ClientHistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "ngb-tabset", [], null, null, null, i5.View_NgbTabset_0, i5.RenderType_NgbTabset)), i1.ɵdid(2, 2146304, null, 1, i4.NgbTabset, [i4.NgbTabsetConfig], { destroyOnHide: [0, "destroyOnHide"] }, null), i1.ɵqud(603979776, 1, { tabs: 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 5, "ngb-tab", [["title", "Historial de validaci\u00F3n"]], null, null, null, null, null)), i1.ɵdid(5, 2113536, [[1, 4]], 2, i4.NgbTab, [], { title: [0, "title"] }, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ClientHistoryComponent_1)), i1.ɵdid(9, 16384, [[3, 4]], 0, i4.NgbTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 5, "ngb-tab", [["title", "Historial de cambios"]], null, null, null, null, null)), i1.ɵdid(11, 2113536, [[1, 4]], 2, i4.NgbTab, [], { title: [0, "title"] }, null), i1.ɵqud(603979776, 4, { titleTpls: 1 }), i1.ɵqud(603979776, 5, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_ClientHistoryComponent_2)), i1.ɵdid(15, 16384, [[5, 4]], 0, i4.NgbTabContent, [i1.TemplateRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientHistoryComponent_3)), i1.ɵdid(17, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "button", [["class", "btn btn-outline-dark"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeModal.dismiss("close button") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Cancelar"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 2, 0, currVal_0); var currVal_1 = "Historial de validaci\u00F3n"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "Historial de cambios"; _ck(_v, 11, 0, currVal_2); var currVal_3 = (_co.history_price_change_log.length > 0); _ck(_v, 17, 0, currVal_3); }, null); }
export function View_ClientHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-client-history", [], null, null, null, View_ClientHistoryComponent_0, RenderType_ClientHistoryComponent)), i1.ɵdid(1, 245760, null, 0, i7.ClientHistoryComponent, [i8.ClientsService, i4.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientHistoryComponentNgFactory = i1.ɵccf("app-client-history", i7.ClientHistoryComponent, View_ClientHistoryComponent_Host_0, { data: "data" }, {}, []);
export { ClientHistoryComponentNgFactory as ClientHistoryComponentNgFactory };
