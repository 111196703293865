import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { SwalService } from '../../../../services/swal.service';
import { WithdrawalsService } from '../../../../services/withdrawals.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-withdrawal-create-edit',
  templateUrl: './withdrawal-create-edit.component.html',
  styleUrls: ['./withdrawal-create-edit.component.scss']
})
export class WithdrawalCreateEditComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  form: FormGroup = this.formBuilder.group({
    amount: ['', Validators.required],
    date: ['', Validators.required],
    reason: ['', Validators.required]
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swalService: SwalService,
    private readonly withdrawalService: WithdrawalsService
  ) { }

  ngOnInit(): void {
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * save
   * Envia una petición de crear o editar dependiendo del status recibido en el data.
   */

  save(): void {
    if (this.data.status === 'create') {
      this.saveWithdrawal();
    }
  }

  /**
   * saveWithdrawal
   * Envia una petición POST con los valores obtenidos del form para crear el registro
   * en la BD
   */
  private saveWithdrawal(): void {
    const swalParams = {
      title: '¿Esta seguro de querer registrar el siguiente retiro?',
      text: 'El retiro se creara con la siguiente información'
    };
    if (this.form.valid) {
      this.swalService.warning(swalParams).then(result => {
        if (result.value) {
          this.subscriptions.push(this.withdrawalService.createWithdrawal(this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swalService.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swalService.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

}
