import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GroupsService {
    baseUrl = `${environment.apiUrl}`;

    constructor(private readonly http: HttpClient) { }
    create(params): any {
        return this.http.post(`${this.baseUrl}/groups`, params);
    }

    delete(id): any {
        return this.http.delete(`${this.baseUrl}/groups/${id}`);
    }

    show(id): any {
        return this.http.get(`${this.baseUrl}/groups/${id}`);
    }

    update(id, params): any {
        return this.http.put(`${this.baseUrl}/groups/${id}`, params);
    }

    getGroups(params?, filters?): any {
        if (filters) {
            Object.assign(params, filters);
        }
        
        return this.http.post(`${this.baseUrl}/groups/get_groups`, params);
    }

    listGroups(params?): any {
        return this.http.get(`${this.baseUrl}/groups`, params);
    }

    getNotifications(params?): any {
        return this.http.post(`${this.baseUrl}/notification_groups/get`, params);
    }

    saveNotificationGroup(params): any {
        return this.http.post(`${this.baseUrl}/notification_groups/save`, params);
    }

    getClientNotification(id, params?): any {
        return this.http.post(`${this.baseUrl}/notification_groups/get_users/${id}`, params);
    }

    editClientNotification(id, params?): any {
        return this.http.post(`${this.baseUrl}/notification_groups/edit/${id}`, params);
    }

    deleteGroup(id): any {
        return this.http.delete(`${this.baseUrl}/notification_groups/${id}`);
    }

    /**
     * flatGroup
     * Simplifica la estructura de los grupos con los planes para unicamente devolver un array bidimesional
     */
    flatGroups(groups): Array<any> {
        let group_object = {};
        const flatted_groups = [];
        groups.forEach(group => {
            if (group.plans.length > 0) {
                group.plans.forEach(plan => {
                    group_object = { id: plan.id_groups, name: plan.name };
                });
            } else {
                group_object = { id: group.id_groups, name: group.name };
            }
            flatted_groups.push(group_object);
        });

        return flatted_groups;
    }
}
