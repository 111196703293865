import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AdvertisingService } from '../../../../../services/advertising.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FromService } from '../../../../../providers/form.service';
import { SwalService } from '../../../../../services/swal.service';
import { BroadcastService } from '../../../../../services/broadcast.service';
var AdvertisingFormComponent = /** @class */ (function () {
    function AdvertisingFormComponent(activeModal, advertisingService, broadcast, formBuilder, fromService, swal) {
        this.activeModal = activeModal;
        this.advertisingService = advertisingService;
        this.broadcast = broadcast;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.swal = swal;
        this.form = this.formBuilder.group({
            balance_date: ['', Validators.required],
            balance: ['', Validators.required]
        });
    }
    AdvertisingFormComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resp, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.fromService.setForm(this.form);
                        if (!(this.data.status === 'edit')) return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.advertisingService.show(this.data.advertisingData.id).toPromise()];
                    case 2:
                        resp = _a.sent();
                        if (resp && resp.success) {
                            this.advertisingItem = resp.response;
                            Object.keys(this.advertisingItem).forEach(function (key) {
                                if (_this.advertisingItem.hasOwnProperty(key) && _this.form.controls[key]) {
                                    _this.form.controls[key].setValue(_this.advertisingItem[key]);
                                }
                            });
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.swal.error({ title: 'Ocurió un error', text: error_1.message });
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AdvertisingFormComponent.prototype.save = function () {
        if (this.data.status === 'edit') {
            this.updateAdvertisingItem();
        }
        if (this.data.status === 'create') {
            this.saveAdvertisingItem();
        }
    };
    AdvertisingFormComponent.prototype.saveAdvertisingItem = function () {
        var _this = this;
        // this.form.get('category').value;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer guardar el siguiente balance?', text: '' }).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var resp;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.value) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.advertisingService.create(this.form.value).toPromise()];
                        case 1:
                            resp = _a.sent();
                            if (resp && resp.success) {
                                this.swal.success().then(function () {
                                    _this.activeModal.dismiss();
                                    _this.broadcast.reloadDataTable();
                                });
                            }
                            else {
                                this.swal.error({ title: 'Ocurió un error al guardar los datos' });
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    AdvertisingFormComponent.prototype.updateAdvertisingItem = function () {
        var _this = this;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos de este balance?', text: '' }).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var resp;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.value) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.advertisingService.edit(this.data.advertisingData.id, this.form.value).toPromise()];
                        case 1:
                            resp = _a.sent();
                            if (resp.success) {
                                this.swal.success().then(function () {
                                    _this.activeModal.dismiss();
                                    _this.broadcast.reloadDataTable();
                                });
                            }
                            else {
                                this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    return AdvertisingFormComponent;
}());
export { AdvertisingFormComponent };
