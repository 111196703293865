import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService } from '../../../services/broadcast.service';
import { ClientsService } from '../../../services/clients.service';
import { SwalService } from '../../../services/swal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-client-update-plan',
  templateUrl: './client-update-plan.component.html',
  styleUrls: ['./client-update-plan.component.scss']
})
export class ClientUpdatePlanComponent implements OnInit, OnDestroy {
  @Input() data: any;
  client: any;
  subscriptions: Array<Subscription> = [];
  current_plan_type: string;
  monthly_fee: Number;
  new_monthly_fee: Number;
  new_plan_info = {
    id: 0,
    name: '',
    reason: ''
  };

  constructor(
    public swal: SwalService,
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly clientsService: ClientsService
    ) {}

  ngOnInit(): void {
    this.client = this.data;
    this.detectClientPlan(this.client);
    this.monthly_fee = this.clientsService.calculateMonthlyFee(this.client);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * detectClientPlan
   * Determina el plan bajo el cual esta el cliente actualmente.
   * 1: Standard
   * 2: Premium 
   * @param client información del cliente
   */
  detectClientPlan(client): void {
    this.current_plan_type = 'Standard';
    this.new_plan_info.id = 2;
    this.new_plan_info.name = 'Premium';
    this.new_monthly_fee = 49900;

    if (client.id_plan_type === 2) {
      this.new_plan_info.id = 1;
      this.new_plan_info.name = 'Standard';
      this.current_plan_type = 'Premium';
      this.new_monthly_fee = 34900;
    }
    const new_client_info = {
      monthly_fee: this.new_monthly_fee,
      social_responsability: this.client.social_responsability
    };

    this.new_monthly_fee = this.clientsService.calculateMonthlyFee(new_client_info);
  }

  /**
   * updatePlan
   * Manda una petición put a la API para actualizar el plan del cliente de standard a premium y viceversa.
   */
  updatePlan(): void {
    const params = {
      title: `¿Esta seguro de querer actualizar el plan de cliente de ${this.current_plan_type} a ${this.new_plan_info.name}?`,
      text: `La mensualidad se actualizara de ${String(this.monthly_fee)} a ${String(this.new_monthly_fee)}`,
      inputPlaceholder: 'Razones',
      confirmButtonText: 'Actualizar'
    };
    this.swal.input(params).then(result => {
      if (result.value) {
        this.new_plan_info.reason = result.value;
        this.subscriptions.push(this.clientsService.updatePlan(this.client.id_clients, this.new_plan_info).subscribe((resp: any) => {
          if (resp.success) {
            this.swal.success({text: 'Plan actualizado exitosamente'}).then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          }
        }));
      } else {
        this.swal.error({ title: 'Ocurió un error al actualizar el plan' });
      }
    });
    
  }
}
