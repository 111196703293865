import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    baseUrl = `${environment.apiUrl}`;

    constructor(private readonly http: HttpClient) { }

    freewater(params?): Observable<any> {
        // params = Math.floor(Date.now() / 1000);
        return this.http.get(`${this.baseUrl}/dashboard/free_water`, params);
    }

    activeUsers(): Observable<any> {
        return this.http.get(`${this.baseUrl}/dashboard/clients`);
    }

    filterChanges(): Observable<any> {
        return this.http.get(`${this.baseUrl}/dashboard/filter-change`);
    }

    dashboard(params?): Observable<any> {
        return this.http.get(`${this.baseUrl}/dashboard`, { params });
    }

    incomes(params?): Observable<any> {
        return this.http.post(`${this.baseUrl}/dashboard/incomes`, {}, { params });
    }

    ticketComplettions(params?): Observable<any> {
        return this.http.get(`${this.baseUrl}/dashboard/tickets_completion`, { params });
    }

    totals(): Observable<any> {
        return this.http.get(`${this.baseUrl}/dashboard/totals`);
    }

    subscriptions(params?): Observable<any> {
        return this.http.post(`${this.baseUrl}/dashboard/subscriptions`, {}, { params });
    }

    shares(params?): Observable<any> {
        return this.http.post(`${this.baseUrl}/dashboard/shares`, {}, { params });
    }

    clientsCoordinates(): Observable<any> {
        return this.http.get(`${this.baseUrl}/dashboard/clients-coordinates`);
    }
}
