<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <canvas baseChart 
        width="450" 
        height="100"
        [datasets]="subsChartData"
        [chartType]="lineChartType"
        [labels]="labels"
        [options]="options"
        [colors]="chartColor">
    </canvas>
</div>
