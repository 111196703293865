import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
// Components
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
// Services
import { BroadcastService } from '../../../services/broadcast.service';
import { PermissionsService } from '../../../services/permissions.service';
import { RoleGuardService } from '../../../services/role-guard.service';
import { SwalService } from '../../../services/swal.service';
import { UsersService } from '../../../services/users.service';
import { UserCreateEditComponent } from './user-create-edit/user-create-edit.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  show_teams = false;

  dataTableConfig = {
    config: {
      base: this.users,
      api: 'getUser'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Email',
        field: 'email',
        type: 'text'
      },
      {
        display: 'Activo',
        field: 'status',
        type: 'boolean'
      },
      {
        display: 'Fecha de alta',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: [

          {
            display: 'Editar',
            icon: 'fa fa-pencil',
            event: 'user.userEdit',
            conditionality: 'true'
          }

        ]
      }
    ],
    filters: [
      {
        title: 'Roles',
        type: 'rol',
        select_type: 'dropdown',
        cssClass: 'align-right',
        options: [
          {
            val: '',
            name: 'Remover filtro'
          },
          {
            val: 8,
            name: 'AB Member'
          },
          {
            val: 19,
            name: 'Accounts Payable'
          },
          {
            val: 18,
            name: 'Accounts Receivable'
          },
          {
            val: 11,
            name: 'Admin Manager'
          },
          {
            val: 1,
            name: 'Administrator'
          },
          {
            val: 2,
            name: 'Client'
          },
          {
            val: 15,
            name: 'Customer Service Agent'
          },
          {
            val: 16,
            name: 'Debt Collector'
          },
          {
            val: 20,
            name: 'Dispatcher'
          },
          {
            val: 7,
            name: 'Founder'
          },
          {
            val: 10,
            name: 'General Manager'
          },
          {
            val: 9,
            name: 'Investor'
          },
          {
            val: 7,
            name: 'Marketing Manager'
          },
          {
            val: 5,
            name: 'Sales Agent'
          },
          {
            val: 14,
            name: 'Sales Manager'
          },
          {
            val: 4,
            name: 'Seller'
          },
          {
            val: 13,
            name: 'Social Media Agent'
          },
          {
            val: 17,
            name: 'Social Responsability'
          },
          {
            val: 6,
            name: 'Staff'
          },
          {
            val: 3,
            name: 'Technician'
          }
        ]
      }
    ]
  };

  actionEventSubscription: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    public route: ActivatedRoute,
    public swal: SwalService,
    private readonly users: UsersService,
    private readonly broadcast: BroadcastService,
    private readonly roleGuardService: RoleGuardService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.showTeams();
    this.actionEventSubscription = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'user.userEdit': this.userEditItem(event.data); break;
      }
    });

  }

  ngOnDestroy(): void {
    this.actionEventSubscription.unsubscribe();
  }

  createUser(data): void {
    const props: SharedComponent = new SharedComponent(
      UserCreateEditComponent,
      {
        userData: data,
        show_teams: this.show_teams,
        status: 'create'
      },
      {
        title: 'Crear usuario'
      }
    );
    this.appModal.openXl(props);
  }

  userEditItem(data): void {
    const props: SharedComponent = new SharedComponent(
      UserCreateEditComponent,
      {
        user: data,
        show_teams: this.show_teams,
        status: 'edit'
      },
      {
        title: 'Editar datos de usuario'
      });
    this.appModal.openXl(props);
  }

  /**
   * showTeams
   * Determina si la columna de distribuidor se mostrara en el la tabla de usuarios 
   */
  showTeams(): void {
    const user = this.roleGuardService.decodedToken.user;
    if (this.permissionsService.hasPermission('list-teams') && user && user.team_id === 1) {
      this.show_teams = true;
      const team_filter =  {
        title: 'Distribuidores',
        type: 'team',
        select_type: 'dropdown',
        cssClass: '',
        options: [
          {
            val: '',
            name: 'TODOS'
          },
          {
            val: 1,
            name: 'Aguagente'
          },
          {
            val: 2,
            name: 'Mérida'
          },
          {
            val: 3,
            name: 'Ventas'
          }
        ]
      };
      const team_column = { display: 'Distribuidor', field: 'team.name', type: 'text' };
      const columns_length = this.dataTableConfig.columns.length;
      this.dataTableConfig.columns.splice(columns_length - 1, 0, team_column);
      this.dataTableConfig.filters.push(team_filter);
    }
  }
}
