import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HelpersService } from '../../../services/helpers.service';

@Component({
  selector: 'app-charge-summary',
  templateUrl: './charge-summary.component.html',
  styleUrls: ['./charge-summary.component.scss']
})
export class ChargeSummaryComponent implements OnInit {
  @Input() data: any;
  charges = [];
  chargesWithSummaryData: Array<number> = [5, 6];
  referencesIds: Array<number> = [4, 5];
  extraData: any;
  whatsAppMessage: string = '';
  activeCharge = false;
  hasSummary = false;
  isReference = false;
  isCollapsed = false;
  total = 0;

  constructor(
    private readonly helpersService: HelpersService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.isReference = this.referencesIds.includes(this.data.id_payment_type);
    this.activeCharge = this.data.status === 'Pendiente' || this.data.remaining_time > 0 ? true : false;
    this.hasSummary = this.chargesWithSummaryData.includes(this.data.id_charge_type);
    if (this.hasSummary || this.isReference) {
      this.getChargesFromExtraData();
    }
  }

  getChargesFromExtraData(): void {
    if (this.data.extra_data) {
      this.extraData = JSON.parse(this.data.extra_data);
      const charges = this.extraData.type ? this.extraData.items : Object.values(this.extraData);
      charges.forEach(charge => {
        switch (charge.description) {
          case 'customID':
          case '5':
          case '6':
            break;
          default:
            const chargeInSummary = { name: charge.name, amount: (charge.price) / 100 };
            this.total += (charge.price) / 100;
            if (this.data.id_charge_type === 6) {
              const subAccounts = this.data.client.sub_accounts;
              const subAccount = subAccounts.find(({ id_clients }) => id_clients === charge.id_clients);
              const accountName = subAccount ? subAccount.name : this.data.client.name;
              Object.assign(chargeInSummary, { clientName: accountName });
            }
            this.charges.push(chargeInSummary);
            break;

        }
      });
    }
  }

  sendByWhatsApp() {
    this.copyToClipboard();
    this.helpersService.sendByWhatsapp(this.whatsAppMessage, this.data.client.phone);
  }

  copyToClipboard() {
    let text = `Descripción: ${this.data.description}\nCantidad: $${this.data.amount / 100} MXN`;
    if (this.isReference) {
      text += `\nReferencia: ${this.extraData.reference}\nTipo de referencia: ${this.extraData.type}`;
    }

    if (this.activeCharge) {
      text += `\nExpira en: ${this.data.remaining_time} ${this.data.time_name}`;
    }

    if (this.data.paid_at) {
      text += `\nFecha de confirmación del pago: ${this.data.paid_at}`;
    }

    if (this.data.failure_message) {
      text += `\nMensaje de error: ${this.data.failure_message}`
    }

    if (this.extraData.reason) {
      text += `\nRazon: ${this.extraData.reason}`;
    }

    if (this.extraData.authBy) {
      text += `\nAutorizado por: ${this.extraData.authBy}`;
    }

    text += `\nFecha del cargo: ${this.data.created_at}`;

    this.whatsAppMessage = text;
    
    this.helpersService.copyToClipboard(text);
  }
}
