import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// services
import { SwalService } from '../../../../services/swal.service';
import { FromService } from '../../../../providers/form.service';
import { ErrrorCodesService } from '../../../../services/error-codes.service';
import { TicketsService } from '../../../../services/tickets.service';
import { BroadcastService } from '../../../../services/broadcast.service';

@Component({
  selector: 'app-unrealized',
  templateUrl: './unrealized.component.html',
  styleUrls: ['./unrealized.component.scss']
})
export class UnrealizedComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  error_codes = [];
  form: FormGroup = this.formBuilder.group({
    final_service_fee: [0],
    status: ['unrealized', Validators.required],
    reason: [''],
    id_error_codes: ['', Validators.required],

  });
  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly swal: SwalService,
    private readonly fromService: FromService,
    private readonly errorCodesService: ErrrorCodesService,
    private readonly ticketsService: TicketsService,
  ) { }

  ngOnInit(): void {
    this.getErrorCodes();
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
  * getErrorCodes
  * Obtiene los codigos de error almacenados en la BD y filtra los que se pueden asignar de manera manual.
  */
  getErrorCodes(): void {
    this.subscriptions.push(this.errorCodesService.index().subscribe((data: any) => {
      data.response.forEach(error_code => {
        if (error_code.id !== 3) {
          this.error_codes.push(error_code);
        }
      });
    }));
  }

  unrealizedTicket() {
    const params = this.form.value;
    this.subscriptions.push(this.ticketsService.setStatus(params, this.data.id_tickets).subscribe((subresp: any) => {
      if (subresp.success) {
        this.swal.success().then(() => {
          this.activeModal.dismiss();
          if ('isMap' in this.data) {
            this.broadcast.fire({
              name: 'reload-map',
              data: {}
            });
          } else {
            this.broadcast.reloadDataTable();
          }
        });
      } else {
        this.swal.error().catch();
      }
    }));
  }
}
