import { Component, Input, OnInit } from '@angular/core';
import { TicketsService } from '../../../services/tickets.service';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { installationFiles } from '../../../model/shared-component.interface';
import { Lightbox } from 'ngx-lightbox';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { UploadPhotosComponent } from '../../../components/shared/upload-photos/upload-photos.component';
import { ClientsService } from '../../../services/clients.service';

@Component({
  selector: 'app-client-extra-images',
  templateUrl: './client-extra-images.component.html',
  styleUrls: ['./client-extra-images.component.scss']
})
export class ClientExtraImagesComponent implements OnInit {
  @Input() data: any;
  premiumPlans = [2, 4];
  isPremium = false;
  regularInstallationIdTicket: Number;
  premiumInstallationIdTicket: Number;
  regularInstallationImages = [];
  premiumInstallationImages = [];
  extraClientImages = [];

  private readonly baseUrl = `${environment.apiUrl}`;
  ticketsUrl = `${this.baseUrl}/documents/tickets`;
  extraImagesUrl = `${this.baseUrl}/documents/contracts`;
  subscriptions: Array<Subscription> = [];

  constructor(
    public appModal: ModalComponent,
    private readonly domSanitizer: DomSanitizer,
    private readonly clientsService: ClientsService,
    private readonly ticketsService: TicketsService,
    private readonly lightbox: Lightbox) { }

  ngOnInit(): void {
    this.isPremium = this.premiumPlans.includes(this.data.id_plan_type);
    this.getInstallationsImages();
    this.getClientExtraImages();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * getInstallationsImages
   * Obtiene las imagenes de instalación regulares y premium (opcional)
   */
  getInstallationsImages(): void {
    this.subscriptions.push(this.ticketsService.getInstallationsImages(this.data.id_clients).subscribe((files: installationFiles) => {
      if (files.regularInstallation) {
        const regularInstallation = files.regularInstallation;
        this.regularInstallationIdTicket = regularInstallation.id_tickets;
        this.regularInstallationImages = regularInstallation.files.map(file =>
        ({
          src: this.domSanitizer.bypassSecurityTrustUrl(`${this.ticketsUrl}/${this.regularInstallationIdTicket}/${file}`),
          caption: `Imagen ${file}`
        }));
      }

      if (this.isPremium && files.premiumInstallation) {
        const premiumInstallation = files.premiumInstallation;
        this.premiumInstallationIdTicket = premiumInstallation.id_tickets;
        this.premiumInstallationImages = premiumInstallation.files.map(file =>
        ({
          src: this.domSanitizer.bypassSecurityTrustUrl(`${this.ticketsUrl}/${this.premiumInstallationIdTicket}/${file}`),
          caption: `Imagen ${file}`
        }));
      }
    }));
  }

  /**
   * getClientExtraImages
   * Obtiene las imagenes extra asociadas a  un cliente (max 5)
   */
  getClientExtraImages(): void {
    this.subscriptions.push(
      this.clientsService.getImages(this.data.id_clients).subscribe((files: any) => {
        this.extraClientImages = files.response.extras.map(file =>
        ({
          src: this.domSanitizer.bypassSecurityTrustUrl(`${this.extraImagesUrl}/${file}`),
          caption: `${file.split('/').pop().split('.')[0]}`
        }));
      })
    );
  }

  /**
   * addExtraPhotos
   * Abre un modal para añadir una imagen extra al cliente
   */
  addExtraPhotos(): void {
    const props: SharedComponent = new SharedComponent(
      UploadPhotosComponent, { client: this.data, type: 'clientExtraImage' },
      { title: `Añadir imagén extra` });
    this.appModal.opensm(props);

  }

  openLightBox(image): void {
    // tslint:disable-next-line: no-parameter-reassignment
    image = [{ src: `${image}` }];
    this.lightbox.open(image, 0, { showZoom: true });
  }
}
