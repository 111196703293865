import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'history-ticket-confirmations',
  templateUrl: './confirmations.component.html',
  styleUrls: ['./confirmations.component.scss']
})
export class ConfirmationsComponent implements OnInit {
  @Input('item') item: any;

  ngOnInit(): void {
    // 
  }
}
