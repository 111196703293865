import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { ChargeSummaryComponent } from '../../../../components/shared/charge-summary/charge-summary.component';
import { SharedComponent } from '../../../../model/shared-component';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { ChargesService } from '../../../../services/charges.service';
import { DebtsService } from '../../../../services/debts.service';
import { NotificationService } from '../../../../services/notification.service';
import { PermissionsService } from '../../../../services/permissions.service';
import { SwalService } from '../../../../services/swal.service';
import { ManualPaymentComponent } from '../../../../components/shared/manual-payment/manual-payment.component';
import { HelpersService } from '../../../../services/helpers.service';

@Component({
  selector: 'app-debts',
  templateUrl: './debts.component.html',
  styleUrls: ['./debts.component.scss']
})
export class DebtsComponent implements OnDestroy, OnInit {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  vendor = 'Sin vendor predeterminado';
  client_debts: any;
  total_debts = 0;
  valid_cards: boolean;
  params = {};
  client_cards = [];
  form: FormGroup = this.formBuilder.group({
    message: ['']
  });

  contractData = {
    id_clients: 0,
    monthly_fee: 0,
    sr: 0,
    bank_fee: 0,
    coupon: 0,
    total: 0
  };

  sr = 0;

  dataTableDebts: any;
  dataTableCharges = {
    config: {
      base: this.charges,
      api: 'getClientCharges',
      params: {
        id_clients: ''
      }
    },
    columns: [
      {
        display: 'Fecha',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'ID del cargo',
        field: 'id_charges',
        type: 'text',
        orderable: false
      },
      {
        display: 'Tipo de cargo',
        field: 'charge_type',
        type: 'text',
        orderable: false
      },
      {
        display: 'Monto',
        field: 'amount',
        type: 'charge_amount'
      },
      {
        display: 'Devolución',
        field: 'refunds_total',
        type: 'charge_amount'
      },
      {
        display: 'Vendor',
        field: 'vendor',
        type: 'text',
        orderable: false
      },
      {
        display: 'Descripción',
        field: 'description',
        type: 'text',
        orderable: false
      },
      {
        display: 'Error',
        field: 'failure_message',
        type: 'text',
        orderable: false
      },
      {
        display: '',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-primary',
            icon: 'fa fa-book',
            name: 'Resumen de deuda',
            event: 'debt.summary',
            conditionality: 'this.data.id_charge_type === 5'
          }
        ]
      }
    ]
  };

  private readonly baseDataTableDebts = {
    config: {
      base: this.debts,
      api: 'getClientDebts',
      params: {
        id_clients: ''
      }
    },
    columns: [
      {
        display: 'Periodo',
        field: 'monthly_charge_date',
        type: 'period_date'
      },
      {
        display: 'Monto',
        field: 'amount',
        type: 'number'
      },
      {
        display: 'Gastos de cobranza',
        field: 'collection_fees',
        type: 'number'
      },
      {
        display: 'Interés moratorio',
        field: 'moratory_fees',
        type: 'number'
      },
      {
        display: 'Total',
        field: 'id_clients',
        type: 'debt-total'
      },
      {
        display: '',
        field: '',
        type: 'inline-button',
        options: [
          {
            cssClass: 'btn btn-primary',
            name: 'Modificar deuda',
            event: 'debt.update',
            conditionality: 'true',
            permissions: ['charge-debts']
          }
        ]
      }
    ]
  };

  baseDataTableCanceledDebts = {
    config: {
      base: this.debts,
      api: 'getClientDebts',
      params: {
        id_clients: '',
        canceledDebts: true
      }
    },
    columns: [
      {
        display: 'Periodo',
        field: 'monthly_charge_date',
        type: 'period_date'
      },
      {
        display: 'Razón',
        field: 'extra_data.reason',
        type: 'text'
      },
      {
        display: 'Autorizado por',
        field: 'auth_by.name',
        type: 'text'
      },
      {
        display: '',
        field: '',
        type: 'inline-button',
        options: []
      }
    ]
  };

  constructor(
    public appModal: ModalComponent,
    public activeModal: NgbActiveModal,
    private readonly formBuilder: FormBuilder,
    private readonly charges: ChargesService,
    private readonly debts: DebtsService,
    private readonly broadcast: BroadcastService,
    private readonly notify: NotificationService,
    private readonly formService: FromService,
    private readonly swal: SwalService,
    private readonly permissionsService: PermissionsService,
    private readonly helpersService: HelpersService
  ) { }

  ngOnInit(): void {
    this.dataTableDebts = this.permissionsService.filterTableConfig(this.baseDataTableDebts);
    this.formService.setForm(this.form);
    this.getDefaultVendor();
    this.client_cards = this.data.cards;
    this.valid_cards = this.getValidCards(this.client_cards);
    this.params = this.data;
    this.dataTableDebts.config.params.id_clients = this.data.id_clients;
    this.dataTableCharges.config.params.id_clients = this.data.id_clients;
    this.baseDataTableCanceledDebts.config.params.id_clients = this.data.id_clients;
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'debt.update': this.updateDebt(event.data); break;
        case 'debt.summary': this.latePaymentSummaryItem(event.data); break;
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * notifyClient
   * Envia una notificación (opcional) al correo y app del cliente cuando se va a realizar un cargo
   */
  notifyClient(): void {
    const params = {
      users: [this.data.id_users],
      message: this.form.get('message').value
    };

    const swal_title = 'Estás seguro de enviar la notificación ';
    this.swal.warning({ title: swal_title }).then(resp => {
      if (resp.value) {
        this.subscriptions.push(this.notify.notifyUser(params).subscribe((data: any) => {
          if (data.success) {
            this.swal.success();
          } else {
            this.swal.error({ title: data.message });
          }
        }));
      }
    });
  }

  /**
   * debtsItem
   * Envia la petición a la API para realizar el cobro de una deuda individual
   * @param data informacion del obtenida por de la tabla de deudas.
   */

  debtsItem(data): void {
    this.client_cards = data.client.cards;

    if (!this.valid_cards) {
      this.swal.error({ title: 'Este cliente no cuenta con tarjetas validas para realizar el cargo' });
    } else {
      this.swal.warning({ title: '¿Desea realizar este cargo?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.debts.chargeDebt(data.id_debts).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurrió un error al actualizar los datos' });
            }
          }));
        }
      });
    }
  }

  /**
   * getClientDebtsTotal
   * Calcula el total de las deudas a cobrar, unicamente como confirmación esta info no se envia a la API
   */

  getClientDebtsTotal(): void {
    let total_debt = 0;
    this.total_debts = 0;
    this.client_debts.forEach(debt => {
      total_debt = (Number(debt.amount) + Number(debt.collection_fees) + Number(debt.moratory_fees)) / 100;
      this.total_debts += total_debt;
    });
  }

  /**
   * chargeAllClientDebtsModal
   * Despliega un modal de confirmación con la cantidad total a cobrar por todas las deudas
   */
  chargeAllClientDebtsModal(): void {
    this.swal.warning({
      title: '¿Esta seguro que desea hacer el cobro de todas las deudas?',
      text: `Se realizara un cargo por: $ ${this.total_debts.toFixed(2)}`
    }).then((result => {
      if (result.value) {
        this.subscriptions.push(this.debts.chargeAllDebts(this.data.id_clients).subscribe((resp: any) => {
          if (resp.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurrio un error al cobrar las deudas' });
          }
        }));
      }
    }));
  }

  /**
   * charge all debts with credit card
   */
  chargeDebtsByCard(): void {
    this.swal.warning({
      title: '¿Esta seguro que desea hacer el cobro de todas las deudas?',
      text: `Se realizara un cargo por: $ ${this.total_debts.toFixed(2)}`
    }).then((result => {
      if (result.value) {
        this.subscriptions.push(this.debts.chargeAllDebts(this.data.id_clients).subscribe((resp: any) => {
          if (resp.success) {
            this.swal.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurrio un error al cobrar las deudas' });
          }
        }));
      }
    }));
  }

  /**
   * chargeAllClientsDebts
   * Envia la petición para cargar todas las deudas del cliente en un unico intento de cargo
   */
  chargeAllClientDebts(): void {
    this.client_debts = this.data.pending_debts;
    if (this.client_debts.length > 0) {
      this.getClientDebtsTotal();
      this.chargeAllClientDebtsModal();
    } else {
      this.swal.error({ title: 'Ocurrio un error al momento de obtener la lista de deudas' });
    }
  }

  /**
   * getDefaultvendor
   * Obtiene el gestor de pagos predeterminado (vendor) del cliente
   */
  getDefaultVendor(): void {
    this.data.clients_vendors.forEach(vendor => {
      if (vendor.default === 1) {
        this.vendor = vendor.vendor.name;
      }
    });
  }

  /**
   * latePaymentSummaryItem
   * Despliega un modal con el resumen de los cargos efectuados para un pago tardio acumulado
   * @param charge cargo seleccionado
   */

  latePaymentSummaryItem(charge): void {
    const formatData = this.helpersService.flatObject(charge);
    const props: SharedComponent = new SharedComponent(
      ChargeSummaryComponent,
      formatData,
      { title: `Resumen del cargo de ${this.data.client.name}` }
    );
    this.appModal.opensm(props);
  }

  /**
   * getValidCards
   * @param client_cards array de tarjetas registradas en la BD del cliente 
   * @returns true si el cliente tiene al menos una tarjeta valida si no regresa false
   */

  getValidCards(client_cards: Array<any>): boolean {
    const curren_date = new Date();
    let valid_cards_amount = 0;
    if (client_cards.length > 0) {
      client_cards.forEach((client_card: any) => {
        const exp_year = client_card.exp_year;
        const exp_month = client_card.exp_month;
        const exp_date = new Date(`20${exp_year}/${exp_month}/01`);
        if (exp_date.getTime() > curren_date.getTime()) {
          valid_cards_amount += 1;
        }
      });

      if (valid_cards_amount > 0) {
        return true;
      }
    }

    return false;
  }

  chargeClientDebts(): void {
    this.offlinePaymentItem(this.data);
  }

  offlinePaymentItem(client_data): void {
    this.showOfflinePaymentModal(client_data);
  }

  total(client): number {
    if (client.social_responsability === 1) {
      this.contractData.sr = client.monthly_fee / 1.16 * 0.007;
    }
    const total = (client.monthly_fee / 100) + this.sr + 100;

    return total;
  }

  /**
   * updateDebt
   * Permite remover los moratorios y collection fees a una deuda
   */
  updateDebt(data): void {
    const id_swal = 'update-debt';
    const swalParams = {
      confirmButtonText: 'Actualizar',
      id: id_swal,
      inputs: [
        `<div id=${id_swal}>` +
        '<select id="modification_type" class="swal2-input">' +
        '<option value="1">Condonar moratorios y gastos de cobranza</option>' +
        '<option value="2">Condonar deuda seleccionada</option>' +
        '</select>',
        '<input id="reason" type="text", placeholder="Razon" class="swal2-input" required>' +
        '</div>'
      ]
    };
    this.swal.customForm(swalParams).then(formValues => {
      const payload = { id_debts: data.id_debts };
      formValues.value.forEach(element => {
        Object.assign(payload, element);
      });
      this.subscriptions.push(this.debts.update(data.id_debts, payload).subscribe((response: any) => {
        if (response.success) {
          this.swal.success().then(() => {
            this.broadcast.reloadDataTable();
          });
        } else {
          this.swal.error({ text: response.message });
        }
      }));
    });
    // this.debts.update(data.id_debts)
  }

  private showOfflinePaymentModal(client_data: any): void {
    if (client_data.completed_installation !== null) {
      this.contractData.monthly_fee = client_data.monthly_fee / 100;
      this.contractData.total = this.total(client_data);
      if (client_data.social_responsability === 1) {
        this.contractData.sr = this.contractData.monthly_fee / 1.16 * 0.007;
      }
      const props: SharedComponent = new SharedComponent(
        ManualPaymentComponent,
        {
          client: client_data,
          contract: this.contractData,
          firstCharge: false
        },
        { title: 'Pago por OXXO / SPEI' });
      this.appModal.openXl(props);
    } else {
      const swal_params = {
        title: 'No se puede generar la referencia',
        text: 'Favor de solicitar al tecnico cerrar el ticket de instalación',
        confirmButtonText: 'Aceptar',
        showCancelButton: false
      };
      this.swal.warning(swal_params);
    }
  }

}
