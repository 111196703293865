import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChargesService } from '../../../../services/charges.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-review-refunds',
  templateUrl: './review-refunds.component.html',
  styleUrls: ['./review-refunds.component.scss']
})
export class ReviewRefundsComponent implements OnInit, OnDestroy {
  @Input() data: any;
  charge: any;
  subscriptions: Array<Subscription> = [];

  dataTableConfig = {
    config: {
      base: this.chargeService,
      api: 'getChargeRefunds',
      params: {
        id_clients: 0
      }
    },
    columns: [
      {
        display: 'ID del cargo',
        field: 'id_charges',
        type: 'text'
      },
      {
        display: 'Cantidad',
        field: 'amount',
        type: 'currency'
      },
      {
        display: 'Fecha del reembolso',
        field: 'created_at',
        type: 'text'
      },
      {
        display: 'Efectuado en',
        field: 'vendor',
        type: 'text'
      }
    ],
    filters: [{}]
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly chargeService: ChargesService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.chargeService.show(this.data.id_charges).subscribe((data: any) => {
      this.charge = data.response;
    }));
    this.dataTableConfig.config.params.id_clients = this.data.id_charges;
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

}
