/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./previous.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../badge/badge.component.ngfactory";
import * as i3 from "../badge/badge.component";
import * as i4 from "../../../../../shared/previous-ticket-data/previous-ticket-data.component.ngfactory";
import * as i5 from "../../../../../shared/previous-ticket-data/previous-ticket-data.component";
import * as i6 from "@angular/common";
import * as i7 from "./previous.component";
var styles_PreviousComponent = [i0.styles];
var RenderType_PreviousComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PreviousComponent, data: {} });
export { RenderType_PreviousComponent as RenderType_PreviousComponent };
function View_PreviousComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "col-md-12 content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "history-ticket-badge", [], null, null, null, i2.View_BadgeComponent_0, i2.RenderType_BadgeComponent)), i1.ɵdid(3, 114688, null, 0, i3.BadgeComponent, [], { item: [0, "item"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-previous-ticket-data", [], null, null, null, i4.View_PreviousTicketDataComponent_0, i4.RenderType_PreviousTicketDataComponent)), i1.ɵdid(5, 114688, null, 0, i5.PreviousTicketDataComponent, [], { ticketData: [0, "ticketData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.item; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_PreviousComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PreviousComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PreviousComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "history-ticket-previous", [], null, null, null, View_PreviousComponent_0, RenderType_PreviousComponent)), i1.ɵdid(1, 114688, null, 0, i7.PreviousComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreviousComponentNgFactory = i1.ɵccf("history-ticket-previous", i7.PreviousComponent, View_PreviousComponent_Host_0, { item: "item" }, {}, []);
export { PreviousComponentNgFactory as PreviousComponentNgFactory };
