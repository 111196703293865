<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <div class="row">
        <div class="col-sm-8">
            <!-- <table class="table">
                <thead>
                    <tr>
                        <th>Estatus</th>
                        <th>Instalados</th>
                        <th>Cobrados</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let client of data.clientStatus">
                        <td>
                            <span [ngSwitch]="client.status">
                                <p *ngSwitchCase="'standby'">Pendiente</p>
                                <p *ngSwitchCase="'accepted'">Aceptado</p>
                                <p *ngSwitchCase="'rejected'">Rechazado</p>
                                <p *ngSwitchCase="'invalid'">Inválido</p>
                                <p *ngSwitchCase="'canceled'">Cancelado</p>
                            </span>
                        </td>
                        <td>{{client.installed}}</td>
                        <td>{{client.charged}}</td>
                        <td>{{client.quantity}}</td>
                    </tr>
                </tbody>
            </table> -->
            <h3>Filter changes due</h3>
            <table class="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>6 months (Premium)</th>
                        <th>1 year</th>
                        <th>2 years</th>
                        <th>4 years</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let month of this.data.filterChanges | keyvalue : noOrder">
                        <td>{{ month.key }}</td>
                        <td>{{ month.value.six_months }}</td>
                        <td>{{ month.value.one_year }}</td>
                        <td>{{ month.value.two_year }}</td>
                        <td>{{ month.value.four_year }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="col-sm-4">
            <h3>Ticket status</h3>
            <table class="table">
                <thead>
                    <tr>
                        <th>Estatus</th>
                        <th>Cantidad</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Abiertos</td>
                        <td>{{data.ticketStatus.opened}}</td>
                    </tr>
                    <tr>
                        <td>Asignados</td>
                        <td>{{data.ticketStatus.assigned}}</td>
                    </tr>
                    <tr>
                        <td>Completados</td>
                        <td>{{data.ticketStatus.completed}}</td>
                    </tr>
                    <tr>
                        <td>Confirmados</td>
                        <td>{{data.ticketStatus.confirmed}}</td>
                    </tr>
                    <tr>
                        <td>Cerrados</td>
                        <td>{{data.ticketStatus.closed}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>