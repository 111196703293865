import { Component, OnInit } from '@angular/core';
import { NPSService } from '../../../../services/nps.service';
import { Subscription } from 'rxjs';
import { BroadcastService } from '../../../../services/broadcast.service';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { SharedComponent } from '../../../../model/shared-component';
import { SwalService } from '../../../../services/swal.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NpsFormComponent } from './nps-form/nps-form.component';
import { PermissionsService } from '../../../../services/permissions.service';

@Component({
  selector: 'app-nps-table',
  templateUrl: './nps-table.component.html',
  styleUrls: ['./nps-table.component.scss']
})
export class NpsTableComponent implements OnInit {
  broadcast$: Subscription;
  dataTableConfig: any;
  
  private readonly baseDataTableConfig = {
    config: {
      base: this.npsService,
      api: 'getNPS'
    },
    columns: [
      {
        display: 'Período',
        field: 'date',
        type: 'date'
      },
      {
        display: 'NPS',
        field: 'nps',
        type: 'percent'
      },
      {
        display: 'Acciones',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Editar',
            icon: 'fa fa-pencil',
            event: 'nps.edit',
            conditionality: 'true',
            permissions: ['update-nps']
          },
          {
            display: 'Eliminar',
            icon: 'fa fa-trash fa-fw',
            event: 'nps.delete',
            conditionality: 'true',
            permissions: ['delete-nps']
          }
        ]

      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    private readonly npsService: NPSService,
    private readonly broadcast: BroadcastService,
    private readonly swal: SwalService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.broadcast$ = this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'nps.delete': this.deleteNPSItem(event.data); break;
        case 'nps.edit': this.editNPSItem(event.data); break;
      }
    });
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    this.broadcast$.unsubscribe();
  }

  createNPSItem(data?): void {
    const props: SharedComponent = new SharedComponent(
      NpsFormComponent,
      {
        npsData: data,
        status: 'create'
      },
      {
        title: 'Crear entrada de NPS'
      }
    );
    this.appModal.opensm(props);
  }

  deleteNPSItem(data): void {
    const swalParams = {
      title: `¿Estás seguro de querer eliminar NPS del período ${data.date}?`
    };

    this.swal.warning(swalParams).then(async result => {
      if (result.value) {
        const resp = await this.npsService.delete(data.id_nps).toPromise();
        if (resp && resp.success) {
          this.swal.success({ title: 'NPS eliminado exitosamente' }).then(() => {
            this.activeModal.dismiss();
            this.broadcast.reloadDataTable();
          });
        } else {
          this.swal.error('Ocurrio un error al intentar borrar NPS');
        }
      }
    });
  }

  editNPSItem(data): void {
    // open modal, passing the context
    const props: SharedComponent = new SharedComponent(
      NpsFormComponent,
      {
        npsData: data,
        status: 'edit'
      },
      { 
        title: 'Net Promoter Score '
      });
    this.appModal.opensm(props);
  }

}
