import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../providers/form.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { GroupsService } from '../../../services/groups.service';
import { ClientsService } from '../../../services/clients.service';
import { SwalService } from '../../../services/swal.service';

@Component({
  selector: 'app-update-accepted-clients-sales-group',
  templateUrl: './update-accepted-clients-sales-group.component.html',
  styleUrls: ['./update-accepted-clients-sales-group.component.scss']
})
export class UpdateAcceptedClientsSalesGroupComponent implements OnInit, OnDestroy {
  subscriptions: Array<Subscription> = [];
  groups = [];
  clients = [];
  showClients = false;
  option: string;
  updateSaleGroupOption = [
    { id: 'all', name: 'Todos' },
    { id: 'custom', name: 'Custom' }
  ];
  form: FormGroup = this.formBuilder.group({
    id_group: ['', Validators.required],
    internalData: this.formBuilder.group({
      updateSaleGroupOption: ['', Validators.required]
    })
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly clientsService: ClientsService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swalService: SwalService,
    private readonly groupService: GroupsService
  ) { }

  ngOnInit(): void {
    this.fromService.setForm(this.form);
    this.checkSelectedOption();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * updateClientsSalesGroup
   * Manda una peticion POST para actualizar el grupo de ventas de los clientes.
   */
  updateClientsSalesGroup(): void {
    this.option = this.showClients ? 'los siguientes' : 'todos los';
    this.form.removeControl('internalData');
    const swalTitle = `Se cambiaran el grupo de ventas de ${this.option} clientes`;
    this.swalService.warning({ title: swalTitle }).then(result => {
      if (result.value) {
        this.subscriptions.push(this.clientsService.updateClientsSalesGroup(this.form.value).subscribe((response: any) => {
          if (response.success) {
            this.swalService.success().then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swalService.error({ title: response.message });
          }
        }));
      }
    });

  }

  /**
   * checkSelectedOption
   * Revisa la opción seleccionada en el formulario para determinar si se han de cargar la lista de clientes aceptados.
   */
  private checkSelectedOption(): void {
    this.subscriptions.push(this.form.get('internalData.updateSaleGroupOption').valueChanges.subscribe(selectedOption => {
      if (selectedOption) {
        this.showClients = selectedOption === 'custom' ? true : false;
        if (this.groups.length <= 0) {
          this.getGroups();
        }

        if (this.showClients && this.clients.length <= 0) {
          this.getActiveClients();
        }

        this.updateFormulary();
      }
    }));
  }

  /**
   * getActiveClients
   * Obtiene a todos los clientes aceptados en el sistema.
   */
  private getActiveClients(): void {
    const params = {
      selected_columns: ['id_clients', 'name'],
      status: 'accepted',
      length: '3000'
    };

    this.subscriptions.push(this.clientsService.getClients(params).subscribe((resp: any) => {
      if (resp.data.length > 0) {
        this.clients = resp.data.map(client => ({ id: client.id_clients, name: client.name }));
      }
    }));
  }

  /**
   * getGroups
   * Obtiene los grupos de ventas padre
   */
  private getGroups(): void {
    const params = { start: 0, length: 50 };
    this.subscriptions.push(this.groupService.getGroups(params).subscribe((resp: any) => {
      this.groups = resp.data.map(group => ({ id: group.id_groups, name: group.name }));
    }));
  }

  /**
   * updateFormulary
   * Añade o remueve al formulario el campo id_clients en base al valor del campo
   * internalData.updateSaleGroupOption
   */
  private updateFormulary(): void {
    const idClients = this.form.get('id_clients');
    if (this.showClients && !idClients) {
      this.form.addControl('id_clients', new FormControl('', [Validators.required]));
    }

    if (!this.showClients && idClients) {
      this.form.removeControl('id_clients');
    }
  }

}
