import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { CardsService } from '../../../../services/cards.service';
import { SwalService } from '../../../../services/swal.service';

@Component({
  selector: 'app-card-create',
  templateUrl: './card-create.component.html',
  styleUrls: ['./card-create.component.scss']
})
export class CardCreateComponent implements OnInit {
  @Input() data: any;
  months = [];
  years = [];
  subscriptions: Array<Subscription> = [];
  form: FormGroup = this.formBuilder.group({
    card_number: ['', [Validators.required, Validators.minLength(15), Validators.maxLength(16)]],
    cvc: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
    expiration_month: ['', Validators.required],
    expiration_year: ['', Validators.required]
  });

  constructor(
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly cardsService: CardsService,
    private readonly swalService: SwalService
  ) { }

  ngOnInit(): void {
    const cardsInfo = this.cardsService.setDateCardInfo();
    this.months = cardsInfo.months;
    this.years = cardsInfo.years;
    this.cardsService.setVendors();
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * createCard
   * Envia una petición POST a la API con los tokens de la tarjeta para asociarla al cliente
   */
  createCard(): void {
    this.registerCard().then(response => {

      if (response.status && response.data) {
        const params = this.formatParams(response);
        this.subscriptions.push(this.cardsService.create(this.data.id_clients, params).subscribe(resp => {
          if (resp.success) {
            this.swalService.success({ text: 'Tarjeta registrada exitosamente' }).then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swalService.error();
          }
        }));
      } else {
        const message = response.data ? response.data : 'Ocurrio un error al validar los datos de la tarjeta';
        this.swalService.error({ text: message });
      }
    });
  }
  /**
   * formatParams
   * Genera un Object que con el formato necesario para poder enviar los datos de los tokens de la tarjeta a la API
   * @param response respuesta con los diferentes tokens de las tarjetas
   * @returns Object
   */
  formatParams(response): Object {
    const tokens = {};
    const params = {};
    Object.keys(response.data).forEach(key => {
      if (key !== 'openpay_device_session_id') {
        tokens[key] = response.data[key];
      }
    });

    Object.assign(params, { token: tokens });

    if (response.data.hasOwnProperty('openpay_device_session_id')) {
      Object.assign(params, { deviceSessionId: response.data.openpay_device_session_id });
    }

    return params;
  }

  /**
   * registerCard
   * Evalua si la información introducida en la seccion de la tarjeta es valida:
   * + Número de tarjeta valido
   * + CVC valido
   * + Fecha de expiración
   * Si la validación de la tarjeta no devuelve ningún error se ejecuta la función para
   * obtener los tokens (CONEKTA, Openpay) de las tarjetas
   * @returns Objeto con la información si la tarjeta se registro o no.
   */
  private async registerCard(): Promise<any> {
    const response = { status: true, added_card: false };
    // const cardData = this.form.get('internalData.cardData');
    if (this.form.dirty) {
      const cardValidationError = this.cardsService.cardValidation(this.form.value);
      if (!cardValidationError) {
        const cardTokens = await this.setCardTokens();
        if (Object.keys(cardTokens).length > 0) {
          response.added_card = true;
          Object.assign(response, { data: cardTokens });
        }
      } else {
        response.status = false;
        Object.assign(response, { data: cardValidationError });
      }
    }

    return response;
  }

  /**
   * setCardTokens
   * Envia la información capturada en el formulario de la tarjeta a los vendors
   * para asi generar los tokens
   * @returns objeto con los tokens de cada vendor
   */
  private async setCardTokens(): Promise<Object> {
    const tokens = {};
    const clientData = {
      name: this.data.name,
      address: this.data.address,
      outdoor_number: this.data.outdoor_number,
      inside_number: this.data.inside_number,
      phone: this.data.phone,
      email: this.data.email,
      between_streets: this.data.between_streets,
      colony: this.data.colony,
      postal_code: this.data.postal_code,
      state: this.data.state,
      county: this.data.county
    };
    const carData = this.form.value;
    const allTokens = await this.cardsService.getTokens(clientData, carData).toPromise();
    allTokens.forEach(token => {
      if ('success' in token && token.success) {
        delete token.success;
        Object.assign(tokens, token);
      }
    });

    return tokens;
  }
}
