<br>
<div class="container">
    <ngb-tabset>
        <ngb-tab title="Datos Generales">
            <ng-template ngbTabContent>
                <div class="row">
                    <div class="col">
                        <app-form-field type="input" [options]="{label: 'Nombre', path: 'name'}"></app-form-field>
                        <app-form-field type="mask" [options]="{label: 'Teléfono', mask:'(00) 0000-0000', path: 'phone'}"></app-form-field>
                        <app-form-field type="email" [options]="{label: 'Email', path: 'email'}"></app-form-field>
                        <app-form-field type="fake_password" [options]="{label: 'Contraseña', path: 'password'}"></app-form-field>
                        <div *ngIf="form.controls.password.dirty">
                            <app-form-field type="password" [options]="{label: 'Confirmar contraseña', path: 'password_confirm'}"></app-form-field>
                        </div>
                        <div *ngIf="change_team">
                          <app-form-field type="select" 
                          [options]="{
                              label: 'Seleccionar distribuidor',
                              path:'team_id',
                              droptext: team,
                              elements: teams
                          }"></app-form-field>
                        </div>
                        <app-form-field type="switch" [options]="{
                                label: 'Estatus', 
                                path: 'status', 
                                trueValue: 1, 
                                falseValue: 0, 
                                trueCaption: 'Activo',
                                falseCaption: 'Inactivo'}">
                        </app-form-field>
                    </div>

                </div>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="Roles del usuario">
            <ng-template ngbTabContent>
              <div class="row">
                  <div class="col">
                    <div class="row ng-scope">
                      <div class="col-md-6">
                        <div class="panel panel-default">
                          <div class="panel-heading">
                            <div class="row">
                              <div class="col-md-12">
                                <i>Roles</i>
                              </div>
                            </div>
                          </div>
                          <div class="panel-body">
                            <div class="table-responsive">
                              <table class="table table-striped table-hover">
                                <tbody>
                                  <app-datatable [options]="dataTableConfig"></app-datatable>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="panel panel-default permissions-tab">
                          <div class="panel-heading">
                            <div class="row">
                              <div class="col-md-12">
                                <i>Roles seleccionados</i>
                              </div>
                            </div>
                          </div>
                          <div class="panel-body">
                            <div class="table-responsive">
                              <table class="table table-striped table-hover">
                                <tbody>
                                  <tr *ngFor="let role of form.controls.roles.value">
                                    <div *ngIf="role.name !== 'Founder'">
                                      <td class="col-md-8">{{role.display_name}}</td>
                                      <td class="col-md-4 remove"><button type="button" class="btn btn-success" (click)="removeRole(role.id)">Remover</button></td>
                                    </div>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
    <br>
    <div class="modal-footer">
        <div *ngIf="this.form.dirty">
            <button type="button" class="btn btn-outline-danger"
                (click)="activeModal.dismiss('Cross click')">Cancelar</button>
            <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
                (click)="save()">Guardar</button>
        </div>
        <div *ngIf="!this.form.dirty">
            <button type="button" class="btn btn-outline-dark"
                (click)="activeModal.dismiss('Cross click')">Cerrar</button>
        </div>
    </div>
</div>