<div class="container-fluid bg-white p-3">
    <app-form-field type="select" [options]="{
        label: 'Selecciona como quieres crear el credito',
        path: 'credit_mode',
        droptext: 'Selecciona como quieres generar el credito',
        elements: creditModes
    }"></app-form-field>
    <div *ngIf="monthAmount">
        <app-form-field type="select" [options]="{
            label: 'Selecciona la cantidad de meses',
            path: 'months',
            droptext: 'Selecciona como quieres generar el credito',
            elements: months
        }"></app-form-field>
    </div>
    <div *ngIf="manualAmount || monthAmount ">
        <app-form-field type="input" [options]="{label: 'Cantidad', path: 'amount', disabled: disabled}">
        </app-form-field>
    </div>
    <app-form-field type="input" [options]="{label: 'Razón', path: 'extra_data.reason'}">
    </app-form-field>
    <div>
        <h5>¿Desea que el daily charge aplique el credito de manera automatica?</h5>
        <app-form-field type="switch" [options]="{
            label: 'Automatico', 
            path: 'automatic', 
            trueValue: 1, 
            falseValue: 0, 
            trueCaption: 'Si',
            falseCaption: 'No'}">
        </app-form-field>
    </div>
    <br>

    <div class="modal-footer">
        <div *ngIf="this.form.dirty">
            <button type="button" class="btn btn-outline-danger"
                (click)="activeModal.dismiss('Cross click')">Cancelar</button>
            <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
                (click)="createCredit()">Crear credito</button>
        </div>
        <div *ngIf="!this.form.dirty">
            <button type="button" class="btn btn-outline-dark"
                (click)="activeModal.dismiss('Cross click')">Cerrar</button>
        </div>
    </div>
</div>