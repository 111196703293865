import { Router } from '@angular/router';
import { AuthorizationService } from './authorization.service';
import * as JWT from 'jwt-decode';
import * as i0 from "@angular/core";
import * as i1 from "./authorization.service";
import * as i2 from "@angular/router";
var RoleGuardService = /** @class */ (function () {
    function RoleGuardService(auth, router) {
        this.auth = auth;
        this.router = router;
        this.tokenPayload = {
            user: {
                roles: []
            }
        };
    }
    RoleGuardService.prototype.canActivate = function () {
        var authorized = false;
        this.tokenPayload = this.decodedToken;
        var roles = this.tokenPayload.user.roles;
        // User with only one role and role = Client can't access to ERP
        if (this.auth.isAuthenticated()) {
            authorized = true;
            if (roles && roles.length > 0 && roles.length === 1 && roles[0].name === 'Client') {
                authorized = false;
            }
        }
        return authorized;
    };
    Object.defineProperty(RoleGuardService.prototype, "decodedToken", {
        get: function () {
            var token = window.localStorage.getItem('access_token');
            var decoded = JWT(token);
            return decoded;
        },
        enumerable: true,
        configurable: true
    });
    RoleGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleGuardService_Factory() { return new RoleGuardService(i0.ɵɵinject(i1.AuthorizationService), i0.ɵɵinject(i2.Router)); }, token: RoleGuardService, providedIn: "root" });
    return RoleGuardService;
}());
export { RoleGuardService };
