import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { SuppliesService } from '../../../services/supplies.service';

@Component({
  selector: 'app-supply-record',
  templateUrl: './supply-record.component.html',
  styleUrls: ['./supply-record.component.scss']
})
export class SupplyRecordComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  historyConfig = {
    config: {
      type: 'supplies',
      base: this.suppliesService,
      api: 'getHistory',
      params: { history: 0 },
      order: [[0, 'desc']]
    },
    rows: [
      {
        display: 'Estatus',
        field: 'id'
      }
    ]
  };
  history = [];
  constructor(
    public activeModal: NgbActiveModal,
    private readonly suppliesService: SuppliesService
  ) { }

  ngOnInit(): void {
    this.getHistory();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * getHistory
   * Obtiene el historial las entradas y salidas de un insumo.
   */
  private getHistory(): void {
    this.subscriptions.push(
      this.suppliesService.getHistory(this.data.id).subscribe((data: any) => {
        data.forEach((element, index) => {
          let supplyMovement = '';
          switch (index) {
            case 0: supplyMovement = 'inputs'; break;
            case 1: supplyMovement = 'outputs'; break;
          }
          element.response.forEach(itm => {
            this.history.push({ supplyMovement, ...itm });
          });
          this.sortData();
        });
      })
    );
  }

  /**
   * sortData
   * Ordena history para mostrar el historial en orden descendente
   * @returns array de objectos ordenados de la fecha mas reciente a la mas antigua.
   */
  private sortData(): Array<any> {
    return this.history.sort((a, b) => {
      let nA = a.created_at;
      let nB = b.created_at;

      return (new Date(nB) as any) - (new Date(nA) as any);
    });
  }

}
