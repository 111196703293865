import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SellersRoutes } from './sellers.routing';
import { SalesGroupComponent } from './sales-group/sales-group.component';
import { ComponentsModule } from '../../components/components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FromService } from '../../providers/form.service';
import { ExtrasComponent } from './extras/extras.component';
import { CommissionsComponent } from './commissions/commissions.component';
import { LightboxModule } from 'ngx-lightbox';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PromotionalMonthlyFeesComponent } from './promotional-monthly-fees/promotional-monthly-fees.component';
import { CreateEditPromotionalMonthlyFeeComponent } from './promotional-monthly-fees/create-edit-promotional-monthly-fee/create-edit-promotional-monthly-fee.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(SellersRoutes),
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    LightboxModule,
    NgxPermissionsModule.forChild()
  ],
  declarations: [
    SalesGroupComponent,
    ExtrasComponent,
    CommissionsComponent,
    PromotionalMonthlyFeesComponent
  ],
  exports: [
    SalesGroupComponent
  ],
  entryComponents: [],
  providers: [
    FromService
  ]
})
export class SellersModule { }
