import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbCarouselConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ComponentsModule } from '../../components/components.module';
import { FromService } from '../../providers/form.service';
import { ClientsComponent } from './clients.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ComponentsModule,
        NgxPermissionsModule.forChild()
    ],
    declarations: [
        ClientsComponent,
    ],
    entryComponents: [
    ],
    exports: [
        ClientsComponent
    ],
    providers: [
        NgbCarouselConfig,
        FromService
    ]
})

export class ClientsModule { }
