/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./client-commission.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./client-commission.component";
var styles_ClientCommissionComponent = [i0.styles];
var RenderType_ClientCommissionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClientCommissionComponent, data: {} });
export { RenderType_ClientCommissionComponent as RenderType_ClientCommissionComponent };
function View_ClientCommissionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Activo"]))], null, null); }
function View_ClientCommissionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "badge badge-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Inactivo"]))], null, null); }
export function View_ClientCommissionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "d-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientCommissionComponent_1)), i1.ɵdid(9, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClientCommissionComponent_2)), i1.ɵdid(11, 278528, null, 0, i2.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i2.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.data.is_active; _ck(_v, 7, 0, currVal_2); var currVal_3 = 1; _ck(_v, 9, 0, currVal_3); var currVal_4 = 0; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.data.client == null) ? null : _co.data.client.name); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.data.client.level; _ck(_v, 5, 0, currVal_1); }); }
export function View_ClientCommissionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-client-commission", [], null, null, null, View_ClientCommissionComponent_0, RenderType_ClientCommissionComponent)), i1.ɵdid(1, 114688, null, 0, i3.ClientCommissionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClientCommissionComponentNgFactory = i1.ɵccf("app-client-commission", i3.ClientCommissionComponent, View_ClientCommissionComponent_Host_0, { data: "data" }, {}, []);
export { ClientCommissionComponentNgFactory as ClientCommissionComponentNgFactory };
