import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class AppUsageService {
    baseUrl = `${environment.apiUrl}`;
    constructor(private readonly http: HttpClient) { }
    
    show(params?): any {
        return this.http.post(`${this.baseUrl}/appUsage`, params);
    }

    registrations(): any {
        return this.http.get(`${this.baseUrl}/registration-per-platform`);
    }
}
