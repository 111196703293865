import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// Services
import { FromService } from '../../../../providers/form.service';
import { PermissionsService } from '../../../../services/permissions.service';
import { SwalService } from '../../../../services/swal.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { RolesService } from '../../../../services/roles.service';
var RoleCreateEditComponent = /** @class */ (function () {
    function RoleCreateEditComponent(broadcast, formBuilder, fromService, permissionsService, rolesService, swal, activeModal) {
        this.broadcast = broadcast;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.permissionsService = permissionsService;
        this.rolesService = rolesService;
        this.swal = swal;
        this.activeModal = activeModal;
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            display_name: ['', Validators.required],
            description: [''],
            permissions: [[]]
        });
        this.dataTableConfig = {
            config: {
                base: this.permissionsService,
                api: 'fetchPermissions'
            },
            columns: [
                {
                    display: 'Permiso',
                    field: 'display_name',
                    type: 'text'
                },
                {
                    display: '',
                    field: '',
                    type: 'inline-button',
                    options: [
                        {
                            cssClass: 'btn btn-success',
                            name: 'Seleccionar',
                            event: 'notify.permission',
                            conditionality: 'true'
                        }
                    ]
                }
            ]
        };
    }
    RoleCreateEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.broadcast$ = this.broadcast.events.subscribe(function (event) {
            switch (event.name) {
                case 'notify.permission':
                    _this.addPermission(event.data);
                    break;
            }
        });
        if (this.data.status === 'edit') {
            this.rolesService.show(this.data.role.id).subscribe(function (data) {
                if (data && data.success) {
                    _this.form.patchValue(data.response);
                }
                // this.userService.getImageProfile(this.admin.id).subscribe((resp: any) => {
                //   this.image_profile = `${this.baseUrl}/profiles/${resp.response}`;
                // });
            });
        }
        // this.phoneValidator(this.role.role);
        this.fromService.setForm(this.form);
    };
    RoleCreateEditComponent.prototype.ngOnDestroy = function () {
        this.broadcast$.unsubscribe();
    };
    RoleCreateEditComponent.prototype.save = function () {
        if (this.data.status === 'edit') {
            this.updateRole(this.data.role.id);
        }
        if (this.data.status === 'create') {
            this.saveRole();
        }
    };
    RoleCreateEditComponent.prototype.saveRole = function () {
        var _this = this;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer guardar los datos del rol?' }).then(function (result) {
                if (result.value) {
                    var formData = tslib_1.__assign({}, _this.form.value);
                    var permissionsIds = _this.form.controls.permissions.value.map(function (permission) { return permission.id; });
                    formData.permissions = permissionsIds;
                    _this.rolesService.createRole(formData).subscribe(function (resp) {
                        if (resp.success) {
                            _this.swal.success().then(function () {
                                _this.form.reset();
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                    });
                }
                else {
                    _this.swal.error({ title: 'Ocurrió un error al crear el rol' });
                }
            });
        }
    };
    RoleCreateEditComponent.prototype.updateRole = function (rolId) {
        var _this = this;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del rol?' }).then(function (result) {
                if (result.value) {
                    _this.rolesService.updateRole(rolId, _this.form.value).subscribe(function (resp) {
                        if (resp.success) {
                            _this.swal.success().then(function () {
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                        else {
                            _this.swal.error({ title: 'Ocurrió un error al actualizar los datos' });
                        }
                    });
                }
            });
        }
    };
    RoleCreateEditComponent.prototype.addPermission = function (selectedPermission) {
        if (!this.form.controls.permissions.value.length ||
            !this.form.controls.permissions.value.find(function (permission) { return permission.id === selectedPermission.id; })) {
            this.form.controls.permissions.setValue(this.form.controls.permissions.value.concat([selectedPermission]));
            this.form.markAsDirty();
        }
        else {
            this.swal.error({ title: 'Ya tiene este permiso.', text: '' });
        }
    };
    RoleCreateEditComponent.prototype.removePermission = function (permissionId) {
        var actualPermissions = this.form.controls.permissions.value.filter(function (permission) { return permission.id !== permissionId; });
        this.form.controls.permissions.setValue(actualPermissions);
        this.form.markAsDirty();
    };
    return RoleCreateEditComponent;
}());
export { RoleCreateEditComponent };
