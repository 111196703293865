import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../environments/environment';
// Services
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { RolesService } from '../../../../services/roles.service';
import { SwalService } from '../../../../services/swal.service';
import { TeamsService } from '../../../../services/teams.service';
import { UsersService } from '../../../../services/users.service';
var UserCreateEditComponent = /** @class */ (function () {
    function UserCreateEditComponent(swal, broadcast, formBuilder, fromService, userService, rolesService, teamsService, activeModal) {
        this.swal = swal;
        this.broadcast = broadcast;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.userService = userService;
        this.rolesService = rolesService;
        this.teamsService = teamsService;
        this.activeModal = activeModal;
        this.baseUrl = "" + environment.apiUrl;
        this.change_team = false;
        this.teams = [];
        this.form = this.formBuilder.group({
            name: ['', Validators.required],
            image64: [''],
            phone: [''],
            email: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            password_confirm: ['', [Validators.required, Validators.minLength(6)]],
            contractor: [''],
            status: [''],
            type: [''],
            roles: [[]]
        });
        this.dataTableConfig = {
            config: {
                base: this.rolesService,
                api: 'getRoles'
            },
            columns: [
                {
                    display: 'Nombre',
                    field: 'name',
                    type: 'text'
                },
                {
                    display: '',
                    field: '',
                    type: 'inline-button',
                    options: [
                        {
                            cssClass: 'btn btn-success',
                            name: 'Seleccionar',
                            event: 'notify.role',
                            conditionality: 'true'
                        }
                    ]
                }
            ]
        };
    }
    UserCreateEditComponent.prototype.ngOnInit = function () {
        this.change_team = this.data.show_teams ? true : false;
        this.initBroadcast();
        this.defineViewStatus();
        this.checkPermission();
        // this.phoneValidator(this.role.role);
        this.fromService.setForm(this.form);
    };
    UserCreateEditComponent.prototype.ngOnDestroy = function () {
        this.broadcast$.unsubscribe();
    };
    UserCreateEditComponent.prototype.initBroadcast = function () {
        var _this = this;
        this.broadcast$ = this.broadcast.events.subscribe(function (event) {
            switch (event.name) {
                case 'notify.role':
                    _this.addRole(event.data);
                    break;
            }
        });
    };
    /**
     * defineviewStatus
     * Si la variable status es edit obtiene y setea en el formulario las variables del user id
     */
    UserCreateEditComponent.prototype.defineViewStatus = function () {
        var _this = this;
        if (this.data.status === 'edit') {
            this.userService.show(this.data.user.id).subscribe(function (data) {
                _this.admin = data.response || _this.data.user;
                Object.keys(_this.admin).forEach(function (key) {
                    if (_this.admin.hasOwnProperty(key) && !!_this.form.controls[key]) {
                        _this.form.controls[key].setValue(_this.admin[key]);
                    }
                });
                _this.updateValidators();
                // this.userService.getImageProfile(this.admin.id).subscribe((resp: any) => {
                //   this.image_profile = `${this.baseUrl}/profiles/${resp.response}`;
                // });
            });
        }
    };
    UserCreateEditComponent.prototype.updateValidators = function () {
        var password = this.form.get('password');
        var password_confirm = this.form.get('password_confirm');
        this.form.get('password').clearValidators();
        this.form.get('password_confirm').clearValidators();
        this.form.get('password').valueChanges.subscribe(function (value) {
            if (value !== '') {
                password.setValidators([Validators.required, Validators.minLength(6)]);
                password_confirm.setValidators([Validators.required, Validators.minLength(6)]);
                // password.updateValueAndValidity({ onlySelf: true });
                // password_confirm.updateValueAndValidity({ onlySelf: true});
            }
        });
        password.updateValueAndValidity();
        password_confirm.updateValueAndValidity();
    };
    UserCreateEditComponent.prototype.phoneValidator = function (role) {
        if (role === 'Technician') {
            this.form.get('phone').setValidators([Validators.required]);
            this.form.get('phone').updateValueAndValidity();
        }
    };
    UserCreateEditComponent.prototype.save = function () {
        if (this.data.status === 'edit') {
            this.updateUser();
        }
        if (this.data.status === 'create') {
            this.saveUser();
        }
    };
    UserCreateEditComponent.prototype.saveUser = function () {
        var _this = this;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer guardar los datos del usuario?' }).then(function (result) {
                if (result.value) {
                    var formData = tslib_1.__assign({}, _this.form.value);
                    var roleIds = _this.form.controls.roles.value.map(function (rol) { return rol.id; });
                    formData.roles = roleIds;
                    _this.userService.createUser(formData).subscribe(function (resp) {
                        if (resp.success) {
                            _this.swal.success().then(function () {
                                _this.form.reset();
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                    });
                }
                else {
                    _this.swal.error({ title: 'Ocurió un error al crear al usuario' });
                }
            });
        }
    };
    UserCreateEditComponent.prototype.updateUser = function () {
        var _this = this;
        if (this.form.valid && this.form.get('password').value === this.form.get('password_confirm').value) {
            this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del usuario?' }).then(function (result) {
                if (result.value) {
                    _this.userService.update(_this.admin.id, _this.form.value).subscribe(function (resp) {
                        if (resp.success) {
                            _this.swal.success().then(function () {
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                        else {
                            _this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
                        }
                    });
                }
            });
        }
    };
    UserCreateEditComponent.prototype.addRole = function (selectedRole) {
        if (!this.form.controls.roles.value.length || !this.form.controls.roles.value.find(function (role) { return role.id === selectedRole.id; })) {
            this.form.controls.roles.setValue(this.form.controls.roles.value.concat([selectedRole]));
            this.form.markAsDirty();
        }
        else {
            this.swal.error({ title: 'Ya tiene este rol.', text: '' });
        }
    };
    UserCreateEditComponent.prototype.removeRole = function (roleId) {
        var actualRoles = this.form.controls.roles.value.filter(function (role) { return role.id !== roleId; });
        this.form.controls.roles.setValue(actualRoles);
        this.form.markAsDirty();
    };
    /**
     * checkPermission
     * Revisa si el usuario logueado tiene permisos para listar los equipos de trabajo
     * en caso de que si añadira al formulario la posibilidad de seleccionar el team bajo el cual crear al usuario
     */
    UserCreateEditComponent.prototype.checkPermission = function () {
        if (this.change_team) {
            this.getTeams();
            this.form.addControl('team_id', new FormControl('', [Validators.required]));
            this.change_team = true;
        }
    };
    /**
     * getTeams
     * Obtiene los equipos de trabajo almacenados en la BD y los asigna a la variable teams
     */
    UserCreateEditComponent.prototype.getTeams = function () {
        var _this = this;
        this.teamsService.index().subscribe(function (resp) {
            if (resp.success) {
                _this.teams = resp.response;
            }
            else {
                _this.swal.error({ title: 'Ocurrio un error al momento de obtener la información de los equipos de trabajo' });
            }
        });
    };
    return UserCreateEditComponent;
}());
export { UserCreateEditComponent };
