<!-- <div *ngIf="data.signed_in_group">
    <a>{{data.signed_in_group.name}}</a>
</div>

<div *ngIf="data.signed_in_group == null">
    <a>{{data.signed_in}}</a>
</div> -->

<div *ngIf="current_group">
    <a>{{current_group.name}}</a>
</div>