<div class="container-fluid bg-white p-3">
    <div class="text-right" *ngIf="client">
        <button *ngIf="client.card_failed_record.length > 0" type="button" class="btn btn-danger"
            (click)="clientFailedCardHistoryItem(client)">Historial
            de tarjetas fallidas</button>
        <button type="button" class="btn btn-success" (click)="createCard(client)"
            *ngxPermissionsOnly="['create-cards']">Registrar tarjeta</button>
    </div>
    <app-datatable [options]="dataTableConfig"></app-datatable>

</div>

<div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('close button')">Close</button>
</div>