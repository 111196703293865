import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { DatatableComponent } from './datatable.component';
import { DatatableColumnComponent } from './datatable-column/datatable-column.component';
import { TicketStatusComponent } from './datatable-column/columns/ticket-status/ticket-status.component';
import { TicketClientComponent } from './datatable-column/columns/ticket-client/ticket-client.component';
import { TicketContactComponent } from './datatable-column/columns/ticket-contact/ticket-contact.component';
import { ActionsComponent } from './datatable-column/columns/actions/actions.component';
import { LabelComponent } from './datatable-column/columns/label/label.component';
import { FiltersComponent } from './datatable-column/columns/filters/filters.component';
import { InlineButtonComponent } from './datatable-column/columns/inline-button/inline-button.component';
import { ClientCommissionComponent } from './datatable-column/columns/client-commission/client-commission.component';
import { CommissionSummaryComponent } from './datatable-column/columns/commission-summary/commission-summary.component';
import { DebtTotalComponent } from './datatable-column/columns/debt-total/debt-total.component';
import { CardExpireComponent } from './datatable-column/columns/card-expire/card-expire.component';
import { ClientDataComponent } from './datatable-column/columns/client-data/client-data.component';
import { ClientSalesGroupComponent } from './datatable-column/columns/client-sales-group/client-sales-group.component';
import { CommissionStatusComponent } from './datatable-column/columns/commission-status/commission-status.component';
import { PipesModule } from '../pipes/pipes.module';
import { ChargeAmountComponent } from './datatable-column/columns/charge-amount/charge-amount.component';
import { TicketTechnicianComponent } from './datatable-column/columns/ticket-technician/ticket-technician.component';
import { IotLabelComponent } from './datatable-column/columns/iot-label/iot-label.component';
// import { NgbdSortableHeader, NgbdTableSortable } from './table-sortable';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        DataTablesModule,
        PipesModule
    ],
    declarations: [
        DatatableComponent,
        DatatableColumnComponent,
        TicketStatusComponent,
        TicketClientComponent,
        TicketContactComponent,
        ActionsComponent,
        LabelComponent,
        FiltersComponent,
        InlineButtonComponent,
        ClientCommissionComponent,
        CommissionSummaryComponent,
        DebtTotalComponent,
        CardExpireComponent,
        ClientSalesGroupComponent,
        CommissionStatusComponent,
        ClientDataComponent,
        ChargeAmountComponent,
        TicketTechnicianComponent,
        IotLabelComponent
    ],
    entryComponents: [
        DatatableComponent
    ],
    exports: [
        DatatableComponent
    ],
    providers: [],
    bootstrap: []
})

export class DatatableModule { }
