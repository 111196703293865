import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-clients-referals-commissions',
  templateUrl: './commissions.component.html',
  styleUrls: ['./commissions.component.scss']
})
export class CommissionsComponent implements OnInit {
  @Input() isMax = false;
  @Input() data: any;

  levels = [];
  total = 0;

  ngOnInit(): void {
    Object.keys(this.data).forEach(key => {
      if (typeof this.data[key] === 'object') {
        this.levels.push(this.data[key]);
        this.total += this.data[key].comission;
      }
    });
  }

}
