import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  baseUrl = `${environment.apiUrl}`;

  constructor(private readonly http: HttpClient) { }

  getInvoices(params?): any {
    return this.http.post(`${this.baseUrl}/invoices`, params);
  }

  downloadFiles(id, params?): Observable<any> {

    return this.http.get(`${this.baseUrl}/invoices/download_files/${id}`, params);
  }

  sendInvoice(params): Observable<any> {
    return this.http.post(`${this.baseUrl}/invoices/send_invoice`, params);
  }

  generateInvoice(id_charge, params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/invoices/manual_invoice/${id_charge}`, params);

  }
}
