import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// Components
import { ModalComponent } from '../../../../components/modal/modal.component';
import { SharedComponent } from '../../../../model/shared-component';
// Services
import { BroadcastService } from '../../../../services/broadcast.service';
import { MoneySpentCampaignService } from '../../../../services/money-spent-campaign.service';
import { MoneySpentCampaignFormComponent } from './money-spent-campaign-form/money-spent-campaign-form.component';
import { PermissionsService } from '../../../../services/permissions.service';
import { SwalService } from '../../../../services/swal.service';
var MoneySpentCampaignComponent = /** @class */ (function () {
    function MoneySpentCampaignComponent(broadcast, moneySpentCampaignService, permissionsService, swal, activeModal, appModal) {
        this.broadcast = broadcast;
        this.moneySpentCampaignService = moneySpentCampaignService;
        this.permissionsService = permissionsService;
        this.swal = swal;
        this.activeModal = activeModal;
        this.appModal = appModal;
        this.allData = [];
        this.baseDataTableConfig = {
            config: {
                base: this.moneySpentCampaignService,
                api: 'getMoneySpentCampaign'
            },
            columns: [
                {
                    display: 'Campaña Id',
                    field: 'campaign_id',
                    type: 'text'
                },
                {
                    display: 'Balance',
                    field: 'balance',
                    type: 'currency'
                },
                {
                    display: 'Acciones',
                    field: '',
                    type: 'actions',
                    options: [
                        {
                            display: 'Editar',
                            icon: 'fa fa-pencil',
                            event: 'campaign.edit',
                            conditionality: 'true',
                            permissions: ['update-campaign']
                        },
                        {
                            display: 'Eliminar',
                            icon: 'fa fa-trash fa-fw',
                            event: 'campaign.delete',
                            conditionality: 'true',
                            permissions: ['delete-campaign']
                        }
                    ]
                }
            ]
        };
    }
    MoneySpentCampaignComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.broadcast$ = this.broadcast.events.subscribe(function (event) {
            switch (event.name) {
                case 'campaign.delete':
                    _this.deleteItem(event.data);
                    break;
                case 'campaign.edit':
                    _this.editItem(event.data);
                    break;
            }
        });
        this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
    };
    MoneySpentCampaignComponent.prototype.ngOnDestroy = function () {
        this.broadcast$.unsubscribe();
    };
    MoneySpentCampaignComponent.prototype.createItem = function (data) {
        var _this = this;
        this.moneySpentCampaignService.index().subscribe(function (res) {
            if (res.success) {
                _this.allData = res.response;
                var props = new SharedComponent(MoneySpentCampaignFormComponent, {
                    campaignData: _this.allData,
                    status: 'create'
                }, {
                    title: 'Crear entrada'
                });
                _this.appModal.open(props);
            }
        });
    };
    MoneySpentCampaignComponent.prototype.deleteItem = function (data) {
        var _this = this;
        var swalParams = {
            title: "\u00BFEst\u00E1s seguro de querer eliminar el balance de la campa\u00F1a " + data.campaign_id + "?",
            text: ''
        };
        this.swal.warning(swalParams).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var resp;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!result.value) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.moneySpentCampaignService.delete(data.id).toPromise()];
                    case 1:
                        resp = _a.sent();
                        if (resp && resp.success) {
                            this.swal.success({ title: 'Entrada eliminada exitosamente' }).then(function () {
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                        else {
                            this.swal.error('Ocurrio un error al intentar borrar la entrada');
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    MoneySpentCampaignComponent.prototype.editItem = function (data) {
        // open modal, passing the context    
        var props = new SharedComponent(MoneySpentCampaignFormComponent, {
            campaignData: data,
            status: 'edit'
        }, {
            title: 'Entradas'
        });
        this.appModal.open(props);
    };
    return MoneySpentCampaignComponent;
}());
export { MoneySpentCampaignComponent };
