import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PollsService } from '../../../../services/polls.service';

@Component({
  selector: 'app-poll-show',
  templateUrl: './poll-show.component.html',
  styleUrls: ['./poll-show.component.scss']
})
export class PollShowComponent implements OnInit, OnDestroy {

  @Input() data: any;
  poll: any;
  private subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private readonly pollsService: PollsService
  ) { }

  ngOnInit(): void {
    this.subscription.add(
      this.pollsService.show(this.data.idPoll).subscribe(poll => {
        if (poll && poll.success) {
           this.poll = poll.response;
        }
      })
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
