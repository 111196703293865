<div class="container">
    <div class="row timeline-item ng-scope" *ngFor="let item of items">
        <div class="col-md-2 date-event">
            <br>
            <p [ngSwitch]="true">
                <span *ngSwitchCase="item.type ==='client_log' || item.type === 'client_log_prices'">{{item.element.created_at | customDate:'MMMM Do, YYYY, h:mm:ss a' }}<br></span>
                <span *ngSwitchDefault>{{item.created_at | customDate:'MMMM Do, YYYY, h:mm:ss a' }}<br></span>
                <small class="text-info" *ngSwitchCase="item.type ==='client_log'  || item.type === 'client_log_prices'">{{item.element.created_at | dateDiff}}</small>
                <small class="text-info" *ngSwitchDefault>{{item.created_at | dateDiff}}</small>
            </p>
            <span *ngIf="item.type ==='client_log' && item.element.user_name">Cambio realizado por: {{item.element.user_name}}</span>
            <span *ngIf="item.supplyMovement && item.user.name">Movimiento realizado por: {{item.user.name}}</span>
        </div>
        <div class="col-md-10 content no-top-border">
            <app-items [item]="item" [type]="type"></app-items>
        </div>
    </div>
</div>