import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})

export class VendorsService {
    baseUrl = `${environment.apiUrl}`;

    constructor(private readonly http: HttpClient) { }

    /**
     * show
     * Petición GET para obtener la información de un vendor mediante el id
     * @param id del vendor
     * @returns 200 | 404
     */
    show(id): Observable<any> {
        return this.http.get(`${this.baseUrl}/vendors/${id}`);
    }
    
    /**
     * getAll
     * Obtiene todos lo vendors activos en el sistema.
     */
    getAll(): Observable<any> {
        return this.http.get(`${this.baseUrl}/vendors`);
    }

}
