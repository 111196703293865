import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ErrrorCodesService = /** @class */ (function () {
    function ErrrorCodesService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    ErrrorCodesService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/error-codes", params);
    };
    ErrrorCodesService.prototype.delete = function (id) {
        return this.http.delete(this.baseUrl + "/error-codes/" + id);
    };
    ErrrorCodesService.prototype.edit = function (id, params) {
        return this.http.put(this.baseUrl + "/error-codes/" + id, params);
    };
    ErrrorCodesService.prototype.getErrorCodes = function (params) {
        return this.http.post(this.baseUrl + "/error-codes/getErrorCodes", params);
    };
    ErrrorCodesService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/error-codes/" + id);
    };
    ErrrorCodesService.prototype.index = function () {
        return this.http.get(this.baseUrl + "/error-codes");
    };
    ErrrorCodesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrrorCodesService_Factory() { return new ErrrorCodesService(i0.ɵɵinject(i1.HttpClient)); }, token: ErrrorCodesService, providedIn: "root" });
    return ErrrorCodesService;
}());
export { ErrrorCodesService };
