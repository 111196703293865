import { Component, OnInit, Input, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { SharedComponentDirective } from '../../../directives/shared-component.directive';
import { SharedComponentI } from '../../../model/shared-component.interface';
import { SharedComponent } from '../../../model/shared-component';

@Component({
  selector: 'app-modal-body',
  templateUrl: './modal-body.component.html',
  styleUrls: ['./modal-body.component.scss']
})
export class ModalBodyComponent implements OnInit {
  @Input() props: SharedComponent;
  @ViewChild(SharedComponentDirective, {static: true}) sharedComponent: SharedComponentDirective;

  constructor(private readonly componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit(): void {
    const cmpFctory = this.componentFactoryResolver.resolveComponentFactory(this.props.component);
    const viewContainer = this.sharedComponent.viewContainerRef;
    viewContainer.clear();

    const cmpRef = viewContainer.createComponent(cmpFctory);
    (cmpRef.instance as SharedComponentI).data = this.props.data;
  }

}
