import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
// Services
import { ClientsService } from '../../../../services/clients.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { SwalService } from '../../../../services/swal.service';
var ClientsMapComponent = /** @class */ (function () {
    function ClientsMapComponent(clientsService, dashboardService, swal) {
        this.clientsService = clientsService;
        this.dashboardService = dashboardService;
        this.swal = swal;
        this.longitude = -103.350693;
        this.latitude = 20.39079895;
        this.zoom = 8;
        this.coordinates = [];
        this.map = null;
    }
    ClientsMapComponent.prototype.ngOnInit = function () {
        //
    };
    // tslint:disable-next-line: typedef
    ClientsMapComponent.prototype.onMapLoad = function (mapInstance) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.dashboardService.clientsCoordinates().toPromise()];
                    case 1:
                        _a.coordinates = _b.sent();
                        this.map = mapInstance;
                        return [2 /*return*/];
                }
            });
        });
    };
    // tslint:disable-next-line: typedef
    ClientsMapComponent.prototype.showClientInfo = function (clientId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, client, params;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.clientsService.show(clientId).toPromise()];
                    case 1:
                        response = _a.sent();
                        // let info;
                        if (response && response.success) {
                            client = response.response;
                            params = {
                                title: '',
                                html: "\n          <h3>" + client.name + "</h3>\n          <table class=\"table\">\n            <tbody>\n              <tr>\n                <td><strong>Email:</strong></td>\n                <td>" + client.email + "</td>\n              </tr>\n              <tr>\n                <td><strong>Tel\u00E9fono:</strong></td>\n                <td>" + client.phone + "</td>\n              </tr>\n              <tr>\n                <td><strong>Status:</strong></td>\n                <td>" + client.status + "</td>\n              </tr>                      \n              <tr>\n                <td><strong>Direcci\u00F3n:</strong></td>\n                <td>" + client.address + ". Entre: " + client.between_streets + "</td>\n              </tr>\n              <tr>\n                <td><strong>Colonia:</strong></td>\n                <td>" + client.colony + "</td>\n              </tr>\n              <tr>\n                <td><strong>Colonia:</strong></td>\n                <td>" + client.county + "</td>\n              </tr>\n              <tr>\n                <td><strong>Estado:</strong></td>\n                <td>" + client.state + "</td>\n              </tr>   \n            </tbody>\n          </table>\n        "
                            };
                            this.swal.info(null, params);
                        }
                        else {
                            this.swal.error({ text: 'Ha ocurrido un error mientras se obtnía la información del cliente. Intente nuevamente.' });
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return ClientsMapComponent;
}());
export { ClientsMapComponent };
