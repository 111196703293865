<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <div class="row">
        <div class="col">
            <h2 class="m-0">Promocionales - meses</h2>
        </div>
        <div class="col text-right">
            <button class="btn btn-success align-items-end" (click)="createPromotionalMonthlyFee()" *ngxPermissionsOnly="['create-promotional-monthly-fees']">Crear mensualidad promocional</button>
        </div>
    </div>
</div>

<div class="container-fluid bg-white p-3">
    <app-datatable [options]="dataTableConfig"></app-datatable>
</div>
