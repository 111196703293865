import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientsService } from '../../../services/clients.service';
var ClientHistoryComponent = /** @class */ (function () {
    function ClientHistoryComponent(clientService, activeModal) {
        this.clientService = clientService;
        this.activeModal = activeModal;
        this.historyConfig = {
            config: {
                type: 'client',
                base: this.clientService,
                api: 'getHistory',
                params: { history: 0 },
                order: [[0, 'desc']]
            },
            rows: [
                {
                    display: 'Estatus',
                    field: 'id'
                }
            ]
        };
        this.history = [];
        this.history_log = [];
        this.history_price_change_log = [];
        this.subscriptions = [];
    }
    ClientHistoryComponent.prototype.ngOnInit = function () {
        var params = {
            id_clients: this.data.id_clients,
            id_users: this.data.id_users
        };
        this.getHistory(params);
        this.getLogs(params);
        this.getPriceChangeLog(params);
    };
    ClientHistoryComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    /**
     * getHistory
     * Obtiene el historial de invalidaciones, correcciones, rechazos, cancelaciones y notificaciones del cliente
     * @param params ids para identificar al cliente.
     */
    ClientHistoryComponent.prototype.getHistory = function (params) {
        var _this = this;
        this.subscriptions.push(this.clientService.getHistory(params).subscribe(function (data) {
            data.forEach(function (element, index) {
                var type = '';
                switch (index) {
                    case 0:
                        type = 'corrections';
                        break;
                    case 1:
                        type = 'invalidations';
                        break;
                    case 2:
                        type = 'rejections';
                        break;
                    case 3:
                        type = 'cancellations';
                        break;
                    case 4:
                        type = 'notifications';
                        break;
                }
                element.response.forEach(function (itm) {
                    if (itm.type === 1 || itm.type === 2) {
                        itm.type = 'notifications';
                    }
                    _this.history.push(tslib_1.__assign({ type: type }, itm));
                });
                _this.sortData(_this.history, null);
            });
        }));
    };
    /**
     * getLogs
     * Obtiene el historial de cambios realizados al cliente y a su usuario asociado
     * @param params ids para identificar al cliente.
     */
    ClientHistoryComponent.prototype.getLogs = function (params) {
        var _this = this;
        this.subscriptions.push(this.clientService.getLogs(params).subscribe(function (data) {
            var type = 'client_log';
            data.response.forEach(function (entry) {
                var element = _this.filterElements(entry);
                if (element !== undefined) {
                    _this.history_log.push({ type: type, element: element });
                }
                _this.sortData(_this.history_log, 'client_log');
            });
        }));
    };
    /**
     * getPriceChangeLog
     * Obtiene el historial de cambios de mensualidades efectuadas a un cliente
     * @param params ids para identificar al cliente.
     */
    ClientHistoryComponent.prototype.getPriceChangeLog = function (params) {
        var _this = this;
        this.subscriptions.push(this.clientService.getPriceHistory(params).subscribe(function (data) {
            var type = 'client_log_prices';
            if (data.response) {
                data.response.forEach(function (element) {
                    _this.history_price_change_log.push({ type: type, element: element });
                });
                _this.sortData(_this.history_price_change_log, 'client_log_prices');
            }
        }));
    };
    /**
     * filterElements
     * Evalua y filtra los campos before_data y after_data relacionados al cliente, esta información se devuelve de la tabla logs.
     * @param element log individual de la tabla logs
     * @returns un booleano o el elemento en caso de que haya diferencia
     */
    ClientHistoryComponent.prototype.filterElements = function (element) {
        var before_data = JSON.parse(element.before_data);
        var after_data = JSON.parse(element.after_data);
        var diff = false;
        Object.entries(after_data).forEach(function (entry) {
            var key = entry[0], after_data_val = entry[1];
            var before_data_val = before_data[key];
            if (after_data_val !== before_data_val) {
                diff = true;
            }
        });
        if (diff && element !== undefined) {
            return element;
        }
    };
    ClientHistoryComponent.prototype.sortData = function (order_value, type) {
        if (type !== null) {
            return order_value.sort(function (a, b) {
                return new Date(b.element.created_at) - new Date(a.element.created_at);
            });
        }
        return order_value.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
        });
    };
    return ClientHistoryComponent;
}());
export { ClientHistoryComponent };
