import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { InvestorDashboardService } from '../../../../services/investor-dashboard.service';
// import { MapService } from '../../../../services/map.service';
var ClientsHeatmapComponent = /** @class */ (function () {
    function ClientsHeatmapComponent(
    // private mapService: MapService
    investorDashboardService) {
        this.investorDashboardService = investorDashboardService;
        this.longitude = -103.350693;
        this.latitude = 20.39079895;
        this.zoom = 8;
        this.map = null;
        this.heatmap = null;
    }
    ClientsHeatmapComponent.prototype.ngOnInit = function () {
        // this.mapService.buildMap();
    };
    // tslint:disable-next-line: typedef
    ClientsHeatmapComponent.prototype.onMapLoad = function (mapInstance) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _coords, coords;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.investorDashboardService.getClientsCoordinates().toPromise()];
                    case 1:
                        _coords = _a.sent();
                        coords = _coords.map(function (c) { return new google.maps.LatLng(c.latitude, c.longitude); });
                        this.map = mapInstance;
                        this.heatmap = new google.maps.visualization.HeatmapLayer({
                            map: this.map,
                            maxIntensity: 5,
                            radius: 40,
                            data: coords
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return ClientsHeatmapComponent;
}());
export { ClientsHeatmapComponent };
