import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { CreateCreditComponent } from './create-credit/create-credit.component';
import { BroadcastService } from '../../../services/broadcast.service';
import { CreditsService } from '../../../services/credits.service';
import { SwalService } from '../../../services/swal.service';
import { PermissionsService } from '../../../services/permissions.service';
import { CreditHistoryComponent } from './credit-history/credit-history.component';

@Component({
  selector: 'app-client-credits',
  templateUrl: './client-credits.component.html',
  styleUrls: ['./client-credits.component.scss']
})
export class ClientCreditsComponent implements OnInit {
  @Input() data: any;
  dataTableConfig: any;
  notSupportedEvents = environment.notSupportedEvents;
  client: any;
  totalCredit = 0;
  subscriptions: Array<Subscription> = [];

  private readonly baseDataTableConfig = {
    config: {
      base: this.creditsService,
      api: 'getClientCreditsTable',
      params: {
        id_clients: 0
      }
    },
    columns: [
      {
        display: 'ID del credito',
        field: 'id_credit',
        type: 'text'
      },
      {
        display: 'Monto original',
        field: 'original_amount',
        type: 'number'
      },
      {
        display: 'Monto restante',
        field: 'remaining_amount',
        type: 'number'
      },
      {
        display: 'Estado',
        field: 'status',
        type: 'text'
      },
      {
        display: 'Autorizado',
        field: 'auth_by.name',
        type: 'text'
      },
      {
        display: 'Fecha de adición',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'Opciones',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Ver historial',
            event: 'creditHistory',
            conditionality: true
          },
          {
            display: 'Cancelar credito',
            event: 'creditCancellation',
            conditionality: 'this.data.status === "active"',
            permissions: ['delete-credits']
          }
        ]
      }
    ],
    filters: [{}]
  };
  constructor(
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    private readonly broadcast: BroadcastService,
    private readonly creditsService: CreditsService,
    private readonly swalService: SwalService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.client = this.data;
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
    this.dataTableConfig.config.params.id_clients = this.client.id_clients;
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      if (!this.notSupportedEvents.includes(event.name)) {
        this[event.name](event.data);
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * createCredit
   * Abre el modal para ingresar los datos necesarios para registrar el credito a un cliente.
   * @param client objeto de clase cliente
   */
  createCredit(client): void {
    const props: SharedComponent = new SharedComponent(
      CreateCreditComponent,
      client,
      {
        title: `Crear credito para el cliente ${client.name}`
      });
    this.appModal.opensm(props);
  }

  /**
   * creditHistory
   * Abre un modal para mostrar los diferentes cargos en los cuales se ha aplicado el credito seleccionado
   * @param credit objeto de la clase credito
   */
  creditHistory(credit): void {
    const props: SharedComponent = new SharedComponent(
      CreditHistoryComponent,
      credit,
      {
        title: `Historial del credito ${credit.id_credit}`
      });
    this.appModal.opensm(props);
  }

  /**
   * creditCancellation
   * Abre un SweetAlert para mandar una petición para cancelar un credito activo.
   * @param credit objeto de la clase credito
   */
  creditCancellation(credit): void {
    const id_swal = 'cancel-credit';
    const swalParams = {
      confirmButtonText: 'Cancelar credito',
      id: id_swal,
      inputs: [
        `<div id=${id_swal}>` +
        '<input id="cancellation_reason" type="text", placeholder="Razón" class="swal2-input" required>' +
        '</div>'
      ]
    };
    this.swalService.customForm(swalParams).then(formValues => {
      const payload = { id_credit: credit.id_credit };
      formValues.value.forEach(element => {
        Object.assign(payload, { 'reason': element });
      });
      this.subscriptions.push(this.creditsService.cancellCredit(payload).subscribe((response: any) => {
        if (response.success) {
          this.swalService.success().then(() => {
            this.broadcast.reloadDataTable();
          });
        } else {
          this.swalService.error({ text: response.message });
        }
      }));
    });
  }

}
