import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var InvestorDashboardService = /** @class */ (function () {
    function InvestorDashboardService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    InvestorDashboardService.prototype.intalationsPerMonth = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/installations_per_month", { params: params });
    };
    InvestorDashboardService.prototype.growthRate = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/ytd_growth_rate", { params: params });
    };
    InvestorDashboardService.prototype.cancellationsRate = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/ytd_cancellations_rate", { params: params });
    };
    InvestorDashboardService.prototype.referrersRate = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/referrers_rate", { params: params });
    };
    InvestorDashboardService.prototype.rollingGrowthRate = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/rolling-growth-rate", { params: params });
    };
    InvestorDashboardService.prototype.annualGrowthRate = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/annual-growth-rate", { params: params });
    };
    InvestorDashboardService.prototype.revenueRate = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/ytd-revenue-rate", { params: params });
    };
    InvestorDashboardService.prototype.annualRevenueRate = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/annual-revenue-rate", { params: params });
    };
    InvestorDashboardService.prototype.annualCancellationsRate = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/annual-cancellations-rate", { params: params });
    };
    InvestorDashboardService.prototype.monthlyReferrersRate = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/monthly-referrers-rate", { params: params });
    };
    InvestorDashboardService.prototype.churnRate = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/churn-rate", { params: params });
    };
    InvestorDashboardService.prototype.monthlyChurnRate = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/monthly-churn-rate", { params: params });
    };
    InvestorDashboardService.prototype.monthlyRecurringRevenue = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/monthly-recurring-revenue", { params: params });
    };
    InvestorDashboardService.prototype.commitedMonthlyRecurringRevenue = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/commited-monthly-recurring-revenue", { params: params });
    };
    InvestorDashboardService.prototype.customerLifetimeValue = function (params) {
        return this.http.get(this.baseUrl + "/investor_dashboard/customer-lifetime-value", { params: params });
    };
    InvestorDashboardService.prototype.getClientsCoordinates = function () {
        return this.http.get(this.baseUrl + "/investor_dashboard/clients-coordinates");
    };
    InvestorDashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function InvestorDashboardService_Factory() { return new InvestorDashboardService(i0.ɵɵinject(i1.HttpClient)); }, token: InvestorDashboardService, providedIn: "root" });
    return InvestorDashboardService;
}());
export { InvestorDashboardService };
