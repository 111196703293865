<div class="modal-body">
    <div class="container">
        <app-form-field type="input" [options]="{label:'Nombre', path: 'name'}"></app-form-field>
        <app-form-field type="input"
            [options]="{label:'Mensualidad promocional', path: 'data.monthsPrice'}"></app-form-field>
        <app-form-field type="select" [options]="{
            label: 'Seleccione los meses que durara la mensualidad promocional',
            width:12,
            droptext: 'Seleccione una opción...',
            elements: months,
            path:'data.numberOfMonths'}">
        </app-form-field>
        <app-form-field type="switch" [options]="{
                    label: '¿Desea añadir meses gratis?', 
                    path: 'data.freeMonths', 
                    trueValue: 1, 
                    falseValue: 0, 
                    trueCaption: 'Si',
                    falseCaption: 'No'}">
        </app-form-field>
        <app-form-field *ngIf="addFreeMonths" type="select" [options]="{
            label: 'Seleccione los meses que se daran gratis',
            width:12,
            droptext: 'Seleccione una opción...',
            elements: months,
            path:'data.numberOfFreeMonths'}">
        </app-form-field>
    </div>
</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="save()">Guardar</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>