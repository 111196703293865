import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { TicketsService } from '../../../../services/tickets.service';
import { ClientsService } from '../../../../services/clients.service';

@Component({
  selector: 'app-record',
  templateUrl: './record.component.html',
  styleUrls: ['./record.component.scss']
})
export class RecordComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  historyConfig = {
    config: {
      type: 'tickets',
      base: this.tickets,
      api: 'getHistory',
      params: { history: 0 },
      order: [[0, 'desc']]
    },
    rows: [
      {
        display: 'Estatus',
        field: 'id'
      }
    ]
  };
  history = [];
  constructor(
    private readonly tickets: TicketsService,
    private readonly clientService: ClientsService,
    public activeModal: NgbActiveModal,
    private readonly _historyService: TicketsService) { }

  ngOnInit(): void {
    this.getClientLastTicket();
    this.history.push({ ticketType: 'created', ...this.data });
    if (this.data.status === 'closed') {
      const nData = JSON.parse(JSON.stringify(this.data));
      this.history.push({ ticketType: 'closed', ...nData, created_at: this.data.updated_at });
    }
    this.subscriptions.push(this._historyService.getHistory(this.data.id_tickets).subscribe((data: any) => {
      data.forEach((element, index) => {
        let ticketType = '';
        switch (index) {
          case 0: ticketType = 'assignations'; break;
          case 1: ticketType = 'completions'; break;
          case 2: ticketType = 'confirmations'; break;
          case 3: ticketType = 'unrealizations'; break;
        }

        element.response.forEach(itm => {
          this.history.push({ ticketType, ...itm });
        });
        this.sortData();
      });
    }));
  }

  sortData(): Array<any> {
    return this.history.sort((a, b) => {
      let nA = a.created_at;
      let nB = b.created_at;
      if (a.ticketType === 'closed') {
        nA = a.updated_at;
      }

      if (b.ticketType === 'closed') {
        nB = b.updated_at;
      }

      return (new Date(nB) as any) - (new Date(nA) as any);
    });
  }

  /**
   * getClientLastTicket
   * Obtiene la información del ultimo ticket del cliente.
   */
  private getClientLastTicket(): void {
    const params = { lastTicket: true, currentIdTicket: this.data.id_tickets };
    this.subscriptions.push(this.clientService.getClientLastTicket(this.data.id_clients, params).subscribe((data: any) => {
      if (data.response && data.response.status == 'closed') {
        this.history.push({ ticketType: 'previous', ...data.response });
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

}
