import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
// Services
import { BadDebtsService } from '../../../services/bad-debts.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { PermissionsService } from '../../../services/permissions.service';
import { SwalService } from '../../../services/swal.service';

@Component({
  selector: 'app-bad-debts',
  templateUrl: './bad-debts.component.html',
  styleUrls: ['./bad-debts.component.scss']
})
export class BadDebtsComponent implements OnDestroy, OnInit, OnDestroy {
  dataTableConfig: any;
  subscriptions: Array<Subscription> = [];
  total_bad_debts = 0;

  private readonly baseDataTableConfig = {
    config: {
      base: this.badDebts,
      api: 'getBadDebtsClients'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Grupo de ventas',
        field: 'group.name',
        type: 'text'
      },
      {
        display: 'Fecha de alta',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'Email',
        field: 'email',
        type: 'text'
      },
      {
        display: 'Deudas',
        field: 'bad_debts',
        type: 'debt-total'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Restaurar al cliente',
            event: 'client.restore',
            conditionality: 'true',
            permissions: ['restore-bad-debts']
          }
        ]
      }
    ]
  };

  constructor(
    public swal: SwalService,
    private readonly badDebts: BadDebtsService,
    private readonly broadcast: BroadcastService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      switch (event.name) {
        case 'client.restore': this.restoreClientItem(event); break;
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
    this.getSummary();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * getSummary
   * Mediante una petición POST se pide a la API un resumen de todas las malas deudas,
   * la respuesta devuelta incluye el total de clientes y el total de dinero en mala deuda.
   */
  getSummary(): void {
    this.subscriptions.push(this.badDebts.getSummary().subscribe(result => {
      if (result.success) {
        this.total_bad_debts = result.response.total_bad_debts;
      }
    }));
  }

  /**
   * restoreClientItem
   * Mueve un cliente y sus deudas de mala deuda a la lista de clientes regulares.
   * @param data información del cliente
   */
  restoreClientItem(data): void {
    this.swal.warning({ title: '¿Desea restaurar al cliente?' }).then(result => {
      if (result.value) {
        this.subscriptions.push(this.badDebts.restoreClient(data.data.id_clients).subscribe((response: any) => {
          if (response.success) {
            this.swal.success();
            this.broadcast.reloadDataTable();
          } else {
            this.swal.error({ title: response.message });
          }
        }));
      }
    });
  }
}
