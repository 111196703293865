import { PipeTransform } from '@angular/core';
var GetFieldPipe = /** @class */ (function () {
    function GetFieldPipe() {
    }
    GetFieldPipe.prototype.transform = function (field) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var nField = field.split('.');
        var data = args[0];
        var type = args[1];
        var isNull = false;
        var text = data;
        nField.forEach(function (item) {
            text = text[item];
            if (text === null) {
                isNull = true;
            }
        });
        if (isNull) {
            switch (type) {
                case 'date':
                    text = null;
                    break;
                case 'number':
                    text = 0;
                    break;
                default:
                    text = 'N/A';
                    break;
            }
        }
        return text;
    };
    return GetFieldPipe;
}());
export { GetFieldPipe };
