import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
interface FormObject { [key: string]: any;}
@Injectable()
export class FromService {
form: FormGroup;
$form = new Subject();

setForm(form: FormGroup): void {
  this.$form.next(form);
  this.form = form;
}

getForm(): FormGroup {
  return this.form;
}

/**
 * getDirtyValues
 * Función que filtra y devuelve unicamente los campos del formulario que se modificaron
 * @param form FormControl con todos los datos
 * @returns object con los datos modificados en el formulario.
 */
getDirtyValues(form: any): FormObject {
  const dirtyValues = {};
  const exceptionValues = ['rfc', 'extra_phones', 'invoice_data'];
  Object.keys(form.controls)
    .forEach(key => {
      const currentControl = form.controls[key];
      if (currentControl.dirty) {
        // tslint:disable-next-line: prefer-conditional-expression
        if (!exceptionValues.indexOf(key)) {
          dirtyValues[key] = currentControl.controls ? this.getDirtyValues(currentControl) : currentControl.value;
        } else {
          dirtyValues[key] = currentControl.value;
        }
      }
    });

  return dirtyValues;
}
}
