import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datatable-column-ticket-contact',
  templateUrl: './ticket-contact.component.html',
  styleUrls: ['./ticket-contact.component.scss']
})
export class TicketContactComponent implements OnInit {
  @Input() data: any;

  ngOnInit(): void {
    //
  }

}
