import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ClientsService } from '../../../services/clients.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FromService } from '../../../providers/form.service';
import { UsersService } from '../../../services/users.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SwalService } from '../../../services/swal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss']
})
export class RestorePasswordComponent implements OnInit, OnDestroy {
  @Input() data: any;
  user: any;
  subscriptions: Array<Subscription> = [];

  form: FormGroup = this.formBuilder.group({
    password: [''],
    password_confirm: ['']
  });

  constructor(
    public activeModal: NgbActiveModal,
    private readonly clientService: ClientsService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly userService: UsersService,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.clientService.show(this.data.id_clients).subscribe((data: any) => {
      this.user = data.response.user;
      const password = this.form.get('password');
      const password_confirm = this.form.get('password_confirm');

      this.subscriptions.push(this.form.valueChanges.subscribe(form => {
        if (form.password !== '' || form.password !== null || form.password_confirm !== '' || form.password_confirm !== null) {
          password.setValidators([Validators.required, Validators.minLength(6)]);
          password_confirm.setValidators([Validators.required, Validators.minLength(6)]);
        }
        password.updateValueAndValidity({ onlySelf: true });
        password_confirm.updateValueAndValidity({ onlySelf: true });
      }));
    }));
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  restorePassword(): void {
    if (this.form.valid && this.form.get('password').value === this.form.get('password_confirm').value) {
      this.subscriptions.push(this.userService.update(this.user.id, this.form.value).subscribe((resp: any) => {
        if (resp.success) {
          this.swal.success({ title: 'Contraseña cambiada exitosamente' }).then(() => {
            this.activeModal.dismiss();
          });
        } else {
          this.swal.error({ title: 'Ocurrio un error al momento de reestablecer la contraseña' });
        }
      }));
    }
  }

}
