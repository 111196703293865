import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { SalesDashboardService } from '../../../../services/sales-dashboard.service';
import { SwalService } from '../../../../services/swal.service';

@Component({
  selector: 'app-sales-agent-chart',
  templateUrl: './sales-agent-chart.component.html',
  styleUrls: ['./sales-agent-chart.component.scss']
})
export class SalesAgentChartComponent implements OnInit {

  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  @Output()
  isLoadAgentChart = new EventEmitter<boolean>();
  isLoad = true;
  barChartColors: Array<Color> = [
    { backgroundColor: '#83b941' },
    { backgroundColor: '#16a4db' },
    { backgroundColor: '#ff843c' },
    { backgroundColor: '#77FF11' },
    { backgroundColor: '#F0F0F0' }
  ];
  months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
  barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1',
          gridLines: {
            display: false
          },
          ticks: {
            beginAtZero: true
          }
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            display: false
          },
          ticks: {
            beginAtZero: true
          }
        }]
    },
    plugins: {
      plugins: {
        datalabels: {
          color: 'black',
          labels: {
            title: {
              font: {
                weight: 'bold'
              }
            },
            value: {
              color: 'black'
            }
          }
        }
      }
    }
  };
  barChartLabels: Array<Label> = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [pluginDataLabels];
  labelsValue: Array<any> = [];

  barChartData: Array<ChartDataSets> = [];
  fieldFrom: FormControl;
  fieldTo: FormControl;
  fieldAgent: FormControl;
  form: FormGroup = this.formBuilder.group({
    from: [],
    to: [],
    agent: ''
  });
  agents = [];

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly swal: SwalService,
    private readonly salesDashboard: SalesDashboardService
  ) { }

  ngOnInit(): void {
    this.fieldFrom = this.form.get('from') as FormControl;
    this.fieldTo = this.form.get('to') as FormControl;
    this.fieldAgent = this.form.get('agent') as FormControl;
    this.setChart();
  }

  search(): void {
    const fromSub = this.form.get('from').value;
    const toSub = this.form.get('to').value;
    const fieldAgent = this.form.get('agent').value;
    const params = {
      from: fromSub,
      to: toSub,
      agent: fieldAgent
    };
    if (params.from && params.to) {
      this.setChart(params);
    } else {
      this.swal.error({ title: 'Favor de introducir las fechas correctamente' });
    }
  }

  setChart(dateRangeParams?): void {
    this.isLoad = true;
    this.labelsValue = [];
    this.salesDashboard.owners().subscribe(res => {
      if (res.success) {
        if (this.agents.length === 0) {
          this.agents.push({ id: '', name: 'Todos' });
          for (const option of res.response) {
            this.agents.push({ id: option.ownerId, name: `${option.firstName} ${option.lastName}` });
          }
        }
        for (const option of res.response) {
          this.labelsValue.push({ label: option.firstName, value: option.ownerId });
        }

        this.salesDashboard.salesByAgent(dateRangeParams).subscribe((resp: any) => {
          // getting labels            
          if (resp.success) {
            const barChartLabelData = [];
            const keys = Object.keys(resp.response.data).sort();
            for (const index of keys) {
              if (index) {
                const yearMonth = index.split('-');
                const year = yearMonth[0];
                const month = this.months[parseInt(yearMonth[1], 10) - 1];
                barChartLabelData.push(`${month} ${year}`);
              }
              // this.barChartLabels = barChartLabelData;
            }
            this.barChartLabels = [...barChartLabelData];
            // group by options
            const barChartDataL = [];
            for (const labelValue of this.labelsValue) {
              const countByData = [];
              for (const index of keys) {
                const arrayByLabel = resp.response.data[index].filter(contact => {
                  if ((contact.hubspot_owner.indexOf(labelValue.value)) > -1 &&
                    contact.lead_promocion === 'lead_ganado') {
                    return contact;
                  }
                });
                countByData.push(arrayByLabel.length);
              }
              const data = { data: countByData, label: labelValue.label };
              barChartDataL.push(data);
            }

            const closeRateDataChart = {
              data: resp.response.close_rate, label: 'Close Rate', type: 'line', datalabels: {
                formatter: (value, context) =>
                  `${value}%`
              },
              yAxisID: 'y-axis-2'
            };
            barChartDataL.push(closeRateDataChart);
            this.barChartData = [...barChartDataL];
            // this.barChartData = barChartDataL;
            this.isLoad = false;
            this.isLoadAgentChart.emit(true);
          } else {
            this.swal.error({ title: 'Error recuperando los datos para la gráfica ventas por mes, intente de nuevo' });
          }
        });

      }
    });
  }

  showInfoGraphic(): void {
    const message = 'How many closed won based on month leads generated filtered by date range and agent. Close rate: closed won/closed lost';
    this.swal.info(message);
  }
}
