import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MoneySpentCampaignService } from '../../../../../services/money-spent-campaign.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FromService } from '../../../../../providers/form.service';
import { SwalService } from '../../../../../services/swal.service';
import { BroadcastService } from '../../../../../services/broadcast.service';
var MoneySpentCampaignFormComponent = /** @class */ (function () {
    function MoneySpentCampaignFormComponent(activeModal, moneySpentCampaignService, broadcast, formBuilder, fromService, swal) {
        this.activeModal = activeModal;
        this.moneySpentCampaignService = moneySpentCampaignService;
        this.broadcast = broadcast;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.swal = swal;
        this.form = this.formBuilder.group({
            campaign_id: ['', Validators.required],
            balance: ['', Validators.required],
            campaign_name: ['', Validators.required],
            init_date: ['', Validators.required],
            end_date: ['', Validators.required]
        });
        this.campaigns = [];
        this.fullCampaigns = [];
        this.isEdit = false;
    }
    MoneySpentCampaignFormComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var resp, error_1;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.moneySpentCampaignService.getCampaigns().subscribe(function (res) {
                            if (res.success) {
                                _this.fullCampaigns = res.response.data;
                                if (_this.campaigns.length === 0) {
                                    for (var _i = 0, _a = res.response.data; _i < _a.length; _i++) {
                                        var option = _a[_i];
                                        _this.campaigns.push({ id: option.id, name: option.id + " - " + option.name });
                                    }
                                }
                                if (_this.data.status === 'create') {
                                    _this.campaigns = _this.campaigns.filter(function (c) {
                                        var isCampaignIdInDatabase = false;
                                        for (var _i = 0, _a = _this.data.campaignData; _i < _a.length; _i++) {
                                            var campaignFromTable = _a[_i];
                                            if (c.id === campaignFromTable.campaign_id) {
                                                isCampaignIdInDatabase = true;
                                                break;
                                            }
                                        }
                                        if (!isCampaignIdInDatabase) {
                                            return true;
                                        }
                                    });
                                }
                            }
                        });
                        this.fromService.setForm(this.form);
                        if (!(this.data.status === 'edit')) return [3 /*break*/, 4];
                        this.isEdit = true;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.moneySpentCampaignService.show(this.data.campaignData.id).toPromise()];
                    case 2:
                        resp = _a.sent();
                        if (resp && resp.success) {
                            this.cashItem = resp.response;
                            Object.keys(this.cashItem).forEach(function (key) {
                                if (_this.cashItem.hasOwnProperty(key) && !!_this.form.controls[key]) {
                                    _this.form.controls[key].setValue(_this.cashItem[key]);
                                }
                            });
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        this.swal.error({ title: 'Ocurió un error, favor de intentar de nuevo', text: '' });
                        return [3 /*break*/, 4];
                    case 4:
                        this.form.get('campaign_id').valueChanges.subscribe(function (value) {
                            for (var _i = 0, _a = _this.fullCampaigns; _i < _a.length; _i++) {
                                var item = _a[_i];
                                if (item.id === value) {
                                    // this.form.get('init_date').setValue('');
                                    var _date = new Date(item.start_time);
                                    var startTime = {
                                        year: _date.getFullYear(),
                                        month: _date.getMonth() + 1,
                                        day: _date.getDate() + 1
                                    };
                                    var _stopDate = new Date(item.stop_time);
                                    var stopTime = {
                                        year: _stopDate.getFullYear(),
                                        month: _stopDate.getMonth() + 1,
                                        day: _stopDate.getDate() + 1
                                    };
                                    _this.form.get('init_date').setValue(startTime);
                                    _this.form.get('end_date').setValue(stopTime);
                                    _this.form.get('campaign_name').setValue(item.name);
                                    if (item.insights && item.insights[0]) {
                                        _this.form.get('balance').setValue(item.insights[0].spend);
                                    }
                                }
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    MoneySpentCampaignFormComponent.prototype.save = function () {
        if (this.data.status === 'edit') {
            this.updateItem();
        }
        if (this.data.status === 'create') {
            this.saveItem();
        }
    };
    MoneySpentCampaignFormComponent.prototype.saveItem = function () {
        var _this = this;
        // this.form.get('category').value;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer guardar el siguiente balance?', text: '' }).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var resp;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.value) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.moneySpentCampaignService.create(this.form.value).toPromise()];
                        case 1:
                            resp = _a.sent();
                            if (resp && resp.success) {
                                this.swal.success().then(function () {
                                    _this.activeModal.dismiss();
                                    _this.broadcast.reloadDataTable();
                                });
                            }
                            else {
                                this.swal.error({ title: 'Ocurió un error al guardar los datos' });
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    MoneySpentCampaignFormComponent.prototype.updateItem = function () {
        var _this = this;
        if (this.form.valid) {
            this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos de este balance?', text: '' }).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                var resp;
                var _this = this;
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.value) return [3 /*break*/, 2];
                            return [4 /*yield*/, this.moneySpentCampaignService.edit(this.data.campaignData.id, this.form.value).toPromise()];
                        case 1:
                            resp = _a.sent();
                            if (resp.success) {
                                this.swal.success().then(function () {
                                    _this.activeModal.dismiss();
                                    _this.broadcast.reloadDataTable();
                                });
                            }
                            else {
                                this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
                            }
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    return MoneySpentCampaignFormComponent;
}());
export { MoneySpentCampaignFormComponent };
