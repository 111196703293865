import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { PromotionalMonthlyFeesService } from '../../../services/promotional-monthly-fees.service';
import { PermissionsService } from '../../../services/permissions.service';
import { ModalComponent } from '../../../components/modal/modal.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BroadcastService } from '../../../services/broadcast.service';
import { SwalService } from '../../../services/swal.service';
import { SharedComponent } from '../../../model/shared-component';
import { CreateEditPromotionalMonthlyFeeComponent } from './create-edit-promotional-monthly-fee/create-edit-promotional-monthly-fee.component';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-promotional-monthly-fees',
  templateUrl: './promotional-monthly-fees.component.html',
  styleUrls: ['./promotional-monthly-fees.component.scss']
})
export class PromotionalMonthlyFeesComponent implements OnInit, OnDestroy {
  notSupportedEvents = environment.notSupportedEvents;
  dataTableConfig: any;
  subscriptions: Array<Subscription> = [];
  private readonly baseDataTableConfig = {
    config: {
      base: this.promotionalMonthlyFeesService,
      api: 'getPromotionalMonthlyFeesTable'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Fecha de alta',
        field: 'created_at',
        type: 'text'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: [
          {
            display: 'Editar',
            icon: 'fa fa-pencil-square-o fa-fw',
            event: 'editPromotionalMonthlyFee',
            conditionality: true,
            permissions: ['update-promotional-monthly-fees']
          },
          {
            display: 'Eliminar',
            icon: 'fa fa-trash fa-fw',
            event: 'deletePromotionalMonthlyFee',
            conditionality: true,
            permissions: ['delete-promotional-monthly-fees']
          }
        ]
      }
    ]
  };

  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly appModal: ModalComponent,
    private readonly swal: SwalService,
    private readonly permissionsService: PermissionsService,
    private readonly promotionalMonthlyFeesService: PromotionalMonthlyFeesService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      if (!this.notSupportedEvents.includes(event.name)) {
        if (this[event.name]) {
          this[event.name](event.data);
        }
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * createPromotionalMonthlyFee
   * Abre un modal que muestra los inputs para crear una promoción mensual
   */
  createPromotionalMonthlyFee(): void {
    const props: SharedComponent = new SharedComponent(
      CreateEditPromotionalMonthlyFeeComponent,
      {
        status: 'create'
      },
      {
        title: 'Crear promocional'
      }
    );
    this.appModal.opensm(props);
  }

  /**
   * editPromotionalMonthlyFee
   * Abre un modal que muestra la información a editar de una promocion mensual
   */
  editPromotionalMonthlyFee(data): void {
    const props: SharedComponent = new SharedComponent(
      CreateEditPromotionalMonthlyFeeComponent,
      {
        editData: data,
        status: 'edit'
      },
      {
        title: `Edición del promocional ${data.name}`
      }
    );
    this.appModal.opensm(props);
  }

  /**
   * deletePromotionalMonthlyFee
   * Abre un modal de confirmación para eliminar la informacion de una promocion mensual
   */
  deletePromotionalMonthlyFee(data): void {
    const swalParams = {
      title: `¿Estás seguro de querer eliminar el promocional ${data.name}?`
    };

    this.swal.warning(swalParams).then(result => {
      if (result.value) {
        this.subscriptions.push(this.promotionalMonthlyFeesService.delete(data.id_promotional_monthly_fee).subscribe((resp: any) => {
          if (resp.success) {
            this.swal.success({ title: 'Promocional eliminado exitosamente' }).then(() => {
              this.activeModal.dismiss();
              this.broadcast.reloadDataTable();
            });
          } else {
            this.swal.error({ title: 'Ocurrio un error al intentar borrar el promocional' });
          }
        }));
      }
    });
  }
}
