import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BroadcastService } from '../../../services/broadcast.service';
import { ChargesService } from '../../../services/charges.service';
import { PermissionsService } from '../../../services/permissions.service';
import { ChargeSummaryComponent } from '../../../components/shared/charge-summary/charge-summary.component';
import { SharedComponent } from '../../../model/shared-component';
import { ModalComponent } from '../../../components/modal/modal.component';
import { HelpersService } from '../../../services/helpers.service';

@Component({
  selector: 'app-recent-charges',
  templateUrl: './recent-charges.component.html',
  styleUrls: ['./recent-charges.component.scss']
})
export class RecentChargesComponent implements OnInit, OnDestroy {
  dataTableConfig: any;
  subscriptions: Array<Subscription> = [];
  notSupportedEvents = environment.notSupportedEvents;

  private readonly baseDataTableConfig = {
    config: {
      base: this.chargesServices,
      api: 'getRecentCharges',
      order: [[5, 'asc']]
    },
    columns: [
      {
        display: 'Cliente',
        field: 'client.name',
        type: 'text'
      },
      {
        display: 'Cargo',
        field: 'description',
        type: 'text'
      },
      {
        display: 'Cantidad',
        field: 'amount',
        type: 'charge_amount'
      },
      {
        display: 'Metodo de pago',
        field: 'paymentType',
        type: 'text'
      },
      {
        display: 'Estado',
        field: 'status',
        type: 'text'
      },
      {
        display: 'Tiempo restante (hrs)',
        field: 'lastChargeStatus.charge_data.remaining_time',
        type: 'text'
      },
      {
        display: 'Fecha de creación',
        field: 'created_at',
        type: 'date',
        format: 'MMMM Do, YYYY, h:mm'
      },
      {
        field: '',
        type: 'actions',
        permissions: ['show-charges'],
        options: [
          {
            icon: 'fa fa-info-circle',
            display: 'Ver información detallada',
            event: 'showChargeInfo',
            permissions: ['show-charges'],
            conditionality: true
          }
        ]
      }
    ]
  };

  constructor(
    private readonly appModal: ModalComponent,
    private readonly broadcast: BroadcastService,
    private readonly chargesServices: ChargesService,
    private readonly permissionsService: PermissionsService,
    private readonly helpersService: HelpersService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      if (!this.notSupportedEvents.includes(event.name)) {
        this[event.name](event.data);
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * showChargeInfo
   * Muestra un alert con la información del pago seleccionado.
   * @param data información del cargo
   */
  showChargeInfo(data): void {
    const formatedCharge = this.helpersService.flatObject(data);
    const props: SharedComponent = new SharedComponent(
      ChargeSummaryComponent,
      formatedCharge,
      { title: `Resumen del cargo de ${data.client.name}` }
    );
    this.appModal.opensm(props);
  }
}
