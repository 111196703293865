import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { forkJoin, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  baseUrl = `${environment.apiUrl}`;
  constructor(private readonly http: HttpClient) { }

  create(params?): any {
    return this.http.post(`${this.baseUrl}/tickets`, params);
  }

  update(id_tickets, params): any {
    return this.http.put(`${this.baseUrl}/ticket/${id_tickets}`, params);
  }

  getTickets(params?, filters?): Observable<any> {
    if (filters) {
      Object.assign(params, filters);
    }

    return this.http.post(`${this.baseUrl}/tickets/get_tickets`, params);
  }

  getTechnicians(params?, filters?): any {
    if (filters) {
      Object.assign(params, filters);
    }

    return this.http.post(`${this.baseUrl}/users/Technician`, params);
  }

  getErrorCodes(params?, filters?): any {
    if (filters) {
      Object.assign(params, filters);
    }
    
    return this.http.get(`${this.baseUrl}/error-codes/get_error_codes`, params);
  }

  getHistory(id_tickets): any {
    const params = {
      params: { id_tickets }
    };

    const assignations = this.http.get(`${this.baseUrl}/assignations`, params);
    const completions = this.http.get(`${this.baseUrl}/completions`, params);
    const confirmations = this.http.get(`${this.baseUrl}/confirmations`, params);
    const unrealizations = this.http.get(`${this.baseUrl}/unrealizations`, params)

    return forkJoin([assignations, completions, confirmations, unrealizations]);
  }

  getTicketTypes(): any {
    return this.http.get(`${this.baseUrl}/tickets/get_ticket_type`);
  }

  getMapTickets(params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/tickets/get_map_tickets`, params);
  }

  setStatus(params, id): any {
    return this.http.post(`${this.baseUrl}/tickets/${id}/setStatus`, params);
  }

  getPhotos(ticketId: number): any {
    return this.http.get(`${this.baseUrl}/tickets/${ticketId}/photos`);
  }

  addPhotos(params, ticketId: Number): Observable<any> {
    return this.http.post(`${this.baseUrl}/tickets/${ticketId}/addPhotos`, params);
  }

  simTicketsNextMonth(params?): Observable<any> {
    return this.http.post(`${this.baseUrl}/tickets/simTicketsNextMonth`, params);
  }

  getInstallationsImages(id_clients: number): Observable<any> {
    return this.http.get(`${this.baseUrl}/tickets/${id_clients}/getInstallationsImages`);
  }
}
