<div class="col-md-12 content">
    <history-ticket-badge [item]="item"></history-ticket-badge>
    <dl class="row">
        <dt class="col-md-4">Número de serie</dt>
        <dd class="col-md-8">{{item.serial_number}}</dd>
        <dt class="col-md-4">Persona que recibió al técnico</dt>
        <dd class="col-md-8">{{ item.recipient }}</dd>
        <dt class="col-md-4">Acciones realizadas</dt>
        <dd class="col-md-8">{{ item.work_description }}</dd>
        <dt class="col-md-4">Cargos extras</dt>
        <dd class="col-md-8">{{ item.extra_charges | currency}}</dd>
        <dt class="col-md-4">Motivos</dt>
        <dd class="col-md-8">{{ item.extra_charges_reasons }}</dd>
        <dt class="col-md-4">Código de error</dt>
        <dd class="col-md-8">{{item.error_code.category}} - {{item.error_code.name}} - {{item.error_code.code}}</dd>
        <dt class="col-md-4">Partes usadas</dt>
        <dd class="col-md-8">
            <ul *ngIf="parts.length">
                <li *ngFor="let part of parts">
                    {{part}}
                </li>
            </ul>
        </dd>
        <dt class="col-md-4">TDS entrada</dt>
        <dd class="col-md-8">{{item.tds_in}}</dd>
        <dt class="col-md-4">TDS salida</dt>
        <dd class="col-md-8">{{item.tds_out}}</dd>
    </dl>
    <dl>
        <dt>Ubicación del técnico al completar el formulario <a href="#" (click)="openMap($event)">Mostrar mapa...</a></dt>
        <dt *ngIf="images && images.length">Fotografías del técnico <a href="#" (click)="showImagesBox($event)">Ver fotos...</a></dt>
    </dl>
</div>