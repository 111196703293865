import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-datatable-column',
  templateUrl: './datatable-column.component.html',
  styleUrls: ['./datatable-column.component.scss']
})

export class DatatableColumnComponent implements OnInit {
  @Input() column: any;
  @Input() data: any;
  @Input() trRef: any;
  htmlContent: string;

  ngOnInit(): void { 
    // tslint:disable-next-line: no-string-literal
    if (this.column['type'] === 'html') {
      this.htmlContent = this.data[this.column.field];
    }
    //
  }
}
