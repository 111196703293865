import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../../providers/form.service';
import { SwalService } from '../../../../services/swal.service';
import { CategoriesService } from '../../../../services/categories.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-edit-category',
  templateUrl: './create-edit-category.component.html',
  styleUrls: ['./create-edit-category.component.scss']
})
export class CreateEditCategoryComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  category: any;
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required]
  });
  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly categoryService: CategoriesService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    if (this.data.status === 'edit') {
      this.subscriptions.push(this.categoryService.show(this.data.categoryData.id_categories).subscribe((resp: any) => {
        this.category = resp.response;
        Object.keys(this.category).forEach(key => {
          if (this.category.hasOwnProperty(key) && !!this.form.controls[key]) {
            this.form.controls[key].setValue(this.category[key]);
          }
        });
      }));
    }
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  save(): void {
    if (this.data.status === 'edit') {
      this.updateCategory();
    }

    if (this.data.status === 'create') {
      this.saveCategory();
    }
  }

  saveCategory(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar los datos de la categoria?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.categoryService.create(this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

  updateCategory(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos de la categoria?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.categoryService.update(this.data.categoryData.id_categories, this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
            }
          }));
        }
      });
    }
  }
}
