import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class UsersService {

  baseUrl = `${environment.apiUrl}`;

  constructor(
    private readonly http: HttpClient
  ) {
  }

  user(val): any {
    return this.http.get(`${this.baseUrl}/users/${val}`);
  }

  getAdmins(payload): any {
    return this.http.post(`${this.baseUrl}/users/Administrator`, payload);
  }

  getTechnitians(payload): any {
    return this.http.post(`${this.baseUrl}/users/Technician`, payload);
  }

  getUserByRole(params, filter): any {
    const role = filter.role.role;

    return this.http.post(`${this.baseUrl}/users/${role}`, params);
  }

  getImageProfile(id): any {
    return this.http.get(`${this.baseUrl}/users/${id}/getImageProfile`);
  }

  create(params): any {
    return this.http.post(`${this.baseUrl}/auth/register`, params);
  }

  createUser(params): any {
    return this.http.post(`${this.baseUrl}/users`, params);
  }

  show(id): any {
    return this.http.get(`${this.baseUrl}/users/${id}`);
  }

  update(id, params): any {
    return this.http.put(`${this.baseUrl}/users/${id}`, params);
  }

  getUser(params?, filters?): any {

    if (filters) {
      Object.assign(params, filters);
    }

    return this.http.post(`${this.baseUrl}/users/getUsers`, params);
  }

  /**
   * getLogedUserRoles
   * Verifica si el usuario logueado tiene los roles solicitados.
   */
  getLogedUserRoles(roles: Array<any>, rolesToCheck: Array<any>): any {

    const filteredRoles = roles.filter((rol: any) => {
      if (rolesToCheck.includes(rol.name)) {
        return true;
      }
    });
    
    return filteredRoles;
  }
}
