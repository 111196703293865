import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedComponent } from '../../model/shared-component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() props: any;
  @Input() config: any;

  loading = false;
  progress$: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly modalService: NgbModal,
    public loadbarService: LoadingBarService) { }

  ngOnInit(): void {
    this.progress$ = this.loadbarService.progress$.subscribe(data => {
      if (data > 0) {
        this.loading = true;
      }
      if (data === 100) {
        this.loading = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.progress$.unsubscribe();
  }

  open(props: SharedComponent): void {
    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.props = props;
    modalRef.componentInstance.config = props.config;
  }

  openXl(props: SharedComponent): void {
     const modalRef = this.modalService.open(ModalComponent, {size: 'xl'});
     modalRef.componentInstance.props = props;
     modalRef.componentInstance.config = props.config;
  }

  opensm(props: SharedComponent): void {
    const modalRef = this.modalService.open(ModalComponent, {size: 'sm'});
    modalRef.componentInstance.props = props;
    modalRef.componentInstance.config = props.config;
 }
}
