import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-commission-summary',
  templateUrl: './commission-summary.component.html',
  styleUrls: ['./commission-summary.component.scss']
})
export class CommissionSummaryComponent implements OnInit {
@Input() data: any;
  
ngOnInit(): void {
  //
}

}
