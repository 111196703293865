/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-expire.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./card-expire.component";
var styles_CardExpireComponent = [i0.styles];
var RenderType_CardExpireComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardExpireComponent, data: {} });
export { RenderType_CardExpireComponent as RenderType_CardExpireComponent };
function View_CardExpireComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "badge badge-default"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Sin tarjeta"]))], null, null); }
function View_CardExpireComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "badge badge-success"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " Vigente(s)"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.valid; _ck(_v, 2, 0, currVal_0); }); }
function View_CardExpireComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "badge badge-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " Por expirar"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.forexpire; _ck(_v, 2, 0, currVal_0); }); }
function View_CardExpireComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "badge badge-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " Expirada(s)"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.expired; _ck(_v, 2, 0, currVal_0); }); }
export function View_CardExpireComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardExpireComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardExpireComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardExpireComponent_3)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardExpireComponent_4)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.no_cards; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.valid; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.forexpire; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.expired; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_CardExpireComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-expire", [], null, null, null, View_CardExpireComponent_0, RenderType_CardExpireComponent)), i1.ɵdid(1, 114688, null, 0, i3.CardExpireComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardExpireComponentNgFactory = i1.ɵccf("app-card-expire", i3.CardExpireComponent, View_CardExpireComponent_Host_0, { data: "data" }, {}, []);
export { CardExpireComponentNgFactory as CardExpireComponentNgFactory };
