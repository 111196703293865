/**
 * Interceptor to handle requests when token expired
 */
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SwalService } from '../services/swal.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/swal.service";
var RefreshTokenInterceptorService = /** @class */ (function () {
    function RefreshTokenInterceptorService(router, injector, swalService) {
        this.router = router;
        this.injector = injector;
        this.swalService = swalService;
    }
    RefreshTokenInterceptorService.prototype.intercept = function (request, next) {
        var _this = this;
        var token_errors = ['token_expired', 'token_invalid', 'token_error', 400, 401];
        return next.handle(request)
            .pipe(catchError(function (errorResponse) {
            // Invalid token
            if (token_errors.includes(errorResponse.status) && token_errors.includes(errorResponse.error.error)) {
                if (errorResponse.url.includes('auth/refresh')) {
                    // Expired token and can't be refreshed
                    localStorage.removeItem('token');
                    localStorage.removeItem('access_token');
                    localStorage.removeItem('user');
                    _this.swalService.error({ text: 'Sesión expirada' });
                    _this.router.navigate(['login']);
                    return next.handle(request);
                }
                // Refresh token
                var accessToken = request.headers.get('authorization').split(' ')[1];
                var http = _this.injector.get(HttpClient);
                return http.post(environment.apiUrl + "/auth/refresh", {}, {
                    headers: {
                        Authorization: "Bearer " + accessToken
                    }
                })
                    .pipe(mergeMap(function (data) {
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('access_token', data.token);
                    var cloneRequest = request.clone({ setHeaders: { Authorization: "Bearer " + data.token } });
                    return next.handle(cloneRequest);
                }));
            }
            // Other http errors
            return throwError(errorResponse);
        }));
    };
    RefreshTokenInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RefreshTokenInterceptorService_Factory() { return new RefreshTokenInterceptorService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i2.SwalService)); }, token: RefreshTokenInterceptorService, providedIn: "root" });
    return RefreshTokenInterceptorService;
}());
export { RefreshTokenInterceptorService };
