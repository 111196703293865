import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from '../../../../components/form/validators/custom.validator';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../../providers/form.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { SwalService } from '../../../../services/swal.service';
import { TicketsService } from '../../../../services/tickets.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-assignation-create-edit',
  templateUrl: './assignation-create-edit.component.html',
  styleUrls: ['./assignation-create-edit.component.scss']
})
export class AssignationCreateEditComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  assignations = [];
  assignation: any;
  min_date: any;
  error_code_description: string;
  view_description: string;
  invalid_range_time = false;
  form: FormGroup = this.formBuilder.group({
    details: ['', Validators.required],
    schedule_date: ['', Validators.required],
    start_time: ['', [Validators.required, CustomValidator.checkRangeHour]],
    end_time: ['', [Validators.required, CustomValidator.checkRangeHour]]
  });

  constructor(
    public activeModal: NgbActiveModal,
    public modal: NgbModal,
    private readonly swal: SwalService,
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly ticketsService: TicketsService) { }

  ngOnInit(): void {
    this.error_code_description = `${this.data.ticket.error_code.code} - ${this.data.ticket.error_code.name}`;
    this.defineViewStatus();
    this.getMinDate();
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * defineViewStatus
   * Determina si la vista que se va a cargar es para la creación de una asignación o para la edición
   * de una asignación ya creada.
   */
  defineViewStatus(): void {
    switch (this.data.status) {
      case 'edit':
        this.getLastAssignation();
        this.getTicketInformation();
        this.view_description = 'Actualizar ticket';
        break;
      case 'assign':
        this.view_description = 'Asignar ticket';
        break;
      case 'reassing':
        this.view_description = 'Reassignar ticket';
        break;
    }
  }

  /**
   * getTicketInformation
   * Obtiene la información del ticket para rellenar los campos que sean editables
   * en el caso de que la vista cargada sea la de edición
   */
  getTicketInformation(): void {
    const format = { local: 'en-CA', options: { hour12: false } };
    Object.keys(this.assignation).forEach(key => {
      if (this.form.controls[key]) {
        this.form.controls[key].setValue(this.assignation[key]);
      }

      if (key === 'schedule_date_start' || key === 'schedule_date_end') {
        const date = new Date(this.assignation[key]);
        switch (key) {
          case 'schedule_date_start':
            const schedule_date = date.toLocaleDateString(format.local);
            const start_time = date.toLocaleTimeString(format.local, format.options);
            this.form.controls.schedule_date.setValue(schedule_date);
            this.form.controls.start_time.setValue(start_time);
            break;
          case 'schedule_date_end':
            const end_time = date.toLocaleTimeString(format.local, format.options);
            this.form.controls.end_time.setValue(end_time);
            break;
        }
      }
    });
  }

  /**
   * getLastAssignation
   * Obtiene la información de la ultima asignación realizada al ticket seleccionado.
   */
  getLastAssignation(): void {
    this.assignations = this.data.ticket.assignations;
    this.assignation = this.assignations[0];

    if (this.assignations.length > 1) {
      this.assignation = this.assignations.slice(-1)[0];
    }
  }

  /**
   * save
   * Crea o actualiza una asignacion dependiendo de la vista que se haya cargado.
   */
  save(): void {
    switch (this.data.status) {
      case 'edit':
        this.updateAssignation();
        break;
      default:
        this.createAssignation();
        break;
    }
  }

  /**
   * createAssignation
   * Manda una petición post a la API para crear una nueva asignación para un ticket
   * marcado como abierto.
   */
  createAssignation(): void {

    const params = {
      id_technicians: this.data.selected_technician.id,
      details: this.form.get('details').value,
      schedule_date: this.form.get('schedule_date').value,
      start_time: this.form.get('start_time').value,
      end_time: this.form.get('end_time').value,
      status: 'assigned'
    };

    this.subscriptions.push(this.ticketsService.setStatus(params, this.data.ticket.id_tickets).subscribe((subresp: any) => {
      if (subresp.success) {
        this.swal.success().then(() => {
          this.activeModal.dismiss();
          if ('isMap' in this.data.ticket) {
            this.broadcast.fire({
              name: 'reload-map',
              data: {}
            });
            this.modal.dismissAll();
          } else {
            this.broadcast.reloadDataTable();
          }
        });
      } else {
        this.swal.error().catch();
      }
    }));
  }

  /**
   * updateAssignation
   * Manda una petición put a la API para actualizar los parametros de una assignación.
   */
  updateAssignation(): void {
    if (this.form.valid) {
      const params = this.form.value;
      const swal_params = {
        title: '¿Esta seguro de querer actualizar los detalles del ticket?'
      };

      this.swal.warning(swal_params).then(result => {
        if (result.value) {
          this.subscriptions.push(this.ticketsService.update(this.data.ticket.id_tickets, params).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error();
            }
          }));
        }
      });

    }
  }

  /**
   * getMinDate
   * Obtiene los valores de fecha minimos para filtrar el input del calendario 
   * y evitar seleccionar fechas anteriores a la fecha actual. 
   */
  getMinDate(): void {
    const current = new Date();
    this.min_date = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }

  /**
   * checkTime
   * Revisa si el valor de la hora de inicio es menor a la hora del finalización.
   */
  checkTime(): void {
    this.subscriptions.push(this.form.valueChanges.subscribe(value => {
      if ((value.start_time !== '') && (value.end_time !== '')) {
        let selected_date = '2021-01-01';

        if (value.schedule_date !== '') {
          selected_date = value.schedule_date;
        }

        const start_time = Date.parse(`${selected_date} ${value.start_time}`);
        const end_time = Date.parse(`${selected_date} ${value.end_time}`);
        this.invalid_range_time = false;

        if (end_time < start_time) {
          this.invalid_range_time = true;
        }
      }

    }));
  }

}
