import { Component } from '@angular/core';

@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.scss']
})

export class SalesDashboardComponent {

  isMonthLoaded = false;
  isCampaignLoaded = false;
  isPromoLoaded = false;
  isAgentLoaded = false;

  setMonthChartLoad(isMonthChartLoad): void {
    this.isMonthLoaded = isMonthChartLoad;
  }

  setCampaignChartLoad(value): void {
    this.isCampaignLoaded = value;
  }

  setPromoChartLoad(value): void {
    this.isPromoLoaded = value;
  }

  setAgentChartLoad(value): void {
    this.isAgentLoaded = value;
  }
}
