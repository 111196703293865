const ChartColors = {
  primary1: [
    {
      backgroundColor: '#16a4db88',
      borderColor: '#16a4db'
    }
  ],
  primary2: [
    {
      backgroundColor: '#83b94188',
      borderColor: '#83b941'
    }
  ],
  primary3: [
    {
      backgroundColor: '#10416a88',
      borderColor: '#10416a'
    }
  ],
  secondary1: [
    {
      backgroundColor: '#77FF1188',
      borderColor: '#77FF11'
    }
  ],
  secondary2: [
    {
      backgroundColor: '#ff113188',
      borderColor: '#ff1131'
    }
  ],
  secondary3: [
    {
      backgroundColor: '#00ffff88',
      borderColor: '#00ffff'
    }
  ]
};

// tslint:disable-next-line: no-default-export
export default ChartColors;
