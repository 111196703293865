import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { BroadcastService } from '../../../services/broadcast.service';
import { ClientsService } from '../../../services/clients.service';
import { SwalService } from '../../../services/swal.service';
import { ManualPaymentComponent } from '../../../components/shared/manual-payment/manual-payment.component';
import { ChargesService } from '../../../services/charges.service';
import { CardsService } from '../../../services/cards.service';
@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit, OnDestroy {
  @Input() data: any;
  subscriptions: Array<Subscription> = [];
  baseUrl = `${environment.apiUrl}`;
  hidden = false;
  hiddeChargeTab = false;
  refeer: any;
  coupon = {};
  documents = [];
  photos = [];
  client: any;
  regular_monthly_fee: Number;
  current_active_referrence: any;
  signed_in_group = {
    name: 'N/A',
    deposit: 0,
    monthly_fee: 0,
    installation_fee: 0,
    trial_days_price: 0,
    trial_days: 0
  };

  contractData = {
    id_clients: 0,
    deposit: 0,
    monthly_fee: 0,
    first_charge: 0,
    extras: 0,
    sr: 0,
    bank_fee: 0,
    installation_fee: 0,
    coupon: 0,
    total: 0
  };

  dataTableConfig = {
    config: {
      base: this.cardsService,
      api: 'getCards',
      params: {
        id_clients: 0
      }
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'default_card'
      },
      {
        display: 'Exp',
        field: 'exp_date',
        type: 'text'
      },
      {
        display: 'Tarjeta',
        field: 'last4',
        type: 'text'
      },
      {
        display: 'Marca',
        field: 'brand',
        type: 'text'
      },
      {
        display: 'Fecha de adición',
        field: 'registered_at.date',
        type: 'date'
      }
    ],
    filters: [{}]
  };

  constructor(
    private readonly clientService: ClientsService,
    private readonly cardsService: CardsService,
    private readonly chargesService: ChargesService,
    private readonly swal: SwalService,
    private readonly broadcast: BroadcastService,
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent
  ) {
  }

  ngOnInit(): void {
    if (this.data.hasOwnProperty('loggedUserRoles')) {
      this.hiddeChargeTab = true;
    }

    this.setClientData();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * setClientData
   * Inicializa todos los valores que se han de mostrar en la vista del contrato.
   */
  setClientData(): void {
    this.subscriptions.push(this.clientService.show(this.data.id_clients).subscribe((resp: any) => {
      this.client = resp.response;
      // tslint:disable-next-line: max-line-length
      this.regular_monthly_fee = this.clientService.calculateMonthlyFee(this.client);
      this.setSignedInGroupInfo(this.client);
      this.contractData.id_clients = this.client.id_clients;
      this.contractData.deposit = this.client.deposit / 100;
      this.contractData.monthly_fee = this.client.monthly_fee / 100;
      this.contractData.installation_fee = this.client.installation_fee / 100;
      this.contractData.extras = this.extras();
      this.contractData.sr = Math.round(this.socialResponsability() * 100) / 100;
      this.contractData.first_charge = this.firstCharge();
      // this.contractData.first_charge = this.firstCharge() + this.contractData.sr;
      this.contractData.bank_fee = this.client.charge_fee / 100;
      this.contractData.coupon = this.discountCoupon();
      this.contractData.total = this.total();
      this.dataTableConfig.config.params.id_clients = this.client.id_clients;
      this.getRefeer();

      this.subscriptions.push(this.clientService.getImages(this.client.id_clients).subscribe((data: any) => {
        this.documents = data.response.documents;
        this.photos = data.response.photos;
      }));
    }));
  }

  /**
   * extras
   * Calcula el total de los extras asociados al contrato del cliente.
   * @returns totalPrice
   */
  extras(): number {
    let totalPrice = 0;
    for (let i = 0; i < this.client.contract.extras.length; i++) {
      const extra = this.client.contract.extras[i];
      let materialPrice = Number(extra.price);

      if (extra.element_name === 'Acero inoxidable') {
        materialPrice = this.contractData.installation_fee;
      }

      totalPrice += materialPrice;
    }

    return totalPrice;
  }

  /**
   * firstCharge
   * Calcula la primera mesualidad que se le hara al cliente en base a la promocion del grupo original bajo el cual se unio.
   * @returns first_charge
   */
  firstCharge(): number {
    let first_charge = this.contractData.monthly_fee;
    const signed_in_group = this.client.signed_in_group;

    if ((signed_in_group !== null && signed_in_group.trial_days >= 0)) {
      const trial_days_price = parseInt(signed_in_group.trial_days_price, 10) / 100;

      if (signed_in_group.trial_days !== 0 || signed_in_group.trial_days_price > 0) {
        first_charge = trial_days_price;
      }
    }

    return first_charge;
  }

  /**
   * socialResponsability
   * Calcula la responsabilidad social de:
   * - Mensualidad.
   * - Instalacion
   * - Extras
   * - Material @deprecated
   * @returns social_responsability
   */

  socialResponsability(): number {
    const signed_in_group = this.client.signed_in_group;
    const material = this.checkIfMaterial();
    let client_responsability = 0;
    let material_responsability = 0;
    let social_responsability = 0;
    let installation_responsability = 0;

    if (this.client.social_responsability === 1) {
      client_responsability = (this.contractData.monthly_fee / 1.16) * 0.007;

      if ((signed_in_group !== null && signed_in_group.trial_days >= 0)) {
        const trial_days_price = parseInt(this.client.signed_in_group.trial_days_price, 10) / 100;

        if (signed_in_group.trial_days !== 0 || signed_in_group.trial_days_price > 0) {
          client_responsability = (trial_days_price / 1.16) * 0.007;
        }
      }

      const extras = this.client.contract.extras;
      extras.forEach(extra => {
        let materialPrice = Number(extra.price);

        if (extra.element_name === 'Acero inoxidable') {
          materialPrice = this.contractData.installation_fee;
        }

        material_responsability += ((materialPrice / 1.16) * 0.007);
      });

      if (!material) {
        installation_responsability = (this.contractData.installation_fee / 1.16) * 0.007;
      }

    }
    social_responsability = client_responsability + material_responsability + installation_responsability;

    return social_responsability;
  }

  /**
   * discoundCoupon
   * No se utilizan los cupones de momento.
   * Si el cliente utilizo un cupon de descuento, obtiene la cantidad de descuento dada por dicho cupon.
   * @returns discount
   */

  discountCoupon(): number {
    let discount = 0;
    if (this.client.id_coupon > 0) {
      discount = parseInt(this.client.coupon.reward, 10);
    }

    return discount;
  }

  /**
   * getRefeer
   * obtiene la informacion del cliente existente que refirio al cliente seleccionado
   */
  getRefeer(): any {
    this.subscriptions.push(this.clientService.show(this.client.referred_by).subscribe((data: any) => {
      this.refeer = data.response;
    }));
  }

  /**
   * setSignedInGroupInfo
   * Obtiene y setea los valores del grupo/plan original bajo el cual se unio el cliente
   * @param client info del cliente
   */
  setSignedInGroupInfo(client: any): any {
    if (client.signed_in_group) {
      this.signed_in_group.name = client.signed_in_group.name;
      this.signed_in_group.deposit = (client.signed_in_group.deposit) / 100;
      this.signed_in_group.monthly_fee = (client.signed_in_group.monthly_fee) / 100;
      this.signed_in_group.installation_fee = (client.signed_in_group.installation_fee) / 100;
      this.signed_in_group.trial_days = client.signed_in_group.trial_days;
      this.signed_in_group.trial_days_price = (client.signed_in_group.trial_days_price) / 100;
    }
  }

  /**
   * total
   * Devuelve el total que se le cobrara al usuario por el primer cargo.
   * @returns total (valor demostrativo no se envia a la API)
   */
  total(): number {
    const material = this.checkIfMaterial();
    let total = (
      this.contractData.deposit +
      this.contractData.first_charge +
      this.contractData.sr +
      this.contractData.extras
    ) - this.contractData.coupon;

    if (!material) {
      total += this.contractData.installation_fee;
    }

    return total;
  }

  /**
   * firstPayment
   * Manda una petición POST a la API para generar el primer pago cont tarjeta
   * Si el monto a cobrar es 0 mostrara una advertencia al usuario que el registro del pago unicamente se creara en la BD
   * en caso contrario se intentara hacer el primer cobro atraves de los vendors activos que tenga el sistema, este primer
   * intento se podra hacer con un maximo de 2 veces seguidas antes de que la API bloquee el intento de cargo por 48 hrs.
   */
  firstPayment(): void {
    if (this.contractData.total === 0) {
      this.swal.warning({
        title: '¿Estás seguro de continuar?, El monto a total a cobrar es 0',
        text: 'Unicamente se creara el registro en el sistema y se le asignara la siguiente fecha de pago al cliente en base a su grupo'
      }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.chargesService.createFirstCharge(this.client.id_clients).subscribe((data: any) => {
            if (data.success) {
              this.swal.success().then(() => {
                this.hideButton();
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurrio un error al hacer el registro' });
            }
          }));
        }
      });
    } else {
      this.swal.warning({
        title: 'Se intentará recolectar el primer pago del cliente',
        text: '¿Estás seguro de continuar?'
      }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.chargesService.createFirstCharge(this.client.id_clients).subscribe((data: any) => {
            if (data.success) {
              this.swal.success().then(() => {
                this.hideButton();
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurrio un error al procesar el pago' });
            }
          }));
        }
      });
    }
  }

  /**
   * manualPayment
   * Manda una peticion post a la API para generar una referencia OXXO/SPEI
   * La API hace una validación para verificar si existe alguna referencia activa, 
   * si existe regresa un mensaje de que se deben de esperar 48 hrs para poder generar otra referencia.
   */
  manualPayment(): void {
    const props: SharedComponent = new SharedComponent(
      ManualPaymentComponent,
      {
        client: this.data,
        contract: this.contractData,
        firstCharge: true
      },
      {
        title: 'Generación del primer pago'
      }
    );
    this.appModal.open(props);
  }

  /**
   * hideButton
   * Regresa un booleano para determinar si en la vista se ocultan los bototones para realizar el primer cargo 
   * ya sea por referencia o tarjeta.
   * El botones unicamente se deberian de mostrar si las coordenadas del cliente no son nulas, 
   * si el cliente no tiene nextpayday o si el cliente no esta rechazado
   * @returns boolean
   */
  hideButton(): boolean {
    if (this.client.coordinate === null || this.client.coordinate === 'null'
      || this.client.coordinate === '' || this.client.next_payday !== null || this.client.status === 'rejected') {

      this.hidden = true;

      return this.hidden;
    }

    return this.hidden;
  }

  /**
   * checkIfMaterial
   * Metodo unicamente creado para mantener compatibilidad con el antiguio sistema de depender del material para calcular la instalación
   * Revisa si el contrato del cliente tiene asociada la categoria material si la tiene asociado la categoria material
   * @returns boolean
   */

  checkIfMaterial(): boolean {
    const material = this.data.contract.extras.find(mat => mat.category_name === 'Material');
    if (material) {
      return true;
    }

    return false;
  }
}
