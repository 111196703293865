import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { HelpersService } from './helpers.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./helpers.service";
var ChargesService = /** @class */ (function () {
    function ChargesService(http, helpersService) {
        this.http = http;
        this.helpersService = helpersService;
        this.baseUrl = "" + environment.apiUrl;
    }
    ChargesService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/charges/" + id);
    };
    ChargesService.prototype.refund = function (id, params) {
        return this.http.post(this.baseUrl + "/charges/" + id + "/refund", params);
    };
    ChargesService.prototype.getClientCharges = function (params, filters) {
        var id = 0;
        if (filters) {
            id = filters.id_clients;
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/client/" + id + "/getClientChargesDataTable", params);
    };
    ChargesService.prototype.getChargeRefunds = function (params, filters) {
        var id = 0;
        if (filters) {
            id = filters.id_clients;
        }
        return this.http.post(this.baseUrl + "/charge/" + id + "/refunds", params);
    };
    /**
     * createManualCharge
     * @param params
     * id_clients: id_del_cliente,
     * payment_types: id_del cargo(OXXO|SPEI|CARD),
     * months_ahead(optional): numero de meses que se desean pagar por adelantado.
     * @returns 200 | Ok
     */
    ChargesService.prototype.createManualCharge = function (params) {
        return this.http.post(this.baseUrl + "/charges/createManualCharge", params);
    };
    /**
     * createFirstCharge
     * @param params
     * id_clients: id_del_cliente,
     * payment_types: id_del cargo(OXXO|SPEI|CARD)
     * @returns 200 | Ok
     */
    ChargesService.prototype.createFirstCharge = function (params) {
        return this.http.post(this.baseUrl + "/charges/createFirstCharge", params);
    };
    /**
     * cancelLastCharge
     * @param params id_clients: for card, id_charges: for references, type: type of charge to unlock
     * @returns 200 | 404
     */
    ChargesService.prototype.cancelLastCharge = function (params) {
        return this.http.put(this.baseUrl + "/charges/cancelLastCharge", params);
    };
    ChargesService.prototype.getRecentCharges = function (params) {
        return this.http.post(this.baseUrl + "/charges/getRecentCharges", params);
    };
    /**
     * freeMonthlyFeee
     * Manda una petición POST para dar una mensualidad gratis al cliente.
     * @param params reason: string, id_clients: number
     * @returns
     */
    ChargesService.prototype.freeMonthlyFee = function (params) {
        return this.http.post(this.baseUrl + "/charges/freeMonthlyFee", params);
    };
    /**
     * formatChargeData
     * Formatea un objecto devuelto por la API cuando se usan los endpoints para saber la información
     * del ultimo cargo con tarjeta o referencia activa para que pueda ser utilizado por la función
     * showChargeInfo
     * @param charge_object Objecto devuelto por la API al momento de verificar si el cliente tiene una
     * referencia o cargo con tarjeta
     * @returns formated_charge
     */
    ChargesService.prototype.formatChargeData = function (charge_object) {
        var charge_data = charge_object.response.charge_data;
        var charge_extra_data = charge_object.response.charge_data.charge_extra_data;
        var formated_charge = {
            id_charges: charge_data.charge_data.id_charges,
            id_payment_type: charge_data.charge_data.id_payment_type,
            description: charge_data.charge_data.description,
            amount: charge_data.charge_data.amount,
            remaining_time: charge_data.remaining_time,
            time_name: charge_data.time_name,
            created_at: charge_data.charge_data.created_at
        };
        if (formated_charge.id_payment_type === 4 || formated_charge.id_payment_type === 5) {
            var extra_data = {
                extra_data: {
                    reference: charge_extra_data.reference,
                    type: charge_extra_data.type
                }
            };
            Object.assign(formated_charge, extra_data);
        }
        return formated_charge;
    };
    /**
     * CalculateMonthsAheadAmount
     * Calcula el total de las mensualidades que se quieren pagar por adelantado.
     * @param monthlyFee number mensualidad del cliente
     * @param months number meses que se pagaran por adelantado
     * @returns number total del numero de meses por adelantado
     */
    ChargesService.prototype.calculateMonthsAheadAmount = function (monthlyFee, months) {
        return monthlyFee * months;
    };
    /**
     * formatMessage
     * Genera un objeto con la info que mostrara el modal cuando se reciba la confirmación de la creación de una referencia en la API
     * esta info se mostrara mientras la referencia no expire.
     * @param resp respuesta devuelta por la API con la info de la referencia creada
     * @param paymentType tipo de referencia que se generara OXXO/SPEI
     * @returns objeto que el titulo y el mensaje que llevara el modal
     */
    ChargesService.prototype.formatMessage = function (resp, paymentType) {
        var offlineMsg = '<div>';
        var titleOffline = "Referencia " + paymentType + " generada exitosamente";
        var expirationDateText = 'Fecha de expiración:';
        var chargeData = resp.response;
        var amount = Math.round(chargeData.amount) / 100;
        var expirationDate = moment(chargeData.expires_at * 1000).format('MMMM Do YYYY, h:mm:ss a');
        var copyText = "\n    Nombre: " + chargeData.client_name + "\n\n    Descripci\u00F3n: " + chargeData.description + "\n\n    Referencia: " + chargeData.reference + "\n\n    Tipo: " + paymentType + "\n\n    Cantidad: $" + amount + " MXN\n\n    " + expirationDateText + " " + expirationDate;
        copyText += "\n*ESTA REFERENCIA ES UNICA Y TIENE UNA VIGENCIA DE 48 HORAS*\n*SI EL MONTO CAMBIA O PASAN 48 HORAS TENDRA QUE SOLICITAR OTRA REFERENCIA*";
        offlineMsg += "<a>" + chargeData.reference + "<a>";
        offlineMsg += '<br> <br>';
        offlineMsg += "<a><strong>Cantidad: $</strong>" + amount + " MXN</a>";
        offlineMsg += '<br>';
        offlineMsg += "<a><strong>" + expirationDateText + " </strong></a>";
        offlineMsg += "<a>" + expirationDate + "<a>";
        offlineMsg += '<\div>';
        this.helpersService.copyToClipboard(copyText);
        return { title: titleOffline, msg: offlineMsg, copyText: copyText };
    };
    /**
     * calculateTotalForManual
     * Calcula el total de una referencia generada manualmente por Staff.
     */
    ChargesService.prototype.calculateTotalForManual = function (params) {
        var monthsAhead = params.months_ahead;
        var total = 0;
        switch (params.idChargeType) {
            case 6:
                params.accounts.forEach(function (account) {
                    total += account.fixed_monthly_fee;
                });
                total = monthsAhead && monthsAhead > 0 ? total * monthsAhead : total;
                break;
            default:
                total;
                break;
        }
        return total;
    };
    /**
     * evaluateDebts
     * @param pendingDebts array de deudas del cliente
     * @returns @interface evaluatedDebts
     */
    ChargesService.prototype.evaluateDebts = function (pendingDebts) {
        var totalDebt = 0;
        var idChargeType = pendingDebts.length === 1 ? 4 : 5;
        if (pendingDebts.length > 0) {
            pendingDebts.forEach(function (debt) {
                totalDebt += Number(debt.amount) + Number(debt.moratory_fees) + Number(debt.collection_fees);
            });
        }
        totalDebt = totalDebt / 100;
        return { idChargeType: idChargeType, totalDebt: totalDebt };
    };
    ChargesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChargesService_Factory() { return new ChargesService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.HelpersService)); }, token: ChargesService, providedIn: "root" });
    return ChargesService;
}());
export { ChargesService };
