<div class="container-fluid" *ngIf="client && vendors">
    <div class="text-right">
        <b>Ultima edición: {{client.updated_at | date: 'dd MMM yyyy H:mm'}}</b>
    </div>
    <ngb-tabset [destroyOnHide]="false">
        <ngb-tab title="Dirección principal">
            <ng-template ngbTabContent>
                <div class="row">
                    <div class="col">
                        <app-form-field type="input" [options]="{label: 'Nombre', path: 'name'}"></app-form-field>
                        <app-form-field type="datepicker" [options]="{
                            min_year: min_year,
                            max_year: max_year,
                            label: 'Fecha de nacimiento',
                            path: 'birth_date',
                            placeholder:'Fecha de nacimiento'
                        }"></app-form-field>
                        <app-form-field type="input" [options]="{label: 'Teléfono', path: 'phone'}">
                        </app-form-field>
                        <app-form-field type="email" [options]="{label: 'Email', path: 'email'}"></app-form-field>
                        <app-form-field type="input" [options]="{label: 'Colonia', path: 'colony'}"></app-form-field>
                        <app-form-field type="input" [options]="{label: 'Calle', path: 'address'}"></app-form-field>
                        <app-form-field type="input" [options]="{label: 'Latitud', path: 'coordinate.latitude'}">
                        </app-form-field>
                    </div>

                    <div class="col">
                        <app-form-field type="input" [options]="{label: 'Número exterior', path: 'outdoor_number'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Número interior', path: 'inside_number'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Entre', path: 'between_streets'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Código postal', path: 'postal_code'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Estado', path: 'state'}"></app-form-field>
                        <app-form-field type="input" [options]="{label: 'Municipio', path: 'county'}"></app-form-field>
                        <app-form-field type="input" [options]="{label: 'Longitud', path: 'coordinate.longitude'}">
                        </app-form-field>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6" *ngIf="!client.parent">
                        <app-form-field type="select" [options]="{
                            label: 'Vendor predeterminado',
                            path: 'vendor',
                            droptext: default_vendor,
                            elements: vendors
                        }"></app-form-field>
                    </div>

                    <div class="col-sm-6">
                        <app-form-field type="select" [options]="{
                            label: 'Asignar a grupo',
                            path: 'id_groups',
                            droptext: group,
                            elements: groups
                        }"></app-form-field>
                    </div>

                    <div class="col-sm-6">
                        <app-form-field type="select" [options]="{
                            label: 'Metodo de pago predeterminado',
                            path: 'preferred_payment_method',
                            droptext: default_payment_method,
                            elements: preferred_payment_methods
                        }"></app-form-field>
                    </div>

                    <div class="col-sm-6" *ngIf="leadsources.length > 0">
                        <app-form-field type="select" [options]="{
                            label: 'Leadsource',
                            path: 'id_leadsources',
                            droptext: default_leadsource,
                            elements: leadsources
                        }"></app-form-field>
                    </div>
                </div>

                <br>

                <div class="row text-center">
                    <div class="col-sm-6 col-md-4" *ngIf= "extraFilterChange">
                        <app-form-field type="switch" [options]="{
                            label: 'Cambio de filtros extras',
                            path: 'extra_filter_change',
                            trueValue: 1, 
                            falseValue: 0, 
                            trueCaption: 'Activo',
                            falseCaption: 'Inactivo'
                        }"></app-form-field>
                    </div>

                    <div class="col-sm-6 col-md-4" *ngIf="client.completed_installation">
                        <app-form-field type="switch" [options]="{
                            label: 'Pago anual', 
                            path: 'anual_payment', 
                            trueValue: 1, 
                            falseValue: 0, 
                            trueCaption: 'Activo',
                            falseCaption: 'Inactivo'}">
                        </app-form-field>
                    </div>

                    <div class="col-sm-6 col-md-4" *ngIf="client.completed_installation && client.is_parent">
                        <app-form-field type="switch" [options]="{
                            label: 'Pago familiar automatico', 
                            path: 'unique_charge', 
                            trueValue: 1, 
                            falseValue: 0, 
                            trueCaption: 'Activo',
                            falseCaption: 'Inactivo'}">
                        </app-form-field>
                    </div>
                </div>
                
                <br>

                <div class="row">
                    <div class="col-md-3">
                        <app-form-field type="image" [options]="{
                                width: '0',
                                path: 'images.id',
                                image: this.documents.id,
                                text:'Subir ID',
                                class: 'image-preview',
                                buttonVisible: 'true',
                                zoom: 'true'
                            }"></app-form-field>
                    </div>
                    <div class="col-md-3">
                        <app-form-field type="image" [options]="{
                                width: '0',
                                path: 'images.id_reverse',
                                image: this.documents.id_reverse,
                                text:'Subir ID (atras)',
                                class: 'image-preview',
                                buttonVisible: 'true',
                                zoom: 'true'
                            }"></app-form-field>
                    </div>
                    <div class="col-md-3">
                        <app-form-field type="image" [options]="{
                            width: '0',
                            path: 'images.proof_of_address',
                            image: this.documents.proof_of_address,
                            text:'Comprobante',
                            class: 'image-preview',
                            buttonVisible: 'true',
                            zoom: 'true'
                        }"></app-form-field>
                    </div>
                    <div class="col-md-3">
                        <app-form-field type="image" [options]="{
                            width: '0',
                            path: 'images.profile',
                            image: this.documents.profile,
                            text: 'Perfil',
                            class: 'image-preview',
                            buttonVisible: 'true',
                            zoom: 'true'
                        }"></app-form-field>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="text-center">Números telefónicos adicionales &nbsp;&nbsp;
                            <button *ngIf="extra_phones.controls.length < 3 " (click)="addExtraPhone(true)"
                                class="btn btn-outline-primary btn-sm" title="addExtraPhone"><i class="fa fa-plus"></i></button>
                        </h3>
                        <hr />
                    </div>
                    <div class="col-md-12">
                        <div class="row" *ngFor="let extraPhoneForm of extra_phones.controls; let i = index">
                            <div class="col-sm-5">
                                <app-form-field type="input"
                                    [options]="{label: 'Numero ' + (i + 1),  path: 'extra_phones.' + i + '.phone'}">
                                </app-form-field>
                            </div>
                            <div class="col-sm-5">
                                <app-form-field type="input"
                                    [options]="{label: 'Nombre ' + (i + 1), placeholder: 'Nombre ' + (i + 1) ,  path: 'extra_phones.' + i + '.name'}">
                                </app-form-field>
                            </div>
                            <div class="col-sm-2">
                                <button (click)="removeExtraPhone(i)" class="btn btn-outline-danger btn-sm"><i
                                        class="fa fa-trash" title="removeExtraPhone"></i></button>
                            </div>
                        </div>
                    </div>
                </div>

                <br>

                <h6>Datos extra(para Staff)</h6>
                <app-form-field type="textarea" styleInput="textarea"
                    [options]="{rows: '4', path:'extra_data', width: true}">
                </app-form-field>
            </ng-template>
        </ngb-tab>

        <ngb-tab title="Dirección de facturación">
            <ng-template ngbTabContent>
                <div class="d-flex justify-content-center px-auto">
                    <h3 class="text-center">Usar datos de la dirección principal &nbsp;&nbsp;
                        <button (click)="copyInvoiceData()"
                            class="btn btn-outline-primary btn-sm" title="copyInvoiceData"><i class="fa fa-clone"></i></button>
                    </h3>
                </div>
                <div class="row">
                    <div class="col">
                        <app-form-field type="input"
                            [options]="{label: 'Nombre', path: 'invoice_data.name', uppercase: true}">
                        </app-form-field>
                        <app-form-field type="input"
                            [options]="{label: 'RFC', path: 'invoice_data.rfc', length: '13', uppercase: true}">
                        </app-form-field>
                        <app-form-field type="select" [options]="{
                                label: 'Regimen fiscal', 
                                path: 'invoice_data.tax_situation', 
                                droptext: default_tax_situation,
                                elements: tax_situations
                            }">
                        </app-form-field>
                        <app-form-field type="select" [options]="{
                            label: 'Uso de CFDI', 
                            path: 'invoice_data.cfdi_use', 
                            droptext: default_cfdi_use,
                            elements: cfdi_uses
                        }"></app-form-field>
                        <app-form-field type="input" [options]="{label: 'Teléfono', path: 'invoice_data.phone'}">
                        </app-form-field>
                        <app-form-field type="email" [options]="{label: 'Email', path: 'invoice_data.email'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Colonia', path: 'invoice_data.colony'}">
                        </app-form-field>
                    </div>

                    <div class="col">
                        <app-form-field type="input" [options]="{label: 'Calle', path: 'invoice_data.address'}">
                        </app-form-field>
                        <app-form-field type="input"
                            [options]="{label: 'Número exterior', path: 'invoice_data.outdoor_number'}">
                        </app-form-field>
                        <app-form-field type="input"
                            [options]="{label: 'Número interior', path: 'invoice_data.inside_number'}"></app-form-field>
                        <app-form-field type="input" [options]="{label: 'Entre', path: 'invoice_data.between_streets'}">
                        </app-form-field>
                        <app-form-field type="input"
                            [options]="{label: 'Código postal', path: 'invoice_data.postal_code'}"></app-form-field>
                        <app-form-field type="input" [options]="{label: 'Estado', path: 'invoice_data.state'}">
                        </app-form-field>
                        <app-form-field type="input" [options]="{label: 'Municipio', path: 'invoice_data.county'}">
                        </app-form-field>
                    </div>
                </div>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>

<br>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger"
            (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid"
            (click)="updateClient()">Actualizar cliente</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>