<div class="container-fluid pt-3">
    <div class="row">
        <div class="col col-md-6">
            <b>Dirección: </b>
            <br />
            <p>Calle {{data.address}} Número ext {{data.outdoor_number}} <span *ngIf="data.inside_number"> Número int {{data.inside_number}} </span> Entre {{data.between_streets}}
                <br>
                Colonia {{data.colony}} C.P {{data.postal_code}} {{data.county}}, {{data.state}}
            </p>
            <b>Teléfono: </b><span>{{ data.phone }}</span><br />
            <b>Email: </b><span>{{ data.email }}</span><br />
            <b>Procesador de pagos predeterminado: </b><span>{{ vendor | titlecase}}</span><br />
            <br>
            <div *ngIf="data.debts.length > 0">
                <button class="btn btn-info" (click)="chargeClientDebts()" *ngxPermissionsOnly="['charge-debts']">Cobrar deudas</button>
            </div>
        </div>

        <div class="col col-md-6" *ngxPermissionsOnly="['notify-user']">
            <app-form-field type="textarea" styleInput="textarea" [options]="{rows: '8', path:'message', width: true}">
            </app-form-field>
            <br>
            <button class="btn btn-info" (click)="notifyClient()">Notificar</button>
        </div>
    </div>
    <ngb-tabset [destroyOnHide]="false">
        <ng-template [ngxPermissionsOnly]="['list-debts']">
            <ngb-tab title="Deudas" *ngIf="data.debt > 0">
                <ng-template ngbTabContent>
                    <app-datatable [options]="dataTableDebts"></app-datatable>
                </ng-template>
            </ngb-tab>
        </ng-template>
        <ng-template [ngxPermissionsOnly]="['list-debts']">
            <ngb-tab title="Deudas condonadas" *ngIf="data.canceled_debts">
                <ng-template ngbTabContent>
                    <app-datatable [options]="baseDataTableCanceledDebts"></app-datatable>
                </ng-template>
            </ngb-tab>
        </ng-template>
        <ngb-tab title="Cargos" *ngxPermissionsOnly="['list-charges']">
            <ng-template ngbTabContent>
                <app-client-charges-history [data]="params"></app-client-charges-history>
                <!-- <app-datatable [options]="dataTableCharges"></app-datatable> -->
            </ng-template>
        </ngb-tab>
    </ngb-tabset>
</div>