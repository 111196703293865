/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./supplies.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./fields/inputs/inputs.component.ngfactory";
import * as i3 from "./fields/inputs/inputs.component";
import * as i4 from "./fields/outputs/outputs.component.ngfactory";
import * as i5 from "./fields/outputs/outputs.component";
import * as i6 from "@angular/common";
import * as i7 from "./supplies.component";
var styles_SuppliesComponent = [i0.styles];
var RenderType_SuppliesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SuppliesComponent, data: {} });
export { RenderType_SuppliesComponent as RenderType_SuppliesComponent };
function View_SuppliesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-inputs", [], null, null, null, i2.View_InputsComponent_0, i2.RenderType_InputsComponent)), i1.ɵdid(1, 114688, null, 0, i3.InputsComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SuppliesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-outputs", [], null, null, null, i4.View_OutputsComponent_0, i4.RenderType_OutputsComponent)), i1.ɵdid(1, 114688, null, 0, i5.OutputsComponent, [], { item: [0, "item"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SuppliesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [["class", "m-b-xs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i6.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SuppliesComponent_1)), i1.ɵdid(4, 278528, null, 0, i6.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SuppliesComponent_2)), i1.ɵdid(6, 278528, null, 0, i6.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.supplyMovement; _ck(_v, 2, 0, currVal_0); var currVal_1 = "inputs"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "outputs"; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_SuppliesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-supplies", [], null, null, null, View_SuppliesComponent_0, RenderType_SuppliesComponent)), i1.ɵdid(1, 114688, null, 0, i7.SuppliesComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuppliesComponentNgFactory = i1.ɵccf("app-supplies", i7.SuppliesComponent, View_SuppliesComponent_Host_0, { item: "item" }, {}, []);
export { SuppliesComponentNgFactory as SuppliesComponentNgFactory };
