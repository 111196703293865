import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ChargesBacksService } from '../../../services/chargesbacks.service';
import { PermissionsService } from '../../../services/permissions.service';

@Component({
  selector: 'app-charges-backs',
  templateUrl: './charges-backs.component.html',
  styleUrls: ['./charges-backs.component.scss']
})
export class ChargesBacksComponent implements OnInit {
  dataTableConfig: any;
  broadcast$: Subscription;

  private readonly baseDataTableConfig = {
    config: {
      base: this.chargesBacksService,
      api: 'getChargesBacksTable'
    },
    columns: [
      {
        display: 'Cliente',
        field: 'client.name',
        type: 'text'
      },
      {
        display: 'Cargo',
        field: 'charge.description',
        type: 'text'
      },
      {
        display: 'Cantidad',
        field: 'amount',
        type: 'charge_amount'
      },
      {
        display: 'Estatus',
        field: 'status',
        type: 'text'
      },
      {
        display: 'Fecha de alta',
        field: 'created_at',
        type: 'date'
      }
    ]
  };

  constructor(
    private readonly chargesBacksService: ChargesBacksService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

}
