import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ElementsService } from '../../../../services/elements.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../../providers/form.service';
import { SwalService } from '../../../../services/swal.service';
import { BroadcastService } from '../../../../services/broadcast.service';
import { environment } from '../../../../../environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-edit-element',
  templateUrl: './create-edit-element.component.html',
  styleUrls: ['./create-edit-element.component.scss']
})
export class CreateEditElementComponent implements OnInit, OnDestroy {
  @Input() data: any;
  baseUrl = `${environment.apiUrl}`;
  subscriptions: Array<Subscription> = [];
  element: any;
  image: any;
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    price: ['', Validators.required],
    id_categories: [''],
    image: ['']
  });
  constructor(
    public activeModal: NgbActiveModal,
    private readonly broadcast: BroadcastService,
    private readonly elementService: ElementsService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    this.form.get('id_categories').setValue(this.data.elementData.id_categories);
    if (this.data.status === 'edit') {
      this.subscriptions.push(this.elementService.show(this.data.elementData.id_categories_elements).subscribe((resp: any) => {
        this.element = resp.response;
        this.getImage(this.element.id_categories_elements);
        Object.keys(this.element).forEach(key => {
          if (this.element.hasOwnProperty(key) && !!this.form.controls[key]) {
            this.form.controls[key].setValue(this.element[key]);
          }
        });
      }));
    }
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  save(): void {
    if (this.data.status === 'edit') {
      this.updateElement();
    }

    if (this.data.status === 'create') {
      this.saveElement();
    }
  }

  saveElement(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar los datos del elemento?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.elementService.create(this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al guardar los datos' });
            }
          }));
        }
      });
    }
  }

  updateElement(): void {
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos de la categoria?' }).then(result => {
        if (result.value) {
          this.subscriptions.push(this.elementService.update(this.data.elementData.id_categories_elements, this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
            }
          }));
        }
      });
    }
  }

  private getImage(id): any {
    let imageUrl = null;
    this.subscriptions.push(this.elementService.getImage(id).subscribe((resp: any) => {
      imageUrl = `${this.baseUrl}/extras/${resp.response}`;
      this.image = imageUrl;
    }));
  }
}
