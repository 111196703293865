<div class="container-fluid border-bottom bg-white p-3 mb-4">
    <h2>Ingresos</h2>
    <canvas baseChart 
            width="450" 
            height="100"
            [datasets]="incomesChartData"
            [chartType]="lineChartType"
            [labels]="labels"
            [options]="options"
            [colors]="chartColor"></canvas>
</div>