<div class="container-fluid">
    <ngb-tabset [destroyOnHide]="false">
        <ngb-tab title="Historial de validación">
            <ng-template ngbTabContent>
                <app-history [items]="history" type="client"></app-history>
            </ng-template>
        </ngb-tab>
        <ngb-tab title="Historial de cambios">
            <ng-template ngbTabContent>
                <app-history [items]="history_log" type="client"></app-history>
            </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="history_price_change_log.length > 0" title="Historial de precios">
            <ng-template ngbTabContent>
                <app-history [items]="history_price_change_log" type="client"></app-history>
            </ng-template>
        </ngb-tab>
    </ngb-tabset>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('close button')">Cancelar</button>
    </div>
</div>