import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})

export class AuthorizationService {

  baseUrl = `${environment.apiUrl}`;
  cookieValue = 'NA';
  jwt = null;

  constructor(private readonly http: HttpClient) { }

  login(credentials): Observable<Object> {
    return this.http.post(`${this.baseUrl}/auth/login`, credentials, { withCredentials: true });
  }

  refreshToken(): Observable<Object> {
    return this.http.post<any>(`${environment.apiUrl}/auth/refresh`, {}).pipe(
      tap((data: any) => {
        localStorage.setItem('access_token', data.token);
      })
    );
  }

  logout(): void {
    this.http.get<any>(`${environment.apiUrl}/auth/logout`).subscribe(
      () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('user');
      }
    );    
  }

  isAuthenticated(): boolean {
    let isAuthorized = false;
    if (window.localStorage.getItem('access_token')) {
      isAuthorized = true;
    }
    
    return isAuthorized;
  }

  get token(): string {
    return localStorage.getItem('access_token');
  }

}
