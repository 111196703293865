import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UsersService = /** @class */ (function () {
    function UsersService(http) {
        this.http = http;
        this.baseUrl = "" + environment.apiUrl;
    }
    UsersService.prototype.user = function (val) {
        return this.http.get(this.baseUrl + "/users/" + val);
    };
    UsersService.prototype.getAdmins = function (payload) {
        return this.http.post(this.baseUrl + "/users/Administrator", payload);
    };
    UsersService.prototype.getTechnitians = function (payload) {
        return this.http.post(this.baseUrl + "/users/Technician", payload);
    };
    UsersService.prototype.getUserByRole = function (params, filter) {
        var role = filter.role.role;
        return this.http.post(this.baseUrl + "/users/" + role, params);
    };
    UsersService.prototype.getImageProfile = function (id) {
        return this.http.get(this.baseUrl + "/users/" + id + "/getImageProfile");
    };
    UsersService.prototype.create = function (params) {
        return this.http.post(this.baseUrl + "/auth/register", params);
    };
    UsersService.prototype.createUser = function (params) {
        return this.http.post(this.baseUrl + "/users", params);
    };
    UsersService.prototype.show = function (id) {
        return this.http.get(this.baseUrl + "/users/" + id);
    };
    UsersService.prototype.update = function (id, params) {
        return this.http.put(this.baseUrl + "/users/" + id, params);
    };
    UsersService.prototype.getUser = function (params, filters) {
        if (filters) {
            Object.assign(params, filters);
        }
        return this.http.post(this.baseUrl + "/users/getUsers", params);
    };
    /**
     * getLogedUserRoles
     * Verifica si el usuario logueado tiene los roles solicitados.
     */
    UsersService.prototype.getLogedUserRoles = function (roles, rolesToCheck) {
        var filteredRoles = roles.filter(function (rol) {
            if (rolesToCheck.includes(rol.name)) {
                return true;
            }
        });
        return filteredRoles;
    };
    UsersService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersService_Factory() { return new UsersService(i0.ɵɵinject(i1.HttpClient)); }, token: UsersService, providedIn: "root" });
    return UsersService;
}());
export { UsersService };
