import { Component, OnInit } from '@angular/core';

// Services
import { ClientsService } from '../../../../services/clients.service';
import { DashboardService } from '../../../../services/dashboard.service';
import { SwalService } from '../../../../services/swal.service';
@Component({
  selector: 'app-clients-map',
  templateUrl: './clients-map.component.html',
  styleUrls: ['./clients-map.component.scss']
})
export class ClientsMapComponent implements OnInit {
  longitude = -103.350693;
  latitude = 20.39079895;
  zoom = 8;
  coordinates: any = [];
  private map: google.maps.Map = null;

  constructor(
    private readonly clientsService: ClientsService,
    private readonly dashboardService: DashboardService,
    private readonly swal: SwalService
  ) { }

  ngOnInit(): void {
    //
  }

  // tslint:disable-next-line: typedef
  async onMapLoad(mapInstance: google.maps.Map) {
    this.coordinates = await this.dashboardService.clientsCoordinates().toPromise();
    this.map = mapInstance;
  }

  // tslint:disable-next-line: typedef
  async showClientInfo(clientId) {
    const response = await this.clientsService.show(clientId).toPromise();
    // let info;
    if (response && response.success) {
      const client = response.response;
      const params = {
        title: '',
        html: `
          <h3>${client.name}</h3>
          <table class="table">
            <tbody>
              <tr>
                <td><strong>Email:</strong></td>
                <td>${client.email}</td>
              </tr>
              <tr>
                <td><strong>Teléfono:</strong></td>
                <td>${client.phone}</td>
              </tr>
              <tr>
                <td><strong>Status:</strong></td>
                <td>${client.status}</td>
              </tr>                      
              <tr>
                <td><strong>Dirección:</strong></td>
                <td>${client.address}. Entre: ${client.between_streets}</td>
              </tr>
              <tr>
                <td><strong>Colonia:</strong></td>
                <td>${client.colony}</td>
              </tr>
              <tr>
                <td><strong>Colonia:</strong></td>
                <td>${client.county}</td>
              </tr>
              <tr>
                <td><strong>Estado:</strong></td>
                <td>${client.state}</td>
              </tr>   
            </tbody>
          </table>
        `
      };

      this.swal.info(null, params);
    } else {
      this.swal.error({text: 'Ha ocurrido un error mientras se obtnía la información del cliente. Intente nuevamente.'});
    }
  }
}
