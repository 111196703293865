import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-client-sales-group',
  templateUrl: './client-sales-group.component.html',
  styleUrls: ['./client-sales-group.component.scss']
})
export class ClientSalesGroupComponent implements OnInit {
  @Input() data: any;
  current_group: any;

  ngOnInit(): void {
    this.current_group = this.data.group;
  }
}
