import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// Services
import { AdvertisingService } from '../../../../services/advertising.service';
import { AdvertisingFormComponent } from './advertising-form/advertising-form.component';
import { BroadcastService } from '../../../../services/broadcast.service';
import { ModalComponent } from '../../../../components/modal/modal.component';
import { PermissionsService } from '../../../../services/permissions.service';
import { SharedComponent } from '../../../../model/shared-component';
import { SwalService } from '../../../../services/swal.service';
var AdvertisingTableComponent = /** @class */ (function () {
    function AdvertisingTableComponent(advertisingService, broadcast, permissionsService, swal, activeModal, appModal) {
        this.advertisingService = advertisingService;
        this.broadcast = broadcast;
        this.permissionsService = permissionsService;
        this.swal = swal;
        this.activeModal = activeModal;
        this.appModal = appModal;
        this.baseDataTableConfig = {
            config: {
                base: this.advertisingService,
                api: 'getAdvertising'
            },
            columns: [
                {
                    display: 'Fecha de balance',
                    field: 'balance_date',
                    type: 'date'
                },
                {
                    display: 'Balance',
                    field: 'balance',
                    type: 'currency'
                },
                {
                    display: 'Acciones',
                    field: '',
                    type: 'actions',
                    options: [
                        {
                            display: 'Editar',
                            icon: 'fa fa-pencil',
                            event: 'advertising.edit',
                            conditionality: 'true',
                            permissions: ['update-ads']
                        },
                        {
                            display: 'Eliminar',
                            icon: 'fa fa-trash fa-fw',
                            event: 'advertising.delete',
                            conditionality: 'true',
                            permissions: ['delete-ads']
                        }
                    ]
                }
            ]
        };
    }
    AdvertisingTableComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.broadcast$ = this.broadcast.events.subscribe(function (event) {
            switch (event.name) {
                case 'advertising.delete':
                    _this.deleteAdvertisingItem(event.data);
                    break;
                case 'advertising.edit':
                    _this.editAdvertisingItem(event.data);
                    break;
            }
        });
        this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
    };
    AdvertisingTableComponent.prototype.ngOnDestroy = function () {
        this.broadcast$.unsubscribe();
    };
    AdvertisingTableComponent.prototype.createAdvertisingItem = function (data) {
        var props = new SharedComponent(AdvertisingFormComponent, {
            advertisingData: data,
            status: 'create'
        }, {
            title: 'Crear entrada de advertising'
        });
        this.appModal.open(props);
    };
    AdvertisingTableComponent.prototype.deleteAdvertisingItem = function (data) {
        var _this = this;
        var swalParams = {
            title: "\u00BFEst\u00E1s seguro de querer eliminar el balance del d\u00EDa " + data.balance_date + "?",
            text: ''
        };
        this.swal.warning(swalParams).then(function (result) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var resp;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!result.value) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.advertisingService.delete(data.id).toPromise()];
                    case 1:
                        resp = _a.sent();
                        if (resp && resp.success) {
                            this.swal.success({ title: 'Entrada de advertising eliminada exitosamente' }).then(function () {
                                _this.activeModal.dismiss();
                                _this.broadcast.reloadDataTable();
                            });
                        }
                        else {
                            this.swal.error('Ocurrio un error al intentar borrar la entrada de advertising');
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    AdvertisingTableComponent.prototype.editAdvertisingItem = function (data) {
        // open modal, passing the context
        var props = new SharedComponent(AdvertisingFormComponent, {
            advertisingData: data,
            status: 'edit'
        }, {
            title: 'Entradas de advertising'
        });
        this.appModal.opensm(props);
    };
    return AdvertisingTableComponent;
}());
export { AdvertisingTableComponent };
