import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class TeamsService {
    private readonly baseUrl = `${environment.apiUrl}`;
    constructor(private readonly http: HttpClient) { }

    index(): any {
        return this.http.get(`${this.baseUrl}/teams`);
    }
}
