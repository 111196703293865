import * as tslib_1 from "tslib";
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FromService } from '../../../providers/form.service';
import { BroadcastService } from '../../../services/broadcast.service';
import { CardsService } from '../../../services/cards.service';
import { CategoriesService } from '../../../services/categories.service';
import { ClientsService } from '../../../services/clients.service';
import { SwalService } from '../../../services/swal.service';
var ClientCreateComponent = /** @class */ (function () {
    function ClientCreateComponent(activeModal, broadcast, cdRef, categoriesService, cardsService, clientsService, formBuilder, fromService, swalService) {
        this.activeModal = activeModal;
        this.broadcast = broadcast;
        this.cdRef = cdRef;
        this.categoriesService = categoriesService;
        this.cardsService = cardsService;
        this.clientsService = clientsService;
        this.formBuilder = formBuilder;
        this.fromService = fromService;
        this.swalService = swalService;
        this.documents = {
            id: '',
            id_reverse: null,
            proof_of_address: null,
            profile: null
        };
        this.elements = [];
        this.show_plans = false;
        this.selectedPlan = false;
        this.tabsStatus = {
            instalationDisabled: true,
            personalDataDisabled: true,
            cardDataDisabled: true
        };
        this.contractsTypes = [];
        this.months = [];
        this.years = [];
        this.banks = [
            'VISA',
            'MASTERCARD',
            'AMEX'
        ];
        this.imgBrand = '';
        this.brandUppercase = '';
        this.allPlans = [];
        this.currentPlans = [];
        this.subscriptions = [];
        this.form = this.formBuilder.group({
            seller: ['', Validators.required],
            client: this.formBuilder.group({
                name: ['', Validators.required],
                address: ['', Validators.required],
                outdoor_number: ['', Validators.required],
                inside_number: [''],
                phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(15)]],
                email: ['', Validators.required],
                between_streets: ['', Validators.required],
                colony: ['', [Validators.required, Validators.minLength(4)]],
                postal_code: ['', Validators.required],
                state: ['', [Validators.required, Validators.minLength(4)]],
                county: ['', [Validators.required, Validators.minLength(4)]]
            }),
            responsabilidad: [true],
            images: this.formBuilder.group({
                id: [''],
                id_reverse: [''],
                proof_of_address: [''],
                profile: ['']
            }),
            elements: this.elements,
            monthly_installments: [1, Validators.required],
            internalData: this.formBuilder.group({
                contract_type: [''],
                referral_email: [''],
                plan: ['', Validators.required],
                cardData: this.formBuilder.group({
                    card_number: [''],
                    cvc: [''],
                    expiration_month: [''],
                    expiration_year: ['']
                })
            })
        });
    }
    ClientCreateComponent.prototype.ngOnInit = function () {
        var cardsInfo = this.cardsService.setDateCardInfo();
        this.months = cardsInfo.months;
        this.years = cardsInfo.years;
        this.fromService.setForm(this.form);
        this.cardsService.setVendors();
        this.getCategories();
    };
    ClientCreateComponent.prototype.ngAfterViewChecked = function () {
        if (this.show_plans) {
            this.getSelectedPlanInfo();
        }
        this.checkPersonalDataFormStatus();
        this.updateCardDataValidators();
        this.cdRef.detectChanges();
    };
    ClientCreateComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions.length > 0) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    /**
     * getRefeer
     * Envia una petición GET a la API para obtener la inforamción del grupo y los planes
     * de la cuenta que refirio al cliente del cual se estan capturando los datos.
     * + Si el correo que se envia es vacio busca la cuenta asociada a aguagratis@aguagente.com
     * + Si el correo ingresado no existe devuelve un mensaje de que el correo no es valido.
     */
    ClientCreateComponent.prototype.getRefeer = function () {
        var _this = this;
        var refeer = this.form.get('internalData.referral_email').value === '' ?
            'aguagratis@aguagente.com' : this.form.get('internalData.referral_email').value;
        if (refeer) {
            this.subscriptions.push(this.clientsService.getClientByEmail(refeer).subscribe(function (resp) {
                if (resp.success && resp.response.length > 0) {
                    _this.form.controls.seller.setValue(resp.response[0].id_clients);
                    _this.referralGroupInfo = resp.response[0].group;
                    _this.setContractTypes(_this.referralGroupInfo);
                    _this.tabsStatus.instalationDisabled = false;
                    _this.tabsStatus.personalDataDisabled = false;
                }
                else {
                    _this.swalService.error({ text: 'El email que ingresaste no es válido' });
                }
            }));
        }
        else {
            this.swalService.error({ text: 'No se pudo determinar el correo' });
        }
    };
    /**
     * Calcula los totales del costo de la instalación
     * El total de la instalación considera los siguientes parametros:
     *  + La primera mensualidad que se le cobrara al cliente esto dependera del valor dado por el grupo del correo del referidor,
     *    Si el grupo bajo el cual se esta registrando el nuevo cliente su trial_days es mayor que 0, entonces se tomara en cuenta
     *    el trial_days_price para la primera mensualidad, en caso contrario se tomara el monthly fee del grupo
     * + La responsabilidad social (sr) en caso de que se haya seleccionado, para la responsabilidad social se toman en cuenta
     *   la primera mensualidad del cliente, asi como el installation_fee y los elementos extras que se hayan decidido seleccionado.
     * @returns objeto con los totales de la primera mensualidad, el total con los extras y si se selecciono el total
     * de la responsabilidad social.
     */
    ClientCreateComponent.prototype.contractTotal = function () {
        var totals = {};
        var selectedElements = this.form.get('elements').value;
        var clientMonthlyFee = Number(this.selectedPlanInfo.monthly_fee);
        if (this.selectedPlanInfo.trial_days > 0) {
            clientMonthlyFee = this.selectedPlanInfo.trial_days_price;
        }
        var total = 0;
        var social_responsability = this.form.get('responsabilidad').value;
        var installationFee = Number(this.selectedPlanInfo.installation_fee);
        var deposit = Number(this.selectedPlanInfo.deposit);
        total = installationFee + clientMonthlyFee;
        if (selectedElements !== null) {
            selectedElements.forEach(function (element) {
                var elementPrice = 0;
                elementPrice = Number(element.price * 100);
                total += elementPrice;
            });
        }
        if (social_responsability) {
            var sr = (total / 1.16) * 0.007;
            total += sr;
            Object.assign(totals, { sr: sr });
        }
        total = Math.round(((total + deposit) / 100) * 100) / 100;
        Object.assign(totals, { total: total, clientMonthlyFee: clientMonthlyFee });
        return totals;
    };
    /**
     * setExtras
     * Setea en el formulario el valor de los materiales extra seleccionados para la instalación.
     * @param event evento recibido por el checkbox
     */
    ClientCreateComponent.prototype.setExtras = function (event) {
        var value = event.value;
        if (this.elements.indexOf(value) > -1) {
            this.elements.splice(this.elements.indexOf(value), 1);
        }
        else {
            this.elements.push(event.value);
        }
        this.form.controls.elements.setValue(this.elements);
    };
    /**
     * clearCardData
     * Función que permite limpiar unicamente el formulario de las tarjetas
     * en caso de que no se quiera añadir ninguna.
     */
    ClientCreateComponent.prototype.clearCardData = function () {
        var cardData = this.form.get('internalData.cardData');
        var card_number = this.form.get('internalData.cardData.card_number');
        var cvc = this.form.get('internalData.cardData.cvc');
        var expiration_month = this.form.get('internalData.cardData.expiration_month');
        var expiration_year = this.form.get('internalData.cardData.expiration_year');
        card_number.clearValidators();
        cvc.clearValidators();
        expiration_month.clearValidators();
        expiration_year.clearValidators();
        cardData.reset();
    };
    /**
     * registerClient
     * Envia una petición POST en un FORM DATA con los datos basicos para generar
     * el registro del cliente en el sistema.
     */
    ClientCreateComponent.prototype.registerClient = function () {
        var _this = this;
        this.registerCard().then(function (response) {
            if (response.status) {
                var cardTokens = response.added_card ? response.data : '';
                var formData = _this.fillFormData(cardTokens);
                _this.subscriptions.push(_this.clientsService.registerClient(formData).subscribe(function (res) {
                    if (res.success) {
                        _this.swalService.success({ text: 'Contrato generado exitosamente' }).then(function () {
                            _this.activeModal.dismiss();
                            _this.broadcast.reloadDataTable();
                        });
                    }
                    else {
                        _this.swalService.error();
                    }
                }));
            }
            else {
                _this.swalService.error({ text: response.data });
            }
        });
    };
    /**
     * SetContractTypes
     * Determina los tipos de contrato que puede llegar a tener el grupo asociado al referidor
     * @param group array que lista todos los planes standard o premium que puede tener los contratos
     * busissnes o premium
     */
    ClientCreateComponent.prototype.setContractTypes = function (group) {
        var _this = this;
        if (group && group.plans && group.plans.length > 0) {
            var _group = tslib_1.__assign({}, group);
            this.allPlans = [_group].concat(group.plans);
            this.allPlans.forEach(function (plan) {
                switch (plan.type) {
                    case 1:
                    case 2:
                        if (_this.contractsTypes.map(function (element) { return element.name; }).indexOf('Home') === -1) {
                            _this.contractsTypes.push({ id: 'home', name: 'Home' });
                        }
                        break;
                    case 3:
                    case 4:
                        if (_this.contractsTypes.map(function (element) { return element.name; }).indexOf('Small Business') === -1) {
                            _this.contractsTypes.push({ id: 'busissnes', name: 'Small Business' });
                        }
                        break;
                }
            });
            this.show_plans = true;
            this.showPlans(this.allPlans);
        }
        else {
            this.selectedPlanInfo = group;
            this.form.get('internalData.plan').setValue(group.id_groups);
        }
    };
    /**
     * showPlans
     * Determina y filtra a que tipo de contrato pertenece cada plan en el grupo.
     * @param plans array con todos los planes que tiene el grupo
     */
    ClientCreateComponent.prototype.showPlans = function (plans) {
        var _this = this;
        var contratType = this.form.get('internalData.contract_type');
        if (contratType) {
            this.subscriptions.push(contratType.valueChanges.subscribe(function (contract) {
                var filteredPlans = [];
                switch (contract) {
                    case 'home':
                        plans.forEach(function (plan) {
                            if (plan.type === 1 || plan.type === 2) {
                                filteredPlans.push({ id: plan.id_groups, name: plan.name });
                            }
                        });
                        break;
                    case 'busissnes':
                        plans.forEach(function (plan) {
                            if (plan.type === 3 || plan.type === 4) {
                                filteredPlans.push({ id: plan.id_groups, name: plan.name });
                            }
                        });
                        break;
                }
                _this.currentPlans = filteredPlans;
            }));
        }
    };
    /**
     * getSelectedPlanInfo
     * Obtiene la información del plan seleccionado.
     */
    ClientCreateComponent.prototype.getSelectedPlanInfo = function () {
        var _this = this;
        var planValue = this.form.get('internalData.plan');
        if (planValue) {
            this.subscriptions.push(planValue.valueChanges.subscribe(function (planId) {
                var plans = _this.allPlans;
                _this.selectedPlanInfo = plans.filter(function (plan) { return plan.id_groups === Number(planId); })[0];
                _this.selectedPlan = _this.selectedPlanInfo ? true : false;
            }));
        }
    };
    /**
     * getCategories
     * Obtiene de la BD las categorias de los elementos que se pueden cobrar para una instalación
     */
    ClientCreateComponent.prototype.getCategories = function () {
        var _this = this;
        this.subscriptions.push(this.categoriesService.getCategoriesList().subscribe(function (resp) {
            if (resp.response.length > 0) {
                _this.categories = resp.response;
            }
            else {
                _this.swalService.error({ text: 'No se pudieron obtener las extras' });
            }
        }));
    };
    /**
     * checkPersonalDataFormStatus
     * Revisa el status de validez del subform de clientes para poder determinar si
     * se activa el tab con el formulario para la tarjeta.
     */
    ClientCreateComponent.prototype.checkPersonalDataFormStatus = function () {
        var personalDataStatus = this.form.get('client').valid ? false : true;
        this.tabsStatus.cardDataDisabled = personalDataStatus;
    };
    /**
     * updateCardDataValidators
     * Si el formulario de la tarjeta es rellenado añade validadores a todos los campos
     */
    ClientCreateComponent.prototype.updateCardDataValidators = function () {
        if (this.form.get('internalData.cardData').dirty) {
            var card_number = this.form.get('internalData.cardData.card_number');
            var cvc = this.form.get('internalData.cardData.cvc');
            var expiration_month = this.form.get('internalData.cardData.expiration_month');
            var expiration_year = this.form.get('internalData.cardData.expiration_year');
            card_number.setValidators([Validators.required, Validators.minLength(15), Validators.maxLength(16)]);
            cvc.setValidators([Validators.required, Validators.minLength(3), Validators.maxLength(4)]);
            expiration_month.setValidators(Validators.required);
            expiration_year.setValidators(Validators.required);
            card_number.updateValueAndValidity({ onlySelf: true });
            cvc.updateValueAndValidity({ onlySelf: true });
            expiration_month.updateValueAndValidity({ onlySelf: true });
            expiration_year.updateValueAndValidity({ onlySelf: true });
        }
    };
    /**
     * fillFormData
     * Genera un FormData con los valores necesarios para poder generar el contrato en el sistema
     * @param cardTokens opcional objeto con los tokens de cada vendor para la tarjeta ingresada
     * @returns FormData
     */
    ClientCreateComponent.prototype.fillFormData = function (cardTokens) {
        var _this = this;
        var formData = new FormData();
        formData.append('client[name]', this.form.get('client.name').value);
        formData.append('client[address]', this.form.get('client.address').value);
        formData.append('client[phone]', this.form.get('client.phone').value);
        formData.append('client[email]', this.form.get('client.email').value);
        formData.append('client[between_streets]', this.form.get('client.between_streets').value);
        formData.append('client[colony]', this.form.get('client.colony').value);
        formData.append('client[state]', this.form.get('client.state').value);
        formData.append('client[county]', this.form.get('client.county').value);
        formData.append('client[postal_code]', this.form.get('client.postal_code').value);
        formData.append('client[id_groups]', this.form.get('internalData.plan').value);
        formData.append('responsabilidad', this.form.get('responsabilidad').value);
        formData.append('seller', this.form.get('seller').value);
        formData.append('monthly_installments', this.form.get('monthly_installments').value);
        var elements = this.form.get('elements').value;
        var images = this.form.get('images');
        if (elements) {
            if (elements.length > 0) {
                elements.forEach(function (element) {
                    formData.append('elements[]', element.id_categories_elements);
                });
            }
        }
        if (images.dirty) {
            Object.keys(images.value).forEach(function (image) {
                var formulary_image = _this.form.get("images." + image).value;
                if (formulary_image) {
                    formData.append("images[" + image + "]", formulary_image);
                }
            });
        }
        if (cardTokens) {
            Object.keys(cardTokens).forEach(function (key) {
                formData.append("credit_card[" + key + "]", cardTokens[key]);
            });
        }
        return formData;
    };
    /**
     * registerCard
     * Evalua si la información introducida en la seccion de la tarjeta es valida:
     * + Número de tarjeta valido
     * + CVC valido
     * + Fecha de expiración
     * Si la validación de la tarjeta no devuelve ningún error se ejecuta la función para
     * obtener los tokens (CONEKTA, Openpay) de las tarjetas
     * @returns Objeto con la información si la tarjeta se registro o no.
     */
    ClientCreateComponent.prototype.registerCard = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var response, cardData, cardValidationError, cardTokens;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        response = { status: true, added_card: false };
                        cardData = this.form.get('internalData.cardData');
                        if (!cardData.dirty) return [3 /*break*/, 3];
                        cardValidationError = this.cardsService.cardValidation(cardData.value);
                        if (!!cardValidationError) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.setCardTokens()];
                    case 1:
                        cardTokens = _a.sent();
                        if (Object.keys(cardTokens).length > 0) {
                            response.added_card = true;
                            Object.assign(response, { data: cardTokens });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        response.status = false;
                        Object.assign(response, { data: cardValidationError });
                        _a.label = 3;
                    case 3: return [2 /*return*/, response];
                }
            });
        });
    };
    /**
     * setCardTokens
     * Envia la información capturada en el formulario de la tarjeta a los vendors
     * para asi generar los tokens
     * @returns objeto con los tokens de cada vendor
     */
    ClientCreateComponent.prototype.setCardTokens = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tokens, clientData, carData, allTokens;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tokens = {};
                        clientData = this.form.get('client').value;
                        carData = this.form.get('internalData.cardData').value;
                        return [4 /*yield*/, this.cardsService.getTokens(clientData, carData).toPromise()];
                    case 1:
                        allTokens = _a.sent();
                        allTokens.forEach(function (token) {
                            if ('success' in token && token.success) {
                                delete token.success;
                                Object.assign(tokens, token);
                            }
                        });
                        return [2 /*return*/, tokens];
                }
            });
        });
    };
    return ClientCreateComponent;
}());
export { ClientCreateComponent };
