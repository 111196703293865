export const clientsActionsOptions = [
  {
    display: 'Ver cuentas',
    icon: 'fa fa-users',
    event: 'showAccounts',
    conditionality: 'this.data.is_parent',
    permissions: ['show-clients'],
    showOnlyForParent: true
  },
  {
    display: 'Ver contrato de compra',
    icon: 'fa fa-file-text',
    event: 'contractItem',
    conditionality: 'true',
    permissions: ['show-clients']
  },
  {
    display: 'Ver contratos referidos',
    icon: 'fa fa-file-o',
    event: 'referalsItem',
    conditionality: 'this.data.status ===  "accepted"',
    permissions: ['show-clients']
  },
  {
    display: 'Historial del cliente',
    icon: 'fa fa-search',
    event: 'clientHistoryItem',
    conditionality: 'true',
    permissions: ['show-clients']
  },
  {
    display: 'Asociar cuentas hijas',
    icon: 'fa fa-link',
    event: 'associateChildAccounts',
    conditionality: 'this.data.status === "accepted" && !this.data.parent',
    permissions: ['update-clients']
  },
  {
    display: 'Volver cuenta individual',
    icon: 'fa fa-chain-broken',
    event: 'disAssociateChildAccounts',
    conditionality: 'this.data.parent'
  },
  {
    display: 'Crear cargo',
    icon: 'fa fa-dollar',
    event: 'manualPaymentItem',
    conditionality: 'this.data.status === "accepted" && this.data.collected_at',
    permissions: ['create-charges']
  },
  {
    display: 'Ver cargos',
    icon: 'fa fa-dollar',
    event: 'clientChargeHistoryItem',
    conditionality: '["accepted", "canceled", "standby"].includes(this.data.status)',
    permissions: ['list-charges', 'show-charges']
  },
  {
    display: 'Dar mensualidad gratis',
    icon: 'fa fa-exclamation',
    event: 'freeMonthlyFee',
    conditionality: 'this.data.status === "accepted" && this.data.collected_at && this.data.bad_debt === 0',
    permissions: ['create-free-monthly-fee']
  },
  {
    display: 'Creditos',
    icon: 'fa fa fa-money',
    event: 'clientCredit',
    conditionality: 'this.data.status === "accepted" || this.data.status === "canceled"',
    permissions: ['list-credits', 'create-credits']
  },
  {
    display: 'Meses promocionales',
    icon: 'fa fa fa-money',
    event: 'clientPromotionalMonthlyFee',
    conditionality: 'this.data.status === "accepted" || this.data.status === "canceled"',
    permissions: ['list-promotional-monthly-fees', 'create-promotional-monthly-fees']
  },
  {
    display: 'Ver tarjetas registradas',
    icon: 'fa fa-credit-card-alt',
    event: 'clientCards',
    conditionality: '(this.data.parent || this.data.is_parent) ? false : true',
    permissions: ['list-cards']
  },
  {
    display: 'Actualizar plan',
    icon: 'fa fa-refresh',
    event: 'updatePlan',
    conditionality: 'this.data.status === "accepted" && (this.data.id_plan_type === 2 || this.data.id_plan_type === 4)',
    permissions: ['update-monthly-fee']
  },
  {
    display: 'Actualizar mensualidad',
    icon: 'fa fa-dollar',
    event: 'updateMonthlyFee',
    conditionality: 'this.data.monthly_fee <= 30000 && this.data.status === "accepted"',
    permissions: ['update-monthly-fee']
  },
  {
    display: 'Editar',
    icon: 'fa fa-pencil',
    event: 'clientEditItem',
    conditionality: 'true',
    permissions: ['update-clients']
  },
  {
    display: 'Imagenes extras',
    icon: 'fa fa-picture-o',
    event: 'extraImages',
    conditionality: 'this.data.status === "accepted" && this.data.collected_at && this.data.completed_installation',
    permissions: ['update-client-extra-images']
  },
  {
    display: 'Restablecer contraseña',
    icon: 'fa fa-key',
    event: 'restorePasswordItem',
    conditionality: 'true',
    permissions: ['update-users']
  },
  {
    display: 'Aceptar cliente',
    event: 'setAcceptedItem',
    conditionality: 'this.data.status === "standby" && this.data.collected_at',
    permissions: ['update-clients']
  },
  {
    display: 'Invalidar cliente',
    event: 'client.setInvalid',
    conditionality: 'this.data.status === "standby"',
    permissions: ['update-clients']
  },
  {
    display: 'Rechazar cliente',
    event: 'client.setRejected',
    conditionality: 'this.data.status === "standby" || this.data.status === "invalid"',
    permissions: ['update-clients']
  },
  {
    display: 'Eliminar cliente',
    event: 'clientDeleteItem',
    conditionality: 'this.data.status === "rejected" || this.data.status === "prospect"',
    permissions: ['delete-clients']
  },
  {
    display: 'Mover a mala deuda',
    event: 'moveToBadDebtItem',
    conditionality: '(this.data.status === "accepted" || this.data.status === "canceled") && this.data.bad_debt === 0 && this.data.debt > 0',
    permissions: ['move-bad-debts']
  },
  {
    display: 'Cambiar status del equipo',
    icon: 'fa fa-wrench',
    event: 'changeDeviceRecoveryStatus',
    conditionality: 'this.data.status === "canceled" || this.data.status === "accepted"',
    permissions: ['update-clients']
  },
  {
    display: 'Crear ticket de soporte',
    icon: 'fa fa-ticket',
    event: 'createSupportTicketItem',
    conditionality: 'this.data.status === "accepted"',
    permissions: ['create-tickets']
  }
];