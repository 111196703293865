<div class="row" *ngIf="!! options?.filters && options?.filters.length">
    <div [ngClass]="filter.cssClass" *ngFor="let filter of options.filters">
        <div *ngIf="!filter.select_type">
            <h6>{{filter.title}}</h6>
        </div>
        <app-filters [data]="filter"></app-filters>
    </div>
</div>

<!-- <div class="btn-group btn-group-toggle mb-3 d-table" data-toggle="buttons">
    <label class="btn btn-secondary">
        <input type="radio" name="options" id="option1"> Excel
    </label>
    <label class="btn btn-secondary">
        <input type="radio" name="options" id="option2"> PDF
    </label>
    <label class="btn btn-secondary">
        <input type="radio" name="options" id="option3"> Imprimir
    </label>
</div> -->
<div class="table-responsive">
    <table datatable [dtOptions]="dtOptions" class="row-border hover" style="width: 100% !important;">
        <thead>
            <tr *ngIf="options?.columns">
                <ng-container *ngFor="let column of options.columns">
                    <ng-container *ngIf="isVisible(column?.conditionality) === true; else noCondition">
                        <th>{{column.display}}</th>
                    </ng-container>

                    <ng-template #noCondition>
                        <th>{{column.display}}</th>
                    </ng-template>


                    <!-- <ng-container *ngIf="column?.conditionality; else noCondition">
                    <ng-container *ngIf="isVisible(column?.conditionality)">
                        {{column.display}}
                    </ng-container>
                </ng-container>
                
                <ng-template #noCondition>
                    {{column.display}}
                </ng-template> -->
                </ng-container>
            </tr>
        </thead>
        <tbody *ngIf="items?.length != 0">
            <ng-container *ngFor="let data of items; let i = index">
                <tr #trRef id="parent-{{i}}" (click)="rowClick(data)">
                    <ng-container *ngFor="let column of options.columns">
                        <ng-container *ngIf="isVisible(column?.conditionality) === true; else noCondition">
                            <td>
                                <app-datatable-column [column]="column" [data]="data" [trRef]="trRef">
                                </app-datatable-column>
                            </td>
                        </ng-container>

                        <ng-template #noCondition>
                            <td>
                                <app-datatable-column [column]="column" [data]="data" [trRef]="trRef">
                                </app-datatable-column>
                            </td>
                        </ng-template>
                        <!-- <ng-container *ngIf="column?.conditionality; else noCondition">
                        <ng-container *ngIf="isVisible(column?.conditionality)">
                            <app-datatable-column [column]="column" [data]="data" [trRef]="trRef"></app-datatable-column>
                        </ng-container>
                    </ng-container>
                    <ng-template #noCondition>
                        <app-datatable-column [column]="column" [data]="data" [trRef]="trRef"></app-datatable-column>
                    </ng-template> -->
                    </ng-container>
                </tr>
                <tr *ngIf="openRows.indexOf(i) > -1">
                    <td colspan="4">
                        <template #trChild id="child-{{i}}"></template>
                    </td>
                </tr>
            </ng-container>
        </tbody>
        <tbody *ngIf="items?.length == 0">
            <tr>
                <td [attr.colspan]="options?.columns.length || 1" class="no-data-available text-center">
                    {{processing ? 'Un momento...' : 'No se encontraron resultados'}}
                </td>
            </tr>
        <tbody>
    </table>
</div>