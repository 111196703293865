<div class="modal-body" *ngIf="user !== undefined">
    <dl class="row">
        <dt class="col-sm-3">Nombre</dt>
        <dd class="col-sm-9">{{user.name}}</dd>
        <dt class="col-sm-3">Email</dt>
        <dd class="col-sm-9">{{user.email}}</dd>
    </dl>
    <app-form-field type="fake_password" [options]="{label: 'Contraseña', path: 'password'}"></app-form-field>
    <div *ngIf="form.controls.password.dirty">
        <app-form-field type="password" [options]="{label: 'Confirmar contraseña', path: 'password_confirm'}"></app-form-field>
    </div>
</div>

<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid" (click)="restorePassword()">Guardar</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>