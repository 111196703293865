import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-previous-ticket-data',
  templateUrl: './previous-ticket-data.component.html',
  styleUrls: ['./previous-ticket-data.component.scss']
})
export class PreviousTicketDataComponent implements OnInit {
  @Input('ticketData') ticketData: any;
 
  ngOnInit(): void {
    //
  }

}
