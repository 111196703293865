<div class="container m-2">
    <app-form-field type="input" [options]="{label: 'Nombre',width:12, path: 'name'}"></app-form-field>
    <app-form-field type="textarea" styleInput="textarea" [options]="{label: 'Descripción', rows: '4', path:'description', width: true}"></app-form-field>
    <label class="col-sm-12 col-form-label font-weight-bold int">Intregantes</label>
    <div class="row">
        <div class="col-md-6">
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="row">
                  <div class="col-md-12">
                    <i>Clientes</i>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="table-responsive">
                  <table class="table table-striped table-hover">
                    <tbody>
                        <app-datatable [options]="dataTableConfig"></app-datatable>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="panel panel-default">
              <div class="panel-heading">
                <div class="row">
                  <div class="col-md-12">
                    <i>Clientes seleccionados</i>
                  </div>
                </div>
              </div>
              <div class="panel-body">
                <div class="table-responsive clientSelected">
                    <table class="table table-striped table-hover">
                        <tbody>
                            <tr *ngFor="let client of selectedClients">
                                <td>{{client.name}}</td>
                                <td class="remove"><button type="button" class="btn btn-success" (click)="removeClient(client)">Remover</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
<br>
<div class="modal-footer">
    <div *ngIf="this.form.dirty">
        <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss('Cross click')">Cancelar</button>
        <button type="button" class="btn btn-outline-success" [disabled]="form.invalid" (click)="groupSave()">Guardar</button>
    </div>
    <div *ngIf="!this.form.dirty">
        <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cross click')">Cerrar</button>
    </div>
</div>