import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidator {
    static noSpace(control: AbstractControl): ValidationErrors | null {
        if ((control.value as string).indexOf(' ') >= 0) {
            return { noSpace: true };
        }

        return null;
    }

    static checkRangeHour(control: AbstractControl): ValidationErrors | null {
        if (control && control.parent) {
            if (control && control.parent.get('start_time') && control.parent.get('end_time')) {
                let selected_date = '2021-01-01T';
    
                if (control.parent.get('schedule_date').value !== '') {
                  selected_date = control.parent.get('schedule_date').value;
                }
                
                const start_time = Date.parse(`${selected_date}${String(control.parent.get('start_time').value)}`);
                const end_time = Date.parse(`${selected_date}${String(control.parent.get('end_time').value)}`);
                
                return (start_time > end_time) ? { checkRangeHour: true} : null;
            } 
        }
    
        return null;
    }
}
