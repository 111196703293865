import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthorizationService } from './authorization.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  userData: any;
  isLoggedIn = false;

  constructor(
    private readonly auth: AuthorizationService,
    private readonly router: Router
  ) { }

  canActivate(): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate(['login']);
    } else {
      return true;

    }
  }

  getLocalStorageUser(): any {
    this.userData = JSON.parse(localStorage.getItem('access_token'));
    this.isLoggedIn = false;

    if (this.userData) {
      this.isLoggedIn = true;
    }

    return this.isLoggedIn;
  }
}
