import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ModalComponent } from '../../../components/modal/modal.component';
import { SharedComponent } from '../../../model/shared-component';
import { SuppliesCategoriesCreateEditComponent } from './supplies-categories-create-edit/supplies-categories-create-edit.component';
import { BroadcastService } from '../../../services/broadcast.service';
import { PermissionsService } from '../../../services/permissions.service';
import { SwalService } from '../../../services/swal.service';
import { SuppliesService } from '../../../services/supplies.service';

@Component({
  selector: 'app-supplies-categories',
  templateUrl: './supplies-categories.component.html',
  styleUrls: ['./supplies-categories.component.scss']
})
export class SuppliesCategoriesComponent implements OnInit, OnDestroy {
  notSupportedEvents = environment.notSupportedEvents;
  dataTableConfig: any;
  subscriptions: Array<Subscription> = [];
  private readonly baseDataTableConfig = {
    config: {
      base: this.suppliesService,
      api: 'getCategoriesSuppliesDataTable'
    },
    columns: [
      {
        display: 'Nombre',
        field: 'name',
        type: 'text'
      },
      {
        display: 'Descripción',
        field: 'description',
        type: 'text'
      },
      {
        display: 'Fecha de alta',
        field: 'created_at',
        type: 'date'
      },
      {
        display: 'ACCIONES',
        field: '',
        type: 'actions',
        options: [

          {
            display: 'Editar',
            icon: 'fa fa-pencil',
            event: 'editCategory',
            conditionality: true,
            permissions: ['update-categories-supplies']
          }

        ]
      }
    ],
    filters: []
  };
  constructor(
    public activeModal: NgbActiveModal,
    public appModal: ModalComponent,
    public swal: SwalService,
    private suppliesService: SuppliesService,
    private readonly broadcast: BroadcastService,
    private readonly permissionsService: PermissionsService
  ) { }

  ngOnInit(): void {
    this.subscriptions.push(this.broadcast.events.subscribe(event => {
      if (!this.notSupportedEvents.includes(event.name)) {
        if (this[event.name]) {
          this[event.name](event.data);
        }
      }
    }));
    this.dataTableConfig = this.permissionsService.filterTableConfig(this.baseDataTableConfig);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  /**
   * createCategory
   * Abre un modal para crear una categoria de un insumo
   */
  createCategory(): void {
    const props: SharedComponent = new SharedComponent(
      SuppliesCategoriesCreateEditComponent,
      {
        status: 'create'
      },
      {
        title: 'Crear categoria de insumo'
      }
    );
    this.appModal.opensm(props);
  }

   /**
   * editCategory
   * Abre un modal que muestra la información a editar de una categoria de un insumo
   */
   editCategory(data): void {
    const props: SharedComponent = new SharedComponent(
      SuppliesCategoriesCreateEditComponent,
      {
        editData: data,
        status: 'edit'
      },
      {
        title: `Edición de la categoria de insumo ${data.name}`
      }
    );
    this.appModal.opensm(props);
  }

}
