import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminRoutes } from './admin.routing';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartsModule } from 'ng2-charts';
import { ClientsModule } from './clients/clients.module';
import { ComponentsModule } from '../components/components.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthorizationInterceptorService } from '../providers/authorization-interceptor.service';
import { FinancesModule } from './finances/finances.module';
import { RefreshTokenInterceptorService } from '../providers/refresh-token-interceptor.service';
import { ServiceModule } from './service/service.module';
import { AdministrationModule } from './administration/administration.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { SellersModule } from './sellers/sellers.module';
import { SocialModule } from './social/social.module';
import { DevModule } from './dev/dev.module';
import { WarehouseModule } from './warehouse/warehouse.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ChartsModule,
    HttpClientModule,
    // BsDropdownModule,
    // ButtonsModule.forRoot(),
    ReactiveFormsModule,
    RouterModule.forChild(AdminRoutes),
    AdministrationModule,
    ClientsModule,
    FinancesModule,
    ServiceModule,
    SellersModule,
    DashboardModule,
    ComponentsModule,
    SocialModule,
    DevModule,
    WarehouseModule
  ],
  declarations: [],
  entryComponents: [
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptorService, multi: true}
    // { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptorService, multi: true}
  ]
})

export class AdminModule { }
