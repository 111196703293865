import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// Services
import { FromService } from '../../../../providers/form.service';
import { UsersService } from '../../../../services/users.service';
import { SwalService } from '../../../../services/swal.service';
import { environment } from '../../../../../environments/environment';
import { BroadcastService } from '../../../../services/broadcast.service';

@Component({
  selector: 'app-administrator-create-edit',
  templateUrl: './administrator-create-edit.component.html',
  styleUrls: ['./administrator-create-edit.component.scss']
})
export class AdministratorCreateEditComponent implements OnInit, OnDestroy {
  @Input() data: any;
  admin: any;
  role: any;
  baseUrl = `${environment.apiUrl}`;
  image_profile: any;
  form: FormGroup = this.formBuilder.group({
    name: ['', Validators.required],
    image64: [''],
    phone: [''],
    email: ['', Validators.required],
    password: ['', [Validators.required, Validators.minLength(6)]],
    password_confirm: ['', [Validators.required, Validators.minLength(6)]],
    contractor: [''],
    status: [''],
    type: [''],
    // 'team_id': [''],
    role: ['']
  });

  definition = {
    name: {
      type: 'text',
      label: 'Nombre Completo'
    },
    email: {
      type: 'email',
      label: 'Correo Electronico'
    },
    phone: {
      type: 'phone',
      label: 'Telefono'
    },
    image: {
      type: 'image'
    }
  };

  constructor(
    private readonly broadcast: BroadcastService,
    private readonly formBuilder: FormBuilder,
    private readonly fromService: FromService,
    public activeModal: NgbActiveModal,
    private readonly userService: UsersService,
    private readonly swal: SwalService) { }

  ngOnInit(): void {
    this.role = this.data.role;
    if (this.data.status === 'edit') {
      this.userService.show(this.data.user.id).subscribe((data: any) => {
        this.admin = data.response || this.data.user;
        Object.keys(this.admin).forEach(key => {
          if (this.admin.hasOwnProperty(key) && !!this.form.controls[key]) {
            this.form.controls[key].setValue(this.admin[key]);
          }
        });
        this.updateValidators();

        this.userService.getImageProfile(this.admin.id).subscribe((resp: any) => {
          this.image_profile = `${this.baseUrl}/profiles/${resp.response}`;
        });
      });
    }
    this.phoneValidator(this.role.role);
    this.fromService.setForm(this.form);
  }

  ngOnDestroy(): void {
    //
  }
  
  updateValidators(): void {
    const password = this.form.get('password');
    const password_confirm = this.form.get('password_confirm');
    this.form.get('password').clearValidators();
    this.form.get('password_confirm').clearValidators();

    this.form.get('password').valueChanges.subscribe(value => {
      if (value !== '') {
        password.setValidators([Validators.required, Validators.minLength(6)]);
        password_confirm.setValidators([Validators.required, Validators.minLength(6)]);
        // password.updateValueAndValidity({ onlySelf: true });
        // password_confirm.updateValueAndValidity({ onlySelf: true});
      }
    });
    password.updateValueAndValidity();
    password_confirm.updateValueAndValidity();
  }

  phoneValidator(role): void {
    if (role === 'Technician') {
      this.form.get('phone').setValidators([Validators.required]);
      this.form.get('phone').updateValueAndValidity();
    }
  }

  save(): void {
    if (this.data.status === 'edit') {
      this.updateUser();
    }

    if (this.data.status === 'create') {
      this.saveUser();
    }
  }

  saveUser(): void {
    if (this.role.role === 'Technician') {

      this.form.controls.role.setValue(this.role.role);
    }
    if (this.form.valid) {
      this.swal.warning({ title: '¿Esta seguro de querer guardar los datos del usuario?' }).then(result => {
        if (result.value) {
          this.userService.create(this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            }
          });
        } else {
          this.swal.error({ title: 'Ocurió un error al crear al usuario' });
        }
      });
    }
  }

  updateUser(): void {
    if (this.form.valid && this.form.get('password').value === this.form.get('password_confirm').value) {
      this.swal.warning({ title: '¿Esta seguro de querer actualizar los datos del usuario?' }).then(result => {
        if (result.value) {
          this.userService.update(this.admin.id, this.form.value).subscribe((resp: any) => {
            if (resp.success) {
              this.swal.success().then(() => {
                this.activeModal.dismiss();
                this.broadcast.reloadDataTable();
              });
            } else {
              this.swal.error({ title: 'Ocurió un error al actualizar los datos' });
            }
          });
        }
      });
    }
  }

}
