import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { BroadcastService } from '../../../../../services/broadcast.service';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {
    @Input() data: any;
    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;

    selected;
    dropdown_title = 'Filtrar por ';
    constructor(private readonly broadcast: BroadcastService) { }

    ngOnInit(): void {
        if (this.data.title) {
            this.dropdown_title = `${this.dropdown_title} ${this.data.title}`;
        }
    }

    isVisible(active): string {
        // tslint:disable-next-line: no-eval
        return eval(active);
    }

    radioChange(value?, dropdown_title?): any {

        if (value || value === '') {
            this.selected = value;
            this.dropdown_title = dropdown_title;
        }

        this.broadcast.fire({
            name: 'datatable-filter',
            data: {
                value: this.selected,
                type: this.data.type
            }
        });
    }
}
