/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./label.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./label.component";
var styles_LabelComponent = [i0.styles];
var RenderType_LabelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LabelComponent, data: {} });
export { RenderType_LabelComponent as RenderType_LabelComponent };
export function View_LabelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "badge badge-", _co.badgeClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.text; _ck(_v, 1, 0, currVal_1); }); }
export function View_LabelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-datatable-column-label", [], null, null, null, View_LabelComponent_0, RenderType_LabelComponent)), i1.ɵdid(1, 114688, null, 0, i2.LabelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LabelComponentNgFactory = i1.ɵccf("app-datatable-column-label", i2.LabelComponent, View_LabelComponent_Host_0, { data: "data", options: "options" }, {}, []);
export { LabelComponentNgFactory as LabelComponentNgFactory };
